export const LANDLORD_ADD_UNIT_STORED_DATA_V2 = 'bso-landlord-add-unit-stored-data-v2'

export type LandlordAddUnitStorageKeyV2 = 'landlord_create_unit_choose_role' | 'landlord_create_unit_upload_title_deed' | 'landlord_create_unit_owner_passport' | 'landlord_create_unit_id_verification' | 'landlord_create_unit_property_info' | 'landlord_create_unit_unit_info' | 'landlord_create_unit_unit_features'
export function landlordAddUnitWriteToStorageV2 (key: LandlordAddUnitStorageKeyV2, value: Record<string, any>): void {
    const storedData = JSON.parse(localStorage.getItem(LANDLORD_ADD_UNIT_STORED_DATA_V2) ?? '{}')
    storedData[key] = value
    localStorage.setItem(LANDLORD_ADD_UNIT_STORED_DATA_V2, JSON.stringify(storedData))
}

export function landlordAddUnitReadFromStorageV2 (): Record<LandlordAddUnitStorageKeyV2, any> {
    return JSON.parse(localStorage.getItem(LANDLORD_ADD_UNIT_STORED_DATA_V2) ?? '{}')
}

export function landlordAddUnitClearStorageV2 (): void {
    localStorage.removeItem(LANDLORD_ADD_UNIT_STORED_DATA_V2)
}
