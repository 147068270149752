
export const getErrors = (json: any): string => {
    // console.log(json, 'json')

    const details = json?.response?.error?.details
    let errorMessages = ''
    if (Object.keys(details).length === 0) {
        if (json?.response?.error.message) {
            errorMessages += json?.response?.error.message
        }
    }
    if (details != null && Array.isArray(details)) {
        for (let i = 0; i < details.length; i++) {
            errorMessages += `${details[i].message}\n`
        }
    } else if (json?.response?.error.message) {
        errorMessages += json?.response?.error.message
    } else if (json?.response?.error.message) {
        errorMessages += json?.response?.error.message
    } else {
        errorMessages += 'Unpredictable error'
    }
    return errorMessages
}

export const getErrorss = (json: any): string => {
    // console.log(json, 'json')
    const details = json?.response?.error?.details
    const errorMessages: string[] = []

    if (json?.response?.error?.message) {
        errorMessages.push(json.response.error.message)
    } else {
        if (Array.isArray(details)) {
            for (const detail of details) {
                //        console.log(':?????')
                if (typeof detail === 'object' && detail?.message) {
                    if (errorMessages.length === 0) {
                        errorMessages.push(detail.message)
                    } if (typeof detail === 'object' && detail?.description) {
                        if (errorMessages.length === 0) {
                            errorMessages.push(detail.description)
                        }
                    }
                }
            }
        } else {
            if (typeof details === 'object' && details?.message) {
                errorMessages.push(details.message)
            } if (typeof details === 'object' && details?.description) {
                errorMessages.push(details.description)
            }
        }
    }

    if (errorMessages.length === 0) {
        errorMessages.push('Unpredictable error')
    }

    //  console.log(errorMessages, 'errorMessages')
    return errorMessages.join('\n')
}

export const getErrorsForScan = (json: any): string => {
    //   console.log(json, 'json')
    const details = json?.response?.error?.details
    const errorMessages: string[] = []
    //  console.log(details, 'details')

    if (details) {
        if (Array.isArray(details)) {
            for (const detail of details) {
                //      console.log(':?????')
                if (typeof detail === 'object' && detail?.message) {
                    errorMessages.push(detail.message)
                } if (typeof detail === 'object' && detail?.description) {
                    errorMessages.push(detail.description)
                }
            }
        } else {
            if (typeof details === 'object' && details?.message) {
                errorMessages.push(details.message)
            } if (typeof details === 'object' && details?.description) {
                errorMessages.push(details.description)
            }
        }
    } else {
        if (json?.response?.error?.message) {
            errorMessages.push(json.response.error.message)
        }
    }

    // if (json?.response?.error?.message) {
    //     errorMessages.push(json.response.error.message)
    // } else {
    if (errorMessages.length === 0) {
        errorMessages.push('Unpredictable error')
    }

    //  console.log(errorMessages, 'errorMessages')
    return errorMessages.join('\n')
}
