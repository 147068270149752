import React, {type ReactElement} from "react";
import ClosableBlock from "../../../../../UIv2/ClosableBlock/ClosableBlock";
import {type ViewUnitSectionProps} from "../ViewUnitSection";
import ViewUnitSectionAdditionalDetailsRow from "./ViewUnitSectionAdditionalDetailsItem";
import viewUnitAdditionalDetailsHelper from "./ViewUnitSectionAdditionalDetails.helper";
import {nanoid} from "nanoid";

export default function ViewUnitSectionAdditionalDetails ({unit}: ViewUnitSectionProps): ReactElement {
    const additionalDetailsConfig = viewUnitAdditionalDetailsHelper(unit)
    return <ClosableBlock
        title={'Additional Details'}
        closable={false}
    >
        <div className={'d-flex flex-column gap-4'}>
            {
                additionalDetailsConfig.map((row) => (
                    <ViewUnitSectionAdditionalDetailsRow
                        label={row.label}
                        values={row.values}
                        key={nanoid()}
                    />
                ))
            }
        </div>
    </ClosableBlock>
}