import React, {type ReactElement} from "react";
import Card from "../../../../../components/shared/UIv2/Card/Card";
import {useLocation, useNavigate} from "react-router-dom";
import {nanoid} from "nanoid";
import {setNavigateFields, setNavigationDirection} from "../../../../../store/slices/modalSlice";
import {useAppDispatch} from "../../../../../hooks/redux";

interface TabLinksProps {
    row: Record<string, any>
}

export default function TabLinks ({row}: TabLinksProps ): ReactElement {
    const { pathname } = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const links = row.links as any[]
    const activeLinkIndex = links?.findIndex((link) => link.href === pathname) ?? 0
    return (
        <div className={'w-100 pb-8'}>
            <Card style={'filled'} rounded={'8'} height={'fit-content'}>
                <div className={'d-flex p-1 gap-1'}>
                    {
                        links?.map((link: any, index: number) => (
                            <div className={'flex-grow-1'}
                                key={nanoid()}
                                onClick={() => {
                                    if (activeLinkIndex < index) {
                                        dispatch(setNavigationDirection('back'))
                                        dispatch(setNavigateFields(true))
                                        navigate(link.href)
                                        setTimeout(() => {
                                            dispatch(setNavigationDirection(undefined))
                                            dispatch(setNavigateFields(false))
                                        }, 1000)
                                    } else {
                                        dispatch(setNavigateFields(true))
                                        navigate(link.href)
                                        setTimeout(() => {
                                            dispatch(setNavigateFields(false))
                                        }, 1000)
                                    }
                                }}
                            >
                                <Card
                                    style={'filled'}
                                    rounded={'4'}
                                    height={'fit-content'}
                                    customBackgroundClass={pathname === link.href ? 'bg-white' : undefined}
                                >
                                    <div className={`py-2 text-align-center fs-5 fw-500 text-center ${pathname === link.href ? 'text-black' : 'text-gray-500'}`}>
                                        {
                                            link.text
                                        }
                                    </div>
                                </Card>
                            </div>
                        ))
                    }
                </div>
            </Card>
        </div>
    )
}