import QueryString from 'qs'
import { REACT_APP_BSO_USER_ID, USER_CATEGORY_KEY, USER_ID_KEY } from '../../../../urls'

export const getQueryAdvertises = (): string => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userCATEGORY = window.localStorage.getItem(USER_CATEGORY_KEY)

    let advertisesQueryObject = {}

    if (userCATEGORY === 'Agent') {
        advertisesQueryObject = {
            filters: {
                $or: [
                    {
                        User: {
                            id: {
                                $eq: userID
                            }
                        }
                    }
                ]
            },
            populate: {
                User: {
                    fields: ['id', 'DeviceToken']
                },
                Unit: {
                    fields: ['id']
                }
            }
        }
    } else if (userCATEGORY === 'Landlord' && Number(userID) !== Number(REACT_APP_BSO_USER_ID)) {
        advertisesQueryObject = {
            populate: {
                User: {
                    fields: ['id', 'DeviceToken']
                },
                Unit: {
                    fields: ['id']
                }
            }
        }
    } else if (Number(userID) === Number(REACT_APP_BSO_USER_ID)) {
        advertisesQueryObject = {
            populate: {
                User: {
                    fields: ['id', 'DeviceToken']
                },
                Unit: {
                    fields: ['id']
                }
            }
        }
    }

    const advertisesQuery = QueryString.stringify(advertisesQueryObject, { encodeValuesOnly: true })

    return advertisesQuery
}
