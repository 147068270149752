import React, {type ReactElement} from "react";
import {useNavigate} from "react-router-dom";

interface TextLinkProps {
    row: Record<string, any>
}

export default function TextLink ({row}: TextLinkProps): ReactElement {
    const navigate = useNavigate()
    return <div className={`pt-2 ${row.stickToBottom === true ? 'h-100' : 'h-fit'} d-flex flex-column justify-content-end`}>
        <div className={`text-center fs-4 fw-medium text-gray-500 ${row.stickToBottom === true ? 'mb-5' : 'mb-8'}`}
            onClick={() => {
                navigate(row.href)
            }}
        >
            {row.content[0]} {
                row.content[1] != null
                    ? <span className={'text-decoration-underline'}
                        style={{
                            textUnderlineOffset: '3px'
                        }}
                    >
                        {row.content[1]}
                    </span>
                    : <></>
            }
        </div>
    </div>
}