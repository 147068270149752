import React, {type ReactElement} from "react";
import {Controller} from "react-hook-form";
import Checkbox from "../../../../../components/shared/UIv2/Checkbox/Checkbox";

interface InputCheckboxProps {
    row: Record<string, any>
    control: any
}

export default function InputCheckbox ({row, control}: InputCheckboxProps): ReactElement {
    return (
        <div className={row.paddingBottom === 'small' ? 'pb-4' : 'pb-8'}>
            <Controller
                name={row.id}
                control={control}
                render={({field}) => {
                    return (
                        <div className={'d-flex gap-4 align-items-center'}
                            onClick={() => {
                                field.onChange(field.value !== true)
                            }}
                        >
                            <Checkbox
                                checked={field.value === true}
                            />
                            <div className={'fs-5 fw-medium'}>
                                {
                                    row.content[0]
                                }
                            </div>
                        </div>
                    )
                }}
            />
        </div>
    )
}