function localStorageForPipelines<TStepKey extends string>(storageKey: string) {
    return {
        writeToStorage (key: TStepKey, value: Record<string, any>): void {
            const storedData = JSON.parse(localStorage.getItem(storageKey) ?? '{}')
            storedData[key] = value
            localStorage.setItem(storageKey, JSON.stringify(storedData))
        },
        readFromStorage (): Record<TStepKey, any> {
            return JSON.parse(localStorage.getItem(storageKey) ?? '{}')
        },
        clearStorage (): void {
            localStorage.removeItem(storageKey)
        }
    }
}

export default localStorageForPipelines
