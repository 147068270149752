import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {type Unit, unitsSetSearchString} from "../../../../store/slices/unitsSlice";
import {type RootState} from "../../../../store/store";
import _ from "lodash";

type IUseMyUnits = () => {
    myUnits: Unit[]
    searchString: string
    setSearchString: (value: string) => void
}

const useMyUnits: IUseMyUnits = () => {
    const units = useAppSelector((state) => state.units.units)
    const currentUser = useAppSelector((state) => state.user.currentUser)
    const searchString = useAppSelector((state: RootState) => state.units.searchString)
    const dispatch = useAppDispatch()
    const myUnits = units.filter((unit) => {
        let result = unit.attributes?.User?.data?.id === currentUser.id
        if (searchString?.trim()?.length > 0) {
            result = result && (
                _.includes(unit.attributes?.Marketing?.Headline?.toLowerCase(), searchString?.toLowerCase()?.trim()) ||
                _.includes(unit.attributes?.Property?.data?.attributes?.Area?.toLowerCase(), searchString?.toLowerCase()?.trim()) ||
                _.includes(unit.attributes?.Property?.data?.attributes?.Name?.toLowerCase(), searchString?.toLowerCase()?.trim()) ||
                _.includes(searchString?.toLowerCase()?.trim(), unit.attributes?.Property?.data?.attributes?.Area?.toLowerCase()) ||
                _.includes(searchString?.toLowerCase()?.trim(), unit.attributes?.Property?.data?.attributes?.Name?.toLowerCase())
            )
        }
        return result
    })
    const setSearchString = (value: string): void => {
        dispatch(unitsSetSearchString(value))
    }
    return {
        myUnits,
        searchString,
        setSearchString
    }
}

export default useMyUnits
