import React, {type ReactElement, type ReactNode} from "react";
import Separator from "../../../../../UIv2/Separator/Separator";
import Card from "../../../../../UIv2/Card/Card";
import {nanoid} from "nanoid";

interface LandlordUnitServiceProps {
    title: string
    infoBlocks: ReactNode[]
}

export default function LandlordUnitCardService ({title, infoBlocks}: LandlordUnitServiceProps): ReactElement {
    return <div className={'d-flex flex-column gap-3'}>
        <div className={'d-flex align-items-center gap-3'}>
            <div className={'fs-7 text-gray-600 text-nowrap'}>
                {
                    title
                }
            </div>
            <Separator/>
        </div>
        <div className={'row d-flex align-items-stretch'}>
            {
                infoBlocks.map((block: ReactNode) => (
                    <div className={'col-6 pe-2'} key={nanoid()}>
                        <Card style={'white'} rounded={'4'} height={'fill'}>
                            <div className={'p-3'}>
                                {
                                    block
                                }
                            </div>
                        </Card>
                    </div>
                ))
            }
        </div>
    </div>
}