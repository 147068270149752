import React from 'react'

import { useNavigate } from 'react-router-dom'
import {AnimatePresence, motion } from "framer-motion"
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import CustomModalFooter from '../CustomModalFooter'
import { store } from '../../../../store/store'
import { verifyResetPasswordEmail, verifyResetPasswordPhone } from '../../../../store/actionsCreators/authActions'
import { Form } from 'antd'
import { nanoid } from 'nanoid'
import { setResendInProcess } from '../../../../store/slices/modalSlice'
import { USER_AREAS_EXTEND_COUNT } from '../../../../urls'
import { swalError, swalSuccess } from '../../../../functions/swalOptions'
import { type IResponseObjectJSON } from '../../../../types/userTypes/TAuth'
import LinkToLogin from "../../customModalUtils/LinkToLogin";
import PhoneNumberPicker from "./PhoneNumberPicker/PhoneNumberPicker";
import ModalTitle from "./ModalTitle/ModalTitle";
import ModalDescription from "./ModalDescription/ModalDescription";
import CodePicker from "./CodePicker/CodePicker";
import CardsSelect from "./CardsSelect/CardsSelect";
import Input from "./Input/Input";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import CustomModalHeader from "../CustomModalHeader";
import ModalHeader from "../Header/ModalHeader";
import TabLinks from "./TabLinks/TabLinks";
import ModalImage from "./ModalImage/ModalImage";
import InputFile from "./InputFile/InputFile";
import ButtonLink from "./ButtonLink/ButtonLink";
import TextLink from "./TextLink/TextLink";
import InputSelect from "./InputSelect/InputSelect";
import InputDate from "./InputDate/InputDate";
import InformationBlock from "./InformationBlock/InformationBlock";
import LinkList from "./LinkList/LinkList";
import FindUserByEmail from "./FindUserByEmail/FindUserByEmail";
import UserWaitingForApprove from "./UserWaitingForApprove/UserWaitingForApprove";
import CardInputFile from "./CardInputFile/CardInputFile";
import InformationCheckList from "./InformationCheckList/InformationCheckList";
import ContactSupport from "./ContactSupport/ContactSupport";
import InputsGroup from "./InputsGroup/InputsGroup";
import InputCheckbox from "./InputCheckbox/InputCheckbox";
import CustomField from "./CustomField";

interface IProps {
    register?: any
    filteredElements?: any
    modal?: any
    handleSubmit?: any
    onSubmit?: any
    errors?: any
    password?: any
    passwordConfirm?: any
    handleInputChange?: any
    isAuth?: any
    control?: any
    unitID?: any
    objectID?: any
    clearErrors?: any
    getValues?: any
    setValue?: any
    trigger?: any
    setError?: any
    watch?: any
}

export default function CustomFieldsHook ({ clearErrors, isAuth, control, unitID, objectID, handleInputChange, filteredElements, modal, register, handleSubmit, onSubmit, errors, password, passwordConfirm, setValue, trigger, getValues, setError, watch }: IProps): JSX.Element {
    const loading = useAppSelector(state => state.auth.loading)
    const currentUser = useAppSelector(state => state.user.currentUser)
    const resendInProcess = useAppSelector((state) => state.modal.resendInProcess)
    const service = useAppSelector((state) => state.service)
    const navigationDirection = useAppSelector((state) => state.modal.navigationDirection)
    const navigateFields = useAppSelector((state) => state.modal.navigateFields)
    const userNUMBER = store.getState().auth.userNUMBER
    const userEMAIL = store.getState().auth.userEMAIL
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const [form] = Form.useForm()
    const storageCountAreasExtend = window.localStorage.getItem(USER_AREAS_EXTEND_COUNT)
    let unitArea: null | string = null
    const [chooseExtAreaIdUnit, countAreasExtend] = storageCountAreasExtend
        ? (typeof storageCountAreasExtend === 'string' ? storageCountAreasExtend.split(' - ') : [undefined, '3'])
        : [undefined, '3']
    if (chooseExtAreaIdUnit && Number(chooseExtAreaIdUnit) === Number(unitID)) {
        const units = store.getState().units?.units?.filter((unit) => unit.id === Number(unitID))
        unitArea = units?.[0]?.attributes?.Property?.data?.attributes?.Area ?? null
    }

    const recentHandler = async (footerRow: any) => {
        dispatch(setResendInProcess(true))
        let callback: IResponseObjectJSON
        footerRow.content?.[1] === 'email'
            ? callback = await dispatch(verifyResetPasswordEmail({ renew: footerRow.renew }))
            : callback = await dispatch(verifyResetPasswordPhone({ renew: footerRow.renew }))

        if (callback.isSuccessful) {
            void swalSuccess(callback.textNotification, () => {
                dispatch(setResendInProcess(false))
            })
        } else {
            void swalError(callback.textNotification, () => {
                dispatch(setResendInProcess(false))
            })
        }
    }

    const animationOffset = 200

    return (
        <>
            <div className="w-100 pt-5" // style={{ paddingTop: userDevice === 'IPhone' ? '45px' : '14px', paddingBottom: '14px' }}
            >
                <ModalHeader modalID={modal[0].id}/>
            </div>
            <AnimatePresence mode="wait">
                <motion.div
                    className={'flex-grow-1 d-flex flex-column flex-grow-1 overflow-auto'}
                    initial={
                        !navigateFields ? { opacity: 0, x: navigationDirection === 'back' ? -animationOffset: animationOffset } : undefined
                    }
                    animate={
                        !navigateFields ? { opacity: 1, x: 0 } : undefined
                    }
                    exit={
                        !navigateFields ? { opacity: 0, x: navigationDirection === 'back' ? animationOffset: -animationOffset } : undefined
                    }
                    transition={
                        !navigateFields ? { duration: 0.3 } : undefined
                    }
                    key={modal?.[0]?.id}
                >
                    {/* <div className={''}> */}
                    {modal?.[0]?.form?.map((row: any, i: number) => (
                        <motion.div
                            key={`key_${row.id}_${i}`}
                            initial={
                                navigateFields && row.animateTransition === true ? { opacity: 0, x: navigationDirection === 'back' ? -animationOffset: animationOffset } : undefined
                            }
                            animate={
                                navigateFields && row.animateTransition === true ? { opacity: 1, x: 0 }: undefined
                            }
                            exit={
                                navigateFields && row.animateTransition === true ? { opacity: 0, x: navigationDirection === 'back' ? animationOffset: -animationOffset }: undefined
                            }
                            transition={
                                navigateFields && row.animateTransition === true ? { duration: 0.3 } : undefined
                            }
                            id={row.id} /* id to manipulate element */

                            /** For the power attorney page. Toggles in SetSwitch component */
                            className={
                                (row.visual !== undefined && (row.visual === 1) !== (service.switchUnit)) || row.displayNone === true
                                    ? 'd-none'
                                    : ''
                            }
                            style={
                                {
                                    ...(row?.defaultHidden === true ? {
                                        visibility: 'hidden', position: 'absolute'
                                    } : {}),
                                    height: row.height,
                                    flex: row.flex != null ? row.flex : undefined
                                }
                            }
                        >
                            <CustomField
                                row={row}
                                control={control}
                                watch={watch}
                                setError={setError}
                                getValues={getValues}
                            />
                        </motion.div>
                    ))
                    }
                    {/* </div> */}
                </motion.div>
            </AnimatePresence>
            <CustomModalFooter key={nanoid()} objectID={objectID} unitID={unitID} modal={modal} form={form} errors={errors} control={control} setError={setError} setValue={setValue} watch={watch}/>
        </>
    )
}
