import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

import _ from 'lodash'
import { nanoid } from 'nanoid'
import { swalSuccess } from '../../../functions/swalOptions'

const CustomCheckLink = ({ row, control, register, errors, getValues, setValue }: any): JSX.Element => {
    const [key, setKey] = useState(nanoid())
    const approved: boolean = _.get(getValues(), row.id)

    const toggleRejectReason = (): void => {
        const values = getValues()
        const rejectReasonElement = document.getElementById('RejectReason') // Hide reject reason field
        if (rejectReasonElement != null) {
            if (values?.Links?.every((link: { Status: boolean }) => link?.Status) === true) {
                setValue('RejectReason', '')
                rejectReasonElement.classList.add('form-hidden')
            } else {
                rejectReasonElement.classList.remove('form-hidden')
            }
        }
    }

    const toggleApproved = (state: boolean) => (): void => {
        setValue(row.id, state)
        setKey(nanoid()) // rerender component
        toggleRejectReason()
    }

    useEffect(() => {
        setTimeout(toggleRejectReason, 100)
        toggleRejectReason()
    }, [getValues, setValue])

    return (
        <Controller
            key={key}
            name={row.id}
            control={control}
            // defaultValue={data.defaultValue}
            defaultValue={row.default || row.default === 'undefined' ? row.default : undefined}
            rules={row.required ? { required: `${row.content?.[0]} is required` } : {}}
            render={({ field }) => (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-2 m-0 p-0 w-100">
                        <div className="d-flex gap-2 align-items-center">
                            <input
                                className={'CustomCheckLinkInput'}
                                type="checkbox"
                                {...register(row.id, { required: row.required === true ? `${row.content?.[0]} is required` : undefined })}
                                style={{
                                    width: 0,
                                    zIndex: -1
                                }}
                            />
                            <a className={'text-truncate'} style={{ maxWidth: '80px' }} target="_blank" href={row.content[0]}
                                rel="noreferrer">{row.content[1]}</a>
                        </div>
                        <div className={'d-flex gap-2'}>
                            <span className={`btn btn-sm ${approved ? 'btn-bso' : 'btn-light'}`}
                                onClick={toggleApproved(true)}
                            >
                                {approved ? 'Approved' : 'Approve'}
                            </span>
                            <span className={`btn btn-sm ${!approved ? 'btn-bso' : 'btn-light'}`}
                                onClick={toggleApproved(false)}
                            >
                                {!approved ? 'Rejected' : 'Reject'}
                            </span>
                            <span className={'btn btn-sm btn-light'}
                                onClick={() => {
                                    void (async () => {
                                        await navigator.clipboard.writeText(row.content[2])
                                        await swalSuccess('Link ID has been copied to clipboard')
                                    })()
                                }}
                            >
                                <i className="las la-copy p-0"></i> ID
                            </span>
                        </div>
                    </div>
                </>
            )}
        />
    )
}

export default CustomCheckLink
