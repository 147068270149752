import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { type RootState } from '../../store/store'
import { setFilterNavigate } from '../../store/slices/scheduleSlice'
import Icon from '../../app/icon/Icon'
import { setMyArchivedUnitsOpen } from '../../store/slices/unitsSlice'

interface IProps {
    active: string
    filterCalendar?: string
    setFilterCalendar?: any
    allAttention?: any
    advertisesCount?: number
}

export default function LandlordHeader({
    allAttention,
    active,
    advertisesCount,
    setFilterCalendar,
    filterCalendar
}: IProps): JSX.Element {
    const navigate = useNavigate()

    const allAttentionNotifies = allAttention
        ? allAttention.filter((el: any) => {
            return el.attributes.Notify === 'Agent'
        })
        : []

    const isShown = useAppSelector((state: RootState) => state.voice.isShown)
    const dispatch = useAppDispatch()
    const filterNavigate = useAppSelector((state: RootState) => state.schedule.filterNavigate)
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const myArchivedUnitsOpen = useAppSelector((state) => state.units.myArchivedUnitsOpen)
    return (
        <>
            <div
                className="header position-fixed bg-white h-auto fw-bold d-flex flex-column shadow-sm px-4 py-2 pt-4"
                style={{
                    top: isShown ? '31px' : 0,
                    left: 0,
                    right: 0,
                    zIndex: 101
                }}
            >
                {userDevice === 'IPhone'
                    ? <div className="h-25px"></div>
                    : <></>
                }
                <div className="w-100 mw-450px mx-auto bg-white">
                    <div className="d-flex flex-column  w-100">
                        <div className="row w-100 g-0">
                            <div className="d-flex align-items-center justify-content-between max-h-30px">
                                <div className="d-flex align-items-center me-5 me-xl-13"></div>
                                {/* text-uppercase */}
                                <h2 className="my-2 text-start flex-grow-1">
                                    {active === 'Schedule' ? null : active}
                                </h2>
                                {/* active === 'Dashboard'
                                    ? (
                                        <>
                                            <div className="btn btn-sm px-2 ms-2">
                                                <Tutorials />
                                            </div>
                                            <Link
                                                to="/agent/profile/me"
                                                className="text-decoration-none"
                                            >
                                                <div className="btn btn-sm px-2 ms-2">
                                                    <i className="bi bi-person-circle fs-3x text-gray-400 m-0 p-0"></i>
                                                </div>
                                            </Link>
                                        </>
                                    )
                                    : null */}
                                {active === 'My referrals' ||
                                    active === 'Agents Leaderboard' ||
                                    active === 'Settings' ||
                                    active === 'Privacy Policy' ||
                                    active === 'Terms and Conditions' ||
                                    active === 'Tutorials' ||
                                    active === 'My Subscription' ||
                                    active === 'My Add-Ons' ||
                                    active === 'My Performance' ||
                                    active === 'Orders' ||
                                    active === 'My Membership' ||
                                    active === 'My clients'
                                    ? (
                                        <>
                                            <div
                                                // -1
                                                onClick={() => {
                                                    navigate('/landlord/menu')
                                                }}
                                                className="btn btn-sm px-2 ms-2"
                                            >
                                                <Icon
                                                    name={'arrow_circle_left'}
                                                    className={'text-muted fs-4x'}
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                {
                                    active === 'My units'
                                        ? <>
                                            <div
                                                onClick={() => {
                                                    dispatch(setMyArchivedUnitsOpen(!myArchivedUnitsOpen))
                                                }}
                                                className="btn btn-sm px-2 ms-2"
                                            >
                                                <Icon
                                                    name={'delete_24dp'}
                                                    className={`${myArchivedUnitsOpen ? 'text-dark' : 'text-muted'} fs-4x`}
                                                    filled={myArchivedUnitsOpen}
                                                />
                                            </div>
                                        </>
                                        : <></>
                                }
                                {
                                    active === 'Agent catalog' ||
                                        active === 'Viewing actions' ||
                                        active === 'Offer actions' ||
                                        active === 'Advertise actions' ||
                                    active === 'Tasks actions' ||
                                        active === 'Order a service'
                                        ? (
                                            <>
                                                <div
                                                    //
                                                    onClick={() => {
                                                        navigate(-1)
                                                    }}
                                                    className="btn btn-sm px-2 ms-2"
                                                >
                                                    <Icon
                                                        name={'arrow_circle_left'}
                                                        className={'text-muted fs-4x'}
                                                    />
                                                </div>
                                            </>
                                        ) : null}

                                {active === 'Chats'
                                    ? (
                                        <>
                                            <div className="btn btn-sm px-2 ms-2">
                                                <Icon
                                                    name={'arrow_circle_left'}
                                                    className={'text-muted fs-4x'}
                                                />
                                            </div>
                                        </>
                                    )
                                    : null}
                                {active === 'Chat' ? (
                                    <Link to="/chats" className="text-decoration-none ">
                                        {/* bi bi-arrow-left */}
                                        {/* bi bi-arrow-left-circle */}
                                        <div className="btn btn-sm px-2 ms-2">
                                            <Icon
                                                name={'arrow_circle_left'}
                                                className={'text-muted fs-4x'}
                                            />
                                        </div>
                                    </Link>
                                ) : null}
                                {active === 'Profile' ? (
                                    <div
                                        onClick={() => {
                                            navigate(-1)
                                        }}
                                        className="btn btn-sm px-2 ms-2"
                                    >
                                        <Icon
                                            name={'arrow_circle_left'}
                                            className={'text-muted fs-4x'}
                                        />
                                    </div>
                                ) // </Link>
                                    : null}

                                {/* {active === 'Schedule' ? ( */}
                                {/*    <> */}
                                {/*        <div className="d-flex px-2"> */}
                                {/*            /!* {filterNavigate === 'viewings' && (filterCalendar === 'full_calendar' */}
                                {/*                    ? <div onClick={() => { */}
                                {/*                        setFilterCalendar('current_day') */}

                                {/*                    } }> */}
                                {/*                        <i className="me-2 fs-4x bi bi-calendar3 text-dark"></i> */}

                                {/*                    </div> */}
                                {/*                    : filterCalendar === 'current_day' */}
                                {/*                        ? <div onClick={() => { */}
                                {/*                            setFilterCalendar('full_calendar') */}

                                {/*                        }}> */}

                                {/*                            <i className="me-2 fs-4x bi bi-calendar2-event text-dark"></i> */}
                                {/*                        </div> */}
                                {/*                        : null) } *!/ */}
                                {/*            <div */}
                                {/*                className="position-relative " */}
                                {/*                onClick={() => { */}
                                {/*                    //  setStatus('Advertise') */}
                                {/*                    dispatch(setFilterNavigate('adverts')) */}
                                {/*                }} */}
                                {/*            > */}
                                {/*                { */}
                                {/*                    advertisesCount && advertisesCount > 0 */}
                                {/*                        ? ( */}
                                {/*                            <span className="fs-8 p-0 me-0 position-absolute top-25 start-75 translate-middle badge badge-circle badge-primary"> */}
                                {/*                                {advertisesCount} */}
                                {/*                            </span> */}
                                {/*                        ) */}
                                {/*                        : null} */}
                                {/*                <i */}
                                {/*                    className={`fs-4x ${ */}
                                {/*                        filterNavigate === 'adverts' */}
                                {/*                            ? 'text-dark' */}
                                {/*                            : '' */}
                                {/*                    }  bi bi-badge-ad-fill me-3`} */}
                                {/*                ></i> */}
                                {/*            </div> */}

                                {/*            <div */}
                                {/*                className="position-relative " */}
                                {/*                onClick={() => { */}
                                {/*                    //    setStatus('Attention') */}
                                {/*                    dispatch(setFilterNavigate('attention')) */}
                                {/*                }} */}
                                {/*            > */}
                                {/*                {allAttentionNotifies && */}
                                {/*                allAttentionNotifies.length > 0 */}
                                {/*                    ? ( */}
                                {/*                        <span className="fs-8 p-0 m-0 position-absolute top-25 start-100 translate-middle badge badge-circle badge-danger"> */}
                                {/*                            {allAttentionNotifies.length} */}
                                {/*                        </span> */}
                                {/*                    ) */}
                                {/*                    : null} */}

                                {/*                <i */}
                                {/*                    className={`fs-4x ${ */}
                                {/*                        filterNavigate === 'attention' */}
                                {/*                            ? 'text-dark' */}
                                {/*                            : '' */}
                                {/*                    }  bi bi-fire`} */}
                                {/*                ></i> */}
                                {/*            </div> */}
                                {/*        </div> */}
                                {/*    </> */}
                                {/* ) : null} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="" style={{ height: userDevice === 'IPhone' ? '80px' : '55px' }}></div>
            {/* <h1 className="marquee  m-0 p-0 bg-bso"><span>This is a marquee. Text, text, text...</span></h1> */}
        </>
    )
}
