import React, { type ReactElement } from 'react'
import FormListForMyCustomForView from '../myCostomFormForViewOnli/FormListForMyCustomForView'
import { AgentModals } from '../../../functions/agent/AgentModals'
import MyCarousel from '../../MyCarousel'
import { useAppSelector } from '../../../hooks/redux'
import GeoForMyCustomModalForView from '../myCostomFormForViewOnli/GeoForMyCustomModalForView'

interface CustomModalUnitInfoProps {
    unitID: number
}

export default function CustomModalUnitInfo({ unitID }: CustomModalUnitInfoProps): ReactElement {
    const units = useAppSelector((state) => state.units.units)
    const currentUnit = units.find(unitItem => unitItem.id === unitID)
    return currentUnit != null
        ? <div className="card d-flex mh-100 ">
            <div className="fv-row my-2 m-0 p-0 w-100">
                <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
            </div>
            <GeoForMyCustomModalForView googleMapsLink={currentUnit?.attributes?.Property?.data?.attributes?.GoogleMapsLink ?? null} />
            <div className="fv-row my-2 m-0 p-0 w-100">
                <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
            </div>
            <FormListForMyCustomForView
                form={
                    AgentModals().find((el: any) => el.id === 'viewing_modal').form
                }
                response={currentUnit}
            />
            <div className="fv-row my-2 m-0 p-0 w-100" />
            <MyCarousel dots={true} autoScrol={true} showTwoColumns={false}>
                {currentUnit?.attributes.Images != null
                    ? (
                        currentUnit?.attributes.Images?.map((el: string) => (
                            <div key={`${el}`} className="text-center">
                                <div
                                    className="w-100 h-250px border border-gray-500 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center position-relative"
                                    style={{ backgroundImage: `url(${el})` }}
                                >
                                    <div className="h-200px w-100"></div>
                                </div>
                            </div>
                        ))
                    )
                    : (
                        <div>Not images</div>
                    )}
            </MyCarousel>
            <div style={{ height: '70px' }}></div>
        </div>
        : <></>
}
