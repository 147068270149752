import { type AppDispatch, type RootState } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { landlordAddUnitWriteToStorage } from '../utils/landlordAddUnitHandleStorage'
import { type NavigateFunction } from 'react-router-dom'
import { addUnit } from './addUnit'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'

export const addUnitMarketingSpecialRequests = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    try {
        landlordAddUnitWriteToStorage('unit_marketing_special_requests', data)
        return {
            textNotification: '',
            textNavigate: `/landlord/modal/landlord_create_unit_marketing/${nanToNull(unitID) ?? '-'}/-`,
            isSuccessful: true
        }
    } catch (err) {
        console.log('Error while adding additional unit info', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export const skipAddUnitMarketingSpecialRequests = (navigate: NavigateFunction, unitID?: number) => async () => {
    navigate(`/landlord/modal/landlord_create_unit_marketing/${nanToNull(unitID) ?? '-'}/-`)
}
