import React, {type ReactElement} from "react";
import {useParams} from "react-router";
import {useAppSelector} from "../../../../../hooks/redux";
import Badge from "../../../../../components/shared/UIv2/Badge/Badge";
import dayjs from "dayjs";

interface UserWaitingForApproveProps {
    row: Record<string, any>
}

export default function UserWaitingForApprove ({row}: UserWaitingForApproveProps): ReactElement {
    const { objectID } = useParams()
    const users = useAppSelector((state) => state.user.allUsers)
    const user =  users.find(userItem => userItem.id === Number(objectID))
    const firstName = user?.ContactInfo?.FirstName
    const lastName = user?.ContactInfo?.FamilyName
    const firstNamePlaceholder = firstName != null ? `${firstName[0]}${'*'.repeat(firstName.length - 1)}`: undefined
    const lastNamePlaceholder = lastName != null ? `${lastName[0]}${'*'.repeat(lastName.length - 1)}` : undefined
    const birthDate = user?.ContactInfo?.DateOfBirth
    const age = birthDate != null ? dayjs().diff(dayjs(birthDate), 'years') : undefined
    const gender = user?.ContactInfo?.Gender != null ? user?.ContactInfo?.Gender : undefined
    return (
        <div className={'d-flex align-items-center justify-content-between bg-gray-100 border border-gray-300 p-8'}
            style={{
                borderRadius: '16px'
            }}
        >
            <div className={'d-flex flex-column gap-1'}>
                <div className={'d-flex gap-3 fs-2x fw-bold'}>
                    <div>
                        {
                            firstNamePlaceholder
                        }
                    </div>
                    <div>
                        {
                            lastNamePlaceholder
                        }
                    </div>
                </div>
                <div className={'d-flex gap-3'}>
                    <Badge
                        size={'25'}
                        style={'gray-400'}
                        text={`${age ?? '-'} yo`}
                    />
                    <Badge
                        size={'25'}
                        style={'gray-400'}
                        text={gender ?? '-'}
                    />
                </div>
            </div>
            <div>
                <img
                    className={'spinning'}
                    src="/assets/media/landlord/create_unit/spinner.svg"
                    alt="spinner"
                    style={{
                        height: '48px',
                        width: '48px'
                    }}
                />
            </div>
        </div>
    )
}