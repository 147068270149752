import React, {type ReactElement} from "react";
import LinkButton from "../../../../../components/shared/UIv2/Button/LinkButton";
import {useNavigate} from "react-router-dom";

interface ButtonLinkProps {
    row: Record<string, any>
}

export default function ButtonLink ({row}: ButtonLinkProps): ReactElement {
    const navigate = useNavigate()
    return (
        <div className={'w-100 mb-8'}>
            <LinkButton
                size={'48'}
                text={row.content[0]}
                style={'black'}
                onClick={() => {
                    navigate(row.href)
                }}
            />
        </div>
    )
}