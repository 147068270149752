import React, { type ReactElement, useState } from 'react'
import Icon from '../../../app/icon/Icon'
import { type Unit } from '../../../store/slices/unitsSlice'

import { useAppDispatch } from '../../../hooks/redux'
import { useNavigate } from 'react-router'
import { archiveUnit } from '../../../store/actionsCreators/unitsActions'

interface UnitArchiveButtonShProps {
    unit: Unit
}

export default function UnitArchiveButtonSh({ unit }: UnitArchiveButtonShProps): ReactElement {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const unitIsAvailable = !(unit?.attributes?.Statuses?.includes('Archived'))
    return <div className={`position-absolute top-0 start-0 p-2 pt-2 pe-2 m-0 w-100 ${unitIsAvailable ? 'text-muted' : 'text-dark'}`} style={{ zIndex: 49 }}>
        {
            !loading
                ? <div className={'p-2'}
                    onClick={() => {
                        setLoading(true)
                        dispatch(archiveUnit(unit.id, navigate))
                            .finally(() => {
                                setLoading(false)
                            })
                    }}
                >
                    <Icon name={'delete_24dp'} className={'fs-4x'} />
                </div>
                : <span className="indicator-progress d-block">
                    <span className={'spinner-border spinner-border-sm align-middle p-4 m-2'}></span>
                </span>
        }
    </div>
}
