import React from 'react'
import { nanoid } from 'nanoid'

import AdvertiseCard from './AdvertiseCard'

export default function AdvertiseListItem (props: any): JSX.Element {
    return (
        <>
            {props.advertisements && props.advertisements.length > 0
                ? (
                    Array.from(new Set(props.advertisements.map((advertise: any) => (new Date(advertise?.attributes?.createdAt) as any).toDateString())))
                        .sort((a: any, b: any) => (new Date(a) as any) - (new Date(b) as any))
                        .map((dateString: any, index: number) => {
                            return (
                                < div key={nanoid()}>
                                    {props.advertisements
                                        .filter((advertise: any) => (new Date(advertise?.attributes?.createdAt) as any).toDateString() === dateString)
                                        .map((advertise: any, index: number) => (
                                            <div
                                                key={nanoid()}
                                                className="card shadow-sm mb-2 mt-1 position-relative"
                                            >
                                                <AdvertiseCard key={nanoid()} advertise={advertise}/>
                                            </div>
                                        ))}

                                </div>
                            )
                        })
                )
                :
            // <div key={nanoid()} className="card shadow-sm mb-2 position-relative" />
                null
            }
        </>

    )
}
