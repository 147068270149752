import React, {type ReactElement} from "react";
import {Controller} from "react-hook-form";
import FileInput from "../../../../../components/shared/UIv2/Input/FileInput";
import Icon from "../../../../icon/Icon";
import {useAppSelector} from "../../../../../hooks/redux";

interface InputFileProps {
    row: Record<string, any>
    control: any
}

export default function InputFile ({row, control}: InputFileProps): ReactElement {
    const modalLoading = useAppSelector(state => state.modal.loading)
    const authLoading = useAppSelector(state => state.auth.loading)

    const loading = modalLoading || authLoading

    return <Controller
        name={row.id}
        control={control}
        rules={{
            required: row.required === true ? 'Field is required' : undefined
        }}
        render={({field, fieldState: { error }}) => {
            return (
                <div className={'d-flex flex-column gap-3 mb-8'}>
                    {row.content[0] != null
                        ? <div className={'fs-5 fw-medium'}>
                            {row.content[0]}
                        </div>
                        : <></>
                    }
                    <FileInput
                        placeholder={row.content[1] ?? 'Upload a file'}
                        multiple={row.multiple === true}
                        value={field.value}
                        onChange={(fileList) => {
                            field.onChange(fileList)
                            if (row.submitOnInput === true && fileList != null && fileList?.length > 0 && !loading) {
                                const submitButton: HTMLButtonElement | null = document.querySelector('button[type="submit"]')
                                submitButton?.click()
                            }
                        }}
                        loading={
                            row.submitOnInput === true
                                ? loading
                                : false
                        }
                    />
                    { error != null
                        ? <div className={'d-flex align-items-center justify-content-between text-danger fs-5 fw-medium'}>
                            <div>
                                {error.message}
                            </div>
                            <Icon name={'error'} className={'fs-1'} imageSize={48}/>
                        </div>
                        : <>
                        </>
                    }
                </div>
            )
        }}
    />
}