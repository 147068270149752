import { useParams } from 'react-router'
import React, { type ReactElement } from 'react'
import {
    landlordAddUnitReadFromStorage
} from '../../../../store/actionsCreators/unitActions/landlordUnitsActions/utils/landlordAddUnitHandleStorage'
import { type IconName } from '../../../../app/icon/iconsConfig'

interface ICreateUnitModalData {
    currentStep: number
    stepsCount: number
    title: string
    description: ReactElement
    descriptionSteps: ReactElement[]
    iconName: IconName
}

export default function useCreateUnitModalInfo (): ICreateUnitModalData {
    const { modalID } = useParams()
    const storedData = landlordAddUnitReadFromStorage()
    const data: ICreateUnitModalData = {
        currentStep: 0,
        stepsCount: 0,
        title: '',
        description: <></>,
        descriptionSteps: [],
        iconName: 'home'
    }
    switch (modalID) {
        case 'landlord_create_unit':
            data.currentStep = 1
            data.stepsCount = 7
            data.title = 'Title Deed Details'
            data.description = <>
                Please scan and upload the <b>Title Deed</b>. Ensure it&apos;s clear and legible.
            </>
            data.descriptionSteps = [
                // <><b>Step 1:</b> Do something</>,
                // <><b>Step 2:</b> Do something else</>,
                // <><b>Step 3:</b> End the process</>
            ]
            data.iconName = 'scan'
            break
        case 'landlord_create_unit_info':
            if (storedData.owners_passport != null) {
                data.currentStep = 5
                data.stepsCount = 10
            } else {
                data.currentStep = 2
                data.stepsCount = 7
            }
            data.title = 'Property Info'
            data.description = <>
                Provide the main <b>property details</b> such as the <b>building name</b>, <b>area</b>, and a <b>Google Maps link</b> for precise location.
            </>
            data.descriptionSteps = []
            data.iconName = 'home'
            break
        case 'landlord_create_unit_owners_passport':
            data.currentStep = 2
            data.stepsCount = 10
            data.title = 'Owner documents (passport)'
            data.description = <>
                Upload the owner&apos;s <b>passport</b> and fill the <b>contact information</b>.
            </>
            data.descriptionSteps = []
            data.iconName = 'description'
            break
        case 'landlord_create_unit_correct_owner_info':
            data.currentStep = 3
            data.stepsCount = 10
            data.title = 'Correct scanned owner data'
            data.description = <>
                <b>Correct</b> the data that has been scanned.
            </>
            data.descriptionSteps = []
            data.iconName = 'edit'
            break
        case 'landlord_create_unit_poa':
            data.currentStep = 4
            data.stepsCount = 10
            data.title = 'Owner documents (POA)'
            data.description = <>
                Upload the owner&apos;s <b>Power Of Attorney</b> document.
            </>
            data.descriptionSteps = []
            data.iconName = 'description'
            break
        case 'landlord_create_main_info_first':
            if (storedData.owners_passport != null) {
                data.currentStep = 6
                data.stepsCount = 10
            } else {
                data.currentStep = 3
                data.stepsCount = 7
            }
            data.title = 'Unit information'
            data.description = <>
                Enter the <b>unit</b> details, including <b>type</b>, <b>unit number</b>, and <b>size</b>
            </>
            data.descriptionSteps = []
            data.iconName = 'square_foot'
            break
        case 'landlord_create_main_info_second':
            if (storedData.owners_passport != null) {
                data.currentStep = 7
                data.stepsCount = 10
            } else {
                data.currentStep = 4
                data.stepsCount = 7
            }
            data.title = 'Unit Configuration'
            data.description = <>
                Provide <b>more details</b> about unit, including the number of <b>bedrooms</b>, <b>bathrooms</b>, etc.
            </>
            data.descriptionSteps = []
            data.iconName = 'bed'
            break
        case 'landlord_create_unit_additional_info':
            if (storedData.owners_passport != null) {
                data.currentStep = 8
                data.stepsCount = 10
            } else {
                data.currentStep = 5
                data.stepsCount = 7
            }
            data.title = 'Unit Details (Indoor)'
            data.description = <>
                Describe the <b>indoor environment</b>, including the <b>number of A/c units</b>, <b>fitting quality</b>, etc.
            </>
            data.descriptionSteps = []
            data.iconName = 'mode_fan'
            break
        case 'landlord_create_unit_additional_info_outdoor':
            if (storedData.owners_passport != null) {
                data.currentStep = 9
                data.stepsCount = 10
            } else {
                data.currentStep = 6
                data.stepsCount = 7
            }
            data.title = 'Unit Details (Outdoor)'
            data.description = <>
                Describe the <b>outdoor environment</b>, including the <b>outdoor features</b>, <b>available amenities</b>, etc.
            </>
            data.descriptionSteps = []
            data.iconName = 'nature_people'
            break
        case 'landlord_create_unit_additional_info_files':
            if (storedData.owners_passport != null) {
                data.currentStep = 10
                data.stepsCount = 10
            } else {
                data.currentStep = 7
                data.stepsCount = 7
            }
            data.title = 'Add unit visual info'
            data.description = <>
                Upload any other <b>files</b> related to the unit, such as <b>photos</b>, <b>video</b>, or <b>floor plan</b>
            </>
            data.descriptionSteps = []
            data.iconName = 'gallery_thumbnail'
            break
        case 'landlord_create_unit_special_requests':
            data.currentStep = 1
            data.stepsCount = 2
            data.title = 'Add unit special requests'
            data.description = <>
                Specify the <b>special requirements</b> for tenants of your unit.
            </>
            data.descriptionSteps = []
            data.iconName = 'info'
            break
        case 'landlord_create_unit_marketing':
            data.currentStep = 2
            data.stepsCount = 2
            data.title = 'Add unit marketing info'
            data.description = <>
                Provide a <b>catchy headline</b>, <b>intended use</b>, and <b>listing price</b>.
            </>
            data.descriptionSteps = []
            data.iconName = 'campaign'
            break
        default:
            break
    }
    return data
}
