import { type AppDispatch, type RootState } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { createDocument } from '../../../documentActions'
import dayjs from 'dayjs'
import { createNewTask } from '../../../taskActions/createNewTask'
import { landlordAddUnitReadFromStorage } from '../utils/landlordAddUnitHandleStorage'
import { addUnit } from './addUnit'
import { nanoid } from 'nanoid'
import { type NavigateFunction } from 'react-router-dom'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'

export const requestValidation = (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    const unitResponse = await dispatch(addUnit(undefined, 'title_deed_step'))
    const unitID = Number(unitResponse?.response?.data?.id)
    const storedData = landlordAddUnitReadFromStorage()
    const poaFileList = data.poa
    const documents: number[] = []
    if (poaFileList != null && poaFileList.length > 0) {
        const RefID = `Power of Attorney-${nanoid()}`
        const poaResponse = await dispatch(createDocument(poaFileList, {
            RefID,
            Type: [
                {
                    __component: 'documents.power-of-attorney'
                }
            ]
        }, undefined, undefined, undefined, RefID))
        documents.push(poaResponse?.response?.data?.id)
    }
    const passportsFileList = [...data.passports]
    const passportPromises = passportsFileList.map(async (passportFile: File) => {
        const RefID = `Passport-${nanoid()}`
        const passportResponse = await dispatch(createDocument([passportFile], {
            RefID,
            Type: [
                {
                    __component: 'documents.passport'
                }
            ]
        }, undefined, undefined, undefined, RefID))
        documents.push(passportResponse?.response?.data?.id)
    })
    await Promise.all(passportPromises)

    documents.push(storedData?.title_deed?.id)

    const taskResponse = await dispatch(createNewTask({
        AssignedTo: Number(process.env.REACT_APP_BSO_USER_ID),
        Documents: documents.filter(Boolean),
        Type: 'Document Verification',
        Statuses: [
            'To do'
        ],
        Unit: unitID,
        StartDate: dayjs()
    }))
    dispatch(dataFetchingFinish())
    // landlordAddUnitClearStorage()
    return {
        ...taskResponse,
        isSuccessful: taskResponse?.response?.error == null && taskResponse?.success === true,
        textNotification: 'Documents will be verified by BSO as soon as possible',
        textNavigate: `/landlord/modal/landlord_create_unit_info/${unitID}/-`
    }
}

export const goBackRequestValidation = (navigate: NavigateFunction, unitID?: number) => () => {
    navigate(`/landlord/modal/landlord_create_unit/${nanToNull(unitID) ?? '-'}/-`)
}
