import React, {type ReactElement} from "react";
import RadioButton from "../../../../components/shared/UIv2/RadioButton/RadioButton";

interface FooterCountProps {
    row: Record<string, any>
    modal: Record<string, any>
    control: any
    setValue: (...args: any) => void
    watch: any
}

export default function FooterCount ({row, control, modal, setValue, watch}: FooterCountProps): ReactElement {
    const addonsRow = modal?.form?.find((row: any) => row.element === 'service-addons')
    const allAddons = addonsRow?.addons?.map((addon: any) => addon.id) ?? []
    const preselectedAddons = addonsRow?.preselected ?? []
    const addonsCount = watch(addonsRow.id)?.length ?? 0
    const allSelected = allAddons?.length === addonsCount
    // console.log(watch())
    return (
        <div className={'d-flex justify-content-between align-items-center pt-5'}>
            <div className={'d-flex align-items-center gap-3'}
                onClick={() => {
                    if (allSelected) {
                        setValue(addonsRow.id, preselectedAddons)
                    } else {
                        setValue(addonsRow.id, allAddons)
                    }
                }}
            >
                <RadioButton isActive={allSelected}/>
                <div className={'fs-3 text-gray-500'}>
                     All
                </div>
            </div>

            <div className={'fs-3'}>
                {addonsCount} {addonsCount === 1 ? 'item' : 'items'} selected
            </div>
        </div>
    )
}