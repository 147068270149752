import {type AppDispatch, type RootState} from "../../../../store";
import {dataFetchingFinish, dataFetchingStart} from "../../../../slices/modalSlice";
import {scanDocuments} from "../../../documentActions";
import {nanoid} from "nanoid";
import {landlordAddUnitWriteToStorageV2} from "../utils/landlordAddUnitHandleStorageV2";

export const landlordCreateUnitOwnerPassport = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    try {
        console.log(data)
        const fileList = data.visa
        if (fileList != null && fileList?.length > 0) {
            const passportResponse = await dispatch(scanDocuments(fileList, 'Passport', true, `RefDOC-Passport-${nanoid()}`, 'users'))
            console.log(passportResponse)
            const passportID = passportResponse[0]?.id
            const passport = getState().documents.documents.find((doc) => doc.id === passportID)
            const data = {
                ...(passport?.attributes?.Type?.[0] ?? {}),
                id: passportID
            }
            landlordAddUnitWriteToStorageV2('landlord_create_unit_owner_passport', data)
            return {
                isSuccessful: true,
                textNavigate: '/landlord/modal/v2/landlord_create_unit_id_verification/-/-',
                textNotification: ''
            }
        }
    } catch (err) {
        console.log('Error while owner passport scanning', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false,
        textNotification: 'Passport scanning has failed. Try again. Make sure passport photo is clear'
    }
}