import React, {type ReactElement} from "react";

interface SeparatorProps {
    color?: 'secondary' | 'gray-400'
}

export default function Separator ({color = 'secondary'}: SeparatorProps): ReactElement {
    return <div className={'h-100 w-100 d-flex align-items-center'}>
        <div className={`bg-${color} w-100`} style={{height: '1px'}}/>
    </div>
}