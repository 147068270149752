import React from 'react'
import LandlordHeader from '../../LandlordHeader'
import ActionCard from '../actionCard'
import { useAppSelector } from '../../../../hooks/redux'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

const TasksActions = (): JSX.Element => {
    const { taskID } = useParams()
    const navigate = useNavigate()

    const tasks = useAppSelector((state) => state.tasks.tasks)
    const task = tasks.find((task) => task.id === Number(taskID))
    const status = task?.attributes?.Statuses?.[0]
    const type = task?.attributes?.Type
    return <>
        <LandlordHeader active={'Tasks actions'}/>
        <div className={'d-flex flex-column gap-4 p-4'}>
            { type === 'Document Verification' &&
                <>
                    { (status === 'To do') &&
                        <ActionCard
                            title={'Verify document'}
                            description={'Check that document is valid and fill the missing fields'}
                            onClick={() => {
                                navigate(`/landlord/set_unit_modal/validate_unit/${String(task?.attributes?.Unit?.data?.id)}/${String(task?.id)}`)
                            }}
                            icon={'check_circle'}
                        />
                    }
                </>
            }
        </div>
    </>
}

export default TasksActions
