
import getAgentStatuses from '../../functions/agent/documents/checkDocumentsForAproved'
import { getErrors } from '../../functions/errors/GetErrors'
import { setStatuses } from '../slices/agentSlice'
import { setReferrals } from '../slices/userSlice'

import { urlAPI, ACCESS_KEY, USER_ID_KEY, USER_AREAS_EXTEND_COUNT } from '../../urls'
import { type ISettings, userSlice, type User } from '../slices/userSlice'
import { store, type AppDispatch, type RootState } from '../store'

import { modalSlice, setIsAreasOpen, setIsPreviousPathChooseAreas, setShowPopupAddOnsAreas } from '../slices/modalSlice'

import { type ApiResponse } from '../../types/userTypes/TAuth'
import qs from 'qs'
import { setPaginationLoading, setStopPagination } from '../slices/paginationSlice'
import _ from 'lodash'
import customLog from '../../functions/log'
import { getPropertyType } from '../../functions/getDataForSelects/dataFunctions'
import { getCurrentDubaiDate } from '../../functions/agent/date/getCurrentDateStrFormat'
import { getQueryAllUsers } from '../../functions/shared/api/queries/getQueryAllUsers'
import { getQueryCurrentUser } from '../../functions/shared/api/queries/getQueryCurrentUser'
import { checkIfKeyExist } from '../../functions/utils/checkIfKeyExist'
import { customSendNotify } from '../../functions/shared/notify-helpers/customSendNotify'
import { subtractDays } from '../../functions/shared/date/subtractDays'
import { toUTCWithOffset } from '../../functions/agent/date/toUTCWithOffset'

export const updateUserTokenDevice = (deviceToken: string) => async (dispatch: AppDispatch) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userDevice = store.getState().app.userDevice
    const currToken = store.getState().user.currentUser.DeviceToken

    if (deviceToken === currToken) return

    try {
        const sendObject = {
            method: 'PUT',
            collection: 'users',
            id: Number(userID),
            body: {
                DeviceToken: deviceToken ?? null,
                DeviceType: userDevice === 'IPhone' ? 'Iphone' : userDevice === 'Android' ? 'Android' : ''
            }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                dispatch(userSlice.actions.userFetchingError(getErrors(responseJSON)))
                return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrors(responseJSON) }
            } else {
                dispatch(userSlice.actions.userUpdate(responseJSON.response))
                return { ...responseJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(userSlice.actions.userFetchingError(getErrors(responseJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        return { textNavigate: '', isSuccessful: false, textNotification: '' }
    }
}

export const addTokenDevice = async (userID: any, androidDeviceToken?: string): Promise<void> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userIDLoc = window.localStorage.getItem(USER_ID_KEY)

    const userDevice = store.getState().app.userDevice

    const storageToken = (window as unknown as Window & { devicetoken: string }).devicetoken || window.localStorage.getItem('devicetoken')

    const deviceToken = ((userDevice === 'IPhone')
        ? storageToken
        : androidDeviceToken) ?? null

    const currToken = store.getState().user.currentUser.DeviceToken
    if(userID === process.env.REACT_APP_BSO_USER_ID) {
        if(deviceToken?.includes("BrowserToken") || deviceToken?.includes("BrowserTokenUnfound"))  {
            return
        }
    }
    if (deviceToken === currToken) return
    try {
        const sendObject = {
            method: 'PUT',
            collection: 'users',
            id: Number(userID ?? userIDLoc),
            body: {
                DeviceToken: deviceToken ?? currToken,
                DeviceType: userDevice === 'IPhone' ? 'Iphone' : userDevice === 'Android' ? 'Android' : ''
            }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        await response.json() as unknown as ApiResponse
    } catch (error) {
        console.log(error)
    }
}

export const fetchUser = (noLading?: boolean) => async (dispatch: AppDispatch) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    try {
        if (noLading !== true) {
            dispatch(userSlice.actions.userFetching())
        }

        const bodyObject = {
            method: 'GET',
            collection: 'users',
            id: Number(userID),
            query: getQueryCurrentUser()

        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })

        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(userSlice.actions.userFetchingError(getErrors(dataJSON)))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrors(dataJSON) }
            } else {
                dispatch(userSlice.actions.userFetchingSuccess(dataJSON.response))
                dispatch(modalSlice.actions.setIsUserVerified(dataJSON?.response?.emailVerified))

                if (dataJSON.response?.Category === 'Agent') {
                    dispatch(setStatuses(getAgentStatuses(dataJSON.response?.Documents)))
                }

                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(userSlice.actions.userFetchingError(getErrors(dataJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(userSlice.actions.userFetchingError((JSON.stringify(error))))
        return { textNavigate: '', isSuccessful: false, textNotification: '' }
    }
}

export const fetchUsers = (noLading?: boolean) => async (dispatch: AppDispatch) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)

    if (noLading !== true) {
        dispatch(userSlice.actions.userFetching())
    }
    try {
        const bodyObject = {
            method: 'GET',
            collection: 'users',
            query: getQueryAllUsers()

        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })

        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(userSlice.actions.userFetchingError(getErrors(dataJSON)))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrors(dataJSON) }
            } else {
                dispatch(userSlice.actions.allUsersFetchingSuccess(dataJSON.response))

                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(userSlice.actions.userFetchingError(getErrors(dataJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(userSlice.actions.userFetchingError(JSON.stringify(error)))
        return { textNavigate: '', isSuccessful: false, textNotification: '' }
    }
}

export const fetchUserById = (userID: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<Record<string, any> | null> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    try {
        const bodyObject = {
            method: 'GET',
            collection: 'users',
            query: getQueryCurrentUser(),
            id: userID

        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })

        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                return null
            } else {
                dispatch(
                    userSlice.actions.allUsersFetchingSuccess(
                        [
                            ...getState().user.allUsers.filter(user => user.id !== userID),
                            dataJSON.response
                        ]
                    )
                )
                return dataJSON.response
            }
        } else {
            return null
        }
    } catch (error) {
        console.log(error)
        return null
    }
}

export const addAgentSettings = (formValues: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log(formValues, 'formValues addAgentSettings')
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const currentUser = getState().user.currentUser
    const userSettings = (currentUser.Settings as ISettings[] | null)?.at(0)

    const currentDubaiDate = getCurrentDubaiDate()
    const afterMonth = toUTCWithOffset(currentDubaiDate, -43200)

    const afterMonthMinusThreeDays = toUTCWithOffset(subtractDays(currentDubaiDate, 3), -43200)

    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        const sendObject = {
            method: 'PUT',
            collection: 'users',
            id: Number(userID),
            query: getQueryCurrentUser(),
            body: {
                Settings:
                 [{
                     ...userSettings,
                     __component: 'settings.agent',
                     Type: getPropertyType(),
                     Areas: formValues.locations ?? formValues.locations_extension ?? userSettings?.Areas
                 }]
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrors(dataJSON) }
            } else {
                dispatch(userSlice.actions.userFetchingSuccess(dataJSON.response))
                dispatch(modalSlice.actions.dataFetchingFinish())
                if (!(checkIfKeyExist(formValues, 'modalID') && formValues.modalID === 'agent_settings_for_subscriptions')) {
                    const bodyNotify = {
                        mode: 'publish',
                        RuleID: undefined,
                        User: Number(userID),
                        Text: 'Attention,area extention!',
                        Type: 'Mobile',
                        RuleData: getState().areas.extendAreas,
                        publishedAt: null
                    }
                    await customSendNotify({
                        ...bodyNotify,
                        executeAt: afterMonth,
                        Rule: 'user-area-extend'
                    })

                    await customSendNotify({
                        ...bodyNotify,
                        executeAt: afterMonthMinusThreeDays,
                        Rule: 'user-area-extend-headsup'
                    })

                    dispatch(setShowPopupAddOnsAreas(false))
                    dispatch(setIsAreasOpen(false))
                    window.localStorage.removeItem(USER_AREAS_EXTEND_COUNT)
                }

                dispatch(setIsPreviousPathChooseAreas(true))

                return { ...dataJSON, textNavigate: formValues.redirect ? formValues.redirect : '/agent/units/nav', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(modalSlice.actions.dataFetchingFinish())
    }
}

export const fetchSocketUser = (entryID: number, update: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    if (update && !_.some(getState().user.profiles, { id: entryID })) {
        return
    }
    try {
        const query: any = {
            populate: ['ContactInfo', 'Settings', 'Type', 'Documents'],
            filters: {
                $and: [
                    {
                        ContactInfo: getState().user.searchString !== ''
                            ? {
                                $or: [
                                    {
                                        FirstName: {
                                            $contains: getState().user.searchString
                                        }
                                    },
                                    {
                                        FamilyName: {
                                            $contains: getState().user.searchString
                                        }
                                    }
                                ]
                            }
                            : undefined
                    },
                    {
                        id: entryID
                    }
                ]
            }
        }
        const stringSplit = getState().user.searchString.trim().split(' ')
        if (stringSplit.length > 1) {
            query.filters.$and[0] = {
                $or: [
                    {
                        ContactInfo: {
                            FirstName: {
                                $eqi: stringSplit[0]
                            },
                            FamilyName: {
                                $contains: stringSplit[1]
                            }
                        }
                    },
                    {
                        ContactInfo: {
                            FamilyName: {
                                $eqi: stringSplit[0]
                            },
                            FirstName: {
                                $contains: stringSplit[1]
                            }
                        }
                    }
                ]
            }
        }

        const bodyObject = {
            method: 'GET',
            collection: 'users',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })

        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })

        const dataJSON = await response.json()

        if (dataJSON.success === true) {
            if (dataJSON.response.error != null) {
                dispatch(userSlice.actions.userFetchingError(getErrors(dataJSON)))
                // return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrors(dataJSON) }
            } else {
                const fetchedProfiles = dataJSON.response
                const storedProfiles = _.cloneDeep(getState().user.profiles ?? [])
                if (!update) {
                    const profiles = [...fetchedProfiles, ...storedProfiles]
                    dispatch(userSlice.actions.setProfiles(profiles))
                } else {
                    const index = _.findIndex(storedProfiles, { id: entryID })
                    if (index !== -1) {
                        storedProfiles[index] = fetchedProfiles[0]
                        dispatch(userSlice.actions.setProfiles(storedProfiles))
                    }
                }
                // dispatch(setPageCount(pageCount))
                // return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(userSlice.actions.userFetchingError(getErrors(dataJSON)))
            // return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        // dispatch(userSlice.actions.userFetchingError(error))
    }
}

export const fetchSearchedUsers = (page: number, callback?: () => void, fromSearch?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().pagination.stopPagination && fromSearch !== true) {
        console.log('STOP PAGINATION', getState().pagination.stopPagination)
        return
    }
    dispatch(setPaginationLoading(true))
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const pageSize = 20
    /* if (getState().user.searchString === '') {
        console.log('EMPTY SEARCH')
        dispatch(userSlice.actions.setProfiles([]))
        return
    } */
    try {
        const query: any = {
            populate: ['ContactInfo', 'Settings', 'Type', 'Documents'],
            filters: {
                ContactInfo: getState().user.searchString !== ''
                    ? {
                        $or: [
                            {
                                FirstName: {
                                    $contains: getState().user.searchString.trim()
                                }
                            },
                            {
                                FamilyName: {
                                    $contains: getState().user.searchString.trim()
                                }
                            }
                        ]
                    }
                    : undefined
            },
            start: (page - 1) * pageSize,
            limit: pageSize
        }
        const stringSplit = getState().user.searchString.trim().split(' ')
        if (stringSplit.length > 1) {
            query.filters = {
                $or: [
                    {
                        ContactInfo: {
                            FirstName: {
                                $eqi: stringSplit[0]
                            },
                            FamilyName: {
                                $contains: stringSplit[1]
                            }
                        }
                    },
                    {
                        ContactInfo: {
                            FamilyName: {
                                $eqi: stringSplit[0]
                            },
                            FirstName: {
                                $contains: stringSplit[1]
                            }
                        }
                    }
                ]
            }
        }
        customLog('QUERY', query)

        const bodyObject = {
            method: 'GET',
            collection: 'users',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })

        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })

        const dataJSON = await response.json()

        if (dataJSON?.success === true) {
            if (callback != null) {
                callback()
            }
            // const pageCount = getChatPageCount(dataJSON)
            if (dataJSON?.response?.error != null) {
                // dispatch(userSlice.actions.userFetchingError(getErrors(dataJSON)))
                // return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrors(dataJSON) }
            } else {
                const storedProfiles = getState().user.profiles ?? []
                const fetchedProfiles = _.filter(dataJSON.response, (profile: any) => !_.some(storedProfiles, { id: profile.id }))
                if (fetchedProfiles.length < 10) {
                    dispatch(setStopPagination(true))
                }
                const profiles = [...storedProfiles, ...fetchedProfiles]
                dispatch(userSlice.actions.setProfiles(profiles))
                // dispatch(setPageCount(pageCount))
                // return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(userSlice.actions.userFetchingError(getErrors(dataJSON)))
            // return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        // dispatch(userSlice.actions.userFetchingError(error))
    }

    dispatch(setPaginationLoading(false))
}

export const getReferrals = () => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean }> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''

        const query = {
            populate: ['Orders', 'ReferredBy', 'ContactInfo', 'Documents'],
            filters: {
                Category: {
                    $in: ['Landlord']
                },
                ReferredBy: {
                    id: {
                        $eq: +userID
                    }
                }
            }
        }

        const bodyObject = {
            method: 'GET',
            collection: 'users',
            query: qs.stringify(query, {
                encodeValuesOnly: true
            })

        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })

        const responseJSON = await response.json()

        if (responseJSON.success === true) {
            if (responseJSON.response.error == null) {
                dispatch(setReferrals(responseJSON.response))
                return {
                    isSuccessful: true
                }
            } else {
                throw new Error(responseJSON.response.error)
            }
        } else {
            throw new Error(responseJSON.response.error)
        }
    } catch (err) {
        console.log(err)
        return {
            isSuccessful: false
        }
    }
}

export const blockUser = (blockID: number, isBlocked: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''

        const sendObject = {
            method: 'PUT',
            collection: 'users',
            id: Number(userID),
            query: getQueryCurrentUser(),

            body: {
                BlackList: isBlocked
                    ? _((getState().user.currentUser as any).BlackList).map((user) => user.id).filter((id) => id !== blockID)
                    : [..._.map((getState().user.currentUser as any).BlackList, (user) => user.id), blockID]
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()
        if (dataJSON.success === true) {
            if (dataJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrors(dataJSON) }
            } else {
                await dispatch(addUserToFavorites(Number(blockID), true))
                dispatch(userSlice.actions.userFetchingSuccess(dataJSON.response))
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (err) {

    }
}

export const addUserToFavorites = (favoriteUserID: number, isFavorite: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''

        const sendObject = {
            method: 'PUT',
            collection: 'users',
            id: Number(userID),
            query: getQueryCurrentUser(),
            body: {
                FavoriteList: isFavorite
                    ? _((getState().user.currentUser as any).FavoriteList).map((user) => user.id).filter((id) => id !== favoriteUserID)
                    : [..._.map((getState().user.currentUser as any).FavoriteList, (user) => user.id), favoriteUserID]
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()
        if (dataJSON.success === true) {
            if (dataJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrors(dataJSON) }
            } else {
                dispatch(userSlice.actions.userFetchingSuccess(dataJSON.response))
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (err) {

    }
}

export const checkUserDocuments = (id: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

        const sendObject = {
            method: 'GET',
            collection: 'users',
            id,
            query: qs.stringify({
                populate: ['Documents']
            })
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        if (responseJSON.success === true) {
            customLog(responseJSON, 'checkUserDocuments')

            return {
                ...getAgentStatuses(responseJSON.response.Documents),
                emailVerified: responseJSON.response.emailVerified
            }
        } else {
            return {
                ...getAgentStatuses([]),
                emailVerified: false
            }
        }
    } catch (err) {
        return {
            ...getAgentStatuses([]),
            emailVerified: false
        }
    }
}

export const addClient = (data: Record<string, unknown>) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

        if (data.Bathrooms != null && _.isArray(data.Bathrooms)) {
            data.BathroomsStart = Number(data.Bathrooms[0])
            data.BathroomsEnd = Number(data.Bathrooms[1])
        }
        if (data.Bedrooms != null && _.isArray(data.Bedrooms)) {
            data.BedroomsStart = Number(data.Bedrooms[0])
            data.BedroomsEnd = Number(data.Bedrooms[1])
        }

        data.User = getState().user.currentUser.id

        const sendObject = {
            method: 'POST',
            collection: 'clients',
            body: _.omit(data, ['Bathrooms', 'Bedrooms'])

        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        if (responseJSON.success === true) {
            await dispatch(fetchUser(true))
            return {
                isSuccessful: true,
                textNavigate: -1
            }
        } else {
            return {
                isSuccessful: false
            }
        }
    } catch (err) {
        return {
            isSuccessful: false
        }
    }
}
