import React, { useEffect } from 'react'

import { DatePicker } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { useAppSelector } from '../hooks/redux'
import _ from 'lodash'
interface MyDataPickerProps {
    register: any
    row: any
    control: any
    setValue: any
    isBlockPast?: undefined | true
}
const dateFormat = 'YYYY-MM-DD'

export default function MyDataPicker({
    register,
    row,
    control,
    setValue,
    isBlockPast
}: MyDataPickerProps): JSX.Element {
    const { clearErrors: clearFormErrors, formState } = useForm()

    if (row.isBlockPast) {
        isBlockPast = row.isBlockPast
    }

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            clearFormErrors() // Очистка ошибок при успешной отправке формы
        }
    }, [formState.isSubmitSuccessful])

    const triggerValue = useAppSelector((store) => _.get(store.modal, row.trigger ?? ''))
    useEffect(() => {
        if (triggerValue != null && row.trigger != null) {
            setTimeout(() => {
                setValue(row.id, triggerValue)
            })
        }
    }, [triggerValue, row.id])

    const disabledDate = (current: any) => {
        const currentDate = dayjs()
        if (current.isBefore(currentDate, 'day')) {
            return true
        }
        return false
    }

    return (
        <Controller
            key={`key-${String(triggerValue)}`}
            name={row.id}
            control={control}
            rules={
                {

                    required: row.required === true && !row.defaultValue ? `${String(row.content?.[0])} is required` : undefined,
                    ...(!isBlockPast && {
                        validate: (value: string) => {
                            let result: boolean = true
                            if (row.yearsBeforeToday != null) {
                                const difference = new Date().getTime() - new Date(value).getTime()
                                const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25
                                const years = difference / millisecondsPerYear
                                result = result && years >= Number(row.yearsBeforeToday)
                            }
                            if (row.dateAfter != null) {
                                result = result && dayjs(value).add(1, 'day').isAfter(dayjs(), 'days')
                            }
                            return result
                        }
                    })
                }
            }
            render={({ field }) => (
                <DatePicker
                    placeholder={row.content && row.content[1]}
                    popupClassName="full-screen"
                    onFocus={e => { e.target.blur() }}
                    disabledDate={isBlockPast ? disabledDate : undefined}
                    {...field}
                    defaultValue={row.defaultValue ? dayjs(row.defaultValue, dateFormat) : triggerValue ?? undefined}
                    className="d-block"
                    size={'large'}
                    name={row.id}
                />

            )}
        />
    )
}
