import React, { type ReactElement, useEffect } from 'react'
import { Drawer } from 'antd'
import NewChat from './index'
interface ChatInDrawerProps {
    chatID: number
    open: boolean
    onClose: () => void
}
export default function ChatInDrawer ({ chatID, open, onClose }: ChatInDrawerProps): ReactElement {
    useEffect(() => {
        // zIndex update to overflow modal footer and keep global styles clean
        if (open) {
            const drawer = document.querySelector('.ant-drawer') as HTMLElement
            if (drawer != null) {
                drawer.style.zIndex = '10001'
            }
        }
    }, [open])
    return (
        <Drawer
            open={open}
            onClose={onClose}
            placement={'bottom'}
            closable={false}
            size={'large'}
            height={'75vh'}
            contentWrapperStyle={{
                borderTopRightRadius: '1rem',
                borderTopLeftRadius: '1rem',
                overflow: 'hidden'
            }}
            bodyStyle={{
                padding: 0
            }}
        >
            <NewChat
                id={chatID}
                embedded={true}
            />
        </Drawer>
    )
}
