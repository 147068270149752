import React, {type ReactElement} from "react";
import {type Unit} from "../../../../../../../store/slices/unitsSlice";
import TextButton from "../../../../../UIv2/Button/TextButton";
import useLandlordUnitCardActions from "./LandlordUnitCardActions.hook";
import {useNavigate} from "react-router-dom";

interface LandlordUnitCardActionsProps {
    unit: Unit
}

export default function LandlordUnitCardActions ({unit}: LandlordUnitCardActionsProps): ReactElement {
    const {
        activeOrdersCount
    } = useLandlordUnitCardActions(unit)
    const navigate = useNavigate()
    return <div className={'d-flex align-items-center gap-4 click-ignore'}>
        <TextButton
            text={'ACTIVE ORDERS'}
            badge={{
                text: String(activeOrdersCount),
                style: 'dark',
                position: 'end'
            }}
            style={'white'}
            textSize={'small'}
            height={'34'}
            width={'full'}
        />
        <TextButton
            text={'NEW SERVICE'}
            icon={{
                name: 'add',
                position: 'end'
            }}
            style={'dark'}
            textSize={'small'}
            height={'34'}
            width={'full'}
            onClick={() => {
                navigate(`/landlord/services/${unit.id}`)
            }}
        />
    </div>
}