import React, { type ReactElement } from 'react'
import iconsConfig, { type IconName } from './iconsConfig'

interface IconProps {
    weight?: 400 | 300
    style?: 'rounded' | 'outlined' | 'sharp'
    styles?: React.CSSProperties // Record<string, string>
    name: IconName
    filled?: boolean
    className?: string
    onClick?: () => any
    key?: string
    imageSize?: 24 | 40 | 48
    id?: string
    useWrapper?: boolean
    iconToFontRatio?: number
}

export default function Icon (
    {
        id,
        weight = 400,
        style = 'rounded',
        styles,
        filled = false,
        name,
        className,
        onClick,
        key,
        imageSize,
        useWrapper = false,
        iconToFontRatio = 1
    }: IconProps
): ReactElement {
    let iSize: number = 24
    const fsIndex = className?.indexOf('fs-')
    if (fsIndex != null && className != null) {
        const fontSize = className.slice(fsIndex, className.slice(fsIndex).indexOf(' '))
        switch (fontSize) {
            case 'fs-5':
            case 'fs-4':
            case 'fs-3':
                iSize = 40
                break
            default:
                break
        }
    }
    if (imageSize != null) {
        iSize = imageSize
    }
    const iconKey = `${name}${filled ? '_FILL1' : '_FILL0'}_wght${weight}_GRAD0_opsz${iSize}` as keyof typeof iconsConfig
    if (useWrapper) {
        return <span {...{ id, key, className, onClick, style: styles }} style={{ textAlign: 'center' }}>
            {
                React.cloneElement(iconsConfig[iconKey] ?? <></>, { width: '1em', height: '1em', style: { fill: 'currentcolor' } })
            }
        </span>
    }
    return React.cloneElement(iconsConfig[iconKey] ?? <></>, { id, key, className, onClick, width: `${iconToFontRatio}em`, height: `${iconToFontRatio}em`, style: { fill: 'currentcolor', ...styles } })
}
