import React, {type ReactElement, type ReactNode, useEffect, useMemo, useState} from "react";
import NumericKeypad from "../../../../../components/shared/UIv2/NumericKeypad/NumericKeypad";
import _ from "lodash";
import config from './PhoneNumberPicker.config.json'
import {nanoid} from "nanoid";
import {Controller} from "react-hook-form";
import {Drawer} from "antd";
import {regExps} from "../../../../../constants";
import Icon from "../../../../icon/Icon";

interface PhoneNumberPickerProps {
    row: Record<string, any>
    control: any
    setError: (...args: any) => void
}

export default function PhoneNumberPicker ({row, control, setError}: PhoneNumberPickerProps): ReactElement {
    const [country, setCountry] = useState(/* 'AE' */ 'AE')
    const [countryPickerOpen, setCountryPickerOpen] = useState(false)
    // const [number, setNumber] = useState<number[]>([])
    const [numberTemplate, setNumberTemplate] = useState<number[]>([])
    const countryInfo = useMemo(() => {
        const info =  config.find((item) => (
            item.countryCode === country
        ))
        const countryTemplate = info?.exampleNumber?.replaceAll(`+${info.callingCode}`, '')?.replaceAll(' ', '')?.replaceAll('-', '')?.replaceAll('+', '').split('').map(Number) ?? []
        setNumberTemplate(countryTemplate)

        return info
    }, [country])

    useEffect(() => {
        setError(row.id, 'Password must be entered')
    }, [])

    function renderPhoneNumber (number: number[]): ReactNode[] {
        const result : ReactNode[][] = []
        const numberScheme = countryInfo?.numberScheme ?? []
        let numberCopy = _.cloneDeep(number)
        let templateCopy = _.cloneDeep(numberTemplate)
        for (let sectionIndex = 1; sectionIndex < (numberScheme?.length ?? 0); sectionIndex ++) {
            const sectionLen = numberScheme?.[sectionIndex] ?? 0
            for (let i = 0; i < sectionLen; i++) {
                if (i < numberCopy.length) {
                    if (i === 0) {
                        result.push([
                            <span className={'text-black'} key={nanoid()}>
                                {numberCopy[i]}
                            </span>
                        ])
                    } else {
                        result[sectionIndex - 1].push(
                            <span className={'text-black'} key={nanoid()}>
                                {numberCopy[i]}
                            </span>
                        )
                    }
                } else {
                    if (i === 0) {
                        result.push([
                            <span className={'text-gray-300'} key={nanoid()}>
                                {templateCopy[i]}
                            </span>
                        ])
                    } else {
                        result[sectionIndex - 1].push(
                            <span className={'text-gray-300'} key={nanoid()}>
                                {templateCopy[i]}
                            </span>
                        )
                    }
                }
            }
            if (numberCopy.length < sectionLen) {
                numberCopy = []
            } else {
                numberCopy = numberCopy.toSpliced(0, sectionLen)
            }
            if (templateCopy.length < sectionLen) {
                templateCopy = []
            } else {
                templateCopy = templateCopy.toSpliced(0, sectionLen)
            }
        }

        return result.map(section => (
            <div key={nanoid()}>
                {
                    section
                }
            </div>
        ))
    }

    return <Controller
        name={row.id}
        control={control}
        rules={{
            required: row.required === true ? 'Phone number is required' : undefined,
            validate: (value: string) => {
                return value.length === countryInfo?.exampleNumber?.replaceAll('-', '')?.replaceAll(' ', '')?.length
            }
        }}
        render={({field}) => {
            const value = field.value?.replaceAll(`+${countryInfo?.callingCode ?? ''}`, '')
            const number = value?.split('')?.map(Number) ?? []

            return (
                <>
                    <div className={'d-flex flex-column gap-20 pb-20 px-8'}>
                        <div className={'d-flex gap-3 fs-4x justify-content-center'}>
                            <div className={'d-flex align-items-center text-black'}
                                onClick={() => {
                                    setCountryPickerOpen(true)
                                }}
                            >
                                <span>
                                    +{
                                        countryInfo?.callingCode
                                    }
                                </span>
                                <Icon name={'keyboard_arrow_down'}/>
                            </div>
                            {
                                renderPhoneNumber(number)
                            }
                        </div>
                        <div>
                            <NumericKeypad
                                handleKeyPress={(key: number) => {
                                    const phoneNumberLength = (countryInfo?.numberScheme?.toSpliced(0, 1) ?? [])
                                        .reduce((prev, current) => prev + current)
                                    if (number.length < phoneNumberLength) {
                                        field.onChange(
                                            [
                                                `+${countryInfo?.callingCode ?? ''}`,
                                                ...number,
                                                key
                                            ].join('')
                                        )
                                    }
                                }}
                                handleBackspace={() => {
                                    const numberCopy = _.cloneDeep(number)
                                    numberCopy.pop()
                                    field.onChange(
                                        numberCopy?.length > 0
                                            ? [
                                                `+${countryInfo?.callingCode ?? ''}`,
                                                ...numberCopy
                                            ].join('')
                                            : undefined
                                    )
                                }}
                            />
                        </div>
                    </div>
                    <Drawer
                        placement={'bottom'}
                        size={'large'}
                        height={'50vh'}
                        open={countryPickerOpen}
                        closable={true}
                        onClose={() => {
                            setCountryPickerOpen(false)
                        }}
                        headerStyle={{ display: 'none' }}
                        contentWrapperStyle={{
                            borderTopRightRadius: '16px',
                            borderTopLeftRadius: '16px',
                            overflow: 'hidden'
                        }}
                        bodyStyle={{
                            padding: 0
                        }}
                        zIndex={10000}
                    >
                        <div
                            className={'overflow-auto'}
                            style={{
                                maxHeight: '50vh'
                            }}
                        >
                            <div className={'d-flex flex-column p-5 gap-5'}>
                                {
                                    config.map((countryInfo) => (
                                        <div key={nanoid()}
                                            className={`d-flex p-1 px-2 align-items-center justify-content-between ${countryInfo.countryCode === country ? 'bg-secondary' : ''}`}
                                            style={{
                                                borderRadius: '8px'
                                            }}
                                            onClick={() => {
                                                setCountry(countryInfo.countryCode)
                                                field.onChange(
                                                    undefined
                                                )
                                                setCountryPickerOpen(false)
                                            }}
                                        >
                                            <div className={'d-flex gap-3'}>
                                                <span className={'fs-2x'}>
                                                    {
                                                        countryInfo.emoji
                                                    }
                                                </span>
                                                <span className={'fs-2x fw-medium'}>
                                                +{
                                                        countryInfo.callingCode
                                                    }
                                                </span>
                                            </div>
                                            <div className={'text-truncate fs-2'}>
                                                {
                                                    countryInfo.country
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </Drawer>
                </>
            )
        }}/>
}