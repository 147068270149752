import React, {type ReactElement} from "react";
import PhoneNumberPicker from "./PhoneNumberPicker/PhoneNumberPicker";
import FindUserByEmail from "./FindUserByEmail/FindUserByEmail";
import UserWaitingForApprove from "./UserWaitingForApprove/UserWaitingForApprove";
import CodePicker from "./CodePicker/CodePicker";
import CardsSelect from "./CardsSelect/CardsSelect";
import Input from "./Input/Input";
import InputsGroup from "./InputsGroup/InputsGroup";
import InputFile from "./InputFile/InputFile";
import InputCheckbox from "./InputCheckbox/InputCheckbox";
import CardInputFile from "./CardInputFile/CardInputFile";
import InputDate from "./InputDate/InputDate";
import InputSelect from "./InputSelect/InputSelect";
import ButtonLink from "./ButtonLink/ButtonLink";
import TextLink from "./TextLink/TextLink";
import TabLinks from "./TabLinks/TabLinks";
import ModalImage from "./ModalImage/ModalImage";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import ModalTitle from "./ModalTitle/ModalTitle";
import ModalDescription from "./ModalDescription/ModalDescription";
import InformationBlock from "./InformationBlock/InformationBlock";
import LinkList from "./LinkList/LinkList";
import InformationCheckList from "./InformationCheckList/InformationCheckList";
import ContactSupport from "./ContactSupport/ContactSupport";
import InputDateRange from "./InputDateRange/InputDateRange";
import ServiceAddons from "./ServiceAddons/ServiceAddons";
import ServiceCheckout from "./ServiceCheckout/ServiceCheckout";


interface CustomFieldProps {
    row: Record<string, any>
    control: any
    setError: any
    watch: any
    getValues: any
}
export default function CustomField ({row, control, setError, watch, getValues}: CustomFieldProps): ReactElement {
    switch (row.element) {
        case 'phone-keypad':
            return (
                <PhoneNumberPicker
                    row={row}
                    control={control}
                    setError={setError}
                />
            )
        case 'find-user-by-email':
            return (
                <FindUserByEmail
                    row={row}
                    control={control}
                    setError={setError}
                />
            )
        case 'user-waiting-approve':
            return (
                <UserWaitingForApprove row={row}/>
            )
        case 'code-keypad':
            return (
                <CodePicker
                    row={row}
                    control={control}
                    setError={setError}
                />
            )
        case 'cards-select':
            return (
                <CardsSelect
                    row={row}
                    control={control}
                />
            )
        case 'input':
            return (
                <Input
                    row={row}
                    control={control}
                    watch={watch}
                />
            )
        case 'inputs-group':
            return (
                <InputsGroup
                    row={row}
                    control={control}
                    watch={watch}
                />
            )
        case 'input-file':
            return (
                <InputFile
                    row={row}
                    control={control}
                />
            )
        case 'input-checkbox':
            return (
                <InputCheckbox
                    row={row}
                    control={control}
                />
            )
        case 'card-input-file':
            return (
                <CardInputFile
                    row={row}
                    control={control}
                    setError={setError}
                    getValues={getValues}
                />
            )
        case 'input-date':
            return (
                <InputDate
                    row={row}
                    control={control}
                />
            )
        case 'input-date-range':
            return (
                <InputDateRange
                    row={row}
                    control={control}
                    setError={setError}
                />
            )
        case 'select':
            return (
                <InputSelect
                    row={row}
                    control={control}
                />
            )
        case 'button-link':
            return (
                <ButtonLink
                    row={row}
                />
            )
        case 'text-link':
            return (
                <TextLink
                    row={row}
                />
            )
        case 'tab-links':
            return (
                <TabLinks
                    row={row}
                />
            )
        case 'modal-image':
            return (
                <ModalImage
                    row={row}
                />
            )
        case 'terms-and-conditions':
            return (
                <TermsAndConditions
                    row={row}
                    control={control}
                />
            )
        case 'modal-title':
            return (
                <ModalTitle row={row}/>
            )
        case 'modal-description':
            return (
                <ModalDescription row={row}/>
            )
        case 'info-block':
            return (
                <InformationBlock row={row}/>
            )
        case 'link-list':
            return (
                <LinkList row={row}/>
            )
        case 'info-checklist':
            return (
                <InformationCheckList row={row}/>
            )
        case 'contact-support':
            return (
                <ContactSupport row={row}/>
            )
        case 'service-addons':
            return (
                <ServiceAddons row={row} control={control} getValues={getValues}/>
            )
        case 'service-checkout':
            return (
                <ServiceCheckout row={row} control={control}/>
            )
        default:
            return <></>
    }
}