import {type AppDispatch, type RootState} from "../../../../../store";
import {
    serviceFindingTenantWriteToStorage
} from "../utils/findingTenantHandleStorage";

export const serviceFindTenantPickDate  = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    serviceFindingTenantWriteToStorage('service_finding_tenant', data)
    return {
        isSuccessful: true,
        textNavigate: `/landlord/modal/v2/service_finding_tenant_unit_info/${unitID ?? '-'}/-`,
        textNotification: ''
    }
}