
import { getErrorss } from '../../functions/errors/GetErrors'
import {ACCESS_KEY, urlAPI, REACT_APP_BSO_USER_ID, USER_ID_KEY} from '../../urls'
import { viewingsSlice } from '../slices/viewingsSlice'
import {type AppDispatch, RootState} from '../store'
import QueryString from 'qs'
import getUserType from '../../functions/shared/user/getUserType'
import {Unit, updateUnitAfterReservViewing} from "../slices/unitsSlice";
import swalTexts from "../../app/texts/swalTexts";
import modalSlice from '../slices/modalSlice'
import { getNameUser } from '../../functions/agent/agentModuleComponent/getNameUser'
import {getUnitAddress} from "../../functions/agent/agentModuleComponent/getUnitAddres";
import {getCurrentDubaiDate} from "../../functions/agent/date/getCurrentDateStrFormat";
import {timeStringInScheduler} from "../../functions/agent/date/timeStringInScheduler";
import sendMail from "../../functions/shared/email/sendMail";
import {createNotifyViewing} from "./viewingActions/createNotifyViewing";
import {setFilterNavigate} from "../slices/scheduleSlice";
import {User} from "../slices/userSlice";
import _ from "lodash";

const viewingsQueryObject = {
    populate: {
        User: {
            fields: ['id', 'DeviceToken']
        },
        Unit: {
            fields: ['id'],
            populate: {
                User: {
                    fields: ['id']
                }
            }
        },
        KeyBooking: {
            populate: {
                Holder: {
                    fields: ['id']
                }
            }
        }
    }
}
export const viewingsQuery = QueryString.stringify(viewingsQueryObject, { encode: false })
export const fetchViewings = (noLading?: boolean) => async (dispatch: AppDispatch) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    if (noLading !== true) {
        dispatch(viewingsSlice.actions.viewingsFetching())
    }
    try {
        let fetchViewingsQueryObject: any = _.cloneDeep(viewingsQueryObject)
        if (getUserType() === 'Landlord') {
            fetchViewingsQueryObject = {
                ...fetchViewingsQueryObject,
                filters: {
                    Unit: {
                        User: {
                            id: {
                                $eq: userID
                            }
                        }
                    }
                }
            }
        }
        const bodyObject = {
            method: 'GET',
            collection: 'viewings',
            query: viewingsQuery
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(viewingsSlice.actions.viewingsFetchingSuccess(dataJSON.response.data))
                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(viewingsSlice.actions.viewingsFetchingError(JSON.stringify(error)))
        return { textNavigate: '', isSuccessful: false, textNotification: '' }
    }
}

// export const createViewing = (bodyClient: any) => {
//     const jwt = window.localStorage.getItem(ACCESS_KEY)
//     const userID = bodyClient.objectID // window.localStorage.getItem(USER_ID_KEY)
//     return async (dispatch: AppDispatch, getState: () => RootState) => {
//         try {
//             const units: any = getState().units.units
//             const users = getState().user.allUsers
//             const unit = units?.filter((el: any) => el.id === Number(bodyClient.unitID))
//             const name = unit[0].attributes?.Property.data.attributes.Name ?? ''
//             const location = unit[0].attributes?.Property.data.attributes.Area ?? ''
//             const unitNumber = unit[0].attributes?.Number ?? ''
//             const ownerUserId = (unit[0] as Unit).attributes?.User.data?.id
//             const ownerUser = users.find(userItem => userItem.id === ownerUserId)
//             const unitStatuses = unit[0].attributes?.Statuses ?? []
//             const unitKey = unit[0].attributes?.UnitKeys?.data[0]
//             //   console.log(unit, 'unit<<<<<================', unitKey)
//             const textsMessage = swalTexts.successViewingsActions.createViewing
//             let status = 'In-process'
//             let createReservKey = false
//             let createOccupied = false
//             let createDigitalLock = false
//             let createBuildingManagement = false
//             let isDoorOpen = false
//             let titleTextAfterCreate = textsMessage.defaultMessage //  'Wait for the viewing confirmation'
//             const isLandlordUnit = ownerUser?.Category === 'Landlord' && ownerUser?.id !== Number(REACT_APP_BSO_USER_ID)
//             const isAgentUnit = unitStatuses?.includes('Door close') && !isLandlordUnit
//             if (isLandlordUnit) {
//                 status = 'In-process'
//                 titleTextAfterCreate = 'You created viewing, please wait for landlord to answer your request'
//             } else if (!isAgentUnit) {
//                 if (unitStatuses != null && unitStatuses?.includes('Vacant')) {
//                     if (unitStatuses?.includes('Door open')) {
//                         status = 'Approved'
//                         titleTextAfterCreate = ''
//                         isDoorOpen = true
//                     } else {
//                         if (unitStatuses?.includes('Digital lock')) {
//                             status = 'In-process'
//                             createDigitalLock = true
//                             titleTextAfterCreate = textsMessage.digitalLook // 'Shortly after confirmation, we will send you a Digital Lock Password that you can use to enter the apartment.'
//                         } else if (unitStatuses?.includes('Keys in BSO office')) {
//                             status = 'In-process' //   status = 'Approved'
//                             createReservKey = true
//                             titleTextAfterCreate = textsMessage.keysInBSO // 'Attention! You have selected a unit that has physical keys. Our specialist will contact you soon, where you will discuss when you can get and return the unit keys, and only after that the viewing will be confirmed.'
//                         } else if (unitStatuses?.includes('Building Management')) {
//                             status = 'In-process' //   status = 'Approved'
//                             createBuildingManagement = true
//                             titleTextAfterCreate = textsMessage.buildingManagment //  'Attention! You have selected a vacant unit, but it is under Building Management, our specialist will clarify the information and contact you for confirmation.'
//                         }
//                     }
//                 } else if (unitStatuses?.includes('Occupied')) {
//                     status = 'In-process' //   status = 'Approved'
//                     createOccupied = true
//                     titleTextAfterCreate = textsMessage.occupied // 'Attention! You have selected an occupied unit. Our specialist will contact you soon, and you will discuss a convenient viewing time for you!'
//                 }
//             } else {
//                 status = 'In-process'
//                 titleTextAfterCreate = 'You created viewing , please wait when agent answered on your request '
//             }
//
//             const dateViewing = new Date(`${bodyClient.time_viewing}+04:00`) // +04:00
//
//             dispatch(modalSlice.actions.dataFetchingStart())
//             const bodyObject = {
//                 method: 'POST',
//                 collection: 'viewings',
//                 query: viewingsQuery, // 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[KeyBooking]=*',
//                 body: {
//                     Datetime: dateViewing,
//                     Statuses: [status],
//                     Name: `${unitNumber} <-> ${name} <-> ${location}`,
//                     Notify: isLandlordUnit
//                         ? 'Landlord'
//                         : !isAgentUnit
//                             ? 'Both'
//                             : 'Other Agent',
//                     Unit: `${bodyClient.unitID}`,
//                     User: `${userID}`,
//                     ...(isAgentUnit && { AgentToAgentID: ownerUserId }),
//                     ...(isLandlordUnit && { AgentToLandlordID: ownerUserId })
//                 }
//             }
//             const response = await fetch(`${urlAPI}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: `Bearer ${jwt}`
//                 },
//                 body: JSON.stringify(bodyObject)
//             })
//             const dataJSON = await response.json()
//
//             //   console.log(dataJSON, 'dataJSON    <<<<< createViewing')
//             if (dataJSON.success) {
//                 if (dataJSON.response.error != null) {
//                     dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//                     dispatch(modalSlice.actions.dataFetchingFinish())
//                     return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
//                 } else {
//                     const currentUnit = unit?.[0]
//                     const currentUser = getState().user.currentUser
//                     const nameUser = getNameUser(currentUser as User)
//                     const unitAdress = getUnitAddress(currentUnit)
//                     dispatch(viewingsSlice.actions.addViewing(dataJSON.response.data))
//                     const currentDubaiDate = getCurrentDubaiDate()
//                     if (createOccupied) {
//                         const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                         await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-in-process', `Attention admin, ${nameUser} has selected ${unitAdress} which is in Occupied , please respond.`)
//                     }
//
//                     if (createReservKey) {
//                         const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                         await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-key', `Attention admin, ${nameUser} has selected ${unitAdress} which contains an Keys in BSO , please respond.`)
//                     }
//                     if (createDigitalLock) {
//                         const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                         await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-digital-lock', `Attention admin, ${nameUser} has selected ${unitAdress} which contains an digital lock , please respond.`)
//                     }
//                     if (createBuildingManagement) {
//                         const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                         await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-building-management', `Attention admin, ${nameUser} has selected ${unitAdress} which contains an Building Management , please respond.`)
//                     } if (isDoorOpen) {
//                         const [dateDubai, timeDubai] = timeStringInScheduler(
//                             dataJSON?.response?.data?.attributes?.Datetime
//                         ).split(',')
//                         const sentMail = await sendMail({
//                             email: currentUser?.email as string,
//                             templateId: 'd-c319ebc8b1f242eb82936867b49e5667',
//                             dynamicTemplateData: { date: dateDubai, time: timeDubai, unit: unitAdress }
//
//                         })
//                     }
//
//                     const areThereMoreThanFiveViewings = getState().viewings.areThereMoreThanFiveViewings
//
//                     await createNotifyViewing(dataJSON.response.data, unit, isAgentUnit)
//                     if (!areThereMoreThanFiveViewings && status === 'In-process') {
//                         if (!areThereMoreThanFiveViewings) {
//                             dispatch(setFilterNavigate('viewings'))
//                         }
//                     }
//
//                     dispatch(updateUnitAfterReservViewing({ viewing: dataJSON.response.data, unit_id: bodyClient.unitID }))
//                     dispatch(modalSlice.actions.dataFetchingFinish())
//                     return {
//                         ...dataJSON,
//                         textNavigate: (status === 'In-process' && !areThereMoreThanFiveViewings) // && !isAgentUnit
//                             ? '/agent/schedule'
//                             : `/agent/modalView/viewing_modal/${bodyClient.unitID}/${dataJSON?.response?.data?.id}/create`,
//                         isSuccessful: true,
//                         textNotification: `${titleTextAfterCreate}`,
//                         title: 'Viewing has been created'
//                     }
//                 }
//             } else {
//                 dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//                 dispatch(modalSlice.actions.dataFetchingFinish())
//                 return { textNavigate: '', isSuccessful: false, textNotification: '' }
//             }
//         } catch (e) {
//             dispatch(modalSlice.actions.dataFetchingFinish())
//             console.log(e)
//         }
//     }
// }

export const toUTCWithOffset = (date: Date, offsetMinutes: number): Date => {
    const utcTime = new Date(date.getTime() - (offsetMinutes * 60 * 1000) + 13000)
    return utcTime
}

export async function sendNotification (notificationTime: Date, userID: number, ObjectID: number | undefined, rule: string, text: string): Promise<void> {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const sentBodyForNotification = {
        method: 'POST',
        collection: 'user-notifies',
        body: {
            mode: 'publish',
            executeAt: notificationTime,
            RuleID: ObjectID,
            Rule: rule,
            User: userID,
            Text: text,
            Type: 'Mobile',
            publishedAt: null
        }
    }

    const requestForNotification = await fetch(`${urlAPI}`, {
        method: 'POST',
        body: JSON.stringify(sentBodyForNotification),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        }
    })
    const requestForNotificationJSON = await requestForNotification.json()
    //  console.log(requestForNotificationJSON, 'requestForNotificationJSON')
}

// /// updte viewing
// export const updateViewing = (bodyClient: any) => {
//     const jwt = window.localStorage.getItem(ACCESS_KEY)
//     const userID = window.localStorage.getItem(USER_ID_KEY)
//     const currentDubaiDate = getCurrentDubaiDate()
//     const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//
//     return async (dispatch: AppDispatch, getState: () => RootState) => {
//         try {
//             //   const view = getState().viewings.viewings.filter((el) => el.id === Number(bodyClient.viewID))?.[0]
//             const units: any = getState().units.units
//             const users = getState().user.allUsers
//             const unit = units?.filter((el: any) => el.id === Number(bodyClient.unitID))
//             const unitStatuses = unit[0].attributes?.Statuses ?? []
//
//             const currentUnit = unit?.[0]
//             const currentUser = getState().user.currentUser
//             const ownerUserId = currentUnit?.attributes?.User?.data?.id
//             const ownerUser = users.find(userItem => userItem.id === ownerUserId)
//             const nameUser = getNameUser(currentUser as User)
//             const unitAddress = getUnitAddress(currentUnit)
//
//             const createDigitalLock = unitStatuses?.includes('Digital lock')
//             const createKeysInBSO = unitStatuses?.includes('Keys in BSO office')
//             const createBuildingManagement = unitStatuses?.includes('Building Management')
//             const createOccupied = unitStatuses?.includes('Occupied')
//             const isDoorOpen = unitStatuses?.includes('Door open')
//             const isOwnedByLandlord = ownerUser?.Category === 'Landlord' && ownerUser?.id !== Number(REACT_APP_BSO_USER_ID)
//             const isDoorCloseAndOwnsOtherAgent = unitStatuses?.includes('Door close') === true && !isOwnedByLandlord
//             const dateViewing = new Date(`${bodyClient.time_viewing}+04:00`)
//             dispatch(modalSlice.actions.dataFetchingStart())
//
//             const bodyObject = {
//                 method: 'PUT',
//                 collection: 'viewings',
//                 query: viewingsQuery, // 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[KeyBooking]=*',
//                 id: Number(bodyClient.viewID),
//                 body: {
//                     Datetime: dateViewing,
//                     Statuses: [isDoorOpen ? 'Approved' : 'Rescheduled by agent'],
//                     Notify: isOwnedByLandlord
//                         ? 'Landlord'
//                         : isDoorCloseAndOwnsOtherAgent
//                             ? 'Other Agent'
//                             : 'Both'
//                 }
//             }
//             const response = await fetch(`${urlAPI}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: `Bearer ${jwt}`
//                 },
//                 body: JSON.stringify(bodyObject)
//             })
//             const dataJSON = await response.json()
//
//             // console.log(dataJSON, 'dataJSON<<<<<<<<<updateViewing<<<<<<<<!!!!!!__________')
//
//             if (dataJSON.success) {
//                 if (dataJSON.response.error != null) {
//                     dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//                     dispatch(modalSlice.actions.dataFetchingFinish())
//                     return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
//                 } else {
//                     if (createOccupied) {
//                         const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                         await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-in-process', `Attention admin, ${nameUser} has selected ${unitAddress} which is in Occupied , please respond.`)
//                     }
//
//                     if (createKeysInBSO) {
//                         const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                         await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-key', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an Keys in BSO , please respond.`)
//                     } if (createDigitalLock) {
//                         const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                         await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-digital-lock', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an digital lock , please respond.`
//                         )
//                     }
//                     if (createBuildingManagement) {
//                         const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                         await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-building-management', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an Building Management , please respond.`
//                         )
//                     } if (isDoorOpen) {
//                         //  const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                         // await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-approved', `Attention admin, ${nameUser} has selected ${unitAddress} which is in door open , please respond.`)
//                     }
//                     await createNotifyViewing(dataJSON.response.data, unit) // true
//                     await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON.response.data.id, 'admin-viewing-newtime', `Attention admin, ${nameUser} has rescheduled ${unitAddress}`)
//                     dispatch(setShowPopupViewingReschedule(false))
//                     dispatch(viewingsSlice.actions.setViewings(
//                         dataJSON.response.data
//                     ))
//                     dispatch(modalSlice.actions.dataFetchingFinish())
//                     const areThereMoreThanFiveViewings = store.getState().viewings.areThereMoreThanFiveViewings
//
//                     if (!areThereMoreThanFiveViewings && !isDoorOpen) {
//                         if (!areThereMoreThanFiveViewings) {
//                             dispatch(setFilterNavigate('viewings'))
//                         }
//                     }
//
//                     return {
//                         ...dataJSON,
//                         textNavigate: !areThereMoreThanFiveViewings && !isDoorOpen
//                             ? '/agent/schedule'
//                             : -1,
//
//                         isSuccessful: true,
//                         textNotification: swalTexts.successViewingsActions.updateViewing.defaultMessage
//                     }
//                 }
//             } else {
//                 dispatch(modalSlice.actions.dataFetchingFinish())
//                 dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//                 return { textNavigate: '', isSuccessful: false, textNotification: '' }
//             }
//         } catch (e) {
//             dispatch(modalSlice.actions.dataFetchingFinish())
//             console.log(e)
//         }
//     }
// }
//
// export const updateStatusViewing = (bodyClient: any) => {
//     const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
//     const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
//     return async (dispatch: AppDispatch, getState: () => RootState) => {
//         try {
//             dispatch(modalSlice.actions.dataFetchingStart())
//             const unitID = bodyClient.unitID
//             const units: any = getState().units.units
//             const users = getState().user.allUsers
//             const unit = units?.filter((el: any) => el.id === Number(unitID))
//             const unitStatuses = unit[0].attributes?.Statuses ?? []
//
//             const currentUnit = unit?.[0]
//             const unitUser = users.find(userItem => userItem.id === currentUnit?.attributes?.User?.data?.id)
//             const currentUser = getState().user.currentUser
//             const nameUser = getNameUser(currentUser as User)
//             const unitAddress = getUnitAddress(currentUnit)
//
//             const createDigitalLock = unitStatuses?.includes('Digital lock')
//             const createKeysInBSO = unitStatuses?.includes('Keys in BSO office')
//             const createBuildingManagement = unitStatuses?.includes('Building Management')
//             const createOccupied = unitStatuses?.includes('Occupied')
//             const isDoorOpen = unitStatuses?.includes('Door open')
//             const isOtherAgent = bodyClient.isOtherAgent
//             const isLandlordUnit = unitUser?.Category === 'Landlord' && unitUser?.id !== Number(process.env.REACT_APP_BSO_USER_ID)
//
//             const response = await fetch(urlAPI,
//                 {
//                     method: 'POST',
//                     headers: {
//                         'Content-type': 'application/json',
//                         Authorization: `Bearer ${jwt}`
//                     },
//                     body: JSON.stringify({
//                         method: 'PUT',
//                         collection: 'viewings',
//                         query: viewingsQuery,
//                         id: Number(bodyClient.viewID),
//                         body: {
//                             Statuses: [bodyClient.status],
//                             Notify: isLandlordUnit
//                                 ? 'Landlord'
//                                 : isOtherAgent === true
//                                     ? 'Agent'
//                                     : 'BSO'
//                         }
//                     })
//                 })
//             const dataJSON = await response.json()
//             //    console.log(responseJSON, 'responseJSON_____________updateStatusViewing__________')
//
//             if (dataJSON.success) {
//                 if (dataJSON.response.error != null) {
//                     dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//                     dispatch(modalSlice.actions.dataFetchingFinish())
//                     return { textNavigate: '', isSuccessful: false, textNotification: '' }
//                 //    return dataJSON
//                 } else {
//                     dispatch(viewingsSlice.actions.setViewings(
//                         dataJSON.response.data
//                     ))
//                     dispatch(setShowPopupViewingReschedule(false))
//                     //      return { ...dataJSON, textNotification: 'Viewing has been ' }
//                     const currentDubaiDate = getCurrentDubaiDate()
//                     const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//
//                     if (bodyClient.status === 'Rejected' || bodyClient.status === 'Canceled') {
//                         if (!isDoorOpen) {
//                             //    isOtherAgent
//                             await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-rejected', `Attention admin, ${bodyClient?.nameUser} CANCELED viewing by ${bodyClient?.unitAdress}.`)
//                         }
//                     } if (bodyClient.status === 'Approved') {
//                         await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-approved', `Attention admin, ${bodyClient?.nameUser} APPROVED viewing by ${bodyClient?.unitAdress}.`)
//                         //    isOtherAgent
//                         if (createOccupied) {
//                             const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                             await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-in-process', `Attention admin, ${nameUser} has selected ${unitAddress} which is in Occupied , please respond.`)
//                         }
//                         if (createKeysInBSO) {
//                             const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                             await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-key', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an Keys in BSO , please respond.`)
//                         }
//                         if (createDigitalLock) {
//                             const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                             await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-digital-lock', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an digital lock , please respond.`
//                             )
//                         }
//                         if (createBuildingManagement) {
//                             const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                             await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-building-management', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an Building Management , please respond.`
//                             )
//                         }
//                         if (isDoorOpen) {
//                             const [dateDubai, timeDubai] = timeStringInScheduler(
//                                 dataJSON?.response?.data?.attributes?.Datetime
//                             ).split(',')
//                             //   console.log({ dateDubai, timeDubai })
//                             const sentMail = await sendMail({
//                                 email: currentUser?.email as string,
//                                 templateId: 'd-c319ebc8b1f242eb82936867b49e5667',
//                                 dynamicTemplateData: { date: dateDubai, time: timeDubai, unit: unitAddress }
//
//                             })
//                             //  console.log(await sentMail.json(), 'await sentMail.json()')
//
//                             // const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 0)
//                             //  await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-approved', `Attention admin, ${nameUser} has selected ${unitAddress} which is in Occupied , please respond.`)
//                         }
//                     }
//                     dispatch(modalSlice.actions.dataFetchingFinish())
//                     return { ...dataJSON, textNavigate: -1, isSuccessful: true, textNotification: swalTexts.successViewingsActions.updateViewing.defaultMessage }
//                 }
//             } else {
//                 dispatch(modalSlice.actions.dataFetchingFinish())
//                 dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//                 return { textNavigate: '', isSuccessful: false, textNotification: '' }
//             }
//         } catch (e) {
//             dispatch(modalSlice.actions.dataFetchingFinish())
//             console.log(e)
//         }
//     }
// }
//
// export const createPeriodBisyKeys = (bodyClient: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
//     // console.log(bodyClient, 'bodyClient')
//     const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
//     const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
//     dispatch(modalSlice.actions.dataFetchingStart())
//     const updViewing = getState().viewings.viewings.filter((view) => view.id === Number(bodyClient.viewID))[0]
//     //  console.log(updViewing, 'updViewing ascmajskcmkasmc ????ASC')
//     const sendObject = {
//         method: 'POST',
//         collection: 'unit-keys',
//         query: 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[Viewings][fields][0]=id',
//         body: {
//             PickUpDate: bodyClient['pick-up-date'].toISOString(),
//             DropOutDate: bodyClient['pick-out-date'].toISOString(),
//             Holder: Number(userID),
//             Viewings: Number(bodyClient.viewID),
//             Unit: Number(bodyClient.unitID),
//             Status: 'In-process'
//         }
//     }
//     try {
//         const response = await fetch(`${urlAPI}`, {
//             method: 'POST',
//             body: JSON.stringify(sendObject),
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${jwt}`
//             }
//         })
//
//         const dataJSON = await response.json()
//         // console.log(dataJSON, 'dataJSON ?A>S:C>AS:C>A:SC>:AS')
//         if (dataJSON.success) {
//             if (dataJSON.response.error != null) {
//                 dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//                 dispatch(modalSlice.actions.dataFetchingFinish())
//                 return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
//             } else {
//                 const DropOutDate = dataJSON.response.data?.attributes?.DropOutDate
//                 const PickUpDate = dataJSON.response.data?.attributes?.PickUpDate
//                 const textNot = `You should pick up your keys on &nbsp;<strong>${timeStringInScheduler(PickUpDate).split(',')[0]} - ${timeStringInScheduler(PickUpDate).split(',')[1]}</strong> &nbsp; But return them by &nbsp;<strong>${timeStringInScheduler(DropOutDate).split(',')[0]} - ${timeStringInScheduler(DropOutDate).split(',')[1]}</strong>`
//                 //  console.log(dataJSON?.response?.data, 'vdataJSON?.response?.data COOOL')
//
//                 const newObject = JSON.parse(JSON.stringify(updViewing))
//                 newObject.attributes.KeyBooking.data = dataJSON?.response?.data
//
//                 //  console.log(newObject, 'newObject<____________<newObjectnewObjectnewObject')
//                 dispatch(viewingsSlice.actions.setViewings(newObject))// responseJSON.response.data
//                 dispatch(modalSlice.actions.dataFetchingFinish())
//                 return { ...dataJSON, textNavigate: `/agent/modalView/viewing_modal/${bodyClient.unitID}/${bodyClient.viewID}/create`, isSuccessful: true, textNotification: textNot, title: 'Viewing has been created' }
//             }
//         } else {
//             dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//             dispatch(modalSlice.actions.dataFetchingFinish())
//             return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
//         }
//     } catch (e) {
//         dispatch(modalSlice.actions.dataFetchingFinish())
//         console.log(e)
//     // dispatch(viewingsSlice.actions.viewingsFetchingError(e as Error))
//     }
// }
//
// export const viewViewing = (bodyClient: any) => {
//     // view.id, "Approved"
//
//     return async (dispatch: AppDispatch, getState: () => RootState) => {
//         const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
//         const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
//         try {
//             const response = await fetch(`${urlAPI}`,
//                 {
//                     method: 'POST',
//                     headers: {
//                         'Content-type': 'application/json',
//                         Authorization: `Bearer ${jwt}`
//                     },
//                     body: JSON.stringify({
//                         method: 'PUT',
//                         collection: 'viewings',
//                         id: Number(bodyClient.viewID),
//                         query: viewingsQuery, // 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[KeyBooking]=*',
//                         body: {
//                             Notify: bodyClient.notify
//                         }
//                     })
//                 })
//             const dataJSON = await response.json()
//             if (dataJSON.success) {
//                 if (dataJSON.response.error != null) {
//                     dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//
//                     return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
//                 } else {
//                     dispatch(viewingsSlice.actions.setViewings(
//                         dataJSON.response.data
//                     ))
//
//                     return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
//                 }
//             } else {
//                 dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//                 return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
//             }
//         } catch (e) {
//             console.log(e)
//         }
//     }
// }
//
// export const removeViewingNotify = (viewingID: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
//     try {
//         const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
//         const viewings = _.cloneDeep(getState().viewings?.viewings ?? [])
//         const viewing = _.find(viewings, (viewingItem: any) => viewingItem.id === viewingID) as IViewing
//         if (viewing != null) {
//             viewing.attributes.Notify = null
//             const bodyObject = {
//                 method: 'PUT',
//                 collection: 'viewings',
//                 query: viewingsQuery,
//                 id: viewingID,
//                 body: { ...viewing.attributes, User: viewing.attributes.User.data, Unit: viewing.attributes.Unit.data, query: viewingsQuery }
//             }
//             const response = await fetch(`${urlAPI}`, {
//                 method: 'POST',
//                 body: JSON.stringify(bodyObject),
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: `Bearer ${jwt}`
//                 }
//             })
//             const responseJSON = await response.json()
//             if (responseJSON.success === true) {
//                 void dispatch(viewingsSlice.actions.setViewings(viewing))
//             }
//         }
//     } catch (err) {
//         console.log(err)
//     }
// }
//
// export const updateViewingByOtherAgent = (bodyClient: any) => {
//     const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
//     const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
//     return async (dispatch: AppDispatch, getState: () => RootState) => {
//         try {
//             dispatch(modalSlice.actions.dataFetchingStart())
//             const unitID = bodyClient.unitID
//             const units: any = getState().units.units
//             const unit = units?.filter((el: any) => el.id === Number(unitID))
//
//             const currentUser = getState().user.currentUser
//             const nameUser = getNameUser(currentUser as User)
//             const unitAddress = getUnitAddress(unit?.[0])
//
//             const response = await fetch(urlAPI,
//                 {
//                     method: 'POST',
//                     headers: {
//                         'Content-type': 'application/json',
//                         'BSO-Hash': '02ns30nsBSO!',
//                         Authorization: `Bearer ${jwt}`
//                     },
//                     body: JSON.stringify({
//                         method: 'PUT',
//                         collection: 'viewings',
//                         query: viewingsQuery,
//                         id: Number(bodyClient.viewID),
//                         body: {
//                             Statuses: ['Rescheduled by other agent'],
//                             Notify: 'Agent',
//                             RejectReason: bodyClient.RejectReason
//                         }
//                     })
//                 })
//             const dataJSON = await response.json()
//
//             if (dataJSON.success) {
//                 if (dataJSON.response.error != null) {
//                     dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//                     dispatch(modalSlice.actions.dataFetchingFinish())
//                     return { textNavigate: '', isSuccessful: false, textNotification: '' }
//                 //    return dataJSON
//                 } else {
//                     dispatch(viewingsSlice.actions.setViewings(
//                         dataJSON.response.data
//                     ))
//
//                     const currentDubaiDate = getCurrentDubaiDate()
//                     const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
//                     await createNotifyViewing(dataJSON.response.data, unit) // true
//                     await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON.response.data.id, 'admin-viewing-newtime', `Attention admin, ${nameUser} has rescheduled ${unitAddress}`)
//
//                     dispatch(modalSlice.actions.dataFetchingFinish())
//                     return { ...dataJSON, textNavigate: `/agent/modalView/viewing_modal/${bodyClient.unitID}/${bodyClient.viewID}/agent_s_unit`, isSuccessful: true, textNotification: swalTexts.successViewingsActions.updateViewing.defaultMessage }
//                 }
//             } else {
//                 dispatch(modalSlice.actions.dataFetchingFinish())
//                 dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
//                 return { textNavigate: '', isSuccessful: false, textNotification: '' }
//             }
//         } catch (e) {
//             dispatch(modalSlice.actions.dataFetchingFinish())
//             console.log(e)
//         }
//     }
// }
