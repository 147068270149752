import React, {type ReactElement} from "react";
import Badge from "../../../../UIv2/Badge/Badge";
import {type Unit} from "../../../../../../store/slices/unitsSlice";
import {type IconName} from "../../../../../../app/icon/iconsConfig";
import {nanoid} from "nanoid";
import dayjs from "dayjs";

interface LandlordUnitCardUnitInfoProps {
    unit: Unit
}

export default function LandlordUnitCardUnitInfo ({unit}: LandlordUnitCardUnitInfoProps): ReactElement {
    const property = unit?.attributes?.Property?.data?.attributes
    const unitUse = unit?.attributes?.Marketing?.Use?.toUpperCase()
    const unitType = unit.attributes?.Type?.toUpperCase()
    const unitBodyOptionsBadges: Array<{
        text?: string,
        icon: IconName,
        iconPosition?: 'start' | 'end',
        renderCondition?: boolean
    }> = [
        {
            text: unit?.attributes?.Bedrooms > 0 ? `${unit?.attributes?.Bedrooms} Bed` : 'Studio',
            icon: 'bed',
            iconPosition: 'end',
            renderCondition: unit?.attributes?.Bedrooms != null
        },
        {
            text: `${unit?.attributes?.Bathrooms} Bath`,
            icon: 'bathtub',
            iconPosition: 'end',
            renderCondition: unit?.attributes?.Bathrooms != null && unit?.attributes?.Bathrooms !== 0

        },

        ...((unit?.attributes?.Statuses != null && unit?.attributes?.Statuses.length > 0)
            ? unit?.attributes?.Statuses.filter((el: string) => ['Vacant', 'Occupied'].includes(el)).map((el: string): { icon: IconName, text: string } => ({
                text: el,
                icon: 'door_front'
            }))
            : []),
        ...(unit?.attributes?.Statuses?.filter((el: string) => ['Building Management', 'Keys in BSO office', 'Digital lock'].includes(el))?.map((el: string): { icon: IconName, text: string } => ({
            text: el,
            icon: 'lock'
        })) ?? []),
        {
            text: `${String(unit?.attributes?.SizeArea?.toLocaleString('en-US'))} Sq.Ft`,
            icon: 'square_foot',
            renderCondition: unit?.attributes?.SizeArea != null
        },
        {
            text: unit?.attributes?.ImportantNotice,
            icon: 'info',
            renderCondition: Boolean(unit?.attributes?.ImportantNotice)
        }
    ]
    const PMCs = unit?.attributes?.PMCs
    const unitIsOnPMC = PMCs?.some((contract) => [
        contract.Status !== 'Terminated',
        dayjs(contract.TerminationDate).isAfter(dayjs()),
        contract.IsArchived !== true
    ].every(Boolean))
    return <div className={'d-flex flex-column gap-1'}>
        { unit?.attributes?.Marketing != null
            ? <div className={'d-flex gap-2 align-items-center'}>
                <div className={'fs-6 text-gray-600'}>
                    {unitUse} / {unitType}
                </div>
                {
                    unitIsOnPMC === true
                        ? <Badge
                            icon={{
                                name: 'check_circle',
                                position: 'end',
                                filled: true
                            }}
                            text={'managed by BSO'}
                            style={'bso'}
                            size={'18'}
                        />
                        : <></>
                }
            </div>
            : <></>
        }
        <div className={'fs-5 fw-medium'}>
            {property?.Area}, {property?.Name}, {unit?.attributes?.Number}
        </div>
        <div className={'d-flex flex-wrap align-items-center gap-2'}>
            {
                unitBodyOptionsBadges.map((option) => (
                    option.renderCondition !== false
                        ? <Badge
                            key={nanoid()}
                            text={option.text ?? ''}
                            icon={{
                                name: option.icon,
                                position: 'start'
                            }}
                            style={'light-gray'}
                            size={'22'}
                        />
                        : <></>
                ))
            }
        </div>
    </div>
}