import React, {type ReactElement} from "react";
import Input from "../Input/Input";
import {nanoid} from "nanoid";

interface InputsGroupProps {
    row: Record<string, any>
    control: any
    watch: any
}

export default function InputsGroup ({row, control, watch}: InputsGroupProps): ReactElement {
    return <div className={'d-flex gap-5'}>
        {
            row.inputs.map((config: Record<string, any>) => (
                <div className={'flex-grow-1'}>
                    <Input
                        row={config}
                        control={control}
                        watch={watch}
                        key={nanoid()}
                    />
                </div>
            ))
        }
    </div>
}
