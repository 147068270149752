import React, { useEffect } from 'react'
import Swal, { type SweetAlertResult } from 'sweetalert2'
import {
    animationOptions,
    swalOptions,
    swalVerifyEmail
} from './functions/swalOptions'
import { fetchUser } from './store/actionsCreators/userActions'
import { useAppDispatch, useAppSelector } from './hooks/redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { type RootState, store } from './store/store'
import { setIsUserVerified } from './store/slices/modalSlice'
import { getErrorss } from './functions/errors/GetErrors'
import { ACCESS_KEY, queryUSER, urlAPI, urlAUTH, USER_EMAIL_KEY, USER_TIME_INTERVAL_VERIFY_KEY } from './urls'
import { changeEmailForFirstTime } from './store/actionsCreators/userActions/verifyActions/changeEmailForFirstTime'
import sendMail from './functions/shared/email/sendMail'
import { verifyUser } from './store/actionsCreators/authActions/registerAction'
import { getQueryCurrentUser } from './functions/shared/api/queries/getQueryCurrentUser'

export default function AlertsComponentVerify(): JSX.Element {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const documentsLoaded = useAppSelector(
        (state: RootState) => state.agentStates.documentsLoaded
    )

    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.passportLoaded

    useEffect(() => {
        async function handleTouch(): Promise<void> {
            await verifyEmail()
        }
        const element = document.querySelector('#hide_component_alerts')
        if (element != null) {
            console.log('Touch')
            element.addEventListener('touchstart', handleTouch)
        }

        return () => {
            if (element != null) {
                element.removeEventListener('touchstart', handleTouch)
            }
        }
    }, [currentUser])

    async function verifyEmail(): Promise<void> {
        const actualUser = store.getState().user?.currentUser
        const inputPlaceholder = 'whiterabbit@follow.me'
        const userEMAIL = window.localStorage.getItem(USER_EMAIL_KEY) ?? currentUser.email as string
        if (!actualUser?.emailVerified) {
            const swalVerifyEmailVar = await swalVerifyEmail(() => dispatch(fetchUser(true))).then(async (result: SweetAlertResult<any>) => {
                try {
                    if (result.isConfirmed) {
                        setIsUserVerified(true)
                    } else if (result.isDenied) {
                        const { value: email } = await new Promise<SweetAlertResult<string>>((resolve) => {
                            Swal.fire({
                                ...swalOptions('warning'),
                                ...{
                                    title: 'Change email',
                                    text: 'Enter your current email and confirm as well',
                                    input: 'email',
                                    inputLabel: 'EMAIL',
                                    inputPlaceholder,
                                    showCancelButton: true,
                                    cancelButton: 'btn btn-danger w-75 mx-auto my-2',
                                    confirmButtonText: 'To change',
                                    cancelButtonText: 'No, cancel',
                                    showLoaderOnConfirm: true,
                                    allowOutsideClick: false,
                                    iconHtml: '<div  id="lottie-icon-warning"></div>',
                                    didOpen: async () => {
                                        const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                                        const pathLottie = await import('../src/animations/Warning.json')
                                        if (lottieContainer != null) {
                                            lottie.loadAnimation({
                                                container: lottieContainer,
                                                renderer: 'svg',
                                                ...{ ...animationOptions, animationData: pathLottie.default }
                                            })
                                        }
                                    }
                                },

                                preConfirm: async (email: string) => {
                                    try {
                                        if (email !== currentUser.email) {
                                            const dataJSON = await dispatch(changeEmailForFirstTime(email))
                                            if (dataJSON.success) {
                                                if (dataJSON.response.error != null) {
                                                    return await new Promise((resolve) => {
                                                        Swal.fire({
                                                            ...swalOptions('error'),
                                                            ...{
                                                                title: 'Attention',
                                                                text: getErrorss(dataJSON),
                                                                showConfirmButton: true,
                                                                confirmButtonText: 'Got it'
                                                            }
                                                        }).then((result: SweetAlertResult<string>) => {
                                                            if (result.isConfirmed) {
                                                                void verifyEmail()
                                                            }
                                                        }) // .catch(console.log)
                                                    })
                                                }
                                            }
                                        } else {
                                            const response = await fetch(`${urlAPI}`, {
                                                method: 'POST',
                                                body: JSON.stringify({
                                                    method: 'PUT',
                                                    collection: 'users',
                                                    id: Number(currentUser.id),
                                                    // query: queryUSER,
                                                    query: getQueryCurrentUser(),
                                                    body: {
                                                        newEmail: null
                                                    }
                                                }),
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    Authorization: `Bearer ${jwt}`
                                                }
                                            })
                                            const dataJSON = await response.json()

                                            const verifyEmailFetch = await fetch(`${urlAUTH}/sendVerifyMail`, {
                                                method: 'POST',
                                                body: JSON.stringify({ email, renew: false }), // , testing: process.env.REACT_APP_IS_DEV === 'true'
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    Authorization: `Bearer ${jwt}`
                                                }
                                            })

                                            const verifyEmailJSON = await verifyEmailFetch.json()
                                            if (verifyEmailJSON.success) {
                                                if (verifyEmailJSON.response.error != null) {
                                                    return await new Promise((resolve) => {
                                                        Swal.fire({
                                                            ...swalOptions('error'),
                                                            ...{
                                                                title: 'Attention',
                                                                text: getErrorss(verifyEmailJSON) + '\n Please try later',
                                                                showConfirmButton: true,
                                                                confirmButtonText: 'Got it'
                                                            }
                                                        }).then((result: SweetAlertResult<string>) => {
                                                            if (result.isConfirmed) {
                                                                void verifyEmail()
                                                            }
                                                        })
                                                    })
                                                }
                                            }
                                        }
                                    } catch (error: any) {
                                        Swal.showValidationMessage(`Request failed: ${error.message}`)
                                    }
                                },
                                allowOutsideClick: false
                            }).then((result: SweetAlertResult<string>) => {
                                void verifyEmail()
                            })
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        const lastTimeVerify = window.localStorage.getItem(USER_TIME_INTERVAL_VERIFY_KEY)
                        if ((lastTimeVerify && new Date() > new Date(Number(lastTimeVerify))) || !lastTimeVerify) {
                            dispatch(verifyUser())
                            const int = new Date().getTime() + 2 * 60 * 1000
                            window.localStorage.setItem(USER_TIME_INTERVAL_VERIFY_KEY, String(int))
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            })
            void verifyEmail()
        } else {
            if (window.localStorage.getItem('changingEmail') === 'true') {
                const isEmailChanged = !actualUser.newEmail
                const sentMail = await sendMail({
                    email: userEMAIL,
                    templateId: 'd-2e029b024c2a467e8f2b07894ccd8ce1',
                    dynamicTemplateData: {
                        value: actualUser.email
                    }
                })
                window.localStorage.setItem('changingEmail', 'false')
            }
            if (loadSts) {
                if (actualUser?.Orders && Array.isArray(actualUser.Orders) && actualUser.Orders.length > 0) {
                    navigate('/agent/units/nav')
                } else {
                    navigate('/agent/settings/subscriptions/choose')
                }
            }
        }
    }
    return (
        <div className='bg-light-info opacity-0 vh-100 w-100 position-fixed' id='hide_component_alerts' style={{
            zIndex: 200
        }}
        // onClick={async () => { await verifyEmail() }}
        >AlertsComponentVerify</div>
    )
}
