import React, { useEffect, useState } from 'react'
import TabButton from '../../../shared/elements/TabButton'
import SharedMediaList from './SharedMediaList'
import SharedFilesList from './SharedFilesList'
import SharedVoiceList from './SharedVoiceList'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import _ from 'lodash'
import { getSharedMedia } from '../../../../store/actionsCreators/chatActions'
import { setMessages } from '../../../../store/slices/chatSlice'
import { type IUnit, type IChatMessage } from '../../../../types'
import SharedUnitList from './SharedUnitList'
import { type Unit } from '../../../../store/slices/unitsSlice'

interface ProfileSharedMediaProps {
    id: number
}

const ProfileSharedMedia = ({ id }: ProfileSharedMediaProps): JSX.Element => {
    const [searchParams] = useSearchParams()
    const isDev = window.localStorage.getItem('BSO_DEVELOP_MODE')
    // const tabOpen = searchParams.get('media-type') ?? 'media'
    const currentUser = useAppSelector((state: RootState) => state.user).currentUser

    const { Messages } = useAppSelector((state: RootState) => state.chat)
    const dispatch = useAppDispatch()
    const [media, setMedia] = useState<IChatMessage[]>()
    const [files, setFiles] = useState<IChatMessage[]>()
    const [voices, setVoices] = useState<IChatMessage[]>()
    const [units, setUnits] = useState<IChatMessage[]>()

    //  console.log(Messages, 'Messages')
    const [activeTab, setActiveTab] = useState<'media' | 'files' | 'voice' | 'units'>('media')

    useEffect(() => {
        dispatch(setMessages([]))
        const chatID = searchParams.get('chatId') // dispatch(openChatByUserID(id))
        if (chatID != null) {
            dispatch(getSharedMedia(+chatID))
        }
        return () => {
            dispatch(setMessages([]))
        }
    }, [currentUser.id])
    useEffect(() => {
        setMedia(_.filter(Messages, (message: IChatMessage) => (
            (message.Text === 'type:file' && /image\/*/.test(message?.Attachments?.type ?? ''))
        )))
        setFiles(_.filter(Messages, (message: IChatMessage) => (
            message.Text === 'type:video' || (message.Text === 'type:file' && !/image\/*/.test(message?.Attachments?.type ?? ''))
        )))
        setVoices(_.filter(Messages, (message: IChatMessage) => (
            _.includes([
                'type:voice',
                'type:audio'
            ], message.Text)
        )))
        const filteredUnits = _.filter(Messages, (message: IChatMessage) => (
            _.includes(['type:unit'], message.Text)
        )).map(el => el.Unit) as any[]
        //    console.log(filteredUnits, 'filteredUnits')
        setUnits(filteredUnits)
    }, [Messages])

    return (
        <div className={'card shadow-sm mx-4 mb-4'}>
            <div className={'card-body p-4'}>
                <div className={'d-flex w-100 mb-4'}>
                    <TabButton
                        stateControlled={true}
                        stateControlledActive={activeTab === 'media'}
                        stateControlledSetActive={() => { setActiveTab('media') }}
                        label={'Media'}
                    />
                    <TabButton
                        stateControlled={true}
                        stateControlledActive={activeTab === 'files'}
                        stateControlledSetActive={() => { setActiveTab('files') }}
                        label={'Files'}
                    />
                    <TabButton
                        stateControlled={true}
                        stateControlledActive={activeTab === 'voice'}
                        stateControlledSetActive={() => { setActiveTab('voice') }}
                        label={'Voice'}
                    />
                    {isDev === 'true'
                        ? <TabButton
                            stateControlled={true}
                            stateControlledActive={activeTab === 'units'}
                            stateControlledSetActive={() => { setActiveTab('units') }}
                            label={'Units'}
                        />
                        : null}

                </div>
                {
                    (activeTab === 'media') && (
                        <SharedMediaList
                            messages={media ?? []}
                        />
                    )
                }
                {
                    (activeTab === 'files') && (
                        <SharedFilesList
                            messages={files ?? []}
                        // messages={files}
                        />
                    )
                }
                {
                    (activeTab === 'voice') && (
                        <SharedVoiceList
                            messages={voices ?? []}
                        />
                    )
                }

                {(activeTab === 'units') && (
                    <SharedUnitList
                        units={units}
                    />
                )

                }
            </div>
        </div>
    )
}

export default ProfileSharedMedia
