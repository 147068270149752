import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

interface TasksState {
    tasks: any[]
}

const initialState: TasksState = {
    tasks: []
}

export const tasksSlice = createSlice({
    name: 'swalsStates',
    initialState,
    reducers: {
        setTasks: (state, action) => {
            state.tasks = action.payload
        },
        addNewTask: (state, action) => {
            state.tasks.push(action.payload)
        },
        updateStoredTask: (state, action) => {
            const tasks = _.cloneDeep(state.tasks)
            const taskIndex = tasks.findIndex(taskItem => taskItem.id === action.payload.id)
            if (taskIndex !== -1) {
                tasks[taskIndex] = action.payload
            }
            state.tasks = tasks
        }
    }
})

export const {
    setTasks,
    addNewTask,
    updateStoredTask
} = tasksSlice.actions

export default tasksSlice.reducer
