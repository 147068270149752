import { type AppDispatch, type RootState } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { landlordAddUnitReadFromStorage, landlordAddUnitWriteToStorage } from '../utils/landlordAddUnitHandleStorage'
import { scanDocuments } from '../../../documentActions'
import { nanoid } from 'nanoid'
import compareNames from '../../../../../functions/shared/user/compareNames/compareNames'
import { type Document } from '../../../../slices/documentSlice'
import { createSingleLandlord } from '../../../unitsActions'
import { type User } from '../../../../slices/userSlice'
import dayjs from 'dayjs'
import { type NavigateFunction } from 'react-router-dom'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'
import Swal from 'sweetalert2'
import { swalOptions } from '../../../../../functions/swalOptions'
import {getUsersPOA} from "../utils/getUsersPOA";

export const scanUnitOwnersPassport = (unitID: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    const storedData = landlordAddUnitReadFromStorage()
    const owners = storedData.title_deed?.response?.document?.owners
    const currentUser = getState().user.currentUser as User
    const documents = getState().documents.documents
    try {
        const fileList = data.passport
        if (fileList != null && fileList?.length > 0) {
            const passportResponse = await dispatch(scanDocuments(fileList, 'Passport', true, `RefDOC-Passport-${nanoid()}`, 'users'))
            const passportID = passportResponse[0]?.id
            const passport = getState().documents.documents.find((doc) => doc.id === passportID)
            console.log(owners, passport)
            const landlordIsOwner = owners.some((owner: any) => compareNames(
                String(owner.name),
                String(passport?.attributes?.Type?.[0]?.Name)
            ))
            const userHasPOA = dispatch(getUsersPOA()) != null

            // const name = passport?.attributes?.Type?.[0]?.Name

            if (landlordIsOwner === true) {
                const FirstName = passport?.attributes?.Type?.[0]?.Name?.split(' ')?.[0]
                const FamilyName = passport?.attributes?.Type?.[0]?.Name?.split(' ')?.slice(1).join(' ')
                const DocumentNumber = (passport as Document)?.attributes?.Type?.[0]?.Number as string
                const Email = data.email
                const Phone = data.phone

                const landlordID = await dispatch(createSingleLandlord({
                    FirstName,
                    FamilyName,
                    Country: undefined,
                    DateOfBirth: undefined,
                    Gender: undefined,
                    Passport: (passport as Document)?.id,
                    DocumentNumber,
                    Email,
                    Phone
                }))
                const userDocument = documents.find((doc) => (
                    doc.attributes.User?.data?.id === currentUser.id &&
                    ['documents.passport', 'documents.emirates-id'].includes(String(doc.attributes?.Type?.[0]?.__component)) &&
                    dayjs().isBefore(dayjs(doc.attributes.ExpiryDate))
                ))
                const representativeID = await dispatch(createSingleLandlord({
                    FirstName: currentUser?.ContactInfo?.FirstName,
                    FamilyName: currentUser?.ContactInfo?.FamilyName,
                    Country: currentUser?.ContactInfo?.Country,
                    DateOfBirth: currentUser?.ContactInfo?.DateOfBirth,
                    Gender: currentUser?.ContactInfo?.Gender,
                    Passport: userDocument?.id,
                    DocumentNumber: (userDocument as Document)?.attributes?.Type?.[0]?.Number as string,
                    Email: currentUser?.ContactInfo?.Email,
                    Phone: currentUser?.ContactInfo?.Phone
                }))
                if (landlordID != null && representativeID != null) {
                    landlordAddUnitWriteToStorage('owners_passport', {
                        landlordID,
                        representativeID,
                        landlordScannedData: {
                            FirstName,
                            FamilyName,
                            DocumentNumber,
                            Email,
                            Phone
                        }
                    })
                    const result = await Swal.fire({
                        ...swalOptions('info'),
                        title: 'Passport is scanned. Check if the scanned data is correct',
                        html: `
                            <div class="text-start">
                                <div>
                                    First Name: <b>${String(FirstName ?? 'Not scanned')}</b>
                                </div>
                                <div>
                                    Family Name: <b>${String(FamilyName ?? 'Not scanned')}</b>
                                </div>
                                <div>
                                    Document Number: <b>${String(DocumentNumber ?? 'Not scanned')}</b>
                                </div>
                                <div>
                                    Email: <b>${String(Email ?? 'Not entered')}</b>
                                </div>
                                <div>
                                    Phone: <b>${String(Phone ?? 'Not entered')}</b>
                                </div>
                            </div>
                        `,
                        showConfirmButton: true,
                        confirmButtonText: 'It is correct',
                        showCancelButton: true,
                        cancelButtonText: 'Correct some fields'
                    })
                    if (result.isConfirmed) {
                        if (userHasPOA) {
                            return {
                                textNotification: '',
                                textNavigate: `/landlord/modal/landlord_create_unit_info/${nanToNull(unitID) ?? '-'}/-`,
                                isSuccessful: true
                            }
                        } else {
                            return {
                                textNotification: '',
                                textNavigate: `/landlord/modal/landlord_create_unit_poa/${nanToNull(unitID) ?? '-'}/-`,
                                isSuccessful: true
                            }
                        }
                    } else {
                        return {
                            textNotification: '',
                            textNavigate: `/landlord/modal/landlord_create_unit_correct_owner_info/${nanToNull(unitID) ?? '-'}/-`,
                            isSuccessful: true
                        }
                    }
                }
            }
        }
    } catch (err) {
        console.log('Error while scanning unit owner\'s passport', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false,
        textNotification: 'Passport scanning has failed. Try again. Make sure passport photo is clear'
    }
}

export const skipScanUnitOwnersPassport = (navigate: NavigateFunction, unitID?: number) => () => {
    navigate(`/landlord/modal/landlord_create_unit_poa/${nanToNull(unitID) ?? '-'}/-`)
}

export const goBackScanUnitOwnersPassport = (navigate: NavigateFunction, unitID?: number) => () => {
    navigate(`/landlord/modal/landlord_create_unit/${nanToNull(unitID) ?? '-'}/-`)
}
