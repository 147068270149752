import { type AppDispatch, type RootState } from '../../store'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import qs from 'qs'
import { updateStoredTask } from '../../slices/tasksSlice'

export const updateTask = (taskID: number, data: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    try {
        const bodyObject = {
            method: 'PUT',
            collection: 'tasks',
            body: data,
            id: taskID,
            query: qs.stringify({
                populate: {
                    Documents: true,
                    Unit: true,
                    AssignedTo: true
                }
            }, { encodeValuesOnly: true })
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'BSO-Hash': '02ns30nsBSO!',
                Authorization: `Bearer ${String(jwt)}`
            },
            body: JSON.stringify(bodyObject)
        })
        const responseJSON = await response.json()
        if (responseJSON.success === true) {
            if (responseJSON.response.error == null) {
                dispatch(updateStoredTask(responseJSON?.response?.data))
            }
        }
        return responseJSON
    } catch (error) {
        return { textNavigate: '', isSuccessful: false, textNotification: '' }
    }
}
