import React, { useState } from 'react'
import { openChatByUserID } from '../../../store/actionsCreators/chatActions'
import { addUserToFavorites, blockUser } from '../../../store/actionsCreators/userActions'
import Icon from '../../../app/icon/Icon'
import { useNavigate } from 'react-router-dom'
import {useAppSelector} from "../../../hooks/redux";

interface IProps {
    callbackMessage: any
    callbackBlock: any
    callbackFavourite: any
    isBlocked: boolean
    isFavorite: boolean
    userID: number
}
function ActionButtons({ callbackMessage, callbackBlock, callbackFavourite, isBlocked, isFavorite, userID }: IProps): JSX.Element {
    const [isLoading, setIsLoading] = useState({ message: false, block: false, favourite: false })
    const navigate = useNavigate()
    const isDev = process.env.REACT_APP_IS_DEV === 'true'
    const currentUser = useAppSelector((state) => state.user.currentUser)
    return (
        <div>
            <div className="d-flex flex-row align-items-stretch fw-semi-bold mb-4 ">
                <div className={'col-3  mx-auto text-center card shadow-sm py-2 px-2 text-gray-700'}
                    onClick={!isLoading.message
                        ? () => callbackMessage((state: boolean) => {
                            setIsLoading((prev) => ({ ...prev, message: state }))
                        })
                        : () => null
                    }
                >
                    {!isLoading.message
                        ? <Icon name={'send'} className={'mx-auto fs-4x my-1 text-gray-700'} />
                        : <span className="indicator-progress d-block text-gray-700 my-1">
                            <span className="spinner-border spinner-border-md align-middle"></span>
                        </span>
                    }
                    <div className={'fs-7 lh-1 px-2'}>
                        Message
                    </div>
                </div>

                <div className={'col-3 mx-auto text-center card shadow-sm py-2 px-2  text-gray-700'}
                    onClick={!isLoading.block
                        ? () => callbackBlock((state: boolean) => {
                            setIsLoading((prev) => ({ ...prev, block: state }))
                        })
                        : () => null
                    }
                >
                    {!isLoading.block
                        ? <Icon name={isBlocked ? 'lock' : 'lock_open_right'} className={'mx-auto fs-4x my-1 text-gray-700'} />
                        : <span className="indicator-progress d-block text-gray-700 my-1">
                            <span className="spinner-border spinner-border-md align-middle"></span>
                        </span>
                    }
                    <div className={'fs-7 lh-1 px-2'}>
                        {!isBlocked ? 'Add to Black List' : 'Remove from Black List'}  </div>
                </div>
                <div className={`col-3 mx-auto text-center card shadow-sm py-2 px-2 ${!isBlocked ? 'text-gray-700' : 'text-gray-400'}`}
                    onClick={!isBlocked && !isLoading.favourite
                        ? () => callbackFavourite((state: boolean) => {
                            setIsLoading((prev) => ({ ...prev, favourite: state }))
                        })
                        : () => null
                    }
                >
                    {!isLoading.favourite
                        ? <Icon name={'bookmark'} filled={isFavorite} className={`mx-auto fs-4x my-1 ${!isBlocked ? 'text-gray-700' : 'text-gray-400'}`} />
                        : <span className="indicator-progress d-block text-gray-700 my-1">
                            <span className="spinner-border spinner-border-md align-middle"></span>
                        </span>
                    }
                    <div className={'fs-7 lh-1 px-2'}>
                        {!isFavorite ? 'Add to Favorites' : 'Remove from Favorites'}
                    </div>
                </div>
                {isDev && currentUser?.id !== Number(process.env.REACT_APP_BSO_USER_ID)
                    ? <div className={'col-3  mx-auto text-center card shadow-sm py-2 px-2 text-gray-700'}
                        onClick={() => { navigate(`/agent/catalog_agent/${userID}`) }}

                    >
                        <Icon
                            name={'home'}
                            className={'mx-auto fs-4x my-1'}

                        />
                        <div className={'fs-7 lh-1 px-2'}>
                            Units catalog
                        </div>
                    </div>
                    : null}

            </div>
            {/* </div> */}
            {/* <li className="nav-item mb-3 me-3 me-lg-6" role="presentation">
                <a className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active" id="kt_stats_widget_16_tab_link_4" data-bs-toggle="pill" href="#kt_stats_widget_16_tab_4" aria-selected="true" role="tab">
                    <div className="nav-icon mb-3">
                        <i className="ki-duotone ki-tablet fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                        Mobile                        </span>
                    <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                </a>
            </li> */}
        </div >
    )
}

export default ActionButtons
// py-2 px-2 mx-auto
