export const getStatusesOrders = (orders: any[]): any => {
    const statuses = [] as any

    const inProcessOrders = orders.filter(order => order?.Statuses?.includes('In-process'))
    const paidOrders = orders.filter(order => order?.Statuses?.includes('Paid'))
    const canceledOrders = orders.filter(order => order?.Statuses?.includes('Canceled'))

    statuses.push({
        status: 'In-process',
        orders: inProcessOrders,
        ordersLength: inProcessOrders.length
    })
    statuses.push({
        status: 'Paid',
        orders: paidOrders,
        ordersLength: paidOrders.length
    })

    statuses.push({
        status: 'Canceled',
        orders: canceledOrders,
        ordersLength: canceledOrders.length
    })

    return statuses
}
