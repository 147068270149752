import { type Unit } from "../../../../../../../store/slices/unitsSlice";
import dayjs from "dayjs";
import {type IOffer} from "../../../../../../../store/slices/offersSlice";
import {useAppSelector} from "../../../../../../../hooks/redux";
import {type User} from "../../../../../../../store/slices/userSlice";

type IUseUnitActiveOrderSectionFindingTenant = (unit: Unit) => {
    serviceIsActive: boolean
    activeOffersCount: number,
    activeAdvertisesCount: number,
    upcomingViewingsCount: number,
    closestViewingDateTime?: dayjs.Dayjs,
    closestAdvertiseDates?: {
        startDate: dayjs.Dayjs
        endDate: dayjs.Dayjs
    },
    highestOfferData?: {
        offer: number,
        chequesNumber: number,
        createdAt: dayjs.Dayjs,
        status: string,
        statusType: 'success' | 'warning' | 'danger',
        roiDate: dayjs.Dayjs
    },
    previousOffers: IOffer[]
}

const useUnitActiveOrderSectionFindingTenant: IUseUnitActiveOrderSectionFindingTenant = (unit) => {
    const currentUser = useAppSelector((state) => state.user.currentUser) as User
    const orders = currentUser.Orders
    const serviceIsActive = orders?.some((order: any) => [
        order.Service?.[0]?.__component === 'services.finding-tenant',
        order.Unit?.id === unit.id,
        dayjs().isBefore(dayjs(order.InvoiceDueDate))
    ].every(Boolean)) === true

    const offers = useAppSelector((state) => state.offers.offers)
    const activeUnitOffers = offers.filter((offer) => [
        offer?.attributes?.Unit?.data?.id === unit.id,
        !['Completed', 'Canceled'].includes(offer?.attributes?.Statuses?.[0])
    ].every(Boolean))

    const sortedOffers = activeUnitOffers?.sort((offerA, offerB) => {
        const priceA = offerA?.attributes?.Type?.[0]?.Offer
        const priceB = offerB?.attributes?.Type?.[0]?.Offer

        if (priceA == null && priceB == null) {
            return 0
        }
        if (priceA == null) {
            return 1
        }
        if (priceB == null) {
            return -1
        }

        return priceB - priceA
    })

    const highestOffer = sortedOffers[0]
    const highestOfferData = {
        offer: highestOffer?.attributes?.Type?.[0]?.Offer ?? 0,
        chequesNumber: highestOffer?.attributes?.Type?.[0]?.Payments ?? 1,
        createdAt: dayjs(highestOffer?.attributes?.createdAt),
        status: highestOffer?.attributes?.Statuses?.[0] ?? '',
        statusType: 'success' as 'success' | 'warning' | 'danger',
        roiDate: dayjs().add(50, 'year')
    }
    if (['Rejected', 'Canceled'].includes(highestOfferData.status)) {
        highestOfferData.statusType = 'danger'
    } else if (highestOfferData.status === 'In-process') {
        highestOfferData.statusType = 'warning'
    }
    const previousOffers = sortedOffers.slice(1, 3)

    const viewings = useAppSelector((state) => state.viewings.viewings)
    const upcomingViewings = viewings.filter((viewing) => [
        viewing?.attributes?.Unit?.data?.id === unit.id,
        !['Completed', 'Canceled'].includes(viewing?.attributes?.Statuses?.[0] ?? ''),
        dayjs(viewing?.attributes?.Datetime).isAfter(dayjs())
    ].every(Boolean))

    const closestViewing = upcomingViewings?.sort((viewingA, viewingB) => {
        const dateTimeA = dayjs(viewingA.attributes.Datetime)
        const dateTimeB = dayjs(viewingB.attributes.Datetime)
        if (dateTimeA.isBefore(dateTimeB)) {
            return -1
        } else if (dateTimeA.isSame(dateTimeB)) {
            return 0
        } else {
            return 1
        }
    })?.[0]

    const closestViewingDateTime = closestViewing?.attributes?.Datetime != null
        ? dayjs(closestViewing?.attributes?.Datetime)
        : undefined

    const advertises = useAppSelector((state) => state.advertises.advertises)
    const activeAdvertises = advertises.filter((advertise) => [
        advertise?.attributes?.Unit?.data?.id === unit.id,
        !['Completed', 'Canceled'].includes(advertise?.attributes?.Statuses?.[0] ?? ''),
        dayjs(advertise?.attributes?.EndDate).isAfter(dayjs())
    ].every(Boolean))

    const closestAdvertise = activeAdvertises?.sort((advertiseA, advertiseB) => {
        const dateTimeA = dayjs(advertiseA.attributes.StartDate)
        const dateTimeB = dayjs(advertiseB.attributes.StartDate)
        const dateNow = dayjs()

        const diffA = dateTimeA.diff(dateNow)
        const diffB = dateTimeB.diff(dateNow)

        return diffA - diffB
    })[0]

    return {
        serviceIsActive,
        activeOffersCount: activeUnitOffers?.length ?? 0,
        activeAdvertisesCount: activeAdvertises?.length ?? 0,
        upcomingViewingsCount: upcomingViewings?.length ?? 0,
        closestAdvertiseDates: closestAdvertise?.attributes?.StartDate != null
            ? {
                startDate: dayjs(closestAdvertise?.attributes?.StartDate),
                endDate: dayjs(closestAdvertise?.attributes?.EndDate)
            }
            : undefined,
        closestViewingDateTime,
        highestOfferData,
        previousOffers
    }
}

export default useUnitActiveOrderSectionFindingTenant
