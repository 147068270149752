import {  getErrorss } from '../../functions/errors/GetErrors'
import {ACCESS_KEY, urlAPI, USER_ID_KEY} from '../../urls'
import { documentsSlice } from '../slices/documentSlice'
import {type AppDispatch, type RootState, store} from '../store'
import { getQueryDocuments } from '../../functions/shared/api/queries/getQueryDocuments'
import compareNames from '../../functions/shared/user/compareNames/compareNames'
import {removeDataAttributes} from "../../functions/adapters/universal/removeDataAttributes";
import {uploadStrapiFile} from "./fileActions";
import _ from "lodash";
import {uploadSingleFile} from "./documentsActions/upload/uploadSingleFile";
import {getCountryCodes} from "../../functions/getDataForSelects/getCountries";
import {getCorrectExpDate} from "../../functions/agent/date/getCorrectExpDateForScaning";
import {setDocuments, setScannedData} from "../slices/modalSlice";

export const fetchDocuments = (noLading?: boolean) => async (dispatch: AppDispatch) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)

    if (noLading !== true) {
        dispatch(documentsSlice.actions.documentsFetching())
    }
    try {
        const bodyObject = {
            method: 'GET',
            collection: 'documents',
            query: getQueryDocuments()
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt as string}`
            },
            body: JSON.stringify(bodyObject)
        })

        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(documentsSlice.actions.documentsFetchingError(getErrorss(dataJSON)))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(documentsSlice.actions.documentsFetchingSuccess(dataJSON?.response?.data))
                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(documentsSlice.actions.documentsFetchingError(getErrorss(dataJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(documentsSlice.actions.documentsFetchingError(JSON.stringify(error)))
        return { textNavigate: '', isSuccessful: false, textNotification: '' }
    }
}





export const createDocument = (documents: FileList | File[] | null, data: any, collection?: string, entityID?: string, url?: string, refID?: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean, response?: any }> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    try {
        if (documents?.[0] != null) {
            const url = await uploadSingleFile(documents[0], refID, collection, entityID)
            const sendBody = {
                method: 'POST',
                collection: 'documents',
                query: getQueryDocuments(),
                body: {
                    ...data,
                    Link: url
                }
            }
            const documentResponse = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(sendBody),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const documentResponseJSON = await documentResponse.json()
            if (documentResponseJSON.response?.error == null) {
                // const documents = getState().modal.documents
                await dispatch(fetchDocuments(true))
            }
            documentResponseJSON.isSuccessful = documentResponseJSON.response?.error == null
            return documentResponseJSON
        } else if (url != null) {
            const sendBody = {
                method: 'POST',
                collection: 'documents',
                query: getQueryDocuments(),
                body: {
                    ...data,
                    Link: url
                }
            }
            const documentResponse = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(sendBody),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const documentResponseJSON = await documentResponse.json()
            if (documentResponseJSON.success === true) {
                // const documents = getState().modal.documents
                await dispatch(fetchDocuments(true))
            }
            documentResponseJSON.isSuccessful = documentResponseJSON.success
            return documentResponseJSON
        } else {
            return {
                isSuccessful: false
            }
        }
    } catch (err) {
        return {
            isSuccessful: false
        }
    }
}
/**
 * @param documents
 * @param type is Category of document in DB
 * @param omitUser
 * @param refID
 * @param collection
 * @param checkCurrentUser
 */
export const scanDocuments = (documents: FileList | File[] | null, type: string, omitUser: boolean = false, refID?: string, collection?: string, checkCurrentUser: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    function checkDocumentUserName (responseJSON: Record<string, any>): boolean {
        const name = responseJSON?.response?.document?.name
        const userFirstName = (getState().user.currentUser?.ContactInfo as any)?.FirstName?.toLowerCase()
        const userFamilyName = (getState().user.currentUser?.ContactInfo as any)?.FamilyName?.toLowerCase()
        return responseJSON.response?.document?.valid === true && compareNames(name, {
            FamilyName: userFamilyName,
            FirstName: userFirstName
        })
    }

    const promises = _.map(documents, async (document, i) => {
        const formData = new FormData()
        formData.append('file', document)
        formData.append('type', type)
        const response = await fetch('https://bso.ae/api/service/scanDocument', {
            method: 'POST',
            body: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        //   console.log(responseJSON, 'responseJSON <========= scanDocuments')
        let url: string | null
        let id: number | null = null
        if (
            responseJSON.response?.error == null &&
            (responseJSON?.response?.document?.number != null || type !== 'Title Deed') &&
            (!checkCurrentUser || checkDocumentUserName(responseJSON))
        ) {
            url = await uploadSingleFile(document, refID, collection)
            // let documentType = undefined
            // if (type === 'Title Deed') {
            //     documentType = ['Unit', 'Land', 'Villa'].includes(responseJSON?.response?.document?.type) ? responseJSON?.response?.document?.type : 'Unit'
            // }
            const personalTypeFields: Record<string, any> = {}
            if (type !== 'Title Deed') {
                personalTypeFields.Gender = responseJSON?.response?.document?.gender === 'M'
                    ? 'Male'
                    : responseJSON?.response?.document?.gender === 'F'
                        ? 'Female'
                        : undefined
                // personalTypeFields.Country = responseJSON?.response?.document?.country
                personalTypeFields.Country = getCountryCodes(responseJSON?.response?.document?.country)
                personalTypeFields.DateOfBirth = getCorrectExpDate(responseJSON?.response?.document?.dob)
            }
            const sendBody = {
                method: 'POST',
                collection: 'documents',
                query: getQueryDocuments(),
                body: {
                    RefID: `REF_DOC_${type.toLowerCase().replace(' ', '_')}_${new Date().getTime()}_${i}`,
                    Link: url,
                    Category: type,
                    ExpiryDate: (type === 'Title Deed' || responseJSON?.response?.document?.expDate == null)
                        ? undefined
                        : getCorrectExpDate(responseJSON?.response?.document?.expDate), // dayjs('20' + _.chunk(responseJSON?.response?.document?.expDate, 2).map(particle => particle.join('')).join('-')), // '270523' -> dayjs('2027-05-23')
                    Type: [
                        {
                            __component: type === 'Title Deed' ? 'documents.title-deed' : type === 'Emirates ID' ? 'documents.emirates-id' : type === 'Passport' ? 'documents.passport' : '',
                            Number: String(responseJSON?.response?.document?.number),
                            // DateOfBirth: responseJSON?.response?.document?.dob,
                            Type: ['Unit', 'Land', 'Villa'].includes(responseJSON?.response?.document?.type) ? responseJSON?.response?.document?.type : 'Unit',
                            Gender: type === 'Title Deed' ? undefined : responseJSON?.response?.document?.type,
                            // Nationality: responseJSON?.response?.document?.nationality,
                            Name: responseJSON?.response?.document?.name,
                            ...(type !== 'Title Deed' ? personalTypeFields : {})
                        }
                    ],
                    Statuses: ['In-process'],
                    User: !omitUser ? userID : undefined
                }
            }
            const documentResponse = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(sendBody),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const documentResponseJSON = await documentResponse.json()
            if (documentResponseJSON.success === true) {
                // const documents = getState().modal.documents
                dispatch(setDocuments([documentResponseJSON.response.data.id]))
                id = documentResponseJSON.response.data.id
                await dispatch(fetchDocuments(true))
            }
        } else {
            url = await uploadSingleFile(document, refID, collection)
            dispatch(setScannedData({ ...getState().modal.scannedData, failedToScan: { url } }))
        }
        return {
            ...responseJSON,
            url,
            id
        }
    })
    return await Promise.all(promises)
}

export const scanTitleDeed = (file: File) => async (dispatch: AppDispatch, getState: () => RootState): Promise<Record<string, any> | null> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', 'Title Deed')
    try {
        const response = await fetch('https://bso.ae/api/service/scanDocument', {
            method: 'POST',
            body: formData,
            headers: {
            // 'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        if (responseJSON.response.error == null) {
            return responseJSON
        }
    } catch (err) {
        console.error(`Error while scanning title deed: `, err)
    }
    return {
        isSuccessful: false
    }
}

export const scanEmiratesID = (fileFront: File | null, fileBack: File | null) => async (dispatch: AppDispatch, getState: () => RootState): Promise<Record<string, any> | null> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const scanOneSide = async (file: File, type: 'Emirates ID' | 'Emirates ID Front'): Promise<{ document: Record<string, any>, url: string } | null> => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', type)
        const response = await fetch('https://bso.ae/api/service/scanDocument', {
            method: 'POST',
            body: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        if (responseJSON.response.error == null) {
            const url = await uploadSingleFile(file, `offer-${new Date().getTime()}-emirates-id-${type === 'Emirates ID Front' ? 'front' : 'back'}`, 'offers')
            if (url != null) {
                return {
                    document: responseJSON?.response?.document,
                    url
                }
            }
        }
        return null
    }
    if (fileFront != null && fileBack != null) {
        const frontResult = await scanOneSide(fileFront, 'Emirates ID Front')
        const backResult = await scanOneSide(fileBack, 'Emirates ID')
        if (frontResult != null && backResult != null) {
            const isOneDocument = Object.keys(backResult).every(key => !(key in frontResult) || (backResult.document[key] === frontResult.document[key]))
            if (isOneDocument) {
                const sendBody = {
                    method: 'POST',
                    collection: 'documents',
                    query: getQueryDocuments(),
                    body: {
                        RefID: `REF_DOC<->emirates_id<->${String(backResult.document.number)}<->${new Date().getTime()}`,
                        LinkFront: frontResult.url,
                        LinkBack: backResult.url,
                        Category: 'Emirates ID',
                        Type: [
                            {
                                __component: 'documents.emirates-id',
                                Number: backResult.document.number,
                                // DateOfBirth: responseJSON?.response?.document?.dob,
                                Gender: backResult.document.gender === 'M' ? 'Male' : 'Female',
                                // Nationality: responseJSON?.response?.document?.nationality,
                                Name: backResult.document.name
                            }
                        ],
                        Statuses: ['In-process']
                    }
                }
                const documentResponse = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    body: JSON.stringify(sendBody),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                })
                const documentResponseJSON = await documentResponse.json()
                if (documentResponseJSON.success === true) {
                    // const documents = getState().modal.documents
                    dispatch(setDocuments([documentResponseJSON.response.data.id]))
                    void dispatch(fetchDocuments(true))
                    return {
                        ...frontResult.document,
                        ...backResult.document
                    }
                }
            }
        }
    }
    return null
}

export const validateDocument = async ({ type, number, nationality, dateOfBirth }: Record<string, string>): Promise<{ isSuccessful: boolean }> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    try {
        const bodyObject = {
            type,
            number,
            nationality,
            dateOfBirth
        }

        const response = await fetch('https://bso.ae/api/service/verifyDocument', {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response.json()
        if ((responseJSON.success === true) && (responseJSON.response.document.valid === true)) {
            return {
                isSuccessful: true
            }
        } else {
            return {
                isSuccessful: false
            }
        }
    } catch (err) {
        return {
            isSuccessful: false
        }
    }
}

export const uploadDepositSlip = async (file: File, offerID: number): Promise<any> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const uploadedFile = await uploadStrapiFile(file)
    const offer = removeDataAttributes(
        _.cloneDeep(
            _.find(
                store.getState().offers.offers, { id: offerID }
            )
        ) ?? {}
    )
    if (offer != null) {
        try {
            const bodyObject = {
                method: 'PUT',
                collection: 'offers',
                id: offerID,
                body: {
                    ...offer,
                    Status: 'Waiting for sleep approval',
                    Type: [
                        {
                            ...offer.Type[0],
                            DepositSlipLink: uploadedFile?.data
                        }
                    ]
                }
            }

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(bodyObject),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const responseJSON = await response.json()
            return {
                isSuccessful: responseJSON.success
            }
        } catch (err) {
            console.error(err)
            return {
                isSuccessful: false
            }
        }
    }
}
