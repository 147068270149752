import localStorageForPipelines
    from "../../../../../../functions/utils/localStorageForPipelines/localSorageForPipelines";

type TFindingTenantHandleStorageStepKey = 'service_finding_tenant' | 'service_finding_tenant_photos' | 'service_finding_tenant_unit_info' | 'service_finding_tenant_marketing_info' | 'service_finding_tenant_addons' | 'service_finding_tenant_checkout'
const FINDING_TENANT_HANDLE_STORAGE_KEY = 'bso-landlord-service-finding-storage'
const {
    writeToStorage,
    readFromStorage,
    clearStorage
} = localStorageForPipelines<TFindingTenantHandleStorageStepKey>(FINDING_TENANT_HANDLE_STORAGE_KEY)

export const serviceFindingTenantWriteToStorage = writeToStorage
export const serviceFindingTenantReadFromStorage = readFromStorage
export const serviceFindingTenantClearStorage = clearStorage

