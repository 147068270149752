import React, { useEffect, useState } from 'react'
import ChatListHeader from './ChatListHeader'
import ChatCard from './ChatCard'
import { getChatList } from '../../../../store/actionsCreators/chatListActions'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import _ from 'lodash'
import useWebSocket from '../../../../hooks/useWebSocket'
import ChatUtils from '../../../../functions/chats/ChatUtils'
import ListLoader from '../../../../app/custom/loaders/ListLoader'
import { Link, useSearchParams } from 'react-router-dom'
import useUserType from '../../../../hooks/UseUserType'
import { nanoid } from 'nanoid'
import { type IChatListItem } from '../../../../types'
import dayjs from 'dayjs'
import { LANDLORD_OFFER_CHAT_VISIBLE_DURATION } from '../../../../constants'


export const isPinnedChat = (chat: IChatListItem): boolean => {
    const isDev = process.env.REACT_APP_IS_DEV === 'true'
    if (isDev) {
        return (ChatUtils.isBSOSupport(chat) || ChatUtils.isBSOBroadcast(chat))
    }
    return ChatUtils.isBSOSupport(chat)
}


const ChatList = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const [searchParams] = useSearchParams()
    const favourites = searchParams.get('filter') === 'favourites'
    const blocked = searchParams.get('filter') === 'blocked'
    const { currentUser } = useAppSelector((state: RootState) => state.user)
    const { userType } = useUserType()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const offers = useAppSelector((state) => state.offers.offers)
    /* useEffect(() => {
        setLoading(true)
        dispatch(getChatList())
            .finally(() => {
                setLoading(false)
            })
    }, []) */
    const { chatList } = useAppSelector((state: RootState) => state.chatList)


    // console.log(chatList, 'chatList')
    //  const isPinnedChat = (chat: IChatListItem): boolean => ChatUtils.isBSOSupport(chat) //  || ChatUtils.isBSOBroadcast(chat)


    return (
        <div className={'bg-gray-200 bg-white'} style={{ height: 'calc(100vh - 12rem)' }} >
            <ChatListHeader />
            {loading
                ? <ListLoader height={'calc(100vh - 17rem)'} />
                : <div className={'d-flex flex-column'}>
                    {!favourites && !blocked
                        ? chatList
                            .filter(isPinnedChat)
                            .map((chat, index) => (
                                <ChatCard
                                    key={nanoid()}
                                    blocked={_.some((currentUser as any).BlackList, (user) => user.id === chat.userID)}
                                    favourite={_.some((currentUser as any).FavoriteList, (user) => user.id === chat.userID)}
                                    chat={chat}
                                />
                            ))
                        : <></>
                    }
                    {/*  !favourites && !blocked
                        ? <div style={ currentUser.id === Number(process.env.REACT_APP_BSO_USER_ID) ? { height: '5.5rem' } : { height: '11rem' }}/>
                        : <></>
                    */}
                    <div className={'d-flex flex-column overflow-auto'}>
                        {
                            _.map(
                                favourites || blocked
                                    ? _.filter(chatList, chat =>
                                        _.some(
                                            favourites ? (currentUser as any).FavoriteList : (currentUser as any).BlackList,
                                            favItem => favItem.id === chat.userID
                                        ) && chat?.userID != null
                                    )
                                    : _.filter(
                                        chatList.filter(chat => (
                                            !ChatUtils.isMessageOffer(chat)
                                        )),
                                        (chat) => (
                                            !isPinnedChat(chat) &&
                                            (
                                                chat?.userID != null &&
                                                chat?.userID !== Number(process.env.REACT_APP_BSO_USER_ID) &&
                                                (
                                                    chat?.LastMessage?.updatedAt !== '' ||
                                                    _.includes([/* Number(process.env.REACT_APP_BSO_USER_ID), */Number(process.env.REACT_APP_BSO_SUPPORT_USER_ID)], chat.userID)
                                                ) &&
                                                (
                                                    (
                                                        userType !== 'Landlord' ||
                                                        currentUser.id === Number(process.env.REACT_APP_BSO_USER_ID)
                                                    ) || (
                                                        offers.some((offer) => (
                                                            offer?.attributes?.User?.data?.id === chat.userID &&
                                                            (
                                                                !['Completed', 'Canceled'].includes(offer?.attributes?.Statuses?.[0]) ||
                                                                dayjs().diff(dayjs(offer?.attributes?.updatedAt), 'day', true) < LANDLORD_OFFER_CHAT_VISIBLE_DURATION
                                                            )
                                                        ))
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                (chat, index) => (
                                    <ChatCard
                                        key={nanoid()}
                                        blocked={_.some((currentUser as any).BlackList, (user) => user.id === chat.userID)}
                                        favourite={_.some((currentUser as any).FavoriteList, (user) => user.id === chat.userID)}
                                        chat={chat}
                                        // fixed={ChatUtils.isBSOBroadcast(chat)}
                                        // fixedSecond={ChatUtils.isBSOSupport(chat)}
                                    />
                                ))
                        }
                    </div>
                    {/* <div className={'w-100 d-flex p-4'}>
                        <Link to={`/${userType.toLowerCase()}/search?type=profiles`}>
                            <div className={'btn btn-bso btn-sm'}>Start a new chat</div>
                        </Link>
                    </div> */}
                    { /* <div className="m-0" style={{ height: '12.5rem' }}></div> */}
                    { !(userType === 'Landlord' && currentUser.id !== Number(process.env.REACT_APP_BSO_USER_ID))
                        ? <div className="fixed-bottom mw-450px mx-auto px-4 py-2 bg-white" style={{ bottom: userDevice === 'IPhone' ? '9.65rem' : '8.2rem', left: '0px', zIndex: 1 }}>
                            <Link to={`/${userType.toLowerCase()}/search?type=profiles`}>
                                <div className="btn btn-bso fs-6 p-1 py-4 w-100">
                                    Start a new chat
                                </div >
                            </Link>
                        </div>
                        : <></>
                    }
                </div>
            }
        </div>
    )
}

export default ChatList
