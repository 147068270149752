import { store } from '../../store/store'
import _ from 'lodash'

export default function awaitAction (callback: any, targets: Array<{ path: string, checker: (value: any) => boolean }>): void {
    const checkConditions = (): boolean => targets.every(target => target.checker(_.get(store.getState(), target.path)))
    //  console.log(`Await action for the task ${JSON.stringify(targets.map((target) => target.path))} has started`)
    if (checkConditions()) {
        callback()
        //     console.log(`Await action for the task ${JSON.stringify(targets.map((target) => target.path))} has succeeded`)
        return
    }
    let depth = 0
    const interval = setInterval(() => {
        if (depth < 60) {
            depth++
            if (checkConditions()) {
                clearInterval(interval)
                void callback()
                //         console.log(`Await action for the task ${JSON.stringify(targets.map((target) => target.path))} has succeeded`)
            }
        } else {
            clearInterval(interval)
            //     console.log(`Await action for the task ${JSON.stringify(targets.map((target) => target.path))} has failed. ( 5 minutes timeout )`)
        }
    }, 5000)
}
