import { type AppDispatch, type RootState } from '../../../store'
import { addService } from './addService'
import Swal from 'sweetalert2'
import { swalOptions } from '../../../../functions/swalOptions'
import { setFilterNavigate } from '../../../slices/scheduleSlice'

export const addServiceFindingTenant = (unitId: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const addServiceResponse: any = await dispatch(addService({
        serviceId: 'finding_tenant',
        plan: getState().service.plan,
        dop: getState().service.dop,
        unitId
    }))
    const unit = getState().units.units.find((unitItem) => unitItem.id === unitId)
    if (addServiceResponse.isSuccessful === true) {
        addServiceResponse.textNotification = ''
        dispatch(setFilterNavigate('services'))
        addServiceResponse.textNavigate = `/landlord/schedule/${unitId}`
        if (unit?.attributes?.Marketing == null) {
            await Swal.fire({
                ...swalOptions('success'),
                title: 'Service has been successfully added',
                text: 'Do you want to continue by filling marketing info',
                showConfirmButton: true,
                confirmButtonText: 'Continue',
                showCancelButton: true,
                cancelButtonText: 'Fill later',
                preConfirm: () => {
                    addServiceResponse.textNavigate = `/landlord/modal/landlord_create_unit_marketing/${unitId}/-`
                }
            })
        }
        return addServiceResponse
    }
    return {
        isSuccessful: false
    }
}
