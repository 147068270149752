import { authSlice } from '../../../slices/authSlice'
import { type RootState, type AppDispatch } from '../../../store'
import { type IFields } from '../../../../types/userTypes/documentTypes/TScan'
import {USER_ID_KEY} from "../../../../urls";
import {nanoid} from "nanoid";
import {createDocument} from "../../documentActions";

export const uploadPOA = (formValues: IFields) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const errorRedirect = '/auth/register_upload_poa'
    const successRedirect = '/landlord'
    try {
        dispatch(authSlice.actions.authFetching(true))

        const fileList = formValues.poa
        if (fileList != null && fileList?.length > 0) {
            const RefID = `Power of Attorney-${nanoid()}`
            const poaResponse = await dispatch(createDocument(fileList, {
                RefID,
                Type: [
                    {
                        __component: 'documents.power-of-attorney'
                    }
                ],
                ForRegister: true,
                User: {
                    id: Number(userID)
                },
                Statuses: ['In-process']
            }, undefined, undefined, undefined, RefID))
            dispatch(authSlice.actions.authFetching(false))
            if (poaResponse.isSuccessful && poaResponse.response.error == null) {
                return { textNavigate: successRedirect, isSuccessful: true, textNotification: '' }
            } else {
                return { textNavigate: errorRedirect, isSuccessful: false, textNotification: 'Some error occurred,please try again' }
            }
        }
        dispatch(authSlice.actions.authFetching(false))
        return { textNavigate: successRedirect, isSuccessful: true, textNotification: '' }

    } catch (error) {
        console.log(error)
        dispatch(authSlice.actions.authFetching(false))
        return { textNavigate: errorRedirect, isSuccessful: false, textNotification: 'Some error occurred,please try again' }
    }
}
