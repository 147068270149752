import {type ReactNode} from "react";
import {type Unit} from "../../../../../../../store/slices/unitsSlice";

type IViewUnitInformationHelper = (unit: Unit) => Array<{ label: string, value: ReactNode}>
const viewUnitInformationHelper: IViewUnitInformationHelper = (unit) => {
    const property = unit.attributes.Property
    const marketing = unit.attributes.Marketing
    return [
        {
            label: 'LOCATION',
            value: property?.data?.attributes?.Area
        },
        {
            label: 'BUILDING NAME',
            value: property?.data?.attributes?.Name
        },
        {
            label: 'UNIT NO.',
            value: unit?.attributes?.Number
        },
        {
            label: 'SIZE AREA',
            value: unit?.attributes?.SizeArea != null ? `${unit?.attributes?.SizeArea?.toLocaleString('en')} sqft`: undefined
        },
        {
            label: 'MARKETING USE',
            value: marketing?.Use === 'For rent' ? 'Rent' : marketing?.Use === 'For sale' ? 'Sale' : undefined
        },
        {
            label: 'MARKETING TYPE',
            value: unit?.attributes?.Type
        },
        {
            label: 'PROPERTY TYPE',
            value: property?.data?.attributes?.Type
        },
        {
            label: 'PROPERTY FIT',
            value: unit?.attributes?.Fit
        },
        {
            label: unit?.attributes?.Type === 'Commercial' ? 'ROOMS' : 'BEDROOMS',
            value: unit?.attributes?.Bedrooms === 0 ? 'Studio' : unit?.attributes?.Bedrooms
        },
        {
            label: unit?.attributes?.Type === 'Commercial' ? 'RESTROOMS' : 'BATHROOMS',
            value: unit?.attributes?.Bathrooms
        },
        {
            label: 'PARKING SLOTS',
            value: unit?.attributes?.ParkingSlotsAmount
        }
    ]
}

export default viewUnitInformationHelper