import { getErrorss } from '../../../functions/errors/GetErrors'
import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'
import { queryUSER, urlAPI, ACCESS_KEY, USER_ID_KEY } from '../../../urls'

import { deletingSomething, modalSlice } from '../../slices/modalSlice'
import { type User, userSlice } from '../../slices/userSlice'
import { type RootState, type AppDispatch } from '../../store'
import { uploadStrapiFile } from '../fileActions'

export const updateAvatar = (formValues: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    //  console.log(formValues, 'formValues')
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const { currentUser } = getState().user

    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        const uploadedFile = await uploadStrapiFile(formValues?.avatar?.[0])

        //   console.log(uploadedFile, 'uploadedFile')

        if (uploadedFile?.data) {
            const userType = { ...(currentUser as User).Type, Photo: uploadedFile?.data, __component: 'contacts.individual' }
            const sendObject = {
                method: 'PUT',
                collection: 'users',
                id: Number(userID),
                // query: queryUSER,
                query: getQueryCurrentUser(),
                body: {
                    Type:
                     [userType]
                }
            }

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(sendObject),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const dataJSON = await response.json()
            //  console.log(dataJSON, 'dataJSON')
            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                } else {
                    dispatch(userSlice.actions.userFetchingSuccess(dataJSON.response))
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '/agent/settings', isSuccessful: true, textNotification: 'The avatar has been successfully changed' }
                }
            } else {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: 'Unknown error occurred, try again' }
        }
    } catch (error) {
        console.log(error)
        dispatch(modalSlice.actions.dataFetchingFinish())
    }
}

export const deleteAvatar = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const { currentUser } = getState().user

    try {
        dispatch(deletingSomething(true))
        const userType = { ...(currentUser as User).Type, Photo: null, __component: 'contacts.individual' }
        const sendObject = {
            method: 'PUT',
            collection: 'users',
            id: Number(userID),
            query: getQueryCurrentUser(),
            // query: queryUSER,
            body: {
                Type:
                 [userType]
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()
        //  console.log(dataJSON, 'dataJSON')
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(deletingSomething(false))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(userSlice.actions.userFetchingSuccess(dataJSON.response))
                dispatch(deletingSomething(false))
                return { ...dataJSON, textNavigate: '/agent/settings', isSuccessful: true, textNotification: 'The avatar was deleted' }
            }
        } else {
            dispatch(deletingSomething(false))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(deletingSomething(false))
    }
}
