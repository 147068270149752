import {useCallback, useEffect, useMemo, useState} from "react";
import {useAppSelector} from "../../../../../hooks/redux";
import {type User} from "../../../../../store/slices/userSlice";
import dayjs from "dayjs";

type IUseFindUserByEmail = (row: Record<string, any>, setError: (...args: any) => void) => {
    searchString: string
    setSearchString: (value: string) => void
    findUser: (onChange: (userID: number | undefined) => void) => void
    userData: {
        exists: boolean
        id?: number
        firstNamePlaceholder?: string
        lastNamePlaceholder?: string
        age?: string
        gender?: string
    }
}

const useFindUserByEmail: IUseFindUserByEmail = (row, setError) => {
    const [searchString, setSearchString] = useState('')
    const [user, setUser] = useState<User | undefined>()
    const users = useAppSelector((state) => state.user.allUsers)
    // const currentUser = useAppSelector((state) => state.user.currentUser)

    const findUser = useCallback((onChange: (userID: number | undefined) => void) => {
        const foundUser = users.find((user) => (
            [
                user.email === searchString.trim(),
                // user.id !== currentUser.id
                // TODO Add user category check
            ].every(Boolean)
        ))
        setUser(foundUser)
        onChange(foundUser?.id)
    }, [users, searchString])

    const userData = useMemo(() => {
        const firstName = user?.ContactInfo?.FirstName
        const lastName = user?.ContactInfo?.FamilyName
        const birthDate = user?.ContactInfo?.DateOfBirth
        const age = birthDate != null ? dayjs().diff(dayjs(birthDate), 'years') : undefined
        return {
            exists: user != null,
            id: user?.id,
            firstNamePlaceholder: firstName != null ? `${firstName[0]}${'*'.repeat(firstName.length - 1)}`: undefined,
            lastNamePlaceholder: lastName != null ? `${lastName[0]}${'*'.repeat(lastName.length - 1)}` : undefined,
            age: age != null ? `${age} yo` : undefined,
            gender: user?.ContactInfo?.Gender != null ? user?.ContactInfo?.Gender : undefined
        }
    }, [user])

    useEffect(() => {
        if (!userData.exists) {
            setError(row.id, 'No user has been found')
        }
    }, [])

    return {
        searchString,
        setSearchString,
        findUser,
        userData
    }
}

export default useFindUserByEmail
