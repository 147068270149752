import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { store } from './store/store'
import { ConfigProvider } from 'antd'
import customFetch from './api/customFetch'

interface TokenTheme {
    colorPrimary: string
    colorWarning: string
    fontFamily: string
}

interface AppTheme {
    token: TokenTheme
    components: any
}
const theme: AppTheme = {
    components: {
        // Image: {
        //     zIndexPopup: '1080!important'
        // }
    },
    token: {
        colorPrimary: '#FFF200',
        colorWarning: '#ffc700',
        fontFamily: 'Poppins , Helvetica, sans-serif'
    }
};

/**
 * Custom fetch function to include BSO-Dev headers
 */
(window as any).fetch = customFetch

// Sentry.init({
//     dsn: 'https://44b839636c7da2aa94e9ee00c6a2d85a@sentry.bso.ae/1',

//     // This sets the sample rate to be 10%. You may want this to be 100% while
//     // in development and sample at a lower rate in production
//     replaysSessionSampleRate: 0.1,
//     // If the entire session is not sampled, use the below sample rate to sample
//     // sessions when an error occurs.
//     replaysOnErrorSampleRate: 1.0,

//     integrations: [Sentry.replayIntegration(
//         {
//             maskAllText: false,
//             blockAllMedia: false
//         }

//     )]
// })

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
    //  <React.StrictMode>

    <ConfigProvider theme={theme}>
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    </ConfigProvider>
    // </React.StrictMode>
)

reportWebVitals()
