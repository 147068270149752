import { type AppDispatch, type RootState, store } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { landlordAddUnitWriteToStorage } from '../utils/landlordAddUnitHandleStorage'
import { createDocument } from '../../../documentActions'
import { type Document } from '../../../../slices/documentSlice'
import { addUnit } from './addUnit'
import { type NavigateFunction } from 'react-router-dom'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'
import { nanoid } from 'nanoid'
import {USER_ID_KEY} from "../../../../../urls";

export const scanUnitPowerOfAttorney = (unitID: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    try {
        const fileList = data.poa
        if (fileList != null && fileList?.length > 0) {
            const RefID = `Power of Attorney-${nanoid()}`
            const poaResponse = await dispatch(createDocument(fileList, {
                RefID,
                Type: [
                    {
                        __component: 'documents.power-of-attorney'
                    }
                ]
            }, undefined, undefined, undefined, RefID))
            const POA = poaResponse.response.data as Document

            landlordAddUnitWriteToStorage('poa', {
                id: POA.id
            })
            return {
                textNotification: '',
                textNavigate: `/landlord/modal/landlord_create_unit_info/${nanToNull(unitID) ?? '-'}/-`,
                isSuccessful: true
            }
        }
    } catch (err) {
        console.log('Error while scanning unit power of attorney', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export const skipScanUnitPowerOfAttorney = (navigate: NavigateFunction, unitID?: number) => async () => {
    // const response = await store.dispatch(addUnit('poa_step', nanToNull(unitID)))
    // navigate(response.textNavigate)
}

export const goBackScanUnitPowerOfAttorney = (navigate: NavigateFunction, unitID?: number) => () => {
    navigate(`/landlord/modal/landlord_create_unit_owners_passport/${nanToNull(unitID) ?? '-'}/-`)
}
