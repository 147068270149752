import React, {type ReactElement} from "react";
import Icon from "../../../../app/icon/Icon";
import Badge from "../Badge/Badge";

interface LinkButtonProps {
    text: string
    size: '26' | '38' | '48'
    style?: 'gray' | 'black'
    badge?: {
        position: 'start' | 'end'
        text: string
    }
    onClick?: () => void
}

export default function LinkButton({text, onClick, badge, size, style = 'gray'}: LinkButtonProps): ReactElement {
    let className = 'd-flex justify-content-between align-items-center '
    let iconClassName = ''
    let badgeSize: '10' | '18' = '10'
    const styles: React.CSSProperties = {
        borderRadius: '54px'
    }

    switch (style) {
        case 'gray':
            className += ' bg-gray-100 border border-secondary'
            break
        case 'black':
            className += ' bg-dark text-white'
            break
        default:
            break
    }

    switch (size) {
        case '26':
            className += ' ps-3 pe-1 py-1 fs-8'
            iconClassName += ' fs-1'
            badgeSize = '10'
            break
        case '38':
            className += ' ps-10 pe-6 py-1 fs-5'
            iconClassName += ' fs-4x'
            badgeSize = '18'
            break
        case '48':
            styles.height = '48px'
            className += ' ps-8 pe-4 fs-4 fw-medium'
            iconClassName += ' fs-3x'
            badgeSize = '18'
            break
        default:
            break
    }
    return <div
        className={className}
        style={styles}
        onClick={onClick}
    >
        <div className={'d-flex align-items-center gap-1'}>
            {
                badge?.position === 'start'
                    ? <Badge text={badge.text} style={'dark-gray'} size={badgeSize}/>
                    : <></>
            }
            <div className={'fw-medium'}>
                {
                    text
                }
            </div>
            {
                badge?.position === 'end'
                    ? <Badge text={badge.text} style={'dark-gray'} size={'10'}/>
                    : <></>
            }
        </div>
        <Icon name={'chevron_right'} className={iconClassName} imageSize={48}/>
    </div>
}