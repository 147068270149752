import React, {type ReactElement} from "react";
import {type IconName} from "../../../../app/icon/iconsConfig";
import Icon from "../../../../app/icon/Icon";

interface LinkCardProps {
    icon?: {
        name: IconName,
        filled?: boolean
    }
    image?: string
    title: string
    description: {
        text: string,
        position: 'top' | 'bottom'
    }
    onClick?: () => void
}

export default function LinkCard ({icon, image, title, description, onClick}: LinkCardProps): ReactElement {
    return <div
        className={'d-flex align-items-center gap-5 ps-5 pe-1 py-3 border border-gray-300 shadow-sm'}
        style={{
            borderRadius: '50px'
        }}
        onClick={onClick}
    >
        <div className={'d-flex align-items-center justify-content-center bg-secondary rounded-circle'} style={{
            height: '42px',
            width: '42px'
        }}>
            {
                icon != null
                    ? <Icon
                        name={icon.name}
                        filled={icon.filled === true}
                        className={'fs-4x'}
                        imageSize={24}
                    />
                    : <></>
            }
            {
                icon == null && image != null
                    ? <img src={image}
                        alt={title}
                        style={{
                            maxHeight: '32px',
                            maxWidth: '32px'
                        }}
                    />
                    : <></>
            }
        </div>
        <div className={'d-flex flex-column flex-grow-1'}>
            {
                description.position === 'top'
                    ? <div className={'text-gray-700 fs-6'}>
                        {
                            description.text
                        }
                    </div>
                    : <></>
            }
            <div className={'fs-3'}>
                {
                    title
                }
            </div>
            {
                description.position === 'bottom'
                    ? <div className={'text-gray-700 fs-6'}>
                        {
                            description.text
                        }
                    </div>
                    : <></>
            }
        </div>
        <Icon name={'chevron_right'} className={'fs-5x'} imageSize={48}/>
    </div>
}