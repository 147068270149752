import React, {type ReactElement, type ReactNode} from "react";

interface ViewUnitSectionUnitInformationRowProps {
    label: string
    value: ReactNode
}

export default function ViewUnitSectionUnitInformationRow ({label, value}: ViewUnitSectionUnitInformationRowProps): ReactElement {
    return <div className={'d-flex align-items-center justify-content-between'}>
        <div className={'fs-7 text-gray-600'}>{label.toUpperCase()}</div>
        <div className={'fs-5'}>{value}</div>
    </div>
}