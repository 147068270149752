import React, {type ReactElement, useState} from "react";
import ServicesHeader from "./ServicesHeader/ServicesHeader";
import {useParams} from "react-router";
import {useAppSelector} from "../../../../hooks/redux";
import ServicesFooter from "./ServicesFooter/ServicesFooter";
import SimpleSelectCard from "../../../shared/UIv2/Card/SimpleSelectCard";
import {
    serviceFindingTenantClearStorage
} from "../../../../store/actionsCreators/serviceActions/servicesV2/findingTenant/utils/findingTenantHandleStorage";

export default function ServicesV2 (): ReactElement {
    const { unitID } = useParams()
    const units = useAppSelector((state) => state.units.units)
    const unit = units.find((unitItem) => unitItem.id === Number(unitID))
    const [activeService, setActiveService] = useState<string | undefined>()
    return (
        <div className={'d-flex flex-column p-8 vh-100'}>
            <ServicesHeader unit={unit}/>
            <div className={'flex-grow-1 overflow-auto'}>
                <div className={'d-flex flex-column gap-5 py-6'}>
                    <SimpleSelectCard
                        title={'Finding a Tenant'}
                        topText={'PROPERTY MANAGEMENT'}
                        description={'Lorem ipsum odor amet, consectetuer adipiscing elit. Inceptos elementum mus adipiscing nibh.'}
                        iconSrc={'/assets/media/landlord/services/icons/finding-tenant.svg'}
                        isActive={activeService === 'service_finding_tenant'}
                        setIsActive={() => {
                            serviceFindingTenantClearStorage()
                            setActiveService('service_finding_tenant')
                        }}
                    />
                </div>
            </div>
            <ServicesFooter
                unit={unit}
                activeService={activeService}
            />
        </div>
    )
}