import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { broadcastUnit } from '../../../store/actionsCreators/chatActions'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { type RootState } from '../../../store/store'

import {
    swalAttentionLeave,
    swalInProgress,
    swalNeedLoadDocs,
    swalNeedRera,
    swalOptions,
    swalRejectedDoc,
    swalSuccess
} from '../../../functions/swalOptions'
import { scanDocumentsForOffer } from '../../../store/actionsCreators/offerActions'
import { scanDocuments } from '../../../store/actionsCreators/documentActions'
import { getName, registerLocale } from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
import { setShowPopupAddOnsAreas } from '../../../store/slices/modalSlice'
import { offersSlice } from '../../../store/slices/offersSlice'
import { type IProperty, type Unit } from '../../../store/slices/unitsSlice'
import { setUnitIDnotEnoughAreaForAdvertise, type IAdvertise } from '../../../store/slices/advertisesSlice'
import { USER_ID_KEY } from '../../../urls'
import { isInInterval } from '../../../functions/agent/date/isInInterval'
import { getOfferType } from '../../../functions/agent/offers/getOfferType'
import { type ISettings } from '../../../store/slices/userSlice'
import swalTexts from '../../../app/texts/swalTexts'
import useUserType from '../../../hooks/UseUserType'
import { areUnitActionButtonsBlocked } from '../../../functions/shared/units/getUnitOfferStatus'
import openAddViewingModal from '../../../store/actionsCreators/viewingActions/openAddViewingModal'
import { setUnitHidden, setUnitSold } from '../../../store/actionsCreators/unitsActions'
import { setWhatIsNotThereArea } from '../../../store/slices/areasSlice'
import getUserType from '../../../functions/shared/user/getUserType'
import { openEditUnitSwitch } from '../../../store/actionsCreators/unitActions/landlordUnitsActions/utils/openEditUnitSwitch'
import {
    checkIfUnitIsInValidationProcess
} from '../../../store/actionsCreators/unitActions/landlordUnitsActions/utils/checkIfUnitIsISInValidationProcess'
registerLocale(en)

interface IProps {
    unit: Unit
    fromEntry: string | undefined
    settings?: ISettings[]
}

export default function ButtonsActionsSh ({ fromEntry, unit, settings }: IProps): JSX.Element {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const userID = window.localStorage.getItem(USER_ID_KEY)

    const { userType, userTypeLowercase } = useUserType()
    const userAreas = settings?.[0]?.Areas ?? []
    const unitArea =
        (unit?.attributes?.Property?.data?.attributes as any)?.Area ??
        (unit?.attributes?.Property as any)?.Area ??
        ''
    const propertyID = (unit?.attributes?.Property?.data?.id as any) ??
        (unit?.attributes?.Property as any)?.id ??
        ''

    const blockAgentButtons = areUnitActionButtonsBlocked(unit)
    let isAdvertiseArea = false
    if (userAreas?.includes(unitArea) || userAreas?.includes('All areas')) {
        isAdvertiseArea = true
    }
    const advertisesState = useAppSelector((state: RootState) => state.advertises.advertises)
    const [advertises, setAdvertise] = useState(advertisesState.filter((adv: IAdvertise) => adv.attributes.Unit.data?.id === unit.id))

    const { statusesApproved, documentsLoaded, documentsInProgress, documentsRejected, documentsExpires } = useAppSelector(
        (state: RootState) => state.agentStates
    )

    const verifiedStatus = statusesApproved.emiratesApproved || statusesApproved.passportApproved
    const verifiedInProgress = documentsInProgress.emiratesInProgress || documentsInProgress.passportInProgress
    const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.passportLoaded
    const rejSts = documentsRejected.passportReject || documentsRejected.emiratesReject
    const expSts = documentsExpires.emiratesExpire || documentsExpires.passportExpire

    const [openAlert, setOpenAlert] = useState(false)
    const [openAlertAddv, setOpenAlertAddv] = useState(false)
    const [actionLoading, setActionLoading] = useState(false)

    const onBlockAdvertise = (): void => {
        return void swalAttentionLeave(swalTexts.attentionLeave.messageForAgentButtonActions).then(async (result: any) => {
            if (result.isConfirmed) {
                dispatch(setShowPopupAddOnsAreas(true))
                dispatch(setUnitIDnotEnoughAreaForAdvertise(unit.id))
                dispatch(setWhatIsNotThereArea(unitArea))
                window.localStorage.setItem('chooseAreaExt', unitArea)
            }
        }).catch(console.log)
    }

    useEffect(() => {
        if (openAlert) {
            if (!verifiedStatus && !verifiedInProgress && userType === 'Agent') {
                if (rejSts) {
                    void swalRejectedDoc(`Your ${documentsRejected.emiratesReject ? 'EmiratesID' : 'Passport'} did rejected, please update documents, to again access to this application\'s functionality `).then(async (result: any) => {
                        if (result?.isConfirmed === true) {
                            setOpenAlert(false)
                            if (documentsRejected.emiratesReject) {
                                //  navigate('/agent/modal/update_emirates_front/units/not')
                                navigate('/agent/modal/update_emirates_front_together/not/not')
                            } else {
                                // navigate('/agent/modal/update_passport/units/not')
                                navigate('/agent/modal/update_passport_together/not/not')
                            }
                        }
                    })
                } if (expSts) {
                    void swalRejectedDoc(`Your ${documentsExpires.emiratesExpire ? 'EmiratesID' : 'Passport'} has expired, please update documents, to again access to this application\'s functionality `).then(async (result: any) => {
                        if (result?.isConfirmed === true) {
                            setOpenAlert(false)
                            if (documentsExpires.emiratesExpire) {
                                navigate('/agent/modal/update_emirates_front_together/not/not')
                                //   navigate('/agent/modal/update_emirates_front/units/not')
                            } else {
                                navigate('/agent/modal/update_passport_together/not/not')
                                //  navigate('/agent/modal/update_passport/units/not')
                            }
                        }
                    })
                }
            } if (verifiedInProgress) {
                void swalInProgress(swalTexts.inProgress.message).then(async (result: any) => {
                    if (result.isConfirmed) {
                        setOpenAlert(false)
                    }
                })
            }
        } if (openAlertAddv) {
            if (!documentsLoaded.licenseLoaded) {
                void swalNeedRera(swalTexts.needRera.messageLicenseLoaded, 'Warning', "Attention, you do not have a RERA card").then(async (result: any) => {
                    if (result.isConfirmed) {
                        setOpenAlertAddv(false)
                    } if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    }
                })
            } if (documentsRejected.licenseReject) {
                void swalNeedRera(swalTexts.needRera.messageLicenseReject, 'Error').then(async (result: any) => {
                    if (result.isConfirmed) {
                        setOpenAlertAddv(false)
                    } if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    }
                })
            } if (documentsExpires.licenseExpire) {
                void swalNeedRera(swalTexts.needRera.messageLicenseExpire, 'Error').then(async (result: any) => {
                    if (result.isConfirmed) {
                        setOpenAlertAddv(false)
                    } if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    }
                })
            } if (documentsInProgress.licenseInProgress) {
                void swalInProgress(swalTexts.inProgress.messageForRera).then(async (result: any) => {
                    if (result.isConfirmed) {
                        setOpenAlertAddv(false)
                    }
                })
            }
        }
    }, [openAlert, openAlertAddv])

    let isAdvertising = false
    let isReservOtherAgents = false
    const MIN_MATCHING_ADVERTISEMENTS = 3
    let offerModalType = ''
    let advertiseID = 'not'

    offerModalType = getOfferType(unit)

    const isAdvFind = advertises.find((adv: IAdvertise) => {
        return adv.attributes.User.data?.id === Number(userID) && adv.attributes.Unit.data?.id === unit.id && !adv.attributes.Statuses?.includes('Completed')
    })

    if (isAdvFind != null) {
        if (isAdvFind?.attributes?.StartDate && isAdvFind.attributes.EndDate) {
            if (isInInterval(isAdvFind.attributes.StartDate, isAdvFind.attributes.EndDate) || !isAdvFind.attributes.Statuses?.includes('Completed')) {
                isAdvertising = true
                advertiseID = String((isAdvFind).id)
            }
        } else {
            isAdvertising = true
            //   advertiseID = String((advertises.find((adv: IAdvertise) => adv.attributes.User.data?.id === Number(userID) && adv.attributes.Unit.data?.id === unit.id && isInInterval(adv.attributes.StartDate as string, adv.attributes.EndDate as string)) as IAdvertise).id)
            advertiseID = String((isAdvFind).id)
        }
    }

    const matchingAdvertsFromOtherUsers = advertises.filter((adv: IAdvertise) =>
        adv.attributes.User.data?.id !== Number(userID) &&
        adv.attributes.Unit.data?.id === unit.id && (adv?.attributes?.Statuses?.includes('Advertisement started') || adv?.attributes?.Statuses?.includes('Docs received') || adv?.attributes?.Statuses?.includes('Approved')) &&
        isInInterval(adv.attributes.StartDate as string, adv.attributes.EndDate as string)
    )

    if (matchingAdvertsFromOtherUsers.length >= MIN_MATCHING_ADVERTISEMENTS) {
        isReservOtherAgents = true
    }

    useEffect(() => {
        //   advertises = unit.attributes?.Advertises?.data ?? []
        if (userAreas?.includes(unitArea) || userAreas?.includes('All areas')) {
            isAdvertiseArea = true
        }

        const isAdvFind = advertises.find((adv: IAdvertise) => {
            // console.log(adv, 'adv')
            return adv.attributes.User.data?.id === Number(userID) && adv.attributes.Unit.data?.id === unit.id && !adv.attributes.Statuses?.includes('Completed')
        })
        //  console.log(isAdvFind, 'isAdvFind')
        if (isAdvFind != null) {
            if (isAdvFind?.attributes?.StartDate && isAdvFind.attributes.EndDate) {
                if (isInInterval(isAdvFind.attributes.StartDate, isAdvFind.attributes.EndDate) || !isAdvFind.attributes.Statuses?.includes('Completed')) {
                    isAdvertising = true
                    advertiseID = String((isAdvFind).id)
                }
            } else {
                isAdvertising = true
                //   advertiseID = String((advertises.find((adv: IAdvertise) => adv.attributes.User.data?.id === Number(userID) && adv.attributes.Unit.data?.id === unit.id && isInInterval(adv.attributes.StartDate as string, adv.attributes.EndDate as string)) as IAdvertise).id)
                advertiseID = String((isAdvFind).id)
            }
        }
        // if (advertises.find((adv: IAdvertise) => adv.attributes.User.data?.id === Number(userID) && adv.attributes.Unit.data?.id === unit.id && isInInterval(adv.attributes.StartDate as string, adv.attributes.EndDate as string)) != null) {
        //     isAdvertising = true
        //     advertiseID = String((advertises.find((adv: IAdvertise) => adv.attributes.User.data?.id === Number(userID) && adv.attributes.Unit.data?.id === unit.id && isInInterval(adv.attributes.StartDate as string, adv.attributes.EndDate as string)) as IAdvertise).id)
        // }
    }, [advertisesState])

    const onBlockLoadDocuments = (): void => {
        void swalNeedLoadDocs().then(async (result: any) => {
            if (result?.isConfirmed) {
                navigate('/agent/modal/update_emirates_front_together/INagentMODULE/to_schoose_subscription')
            } if (result.isDismissed) {
                console.log('cancel')
            }
        })
    }
    const unitIsInValidationProcess = dispatch(checkIfUnitIsInValidationProcess(unit.id)) === true
    // console.log(propertyID, 'propertyID')
    return (
        <div className="d-flex flex-column m-0 px-3"
            onClick={loadSts || userType !== 'Agent'
                ? !verifiedStatus && userType === 'Agent'
                    ? () => { setOpenAlert(true) }
                    : undefined
                : () => { onBlockLoadDocuments() }}
        >
            <div className="d-flex flex-row row g-0 gx-4 mb-0">
                {
                    // getUserType({ checkForAdmin: true }) === 'Landlord' && (unit.attributes.Marketing == null || unitIsInValidationProcess)
                    // ? !unitIsInValidationProcess
                    //     ? <>
                    //         <div className="col-12">
                    //             <Link to={`/landlord/modal/landlord_create_unit_marketing/${unit.id}/-`}>
                    //                 <div className={'btn btn-bso  m-0 fs-6 p-1 py-3  w-100'}>
                    //                     Add marketing info
                    //                 </div>
                    //             </Link>
                    //         </div>
                    //     </>
                    //     : <></>
                    // :
                    <>
                        {(fromEntry === 'nav' || fromEntry == null) && (
                            <>
                                { unit?.attributes?.User?.data?.id === Number(process.env.REACT_APP_BSO_USER_ID)
                                    ? <div className="col">
                                        <div
                                            onClick={
                                                loadSts
                                                    ? verifiedStatus
                                                        ? statusesApproved.licenseApproved && !blockAgentButtons
                                                            ? isAdvertiseArea
                                                                ? !isAdvertising && isReservOtherAgents
                                                                    ? undefined
                                                                    : () => {
                                                                        navigate(isAdvertising
                                                                            ? `/agent/modalView/advertise_modal/${unit.id}/${advertiseID}/not`
                                                                            : `/agent/modal/agent_advertise_create_duration/${unit.id}/${userID}`)
                                                                    }
                                                                : () => { onBlockAdvertise() }
                                                            : () => { setOpenAlertAddv(true) }
                                                        : undefined
                                                    : undefined
                                            }

                                            className={`btn ${isAdvertiseArea ? isAdvertising ? 'btn-success' : 'btn-dark' : 'btn-secondary'}   ${blockAgentButtons ? 'opacity-50' : 'opacity-100'} m-0 fs-6 p-1 py-3 w-100`}>
                                            {isAdvertising ? 'Advertised' : 'Advertise'} {!isAdvertising && isReservOtherAgents ? <i className="bi bi-lock-fill fs-2x text-white"></i> : null}
                                        </div>
                                    </div>
                                    : <></>
                                }
                                <div className="col">
                                    <div onClick={
                                        loadSts
                                            ? verifiedStatus
                                                ? (e) => {
                                                    e.preventDefault()
                                                    if (!blockAgentButtons) {
                                                        openAddViewingModal(unit, navigate, dispatch)
                                                    }
                                                }
                                                : undefined
                                            : undefined
                                    } className={`book-viewing-button fs-6 h-100 fw-semibold py-3 ${blockAgentButtons ? 'opacity-50' : 'opacity-100'}`} >Book viewing</div>

                                </div>
                                <div className="col">
                                    <div className={`btn btn-bso  m-0 fs-6 p-1 py-3  w-100 ${blockAgentButtons ? 'opacity-50' : 'opacity-100'}`}
                                        onClick={loadSts
                                            ? verifiedStatus
                                                ? () => {
                                                    if (!blockAgentButtons) {
                                                        void dispatch(scanDocumentsForOffer(unit.id, offerModalType, navigate, false))
                                                    }
                                                }
                                                : undefined
                                            : undefined}
                                    >
                                        Make offer
                                    </div>
                                </div>
                            </>
                        )}
                        {fromEntry === 'makeOffer' && (
                            <div className="col-12">
                                <div className={`btn btn-bso  m-0 fs-6 p-1 py-3  w-100 ${blockAgentButtons ? 'opacity-50' : 'opacity-100'}`}
                                    onClick={loadSts
                                        ? verifiedStatus
                                            ? () => {
                                                if (!blockAgentButtons) {
                                                    void dispatch(scanDocumentsForOffer(unit.id, offerModalType, navigate, false))
                                                }
                                            }
                                            : undefined
                                        : undefined

                                    }
                                >Make offer</div>
                            </div>
                        )}
                        {fromEntry === 'bookViewing' && (
                            <div className="col-12">

                                <div onClick={
                                    loadSts
                                        ? verifiedStatus
                                            ? (e) => {
                                                e.preventDefault()
                                                if (!blockAgentButtons) {
                                                    openAddViewingModal(unit, navigate, dispatch)
                                                }
                                            }
                                            : undefined
                                        : undefined} className={`btn btn-bso  m-0 fs-6 p-1 py-3  w-100 ${blockAgentButtons ? 'opacity-50' : 'opacity-100'}`}>Book viewing</div>
                            </div>
                        )}
                        {fromEntry === 'catalogAgent' && (
                            <>
                                <div className="col-6">
                                    <div className="btn btn-bso  m-0 fs-6 p-1 py-3 w-100" onClick={statusesApproved.licenseApproved ? () => { } : () => { setOpenAlertAddv(true) }} >Advertise</div>
                                </div>
                                <div className="col-6">
                                    <Link to={'#'} className="text-decoration-none">
                                        <div className="btn btn-bso  m-0 fs-6 p-1 py-3  w-100">Send message</div>
                                    </Link>
                                    {/* <Link to={`/agent/modal/agent_set_viewing_modal/${id}/${id}`} className="text-decoration-none">
                                <div className="btn btn-bso  m-0 fs-6 p-1 py-3  w-100">Book viewing</div>
                            </Link> */}
                                </div>
                            </>
                        )}
                        {fromEntry === 'myUnitsAgent' && (
                            !actionLoading
                                ? <>
                                    {userType === 'Agent'
                                        ? <div className="col">
                                            <Link to={`/${userTypeLowercase}/modal/agent_edit_unit/${unit.id}/${propertyID}`} className="text-decoration-none">
                                                <div className="btn btn-bso  m-0 fs-6 p-1 py-3 w-100">Edit</div>
                                            </Link>
                                        </div>
                                        : getUserType() === 'Admin'
                                            ? <div className="col">
                                                <Link to={`/${userTypeLowercase}/set_unit_modal/edit_unit_modal/${unit.id}/-`} className="text-decoration-none">
                                                    <div className="btn btn-bso  m-0 fs-6 p-1 py-3 w-100">Edit</div>
                                                </Link>
                                            </div>
                                            : <div className="col">
                                                <div className="btn btn-bso  m-0 fs-6 p-1 py-3 w-100"
                                                    onClick={openEditUnitSwitch(unit, navigate)}
                                                >
                                                    Edit
                                                </div>
                                            </div>
                                    }
                                    {
                                        getUserType() === 'Admin'
                                            ? <>
                                                {unit?.attributes?.Statuses != null && unit?.attributes?.Statuses.length > 0 &&
                                                unit.attributes.Statuses?.includes('Sold')
                                                    ? <div className="col" onClick={() => {
                                                        void swalAttentionLeave('Are you sure want to set enabled ?').then(async (result: any) => {
                                                            if (result.isConfirmed) {
                                                                setActionLoading(true)
                                                                void dispatch(setUnitSold(unit.id, true))
                                                                    .finally(() => {
                                                                        setActionLoading(false)
                                                                    })
                                                            }
                                                        }).catch(console.log)
                                                    }}>
                                                        <div className="book-viewing-button fs-6 h-100 fw-semi-bold py-3 cursor-pointer">Set enabled</div>
                                                    </div>
                                                    : <div className="col" onClick={() => {
                                                        void swalAttentionLeave(`Are you sure want to set ${unit?.attributes?.Marketing?.Use === 'For sale' ? 'sold' : 'rented'}  ?`).then(async (result: any) => {
                                                            if (result.isConfirmed) {
                                                                setActionLoading(true)
                                                                void dispatch(setUnitSold(unit.id, false))
                                                                    .finally(() => {
                                                                        setActionLoading(false)
                                                                    })
                                                            }
                                                        }).catch(console.log)
                                                    }}>
                                                        <div className="btn btn-dark fs-6 h-100 fw-semibold py-3 cursor-pointer w-100">
                                                            {
                                                                unit?.attributes?.Marketing?.Use === 'For sale'
                                                                    ? <>Set sold</>
                                                                    : <>Set rented</>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (unit?.attributes?.Statuses?.includes('Drafted')
                                                        ? <div className="col" onClick={() => {
                                                            setActionLoading(true)
                                                            void dispatch(setUnitHidden(unit.id, true))
                                                                .finally(() => {
                                                                    setActionLoading(false)
                                                                })
                                                        }}>
                                                            <div className="book-viewing-button fs-6 h-100 fw-semibold py-3 cursor-pointer">Show</div>
                                                        </div>
                                                        : <div className="col" onClick={() => {
                                                            setActionLoading(true)
                                                            void dispatch(setUnitHidden(unit.id, false))
                                                                .finally(() => {
                                                                    setActionLoading(false)
                                                                })
                                                        }}>
                                                            <div className="btn btn-dark fs-6 h-100 fw-semibold py-3 cursor-pointer w-100">
                                                                Hide
                                                            </div>
                                                        </div>)
                                                }
                                            </>
                                            : <></>
                                    }
                                    {
                                        getUserType() === 'Landlord'
                                            ? <>
                                                <div className="col">
                                                    <div className="btn btn-bso m-0 fs-6 p-1 py-3 w-100"
                                                        onClick={() => {
                                                            navigate(`/landlord/services/${unit.id}`)
                                                        }}
                                                    >
                                                        Order service
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="btn btn-bso m-0 fs-6 p-1 py-3 w-100"
                                                        onClick={() => {
                                                            navigate(`/landlord/schedule/${unit.id}`)
                                                        }}
                                                    >
                                                        Tasks
                                                    </div>
                                                </div>
                                            </>
                                            : <></>
                                    }
                                    {userType !== 'Agent'

                                        ? <></>
                                        : <div className="col">
                                            <div className="btn btn-bso m-0 fs-6 p-1 py-3 w-100"
                                                onClick={() => {
                                                    void swalAttentionLeave('Are you sure want to broadcast this unit ?').then(async (result: any) => {
                                                        if (result.isConfirmed) {
                                                            navigate(`/${userTypeLowercase}/modal/agent_set_broadcast/${unit.id}/-`)
                                                        }
                                                    }).catch(console.log)
                                                }}
                                            >
                                                Broadcast
                                            </div>
                                        </div>

                                    }
                                </>

                                /* <div className="col-6">
                                <div className="btn btn-bso  m-0 fs-6 p-1 py-3  w-100"
                                    onClick={() => {
                                        dispatch(broadcastUnit(unit.id, (broadcastID) => {
                                            navigate(`/${userTypeLowercase}/chats/${broadcastID}`)
                                        }))
                                    }}
                                >
                                    Broadcast
                                </div>
                            </div> */
                                : <>
                                    <div className="btn btn-bso  m-0 fs-6 p-1 py-3 w-100">Loading...</div>
                                </>
                        )}
                    </>
                }
            </div>

        </div>

    )
}