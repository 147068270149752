import React, {type ReactElement} from "react";
import {useNavigate} from "react-router-dom";
import Markdown from "react-markdown";

interface ModalDescriptionProps {
    row: Record<string, any>
}

export default function ModalDescription ({row}: ModalDescriptionProps): ReactElement {
    const navigate = useNavigate()
    return (
        <div className={`fs-5 fw-medium text-center text-gray-400 pt-4 ${row.paddingX === 'small' ? '' : 'px-8'} ${row.paddingBottom === 'small' ? 'pb-8' : 'pb-20'}`}>
            {
                row.markdown === true
                    ? <Markdown className={'m-0'}>{row.content[0]}</Markdown>
                    : row.content[0]
            } {
                row.content[1] != null && row.content[2] != null
                    ? <span className={'text-decoration-underline'}
                        onClick={() => {
                            navigate(row.content[2])
                        }}
                    >
                        {row.content[1]}
                    </span>
                    : <></>
            }
        </div>
    )
}