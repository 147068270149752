import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { timeStringInScheduler } from '../../../../functions/agent/date/timeStringInScheduler'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { generateBgAndBorderCol } from '../../../../functions/agent/schedule/generateBgAndBorderCol'
import { type RootState } from '../../../../store/store'

import { nanoid } from 'nanoid'
import UseUserType, { type UserType } from '../../../../hooks/UseUserType'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
import Icon from '../../../../app/icon/Icon'
import { getBelongsUnitForViewing } from '../../../../functions/shared/schedule/getBelongsUnit'

export default function ScheduleViewingAttention({ view }: any): JSX.Element {
    const { userType, userTypeLowercase } = UseUserType()
    const userID = window.localStorage.getItem(USER_ID_KEY)
    let notifyApp: UserType | 'BSO' = userType
    if (userID === REACT_APP_BSO_USER_ID) {
        notifyApp = 'BSO'
    }
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    const [statusNotify, setStatusNotify] = useState(view?.attributes?.Notify)

    const currentUnit = useAppSelector(
        (state: RootState) => state.units.units
    )?.filter(
        (unit) => unit.id === Number(view?.attributes?.Unit?.data?.id)
    )[0]

    let task = getBelongsUnitForViewing(view)

    const [showBadge, setShowBadge] = useState(false)

    const [date, time] = timeStringInScheduler(
        view?.attributes?.createdAt
    ).split(',')
    const [day, mounth, year] = date.split(' ')
    let unitNumber, buildName, location
    if (view?.attributes?.Name) {
        [unitNumber, buildName, location] = view?.attributes?.Name?.split(' <-> ')
    } else {
        [unitNumber, buildName, location] = [
            'Not number',
            'Not title',
            'Not location'
        ]
    }
    const [dateDubai, timeDubai] = timeStringInScheduler(
        view?.attributes?.Datetime ?? ''
    ).split(',')

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (notifyApp === 'Agent') {
            if (task.type === 'MY UNIT' && view?.attributes.User?.data?.id !== Number(userID) && statusNotify === 'Other Agent') {
                setShowBadge(true)
            } else if (task.type === 'BSO UNIT' && view?.attributes.User?.data?.id === Number(userID) && statusNotify === 'Agent') {
                setShowBadge(true)
            } else if (task.type === 'OTHER AGENT UNIT' && view?.attributes.User?.data?.id === Number(userID) && statusNotify === 'Agent') {
                setShowBadge(true)
            }
        } else if (notifyApp === 'BSO' && notifyApp === statusNotify) {
            setShowBadge(true)
        }
        task = getBelongsUnitForViewing(view)
        setStatusNotify(view?.attributes?.Notify)
    }, [view, dispatch])

    const createdViewingAgent = useAppSelector(
        (state) => state.user.allUsers
    )?.filter(
        (user) => user.id === Number(view?.attributes?.User?.data?.id)
    )[0]

    return (
        <Link
            // to={`/${userTypeLowercase}/modalView/${notifyApp === 'BSO' ? 'magic_' : ''}viewing_modal/${view?.attributes?.Unit?.data?.id}/${view?.id}/attention`}
            to={userTypeLowercase === 'agent'
                ? `/${userTypeLowercase}/modalView/viewing_modal/${view?.attributes?.Unit?.data?.id}/${view?.id}/${task.type === 'MY UNIT' ? 'my_unit' : currentUnit?.attributes?.Statuses.includes('Door close') ? 'other_agent_s_unit' : 'calendar'}`
                : `/${userTypeLowercase}/modalView/${notifyApp === 'BSO' ? 'magic_' : ''}viewing_modal/${view?.attributes?.Unit?.data?.id}/${view?.id}/calendar`
            }
            className="text-decoration-none"
        >
            <div
                className={`d-flex flex-row row card shadow-sm g-0 p-2 px-3 m-0 align-items-center justify-content-between position-relative border border-1 border-${generateBgAndBorderCol(view).borderColor} bg-light-${generateBgAndBorderCol(view).borderColor}`}
            >

                {showBadge
                    ? (
                        <span
                            className="position-absolute end-0 badge badge-danger w-auto mx-auto px-auto text-end"
                            style={{ top: -9 }}
                        >
                            {timeStringInScheduler(view.attributes.updatedAt ?? view.attributes.createAt)}
                        </span>

                    )
                    : null}

                <div className="d-flex flex-column col-2 lh-1">
                    {isDev
                        ? notifyApp !== 'BSO' && <span className={`position-absolute badge  badge-light-${generateBgAndBorderCol(view).textColor} border border-${generateBgAndBorderCol(view).borderColor} text-${generateBgAndBorderCol(view).textColor} p-1`} style={{ top: '-7px', left: '-5px' }}>
                            {task.type}
                        </span>
                        : null}
                    <div className="w-50px d-flex flex-column align-items-center justify-content-start  px-2 col-2">
                        <span className="fs-4x text-dark">{`${day}`}</span>
                        <div>  <span className="text-dark fs-6 text-uppercase">{mounth}</span>
                            <span className=" fs-8 text-gray-700 mt-1">{`${year?.slice(2)}`}</span></div>
                        <div className={`badge badge-light-${generateBgAndBorderCol(view).textColor} border text-${generateBgAndBorderCol(view).textColor}  fs-9 px-2 d-inline`}>Viewing</div>
                    </div>
                    {/* <span className="fs-4 text-dark mt-1">{`${time}`}</span> */}
                </div>

                <div className="d-flex flex-column col-6">
                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'door_open'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>{unitNumber}, {buildName}</span>
                    </span>
                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'location_on'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>{location}{' '}</span>
                    </span>

                    {/* <div className="badge badge-light-info  border border-info fs-8 px-1 d-inline">Viewing</div> */}
                    {task.type !== 'BSO UNIT' && <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'person'}
                            className={''}
                            styles={{ minWidth: '12.9px' }}
                        /><div className="">
                            <span className={'text-dots-app'}>
                                {createdViewingAgent?.ContactInfo?.FirstName != null
                                    ? `${String(createdViewingAgent?.ContactInfo?.FirstName)} ${String(createdViewingAgent?.ContactInfo?.FamilyName)}`
                                    : 'No name'
                                }
                            </span>
                            {/* <div className="fs-8 text-gray-700 my-n2"> {task.type === 'OTHER AGENT UNIT' ? 'Owner\'s unit' : 'Agent'}</div> */}
                        </div>
                    </span>
                    }
                    {!currentUnit?.attributes?.Statuses?.includes('Door close') &&

                        <span className="fs-6 fw-bold text-dark d-flex align-items-center gap-2">
                            <Icon
                                name={'info'}
                                styles={{ minWidth: '12.9px' }}
                            />
                            <span className={'text-dots-app'}>
                                {currentUnit?.attributes?.Statuses?.includes('Door open')
                                    ? 'Door open'
                                    : ''}
                                {currentUnit?.attributes?.Statuses?.includes('Digital lock')
                                    ? 'Digital lock'
                                    : ''}
                                {currentUnit?.attributes?.Statuses?.includes('Keys in BSO office')
                                    ? 'Keys in BSO office'
                                    : ''}
                                {currentUnit?.attributes?.Statuses?.includes('Occupied')
                                    ? 'Occupied'
                                    : ''}
                                {currentUnit?.attributes?.Statuses?.includes('Building Management')
                                    ? 'Building management'
                                    : ''}
                            </span>
                        </span>}
                </div>

                <div className="d-flex flex-column col-4 align-items-end justify-content-center">
                    <div className="fs-8 text-gray-700">Viewing status </div>
                    {view?.attributes?.Statuses?.map((el: any) => (
                        <div key={nanoid()} className="fs-1x fw-bold text-dark lh-1 text-end">
                            <span
                                className={`me-1 badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderCol(view).borderColor} bg-light-${generateBgAndBorderCol(view).borderColor}`}
                            >
                            </span>
                            {el}
                        </div>
                    ))}
                    <span className="fs-8 text-gray-700">Viewing time </span>
                    <div className="fs-5 pe-2 fw-bold text-dark">
                        {timeDubai
                            ? <>  <span className="bi bi-clock me-2"></span>
                                {timeDubai}
                            </>
                            : <>
                                <div className="fs-8 lh-1 fw-bold border-light-danger text-bold text-danger my-1 text-end">Waiting time expired</div>
                            </>}
                    </div>

                </div>
            </div>
        </Link>
    )
}
