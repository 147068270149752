import swalTexts from "../../../../app/texts/swalTexts"
import { isTomorrow } from "../../../../functions/agent/date/isTommorow"
import { getQueryDocuments } from "../../../../functions/shared/api/queries/getQueryDocuments"
import { isToday } from "../../../../functions/shared/date/isToday"
import { ACCESS_KEY, urlAPI, USER_ID_BACK_ID_LOAD, USER_ID_FRONT_ID_KEY, USER_ID_KEY } from "../../../../urls"
import { uploadSingleFile } from "./uploadSingleFile"

export const uploadFiles = async (formValues: Record<string, any>, fileKeys: string[]): Promise<any> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''

    console.log(formValues , "formValues" )
    console.log(fileKeys , "fileKeys" )
    
    try {
        const promises = fileKeys.map(async (fileKey) => {
            const file = formValues[fileKey]?.[0]
            console.log(file,"file")
            if (!file) return null
            const url = await uploadSingleFile(file)
            console.log(url,"url")
            return { key: fileKey, url }
        })
        console.log(promises,"promises")
        const uploadedFiles = (await Promise.all(promises)).filter(Boolean)
        console.log(uploadedFiles,"uploadedFiles")
        const sendBodies = uploadedFiles.map((file, i) => {
            let expiryDate, type , category
            if (file != null) {
                const { key, url } = file
                if (key === 'id_back') {
                    expiryDate = formValues.id_back_expiry || formValues.id_back_expiry
                    type = {
                        __component: 'documents.emirates-id',
                        ExpiryDate: expiryDate,
                        Name: formValues?.name  || formValues?.name ,
                        Number: formValues?.number || formValues?.number ,
                        DateOfBirth: formValues?.dob ||formValues?.dob,
                        Gender: formValues?.gender ||  formValues?.gender,
                        Nationality: formValues?.nationality ||formValues?.nationality 
                    }

                    return {
                        method: window.localStorage.getItem(USER_ID_FRONT_ID_KEY) != null ? 'PUT' : 'POST',
                        collection: 'documents',
                        id: window.localStorage.getItem(USER_ID_FRONT_ID_KEY) != null ? Number(window.localStorage.getItem(USER_ID_FRONT_ID_KEY)) : undefined,
                        query: getQueryDocuments(),
                        body: {
                            RefID: `REF_DOC<->emirates_id<->${formValues?.number || "not_number"}<->${new Date().getTime()}`, 
                            Type: [type],
                            LinkBack: url,
                            ExpiryDate: expiryDate,
                            Statuses: [formValues.status],
                            User: userID,
                            Category : "Emirates ID",
                            ForRegister : formValues.ForRegister
                        }
                    }
                } else if (key === 'visa') {
                    expiryDate = formValues?.visa_expiry
                    category = "Passport"
                    type = {
                        __component: 'documents.passport',
                        ExpiryDate: expiryDate,
                        Name: formValues?.name,
                        Number: formValues?.number,
                        DateOfBirth: formValues?.dob,
                        Gender: formValues?.gender,
                        Country: formValues?.nationality
                    }
                        
                } else if (key === 'rera') {
                    category = "RERA Card"
                    expiryDate = formValues.rera_expiry
                    type = {
                        __component: 'documents.rera-card',
                        Number: formValues?.number,
                        Name: formValues?.name,
                        // Email: formValues?.rera_email,
                        // Phone: formValues?.rera_phone,
                        CompanyName: formValues?.companyName,
                        IssueDate: formValues?.rera_issue
                    }
                } else if (key === 'agent_create_unit_title_deed') {
                    category = "Title Deed"
                    type = {
                        __component: 'documents.title-deed',
                        Number: formValues?.number,
                        Propety: {
                            Number: formValues.property.number,
                            Name: formValues.property.name,
                            Area: formValues.property.area,
                            Type: formValues.property.type,
                            MAKANI: formValues.property.makani
                        }
                    }
                } else if (key === 'id_front') {
                    type = {
                        __component: 'documents.emirates-id',
                        ExpiryDate: expiryDate,
                        Name: formValues?.name,
                        Number: formValues?.number,
                        DateOfBirth: formValues?.dob != null && formValues?.dob !== '' ? formValues?.dob : undefined,
                        Gender: formValues?.gender,
                        Nationality: formValues?.nationality
                    }
                    return {
                        method: 'POST',
                        collection: 'documents',
                        query: getQueryDocuments(),
                        body: {
                            RefID: `REF_DOC<->emirates_id<->${formValues?.number || "not_number"}<->${new Date().getTime()}`, // <->${new Date().getTime()}_${i} <->${new Date().getTime()}
                            Type: [type],
                            LinkFront: url,
                            ExpiryDate: expiryDate,
                            Statuses: [formValues.status],
                            User: userID
                        }
                    }
                }

                return {
                    method: 'POST',
                    collection: 'documents',
                    query: getQueryDocuments(),
                    body: {
                        RefID: `REF_DOC<->${key === 'agent_edit_unit_title_deed' ? 'agent_create_unit_title_deed' : key}<->${formValues?.number}<->${new Date().getTime()}`, // <->${new Date().getTime()}_${i} <->${new Date().getTime()}
                        Type: [type],
                        Link: url,
                        ExpiryDate: expiryDate,
                        Statuses: [formValues.status],
                        User: userID ,
                        Category : category ,
                        ForRegister : formValues.ForRegister
                    }
                }
            }
            return null
        })
        console.log(sendBodies,"sendBodies")
        const createLinks = await Promise.all(
            sendBodies.map(async (sendBody) => {
                const response = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    body: JSON.stringify(sendBody),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                })

                if (response.status === 200) {
                    const json = await response.json()

                    if (json.success && json.response && json.response.data && json.response.data.attributes) {
                        const ruleIdForSent = json?.response?.data?.id
                        const key = uploadedFiles[0]?.key

                        if (key === 'id_front') {
                            window.localStorage.setItem(USER_ID_FRONT_ID_KEY, String(ruleIdForSent ?? ''))
                        } else {
                            if (key !== 'id_back') {
                                //  window.localStorage.removeItem(USER_ID_BACK_ID_LOAD)
                                //   window.localStorage.removeItem(USER_ID_FRONT_ID_KEY)
                            }
                            const currentDate = new Date()
                            const timezoneOffset = currentDate.getTimezoneOffset()
                            const timezoneOffsetMs = timezoneOffset * 60 * 1000
                            const myDate = new Date(json?.response?.data?.attributes?.ExpiryDate)
                            const timeSt = new Date(json?.response?.data?.attributes?.ExpiryDate).getTime()
                            const expiryDateAdjusted = new Date(timeSt - timezoneOffsetMs)
                            const thirtyDaysBeforeExpiry = new Date(myDate.getTime() - 30 * 24 * 60 * 60 * 1000)
                            let timeNote: string | number
                            if (isToday(myDate.toDateString()) || isTomorrow(myDate.toDateString())) {
                                timeNote = Date.now() + 100000 // convert to string
                                //       console.log('DA', timeNote)
                            } else {
                                const MainExpiryDate = myDate.setHours(10)
                                const expiryDateAdjusted = new Date(MainExpiryDate)
                                const future = expiryDateAdjusted.setDate(expiryDateAdjusted.getDate() - 1)
                                timeNote = future
                            }

                            const sentBodyForNotification = {
                                method: 'POST',
                                collection: 'user-notifies',
                                body: {
                                    mode: 'publish',
                                    executeAt: timeNote,
                                    RuleID: ruleIdForSent,
                                    Rule: 'user-document-expiry',
                                    User: Number(userID),
                                    Text: swalTexts.agentNotices.expiryDocument,
                                    Type: 'Mobile',
                                    publishedAt: null
                                }
                            }

                            const sentBodyForNotificationHeadsUp = {
                                method: 'POST',
                                collection: 'user-notifies',
                                body: {
                                    mode: 'publish',
                                    executeAt: thirtyDaysBeforeExpiry,
                                    RuleID: ruleIdForSent,
                                    Rule: 'user-document-expiry-heads-up',
                                    User: Number(userID),
                                    Text: swalTexts.agentNotices.expiryDocument + 'Expiry Head Up',
                                    Type: 'Mobile',
                                    publishedAt: null
                                }
                            }

                            const requestForNotification = await fetch(`${urlAPI}`, {
                                method: 'POST',
                                body: JSON.stringify(sentBodyForNotification),
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${jwt}`
                                }
                            })
                            const requestForNotificationHeadsUp = await fetch(`${urlAPI}`, {
                                method: 'POST',
                                body: JSON.stringify(sentBodyForNotificationHeadsUp),
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${jwt}`
                                }
                            })

                            if (formValues.status === 'In-process') {
                                const sentBodyForNotificationInProcess = {
                                    method: 'POST',
                                    collection: 'user-notifies',
                                    body: {
                                        mode: 'publish',
                                        executeAt: Date.now() + 100000,
                                        RuleID: ruleIdForSent,
                                        Rule: 'admin-document-pending',
                                        User: Number(userID),
                                        Text: 'This document\'s status in-process, please check!',
                                        Type: 'Mobile',
                                        publishedAt: null
                                    }
                                }

                                const requestForNotificationInProcess = await fetch(`${urlAPI}`, {
                                    method: 'POST',
                                    body: JSON.stringify(sentBodyForNotificationInProcess),
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${jwt}`
                                    }
                                })
                            }
                        }
                    }
                    return json
                }
            })
        )
        console.log(createLinks,"createLinks")
        return createLinks
    } catch (error) {
        console.log(error, 'error')
    }
}
