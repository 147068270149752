export const LANDLORD_ADD_UNIT_STORED_DATA = 'bso-landlord-add-unit-stored-data'

export type LandlordAddUnitStorageKey = 'title_deed' | 'unit_info' | 'additional_unit_info' | 'owners_passport' | 'poa' | 'user_passport' | 'main_unit_info_first' | 'main_unit_info_second' | 'additional_unit_info_outdoor' | 'additional_unit_info_files' | 'unit_marketing_info' | 'unit_marketing_special_requests'

export function landlordAddUnitWriteToStorage (key: LandlordAddUnitStorageKey, value: Record<string, any>): void {
    const storedData = JSON.parse(localStorage.getItem(LANDLORD_ADD_UNIT_STORED_DATA) ?? '{}')
    storedData[key] = value
    console.log(storedData)
    localStorage.setItem(LANDLORD_ADD_UNIT_STORED_DATA, JSON.stringify(storedData))
}

export function landlordAddUnitReadFromStorage (): Record<LandlordAddUnitStorageKey, any> {
    return JSON.parse(localStorage.getItem(LANDLORD_ADD_UNIT_STORED_DATA) ?? '{}')
}

export function landlordAddUnitClearStorage (): void {
    localStorage.removeItem(LANDLORD_ADD_UNIT_STORED_DATA)
}
