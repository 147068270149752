import React from 'react'
import { Input } from 'antd'
import { applyUnitSearch } from '../../../store/actionsCreators/unitsActions'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { setMyArchivedUnitsOpen, unitsSlice } from '../../../store/slices/unitsSlice'
import useFireSearch from '../../../hooks/useFireSearch'
import Icon from '../../../app/icon/Icon'
import { Link } from 'react-router-dom'
import UseUserType from '../../../hooks/UseUserType'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../urls'

export default function UnitsHeaderSh (): JSX.Element {
    // const { Search } = Input
    const { userType, userTypeLowercase } = UseUserType()
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? currentUser.id ?? ''

    const dispatch = useAppDispatch()
    const { searchString } = useAppSelector((state: RootState) => state.units)
    const myArchivedUnitsOpen = useAppSelector((state) => state.units.myArchivedUnitsOpen)
    /* useFireSearch({
        callback: () => {
            dispatch(applyUnitSearch())
        },
        delay: 300,
        controlValue: searchString
    }) */
    function Searc2h (): JSX.Element {
        return (
            <span className="">
                {/* <i className="bi bi-search fs-3 me-2 p-0 "></i> */}
                <Icon
                    name={'search'}
                    className={'text-muted fs-3 me-2 p-0'}
                />
            </span>
        )
    }

    return (

        <div className=" w-100 g-0">
            <div className="d-flex flex-row mt-4 w-100">
                <div className={`col-${process.env.REACT_APP_IS_DEV === 'true' || Number(userID) === Number(REACT_APP_BSO_USER_ID) ? '9' : '12'}`}>
                    <Input
                        className={''}
                        allowClear
                        placeholder='Search unit'
                        value={searchString}
                        onChange={(e: any) => {
                            dispatch(unitsSlice.actions.unitsSetSearchString(e.target.value))
                        }}
                        // onKeyPress={(e: any) => {
                        // if (e.key === 'Enter') {
                        // dispatch(applyUnitSearch())
                        // }
                        // }}
                        prefix={<Searc2h />}
                    />
                </div>
                {userType === 'Agent'
                    ? process.env.REACT_APP_IS_DEV === 'true'
                        ? <div className="">
                            <Link className="text-decoration-none" to={'/agent/my_units'}>
                                <div className="btn btn-bg-light-secondary p-2 px-auto ms-4 border border-dark link-dark fw-bold fs-7 text-uppercase text-nowrap">
                                    My Units
                                </div>
                            </Link>
                        </div>
                        : null
                    : <div className={`btn ${myArchivedUnitsOpen ? 'btn-bg-dark text-white' : 'btn-bg-light-secondary link-dark'}  p-2 px-auto ms-4 border border-dark  fw-bold fs-7 text-uppercase text-nowrap w-100`}
                        onClick={() => {
                            dispatch(setMyArchivedUnitsOpen(!myArchivedUnitsOpen))
                        }}>
                            ARCHIVE
                        <Icon
                            name={'delete_24dp'}
                            className={'fs-2x '}
                            filled={myArchivedUnitsOpen}
                        />

                    </div>
                }

            </div>
        </div >
    )
}
