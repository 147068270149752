import React, {type ReactElement, useEffect, useState} from "react";
import TextButton from "../../../../components/shared/UIv2/Button/TextButton";
import Timer from "../../../../components/shared/elements/Timer";

interface ResetButtonProps {
    loading: boolean
    row: Record<string, any>
    errors: any[]
    resetRef: React.RefObject<HTMLButtonElement>
}

export default function ResetButton ({loading, row, errors, resetRef}: ResetButtonProps): ReactElement {
    const [text, setText] = useState(row.content[0])
    const [clickCount, setClickCount] = useState(0)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        let interval: NodeJS.Timer
        if (row.timeout != null) {
            let timeRemaining: number = row.timeout
            setText(`${String(row.content[0])} (${timeRemaining}s)`)
            setDisabled(true)
            interval = setInterval(() => {
                timeRemaining--
                if (timeRemaining === 0) {
                    setDisabled(false)
                    setText(row.content[0])
                    clearInterval(interval)
                    return
                }
                setText(`${String(row.content[0])} (${timeRemaining}s)`)
            }, 1000)
        }
        return () => {
            clearInterval(interval)
        }
    }, [row, clickCount])

    function onReset (): void {
        if (!loading && !disabled) {
            setClickCount(clickCount + 1)
            resetRef?.current?.click()
        }
    }

    return (
        row.style === 'text'
            ? <div className={'text-center fw-medium fs-4 text-gray-500 p-3'}
                onClick={onReset}
            >
                {
                    text
                }
            </div>
            : <TextButton
                isLoading={loading}
                text={text}
                width={'full'}
                style={'white'}
                height={'48'}
                textSize={'large'}
                rounded={'48'}
                onClick={onReset}
            />
    )
}