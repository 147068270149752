import React, {type ReactElement} from "react";
import {useLocation} from "react-router";
import {
    registerActionsV2Wrapper, verifyResetPasswordPhone
} from "../../store/actionsCreators/authActions";
import {AgentModals} from "../../functions/agent/AgentModals";
import CustomValidatorHook from "../../app/custom/modalV2/CustomValidatorHook";
import CustomFieldsHook from "../../app/custom/modalV2/Fields/CustomFieldsHook";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {setResendInProcess} from "../../store/slices/modalSlice";
import {swalError, swalSuccess} from "../../functions/swalOptions";
import {type AppDispatch } from "../../store/store";
import {USER_CATEGORY_KEY} from "../../urls";
import {useNavigate} from "react-router-dom";
import {verifyUser} from "../../store/actionsCreators/authActions/registerAction";


export default function AuthPageV2 (): ReactElement {
    const locationArray = useLocation().pathname.split('/')
    const location = locationArray[locationArray.length - 1]
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    let callback
    let handleReset = (): void => {}

    switch (location) {
        case 'register_phone':
            callback = registerActionsV2Wrapper('verifyPhoneCreate')
            break
        case 'register_phone_verify':
            callback = registerActionsV2Wrapper('verifyPhoneConfirm')
            handleReset = () => {
                dispatch(setResendInProcess(true))
                void dispatch(verifyResetPasswordPhone({ renew: false }))
                    .then((response: any) => {
                        if (response?.isSuccessful === true) {
                            void swalSuccess(response.textNotification, () => {
                                dispatch(setResendInProcess(false))
                            })
                        } else {
                            void swalError(response?.textNotification, () => {
                                dispatch(setResendInProcess(false))
                            })
                        }
                    })
                    .catch(() => {
                        dispatch(setResendInProcess(false))
                    })
            }
            break
        case 'register_user_type':
            callback = (fieldData: any) => (dispatch: AppDispatch) => {
                window.localStorage.setItem(USER_CATEGORY_KEY, fieldData.user_type)
                return ({
                    isSuccessful: true,
                    textNotification: '',
                    textNavigate: '/auth/v2/register_setup_details'
                })
            }
            break
        case 'register_setup_details':
            callback = registerActionsV2Wrapper('register')
            break
        case 'register_id_verification':
            callback = registerActionsV2Wrapper('registerEnd')
            break
        case 'register_verify_identity_emirates_id':
            callback = registerActionsV2Wrapper('scanFieldsFront')
            break
        case 'register_verify_identity_passport':
        case 'register_verify_identity_emirates_id_back':
            callback = registerActionsV2Wrapper('scanFields')
            break
        case 'register_end_landlord':
            callback = () => () => {
                dispatch(verifyUser(true))
                return {
                    textNavigate: '/landlord/modal/v2/landlord_create_unit_choose_role/-/-',
                    textNotification: '',
                    isSuccessful: true
                }
            }
            handleReset = () => {
                dispatch(verifyUser(true))
                navigate('/landlord/my_units')
            }
            break
        default:
            break
    }
    const modal = AgentModals().filter((modal: any) => modal.id === location)
    return (
        <div className="page d-flex fs-5 vh-100">
            <div className="content w-100 mw-450px mx-auto ">
                <div className="card d-flex mh-100 h-100 p-8">
                    <div className="h-100 p-0" style={
                        modal[0].overflowScroll === true
                            ? { maxHeight: `calc(100vh - ${userDevice === 'IPhone' ? '101' : '70'}px)` }
                            : {}
                    }>
                        <CustomValidatorHook id={location} callback={callback} handleReset={handleReset}>
                            <CustomFieldsHook modal={modal} isAuth={true} />
                        </CustomValidatorHook>
                        {/* <div style={{ height: '70px' }}></div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}