import {type Unit} from "../../../../../../../store/slices/unitsSlice";
import {type ReactNode} from "react";

type IViewUnitAdditionalDetailsHelper = (unit: Unit) => Array<{ label: string, values: string[]}>

const viewUnitAdditionalDetailsHelper: IViewUnitAdditionalDetailsHelper = (unit) => {
    return [
        {
            label: 'APPLIANCES INCLUDED',
            values: unit?.attributes?.Appliances
        },
        {
            label: 'AMENITIES AVAILABLE',
            values: unit?.attributes?.Amenities
        },
        {
            label: 'OUTDOOR FEATURES',
            values: unit?.attributes?.Features
        }
    ].filter(row => row.values?.length > 0)
}

export default viewUnitAdditionalDetailsHelper
