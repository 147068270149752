import {type NavigateFunction} from "react-router-dom";
import {serviceFindingTenantReadFromStorage} from "../utils/findingTenantHandleStorage";
import {AgentModals} from "../../../../../../functions/agent/AgentModals";
import _ from "lodash";
import {type AppDispatch, type RootState} from "../../../../../store";
import { nanoid } from "nanoid";

export const fillValuesForServiceFindingTenant = (modalID: string, unitID: number, navigate: NavigateFunction) => (dispatch: AppDispatch, getState: () => RootState): { modal: [Record<string, unknown>], initialState: Record<string, unknown> } => {
    const modal = _.cloneDeep(
        AgentModals().find((modal: any) => modal.id === modalID)
    )
    const initialState: Record<string, unknown> = {}
    const storedData = serviceFindingTenantReadFromStorage()

    modal.form.forEach((row: Record<string, any>) => {
        switch (row.id) {
            case 'dates':
                initialState[row.id] = storedData.service_finding_tenant?.dates
                break
            case 'Fit':
                initialState[row.id] = storedData.service_finding_tenant_unit_info?.Fit
                break
            case 'Appliances':
                initialState[row.id] = storedData.service_finding_tenant_unit_info?.Appliances
                break
            case 'Features':
                initialState[row.id] = storedData.service_finding_tenant_unit_info?.Features
                break
            case 'Amenities':
                initialState[row.id] = storedData.service_finding_tenant_unit_info?.Amenities
                break
            case 'View':
                initialState[row.id] = storedData.service_finding_tenant_unit_info?.View
                break
            case 'Marketing.Price':
                initialState[row.id] = storedData.service_finding_tenant_marketing_info?.Marketing?.Price
                break
            case 'Marketing.Headline':
                initialState[row.id] = storedData.service_finding_tenant_marketing_info?.Marketing?.Headline
                break
            case 'Images':
                initialState[row.id] = storedData.service_finding_tenant_photos?.Images?.length > 0
                    ? storedData.service_finding_tenant_photos.Images.map((url: string) => ({
                        name: nanoid(),
                        url
                    }))
                    : undefined
                break
            case 'Video':
                initialState[row.id] = storedData.service_finding_tenant_photos?.Video != null
                    ? [{
                        name: nanoid(),
                        url: storedData.service_finding_tenant_photos.Video,
                        type: new URL(storedData.service_finding_tenant_photos.Video).searchParams.get("type")
                    }]
                    : undefined
                break
            case 'FloorPlan':
                initialState[row.id] = storedData.service_finding_tenant_photos?.FloorPlan != null
                    ? [{
                        name: nanoid(),
                        url: storedData.service_finding_tenant_photos.FloorPlan
                    }]
                    : undefined
                break
            case 'addons':
                initialState[row.id] = storedData.service_finding_tenant_addons?.addons
                if (storedData.service_finding_tenant_photos?.bso_photography === true) {
                    row.preselected = ['unit_photography']
                }
                break
            case 'services':
                // initialState[row.id] = storedData.service_finding_tenant_addons?.services
                row.preselected = [
                    'find_tenant',
                    ...(storedData.service_finding_tenant_addons?.addons ?? [])
                ]
                break
            default:
                break
        }
    })
    modal.footer.forEach((row: Record<string, any>) => {
        switch(row.id) {
            case 'bso_photography':
                if (storedData.service_finding_tenant_photos != null && storedData.service_finding_tenant_photos?.bso_photography !== true) {
                    modal.footer = modal.footer.filter((rowItem: any) => rowItem.id !== row.id)
                }
                initialState[row.id] = storedData.service_finding_tenant_photos?.bso_photography
                break
            default:
                break
        }
    })

    return {
        modal: [modal],
        initialState
    }
}