import React, { useEffect, useState } from 'react'
import AgentHeader from '../../AgentHeader'
import AgentFooter from '../../AgentFooter'
import { Progress } from 'antd'
import MembershipCardDescription from './MembershipCardDescription'
import { useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { type User, type ISettings } from '../../../../store/slices/userSlice'
import { getUserDataForMembership } from '../../../../functions/agent/memberships/getUserDataForMembership'

export default function AgentMemberships () {
    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser
    ) as User
    //  console.log(currentUser, 'currentUser')
    const userID = currentUser.id
    const userSETTINGS = (currentUser.Settings as [ISettings])?.[0]
    const [selectedOption, setSelectedOption] = useState<string>('Requirements')
    // console.log(userSETTINGS  , 'userSETTINGS')
    const [currentMembership, setCurrentMembership] = useState<string>(userSETTINGS.Membership ?? 'Bronze')
    // console.log(currentMembership, 'currentMembership')
    const progressProfile = 25
    const progressBarWidth = 300
    const progressInPixels = (progressProfile / 100) * progressBarWidth

    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
            >
                <AgentHeader active="My Membership" />
                <div
                    className="content d-flex flex-column flex-fill p-0 m-4"
                >
                    <div className="w-100 mw-450px mx-auto">
                        {/* <SubscritionsList/> */}
                        <div className="nav-group nav-group-outline mx-auto mb-4 text-center" >
                            <button className={`btn btn-color-gray-400 btn-active btn-active-secondary px-8 py-3 me-2 ${selectedOption === 'Requirements' ? 'active' : ''} `} onClick={() => { setSelectedOption('Requirements') }}>
                            Requirements
                            </button>
                            <button className={`btn btn-color-gray-400 btn-active btn-active-secondary px-8 py-3  ${selectedOption === 'Benefits' ? 'active' : ''} `} onClick={() => { setSelectedOption('Benefits') }} >
                            Benefits
                            </button>
                        </div>

                        <div className="fs-7 position relative pb-8">

                            <div className="bronze card shadow-sm mb-4 ">
                                <div className=" card-body p-4">
                                    <div className="d-flex text-center align-items-center">
                                        <div className='d-flex flex-column'>
                                            {/* <div className="coin bronze mb-4"></div> */}

                                        </div>

                                        <div className='d-flex flex-column  w-100'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='fw-bold text-uppercase fs-2x text-start ms-2 text-dark'>Bronze status</div>
                                                <i className="ki-duotone ki-check fs-6x text-gray-700"></i>
                                            </div>
                                            {/* {currentMembership === 'Bronze'
                                                ? <div className="h-5px mx-3 w-80  rounded bg-white mb-2">
                                                    <div className="bg-success rounded h-5px" style={{ width: `${progressInPixels}px` }}></div>
                                                </div>
                                                : null } */}
                                            <div className='lh-sm fs-6'> This level allows you to use only a basic amount of advertising and broadcast. </div>

                                            {/* <Progress percent={100} size={[270, 25]} strokeColor={twoColorsBronze} status={'active'}/> */}
                                            {/* {selectedOption === 'Benefits'
                                                ? <MembershipCardDescription currentUser={currentUser } currentMembership={currentMembership} selectedOption={selectedOption} typeMembership={'Bronze'}/>
                                                : null } */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="silver card shadow-sm mb-4">
                                <div className="card-body p-4">
                                    <div className="d-flex text-center align-items-center">
                                        <div className='d-flex flex-column'>
                                            {/* <div className="coin silver mb-4"></div> */}

                                        </div>
                                        <div className='d-flex flex-column  w-100'>

                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='fw-bold text-uppercase fs-2x text-start ms-2 text-dark'>Silver status</div>
                                                <i className={`ki-duotone ki-check fs-6x text-gray-700 opacity-${currentMembership === 'Gold' ? '100' : '0'}`}></i>
                                            </div>

                                            {currentMembership === 'Bronze'
                                                ? <>
                                                    <div className="d-flex justify-content-between w-100 mt-auto mb-1">
                                                        <span className="fw-bold fs-6 ps-3 text-start text-gray-700 ">Rang Completion</span>
                                                        <span className="fw-bold fs-6 text-gray-700">{progressProfile}%</span>
                                                    </div>

                                                    <div className="h-5px mx-3 w-80  rounded bg-white mb-2">
                                                        <div className="bg-success rounded h-5px" style={{ width: `${progressInPixels}px` }}></div>
                                                    </div>
                                                </>
                                                : null }

                                            <div className='lh-sm fs-6'> This level allows you to get +1/month of broadcasting and advertising usage +3/month on units for rent , and +2/month for units for sale . </div>
                                            {currentMembership === 'Bronze'

                                                ? <MembershipCardDescription typeMembership={'Silver'} currentUser={currentUser } currentMembership={currentMembership} selectedOption={selectedOption}/>
                                                : null }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gold card shadow-sm mb-4  ">
                                <div className="card-body p-4">
                                    <div className="d-flex text-center align-items-center">
                                        <div className='d-flex flex-column'>
                                            {/* <div className="coin gold mb-4"></div> */}

                                        </div>
                                        <div className='d-flex flex-column  w-100'>

                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='fw-bold text-uppercase fs-2x text-start ms-2 text-dark'>Gold status</div>
                                                <i className={`ki-duotone ki-check fs-6x text-gray-700 opacity-${currentMembership === 'Gold' ? '100' : '0'}`}></i>

                                            </div>

                                            {currentMembership === 'Silver'
                                                ? <div className="h-5px mx-3 w-80  rounded bg-white mb-2">
                                                    <div className="bg-success rounded h-5px" style={{ width: `${progressInPixels}px` }}></div>
                                                </div>
                                                : null }

                                            <div className='lh-sm fs-6'>  This level allows you to get +3 / month of broadcast usage and unlimited advertising on units for rent , and +10 / month for units for sale .</div>
                                            <MembershipCardDescription currentUser={currentUser } typeMembership={'Gold'} currentMembership={currentMembership} selectedOption={selectedOption}/>
                                            {/*
                                            {selectedOption === 'Requirements'
                                                ? <MembershipCardDescription currentUser={currentUser } typeMembership={'Gold'} currentMembership={currentMembership} selectedOption={selectedOption}/>
                                                : null } */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="m-0" style={{ height: '160px' }} />
            </div>
            <AgentFooter />
            {/* {mainButtonOpen || buttonOrdersHistoryOpen || buttonSubscritionsOpen
                ? null
                : <>

                    <LandlordFooter /></>} */}

        </>

    )
}
