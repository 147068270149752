
import { getQueryDocuments } from '../../../../functions/shared/api/queries/getQueryDocuments'
import { ACCESS_KEY, urlAPI, USER_ID_FRONT_ID_KEY, USER_ID_KEY } from '../../../../urls'
import { authSlice } from '../../../slices/authSlice'
import { type AppDispatch } from '../../../store'
import { uploadSingleFile } from './uploadSingleFile'


export const uploadMultipleForOffers = (formValues: Record<string, any>, offerType: 'agent_to_bso_offer' | 'agent_to_agent_offer' = 'agent_to_agent_offer') => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.authFetching(true))
        const fileTypesToUpload = []
        if ('visa' in formValues) {
            fileTypesToUpload.push('visa')
        } if ('id_front' in formValues) {
            fileTypesToUpload.push('id_front')
        } if ('id_back' in formValues) {
            fileTypesToUpload.push('id_back')
        } if ('trade_licence' in formValues) {
            fileTypesToUpload.push('trade_licence')
        }
        const result = await uploadFiles(formValues, fileTypesToUpload, offerType)
        let filteredResults = []
        if (result && result.length > 0) {
            filteredResults = result.filter((item: any) => item?.success && item?.response && item?.response?.data && item?.response?.data?.attributes).map((el: any) => el.response.data.id)
        }
        dispatch(authSlice.actions.authFetching(false))
        return filteredResults
    } catch (error) {
        console.error(error)
        dispatch(authSlice.actions.authFetching(false))
        return { textNavigate: '', isSuccessful: false, textNotification: 'Error loaded file' }
    }
}

const uploadFiles = async (formValues: Record<string, any>, fileTypesToUpload: string[], offerType: 'agent_to_bso_offer' | 'agent_to_agent_offer'): Promise<any> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
        const offerID = formValues.offerID
        const promises = fileTypesToUpload.map(async (fileKey) => {
            const file = formValues[fileKey]?.[0]

            if (!file) return null
            const url = await uploadSingleFile(file)
            return { key: fileKey, url }
        })

        const uploadedFiles = (await Promise.all(promises)).filter(Boolean)

        const sendBodies = uploadedFiles.map((file, i) => {
            let expiryDate, type
            if (file != null) {
                const { key, url } = file
                console.log({ key, url })
                if (key === 'id_back') {
                    return {
                        method: 'PUT',
                        collection: 'documents',
                        id: Number(window.localStorage.getItem(USER_ID_FRONT_ID_KEY)),
                        query: getQueryDocuments(),
                        body: {
                            RefID: `REF_DOC<->emirates_id<->${offerType === 'agent_to_agent_offer' ? 'agent_offer' : 'bso_offer'}<->${new Date().getTime()}`, // <->${new Date().getTime()}_${i} <->${new Date().getTime()}
                            LinkBack: url
                        }
                    }
                } else if (key === 'visa') {
                    type = {
                        __component: 'documents.passport'

                    }
                } else if (key === 'trade_licence') {
                    type = {
                        __component: 'documents.trade-license'
                    }
                } else if (key === 'id_front') {
                    type = {
                        __component: 'documents.emirates-id'
                    }
                    return {
                        method: 'POST',
                        collection: 'documents',
                        query: getQueryDocuments(),
                        body: {
                            RefID: `REF_DOC<->emirates_id<->${offerType === 'agent_to_agent_offer' ? 'agent_offer' : 'bso_offer'}<->${new Date().getTime()}`, // <->${new Date().getTime()}_${i} <->${new Date().getTime()}
                            Type: [type],
                            LinkFront: url,
                            User: offerType === 'agent_to_agent_offer' ? Number(userID) : undefined,
                            Offer: offerID

                        }
                    }
                }

                return {
                    method: 'POST',
                    collection: 'documents',
                    query: getQueryDocuments(),
                    body: {
                        RefID: `REF_DOC<->${key}<->${offerType === 'agent_to_agent_offer' ? 'agent_offer' : 'bso_offer'}<->${new Date().getTime()}`, // <->${new Date().getTime()}_${i} <->${new Date().getTime()}
                        Type: [type],
                        Link: url,
                        //  ExpiryDate: expiryDate,
                        // Statuses: [formValues.status],
                        User: offerType === 'agent_to_agent_offer' ? Number(userID) : undefined,
                        Offer: offerID
                    }
                }
            }
            return null
        })

        const createLinks = await Promise.all(
            sendBodies.map(async (sendBody) => {
                const response = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    body: JSON.stringify(sendBody),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`,
                        'BSO-Hash': '02ns30nsBSO!'
                    }
                })

                if (response.status === 200) {
                    const json = await response.json()
                    if (json.success && json.response && json.response.data && json.response.data.attributes) {
                        const ruleIdForSent = json?.response?.data?.id
                        const key = uploadedFiles[0]?.key
                        if (key === 'id_front') {
                            window.localStorage.setItem(USER_ID_FRONT_ID_KEY, String(ruleIdForSent ?? ''))
                        }
                    }

                    return json
                }
            })
        )

        return createLinks
    } catch (error) {
        console.error(error)
    }
}
