import { ACCESS_KEY, urlAPI } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart, modalSlice } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'
import qs from 'qs'

import { getErrorss } from '../../../functions/errors/GetErrors'
import _ from 'lodash'
import { type IFile } from '../../../types'
import { uploadStrapiFile } from '../fileActions'
import { nanoid } from 'nanoid'
import { documentsSlice, setTemporaryTitleDeed } from '../../slices/documentSlice'
import { setCreatePropertyFields } from '../../slices/propertySlice'

import { getQueryDocuments } from '../../../functions/shared/api/queries/getQueryDocuments'
import { getQueryUnits } from '../../../functions/shared/api/queries/getQueryUnits'
import { addNewUnit } from '../../slices/unitsSlice'
import { uploadSingleFile } from '../documentsActions/upload/uploadSingleFile'

export const createMarketingForUnit = (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    console.log(data, '   data < ===')
    try {
        dispatch(dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

        const images: Array<IFile | null> = getState().modal.images
        const storedImages = _.filter(images, (image) => !_.includes(image?.data, 'data:'))
        const notUploadedImages = _.filter(images, (image) => _.includes(image?.data, 'data:'))

        const uploadedImages = await Promise.all(_.map(notUploadedImages, async (image) => {
            const res: Response = await fetch(image?.data ?? '')
            const blob: Blob = await res.blob()
            const file = new File([blob], `unit-image-${nanoid()}`, { type: blob.type })
            return await uploadStrapiFile(file, 'properties', null, `unit-image-${nanoid()}`)
        }))
        const unitBody: any = {
            Marketing: {}
        }

        if (storedImages.includes(images[0])) {
            unitBody.Images = _.map([...storedImages, ...uploadedImages], (image) => image?.data)
        } else {
            unitBody.Images = _.map([...uploadedImages, ...storedImages], (image) => image?.data)
        }

        const unit = _.cloneDeep(getState().units.units?.find((unit) => unit.id === Number(data.unitID)))

        unitBody.Statuses = ['Door close']
        if (data.StatusesMortgage && !unitBody.Statuses.includes('Mortgage')) {
            unitBody.Statuses.push('Mortgage')
        }
        //   console.log(data, 'data ')

        const temporaryTitleDeed = getState().documents.temporaryTitleDeed
        let docTitleDeed
        if (temporaryTitleDeed != null) {
            const owners = Array.isArray(temporaryTitleDeed?.document?.owners) && temporaryTitleDeed.document.owners.length > 0
                ? temporaryTitleDeed.document.owners.map((owner: any) => {
                    return {
                        Name: owner?.name,
                        Valid: owner?.valid
                    }
                })
                : undefined
            const file = data.agent_create_unit_title_deed?.[0]

            if (file) {
                const url = await uploadSingleFile(file)
                const bodyCreateTitleDeed = {
                    RefID: `REF_DOC<->${'agent_create_unit_title_deed'}<->${temporaryTitleDeed?.document?.number}<->${new Date().getTime()}`,
                    Type: [{
                        __component: 'documents.title-deed',
                        Number: temporaryTitleDeed?.document?.number,
                        Year: temporaryTitleDeed?.document?.year,
                        Type: temporaryTitleDeed?.document?.type,
                        Property: {
                            Number: temporaryTitleDeed?.document?.property?.number,
                            Name: temporaryTitleDeed?.document?.property?.name,
                            Area: temporaryTitleDeed?.document?.property?.area,
                            Type: temporaryTitleDeed?.document?.property?.type,
                            MAKANI: temporaryTitleDeed?.document?.property?.makani
                        },
                        Owners: owners
                    }],
                    Link: url,
                    Valid: Boolean(temporaryTitleDeed?.document?.valid),
                    Statuses: [temporaryTitleDeed?.document?.valid ? 'Approved' : 'In-process']
                }
                console.log(bodyCreateTitleDeed, 'bodyCreateTitleDeed')
                const responseTD = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    body: JSON.stringify({
                        method: 'POST',
                        collection: 'documents',
                        query: getQueryDocuments(),
                        body: bodyCreateTitleDeed
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`,
                        'BSO-Hash': '02ns30nsBSO!'
                    }
                })
                const dataTitleDeedCreateJSON = await responseTD.json()

                if (dataTitleDeedCreateJSON.success) {
                    if (dataTitleDeedCreateJSON?.response?.data) {
                        docTitleDeed = dataTitleDeedCreateJSON?.response?.data
                        dispatch(documentsSlice.actions.documentAddSuccess(dataTitleDeedCreateJSON.response?.data))
                    }
                }
            }

            dispatch(setTemporaryTitleDeed(null))
            dispatch(setCreatePropertyFields(null))
        }

        //  unitBody.Images = _.map([...storedImages, ...uploadedImages], (image) => image?.data)
        unitBody.Marketing.Headline = data?.Marketing?.Headline
        unitBody.Marketing.Description = data?.Marketing?.Description
        unitBody.Marketing.Price = data?.Marketing?.Price
        unitBody.Marketing.Use = data?.Marketing?.Payments ? 'For rent' : 'For sale'
        unitBody.Marketing.Checks = data?.Marketing?.Payments

        if (docTitleDeed) {
            unitBody.Documents = [docTitleDeed]
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'units',
            id: Number(data.unitID),
            body: unitBody,
            query: getQueryUnits()
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response?.json() as unknown as any
        console.log(responseJSON, 'responseJSON createMarketingForUnit')
        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
            } else {
                dispatch(addNewUnit(responseJSON.response.data))
                dispatch(dataFetchingFinish())
                return {
                    textNavigate: `/agent/modalView/unit_modal/${data?.unitID}/not/agent_s_unit`, // '/agent/my_units',
                    isSuccessful: true,
                    textNotification: 'Unit is added successfully',
                    title: 'Success'
                }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        dispatch(dataFetchingFinish())
        console.log(error)
        return {
            isSuccessful: false
        }
    }
}
