import React, {type ReactElement} from "react";
import {Controller} from "react-hook-form";
import SelectCard from "../../../../../components/shared/UIv2/Card/SelectCard";
import {nanoid} from "nanoid";

interface CardsSelectProps {
    row: Record<string, any>
    control: any
}

export default function CardsSelect ({ row, control }: CardsSelectProps): ReactElement {
    return (
        <Controller
            name={row.id}
            control={control}
            render={({field}) => {
                return <div className={'d-flex flex-column gap-5'}>
                    {
                        row.selectors.map((selector: any) => (
                            <SelectCard
                                title={selector.title}
                                titlePlacement={row.titlePlacement === 'vertical' ? 'vertical' : 'horizontal'}
                                description={selector.description}
                                icon={selector.icon}
                                color={selector.color}
                                isSelected={field.value === selector.value}
                                onClick={() => {
                                    field.onChange(selector.value)
                                }}
                                showRadio={row.showRadio !== false}
                                fillImage={row.fillImage !== false}
                                key={nanoid()}
                            />
                        ))
                    }
                </div>
            }}
        />
    )
}