import React, {type ReactElement} from "react";
import IconButton from "./IconButton";

interface CloseButtonProps {
    onClick: () => void
    styles?: React.CSSProperties
}

export default function CloseButton ({onClick, styles = {}}: CloseButtonProps): ReactElement {
    return <div
        className={'text-white'}
        style={{...styles, width: 'fit-content', borderRadius: '8px', padding: '1px', background: '#06060380' }}
        onClick={onClick}
    >
        <IconButton
            icon={'close'}
            style={'no-background'}
        />
    </div>
}