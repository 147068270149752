
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, USER_ADVERTISE_DAYS, USER_ID_KEY, urlAPI } from '../../../urls'
import { modalSlice } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'
import { advertisesSlice, setDebitMarketingCredits } from '../../slices/advertisesSlice'
import { makePositiveOrUnchanged } from '../../../functions/agent/date/makePositiveOrUnchanged'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'

export const extendAdvertise = (bodyClient: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    try {
        const debitMarketingCredits = getState().advertises.debitMarketingCredits
        const currentAdvertise = getState().advertises.advertises.filter((adv) => adv.id === Number(bodyClient.viewID))

        const durationAdv = bodyClient.advertise_create_duration ?? 0
        const startDate = new Date(currentAdvertise?.[0]?.attributes?.EndDate as string)
        const endDate = new Date(startDate)
        endDate.setDate(endDate.getDate() + durationAdv)

        dispatch(modalSlice.actions.dataFetchingStart())
        const response = await fetch(`${urlAPI}`,
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify({

                    method: 'PUT',
                    collection: 'unit-advertises',
                    id: Number(bodyClient.viewID),
                    query: getQueryAdvertises(), // `filters[User][id][$eq]=${userID}&populate[User][fields][0]=id&populate[Unit][fields][0]=id`, // [id]
                    body: {
                        StartDate: startDate,
                        EndDate: endDate,
                        Notify: 'BSO',
                        Duration: bodyClient.advertise_create_duration,
                        CreditsAmount: makePositiveOrUnchanged(debitMarketingCredits),
                        Statuses: ['Confirmed']
                    }
                })
            })
        const responseJSON = await response.json()
        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                dispatch(advertisesSlice.actions.advertisesFetchingError(getErrorss(responseJSON)))
                return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
            } else {
                dispatch(setDebitMarketingCredits(0))
                localStorage.removeItem('firstDateAdvertise')
                localStorage.removeItem('secondDateAdvertise')
                localStorage.removeItem(USER_ADVERTISE_DAYS)

                const inMomentViewingForAdmin = toUTCWithOffset(startDate, 0)
                const advertiseDateEndNotify = toUTCWithOffset(new Date(endDate), 0)
                const advertiseDateEndNotifyAfterDay = new Date(advertiseDateEndNotify.getTime() + 1 * 24 * 60 * 60 * 1000)
                const advertiseDateEndNotifyBeforeDay = new Date(advertiseDateEndNotify.getTime() - 1 * 24 * 60 * 60 * 1000)

                dispatch(advertisesSlice.actions.setAdvertises(responseJSON.response.data))
                await sendNotification(inMomentViewingForAdmin, Number(userID), responseJSON.response.data.id, 'user-advertise-start', 'Hi , it\'s new')
                await sendNotification(advertiseDateEndNotifyAfterDay, Number(userID), responseJSON.response.data.id, 'user-advertise-end', 'Hi , it\'s new')
                await sendNotification(advertiseDateEndNotifyBeforeDay, Number(userID), responseJSON.response.data.id, 'user-advertise-heads-up', 'Hi , it\'s new')

                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...responseJSON, textNavigate: `/agent/modalView/advertise_modal/${currentAdvertise?.[0].attributes.Unit.data?.id}/${currentAdvertise?.[0].id}/create`, isSuccessful: true, textNotification: 'The advertising date has been set' }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            dispatch(advertisesSlice.actions.advertisesFetchingError(getErrorss(responseJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (e) {
        dispatch(modalSlice.actions.dataFetchingFinish())
        console.log(e)
    }
}
