import React, {type ReactElement} from "react";

interface ModalImageProps {
    row: Record<string, any>
}

export default function ModalImage ({row}: ModalImageProps): ReactElement {
    return <div className={'pb-8'}>
        <div style={{
            backgroundImage: `url(${String(row.src)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: row.imageHeight
        }}
        />
    </div>
}