import {type Unit} from "../../../../../../../../store/slices/unitsSlice";
import {useAppSelector} from "../../../../../../../../hooks/redux";
import {type User} from "../../../../../../../../store/slices/userSlice";
import dayjs from "dayjs";
import {type IUnitCardServiceData} from "../LandlordUnitCardServicesList";

export interface IUseServiceFindTenantsData extends IUnitCardServiceData {
    serviceIsActive: boolean
    activeOffersCount: number,
    notifyOffersCount: number,
    maxOfferPrice: number,
    upcomingViewingsCount: number,
    closestViewingDateTime?: dayjs.Dayjs
}

type IUseServiceFindTenants = (unit: Unit) => IUseServiceFindTenantsData

const useServiceFindTenants: IUseServiceFindTenants = (unit: Unit) => {
    const currentUser = useAppSelector((state) => state.user.currentUser) as User
    const orders = currentUser.Orders
    const serviceIsActive = orders?.some((order: any) => [
        order.Service?.[0]?.__component === 'services.finding-tenant',
        order.Unit?.id === unit.id,
        dayjs().isBefore(dayjs(order.InvoiceDueDate))
    ].every(Boolean)) === true

    const offers = useAppSelector((state) => state.offers.offers)
    const activeUnitOffers = offers.filter((offer) => [
        offer?.attributes?.Unit?.data?.id === unit.id,
        !['Completed', 'Canceled'].includes(offer?.attributes?.Statuses?.[0])
    ].every(Boolean))

    const notifyUnitOffers = activeUnitOffers.filter((offer) => offer.attributes.Notify === 'Landlord')

    const offerPrices: number[] = activeUnitOffers
        .map((offer) => Number(offer?.attributes?.Type?.[0]?.Offer))
        .filter(Number.isFinite)
    const maxOfferPrice = Math.max(...offerPrices) ?? 0

    const viewings = useAppSelector((state) => state.viewings.viewings)
    const upcomingViewings = viewings.filter((viewing) => [
        viewing?.attributes?.Unit?.data?.id === unit.id,
        !['Completed', 'Canceled'].includes(viewing?.attributes?.Statuses?.[0] ?? ''),
        dayjs(viewing?.attributes?.Datetime).isAfter(dayjs())
    ].every(Boolean))

    const closestViewing = upcomingViewings?.sort((viewingA, viewingB) => {
        const dateTimeA = dayjs(viewingA.attributes.Datetime)
        const dateTimeB = dayjs(viewingB.attributes.Datetime)
        if (dateTimeA.isBefore(dateTimeB)) {
            return -1
        } else if (dateTimeA.isSame(dateTimeB)) {
            return 0
        } else {
            return 1
        }
    })?.[0]

    const closestViewingDateTime = closestViewing?.attributes?.Datetime != null
        ? dayjs(closestViewing?.attributes?.Datetime)
        : undefined

    return {
        serviceIsActive,
        activeOffersCount: activeUnitOffers?.length ?? 0,
        notifyOffersCount: notifyUnitOffers?.length ?? 0,
        maxOfferPrice,
        upcomingViewingsCount: upcomingViewings?.length ?? 0,
        closestViewingDateTime
    }
}

export default useServiceFindTenants