import { type AppDispatch, type RootState } from '../store'

import { ACCESS_KEY, urlAPI } from '../../urls'
import { clearPropertyDeleteTasks, setProperties } from '../slices/propertySlice'
import _ from 'lodash'
import { uploadStrapiFile } from './fileActions'

import { type ApiResponse } from '../../types/userTypes/TAuth'
import { dataFetchingFinish, dataFetchingStart, setDrawerOpen, setDynamicDropDown } from '../slices/modalSlice'
import { nanoid } from 'nanoid'
import { setValue } from '../slices/setUnitSlice'
import qs from 'qs'
import mapPropertiesForSelect from '../../functions/shared/properties/mapPropertiesForSelect'

export const fetchProperties = () => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean }> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

        const query = {
            populate: ['Units']
        }

        const bodyObject = {
            method: 'GET',
            collection: 'properties',
            query: qs.stringify(query, { encodeValuesOnly: true })
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response.json() as unknown as ApiResponse
        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                return ({
                    isSuccessful: false
                })
            } else {
                dispatch(
                    setProperties(
                        responseJSON.response.data
                    )
                )
                return ({
                    isSuccessful: true
                })
            }
        } else {
            return ({
                isSuccessful: false
            })
        }
    } catch (error) {
        console.log(error)
        return ({
            isSuccessful: false
        })
    }
}

export const addProperty = (data: any, checkStoredProperties: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        const storedProperty = _.find(
            getState().property.properties,
            (property) => [
                property?.attributes?.Name === data.Name,
                property?.attributes?.Area === data?.Area,
                property?.attributes?.GoogleMapsLink === data?.GoogleMapsLink
            ].every(Boolean))
        if (storedProperty != null && checkStoredProperties) {
            return {
                isSuccessful: true,
                response: {
                    data: storedProperty
                }
            }
        } else {
            dispatch(dataFetchingStart())
            const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
            if (data.Images != null) {
                const uploadPromise = Promise.all(_.map(data.Images, async (file) => await uploadStrapiFile(file, 'properties')))
                const images = await uploadPromise
                data.Images = _.map(images, (image) => image?.data)
            }
            data.RefID = [data.Name, data.Area, new Date().getTime()]?.join('-')
            const bodyObject = {
                method: 'POST',
                collection: 'properties',
                body: data
            }

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(bodyObject),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })

            const responseJSON = await response.json() as unknown as any
            await dispatch(fetchProperties())
            dispatch(setDynamicDropDown(mapPropertiesForSelect(getState().property.properties)))
            // dispatch(setDynamicDropDown(_.map(getState().property.properties, (property: any) => ({ label: property.attributes.Name, value: property.id }))))
            // Set value in add unit form
            dispatch(setValue({ value: responseJSON?.response?.data?.id, id: 'Property' }))
            dispatch(setDrawerOpen(undefined))
            dispatch(dataFetchingFinish())
            responseJSON.isSuccessful = responseJSON.success
            return responseJSON
        }
    } catch (error) {
        console.log(error)
    }
}

export const editProperty = (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        dispatch(dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        if (data.Images != null) {
            const uploadPromise = Promise.all(_.map(data.Images, async (file) => await uploadStrapiFile(file, 'properties')))
            const images = await uploadPromise
            data.Images = _.map(images, (image) => image?.data)
        }
        const propertyId = _.find(getState().setUnit.staticForm, (row) => row.id === 'Property')?.value ?? -1
        const property = getState().property.properties.find((property) => property.id === propertyId)
        data.RefID = property?.attributes?.RefID ?? [data.Name, data.Area, new Date().getTime()]?.join('-')

        const bodyObject = {
            method: 'PUT',
            collection: 'properties',
            id: propertyId,
            body: data
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response.json() as unknown as any
        await dispatch(fetchProperties())
        dispatch(setDynamicDropDown(mapPropertiesForSelect(getState().property.properties)))
        dispatch(setDrawerOpen(undefined))
        dispatch(dataFetchingFinish())
        responseJSON.isSuccessful = responseJSON.success
        return responseJSON
    } catch (error) {
        console.log(error)
    }
}

export const deleteProperty = (propertyId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean }> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const bodyObject = {
            method: 'DELETE',
            collection: 'properties',
            id: propertyId
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as any
        if (responseJSON.response.error == null) {
            await dispatch(fetchProperties())
            return {
                isSuccessful: true
            }
        }
    } catch (err) {
        console.error(err)
    }
    return {
        isSuccessful: false
    }
}

export const processPropertyDeleteTasks = (selectedPropertyId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const propertyDeleteTasks = getState().property.propertyDeleteTasks
        .filter(task => (
            task.property.id !== selectedPropertyId && task.property.attributes?.Units?.data?.length === 0
        ))
        .map(async (task) => await dispatch(deleteProperty(task.property.id)))
    const result = await Promise.all(propertyDeleteTasks)
    const isSuccessful = result.every(res => res.isSuccessful)
    // if (isSuccessful) {
    dispatch(clearPropertyDeleteTasks())
    // }
    return {
        isSuccessful
    }
}
