import { type AppDispatch, type RootState } from '../../../store'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../../slices/modalSlice'
import { type IServicePlan } from '../../../slices/serviceSlice'
import dayjs from 'dayjs'
import nanToNull from '../../../../functions/adapters/universal/nanToNull'
import { fetchUser } from '../../userActions'

export interface AddServiceDTO {
    serviceId: string
    plan?: IServicePlan
    dop?: any[]
    unitId?: number
}

export const addService = ({ serviceId, plan, dop, unitId }: AddServiceDTO) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean }> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    dispatch(dataFetchingStart())
    try {
        let servicePrice = nanToNull(Number(plan?.price)) ?? 0
        dop?.forEach((dopItem: any) => {
            servicePrice += nanToNull(Number(dopItem.price)) ?? 0
        })
        const data: Record<string, any> = {
            InvoiceDate: dayjs().toDate(),
            Name: `${serviceId}-${plan?.name ?? 'no_plan'}-${String(dop?.map((dopItem: any) => `${String(dopItem.name)}`).join('-') ?? 'no_dop')}`,
            Amount: servicePrice,
            User: getState().user.currentUser.id,
            Unit: unitId,
            Statuses: ['In-process']
        }
        switch (serviceId) {
            case 'finding_tenant':
                data.Service = [{
                    __component: 'services.finding-tenant'
                }]
                switch (plan?.name) {
                    case 'One Day':
                        data.Service[0].Package = 'One day plan'
                        data.InvoiceDueDate = dayjs().add(1, 'day').toDate()
                        break
                    case '3-Days':
                        data.Service[0].Package = 'Three days plan'
                        data.InvoiceDueDate = dayjs().add(3, 'day').toDate()
                        break
                    case 'Weekly':
                        data.Service[0].Package = 'Weekly subscription'
                        data.InvoiceDueDate = dayjs().add(7, 'day').toDate()
                        break
                    case 'BSO Premium':
                        data.Service[0].Package = 'BSO Premium subscription'
                        data.InvoiceDueDate = dayjs().add(7, 'day').toDate()
                        break
                    default:
                        break
                }
                switch (dop?.[0]?.name) {
                    case 'We will determine the cost for you':
                        data.Service[0].AdditionalServices = 'We will determine the cost for you'
                        break
                    default:
                        break
                }
                break
            default:
                break
        }
        const bodyObject = {
            method: 'POST',
            collection: 'orders',
            body: data
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                'BSO-Hash': '02ns30nsBSO!',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response?.json() as unknown as any
        responseJSON.isSuccessful = responseJSON?.response?.error == null
        if (responseJSON.isSuccessful === true) {
            await dispatch(fetchUser(true))
        }
        return {
            isSuccessful: responseJSON.isSuccessful
        }
    } catch (err) {
        console.log('Error while adding service', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}
