import ImagesPreviewGroup
    from "../../../../../../../app/custom/myCostomFormForViewOnli/previewImage/ImagesPreviewGroup";
import CloseButton from "../../../../../UIv2/Button/CloseButton";
import React, {type ReactElement} from "react";
import {type ViewUnitSectionProps} from "../ViewUnitSection";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../../../../../hooks/redux";

export default function ViewUnitSectionHeading ({unit}: ViewUnitSectionProps): ReactElement {
    const navigate = useNavigate()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    return <>
        <ImagesPreviewGroup currentUnit={unit} />
        <CloseButton
            onClick={() => {
                navigate(-1)
            }}
            styles={{
                position: 'absolute',
                top: userDevice === 'IPhone' ? '32px' : '8px',
                right: '16px'
            }}
        />
    </>
}