import React, {type ReactElement} from "react";
import {type IconName} from "../../../../app/icon/iconsConfig";
import Icon from "../../../../app/icon/Icon";

export type BadgeStyle = 'bso' | 'light-gray' | 'gray' | 'gray-400' | 'dark-gray' | 'dark' | 'danger' | 'danger-outlined' | 'danger-light' | 'success-light'

interface BadgeProps {
    text: string
    icon?: {
        position: 'start' | 'end',
        name: IconName,
        filled?: boolean
        styles?: React.CSSProperties
    }
    style: BadgeStyle,
    size: '10' | '13' | '18' | '22' | '24' | '25',
    key?: string
}

export default function Badge ({text, icon, style, size, key}: BadgeProps) : ReactElement {
    let className = ''
    let styles: React.CSSProperties = {}
    switch (size) {
        case '10':
            styles = {
                height: '10px',
                borderRadius: '100px'
            }
            className = 'fs-10 fw-bold py-1 px-2 gap-1'
            break
        case '13':
            styles = {
                height: '13px',
                borderRadius: '100px'
            }
            className = 'fs-9 fw-bold py-1 px-2 gap-1'
            break
        case '18':
            styles = {
                height: '18px',
                borderRadius: '5px'
            }
            className = 'fs-9 fw-medium px-2 py-1 gap-1'
            break
        case '22':
            styles = {
                height: '22px',
                borderRadius: '5px'
            }
            className = 'fs-8 fw-medium px-3 py-2 gap-1'
            break
        case '24':
            styles = {
                height: '24px',
                borderRadius: '16px'
            }
            className += 'fs-7 px-4 gap-2'
            break
        case '25':
            styles = {
                height: '25px',
                borderRadius: '25px'
            }
            className += 'fs-5 px-3 gap-2'
            break
        default:
            break
    }
    switch (style) {
        case 'bso':
            className += ' d-flex align-items-center bg-bso text-dark lh-1'
            break
        case 'light-gray':
            className += ' d-flex align-items-center bg-gray-200 text-gray-600 lh-1'
            break
        case 'gray':
            className += ' d-flex align-items-center bg-secondary text-dark lh-1'
            break
        case 'gray-400':
            className += ' d-flex align-items-center bg-gray-400 text-white lh-1'
            break
        case 'dark-gray':
            className += ' d-flex align-items-center bg-gray-700 text-white lh-1'
            break
        case 'dark':
            className += ' d-flex align-items-center bg-dark text-white lh-1'
            break
        case 'danger':
            className += ' d-flex align-items-center bg-danger text-white lh-1'
            break
        case 'danger-outlined':
            className += ' d-flex align-items-center bg-white border border-danger text-danger lh-1'
            break
        case 'success-light':
            className += ' d-flex align-items-center fw-bold lh-1'
            styles.background = '#C0DED2'
            styles.color = '#3E8367'
            break
        case 'danger-light':
            className += ' d-flex align-items-center fw-bold lh-1'
            styles.background = '#FFC3C3'
            styles.color = '#DC5454'
            break
        default:
            break
    }
    return <div className={className} style={styles} key={key}>
        {
            icon?.position === 'start'
                ? <Icon name={icon.name} filled={icon.filled} className={'fs-7'} styles={icon.styles}/>
                : <></>
        }
        <span>
            {text}
        </span>
        {
            icon?.position === 'end'
                ? <Icon name={icon.name} filled={icon.filled} className={'fs-7'}/>
                : <></>
        }
    </div>
}