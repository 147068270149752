import React from 'react'

interface StateLink {
    Title: string
    Link: string
    Trakheesi_id: string
    Status: string
}

interface IProps {
    stateLinks: StateLink[]
}
export default function SecondTryTable ({ stateLinks }: IProps) {
    return (
        <>
            {stateLinks && stateLinks.length > 0
                ? stateLinks.map((lin: any, i: number) => {
                    const title = lin?.Title
                    const link = lin?.Link
                    const trakheesi = lin?.Trakheesi_id
                    const sts = lin?.Status
                    return (

                        <div
                            // className={'card border border-2'}

                            className={`card shadow-sm my-2 d-flex flex-row row g-0 p-1 me-1  align-items-start justify-content-between position-relative border border-1 border-${sts === 'Approved' ? 'success' : sts === 'In-process' ? 'warning' : 'danger'} bg-light-${sts === 'Approved' ? 'success' : sts === 'In-process' ? 'warning' : 'danger'}`}
                        >

                            {/* <span className={`bullet bullet-vertical d-flex align-items-center h-auto bg-${sts === 'Approved' ? 'success' : sts === 'In-process' ? 'warning' : 'danger'}`}></span> */}

                            <div className="d-flex">
                                <div className={'position-absolute top-1  translate-middle'} style={{ right: '-32px' }}>
                                    <span className={`badge me-2 badge-light-${sts === 'Approved' ? 'success' : sts === 'In-process' ? 'warning' : 'danger'}`}>{sts}</span>
                                    {/* <div className={`bullet bullet-line d-flex align-items-center h-1px w-100 bg-${sts === 'Approved' ? 'success' : sts === 'In-process' ? 'warning' : 'danger'}`}> </div> */}
                                </div>
                            </div>
                            <div className='py-2 px-2'>
                                <div className={'d-flex justify-content-between align-items-center'}>
                                    <span className="text-dark fw-semi-bold fs-7 ">Title</span>
                                    <span className="text-gray-800 text-hover-primary fw-semi-bold fs-7 text-break">{title}</span>
                                </div>
                                <div className="separator border-dark-gray-400  separator-dashed  "></div>
                                <div className={'d-flex justify-content-between align-items-center  text-end   '}>
                                    <span className="text-dark fw-semi-bold fs-7 ">Link</span>
                                    <a href={link} target={'_blank'} className="ps-4 text-endtext-primary text-break fw-semi-bold fs-7" rel="noreferrer">{link}</a></div>
                                <div className="separator border-dark-gray-400  separator-dashed "></div>
                                <div className={'d-flex justify-content-between align-items-center  text-end'}>
                                    <span className="text-dark fw-semi-bold fs-7 ">Trakheesi ID</span>
                                    <span className="text-gray-800 text-hover-primary fw-semi-bold fs-7">{trakheesi}</span>
                                </div>
                            </div>

                            {/* {i !== stateLinks.length - 1 */}
                            {/*    ? <div className="separator separator-dashed border-dark my-3"></div> */}
                            {/*    : null } */}
                        </div>
                    )
                })
                : null
            }
        </>
    )
}
