import { type AppDispatch, type RootState, store } from '../../../../store'
import _ from 'lodash'
import { AgentModals } from '../../../../../functions/agent/AgentModals'
import { landlordAddUnitReadFromStorage, type LandlordAddUnitStorageKey } from '../utils/landlordAddUnitHandleStorage'
import searchFuse from '../../../../../functions/search/searchFuse'
import { removeDataAttributes } from '../../../../../functions/adapters/universal/removeDataAttributes'
import { setImages } from '../../../../slices/modalSlice'
import { setImagesLoaded } from '../../../../slices/setUnitSlice'
import { goBackRequestValidation } from '../callbacks/requestValidation'
import { type NavigateFunction } from 'react-router-dom'
import { goBackScanUnitOwnersPassport } from '../callbacks/scanUnitOwnersPassport'
import { goBackScanUnitPowerOfAttorney } from '../callbacks/scanUnitPowerOfAttorney'
import { goBackAddUnitInfo } from '../callbacks/addUnitInfo'
import { goBackAddMainInfoFirst } from '../callbacks/addUnitMainInfoFirst'
import { goBackAddMainInfoSecond } from '../callbacks/addUnitMainInfoSecond'
import { goBackAddAdditionalUnitInfo } from '../callbacks/addUnitAdditionalInfo'
import { goBackAddAdditionalUnitInfoOutdoor } from '../callbacks/addUnitAdditionalInfoOutdoor'
import { goBackAddAdditionalUnitInfoFiles } from '../callbacks/addUnitAdditionalInfoFiles'
import { goBackAddUnitMarketingInfo } from '../callbacks/addUnitMarketing'

export const fillValuesForAddUnitModal = (modalID: string, unitID: number, navigate: NavigateFunction) => (dispatch: AppDispatch, getState: () => RootState): { modal: [Record<string, unknown>], initialState: Record<string, unknown> } => {
    const modal = _.cloneDeep(
        AgentModals().find((modal: any) => modal.id === modalID)
    )
    const initialState: Record<string, unknown> = {}
    const storedData = landlordAddUnitReadFromStorage()
    const rawUnit = getState().units.units.find((unit) => unit.id === unitID)

    const fieldsData: Record<string, any> = Object.keys(storedData).reduce((acc, key) => ({
        ...acc,
        ...storedData[key as LandlordAddUnitStorageKey]
    }), {})
    const filteredFieldsData = _.omitBy(fieldsData, (value, key) => key.includes('.'))

    const currentUser = getState().user.currentUser

    if ((currentUser?.Units as any)?.length === 0) {
        modal.header = modal.header.replace('new', 'your first')
    }

    if (rawUnit == null) {
        modal.form.forEach((row: Record<string, any>) => {
            initialState[row.id] = _.get(filteredFieldsData, row.id)
            switch (row.id) {
                // Landlord add unit info modal
                case 'title_deed_info':
                    if (storedData.title_deed != null) {
                        if (storedData.title_deed?.response?.document?.number != null) {
                            row.content.push({
                                label: 'Title Deed Number',
                                text: storedData.title_deed?.response?.document?.number
                            })
                        }
                        if (storedData.title_deed?.response?.document?.type != null) {
                            row.content.push({
                                label: 'Title Deed Type',
                                text: storedData.title_deed?.response?.document?.type
                            })
                        }
                        if (storedData.title_deed?.response?.document?.year != null) {
                            row.content.push({
                                label: 'Title Deed Issue Year',
                                text: storedData.title_deed?.response?.document?.year
                            })
                        }
                        if (storedData.title_deed?.response?.document?.owners != null) {
                            storedData.title_deed?.response?.document?.owners?.forEach((owner: any) => {
                                row.content.push({
                                    label: 'Owner',
                                    text: owner?.name
                                })
                            })
                        }
                    }
                    break
                case 'Number':
                    if (storedData.title_deed?.response?.document?.property?.number != null && initialState[row.id] == null) {
                        initialState[row.id] = storedData.title_deed.response.document.property.number
                    }
                    break
                case 'Property.Name':
                    if (storedData.title_deed?.response?.document?.property?.name != null && initialState[row.id] == null) {
                        initialState[row.id] = storedData.title_deed.response.document.property.name
                    }
                    break
                case 'Property.Area':
                    if (storedData.title_deed?.response?.document?.property?.area != null && initialState[row.id] == null) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const searchedAreas = searchFuse(
                            getState()?.app?.config?.all?.Area ?? [],
                            storedData.title_deed.response.document.property.area,
                            0.4,
                            []
                        )
                        initialState[row.id] = searchedAreas[0]?.item
                    }
                    break
                case 'Type':
                    row.onChange = (option: string) => {
                        try {
                            const fields = document.querySelectorAll('#Bedrooms, #GuestBathroom, #MaidsRoom')
                            const bathroomsField = document.querySelector('#Bathrooms')
                            const bathroomsLabel = bathroomsField?.querySelector('label')
                            const bathroomsInput = bathroomsField?.querySelector('input')
                            if (option === 'Commercial') {
                                if (bathroomsLabel != null) {
                                    bathroomsLabel.innerText = 'Restrooms'
                                }
                                if (bathroomsInput != null) {
                                    bathroomsInput.placeholder = 'Enter the number of restrooms'
                                }
                                fields.forEach((field) => {
                                    field.classList.add('d-none')
                                })
                            } else {
                                if (bathroomsLabel != null) {
                                    bathroomsLabel.innerText = 'Bathrooms'
                                }
                                if (bathroomsInput != null) {
                                    bathroomsInput.placeholder = 'Enter the number of bathrooms'
                                }
                                fields.forEach((field) => {
                                    field.classList.remove('d-none')
                                })
                            }
                        } catch (err: any) {
                            console.error(`Error while switching visibility of fields ${String(err.message)}`)
                        }
                    }
                    break
                case 'GuestBathroom,MaidsRoom':
                    initialState.GuestBathroom = filteredFieldsData?.GuestBathroom
                    initialState.MaidsRoom = filteredFieldsData?.MaidsRoom
                    break
                default:
                    break
            }
        })
    } else {
        const unit = removeDataAttributes(_.cloneDeep(rawUnit))
        if (
            !(modalID === 'landlord_create_unit_marketing' && rawUnit.attributes.Marketing == null) &&
            !(modalID === 'landlord_create_unit_info' && rawUnit.attributes.Number == null) &&
            !(modalID === 'landlord_create_unit_additional_info' && rawUnit.attributes.SizeArea == null)
        ) {
            modal.header = modal.header
                .replace('Set', 'Edit')
                .replace('Add new', 'Edit')
        }
        const currentDocuments = store.getState().documents.documents.filter(doc => (
            unit?.Documents?.some((unitDoc: any) => (
                unitDoc.id === doc.id
            ))
        ))
        const titleDeedDocument = currentDocuments.findLast(doc => doc?.attributes?.Type?.[0]?.__component === 'documents.title-deed')

        if (
            !['landlord_create_unit_marketing'].includes(modalID) &&
            !(modalID === 'landlord_create_unit_info' && rawUnit.attributes.Number == null) &&
            !(modalID === 'landlord_create_unit_additional_info' && rawUnit.attributes.SizeArea == null)
        ) {
            if (titleDeedDocument?.id === storedData?.title_deed?.id || ['landlord_create_unit'].includes(modalID)) {
                modal.footer.push({
                    id: 'reset', content: ['Skip'], element: 'button-secondary'
                })
            }
        }

        modal.form.forEach((row: Record<string, any>) => {
            switch (row.id) {
                case 'title_deed_info':
                    if (storedData.title_deed != null) {
                        if (storedData.title_deed?.response?.document?.number != null) {
                            row.content.push({
                                label: 'Title Deed Number',
                                text: storedData.title_deed?.response?.document?.number
                            })
                        }
                        if (storedData.title_deed?.response?.document?.owners != null) {
                            storedData.title_deed?.response?.document?.owners?.forEach((owner: any) => {
                                row.content.push({
                                    label: 'Owner',
                                    text: owner?.name
                                })
                            })
                        }
                    }
                    break
                case 'Number':
                    if (storedData.title_deed?.response?.document?.property?.number != null && titleDeedDocument?.id !== storedData?.title_deed?.id) {
                        initialState[row.id] = _.get(filteredFieldsData, row.id) ?? storedData.title_deed.response.document.property.number
                    } else {
                        initialState[row.id] = _.get(filteredFieldsData, row.id) ?? _.get(unit, row.id)
                    }
                    break
                case 'Property.Name':
                    if (storedData.title_deed?.response?.document?.property?.name != null && titleDeedDocument?.id !== storedData?.title_deed?.id) {
                        initialState[row.id] = storedData.title_deed.response.document.property.name
                    } else {
                        initialState[row.id] = _.get(filteredFieldsData, row.id) ?? _.get(unit, row.id)
                    }
                    break
                case 'Property.Area':
                    if (storedData.title_deed?.response?.document?.property?.area != null && titleDeedDocument?.id !== storedData?.title_deed?.id) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const searchedAreas = searchFuse(
                            getState()?.app?.config?.all?.Area ?? [],
                            storedData.title_deed.response.document.property.area,
                            0.4,
                            []
                        )
                        initialState[row.id] = searchedAreas[0]?.item
                    } else {
                        initialState[row.id] = _.get(filteredFieldsData, row.id) ?? _.get(unit, row.id)
                    }
                    break
                case 'Images':
                    if (unit?.Images?.length > 0) {
                        const images = _.get(filteredFieldsData, row.id) ?? unit?.Images
                        dispatch(setImages(images?.map((image: any) => ({ data: image }))))
                        dispatch(setImagesLoaded(true))
                    }
                    break
                case 'Video':
                case 'FloorPlan':
                    row.uploadedBase64 = _.get(filteredFieldsData, row.id) ?? _.get(unit, row.id)
                    break
                case 'Type':
                    row.onChange = (option: string) => {
                        try {
                            const fields = document.querySelectorAll('#Bedrooms, #GuestBathroom, #MaidsRoom')
                            const bathroomsField = document.querySelector('#Bathrooms')
                            const bathroomsLabel = bathroomsField?.querySelector('label')
                            const bathroomsInput = bathroomsField?.querySelector('input')
                            if (option === 'Commercial') {
                                if (bathroomsLabel != null) {
                                    bathroomsLabel.innerText = 'Restrooms'
                                }
                                if (bathroomsInput != null) {
                                    bathroomsInput.placeholder = 'Enter the number of restrooms'
                                }
                                fields.forEach((field) => {
                                    field.classList.add('d-none')
                                })
                            } else {
                                if (bathroomsLabel != null) {
                                    bathroomsLabel.innerText = 'Bathrooms'
                                }
                                if (bathroomsInput != null) {
                                    bathroomsInput.placeholder = 'Enter the number of bathrooms'
                                }
                                fields.forEach((field) => {
                                    field.classList.remove('d-none')
                                })
                            }
                        } catch (err: any) {
                            console.error(`Error while switching visibility of fields ${String(err.message)}`)
                        }
                    }
                    initialState.Type = _.get(filteredFieldsData, row.id) ?? rawUnit?.attributes?.Type
                    break
                case 'Bedrooms':
                case 'GuestBathroom':
                case 'MaidsRoom':
                    if (rawUnit?.attributes?.Type === 'Commercial') {
                        row.displayNone = true
                    }
                    initialState[row.id] = _.get(filteredFieldsData, row.id) ?? (rawUnit?.attributes as any)?.[row.id]
                    break
                case 'Bathrooms':
                    if (rawUnit?.attributes?.Type === 'Commercial') {
                        row.content = ['Restrooms', 'Enter the number of restrooms']
                    }
                    initialState[row.id] = _.get(filteredFieldsData, row.id) ?? (rawUnit?.attributes as any)?.[row.id]
                    break
                case 'GuestBathroom,MaidsRoom':
                    initialState.GuestBathroom = _.get(filteredFieldsData, 'GuestBathroom') ?? (rawUnit?.attributes as any)?.GuestBathroom
                    initialState.MaidsRoom = _.get(filteredFieldsData, 'MaidsRoom') ?? (rawUnit?.attributes as any)?.MaidsRoom
                    break
                default:
                {
                    // if (_.isBoolean(value)) {
                    //     value = value ? 'Yes' : 'No'
                    // }
                    initialState[row.id] = _.get(filteredFieldsData, row.id) ?? _.get(unit, row.id)
                    break
                }
            }
        })
    }

    let goBack: (() => void) | null = null

    switch (modalID) {
        case 'landlord_create_unit':
            goBack = null
            break
        case 'landlord_create_unit_request_validation':
            goBack = goBackRequestValidation(navigate, unitID)
            break
        case 'landlord_create_unit_owners_passport':
            goBack = goBackScanUnitOwnersPassport(navigate, unitID)
            break
        case 'landlord_create_unit_poa':
            goBack = goBackScanUnitPowerOfAttorney(navigate, unitID)
            break
        case 'landlord_create_unit_info':
            goBack = goBackAddUnitInfo(navigate, unitID)
            break
        case 'landlord_create_main_info_first':
            goBack = goBackAddMainInfoFirst(navigate, unitID)
            break
        case 'landlord_create_main_info_second':
            goBack = goBackAddMainInfoSecond(navigate, unitID)
            break
        case 'landlord_create_unit_additional_info':
            goBack = goBackAddAdditionalUnitInfo(navigate, unitID)
            break
        case 'landlord_create_unit_additional_info_outdoor':
            goBack = goBackAddAdditionalUnitInfoOutdoor(navigate, unitID)
            break
        case 'landlord_create_unit_additional_info_files':
            goBack = goBackAddAdditionalUnitInfoFiles(navigate, unitID)
            break
        case 'landlord_create_unit_marketing':
            goBack = goBackAddUnitMarketingInfo(navigate, unitID)
            break
        default:
            break
    }

    if (goBack != null) {
        modal.footer = [
            { id: 'back', content: ['Back'], element: 'button-custom-secondary-action', onClick: goBack },
            ...modal.footer
        ]
    }

    return {
        modal: [modal],
        initialState
    }
}
