import _ from 'lodash'
import { AgentModals } from '../../../../../functions/agent/AgentModals'
import { landlordAddUnitReadFromStorage } from '../utils/landlordAddUnitHandleStorage'
import { type AppDispatch, type RootState } from '../../../../store'
import { goBackCorrectLandlordInfo } from '../callbacks/correctLandlordInfo'
import { type NavigateFunction } from 'react-router-dom'

export const fillValuesForCorrectLandlordInfoModal = (modalID: string, unitID: number, navigate: NavigateFunction) => (dispatch: AppDispatch, getState: () => RootState): { modal: [Record<string, unknown>], initialState: Record<string, unknown> } => {
    const modal = _.cloneDeep(
        AgentModals().find((modal: any) => modal.id === modalID)
    )
    const initialState: Record<string, unknown> = {}
    const storedData = landlordAddUnitReadFromStorage()
    const landlordData: Record<string, string> = storedData.owners_passport?.landlordScannedData ?? {}

    modal.form.forEach((row: Record<string, any>) => {
        initialState[row.id as string] = _.get(landlordData, row.id)
    })

    modal.footer = [
        { id: 'back', content: ['Back'], element: 'button-custom-secondary-action', onClick: goBackCorrectLandlordInfo(navigate, unitID) },
        ...modal.footer
    ]

    return {
        modal: [modal],
        initialState
    }
}
