import React, {type ReactElement} from "react";
import Card from "../../../../../../UIv2/Card/Card";
import Badge from "../../../../../../UIv2/Badge/Badge";
import LandlordUnitCardService from "../LandlordUnitCardService";
import {type Unit} from "../../../../../../../../store/slices/unitsSlice";
import {type IUseServiceFindTenantsData} from "./ServiceFindTenant.hook";

interface ServiceFindTenantProps extends IUseServiceFindTenantsData {
    unit: Unit
}

export default function ServiceFindTenant ({
    unit,
    serviceIsActive,
    activeOffersCount,
    notifyOffersCount,
    maxOfferPrice,
    upcomingViewingsCount,
    closestViewingDateTime
}: ServiceFindTenantProps): ReactElement {
    return serviceIsActive
        ? <LandlordUnitCardService
            title={'FIND A TENANT'}
            infoBlocks={[
                <>
                    <div className={'d-flex gap-1 align-items-center mb-2'}>
                        <div className={'fs-6 text-gray-600'}>OFFERS</div>
                        <Badge text={String(activeOffersCount)} style={'dark-gray'} size={'13'}/>
                        <Badge text={String(notifyOffersCount)} style={'danger'} size={'13'}/>
                    </div>
                    { activeOffersCount > 0
                        ? <>
                            <div className={'d-flex align-items-center justify-content-center gap-2 mb-1'}>
                                <div className={'fs-2x'}>
                                    {
                                        maxOfferPrice.toLocaleString('en')
                                    }
                                </div>
                                <div className={'d-flex flex-column gap-1 fs-7 lh-1'}>
                                    <div>AED</div>
                                    <div className={'fw-light'}>per year</div>
                                </div>
                            </div>
                            <div className={'bg-secondary text-center fs-9 lh-1 p-1'} style={{borderRadius: '3px'}}>
                                CURRENT HIGHEST OFFER
                            </div>
                        </>
                        : <Card style={'filled'} rounded={'8'} >
                            <div className={'d-flex justify-content-between align-items-center px-3 py-6 fs-5 lh-1'}>
                                <div>
                                    No active offers
                                </div>
                            </div>
                        </Card>
                    }
                </>,
                <>
                    <div className={'d-flex gap-1 align-items-center mb-2'}>
                        <div className={'fs-6 text-gray-600'}>VIEWINGS</div>
                    </div>
                    <Card style={'filled'} rounded={'8'} >
                        { upcomingViewingsCount > 0
                            ? <div className={'d-flex justify-content-between align-items-center px-3 py-6 fs-5 lh-1'}>
                                <div>
                                    {
                                        closestViewingDateTime?.format('MMM D')
                                    }
                                </div>
                                <div className={'fw-medium'}>
                                    {
                                        closestViewingDateTime?.format('h:mmA')
                                    }
                                </div>
                            </div>
                            : <div className={'d-flex justify-content-between align-items-center px-3 py-6 fs-5 lh-1'}>
                                <div>
                                    No upcoming viewings
                                </div>
                            </div>
                        }
                    </Card>
                </>
            ]}
        />
        : <></>
}