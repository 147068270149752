import React, { useEffect, useRef, useState } from 'react'

// import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
// import { fetchUnits } from '../../../redux/ActionCreators'

import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { isLater } from '../../../functions/agent/date/isLater'

import { type RootState } from '../../../store/store'
import { DatePicker, type DatePickerProps } from 'antd'

import CustomFormLable from '../../../app/custom/customModalUtils/CustomFormLable'
import dayjs from 'dayjs'
import MyCarousel from '../../../app/MyCarousel'

import FormListForMyCustomForView from '../../../app/custom/myCostomFormForViewOnli/FormListForMyCustomForView'

import { AgentModals } from '../../../functions/agent/AgentModals'
import { isReservation } from '../../../functions/agent/date/isReservation'
import {
    getCurrentDubaiDate,
    getCurrentDubaiDateString
} from '../../../functions/agent/date/getCurrentDateStrFormat'
import { getBoardTime } from '../../../functions/getDataForSelects/getBoardTime'
import { setDateChooseViewing } from '../../../store/slices/modalSlice'
import TutorialReserveTimes from './TutorialReserveTimes'
import { type IViewing } from '../../../store/slices/viewingsSlice'
import { nanoid } from 'nanoid'
import { type Unit } from '../../../store/slices/unitsSlice'
import Swal from 'sweetalert2'
import { checkDubaiDateTime } from '../../../functions/shared/date/checkDubaiDateTime'
import GeoForMyCustomModalForView from '../../../app/custom/myCostomFormForViewOnli/GeoForMyCustomModalForView'

const dateFormat = 'YYYY-MM-DD'
export default function ViewingSelectTime({
    id,
    modal,
    unitID,
    row,
    control,
    viewingID,
    register,
    errors,
    clearErrors,
    setValue,
    trigger
}: any): JSX.Element {
    const viewings = useAppSelector(
        (state: RootState) => state.viewings.viewings
    )
    const units = useAppSelector(
        (state: RootState) => state.units.units
    )

    const [unitViewings, setUnitViewings] = useState(viewings?.filter((view) => view.attributes?.Unit.data?.id === Number(unitID)) ?? [])
    const [currentUnit, setCurrentUnit] = useState(units?.filter((el: any) => el.id === Number(unitID))[0])
    const showPopupViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.showPopupViewingReschedule)

    const unitStatuses = currentUnit?.attributes?.Statuses ?? []

    useEffect(() => {
        Swal.close()
        if (!showPopupViewingReschedule) {
            Swal.close()
            // dispatch(setShowPopupViewingShedule(true))
        }
    }, [])
    const dispatch = useAppDispatch()
    useEffect(() => {
        const filteredUnitViewings = viewings.filter((view) => view.attributes?.Unit.data?.id === Number(unitID))
        const foundCurrentUnit = units?.find((el: any) => el.id === Number(unitID)) as Unit
        setUnitViewings(filteredUnitViewings)
        setCurrentUnit(foundCurrentUnit)
    }, [viewings, unitID, units])

    const [data, setData] = useState(getCurrentDubaiDateString())
    const [time, setTime] = useState('')
    const chooseDay = new Date(data).getDay()

    const reservetionHandler = (value: string): void => {
        if (value !== '') {
            setTime(value)
            const updatedBaseDate = `${data}T${value}:00` // +04:00

            setValue(row.id, updatedBaseDate, {
                shouldValidate: true,
                shouldDirty: true
            })
            if (modal[0].id !== 'set_key_bookings_modal' && modal[0].id !== 'set_key_bookings_modal_reschedule') {
                dispatch(setDateChooseViewing(updatedBaseDate))
            }
        }
    }

    const onChange: DatePickerProps['onChange'] = (date, dateString: any) => {
        setData(dateString)
        setTime('')
    }

    const isWorkingHour = (time: string): boolean => {
        return (
            (
                modal[0].id !== 'set_key_bookings_modal' && modal[0].id !== 'set_key_bookings_modal_reschedule'
            ) || (
                Number(time.split(':')[0]) >= 9 && (
                    (
                        Number(time.split(':')[0]) < 18
                    ) || (
                        Number(time.split(':')[0]) === 18 && time.split(':')[1] === '00'
                    )
                )
            )
        )
    }

    return (
        < div key={nanoid()}>
            <label
                htmlFor="date_viewing"
                key={'lable_date_viewing'}
                className={'required form-label mt-3 fs-1x fw-bold text-uppercase'}
            >
                {modal[0].id === 'set_key_bookings_modal' || modal[0].id === 'set_key_bookings_modal_reschedule'
                    ? row.content[1]
                    : 'Date of viewing'
                }
            </label>

            <DatePicker
                onFocus={(e) => {
                    e.target.blur()
                }}
                disabledDate={
                    modal[0].id === 'set_key_bookings_modal' || modal[0].id === 'set_key_bookings_modal_reschedule'
                        ? (date) => date.day() === 0 || date.day() === 6 || date.isBefore(dayjs(), 'day')
                        : undefined
                }
                popupClassName="full-screen"
                defaultValue={dayjs(data, dateFormat)}
                className="d-block mb-4"
                size={'large'}
                name={row.id}
                onChange={onChange}
                allowClear={false}
            />
            <TutorialReserveTimes />

            <div className="fv-row my-2 m-0 p-0 w-100">
                <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
            </div>
            <CustomFormLable row={row} description={'Availability shown as per Dubai timezone'} />

            <input
                hidden
                //        className='visually-hidden'
                name={row.id}
                value={time ? `${data}T${time}:00` : ''}
                {...register(
                    row.id,
                    row.required ? { required: `${row.content?.[0]} is required` } : {}
                )}
                type="text"
            />

            <div className="row g-0 d-flex flex-row">
                {data ? (
                    getBoardTime().map((value) => {
                        if (checkDubaiDateTime(data) === 'Today' && isWorkingHour(value)) {
                            if (isLater(value)) {
                                const resultIsReservation = isReservation(data, value, unitViewings, currentUnit, modal[0].id === 'set_key_bookings_modal' || modal[0].id === 'set_key_bookings_modal_reschedule')
                                return (
                                    <div
                                        key={value + nanoid()}
                                        className={`col-3 btn ${resultIsReservation.isReservCeilCurrentUser
                                            ? 'btn-light-danger active ml-1 mt-1'
                                            : resultIsReservation.isReservCeilOtherUsers
                                                ? 'col-3 btn btn-active-light-dark active ml-1 mt-1'
                                                : resultIsReservation.isFreeCeil
                                                    ? `btn-light-success ml-1 mt-1 ${time && time === value ? 'active' : ''
                                                    }`
                                                    : `btn-light-success ml-1 mt-1 ${time && time === value ? 'active' : ''
                                                    }`
                                            }`}
                                        onClick={
                                            !resultIsReservation.isReservCeilCurrentUser && !resultIsReservation.isReservCeilOtherUsers
                                                ? () => {
                                                    reservetionHandler(value)
                                                }
                                                : undefined
                                        }
                                    >
                                        {value}
                                    </div>
                                )
                                //   }
                            } else {
                                //           console.log(value, 'ELSE Later?')
                                return (
                                    <div
                                        key={value + nanoid()}
                                        className={
                                            'col-3 btn btn-active-light-dark active ml-1 mt-1'
                                        }
                                    >
                                        {value}
                                    </div>
                                )
                            }
                        } else {
                            if (checkDubaiDateTime(data) === 'Future' && isWorkingHour(value)) {
                                const resultIsReservation = isReservation(data, value, unitViewings, currentUnit, modal[0].id === 'set_key_bookings_modal' || modal[0].id === 'set_key_bookings_modal_reschedule')
                                return (
                                    <div
                                        key={value + nanoid()}
                                        className={`col-3 btn ${resultIsReservation.isReservCeilCurrentUser
                                            ? 'btn-light-danger active ml-1 mt-1'
                                            : resultIsReservation.isReservCeilOtherUsers
                                                ? 'col-3 btn btn-active-light-dark active ml-1 mt-1'
                                                : resultIsReservation.isFreeCeil
                                                    ? `btn-light-success ml-1 mt-1 ${time && time === value ? 'active' : ''
                                                    }`
                                                    : `btn-light-success ml-1 mt-1 ${time && time === value ? 'active' : ''
                                                    }`
                                            }`}
                                        onClick={
                                            !resultIsReservation.isReservCeilCurrentUser && !resultIsReservation.isReservCeilOtherUsers
                                                ? () => {
                                                    reservetionHandler(value)
                                                }
                                                : undefined
                                        }
                                    >
                                        {value}
                                    </div>
                                )
                                //   }
                            }
                            if (checkDubaiDateTime(data) === 'Past' || !isWorkingHour(value)) {
                                return (
                                    <div
                                        key={value + nanoid()}
                                        className={
                                            'col-3 btn btn-active-light-dark active ml-1 mt-1'
                                        }
                                    >
                                        {value}
                                    </div>
                                )
                            }
                        }
                    })
                ) : (
                    <div> Choose date</div>
                )}
                {/* gray-500 */}
                {(chooseDay === 6 || chooseDay === 0) && (
                    <div className="mt-3 text-gray-500">
                        Attention, you have chosen{' '}
                        {chooseDay === 6 ? 'Saturday' : chooseDay === 0 ? 'Sunday' : ''}, on
                        this day you can not take or return keys from the BSO office, they
                        can be taken or returned only on weekdays, please take care of it in
                        advance!`
                    </div>
                )}
                {errors[row.id] && (
                    <div className="text-danger">{errors[row.id].message}</div>
                )}

                {modal[0].id === 'agent_update_viewing_modal' || modal[0].id === 'agent_update_viewing_modal_close_btn' || modal[0].id === 'agent_set_viewing_modal'
                    ? (
                        <div className="card d-flex mh-100 ">
                            <div className="fv-row my-2 m-0 p-0 w-100">
                                <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
                            </div>
                            <GeoForMyCustomModalForView googleMapsLink={null} />
                            <div className="fv-row my-2 m-0 p-0 w-100">
                                <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
                            </div>
                            <FormListForMyCustomForView
                                form={
                                    AgentModals().find((el: any) => el.id === 'viewing_modal').form
                                }
                                response={currentUnit}
                            />
                            <div className="fv-row my-2 m-0 p-0 w-100" />
                            <MyCarousel dots={true} autoScrol={true} showTwoColumns={false}>
                                {currentUnit?.attributes.Images
                                    ? (
                                        currentUnit?.attributes.Images?.map((el: string) => (
                                            <div key={`${el}`} className="text-center">
                                                <div
                                                    className="w-100 h-250px border border-gray-500 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center position-relative"
                                                    style={{ backgroundImage: `url(${el})` }}
                                                >
                                                    <div className="h-200px w-100"></div>
                                                </div>
                                            </div>
                                        ))
                                    )
                                    : (
                                        <div>Not images</div>
                                    )}
                            </MyCarousel>
                            <div style={{ height: '70px' }}></div>
                        </div>
                    )
                    : null}
            </div>
        </div>
    )
}
