import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, urlAPIService } from '../../../urls'
import { modalSlice } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'
import swalTexts from '../../../app/texts/swalTexts'

export const scanQrCodeForPickUpKeys = (bodyClient: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const sendedFile = bodyClient.img_scan[0]
    const formData = new FormData()
    const qrTexts = swalTexts.successViewingsActions.scanQrCodeForPickUpKeysViewing
    formData.append('file', sendedFile)
    dispatch(modalSlice.actions.dataFetchingStart())
    try {
        const response = await fetch(`${urlAPIService}/scanQRCode`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.valid) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: `/agent/modalView/viewing_modal/${bodyClient.unitID}/${bodyClient.viewID}/create`, isSuccessful: true, textNotification: qrTexts.defaultMessage }
            } else {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: qrTexts.errorMessage }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: qrTexts.errorMessage }
        }
    } catch (e) {
        console.log(e)
        dispatch(modalSlice.actions.dataFetchingFinish())
        return { textNavigate: '', isSuccessful: false, textNotification: qrTexts.errorMessage }
    }
}
