import React, {type ReactElement} from "react";
import Badge from "../../../../../UIv2/Badge/Badge";
import {nanoid} from "nanoid";

interface ViewUnitSectionAdditionalDetailsRowProps {
    label: string
    values: string[]
}

export default function ViewUnitSectionAdditionalDetailsRow ({label, values}: ViewUnitSectionAdditionalDetailsRowProps): ReactElement {
    return <div className={'d-flex flex-column gap-3'}>
        <div className={'fs-7 text-gray-600'}>{label.toUpperCase()}</div>
        <div className={'d-flex flex-wrap gap-3'}>
            {
                values.map((value) => (
                    <Badge text={value} size={'24'} style={'gray'} key={nanoid()}/>
                ))
            }
        </div>
    </div>
}