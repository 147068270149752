import React from 'react'

import _ from 'lodash'

interface IProps {
    register: any
    errors: any
    row: any
}
export default function AreaInput({ register, errors, row }: IProps): JSX.Element {
    return (
        <div className="fv-row mb-2 m-0 p-0 w-100 fv-plugins-icon-container">
            <label className={
                (row.required)
                    ? 'required form-label mb-2 fw-bold text-dark text-uppercase'
                    : 'form-label fw-bold text-dark text-uppercase'
            }>
                {row.content?.[0]}
            </label>
            <textarea
                {...register(row.id, {
                    required: row.required ? `Field "${row.content?.[0]}" is required` : undefined,
                    minLength: 2,
                    maxLength: row.maxLength ?? 256
                })
                }
                className="form-control form-control-solid form-control-lg"
                rows={5}
                placeholder={row.content?.[1]}
                defaultValue={row.defaultValue}
            // type="text"
            //   onChange={handleInputChange}
            />
            {
                errors[row.id] && (
                    <div className="text-danger">{errors[row.id].message}</div>
                )
            }
            {
                errors[row.id] && errors[row.id].type === 'minLength' && (
                    <div className="text-danger">{`${row.content?.[0]} can not be less than 3 characters `} </div>
                )
            }
            {
                errors[row.id] && errors[row.id].type === 'maxLength' && (
                    <div className="text-danger">{`${row.content?.[0]} cannot exceed  ${row.maxLength ?? 256} characters more `} </div>
                )
            }
        </div>
    )
}
