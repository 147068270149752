import React, {type ReactElement} from "react";
import {type IconName} from "../../../../app/icon/iconsConfig";

interface IconProps {
    type: 'icon'
    name: IconName
}
interface ImageProps {
    type: 'image'
    src: string
}

type MediaProps = IconProps | ImageProps

interface SelectCardProps {
    title: string
    titlePlacement?: 'horizontal' | 'vertical'
    showRadio?: boolean
    fillImage?: boolean
    description: string
    color: `#${string}`
    icon: MediaProps
    isSelected: boolean
    onClick?: () => void
}

export default function SelectCard ({title, description, color, icon, isSelected, onClick, titlePlacement = 'horizontal', showRadio = true, fillImage = true}: SelectCardProps): ReactElement {
    return (
        <div className={`d-flex flex-column gap-3 p-5 border ${isSelected ? 'border-dark' : 'border-gray-300'}`}
            onClick={onClick}
            style={{
                borderRadius: '16px',
                boxShadow: '0px 0px 12px 0px #00000014'
            }}
        >
            <div className={'d-flex justify-content-between align-items-center'}>
                <div className={`d-flex ${titlePlacement === 'horizontal' ? 'align-items-center gap-3' : 'flex-column gap-8'}`}>
                    { fillImage
                        ? <div className={'p-1'}
                            style={{
                                borderRadius: '50%',
                                width: 'fit-content',
                                backgroundColor: `${color}15`// '#C9E4FF80'
                            }}
                        >
                            <div className={'d-flex align-items-center justify-content-center'}
                                style={{
                                    height: '25px',
                                    width: '25px',
                                    borderRadius: '50%',
                                    backgroundColor: `${color}20`// '#C9E4FF'
                                }}
                            >
                                {
                                    icon.type === 'image'
                                        ? <img src={icon.src} alt={title} style={{ height: '16px', width: '16px' }}/>
                                        : <></> // TODO add the Icon implementation
                                }
                            </div>
                        </div>
                        : <div>
                            {
                                icon.type === 'image'
                                    ? <img src={icon.src} alt={title} style={{ height: '24px', width: '24px' }}/>
                                    : <></> // TODO add the Icon implementation
                            }
                        </div>
                    }
                    <div className={'fs-5 fw-medium'}>
                        {
                            title
                        }
                    </div>
                </div>
                {
                    isSelected && showRadio
                        ? <div className={'border border-dark p-2'}
                            style={{
                                borderRadius: '50%'
                            }}
                        >
                            <div style={{
                                height: '10px',
                                width: '10px',
                                backgroundColor: color,
                                borderRadius: '50%'
                            }}/>
                        </div>
                        : <></>
                }
            </div>
            <div className={'fw-light fs-5'}>
                {
                    description
                }
            </div>
        </div>
    )
}