import React, { useEffect, useState } from 'react'
import StartSub from './StartSub'

import MidSub from './MidSub'
import EndSub from './EndSub'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { mainSwalDeal, swalJustAlertAndRedirect } from '../../../../functions/swalOptions'

import { Navigate, useNavigate } from 'react-router-dom'
import { type IOrder, type OrderService } from '../../../../store/slices/userSlice'
import { createSubscriptionAgent, createSubscriptionAgentWithPaymentMethod } from '../../../../store/actionsCreators/subscriptionsActions/createSubscriptionAgent'
import FormPaymentComponent from './FormPaymentComponent'
import swalsConfig from '../../../../app/texts/swalTexts'
export default function SubscritionsList (): JSX.Element {
    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser
    )
    // console.log(currentUser, 'currentUser')
    const userID = currentUser.id

    // const ServiceAgentSubscription = currentUser.Orders && currentUser.Orders.filter((servise: OrderService) => servise.__component === 'services.broker-subscription')

    const ServiceAgentSubscription = (currentUser.Orders as IOrder[])?.filter(
        (order: IOrder) => order.Service.some((service) => service.__component === 'services.broker-subscription')
    )
    ServiceAgentSubscription?.sort((a, b) => new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime())

    const latestServiceAgentSubscription = ServiceAgentSubscription?.[0]
    const activeSub = latestServiceAgentSubscription?.Service?.[0]?.Package ?? '' // 'free'
    // const activeSub = ''
    // console.log(activeSub, 'activeSub')
    // {__component: 'services.broker-subscription'

    const [selectedSubscription, setSelectedSubscription] = useState<string>(activeSub)
    const [selectedPeriod, setSelectedPeriod] = useState<string>('month')
    const { buttonSubscritionsOpen } = useAppSelector((state: RootState) => state.modal)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    //  console.log(selectedSubscription, 'selectedSubscription')
    useEffect(() => {
        setSelectedSubscription(activeSub)
    }, [buttonSubscritionsOpen])
    //  console.log(selectedSubscription, 'selectedSubscription')

    // swalWithBootstrapButtons.fire({
    //         confirmButtonText: "Yes, let's pay and set my areas",
    //         denyButtonText: "No, I want to choose another package",

    //     })

    const handleSubscriptionChange = (renew?: string): void => {
        void mainSwalDeal(swalsConfig.mainDeal.textSubscriptionListRenew(renew), swalsConfig.mainDeal.titleSubscriptionList, swalsConfig.mainDeal.confirmButtonSubscriptionList(selectedSubscription === 'Free'), swalsConfig.mainDeal.denyButtonSubscriptionList).then(async (result: any) => {
            if (result.isConfirmed) {
                //     navigate('/agent/settings/subscriptions/choose')
                // const createOrder = await dispatch(createSubscriptionAgent(selectedSubscription))
                let createOrder: any
                //  || selectedSubscription === ''
                if (selectedSubscription === 'Free') {
                    createOrder = await dispatch(createSubscriptionAgent(selectedSubscription))

                    // console.log(createOrder?.url, 'createOrder?.url')
                } else {
                    createOrder = await dispatch(createSubscriptionAgentWithPaymentMethod(selectedSubscription))
                    //     console.log(createOrder, 'createOrder')
                    //     console.log(createOrder?.url, 'createOrder?.url')
                }
                //     console.log(createOrder, 'createOrder')
                if (createOrder?.isSuccessful) {
                    // void swalJustAlertAndRedirect(createOrder?.textNotification, `You choose ${selectedSubscription} plan`, 'success').then(async (result: any) => {
                    //     if (result.isConfirmed) {
                    if (selectedSubscription === 'Free') {
                        navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/${selectedSubscription}`)
                    } else {
                        window.open(createOrder?.url, '_self')
                    }
                    //  }
                    //  })
                }
            }
        })
    }

    return (

        <div>
            <div className="nav-group nav-group-outline mx-auto mb-4 text-center" >
                <button className={`btn btn-color-gray-400 btn-active btn-active-secondary px-8 py-3 me-2 ${selectedPeriod === 'month' ? 'active' : ''} `} onClick={() => { setSelectedPeriod('month') }} >
                            Monthly
                </button>
                <button className={`btn btn-color-gray-400 btn-active btn-active-secondary px-8 py-3 ${selectedPeriod === 'annual' ? 'active' : ''} `} onClick={() => { setSelectedPeriod('annual') }}>
                            Annual
                </button>
            </div>
            <div className="col-lg-6 mb-10 mb-lg-0">
                <div className="nav flex-column" role="tablist">

                    <StartSub handleSubscriptionChange={handleSubscriptionChange} activeSub={activeSub} selectedPeriod={selectedPeriod} selectedSubscription={selectedSubscription} setSelectedSubscription={setSelectedSubscription}/>
                    <MidSub handleSubscriptionChange={handleSubscriptionChange} activeSub={activeSub} selectedPeriod={selectedPeriod} selectedSubscription={selectedSubscription} setSelectedSubscription={setSelectedSubscription}/>
                    <EndSub handleSubscriptionChange={handleSubscriptionChange} activeSub={activeSub} selectedPeriod={selectedPeriod} selectedSubscription={selectedSubscription} setSelectedSubscription={setSelectedSubscription}/>
                </div>

            </div>

        </div>
    )
}
