import RulesModals from '../../../components/shared/auth/RulesModals'
import React, { type ReactElement } from 'react'
import { nanoid } from 'nanoid'

interface CheckboxGroupProps {
    register: any
    errors: any
    row: any
}

export default function CheckboxGroup ({ register, errors, row }: CheckboxGroupProps): ReactElement {
    const rows = (row?.id as string)
        ?.split(',')
        ?.map((id: any, index: number) => ({
            id,
            content: row?.content?.[index]
        }))
        ?.filter(rowItem => rowItem.id != null && rowItem.content != null) ?? []
    return (
        <div className="fv-row my-2 py-2  w-100">
            {
                rows.map(rowItem => (
                    <div key={nanoid()}>
                        <div className='d-flex align-items-center'>
                            <label className="form-check form-check-solid form-check-inline my-4">
                                <input
                                    {...register(rowItem.id)}
                                    className="form-check-input h-25px w-25px pt-2 me-2"
                                    type="checkbox"
                                    name={rowItem.id}
                                />
                                <span className="fw-bold fs-5">
                                    {rowItem.content}
                                </span>
                            </label>
                        </div>
                        {errors[rowItem.id] != null && (
                            <div className="text-danger">{errors[rowItem.id].message}</div>
                        )}
                    </div>
                ))
            }
        </div>
    )
}
