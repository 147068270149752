import React, { type ReactElement } from 'react'
import SupportInModal from '../../../../app/custom/supportInModal/SupportInModal'
import useCreateUnitModalInfo from './useCreateUnitModalInfo'
import { nanoid } from 'nanoid'
import Icon from '../../../../app/icon/Icon'
export default function CreateUnitModalInfo (): ReactElement {
    const data = useCreateUnitModalInfo()
    return <div className={'w-100 d-flex flex-column gap-4'}>
        <div className={'text-center fw-bold fs-2'}>
            Step {data.currentStep}: {data.title}
        </div>
        <div className={'w-100 rounded rounded-4 bg-light-success p-1 pe-2 position-relative overflow-hidden d-flex align-items-center justify-content-end'}>
            <div
                className={'h-100 d-inline-block bg-success position-absolute top-0 start-0 bottom-0 rounded rounded-4'}
                style={{
                    width: `${Math.min(Math.ceil(data.currentStep / data.stepsCount * 100), 100)}%`
                }}
            />
            <div className={'position-relative z-index-2 fs-6 fw-medium'}>{data.currentStep} / {data.stepsCount}</div>
        </div>
        <div className={'card rounded-4 border border-muted bg-light'}>
            <div className={'card-body d-flex flex-column gap-2 p-4'}>
                <div className={'d-flex gap-4'}>
                    <div className={'rounded-4 p-2'} style={{
                        border: '3px solid black',
                        height: 'fit-content'
                    }}>
                        <Icon name={data.iconName} className={'fs-5x'} imageSize={24}/>
                    </div>
                    <div className={'w-100 fs-4'}>
                        {
                            data.description
                        }
                    </div>
                </div>
                {data.descriptionSteps?.length > 0
                    ? <ul>
                        {
                            data.descriptionSteps.map((step) => (
                                <li key={nanoid()}>
                                    {
                                        step
                                    }
                                </li>
                            ))
                        }
                    </ul>
                    : <></>
                }
            </div>
        </div>
        <div className={'card border border-muted bg-light rounded-4'}>
            <div className={'card-body d-flex justify-content-between align-items-center py-0 px-4'}>
                <div>
                    If you have any questions
                </div>
                <div>
                    <SupportInModal/>
                </div>
            </div>
        </div>
        <div className={'text-center fw-bold fs-2 mt-4 mb-4'}>
            Fill the form below
        </div>
    </div>
}
