import React, {type ReactElement, type ReactNode} from "react";

interface ViewUnitActionsProps {
    buttons: ReactNode[]
}

export default function ViewUnitActions ({ buttons }: ViewUnitActionsProps): ReactElement {
    return <>
        <div className={'position-fixed bottom-0 w-100 d-flex align-items-center gap-3 px-8 py-5 bg-white'}>
            {
                buttons.map((button) => (
                    button
                ))
            }
        </div>
        <div style={{
            height: '72px'
        }}/>
    </>
}