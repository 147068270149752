import React, { useEffect } from 'react'
import MenuItem from './MenuItem'
import { useAppDispatch } from '../../../hooks/redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../../store/slices/authSlice'
import { swalSureLogout } from '../../../functions/swalOptions'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import Icon from '../../../app/icon/Icon'
import { type IconName } from '../../../app/icon/iconsConfig'
import { clearDeviceToken } from '../../../store/actionsCreators/authActions'
import { removeNotifyByDeviceToken } from '../../../functions/shared/notify-helpers/removeNotify'
import { store } from '../../../store/store'
import getUserType from '../../../functions/shared/user/getUserType'

export default function LandlordMenuPage(): JSX.Element {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        document.getElementById('menu_page')?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'

        })
    }, [])

    const logoutHandler = (): any => {
        void swalSureLogout().then(async (result: any) => {
            if (result.isDenied === true) {
                //  const clearDevice = await dispatch(clearDeviceToken())
                //  if (clearDevice.success === true && clearDevice.response != null) {
                dispatch(logout())
                void removeNotifyByDeviceToken({
                    userID: store.getState().user.currentUser.id as number,
                    deviceToken: store.getState().user.currentUser.DeviceToken as string,
                    badges: 0
                })
                location.reload()
                navigate('/auth/login')
                //  }
            }
        })
    }

    const menuConfig: Array<Array<{
        label: string
        description: string
        route: string
        bgColor: string
        icon: string
        onClick?: any
    }>> = [
            [
                {
                    label: 'Profile',
                    description: 'Profile, dashboard, BSO credit balance',
                    route: '/landlord/profile/me',
                    bgColor: 'primary',
                    icon: 'person' // 'bi bi-person fs-3x mx-auto text-white'
                },
                {
                    label: 'Settings',
                    route: '/landlord/settings',
                    description: 'Contact details, order history, notifications',
                    bgColor: 'warning',
                    icon: 'settings'
                }
            ],
            /* [
                        {
                            label: 'My Units',
                            route: '/landlord/my_units',
                            description: 'Add new unit, set up your units catalogue',
                            bgColor: 'danger',
                            icon: 'bi bi-building-gear fs-3x mx-auto text-white'
                        },
                        {
                            label: 'My Referrals',
                            route: '/landlord/referrals',
                            description: 'Refer and earn more money',
                            bgColor: 'info',
                            icon: 'bi bi-person-check fs-3x mx-auto text-white'
                        },
                        {
                            label: 'My Orders',
                            route: '/landlord/services_subscriptions?page=services',
                            description: 'Order history, payments',
                            bgColor: 'primary',
                            icon: 'bi bi-list-task fs-3x mx-auto text-white'
                        },
                        {
                            label: 'My Subscription',
                            route: '/landlord/services_subscriptions?page=subscriptions',
                            description: 'Current subscription, upgrade plan',
                            bgColor: 'warning',
                            icon: 'bi bi-bookmark-star fs-3x mx-auto text-white'
                        }
                    ], */
            /* [
                        {
                            label: 'Tutorials',
                            route: '/landlord/settings/tutorials',
                            description: 'Learn how to take advantage of BSOclub app',
                            bgColor: 'primary',
                            icon: 'bi bi-file-earmark-easel fs-3x mx-auto text-white'
                        },
                        {
                            label: 'Terms and Conditions',
                            route: '/landlord/settings/terms',
                            description: 'Terms and Conditions of using BSO Club Application',
                            bgColor: 'danger',
                            icon: 'bi bi-file-earmark-text fs-3x mx-auto text-white'
                        },
                        {
                            label: 'Privacy Policy',
                            route: '/landlord/settings/privacy',
                            description: 'Privacy policy of using BSO Club Application',
                            bgColor: 'success',
                            icon: 'bi bi-shield-lock fs-3x mx-auto text-white'
                        }
                    ], */
            [
                {
                    label: 'Logout',
                    route: '#',
                    onClick: logoutHandler,
                    description: '',
                    bgColor: 'warning',
                    icon: 'door_open'
                }
            ]
        ].filter(Boolean)

    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5 pt-8 " id='menu_page'
            >
                <div
                    className="content d-flex flex-column flex-fill p-0 m-4 "
                >
                    <div className="w-100 mw-450px mx-auto ">
                        {
                            _.map(menuConfig, (cardConfig) => (
                                <div className="card shadow-sm mb-4 mt-8" key={nanoid()}>
                                    <div className="card-body p-4">
                                        {
                                            _.map(cardConfig, (item) => (
                                                <MenuItem
                                                    {
                                                    ...item
                                                    }
                                                    key={nanoid()}
                                                    icon={<Icon name={item.icon as IconName} className={'fs-3x mx-auto text-white'} />}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                {/* <div className="m-0" style={{ height: '195px' }}></div> */}
            </div>
        </>
    )
}
