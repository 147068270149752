import React, {type ReactElement} from "react";
import IconButton from "../../../../../components/shared/UIv2/Button/IconButton";

interface CardInputPreviewVideoProps {
    row: Record<string, any>
    field: any
}

export default function CardInputPreviewVideo ({row, field}: CardInputPreviewVideoProps): ReactElement {
    return (
        <div className={'w-100 d-flex align-items-center justify-content-center overflow-hidden bg-dark position-relative'}
            style={{
                borderRadius: '12px',
                height: row.size === 'small' ? '130px' : '186px'
            }}
        >
            <video
                src={field.value?.[0]?.url}
                autoPlay={true}
                loop={true}
                muted={true}
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%'
                }}
            />
            <div className={'position-absolute'} style={{
                top: '9px',
                right: '9px'
            }}>
                <IconButton
                    icon={'close'}
                    style={'no-background'}
                    customBackground={'#8080804D'}
                    customColor={'#FFFFFF'}
                    onClick={() => {
                        field.onChange(
                            undefined
                        )
                    }}
                />
            </div>
        </div>
    )
}