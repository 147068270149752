import {type Unit} from "../../../../../store/slices/unitsSlice";
import {useParams} from "react-router";
import {useAppSelector} from "../../../../../hooks/redux";

type IUseViewUnit = () => {
    unit?: Unit
}

const useViewUnit: IUseViewUnit = () => {
    const {unitID} = useParams()
    const units = useAppSelector((state) => state.units.units)
    const unit = units.find((unit) => unit.id === Number(unitID))
    return {
        unit
    }
}

export default useViewUnit
