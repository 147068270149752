import React, {type ReactElement} from "react";

interface InformationBlockProps {
    row: Record<string, any>
}

export default function InformationBlock ({row}: InformationBlockProps): ReactElement {
    return (
        <div className={'h-100 d-flex flex-column align-items-center justify-content-center gap-8'}>
            <img src={row.src} alt={row.content[0]} style={{
                maxWidth: '150px',
                maxHeight: '150px'
            }}/>
            <div className={'fs-4x fw-medium text-center lh-1'}>
                {
                    row.content[0]
                }
            </div>
            <div className={'fs-5 text-gray-400 px-10 text-center'}>
                {
                    row.content[1]
                }
            </div>
        </div>
    )
}