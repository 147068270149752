import {type AppDispatch, type RootState} from "../../../../store";
import {scanFields} from "../../../authActions/scanDocumentForRegisterActions";
import {type IFields} from "../../../../../types/userTypes/documentTypes/TScan";
import {landlordAddUnitReadFromStorageV2} from "../utils/landlordAddUnitHandleStorageV2";
import {fetchUser} from "../../../userActions";

export const landlordCreateUnitUsersPassport  = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const response = await dispatch(scanFields(data as IFields))
    await dispatch(fetchUser(true))
    const storedData = landlordAddUnitReadFromStorageV2()
    if (response.isSuccessful) {
        if (storedData.landlord_create_unit_choose_role?.role === 'representative') {
            return {
                isSuccessful: true,
                textNavigate: '/landlord/modal/v2/landlord_create_unit_representative_auth_method/-/-',
                textNotification: ''
            }
        } else {
            return {
                isSuccessful: true,
                textNavigate: '/landlord/modal/v2/landlord_create_unit_upload_title_deed/-/-',
                textNotification: ''
            }
        }
    } else {
        response.textNavigate = ''
    }
    return response
}