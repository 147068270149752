import { type AppDispatch, type RootState } from '../../../../store'

export const getDocumentVerificationTask = (unitID: number) => (dispatch: AppDispatch, getState: () => RootState): any | null => {
    const unit = getState().units.units.find((unit) => unit.id === unitID)
    if (unit != null) {
        const task: any = unit.attributes?.Tasks?.data?.find((taskItem) => (
            ['To do', 'In-process'].includes(taskItem.attributes.Statuses[0]) &&
            taskItem.attributes.Type === 'Document Verification'
        ))
        if (task != null) {
            return getState().tasks.tasks.find(taskItem => taskItem.id === task.id)
        }
    }
    return null
}
