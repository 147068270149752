import React, { useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { Drawer } from 'antd'
import { mainUnitFiltersClosing, mainUnitFiltersOpening } from '../../../../store/slices/modalSlice'

import { nanoid } from 'nanoid'
import { Link } from 'react-router-dom'
import { setUnitsFilters } from '../../../../store/actionsCreators/unitsActions'
import _ from 'lodash'
import { type User } from '../../../../store/slices/userSlice'
import Icon from '../../../../app/icon/Icon'
import useUserType from '../../../../hooks/UseUserType'

export interface UnitFilterProps {
    label: string
    value: string
    variants: string[]
    slice?: 'units' | 'schedule'
    action?: any
}

/**
 *  key: 'More' ->
 *  key: {
 *      $gt: 3
 *  }
 *  key: 'value' ->
 *  key: {
 *      $eq: 'value'
 *  }
 *  key_key1: 'value' ->
 *  key: {
 *      key1: {
 *          $eq: 'value'
 *      }
 *  }
 *  >key: ['value1', 'value2'] ->
 *  key: {
 *      $in: ['value1', 'value2']
 *  }
 *  <key: ['value1', 'value2'] ->
 *  key: {
 *      $contains: ['value1', 'value2']
 *  }
 *  key: [n, k] ->
 *  key: {
 *      $between: [n, k]
 *  }
 *
 */

export default function MainUnitFilters (): JSX.Element {
    const { mainUnitFiltersOpen } = useAppSelector((state: RootState) => state.modal)
    const { filters } = useAppSelector((state: RootState) => state.units)
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const { userTypeLowercase } = useUserType()
    // const [selectedFilters, setSelectedFilters] = useState<any>({})

    const [optionsRender, setOptionsRender] = useState<any>([])

    const dispatch = useAppDispatch()

    const filtersConfig = [
        {
            label: 'Landlord\'s units',
            value: 'Landlord',
            filter: [],
            isBoolean: true
        },
        {
            label: 'Bed',
            value: 'Bedrooms',
            variants: [
                {
                    text: '1',
                    value: 1
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '3',
                    value: 3
                },
                {
                    text: 'More',
                    value: 'MORE-3'
                }
            ]
        },
        {
            label: 'Bath',
            value: 'Bathrooms',
            variants: [
                {
                    text: '1',
                    value: 1
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '3',
                    value: 3
                },
                {
                    text: 'More',
                    value: 'MORE-3'
                }
            ]
        },
        {
            label: 'Property type',
            value: 'Type',
            variants: [
                {
                    text: 'Residential',
                    value: 'Residential'
                },
                {
                    text: 'Commercial',
                    value: 'Commercial'
                }
            ]
        },
        {
            label: 'Under offer',
            value: '>Offers[0]_Statuses[0]',
            filter: ['Approved', 'Confirmed', 'Negotiated by BSO', 'Negotiated by agent', 'Negotiated by landlord', 'Waiting booking form sign'],
            isBoolean: true
        },
        {
            label: 'My areas',
            value: '>Property.Area',
            filter: (currentUser as User)?.Settings?.[0]?.Areas ?? [],
            isBoolean: true
        }
    ]
    if (_.includes((currentUser as User)?.Settings?.[0]?.Areas, 'All areas')) {
        filtersConfig.pop()
    }

    const onClose = (): void => {
        dispatch(mainUnitFiltersClosing())
    }

    // <i className="ki-duotone ki-black-down"></i>
    // <i className="fs-2x ki-duotone ki-black-up"></i>
    // console.log(Object.entries(selectedFilters), 'Object.keys(selectedFilters)sss')
    const calcSelectedFiltersLength = (): number => {
        let filtersLength = 0
        const entries = Object.entries(filters)
        //  console.log('entries', entries)
        for (let index = 0; index < entries.length; index++) {
            const element = entries[index]
            if ((element[1] ?? '').length !== 0) {
                filtersLength++
            }
            // console.log(element, 'element')
        }
        return filtersLength
    }
    const isEmpty = Object.keys(optionsRender).length == 0

    const showDrawer = (value: string): void => {
        dispatch(mainUnitFiltersOpening())
        const filter = filtersConfig.find(filterOption => filterOption.value === value)
        if (filter != null) {
            /* setSelectedFilters((prevState: any) => ({
                ...prevState,
                [filter.value]: prevState[filter.value] || ''
            })) */
            setOptionsRender(filter)
        }
    }

    const handleFilterSelect = (filterValue: string, optionValue: string | string[]): void => {
        /**
         * JSON stringify in case two arrays of strings have to be compared
         */
        if (filterValue in filters && JSON.stringify(filters[filterValue]) === JSON.stringify(optionValue)) {
            dispatch(setUnitsFilters(_.omit(filters, [filterValue])))
        } else {
            dispatch(setUnitsFilters({
                ...filters,
                [filterValue]: optionValue
            }))
        }
        dispatch(mainUnitFiltersClosing())
    }

    const selectedFiltersLength = calcSelectedFiltersLength()
    // console.log(selectedFilters, '____selectedFilters')
    return (
        <>
            <div className="d-flex flex-row px-4 py-2 pe-8">
                <div className="col-2 position-relative" >
                    <Link
                        className="text-decoration-none"
                        to={`/${userTypeLowercase}/modal/agent_unit_filters_modal/${'filterUnits'}/not`}
                    >
                        <span className={`btn btn-sm ${selectedFiltersLength > 0 ? 'btn-bso' : 'btn-outline btn-outline-default'} p-0 d-flex justify-content-center align-items-center h-100`}>
                            <Icon
                                name={'page_info'}
                                className={'text-muted fs-2x p-0'}
                            />
                        </span>
                        {/* Filters */}
                    </Link>
                    {selectedFiltersLength != null && selectedFiltersLength > 0
                        ? <span
                            className="fs-8 p-0 m-0 position-absolute badge badge-circle badge-dark text-white" style={{ top: '0px', right: '-7px' }}
                        >
                            {selectedFiltersLength}
                        </span>
                        : null}

                </div>
                <div className={`${selectedFiltersLength > 0 ? 'ms-4' : 'ms-2'} d-flex align-items-center gap-2 overflow-x-auto`}>
                    {/* <span className="h-80 bg-text-gray-400 w-10px"></span> */}
                    {filtersConfig.map((filter) => (
                        <div className={'position-relative'} key={nanoid()}>
                            <span
                                onClick={() => {
                                    if (filter.isBoolean === true) {
                                        handleFilterSelect(filter.value, filter.filter)
                                    } else {
                                        showDrawer(filter.value)
                                    }
                                }}
                                className={`btn btn-sm text-nowrap ${filters[filter.value] != null ? 'btn-bso' : 'btn-outline btn-outline-default position-relative'}`}
                            >
                                {filter.label}
                            </span>
                            { filters[filter.value] != null
                                ? <span className={'badge badge-circle badge-dark position-absolute'} style={{ top: '0', right: '-5px' }}
                                    onClick={() => {
                                        handleFilterSelect(filter.value, filters[filter.value])
                                    }}
                                >
                                    <i className={'bi bi-x fs-1 text-white'}/>
                                </span>
                                : <></>
                            }
                        </div>
                    ))}
                </div>
            </div>

            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{ body: { maxHeight: '50vh', marginBottom: '3px', paddingTop: '3px', marginTop: '6px', paddingLeft: '2px', paddingRight: '2px' } }}
                placement={'bottom'}
                closable={false}
                onClose={onClose}
                open={mainUnitFiltersOpen}
                key={'bottom'}
                zIndex={3000}
            >
                <div className="mx-4 py-2 mt-2 fs-3x fw-semi-bold  border-bottom border-2">
                    <h2>{optionsRender.label}</h2>
                </div>

                <div
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div className="w-100 mw-450px mx-auto">
                        <div className={'position-relative w-100 h-100  rounded rounded-2 my-1 opacity-75 '}
                        >

                            <div className="d-flex flex-column">

                                {!isEmpty && optionsRender.variants.length > 0 && optionsRender.variants.map((option: { text: string, value: any }) => {
                                    //              console.log(option, 'option')
                                    return (<>
                                        <span
                                            key={nanoid()}
                                            onClick={() => { handleFilterSelect(optionsRender.value, option.value) }}
                                            className={`ps-3 py-2 fs-2x fw-semi-self  ${filters[optionsRender.value] === option.value ? 'rounded rounded-1 bg-bso' : ''}`}
                                        >
                                            {option.text}
                                        </span></>

                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    )
}
