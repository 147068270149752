import React, {type ReactElement} from "react";
import {type ViewUnitSectionProps} from "../ViewUnitSection";
import ClosableBlock from "../../../../../UIv2/ClosableBlock/ClosableBlock";
import ViewUnitSectionUnitInformationRow from "./ViewUnitSectionUnitInformationRow";
import viewUnitInformationHelper from "./ViewUnitSectionUnitInformation.helper";
import {nanoid} from "nanoid";

export default function ViewUnitSectionUnitInformation({unit}: ViewUnitSectionProps): ReactElement {
    const unitInformationConfig = viewUnitInformationHelper(unit)
    return <ClosableBlock
        title={'Unit Information'}
        closable={false}
    >
        <div className={'d-flex flex-column gap-3'}>
            {
                unitInformationConfig?.map((row) => (
                    <ViewUnitSectionUnitInformationRow label={row.label} value={row.value ?? 'N/A'} key={nanoid()}/>
                ))
            }
        </div>
    </ClosableBlock>
}