import React from 'react'
import { locationSelectionImage } from '../../../urls'

interface IProps {
    countAreasExtend: undefined | string
    unitArea: null | string
}

export default function DescriptionAreasExtend ({
    countAreasExtend,
    unitArea
}: IProps) {
    return (
        <div className={'px-2 mt-2   mb-8'}>
            <h1>Choose your preferred locations</h1>
            <p className={'text-gray-400 fs-3'}>
                Market and explore listings strategically in these key areas.
            </p>
            <img
                className=" w-100 h-275px object-fit-cover mb-2 rounded-0 rounded-bottom rounded-top opacity-100"
                src={locationSelectionImage}
                alt={'loc_liable'}
            />
            {/* style={{ whiteSpace: 'pre-wrap' }} */}
            {unitArea
                ? (
                    <div className={'text-gray-400 fs-3 ps-4 mb-8'}>
                    • Select{' '}
                        <span className="fs-3 fw-bold text-dark">{unitArea}</span>{' '}
                    and hit the "Save Changes" button below
                    </div>
                )
                : (
                    <>
                        <div className={'text-gray-400 fs-3 ps-4 '}>
                        • Click the dropdown menu below to select your top{' '}
                            {Number(countAreasExtend) > 1
                                ? 'preferred districts. '
                                : 'preferred district. '}
                        </div>
                        <div className={'text-gray-400 fs-3 ps-4 '}>
                        • Remember, your current subscription allows you to
                        choose up to{' '}
                            {Number(countAreasExtend) > 1
                                ? `${String(countAreasExtend)} locations. `
                                : ' 1 location. '}{' '}
                        </div>
                        <div className={'text-gray-400 fs-3 ps-4 '}>
                        • Each selected location allows you to make
                        advertisement requests for units located in this area.
                        </div>
                        <div className={'text-gray-400 fs-3 ps-4 mb-8'}>
                        • Ready to make a move? Hit the "Save Changes" button
                        below{' '}
                        </div>

                        <div
                            className={' fs-3 text-gray-400 '}
                            style={{ fontStyle: 'italic' }}
                        >
                        Want to expand your reach? Check out our add-on section
                        to expand your preference beyond{' '}
                            {Number(countAreasExtend) > 1
                                ? `${String(
                                    countAreasExtend
                                )} districts on period up to 30 days. `
                                : ' 1 district on period up to 30 days. '}{' '}
                        </div>
                    </>
                )}
        </div>
    )
}
