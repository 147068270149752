import React from 'react'
import ChatUtils from '../../../../../functions/chats/ChatUtils'
import Avatar from '../../../elements/Avatar'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

interface TextMessageProps {
    sentBy: 'me' | 'other'
    text: string
    time: Date
    senderName?: string
    user?: {
        FirstName: string
        FamilyName: string
        Avatar: string
    }
    chatWith: string
}
const TextMessage = ({ sentBy, text, time, senderName, user, chatWith }: TextMessageProps): JSX.Element => {
    const location = useLocation()
    return (
        <>
            <div className={`w-100 d-flex justify-content-${sentBy === 'me' ? 'end' : 'start'}`}>
                <div className={'card pt-2 px-4 pb-2 shadow-sm'} style={sentBy === 'me' ? { width: '70vw', background: '#fff200' } : { width: '70vw' }}>
                    { _.includes(location.pathname, 'chats')
                        ? sentBy === 'other'
                            ? <div className={'text-gray-600 mb-1'}>{senderName}</div>
                            : <div className={'mb-2'}></div>
                        : <div className={'text-gray-600 mb-1'}>{chatWith}</div>
                    }
                    <div className={'mb-2'}
                        style={{ whiteSpace: 'pre-wrap' }}
                    >
                        { text }
                    </div>
                    <div className={`d-flex align-items-center ${user == null || _.includes(location.pathname, 'chats') ? 'justify-content-end' : 'justify-content-between'} fs-6 text-gray-600`}>
                        {
                            user != null && !_.includes(location.pathname, 'chats')
                                ? <div className={'d-flex align-items-center gap-2'}>
                                    <Avatar
                                        image={user?.Avatar}
                                        name={user?.FirstName ?? ''}
                                        size={'18px'}
                                    />
                                    <div>{user?.FirstName} {user?.FamilyName}</div>
                                </div>
                                : <></>
                        }
                        <span>{ ChatUtils.formatTime(time)}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TextMessage
