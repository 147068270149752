import React, {type ReactElement, useEffect} from "react";
import NumericKeypad from "../../../../../components/shared/UIv2/NumericKeypad/NumericKeypad";
import {Controller} from "react-hook-form";
import {nanoid} from "nanoid";
import _ from "lodash";
import {regExps} from "../../../../../constants";

interface CodePickerProps {
    row: Record<string, any>
    control: any
    setError: any
}

export default function CodePicker ({row, control, setError}: CodePickerProps): ReactElement {

    useEffect(() => {
        setError(row.id, 'Password must be entered')
    }, [])

    return <Controller
        rules={{
            required: row.required,
            pattern: new RegExp(regExps.isAuthCode)
        }}
        name={row.id}
        control={control}
        render={({field}) => {
            const code: string[] = field.value?.split('') ?? []
            return (
                <div className={'d-flex flex-column gap-20 pb-20 px-8'}>
                    <div className={'d-flex gap-3 fs-4x justify-content-center'}>
                        {
                            [0, 1, 2, 3, 4, 5].map((index) => (
                                <div key={nanoid()}
                                    className={'border border-bottom border-start-0 border-end-0 border-top-0 border-dark w-100 text-center'}
                                    style={{
                                        minHeight: '50px'
                                    }}
                                >
                                    {
                                        code[index]
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div>
                        <NumericKeypad
                            handleKeyPress={(key: number) => {
                                if (code.length < 6) {
                                    field.onChange(
                                        [
                                            ...code,
                                            key
                                        ].join('')
                                    )
                                }
                            }}
                            handleBackspace={() => {
                                const codeCopy = _.cloneDeep(code)
                                codeCopy.pop()
                                field.onChange(
                                    codeCopy.join('')
                                )
                            }}
                        />
                    </div>
                </div>
            )
        }}
    />
}