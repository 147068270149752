import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/redux'
import { store, type RootState } from '../../../store/store'
import Swal from 'sweetalert2'
import { viewingsQuery } from '../../../store/actionsCreators/viewingsActions'
import { animationOptions, swalOptions, swalSettingsViewingsWindow, swalViewingsWindow } from '../../../functions/swalOptions'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { createNotifyViewing } from '../../../store/actionsCreators/viewingActions/createNotifyViewing'
import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { type User } from '../../../store/slices/userSlice'
import { type IViewing } from '../../../store/slices/viewingsSlice'
import { setFirstLengthSolidStackViewingsReschedule, setIndexOpenPopupViewingReschedule, setNewViewingRescheduleSocket, setOneCicleShowPopupsViewingReschedule, setShowPopupViewingReschedule, setStackViewingsReschedule, setViewingChanged } from '../../../store/slices/swalsSlice'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import { isPastDate } from '../../../functions/agent/date/isPastDate'
import { updateStatusViewing } from '../../../store/actionsCreators/viewingActions/updateStatusViewing'

interface IProps {
    dataLoaded: boolean
    dispatch: any
    viewings: IViewing[]
    currentUser: User | Record<string, unknown>
}
export default function ViewingRescheduled({ dataLoaded, dispatch, currentUser }: IProps): JSX.Element {
    const navigate = useNavigate()
    const location = useLocation()

    const isViewingModal = location.pathname.split('/')[3] === 'viewing_modal'
    const viewingIdLocation = location.pathname.split('/')[5]

    const viewings = useAppSelector((state: RootState) => state.viewings.viewings)
    const userID = currentUser.id

    const newViewingRescheduleSocket = useAppSelector((state: RootState) => state.swalsStates.newViewingRescheduleSocket)
    const oneCicleShowPopupsViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.oneCicleShowPopupsViewingReschedule)
    const viewingChanged = useAppSelector((state: RootState) => state.swalsStates.viewingChanged)

    const agentViewings = viewings.filter((el: IViewing) => el?.attributes?.User?.data?.id === userID)

    const indexOpenPopupViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.indexOpenPopupViewingReschedule)

    const firstLengthSolidStackViewingsReschedule = useAppSelector((state: RootState) => state.swalsStates.firstLengthSolidStackViewingsReschedule)
    let indicesWithRescheduledStatus: [] | number[] = []
    useEffect(() => {
        indicesWithRescheduledStatus = agentViewings
            .map((view, index) => ({ view, index }))
            .filter(item =>
                item.view?.attributes?.Statuses?.includes('Rescheduled by BSO') && item.view.attributes.NotifyViewedDate == null)
            .sort((a, b) => {
                const dateA = new Date(a.view?.attributes?.updatedAt || 0)
                const dateB = new Date(b.view?.attributes?.updatedAt || 0)
                return dateB.getTime() - dateA.getTime()
            })
            .map(item => item.index)

        dispatch(setFirstLengthSolidStackViewingsReschedule(indicesWithRescheduledStatus.length))
        dispatch(setStackViewingsReschedule(indicesWithRescheduledStatus))

        if (indicesWithRescheduledStatus.length > 0) {
            if (indexOpenPopupViewingReschedule === -1) {
                if (!oneCicleShowPopupsViewingReschedule) {
                    showRescheduledPopup(indexOpenPopupViewingReschedule + 1)
                }
            } else {
                showRescheduledPopup(0)
            }
        }
    }, [])

    useEffect(() => {
        if (!oneCicleShowPopupsViewingReschedule) {
            if (indexOpenPopupViewingReschedule !== -1) {
                showRescheduledPopup(indexOpenPopupViewingReschedule)
            }
        }
    }, [indexOpenPopupViewingReschedule, viewingChanged]) // , dataLoaded

    useEffect(() => {
        if (newViewingRescheduleSocket) {
            const agentViewings = store.getState().viewings.viewings.filter((el: IViewing) => el?.attributes?.User?.data?.id === userID)
            if (agentViewings.length > 0) {
                indicesWithRescheduledStatus = agentViewings
                    .map((view, index) => ({ view, index }))
                    .filter(item =>
                        item.view?.attributes?.Statuses?.includes('Rescheduled by BSO') && item.view.attributes.NotifyViewedDate == null)
                    .sort((a, b) => {
                        const dateA = new Date(a.view?.attributes?.updatedAt || 0)
                        const dateB = new Date(b.view?.attributes?.updatedAt || 0)
                        return dateB.getTime() - dateA.getTime()
                    })
                    .map(item => item.index)
                dispatch(setFirstLengthSolidStackViewingsReschedule(indicesWithRescheduledStatus.length))
                dispatch(setStackViewingsReschedule(indicesWithRescheduledStatus))
            }
            showRescheduledPopup(0)
        }
        dispatch(setNewViewingRescheduleSocket(false))
    }, [newViewingRescheduleSocket, viewings])

    const showRescheduledPopup =
        (index: number) => {
            dispatch(setShowPopupViewingReschedule(true)) // false
            if (newViewingRescheduleSocket) {
                dispatch(setNewViewingRescheduleSocket(false))
            }
            if (index >= firstLengthSolidStackViewingsReschedule || index < 0) {
                dispatch(setOneCicleShowPopupsViewingReschedule(true))
                dispatch(setShowPopupViewingReschedule(false))
                dispatch(setIndexOpenPopupViewingReschedule(-1))
                return
            }

            const view = agentViewings[indicesWithRescheduledStatus[index]]
            if (isViewingModal && view.id === Number(viewingIdLocation)) {
                dispatch(setShowPopupViewingReschedule(false))
                if (indexOpenPopupViewingReschedule === -1) {
                    dispatch(setIndexOpenPopupViewingReschedule(indexOpenPopupViewingReschedule + 2))
                } else {
                    dispatch(setIndexOpenPopupViewingReschedule(indexOpenPopupViewingReschedule + 1))
                }
                return
            }

            const unit = store.getState().units.units.filter((el) => el.id === view?.attributes?.Unit?.data?.id)?.[0]
            void swalViewingsWindow(view, unit).then(
                async (result: any) => {
                    if (result.isConfirmed) {
                        const request = await dispatch(
                            updateStatusViewing({
                                viewID: view.id,
                                status: 'Approved',
                                unitID: view?.attributes?.Unit?.data?.id,
                                nameUser: getNameUser(currentUser as User),
                                unitAdress: getUnitAddress(unit),
                                view
                            })
                        )

                        if (request.success && request.response.data) {
                            const units: any = store.getState().units.units
                            const unit = units?.filter((el: any) => el.id === Number(view?.attributes?.Unit?.data?.id))
                            await createNotifyViewing(request.response.data, unit)
                            const iconText = 'Success'
                            const options = {
                                ...swalOptions('success'),
                                ...{
                                    title: 'Approved!',
                                    html: 'You have successfully confirmed viewing',
                                    showConfirmButton: true,
                                    iconHtml: '<div  id="lottie-icon-success></div>',
                                    confirmButtonText: 'Got it',
                                    didOpen: async () => {
                                        const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                                        const pathLottie = await import(`../../../../src/animations/${iconText}.json`)
                                        if (lottieContainer != null) {
                                            lottie.loadAnimation({
                                                container: lottieContainer,
                                                renderer: 'svg',
                                                ...{ ...animationOptions, animationData: pathLottie.default }
                                            })
                                        }
                                    }

                                }
                            }
                            const result = await Swal.fire(options).then(async (value: any) => {
                                if (value.isConfirmed) {
                                    dispatch(setViewingChanged(view.id))
                                    dispatch(setShowPopupViewingReschedule(false))
                                }
                            })
                        } else {
                            await swalSettingsViewingsWindow
                                .fire('Error', 'An unexpected error occurred', 'error')
                                .then(async (value: any) => {
                                    if (value.isConfirmed) {
                                        dispatch(setViewingChanged(view.id))
                                        dispatch(setShowPopupViewingReschedule(false))
                                    }
                                })
                        }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        const request = await dispatch(
                            updateStatusViewing({
                                viewID: view.id,
                                status: 'Canceled',
                                unitID: view?.attributes?.Unit?.data?.id,
                                nameUser: getNameUser(currentUser as User),
                                unitAdress: getUnitAddress(unit),
                                view
                            })
                        )

                        if (request.success && request.response.data) {
                            const iconText = 'Error'
                            const options = {
                                ...swalOptions('success'),
                                ...{
                                    title: 'Cancelled!',
                                    html: 'Viewing was successfully canceled',
                                    showConfirmButton: true,
                                    iconHtml: '<div  id="lottie-icon-error></div>',
                                    confirmButtonText: 'Got it',
                                    didOpen: async () => {
                                        const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                                        const pathLottie = await import(`../../../../src/animations/${iconText}.json`)
                                        if (lottieContainer != null) {
                                            lottie.loadAnimation({
                                                container: lottieContainer,
                                                renderer: 'svg',
                                                ...{ ...animationOptions, animationData: pathLottie.default }
                                            })
                                        }
                                    }

                                }
                            }
                            const result = await Swal.fire(options).then(async (value: any) => {
                                if (value.isConfirmed) {
                                    dispatch(setViewingChanged(view.id))
                                    dispatch(setShowPopupViewingReschedule(false))
                                }
                            })
                        } else {
                            await swalSettingsViewingsWindow
                                .fire('Error', 'An unexpected error occurred', 'error')
                                .then(async (value: any) => {
                                    if (value.isConfirmed) {
                                        dispatch(setViewingChanged(view.id))
                                        dispatch(setShowPopupViewingReschedule(false))
                                    }
                                })
                        }
                    } else if (result.isDenied) {
                        dispatch(setShowPopupViewingReschedule(false))
                        navigate(
                            `/agent/modal/agent_update_viewing_modal/${view?.attributes?.Unit?.data?.id}/${view.id}`)
                        dispatch(setViewingChanged(view.id))
                    } else {
                        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

                        if (view?.attributes?.NotifyViewedDate == null && (view?.attributes?.Datetime == null || isPastDate(view?.attributes?.Datetime))) {
                            const response = await fetch(urlAPI,
                                {
                                    method: 'POST',
                                    headers: {
                                        'Content-type': 'application/json',
                                        Authorization: `Bearer ${jwt}`
                                    },
                                    body: JSON.stringify({
                                        method: 'PUT',
                                        collection: 'viewings',
                                        query: viewingsQuery, // 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[KeyBooking]=*',
                                        id: Number(view.id),
                                        body: {
                                            NotifyViewedDate: getCurrentDubaiDate() // + '04:00'
                                        }
                                    })
                                })
                            const dataJSON = await response.json()
                        }

                        dispatch(setShowPopupViewingReschedule(false))

                        if (indexOpenPopupViewingReschedule === -1) {
                            dispatch(setIndexOpenPopupViewingReschedule(indexOpenPopupViewingReschedule + 2))
                        } else {
                            dispatch(setIndexOpenPopupViewingReschedule(indexOpenPopupViewingReschedule + 1))
                        }
                    }
                }
            )
        }

    return <></>
}
