import React, {type ReactElement, useRef} from "react";
import Icon from "../../../../app/icon/Icon";
import dayjs from "dayjs";
import {DatePicker} from "antd";
import _ from "lodash";

interface DateInputProps {
    placeholder: string
    value?: Date
    onChange: (value: Date) => void
}

export default function DateInput ({placeholder, value, onChange}: DateInputProps): ReactElement {
    const selectedDate = value != null ? dayjs(value).format('MMM D, YYYY') : undefined
    const inputRef = useRef<HTMLInputElement>(null)
    return (
        <>
            <div className={'d-flex border border-gray-300 w-100 ps-5 pe-4 position-relative bg-white'}
                style={{
                    borderRadius: '8px',
                    height: '42px'
                }}
                onClick={() => {
                    inputRef?.current?.showPicker()
                    inputRef?.current?.focus()
                }}
            >
                <div className={`flex-grow-1 d-flex align-items-center fw-medium fs-5 ${selectedDate != null ? 'text-dark' : 'text-gray-400'}`}>
                    {selectedDate ?? placeholder}
                </div>
                <div className={'d-flex align-items-center border border-gray-300 border-top-0 border-right-0 border-bottom-0 ps-4'}>
                    <Icon name={'calendar_month'} className={'fs-2x'} imageSize={40}/>
                </div>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    opacity: 0
                }}>
                    <DatePicker
                        value={
                            value != null ? dayjs(value) as any : undefined
                            // _.isString(value)
                            //     ? new Date(value)
                            //     : value
                        }
                        onChange={onChange}
                        format="MMM D, YYYY"
                        popupClassName="full-screen"
                        size="large"
                        className="d-block"
                        style={{ width: '100%', left: 'auto' }}
                    />
                    {/* <input */}
                    {/*    style={{ */}
                    {/*        flexGrow: 1, */}
                    {/*        // visibility: 'hidden' */}
                    {/*    }} */}
                    {/*    ref={inputRef} */}
                    {/*    type="date" */}
                    {/*    onInput={(e) => { */}
                    {/*        onChange(dayjs(e.currentTarget.value).toDate()) */}
                    {/*    }} */}
                    {/*    value={dayjs(value).format('YYYY-MM-DD')} */}
                    {/* /> */}
                </div>
            </div>
        </>
    )
}