import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SchedulerStatus } from '../../../functions/agent/date/SchedulerStatus'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { timeStringInScheduler } from '../../../functions/agent/date/timeStringInScheduler'
import { generateBgAndBorderCol, generateBgAndBorderColFromOfferMessage } from '../../../functions/agent/schedule/generateBgAndBorderCol'
import { nanoid } from 'nanoid'
import { type IViewing } from '../../../store/slices/viewingsSlice'
import {
    type IOfferType,
    type IOffer
} from '../../../store/slices/offersSlice'
import { type RootState } from '../../../store/store'
import formatPrice from '../../../functions/shared/format/formatPrice'
import UseUserType, { type UserType } from '../../../hooks/UseUserType'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../urls'
import Icon from '../../../app/icon/Icon'
import dayjs from 'dayjs'
interface IProps {
    viewOrOffer: any // IViewing | IOffer
    isWhat: string
    isMyTasks?: boolean
    isMessageOffer?: boolean
}
export default function CalendarListItems ({
    viewOrOffer,
    isWhat,
    isMyTasks,
    isMessageOffer
}: IProps): JSX.Element {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const { userType, userTypeLowercase } = UseUserType()

    let notifyApp: UserType | 'BSO' | 'Both' | 'Other Agent' = userType
    if (userID === REACT_APP_BSO_USER_ID) {
        notifyApp = 'BSO'
    }

    if (isWhat === 'service') {
        viewOrOffer = {
            id: viewOrOffer.id,
            attributes: viewOrOffer
        }
    }

    let currentViewing: any
    let currentOffer: any
    let isThereNotKey = false
    if (isWhat === 'viewings') {
        currentViewing = useAppSelector(
            (state: RootState) => state.viewings.viewings
        )?.filter((view) => view.id === Number(viewOrOffer.id))[0]

        if (currentViewing.attributes.KeyBooking.data) {
            const keySts = currentViewing.attributes.KeyBooking.data.attributes.Status
            if (keySts.includes('In-process') || keySts.includes('Waiting for agent')) { isThereNotKey = true }
        }
    }
    if (isWhat === 'offers') {
        currentOffer = useAppSelector(
            (state: RootState) => state.offers.offers
        )?.filter((offer) => offer.id === Number(viewOrOffer.id))[0]
    }
    let currentUnit: any
    if (isMessageOffer !== undefined && isMessageOffer && isWhat === 'offers') {
        currentUnit = useAppSelector(
            (state: RootState) => state.units.units
        )?.filter(
            (unit) => unit.id === viewOrOffer?.UnitID
        )[0]
    } else {
        currentUnit = useAppSelector(
            (state: RootState) => state.units.units
        )?.filter(
            (unit) => [Number(viewOrOffer?.attributes?.Unit?.data?.id), Number(viewOrOffer?.Unit?.id), Number(viewOrOffer?.attributes?.Unit?.id)].includes(unit.id)
        )[0]
    }

    const currentAgent = useAppSelector(
        (state) => state.user.allUsers
    )?.filter(
        (user) => user.id === Number(viewOrOffer?.attributes?.User?.data?.id)
    )[0]

    const [statusNotify, setStatusNotify] = useState(
        viewOrOffer.attributes?.Notify
    )

    const [dateDubai, timeDubai] = timeStringInScheduler(
        currentViewing?.attributes?.Datetime ?? ''
    ).split(',')

    let unitNumber, buildName, location

    if (isWhat === 'viewings') {
        if (currentViewing) {
            if (viewOrOffer?.attributes?.Name) {
                [unitNumber, buildName, location] = viewOrOffer?.attributes?.Name?.split(' <-> ')
            } else if (viewOrOffer?.attributes?.Name) {
                [unitNumber, buildName, location] = viewOrOffer?.attributes?.Name?.split(' <-> ')
            } else {
                [unitNumber, buildName, location] = [
                    'Not number',
                    'Not title',
                    'Not location'
                ]
            }
        }
    } else {
        [unitNumber, buildName, location] = [
            currentUnit?.attributes?.Number ?? 'Not number',
            currentUnit?.attributes?.Property?.data?.attributes?.Name ?? 'Not title',
            currentUnit?.attributes?.Property?.data?.attributes?.Area ?? 'Not location'
        ]
    }
    // console.log(currentUnit, 'currentUnit')
    const dispatch = useAppDispatch()
    useEffect(() => {
        setStatusNotify(viewOrOffer?.attributes?.Notify)
    }, [viewOrOffer, dispatch])

    function renderOfferComment (status: string): string | null {
        if (userType === 'Agent') {
            switch (status) {
                // case 'Completed':
                //     return 'Completed status on offer' // 'Wait updates from your client'
                case 'Approved':
                    return 'Wait for landlord response'
                case 'Slip approved':
                    return 'Please, wait for your response'
                default:
                    return null
            }
        } else {
            return null
        }
    }
    function renderViewingStatus (statuses: string[]): string {
        if (statuses.includes('Door close')) {
            return 'Agency Unit'
        } else {
            if (statuses.includes('Door open')) {
                return 'Door open'
            } else if (statuses.includes('Digital lock')) {
                return 'Digital lock'
            } else if (statuses.includes('Occupied')) {
                return 'Occupied'
            } else if (statuses.includes('Keys in BSO office')) {
                return 'Keys in BSO office'
            } else if (statuses.includes('Building Management')) {
                return 'Building Management'
            } else {
                return ''
            }
        }
    }

    //  console.log(viewOrOffer, '<<<<<<<<<< ================    =============== ============ ======== ========= =========viewOrOffer')
    //  console.log(currentUnit?.attributes.Marketing?.Price, 'currentUnit')
    return (

        <>
            {isWhat === 'viewings' ? (
                // <Link to={`/agent/modalView/viewing_modal/${viewOrOffer?.attributes?.Unit?.data?.id}/${viewOrOffer?.id}/calendar`} className="text-decoration-none" key={nanoid()}>
                <div
                    key={`${nanoid()}-${viewOrOffer.id}`}
                    className={`card shadow-sm my-2 d-flex flex-row row g-0 p-1 me-1  align-items-start justify-content-between position-relative border border-1 border-${generateBgAndBorderCol(viewOrOffer).borderColor
                    } bg-light-${generateBgAndBorderCol(viewOrOffer).borderColor}`}
                >
                    {

                        statusNotify === notifyApp || statusNotify === 'Both' || (isMyTasks && viewOrOffer.attributes.Notify === 'Other Agent')
                            ? (
                                <span className="position-absolute top-1 start-100 translate-middle badge badge-circle badge-danger">
                                    !
                                </span>
                            )
                            : null}

                    <div className="d-flex flex-column col-8 p-2 ">
                        <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                            <Icon
                                name={'door_open'}
                                className={''}
                                styles={{ minWidth: '12.9px' }}
                            // iconToFontRatio={1.5}
                            // useWrapper
                            />
                            <span className={'text-dots-app'}>{unitNumber}, {buildName}</span>
                        </span>
                        <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                            <Icon
                                name={'location_on'}
                                className={''}
                                styles={{ minWidth: '12.9px' }}
                            />
                            <span className={'text-dots-app'}>{location}{' '}</span>
                        </span>

                        {

                            isMyTasks || userType === 'Landlord'
                                ? <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                    <Icon
                                        name={'person'}
                                        className={''}
                                        styles={{ minWidth: '12.9px' }}
                                    />
                                    <span className={'text-dots-app'}>
                                        {

                                            currentAgent?.ContactInfo?.FirstName != null
                                                ? `${String(currentAgent?.ContactInfo?.FirstName)} ${String(currentAgent?.ContactInfo?.FamilyName)}`
                                                : 'No name'
                                        }
                                    </span>
                                </span>
                                : <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                    <Icon
                                        name={'info'}
                                        className={''}
                                        styles={{ minWidth: '12.9px' }}
                                    />
                                    <span className={'text-dots-app '}>
                                        {/* {currentUnit?.attributes?.Statuses.includes('Door close')
                                            ? <u className={'text-danger'}> {renderViewingStatus(currentUnit?.attributes?.Statuses)}</u>
                                            : renderViewingStatus(currentUnit?.attributes?.Statuses)
                                        } */}
                                        {renderViewingStatus(currentUnit?.attributes?.Statuses)}
                                    </span>
                                </span>
                        }
                    </div>
                    <div className="d-flex flex-column col-4 p-1 mt-1 text-end">
                        <div className="fs-6 d-flex align-items-center justify-content-end">

                            {viewOrOffer.attributes?.Statuses?.includes('Rescheduled by BSO') || viewOrOffer.attributes?.Statuses?.includes('Rescheduled by agent') || viewOrOffer.attributes?.Statuses?.includes('Rescheduled by other agent')
                                ? <span
                                    className={`me-1 text-end badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderCol(viewOrOffer).borderColor} bg-light-${generateBgAndBorderCol(viewOrOffer).borderColor}`}
                                >
                                    {' '}
                                </span>
                                : null}

                            <div className="d-flex flex-column">
                                {viewOrOffer?.attributes?.Statuses?.map((el: any) => (
                                    <div key={nanoid()} className={`fs-1x text-dark lh-1 ${generateBgAndBorderCol(viewOrOffer).thin}`}>
                                        {el !== 'Rescheduled by BSO' && el !== 'Rescheduled by agent' && el !== 'Rescheduled by other agent'
                                            ? <span
                                                className={`me-1 text-end badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderCol(viewOrOffer).borderColor} bg-light-${generateBgAndBorderCol(viewOrOffer).borderColor}`}
                                            >
                                                {' '}
                                            </span>
                                            : null}
                                        {timeDubai == null && el === 'Rescheduled by BSO'
                                            ? 'Reschedule required'
                                            : el === 'Waiting for complain' ? isThereNotKey ? 'Waiting for keys return' : 'Completed' : el === 'Rescheduled by other agent' ? isMyTasks ? 'Rescheduled by youself' : 'Rescheduled by agent' : el

                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="fs-1x pe-2 text-dark pt-1">
                            {timeDubai
                                ? <div className={'d-flex align-items-center gap-1 justify-content-end'}>
                                    <Icon
                                        name={'schedule'}
                                    />
                                    <div>{timeDubai}</div>
                                </div>
                                : <>
                                    <div className="fs-8 lh-1 fw-bold border-light-danger text-bold text-danger my-1">Waiting time expired</div>
                                </>
                            }

                        </div>
                        {/* magic_viewing_modal */}
                        <Link
                            //      to={(isAgentTasks && viewOrOffer.attributes.Notify === 'Other Agent') ? `/${userTypeLowercase}/modal/viewing_actions_from_agent/${currentUnit.id}/${currentViewing.id}` : `/${userTypeLowercase}/modalView/${notifyApp === 'BSO' ? 'magic_' : ''}viewing_modal/${currentViewing?.attributes?.Unit?.data?.id}/${currentViewing?.id}/${isAgentTasks ? 'agent_s_unit' : 'calendar'} `}
                            to={`/${userTypeLowercase}/modalView/${notifyApp === 'BSO' ? 'magic_' : ''}viewing_modal/${currentViewing?.attributes?.Unit?.data?.id}/${currentViewing?.id}/${isMyTasks ? 'my_unit' : currentUnit?.attributes?.Statuses.includes('Door close') ? 'other_agent_s_unit' : 'calendar'} `}

                            className="text-decoration-none"
                        >
                            <div
                                className={`fs-7 pt-1 badge border border-${generateBgAndBorderCol(currentViewing as IViewing).borderColor
                                }`}
                            >
                                GET INFO
                                <Icon
                                    name={'expand_more'}
                                    className={'text-dark fs-2 ms-1'}
                                    useWrapper
                                />
                            </div>
                        </Link>
                    </div>
                </div>
            ) // </Link>
                : isWhat === 'offers' ? (

                    <div
                        key={`${nanoid()}-${viewOrOffer.id}`}
                        className={`card shadow-sm my-2 p-1 me-1 position-relative border border-1 border-${isMessageOffer ? generateBgAndBorderColFromOfferMessage(viewOrOffer).borderColor : generateBgAndBorderCol(currentOffer as IOffer).borderColor
                        } bg-light-${isMessageOffer ? generateBgAndBorderColFromOfferMessage(viewOrOffer).borderColor : generateBgAndBorderCol(viewOrOffer).borderColor}`}
                    >
                        <div className={'d-flex flex-row row g-0 align-items-start justify-content-between'}>
                            {statusNotify === notifyApp || statusNotify === 'Both'
                                ? (
                                    <span className="position-absolute top-1 start-100 translate-middle badge badge-circle badge-danger">
                                        !
                                    </span>
                                )
                                : null}

                            {isMessageOffer
                                ? (
                                    <span
                                        className="position-absolute end-0 badge badge-danger w-auto mx-auto px-auto text-end"
                                        style={{ top: -9 }}
                                    >
                                        {timeStringInScheduler(viewOrOffer?.LastMessage?.updatedAt)}
                                    </span>
                                )
                                : null}

                            <div className="d-flex flex-column col-8 p-2 ">
                                <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                    <Icon
                                        name={'door_open'}
                                        styles={{ minWidth: '12.9px' }}
                                    />
                                    <span className={'text-dots-app'}>
                                        {unitNumber}, {buildName}
                                    </span>
                                </span>
                                <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                    <Icon
                                        name={'location_on'}
                                        styles={{ minWidth: '12.9px' }}
                                    />
                                    <span className={'text-dots-app'}>
                                        {location}{' '}
                                    </span>
                                </span>
                                {userType === 'Agent'
                                    ? (currentOffer?.attributes?.Type[0]?.__component === 'offer.commercial' || currentOffer?.attributes?.Type[0]?.__component === 'offer.sale-commercial'
                                        ? <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                            <Icon
                                                name={'person'}
                                                styles={{ minWidth: '12.9px' }}
                                            />  <span className={'text-dots-app'}>
                                                {currentOffer?.attributes?.Type?.[0]?.BusinessName ?? 'No name'}
                                            </span>

                                        </span>
                                        : <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                            <Icon
                                                name={'person'}
                                                styles={{ minWidth: '12.9px' }}
                                            />

                                            {isMessageOffer
                                                ? <span className={'text-dots-app'}>
                                                    {viewOrOffer?.Name ?? 'No name'}
                                                </span>
                                                : <span className={'text-dots-app'}>
                                                    {currentOffer?.attributes?.Type?.[0]?.FirstName ?? 'No'}{' '}
                                                    {currentOffer?.attributes?.Type?.[0]?.LastName ?? 'name'}
                                                </span>
                                            }

                                        </span>
                                    )
                                    : <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                        <Icon
                                            name={'person'}
                                            styles={{ minWidth: '12.9px' }}
                                        />

                                        <span className={'text-dots-app'}>
                                            {`${String(currentAgent?.ContactInfo?.FirstName ?? 'Account')} ${String(currentAgent?.ContactInfo?.FamilyName ?? 'deleted')}`}
                                        </span>
                                    </span>
                                }
                                {
                                    renderOfferComment(viewOrOffer?.attributes?.Statuses[0]) != null
                                        ? <span className="fs-6 text-gray-800 fw-bold d-flex align-items-center gap-2">
                                            <Icon
                                                name={'info'}
                                            />
                                            <span className={'text-dots-app1'}>
                                                {renderOfferComment(viewOrOffer?.attributes?.Statuses[0])}
                                            </span>
                                        </span>
                                        : <></>
                                }
                            </div>

                            <div className="d-flex flex-column col-4 p-1 text-end">
                                <div className="fs-6 d-flex align-items-center justify-content-end">

                                    <div className="d-flex flex-column">
                                        {isMessageOffer

                                            ? <div className="fs-1x text-dark lh-1 fw-bold mt-2" key={nanoid()}>
                                                <span
                                                    className={`me-1 text-end badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${isMessageOffer ? generateBgAndBorderColFromOfferMessage(viewOrOffer).borderColor : generateBgAndBorderCol(viewOrOffer).borderColor} bg-light-${isMessageOffer ? generateBgAndBorderColFromOfferMessage(viewOrOffer).borderColor : generateBgAndBorderCol(viewOrOffer).borderColor}`}
                                                >
                                                    {' '}
                                                </span>
                                                {
                                                    viewOrOffer.Status
                                                }
                                            </div>

                                            : viewOrOffer?.attributes?.Statuses?.map((el: any) => {
                                                return (
                                                    <div className="fs-1x text-dark lh-1 fw-bold" key={nanoid()}>
                                                        <span
                                                            className={`me-1 text-end badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${isMessageOffer ? generateBgAndBorderColFromOfferMessage(viewOrOffer).borderColor : generateBgAndBorderCol(viewOrOffer).borderColor
                                                            } bg-light-${isMessageOffer ? generateBgAndBorderColFromOfferMessage(viewOrOffer).borderColor : generateBgAndBorderCol(viewOrOffer).borderColor}`}
                                                        >
                                                            {' '}
                                                        </span>
                                                        {
                                                            el === 'Waiting for complain'
                                                                ? 'Completed'
                                                                // : el === 'Approved'
                                                                //     ? 'Sent to landlord'
                                                                : el
                                                        }
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                                <div className="text-dark pe-2 pt-1 d-flex align-items-center justify-content-end gap-1">

                                    {/* currentUnit. */}
                                    <div>
                                        <Icon
                                            name={'monetization_on'}
                                        />
                                        {formatPrice(isMessageOffer ? currentUnit?.attributes.Marketing?.Price : currentOffer?.attributes?.Type?.[0]?.Offer)}
                                        <span className="fs-8 text-gray-600">AED</span>
                                        {isMessageOffer && <div className="fs-8 text-gray-700">Unit price</div>}
                                    </div>
                                </div>

                                {/* <div className={'me-1'}> */}
                                <Link
                                    to={isMessageOffer
                                        ? `/${userTypeLowercase}/chats/${viewOrOffer.id}`
                                        : `/${userTypeLowercase}/modalView/${notifyApp === 'BSO' ? 'magic_' : ''}offer_modal/${String(currentOffer?.attributes?.Unit?.data?.id) ?? 'not'
                                        }/${String(currentOffer?.id) ?? 'not'}/catalog?type=${String(
                                            currentOffer?.attributes?.Type?.[0]?.__component ?? 'not'
                                        ).slice(6)}`}
                                    className="text-decoration-none"
                                >
                                    <div
                                        className={`fs-7 pt-1 badge border border-${isMessageOffer ? generateBgAndBorderColFromOfferMessage(viewOrOffer).borderColor : generateBgAndBorderCol(viewOrOffer).borderColor
                                        }`}
                                    >
                                        {isMessageOffer ? 'OPEN CHAT' : 'GET INFO'}
                                        <Icon
                                            name={'expand_more'}
                                            className={'text-dark fs-2 ms-1'}
                                            useWrapper
                                        />
                                    </div>
                                </Link>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                ) // </Link>
                    : null}
            {
                isWhat === 'tasks'
                    ? <div
                        key={`${nanoid()}-${viewOrOffer.id}`}
                        className={`card shadow-sm my-2 p-1 me-1 position-relative border border-1 border-${generateBgAndBorderCol(currentOffer as IOffer).borderColor
                        } bg-light-${generateBgAndBorderCol(viewOrOffer).borderColor}`}
                    >
                        <div className={'d-flex flex-row row g-0 align-items-start justify-content-between'}>
                            {/* {statusNotify === notifyApp || statusNotify === 'Both' */}
                            {/*    ? ( */}
                            {/*        <span className="position-absolute top-1 start-100 translate-middle badge badge-circle badge-danger"> */}
                            {/*            ! */}
                            {/*        </span> */}
                            {/*    ) */}
                            {/*    : null} */}
                            <div className="d-flex flex-column col-8 p-2 ">
                                <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                    <Icon
                                        name={'label'}
                                        styles={{ minWidth: '12.9px' }}
                                    />
                                    <span className={'text-dots-app'}>
                                        {viewOrOffer?.attributes?.Type}
                                    </span>
                                </span>
                                <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                    <Icon
                                        name={'schedule'}
                                        styles={{ minWidth: '12.9px' }}
                                    />
                                    <span className={'text-dots-app'}>
                                        Start date: {viewOrOffer?.attributes?.StartDate ?? 'not set'}
                                    </span>
                                </span>
                                {
                                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                        <Icon
                                            name={'schedule'}
                                            styles={{ minWidth: '12.9px' }}
                                        />
                                        <span className={'text-dots-app'}>
                                            Due date: {viewOrOffer?.attributes?.DueDate ?? 'not set'}
                                        </span>
                                    </span>
                                }
                                {/* <span className="fs-6 text-gray-800 d-flex align-items-center gap-2"> */}
                                {/*    <Icon */}
                                {/*        name={'location_on'} */}
                                {/*        styles={{ minWidth: '12.9px' }} */}
                                {/*    /> */}
                                {/*    <span className={'text-dots-app'}> */}
                                {/*        {location}{' '} */}
                                {/*    </span> */}
                                {/* </span> */}
                                {/* {userType === 'Agent' */}
                                {/*    ? (currentOffer?.attributes?.Type[0]?.__component === 'offer.commercial' || currentOffer?.attributes?.Type[0]?.__component === 'offer.sale-commercial' */}
                                {/*        ? <span className="fs-6 text-gray-800 d-flex align-items-center gap-2"> */}
                                {/*            <Icon */}
                                {/*                name={'person'} */}
                                {/*                styles={{ minWidth: '12.9px' }} */}
                                {/*            /> */}
                                {/*            <span className={'text-dots-app'}> */}
                                {/*                {currentOffer?.attributes?.Type?.[0]?.BusinessName ?? 'No name'} */}
                                {/*            </span> */}
                                {/*        </span> */}
                                {/*        : <span className="fs-6 text-gray-800 d-flex align-items-center gap-2"> */}
                                {/*            <Icon */}
                                {/*                name={'person'} */}
                                {/*                styles={{ minWidth: '12.9px' }} */}
                                {/*            /> */}
                                {/*            <span className={'text-dots-app'}> */}
                                {/*                {currentOffer?.attributes?.Type?.[0]?.FirstName ?? 'No'}{' '} */}
                                {/*                {currentOffer?.attributes?.Type?.[0]?.LastName ?? 'name'} */}
                                {/*            </span> */}
                                {/*        </span> */}
                                {/*    ) */}
                                {/*    : <span className="fs-6 text-gray-800 d-flex align-items-center gap-2"> */}
                                {/*        <Icon */}
                                {/*            name={'person'} */}
                                {/*            styles={{ minWidth: '12.9px' }} */}
                                {/*        /> */}
                                {/*        <span className={'text-dots-app'}> */}
                                {/*            {`${String(currentAgent?.ContactInfo?.FirstName ?? 'Account')} ${String(currentAgent?.ContactInfo?.FamilyName ?? 'deleted')}`} */}
                                {/*        </span> */}
                                {/*    </span> */}
                                {/* } */}
                                {
                                    renderOfferComment(viewOrOffer?.attributes?.Statuses[0]) != null
                                        ? <span className="fs-6 text-gray-800 fw-bold d-flex align-items-center gap-2">
                                            <Icon
                                                name={'info'}
                                            />
                                            <span className={'text-dots-app1'}>
                                                {renderOfferComment(viewOrOffer?.attributes?.Statuses[0])}
                                            </span>
                                        </span>
                                        : <></>
                                }
                            </div>
                            <div className="d-flex flex-column col-4 p-1 text-end gap-2">
                                <div className="fs-6 d-flex align-items-center justify-content-end">

                                    <div className="d-flex flex-column">
                                        {viewOrOffer?.attributes?.Statuses?.map((el: any) => {
                                            return (
                                                <div className="fs-1x text-dark lh-1 fw-bold" key={nanoid()}>
                                                    <span
                                                        className={`me-1 text-end badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderCol(viewOrOffer).borderColor
                                                        } bg-light-${generateBgAndBorderCol(viewOrOffer).borderColor}`}
                                                    >
                                                        {' '}
                                                    </span>
                                                    {
                                                        el
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {/* <div className="text-dark pe-2 pt-1 d-flex align-items-center justify-content-end gap-1"> */}
                                {/*    <Icon */}
                                {/*        name={'monetization_on'} */}
                                {/*    /> */}
                                {/*    <div> */}
                                {/*        {formatPrice(currentOffer?.attributes?.Type?.[0]?.Offer)} */}
                                {/*        <span className="fs-8 text-gray-600">AED</span> */}
                                {/*    </div> */}
                                {/* </div> */}

                                {/* <div className={'me-1'}> */}
                                <Link
                                    to={`/${userTypeLowercase}/tasks_actions/${String(viewOrOffer.id)}`}
                                    className="text-decoration-none"
                                >
                                    <div
                                        className={`fs-7 pt-1 badge border border-${generateBgAndBorderCol(viewOrOffer).borderColor
                                        }`}
                                    >
                                        DO THE TASK
                                        <Icon
                                            name={'expand_more'}
                                            className={'text-dark fs-2 ms-1'}
                                            useWrapper
                                        />
                                    </div>
                                </Link>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                isWhat === 'service'
                    ? <div
                        key={`${nanoid()}-${viewOrOffer.id}`}
                        className={`card shadow-sm my-2 p-1 me-1 position-relative border border-1 border-${generateBgAndBorderCol(currentOffer as IOffer).borderColor
                        } bg-light-${generateBgAndBorderCol(viewOrOffer).borderColor}`}
                    >
                        <div className={'d-flex flex-row row g-0 align-items-start justify-content-between'}>
                            {statusNotify === notifyApp || statusNotify === 'Both'
                                ? (
                                    <span className="position-absolute top-1 start-100 translate-middle badge badge-circle badge-danger">
                                        !
                                    </span>
                                )
                                : null}
                            <div className="d-flex flex-column col-8 p-2 ">
                                <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                    <Icon
                                        name={'build_circle'}
                                        styles={{ minWidth: '12.9px' }}
                                    />
                                    <span className={'text-dots-app fw-bold'}>
                                        {
                                            viewOrOffer?.attributes?.Service?.[0]?.__component
                                                ?.replace('services.', '')
                                                ?.replace('-', ' ')
                                                ?.split('')
                                                ?.map((symbol: string, index: number) => index === 0 ? symbol.toUpperCase() : symbol)
                                                ?.join('')
                                        }
                                    </span>
                                </span>
                                <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                    <Icon
                                        name={'door_open'}
                                        styles={{ minWidth: '12.9px' }}
                                    />
                                    <span className={'text-dots-app'}>
                                        {unitNumber}, {buildName}
                                    </span>
                                </span>
                                <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                    <Icon
                                        name={'event_available'}
                                        styles={{ minWidth: '12.9px' }}
                                    />
                                    <span className={'text-dots-app'}>
                                        {viewOrOffer?.attributes?.InvoiceDate} To {viewOrOffer?.attributes?.InvoiceDueDate}
                                    </span>
                                </span>
                            </div>
                            <div className="d-flex flex-column col-4 p-1 text-end">
                                <div className="fs-6 d-flex align-items-center justify-content-end">

                                    <div className="d-flex flex-column">
                                        {(viewOrOffer?.attributes?.Statuses ?? []).map((el: any) => {
                                            return (
                                                <div className="fs-1x text-dark lh-1 fw-bold" key={nanoid()}>
                                                    <span
                                                        className={`me-1 text-end badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderCol(viewOrOffer).borderColor
                                                        } bg-light-${generateBgAndBorderCol(viewOrOffer).borderColor}`}
                                                    >
                                                        {' '}
                                                    </span>
                                                    {
                                                        el === 'Waiting for complain'
                                                            ? 'Completed'
                                                            : el === 'Approved'
                                                                ? 'Sent to landlord'
                                                                : el
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="text-dark pe-2 pt-1 d-flex align-items-center justify-content-end gap-1">
                                    <Icon
                                        name={'monetization_on'}
                                    />
                                    <div>
                                        {formatPrice(viewOrOffer?.attributes?.Amount)} <span className="fs-8 text-gray-600">AED</span>
                                    </div>
                                </div>

                                {/* <div className={'me-1'}> */}
                                <Link
                                    to={'#'}
                                    className="text-decoration-none"
                                >
                                    <div
                                        className={`fs-7 pt-1 badge border border-${generateBgAndBorderCol(viewOrOffer).borderColor}`}
                                    >
                                        GET INFO
                                        <Icon
                                            name={'expand_more'}
                                            className={'text-dark fs-2 ms-1'}
                                            useWrapper
                                        />
                                    </div>
                                </Link>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    : <></>
            }
        </>
    )
}
