import React, {type ReactElement, useState} from "react";
import Checkbox from "../../../../../components/shared/UIv2/Checkbox/Checkbox";
import {Controller} from "react-hook-form";
import PrivacyPolicyDrawer from "./PrivacyPolicyDrawer";
import TermsAndConditionsDrawer from "./TermsAndConditionsDrawer";

interface TermsAndConditionsProps {
    row: any
    control: any
}

export default function TermsAndConditions ({row, control}: TermsAndConditionsProps): ReactElement {
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false)
    const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false)
    return <>
        <Controller
            name={row.id}
            control={control}
            rules={{
                validate: (value) => {
                    return value === true
                }
            }}
            render={({field}) => {
                const value = field.value === true
                return (
                    <div className={'pt-2 pb-8 h-100 d-flex flex-column justify-content-end'}>
                        <div className={'d-flex gap-4'}
                            onClick={(e) => {
                                if ((e.target as HTMLElement).closest('.click-ignore') == null) {
                                    field.onChange(!value)
                                }
                            }}
                        >
                            <div className={'pt-1'}>
                                <Checkbox checked={value}/>
                            </div>
                            <div className={'fs-5 fw-medium text-gray-400'}>
                            I accept the <span className={'text-dark click-ignore'} onClick={() => {setTermsAndConditionsOpen(true)}}>Terms & Conditions</span> and <span className={'text-dark click-ignore'} onClick={() => {setPrivacyPolicyOpen(true)}}>Privacy Policy</span>. BSO may send you marketing emails. You may change your preferences in the settings.
                            </div>
                        </div>
                    </div>
                )
            }}
        />
        <PrivacyPolicyDrawer isOpen={privacyPolicyOpen} setIsOpen={setPrivacyPolicyOpen}/>
        <TermsAndConditionsDrawer isOpen={termsAndConditionsOpen} setIsOpen={setTermsAndConditionsOpen}/>
    </>
}