import {type AppDispatch, type RootState} from "../../../../../store";
import {ACCESS_KEY, urlAPI} from "../../../../../../urls";
import {dataFetchingFinish, dataFetchingStart} from "../../../../../slices/modalSlice";
import {
    serviceFindingTenantClearStorage,
    serviceFindingTenantReadFromStorage
} from "../utils/findingTenantHandleStorage";
import {ServicesConfig} from "../../../../../../functions/landlord/data/serivces/servicesConfig";
import {fetchUser} from "../../../../userActions";
import {fetchUnits} from "../../../../unitsActions";

const addServiceFindingTenant = (unitId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    dispatch(dataFetchingStart())
    const storedData = serviceFindingTenantReadFromStorage()
    const units = getState().units.units
    const unit = units.find(unitItem => unitItem.id === unitId)
    try {
        const unitData = {
            Marketing: {
                ...(unit?.attributes?.Marketing ?? {}),
                Use: storedData?.service_finding_tenant_marketing_info?.Marketing?.Use,
                Headline: storedData?.service_finding_tenant_marketing_info?.Marketing?.Headline,
                Price: storedData?.service_finding_tenant_marketing_info?.Marketing?.Price
            },
            Fit: storedData?.service_finding_tenant_unit_info?.Fit,
            Appliances: storedData?.service_finding_tenant_unit_info?.Appliances,
            Features: storedData?.service_finding_tenant_unit_info?.Features,
            Amenities: storedData?.service_finding_tenant_unit_info?.Amenities,
            View: storedData?.service_finding_tenant_unit_info?.View,
            Images: storedData?.service_finding_tenant_photos?.Images,
            Video: storedData?.service_finding_tenant_photos?.Video,
            FloorPlan: storedData?.service_finding_tenant_photos?.FloorPlan
        }

        const unitBodyObject = {
            method: 'PUT',
            collection: 'units',
            id: unitId,
            body: unitData
        }

        const unitResponse = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(unitBodyObject),
            headers: {
                'Content-Type': 'application/json',
                'BSO-Hash': '02ns30nsBSO!',
                Authorization: `Bearer ${jwt}`
            }
        })

        const unitResponseJSON = await unitResponse?.json() as unknown as any
        unitResponseJSON.isSuccessful = unitResponseJSON?.response?.error == null

        const services = storedData.service_finding_tenant_checkout?.services
        const servicesPrices = services.map((service: string) => (
            ServicesConfig.getService(service)?.price ?? 0
        ))
        const price = servicesPrices.reduce((acc: number, value: number) => acc + value, 0)
        const totalPrice = price * 1.05
        const data: Record<string, any> = {
            InvoiceDate: storedData.service_finding_tenant?.dates?.From,
            InvoiceDueDate: storedData.service_finding_tenant?.dates?.To,
            Name: services.join('-'),
            Amount: totalPrice,
            User: getState().user.currentUser.id,
            Unit: unitId,
            Statuses: ['In-process'],
            Service: [
                {
                    __component: 'services.finding-tenant'
                }
            ]
        }
        const bodyObject = {
            method: 'POST',
            collection: 'orders',
            body: data
        }

        if (unitResponseJSON.isSuccessful === true) {
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(bodyObject),
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const responseJSON = await response?.json() as unknown as any
            responseJSON.isSuccessful = responseJSON?.response?.error == null
            if (responseJSON.isSuccessful === true) {
                await Promise.all([
                    dispatch(fetchUser(true)),
                    dispatch(fetchUnits(true))
                ])
                dispatch(dataFetchingFinish())
                serviceFindingTenantClearStorage()
                return {
                    isSuccessful: true,
                    textNotification: '',
                    textNavigate: `/landlord/modal/v2/service_finding_tenant_end/${unitId}/-`
                }
            }
        }
    } catch (err) {
        console.error('error in addServiceFindingTenant', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export default addServiceFindingTenant
