import React from 'react'
import moment from 'moment'
import Timer from '../../shared/elements/Timer'
import { type IMarketing, type Unit } from '../../../store/slices/unitsSlice'
import getUnitOfferStatus, { getUnitOfferUnderOfferType } from '../../../functions/shared/units/getUnitOfferStatus'
import {
    checkIfUnitIsInValidationProcess
} from '../../../store/actionsCreators/unitActions/landlordUnitsActions/utils/checkIfUnitIsISInValidationProcess'
import { useAppDispatch } from '../../../hooks/redux'

interface IProps {
    hide: boolean
    unit: Unit
}

export default function UnitOfferSaleSh ({ hide, unit }: IProps): JSX.Element {
    const Marketing = unit?.attributes?.Marketing as IMarketing ?? {}
    const dispatch = useAppDispatch()
    const { DiscountEndDatetime, DiscountStartDatetime, DiscountHeadline, Status } = Marketing
    const startDate = moment(DiscountStartDatetime)
    const endDate = moment(DiscountEndDatetime)
    const discountActive = moment().isAfter(moment(startDate)) && moment().isBefore(moment(endDate)) && Status === 'Enabled'

    const unitOfferStatus = getUnitOfferStatus(unit)

    const unitIsInValidationProcess = dispatch(checkIfUnitIsInValidationProcess(unit.id)) === true

    const unitIsNew = (unit.attributes as any).CreateDate != null && moment().isBefore(moment((unit.attributes as any).CreateDate).add(1, 'day'))

    return (
        discountActive || unitOfferStatus !== 'Free' || unitIsInValidationProcess || unitIsNew// || unit?.attributes?.ImportantNotice != null
            ? <div
                data-id="units_list_template_discount"
                className={`position-absolute top-0 end-0 p-2 pt-2 pe-2 m-0 w-100 ${
                    hide ? '' : 'd-none'
                }`}
                style={{ zIndex: 50 }}
            >
                <div className="d-flex flex-row w-100">
                    <div className="d-flex flex-column pe-1 w-100">
                        {
                            unitIsNew
                                ? <div className="d-flex justify-content-end">
                                    <div
                                        className="btn btn-sm btn-bso btn-active-bso p-0 px-2 m-0 mb-1 text-truncate"
                                    >
                                        NEW UNIT!
                                    </div>
                                </div>
                                : <></>
                        }
                        { discountActive
                            ? <><div className="d-flex justify-content-end">
                                <div
                                    data-id="units_list_template_discount_headline"
                                    className="btn btn-sm btn-bso btn-active-bso p-0 px-2 m-0 mb-1 text-truncate mw-50"
                                >
                                    {DiscountHeadline}
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <div
                                    data-id="units_list_template_discount_time"
                                    className="btn btn-sm btn-bso btn-active-bso p-0 px-2 m-0"
                                >
                                    <Timer
                                        mode={'count-back'}
                                        endTime={endDate}
                                    />
                                </div>
                            </div></>
                            : <></>
                        }
                        { unitOfferStatus === 'Under offer'
                            ? <div className="position-absolute d-flex justify-content-center" style={{ top: '90px', width: 'calc(100% - 1rem)' }}>
                                <div
                                    data-id="units_list_template_discount_headline"
                                    className="btn btn-bso btn-active-bso fs-3 p-0 px-2 m-0 mb-1 text-truncate mw-50 shadow-lg"
                                >
                                    {
                                        getUnitOfferUnderOfferType(unit)
                                    }
                                </div>
                            </div>
                            : <></>
                        }
                        {
                            unitOfferStatus === 'Sold'
                                ? <div className="position-absolute d-flex justify-content-center" style={{ top: '90px', width: 'calc(100% - 1rem)' }}>
                                    <div
                                        data-id="units_list_template_discount_headline"
                                        className="btn btn-bso btn-active-bso fs-3 p-0 px-2 m-0 mb-1 text-truncate mw-50 shadow-lg"
                                    >
                                        Sold
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            unitOfferStatus === 'Rented'
                                ? <div className="position-absolute d-flex justify-content-center" style={{ top: '90px', width: 'calc(100% - 1rem)' }}>
                                    <div
                                        data-id="units_list_template_discount_headline"
                                        className="btn btn-bso btn-active-bso fs-3 p-0 px-2 m-0 mb-1 text-truncate mw-50 shadow-lg"
                                    >
                                        Off-market
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            unitIsInValidationProcess
                                ? <div className="position-absolute d-flex justify-content-center" style={{ top: '90px', width: 'calc(100% - 1rem)' }}>
                                    <div
                                        data-id="units_list_template_discount_headline"
                                        className="btn btn-bso btn-active-bso fs-3 p-0 px-2 m-0 mb-1 text-truncate mw-60 shadow-lg"
                                    >
                                        Documents validation <br></br>
                                        is in process
                                    </div>
                                </div>
                                : <></>
                        }
                        {/*
                            unit?.attributes?.Statuses?.includes('Occupied') && unit?.attributes?.ImportantNotice != null
                                ? <div className="position-absolute d-flex justify-content-center" style={{ top: '90px', width: 'calc(100% - 1rem)' }}>
                                    <div
                                        data-id="units_list_template_discount_headline"
                                        className="btn btn-bso btn-active-bso fs-3 p-0 px-2 m-0 mb-1 text-truncate mw-100 shadow-lg"
                                    >
                                        { unit?.attributes?.ImportantNotice }
                                    </div>
                                </div>
                                : <></>
                        */}
                    </div>
                    {/* <a className="btn btn-bso btn-active-bso p-2 m-0">
                    <i className="bi bi-tag-fill p-0 m-0 fs-5x"></i>
                </a> */}
                </div>
            </div>
            : <></>
    )
}
