import { type AppDispatch, type RootState } from '../../../../store'

export const checkIfUnitIsInValidationProcess = (unitID: number) => (dispatch: AppDispatch, getState: () => RootState): boolean => {
    const unit = getState().units.units.find((unit) => unit.id === unitID)
    if (unit != null) {
        return unit?.attributes?.Tasks?.data?.some((task) => (
            ['To do', 'In-process'].includes(task.attributes.Statuses[0]) &&
            task.attributes.Type === 'Document Verification'
        ))
    }
    return false
}

export const checkIfUnitHasPassedValidationProcess = (unitID: number) => (dispatch: AppDispatch, getState: () => RootState): boolean => {
    const unit = getState().units.units.find((unit) => unit.id === unitID)
    if (unit != null) {
        return unit?.attributes?.Tasks?.data?.some((task) => (
            ['Archived', 'Done'].includes(task.attributes.Statuses[0]) &&
            task.attributes.Type === 'Document Verification'
        ))
    }
    return false
}
