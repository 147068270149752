import { type AppDispatch, type RootState, store } from '../../store'
import { modalSlice } from '../../slices/modalSlice'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import { viewingsSlice } from '../../slices/viewingsSlice'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { viewingsQuery } from '../viewingsActions'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import dayjs from 'dayjs'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'

export const updateViewingByMagic = (id: number, data: Record<string, unknown>, successAlert: string, notifyRule: string, notifyMessage: string, textNavigate: string | number = -1, sendNoNotify: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    // const userID = window.localStorage.getItem(USER_ID_KEY)
    const viewing = getState().viewings?.viewings?.find((viewing) => viewing.id === id)
    const userID = viewing?.attributes?.User?.data?.id
    const currentDubaiDate = getCurrentDubaiDate()
    const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 0)
    try {
        // const dateViewing = new Date(`${bodyClient.time_viewing}+04:00`)
        dispatch(modalSlice.actions.dataFetchingStart())
        const bodyObject = {
            method: 'PUT',
            collection: 'viewings',
            query: viewingsQuery,
            //  query: 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[KeyBooking]=*',
            id,
            body: { ...data, Notify: 'Agent' }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${String(jwt)}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()

        // console.log(dataJSON, 'dataJSON<<<<<<<<<updateViewing<<<<<<<<!!!!!!__________')

        if (dataJSON.success === true) {
            if (dataJSON.response.error != null) {
                dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(viewingsSlice.actions.setViewings(
                    dataJSON.response.data
                ))
                if (!sendNoNotify) {
                    await sendNotification(inMomentViewingForAdmin, Number(userID), id, notifyRule, notifyMessage)
                }
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate, isSuccessful: true, textNotification: successAlert ?? 'Great, unit time view has been changed' }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (err) {

    }
}

export const createKeyBookingByMagic = (unitID: number, viewingID: number, data: Record<string, unknown>, textNavigate: string | number = -1, status: string = 'Approved') => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    try {
        console.log(data)
        dispatch(modalSlice.actions.dataFetchingStart())
        const allViewings = getState().viewings.viewings
        const viewing = allViewings?.find((item) => item.id === viewingID)
        const adminUser = viewing?.attributes?.User?.data?.id
        const bodyObject = {
            method: 'POST',
            collection: 'unit-keys',
            query: 'populate[Holder][fields][0]=id&populate[Unit][fields][0]=id&populate[Viewings]=id',
            body: {
                PickUpDate: new Date(data.PickUpDate as string).toISOString(),
                DropOutDate: new Date(data.DropOutDate as string).toISOString(),
                Holder: adminUser,
                Status: 'Waiting for agent',
                Unit: unitID,
                Viewings: viewingID,
                Notify: 'Agent'
            }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${String(jwt)}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()

        if (dataJSON.success === true) {
            if (dataJSON.response.error == null) {
                await sendNotification(dayjs(data.PickUpDate as string).subtract(30, 'minutes').toDate(), Number(viewing?.attributes?.User?.data?.id), Number(viewing?.id), 'user-viewing-key-pickup', 'Pick up keys in 30 minutes')
                await sendNotification(dayjs(data.DropOutDate as string).subtract(30, 'minutes').toDate(), Number(viewing?.attributes?.User?.data?.id), Number(viewing?.id), 'user-viewing-key-dropout', 'Drop out keys in 30 minutes')
                return await dispatch(updateViewingByMagic(viewingID, { Statuses: [status], Datetime: status === 'Rescheduled by BSO' ? getState().modal.dateChooseViewing : undefined, RescheduleReason: status === 'Rescheduled by BSO' ? store.getState().modal.rescheduleReason : undefined }, 'New keys have been created', status === 'Rescheduled by BSO' ? 'user-viewing-newtime' : 'user-viewing-approved', 'ATTENTION keys pick-up and drop-out time is added to viewing', textNavigate))
            }
        }
        dispatch(modalSlice.actions.dataFetchingFinish())
    } catch (err) {
        console.log(err)
        dispatch(modalSlice.actions.dataFetchingFinish())
    }
}
