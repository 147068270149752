/**
 * @module SetUnitActions
 *
 * @description Complex actions that are used to operate the SetUnitSlice state.
 * Used to fill the edit modal,
 * To perform complicated field logic, scanning e.t.c.
 */
import { type AppDispatch, type RootState, store } from '../store'
import _ from 'lodash'
import {
    addOwner, type ModalRow, type Owner, type OwnerValues, type OwnerValuesKey,
    removeOwner,
    rerenderModal, setDynamicRowShow, setFooter,
    setImagesLoaded,
    setTitleDeedLoaded,
    setValue,
    detectChange,
    setRepresentative, addUploadedDocument, validateUnitFields
} from '../slices/setUnitSlice'

import { dataFetchingFinish, dataFetchingStart, setDocuments, setDynamicDropDown, setImages } from '../slices/modalSlice'
import { uploadMultipleFiles } from './fileActions'
import { addProperty } from './propertyActions'
import { nanoid } from 'nanoid'
import searchFuse from '../../functions/search/searchFuse'
import { documentStatuses } from '../../app/custom/inputs/CustomEmiratesIdInput'
import dayjs from 'dayjs'
import getUserType from '../../functions/shared/user/getUserType'
import { regExps } from '../../constants'
import filterUserCallback from '../../functions/shared/users/filterUserCallback'
import mapPropertiesForSelect from '../../functions/shared/properties/mapPropertiesForSelect'
import { addPropertyDeleteTask } from '../slices/propertySlice'
import { removeDataAttributes } from '../../functions/adapters/universal/removeDataAttributes'
import { scanDocuments } from './documentsActions/scaning/scanDocuments'
import { createDocument } from './documentsActions/createDocument'

/**
 * @function renderSetUnitModal
 * @param {string} modalID
 * @param {string | undefined} taskID
 *
 * @description This function is used to get the modal object that is filled with all values and callbacks based on SetUnitSlice. Runs on every render of SetUnitModal component
 */
export const renderSetUnitModal = (modalID: string, taskID?: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setFooter(modalID))
    const modal = _.cloneDeep(getState().setUnit.modal)
    const propertyId = getState().setUnit.staticForm.find((formItem) => formItem.id === 'Property')?.value
    let property: any = null
    if (propertyId != null) {
        property = getState().property.properties?.find((property) => property.id === propertyId)
    }
    _.forEach(getState().setUnit.staticForm, (formItem) => {
        if (formItem.userType != null && getUserType() !== formItem.userType) {
            if (modalID !== 'validate_unit' || formItem.id !== 'add_poa_button') {
                return
            }
        }
        switch (formItem.row.element) {
            case 'document-scan-input':
                // eslint-disable-next-line no-case-declarations
                modal.form.push({
                    ...formItem.row,
                    onScan: (documentType: 'Title Deed') => async (documents: Record<string, File>): Promise<{ isSuccessful: boolean }> => {
                        dispatch(dataFetchingStart())
                        try {
                            const response = await dispatch(scanDocuments(Object.values(documents), 'Title Deed'))
                            const fileUrl: string | null = response?.[0]?.url
                            if (fileUrl != null) {
                                dispatch(addUploadedDocument({ field: String(formItem.id), base64: fileUrl }))
                                dispatch(applyTitleDeed(response))
                                dispatch(setTitleDeedLoaded(true))
                                dispatch(rerenderModal())
                                dispatch(setValue({ value: documentStatuses.true, id: formItem.id ?? '' }))
                                dispatch(detectChange(true))
                                dispatch(dataFetchingFinish())
                                return {
                                    isSuccessful: true
                                }
                            }
                            dispatch(dataFetchingFinish())
                        } catch (err) {
                            dispatch(dataFetchingFinish())
                            console.log('Error while title deed scanning', err)
                        }
                        return {
                            isSuccessful: false
                        }
                    },
                    value: formItem.value
                })
                break
            case 'input':
            case 'input-group':
                modal.form.push({
                    ...formItem.row,
                    onInput: (e: any) => {
                        dispatch(setValue({ value: e.target.value, id: formItem.id ?? '' }))
                        dispatch(detectChange(true))
                    }
                })
                break
            case 'select':
                switch (formItem.id) {
                    // case 'Property':
                    //     modal.form.push({
                    //         ...formItem.row,
                    //         selectors: getState().property.properties?.map(property => ({
                    //             label: property.attributes.Name,
                    //             value: property.id
                    //         })),
                    //         onChange: (e: any) => {
                    //             dispatch(setValue({ value: e, id: formItem.id ?? '' }))
                    //             dispatch(detectChange(true))
                    //         }
                    //     })
                    //     break
                    case 'Features':
                    case 'Amenities':
                    case 'Appliances':
                        modal.form.push({
                            ...formItem.row,
                            selectors: getState().app.config?.units?.[formItem.id] ?? [],
                            onChange: (e: any) => {
                                dispatch(setValue({
                                    value: [
                                        ...(getState().setUnit.staticForm.find((storedFormItem) => storedFormItem.id === formItem.id)?.value ?? []),
                                        e
                                    ],
                                    id: formItem.id ?? ''
                                }))
                                dispatch(detectChange(true))
                            },
                            onDeselect: (e: any) => {
                                dispatch(setValue({
                                    value: (getState().setUnit.staticForm.find((storedFormItem) => storedFormItem.id === formItem.id)?.value ?? []).filter((value: any) => value !== e),
                                    id: formItem.id ?? ''
                                }))
                                dispatch(detectChange(true))
                            }
                        })
                        break
                    case 'DisabledWeekdays':
                        modal.form.push({
                            ...formItem.row,
                            onChange: (e: any) => {
                                dispatch(setValue({
                                    value: [
                                        ...(getState().setUnit.staticForm.find((storedFormItem) => storedFormItem.id === formItem.id)?.value ?? []),
                                        e
                                    ],
                                    id: formItem.id ?? ''
                                }))
                                dispatch(detectChange(true))
                            },
                            onDeselect: (e: any) => {
                                dispatch(setValue({
                                    value: (getState().setUnit.staticForm.find((storedFormItem) => storedFormItem.id === formItem.id)?.value ?? []).filter((value: any) => value !== e),
                                    id: formItem.id ?? ''
                                }))
                                dispatch(detectChange(true))
                            }
                        })
                        break
                    case 'Fit':
                    case 'View':
                        modal.form.push({
                            ...formItem.row,
                            selectors: getState().app.config?.units?.[formItem.id] ?? [],
                            onChange: (e: any) => {
                                dispatch(setValue({
                                    value: e,
                                    id: formItem.id ?? ''
                                }))
                                dispatch(detectChange(true))
                            }
                        })
                        break
                    case 'Statuses':
                        modal.form.push({
                            ...formItem.row,
                            onChange: (e: any) => {
                                /** Add field Occupied until to form in case status is set to occupied */
                                if (e?.includes('Occupied') === true) {
                                    dispatch(setDynamicRowShow({ id: 'OccupiedUntil', state: true }))
                                    dispatch(setValue({ value: e, id: formItem.id ?? '' }))
                                    dispatch(detectChange(true))
                                    dispatch(rerenderModal())
                                } else {
                                    dispatch(setDynamicRowShow({ id: 'OccupiedUntil', state: false }))
                                    dispatch(setValue({ value: e, id: formItem.id ?? '' }))
                                    dispatch(detectChange(true))
                                    dispatch(rerenderModal())
                                }
                            }
                        })
                        break
                    case 'ImagesOnly':
                        modal.form.push({
                            ...formItem.row,
                            onChange: (e: any) => {
                                dispatch(setDynamicRowShow({ id: 'SpecialBrokers', state: e === 'Yes' }))
                                dispatch(setValue({ value: null, id: 'SpecialBrokers' }))
                                dispatch(setValue({ value: e, id: formItem.id ?? '' }))
                                dispatch(detectChange(true))
                                dispatch(rerenderModal())
                            }
                        })
                        break
                    case 'property_type':
                        modal.form.push({
                            ...formItem.row,
                            selectors: getState().app.config?.documents?.Type?.TitleDeed?.Type ?? [],
                            onChange: (e: any) => {
                                dispatch(setValue({
                                    value: e,
                                    id: formItem.id ?? ''
                                }))
                                dispatch(detectChange(true))
                            }
                        })
                        break
                    default:
                        modal.form.push({
                            ...formItem.row,
                            onChange: (e: any) => {
                                dispatch(setValue({ value: e, id: formItem.id ?? '' }))
                                dispatch(detectChange(true))
                            }
                        })
                        break
                }
                break
            case 'select-add':
                modal.form.push({
                    ...formItem.row,
                    onChange: (e: any) => {
                        const previousPropertyId = getState().setUnit.staticForm.find((row) => row.id === 'Property')?.value
                        console.log(previousPropertyId, 'previousPropertyId')
                        if (previousPropertyId != null) {
                            dispatch(addPropertyDeleteTask({
                                id: previousPropertyId
                            }))
                        }
                        dispatch(setValue({ value: e, id: formItem.id ?? '' }))
                        dispatch(detectChange(true))
                        dispatch(rerenderModal())
                    },
                    modal: 'set_property_modal',
                    editModal: property != null ? 'edit_property_modal' : undefined
                })
                break
            case 'button':
                switch (formItem.id) {
                    case 'add_owner_button':
                        modal.form.push({
                            ...formItem.row,
                            onClick: () => {
                                dispatch(detectChange(true))
                                dispatch(addOwnerToForm())
                            }
                        })
                        break
                    case 'add_poa_button':
                        // eslint-disable-next-line no-case-declarations
                        if (getUserType() === 'Admin') {
                            const poaSectionOpen = getState().setUnit.dynamicForm.find((formItem) => formItem.id === 'representative.ContactInfo.Name')?.show === true
                            modal.form.push({
                                ...formItem.row,
                                content: [poaSectionOpen ? 'Remove POA' : 'Add POA'],
                                onClick: () => {
                                    dispatch(detectChange(true))
                                    dispatch(setDynamicRowShow({
                                        id: 'representative.ContactInfo.Name',
                                        state: !poaSectionOpen
                                    }))
                                    dispatch(setDynamicRowShow({
                                        id: 'representative.ContactInfo.Phone',
                                        state: !poaSectionOpen
                                    }))
                                    dispatch(setDynamicRowShow({
                                        id: 'representative.ContactInfo.Email',
                                        state: !poaSectionOpen
                                    }))
                                    dispatch(setDynamicRowShow({
                                        id: 'representative.emirates_id',
                                        state: !poaSectionOpen
                                    }))
                                    dispatch(setDynamicRowShow({ id: 'representative.passport', state: !poaSectionOpen }))
                                    dispatch(setDynamicRowShow({ id: 'representative.POA', state: !poaSectionOpen }))
                                    dispatch(setDynamicRowShow({
                                        id: 'representative.separator',
                                        state: !poaSectionOpen
                                    }))
                                    dispatch(rerenderModal())
                                }
                            })
                        }
                        break
                }
                break
            case 'label-badge':
                switch (formItem.id) {
                    case 'PropertyInfo':
                        if (property != null) {
                            modal.form.push({
                                ...formItem.row,
                                content: [
                                    {
                                        label: 'Property Name',
                                        text: property.attributes.Name
                                    },
                                    {
                                        label: 'Property Location',
                                        text: property.attributes.Area
                                    },
                                    {
                                        label: 'Property Type',
                                        text: property.attributes.Type
                                    }
                                ]
                            })
                        }
                        break
                    default:
                        modal.form.push(formItem.row)
                        break
                }
                break
            case 'hidden-input':
                switch (formItem.id) {
                    case 'property_valid':
                        modal.form.push({
                            ...formItem.row,
                            required: property?.attributes?.Area == null ? formItem.row.required : undefined
                        })
                        break
                    default:
                        modal.form.push(formItem.row)
                        break
                }
                break
            default:
                modal.form.push(formItem.row)
                break
        }
    })
    if (modalID === 'validate_unit') {
        const tasks = getState().tasks.tasks
        const task = tasks.find((taskItem) => taskItem.id === Number(taskID))
        const documentLinks = task?.attributes?.Documents?.data?.map((document: any) => document?.attributes?.Link) ?? []
        modal.form = modal.form.filter((row: any) => validateUnitFields.includes(row.id))
        modal.form = [
            {
                id: 'validation_documents_preview',
                content: ['Open unit documents'],
                onClick: () => {
                    documentLinks
                        .filter(Boolean)
                        .forEach((link: string) => {
                            window.open(link, '_blank')
                        })
                },
                element: 'button'
            },
            {
                element: 'separator'
            },
            ...modal.form
        ]
    }
    if (getUserType() === 'Landlord') {
        // _.forEach(getState().setUnit.owners, (owner) => {
        //     const ownerFields = mapOwnerToTemplate(owner)
        //     modal.form.push({
        //         ...ownerFields[0].row,
        //         onRemove: () => {
        //             dispatch(removeOwner(owner.id))
        //             if (getState().setUnit.owners.length === 0) {
        //                 dispatch(setValue({ id: 'owners_valid', value: '' }))
        //             }
        //             dispatch(detectChange(true))
        //             dispatch(rerenderModal())
        //         },
        //         onInput: (e: any) => {
        //             dispatch(detectChange(true))
        //             dispatch(setValue({ value: e.target.value, id: ownerFieldId(owner.id ?? '', ...ownerFieldParts[0]) }))
        //         }
        //     })
        //     for (let i = 1; i < ownerFields.length; i++) {
        //         modal.form.push({
        //             ...ownerFields[i].row,
        //             onInput: (e: any) => {
        //                 dispatch(detectChange(true))
        //                 dispatch(setValue({
        //                     value: e.target.value,
        //                     id: ownerFieldId(owner.id ?? '', ...ownerFieldParts[i])
        //                 }))
        //             }
        //         })
        //     }
        //     try {
        //         const index = _.findIndex(modal.form, (formRow: any) => formRow.id === ownerFieldId(owner.id ?? '', ...ownerFieldParts[0]))
        //         modal.form[index].onAdd = undefined
        //     } catch (err) {
        //         console.error(err)
        //     }
        // })
    } else {
        const ownerRows: any[] = []
        _.forEach(getState().setUnit.owners, (owner) => {
            const ownerFields = mapOwnerToTemplate(owner)
            ownerRows.push({
                ...ownerFields[0].row,
                onRemove: () => {
                    dispatch(removeOwner(owner.id))
                    if (getState().setUnit.owners.length === 0) {
                        dispatch(setValue({ id: 'owners_valid', value: '' }))
                    }
                    dispatch(detectChange(true))
                    dispatch(rerenderModal())
                },
                onInput: (e: any) => {
                    dispatch(detectChange(true))
                    dispatch(setValue({ value: e.target.value, id: ownerFieldId(owner.id ?? '', ...ownerFieldParts[0]) }))
                }
            })
            for (let i = 1; i < ownerFields.length; i++) {
                if (!['emirates_id', 'passport'].some((idChunk) => ownerFields[i]?.row?.id?.includes(idChunk))) {
                    if (ownerFields[i]?.row?.id?.includes('IsDecisionMaker') === true) {
                    ownerRows.push({
                        ...ownerFields[i].row,
                        onChange: (e: any) => {
                            dispatch(detectChange(true))
                            dispatch(setValue({
                                value: e,
                                id: ownerFieldId(owner.id ?? '', ...ownerFieldParts[i])
                            }))
                            if (e === 'Yes') {
                                getState().setUnit.owners.forEach((editedOwner) => {
                                    if (editedOwner.id !== owner.id) {
                                        dispatch(setValue({
                                            value: 'No',
                                            id: ownerFieldId(editedOwner.id ?? '', 'IsDecisionMaker')
                                        }))
                                        dispatch(rerenderModal())
                                    }
                                })
                            }
                        }
                    })
                } else {ownerRows.push({
                        ...ownerFields[i].row,
                        onInput: (e: any) => {
                            dispatch(detectChange(true))
                            dispatch(setValue({
                                value: e.target.value,
                                id: ownerFieldId(owner.id ?? '', ...ownerFieldParts[i])}))
                            }
                        })
                    }
                } else {
                    const documentCategory = ownerFields[i]?.row?.id?.includes('passport') === true ? 'Passport' : 'Emirates ID'
                    ownerRows.push({
                        ...ownerFields[i].row,
                        required: owner.values.passport == null && owner.values.emirates_id == null,
                        uploaded: (owner.values.passport != null && documentCategory === 'Passport') || (owner.values.emirates_id != null && documentCategory === 'Emirates ID'),
                        uploadedBase64: getState().setUnit.uploadedDocuments[String(ownerFields[i].row.id)] ?? (documentCategory === 'Passport' ? owner.values.uploaded_passport : owner.values.uploaded_emirates_id),
                        onInput: (e: any) => {
                            const files = e.target.files
                            // const file = files?.[0]
                            // if (file != null) {
                            //     const reader = new FileReader()
                            //     reader.onload = () => {
                            //         dispatch(addUploadedDocument({ field: String(ownerFields[i].row.id), base64: String(reader.result) }))
                            //     }
                            //     reader.readAsDataURL(file)
                            // }
                            dispatch(dataFetchingStart())
                            dispatch(detectChange(true))
                            const RefID = `REF_DOC<->${documentCategory === 'Passport' ? 'passport' : 'emirates_id'}<->${nanoid()}`
                            void dispatch(createDocument(files, {
                                RefID,
                                Category: documentCategory,
                                Type: [{
                                    __component: `documents.${documentCategory === 'Passport' ? 'passport' : 'emirates-id'}`
                                }]
                            }, undefined, undefined, undefined, RefID))
                                .then((response :any) => {
                                    const fileUrl: string | null = response?.response?.data?.attributes?.Link
                                    if (fileUrl != null) {
                                        dispatch(addUploadedDocument({ field: String(ownerFields[i].row.id), base64: fileUrl }))
                                    }
                                    dispatch(setValue({
                                        value: response.response.data.id,
                                        id: ownerFieldId(owner.id ?? '', ...ownerFieldParts[i])
                                    }))
                                    dispatch(rerenderModal())
                                })
                                .finally(() => {
                                    dispatch(dataFetchingFinish())
                                })
                        }
                    })
                }
            }
            try {
                const index = _.findIndex(ownerRows, (formRow: any) => formRow.id === ownerFieldId(owner.id ?? '', ...ownerFieldParts[0]))
                ownerRows[index].onAdd = undefined
            } catch (err) {
                console.error(err)
            }
        })
        const poaIndex = _.findIndex(modal.form, (formRow: any) => formRow.id === 'add_poa_button')
        modal.form = [
            ..._.slice(modal.form, 0, poaIndex),
            ...ownerRows,
            ..._.slice(modal.form, poaIndex)
        ]
    }
    const index = _.findLastIndex(modal.form, (formRow: any) => _.includes(formRow.id, 'Owners[') && _.includes(formRow.id, 'Name'))
    if (index != null && modal.form[index] != null) {
        modal.form[index].onAdd = () => {
            dispatch(detectChange(true))
            dispatch(addOwnerToForm())
        }
        const buttonIndex = _.findIndex(modal.form, (formRow: any) => formRow.id === 'add_owner_button')
        modal.form.splice(buttonIndex, 1)
    }
    _.forEach(
        _.filter(getState().setUnit.dynamicForm, (formItem) => formItem.show),
        (formItem) => {
            let row: any
            switch (formItem.row.element) {
                case 'button':
                    row = {
                        ...formItem.row,
                        onClick: () => {

                        }
                    }
                    break
                case 'input-file':
                    switch (formItem.id) {
                        case 'representative.emirates_id':
                        case 'representative.passport':
                        case 'representative.POA':
                            // eslint-disable-next-line no-case-declarations
                            const representative = getState().setUnit.representative
                            row = {
                                ...formItem.row,
                                uploadedBase64: getState().setUnit.uploadedDocuments[formItem.id],
                                required: formItem.id === 'representative.POA'
                                    ? representative.poa == null
                                    : representative.passport == null && representative.emirates_id == null,
                                uploaded: (representative.passport != null && formItem.id === 'representative.passport') || (representative.emirates_id != null && formItem.id === 'representative.emirates_id') || (representative.poa != null && formItem.id === 'representative.POA'),
                                onInput: (e: any) => {
                                    const files = e.target.files
                                    dispatch(dataFetchingStart())
                                    dispatch(detectChange(true))

                                    const RefID = `REF_DOC<->${
                                        formItem.id === 'representative.passport'
                                            ? 'passport'
                                            : formItem.id === 'representative.emirates_id'
                                                ? 'emirates_id'
                                                : 'power_of_attorney'
                                    }<->${nanoid()}`

                                    const Category = formItem.id === 'representative.passport'
                                        ? 'Passport'
                                        : formItem.id === 'representative.emirates_id'
                                            ? 'Emirates ID'
                                            : 'Power Of Attorney'

                                    const Type = [{
                                        __component: `documents.${
                                            formItem.id === 'representative.passport'
                                                ? 'passport'
                                                : formItem.id === 'representative.emirates_id'
                                                    ? 'emirates-id'
                                                    : 'power-of-attorney'
                                        }`
                                    }]

                                    void dispatch(createDocument(files, {
                                        RefID,
                                        Category,
                                        Type
                                    }, undefined, undefined, undefined, RefID))
                                        .then((response : any) => {
                                            // console.log(response.response.data.id)
                                            const fileUrl: string | null = response?.response?.data?.attributes?.Link
                                            if (fileUrl != null) {
                                                dispatch(addUploadedDocument({ field: String(formItem.id), base64: fileUrl }))
                                            }
                                            const key = formItem.id === 'representative.passport'
                                                ? 'passport'
                                                : formItem.id === 'representative.emirates_id'
                                                    ? 'emirates_id'
                                                    : 'poa'
                                            dispatch(setRepresentative({
                                                ...getState().setUnit.representative,
                                                [key]: response.response.data.id
                                            }))
                                            dispatch(rerenderModal())
                                        })
                                        .finally(() => {
                                            dispatch(dataFetchingFinish())
                                        })
                                }
                            }
                            break
                        default:
                            row = formItem.row
                            break
                    }
                    break
                case 'input':
                    row = {
                        ...formItem.row,
                        onInput: (e: any) => {
                            //       console.log(e.target.value)
                            dispatch(setValue({ value: e.target.value, id: formItem.id ?? '' }))
                            dispatch(detectChange(true))
                        }
                    }
                    break
                case 'select':
                    switch (formItem.id) {
                        case 'SpecialBrokers':
                            row = {
                                ...formItem.row,
                                onChange: (e: any) => {
                                    dispatch(setValue({
                                        value: [
                                            ...(getState().setUnit.dynamicForm.find((storedFormItem) => storedFormItem.id === formItem.id)?.value ?? []),
                                            e
                                        ],
                                        id: formItem.id ?? ''
                                    }))
                                    dispatch(detectChange(true))
                                },
                                onDeselect: (e: any) => {
                                    dispatch(setValue({
                                        value: (getState().setUnit.dynamicForm.find((storedFormItem) => storedFormItem.id === formItem.id)?.value ?? []).filter((value: any) => value !== e),
                                        id: formItem.id ?? ''
                                    }))
                                    dispatch(detectChange(true))
                                },
                                selectors: getState().user.allUsers
                                    ?.filter(filterUserCallback)
                                    ?.map((user) => ({
                                        value: user.id,
                                        label: `${user?.ContactInfo?.FirstName ?? ''} ${user?.ContactInfo?.FamilyName ?? ''}`
                                    }))
                            }
                            break
                        default:
                            row = formItem.row
                            break
                    }
                    break
                default:
                    row = formItem.row
                    break
            }
            if (formItem.placeAfter != null) {
                const index = _.findIndex(modal.form, (formRow: any) => formRow?.id === formItem.placeAfter)
                modal.form = [
                    ..._.slice(modal.form, 0, index + 1),
                    row,
                    ..._.slice(modal.form, index + 1)
                ]
            } else if (formItem.placeBefore != null) {
                const index = _.findIndex(modal.form, (formRow: any) => formRow?.id === formItem.placeAfter)
                modal.form = [
                    ..._.slice(modal.form, 0, index),
                    row,
                    ..._.slice(modal.form, index)
                ]
            } else {
                modal.form.push(row)
            }
        }
    )
    return modal
}

/**
 * @function renderSetUnitInitialState
 * @description returns the initial state of the modal based on SetUnitSlice that will be passed to react hook form as initial state
 */
export const renderSetUnitInitialState = () => (dispatch: AppDispatch, getState: () => RootState) => {
    let initialState: any = {}
    _.forEach(getState().setUnit.staticForm, (formItem) => {
        if (formItem.value != null && formItem.id != null) {
            initialState[formItem.id] = formItem.value
        }
    })
    _.forEach(getState().setUnit.owners, (owner) => {
        if (owner.values != null && owner.id != null) {
            for (const fieldPart of ownerFieldParts) {
                if (owner.values[fieldPart[fieldPart.length - 1] as OwnerValuesKey] != null) {
                    if (_.isBoolean(owner.values[fieldPart[fieldPart.length - 1] as OwnerValuesKey])) {
                        initialState[ownerFieldId(owner.id, ...fieldPart)] = owner.values[fieldPart[fieldPart.length - 1] as OwnerValuesKey] === true ? 'Yes' : 'No'
                    } else {
                        initialState[ownerFieldId(owner.id, ...fieldPart)] = owner.values[fieldPart[fieldPart.length - 1] as OwnerValuesKey]
                    }
                }
            }
        }
    })
    _.forEach(getState().setUnit.dynamicForm, (formItem) => {
        if (formItem.value != null && formItem.id != null && formItem.show) {
            switch (formItem.row.element) {
                case 'input-date':
                    initialState[formItem.id] = dayjs(formItem.value)
                    break
                default:
                    initialState[formItem.id] = formItem.value
                    break
            }
        }
    })
    initialState = {
        ...initialState,
        ...getState().setUnit.documentFields
    }
    return initialState
}

/**
 * @function fillValuesForEdit
 * @param {number} unitID
 *
 * @description for each field of the set unit modal finds value in unit object stored in state and calls setValue function to fill the value in SetUnitState.
 * After all values are filled calls rerenderModal to render the filled modal to user
 */
export const fillValuesForEdit = (unitID: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    // const { currentUser } = getState().user
    const units = getState().units.units
    const unit = units.find(unitItem => unitItem?.id === unitID)
    const currentUnit = removeDataAttributes(_.cloneDeep(unit ?? {}))
    const { staticForm } = getState().setUnit
    _.forEach(staticForm, (formItem) => {
        switch (formItem.id) {
            case 'title_deed':
                dispatch(setValue({ id: 'title_deed', value: documentStatuses.true }))
                break
            case 'Property':
                console.log((currentUnit)?.Property?.id ?? (currentUnit)?.Property?.data?.id)
                dispatch(setValue({ id: 'Property', value: (currentUnit)?.Property?.id ?? (currentUnit)?.Property?.data?.id }))
                break
            case 'Statuses':
            // case 'Statuses[2]':
                for (const selector of formItem.row.selectors as Array<{ value: string }> ?? []) {
                    const splitSelector = selector?.value?.split('-')
                    if (splitSelector?.every((selectorItem) => (currentUnit)?.Statuses?.includes(selectorItem))) {
                        dispatch(setValue({ id: formItem.id as string, value: selector?.value }))
                    }
                }
                /* _.forEach((currentUnit)?.Statuses ?? [], (status: string) => {
                    //   console.log(formItem.row.selectors, status)
                    if (_.includes(formItem.row.selectors as string[], status)) {
                        dispatch(setValue({ id: formItem.id as string, value: status }))
                    }
                }) */
                break
            case 'Statuses[1]':
                if (_.includes((currentUnit)?.Statuses ?? [], (formItem.row.selectors?.[0] as { label: string, value: string })?.value)) {
                    dispatch(setValue({ id: 'Statuses[1]', value: (formItem.row.selectors?.[0] as { label: string, value: string })?.value }))
                } else {
                    dispatch(setValue({ id: 'Statuses[1]', value: (formItem.row.selectors?.[1] as { label: string, value: string })?.value }))
                }
                break
            case 'GuestBathroom':
            case 'MaidsRoom':
            case 'ImagesOnly':
                dispatch(setValue({ id: formItem.id, value: currentUnit[formItem.id] === true ? 'Yes' : 'No' }))
                if (formItem.id === 'ImagesOnly' && currentUnit[formItem.id] === true) {
                    dispatch(setDynamicRowShow({ id: 'SpecialBrokers', state: true }))
                }
                break
            case 'DisabledTimes':
                dispatch(setValue({
                    id: formItem.id,
                    value: currentUnit?.DisabledTimes?.map((range: any) => (
                        {
                            From: range.From.replace(':00.000', ''),
                            To: range.To.replace(':00.000', '')
                        }
                    )) ?? []
                }))
                break
            case 'AdvertisementSpaces':
                dispatch(setValue({
                    id: formItem.id,
                    value: currentUnit.AdvertisementSpaces != null ? String(currentUnit.AdvertisementSpaces) : 'Open'
                }))
                break
            case 'property_type':
            {
                const unitDocsIDs = currentUnit?.Documents?.map((doc: any) => doc.id) ?? []
                const unitTitleDeedDocs = getState().documents.documents.filter((doc) => unitDocsIDs.includes(doc.id) === true && doc?.attributes?.Type?.[0]?.__component === 'documents.title-deed')
                const titleDeedDoc = unitTitleDeedDocs.length > 0 ? unitTitleDeedDocs[unitTitleDeedDocs.length - 1] : undefined
                dispatch(setValue({
                    id: formItem.id,
                    value: titleDeedDoc?.attributes?.Type?.[0]?.Type ?? null
                }))
                break
            }
            case 'special_requests':
                dispatch(setValue({
                    id: formItem.id,
                    value: currentUnit?.Statuses?.includes('Special Request') === true ? 'Yes' : 'No'
                }))
                break
            default:
                // path = _.split(formItem.row.id, '.')
                dispatch(setValue({
                    id: formItem.row.id as string,
                    value: _.get(currentUnit, formItem.row.id as string)
                    // value: _.reduce(
                    //     path,
                    //     (object, key) => object?.[key],
                    //     ((currentUnit) ?? {}))
                }))
        }
        if (formItem.row.id === 'Images') {
            if (currentUnit?.Images?.length > 0) {
                dispatch(setImages(_.map((currentUnit)?.Images, (image) => ({ data: image }))))
                dispatch(setImagesLoaded(true))
            }
        }
        /* if (row.id === 'Marketing.DiscountStartDatetime' || row.id === 'Marketing.DiscountEndDatetime') {
            row.default = dayjs(row.default)
            console.log(row.default, typeof row.default)
        }
        if (row.element === 'label-secondary') {
            row.content[1] = row.default
            if (row.id === 'Marketing.DiscountStartDatetime' || row.id === 'Marketing.DiscountEndDatetime') {
                row.content[1] = moment(row.default).format('LL')
            }
        }
        if (row.id === 'edit_marketing_button') {
            row.modal = 'edit_unit_marketing_modal'
        }
        if (row.id === 'Images') {
            dispatch(setImages(_.map((currentUnit)?.attributes?.Images, (image) => ({ data: image }))))
        }
        state[row.id] = row.default
        console.log({ state }) */
    })
    if (currentUnit?.Documents?.length > 0) {
        const filteredDocs = _.filter(currentUnit.Documents, document => document?.Type?.[0]?.__component === 'documents.title-deed')
        const docs = _.map(
            filteredDocs,
            (document: any) => document.id
        )
        dispatch(setDocuments(docs))
        if (filteredDocs?.[filteredDocs.length - 1]?.Link != null) {
            dispatch(addUploadedDocument({ field: 'title_deed', base64: filteredDocs[filteredDocs.length - 1].Link }))
        }
        dispatch(setTitleDeedLoaded(true))
    }
    if (getUserType({ checkForAdmin: true }) === 'Admin') {
        dispatch(setDynamicRowShow({ id: 'marketing_separator', state: true }))
        dispatch(setDynamicRowShow({ id: 'marketing_title', state: true }))
        dispatch(setDynamicRowShow({ id: 'edit_marketing_button', state: true }))
    }
    if (currentUnit?.Statuses?.includes('Occupied') === true) {
        dispatch(setDynamicRowShow({ id: 'OccupiedUntil', state: true }))
        dispatch(setValue({
            id: 'OccupiedUntil',
            value: currentUnit.OccupiedUntil // TODO probably wrap OccupiedUntil in dayjs() or not
        }))
    }
    _.forEach(currentUnit.Landlords, (landlord: any) => {
        if (landlord?.Landlord?.ContactInfo?.FirstName != null && landlord?.Landlord?.ContactInfo?.FirstName !== '') {
            dispatch(addOwnerToForm({
                Name: `${String(landlord.Landlord?.ContactInfo?.FirstName)} ${String(landlord.Landlord?.ContactInfo?.FamilyName)}`,
                Phone: landlord.Landlord?.ContactInfo?.Phone,
                Email: landlord.Landlord?.ContactInfo?.Email,
                // DocumentNumber: landlord.Landlord?.RefID?.split('-')[landlord?.Landlord?.RefID?.split('-')?.length - 1],
                passport: landlord.Landlord?.Documents?.find((doc: any) => doc.Category === 'Passport')?.id,
                emirates_id: landlord.Landlord?.Documents?.find((doc: any) => doc.Category === 'Emirates ID')?.id,
                uploaded_passport: landlord.Landlord?.Documents?.find((doc: any) => doc.Category === 'Passport')?.Link,
                uploaded_emirates_id: landlord.Landlord?.Documents?.find((doc: any) => doc.Category === 'Emirates ID')?.Link,
                IsDecisionMaker: landlord.IsDecisionMaker
            }))
        }
    })
    const representative = currentUnit?.Landlords?.[0]?.Representatives?.[0]
    if (currentUnit?.Landlords?.[0]?.Representatives?.[0]?.ContactInfo?.FirstName != null) {
        dispatch(setDynamicRowShow({ id: 'representative.ContactInfo.Name', state: true }))
        dispatch(setDynamicRowShow({ id: 'representative.ContactInfo.Phone', state: true }))
        dispatch(setDynamicRowShow({ id: 'representative.ContactInfo.Email', state: true }))
        // dispatch(setDynamicRowShow({ id: 'representative.DocumentNumber', state: true }))
        dispatch(setDynamicRowShow({ id: 'representative.emirates_id', state: true }))
        dispatch(setDynamicRowShow({ id: 'representative.passport', state: true }))
        dispatch(setDynamicRowShow({ id: 'representative.POA', state: true }))
        dispatch(setDynamicRowShow({ id: 'representative.separator', state: true }))

        dispatch(setValue({ id: 'representative.ContactInfo.Name', value: `${String(representative?.ContactInfo?.FirstName)} ${String(representative?.ContactInfo?.FamilyName)}` }))
        dispatch(setValue({ id: 'representative.ContactInfo.Phone', value: representative?.ContactInfo?.Phone }))
        dispatch(setValue({ id: 'representative.ContactInfo.Email', value: representative?.ContactInfo?.Email }))
        // dispatch(setValue({ id: 'representative.DocumentNumber', value: representative?.RefID?.split('-')[representative?.RefID?.split('-')?.length - 1] }))
        const representativePassport = representative?.Documents?.find((doc: any) => doc.Category === 'Passport')
        const representativeEmiratesID = representative?.Documents?.find((doc: any) => doc.Category === 'Emirates ID')
        const representativePOA = currentUnit?.Landlords?.[0]?.PowerOfAttorney
        console.log(representativePOA)
        if (representativePassport?.Link != null) {
            dispatch(addUploadedDocument({ field: 'representative.passport', base64: representativePassport.Link }))
        }
        if (representativeEmiratesID?.Link != null) {
            dispatch(addUploadedDocument({ field: 'representative.emirates_id', base64: representativeEmiratesID.Link }))
        }
        if (representativePOA?.Link != null) {
            dispatch(addUploadedDocument({ field: 'representative.POA', base64: representativePOA.Link }))
        }
        dispatch(setRepresentative({
            passport: representativePassport?.id,
            emirates_id: representativeEmiratesID?.id,
            poa: representativePOA?.id
        }))
    }
    dispatch(setValue({ id: 'SpecialBrokers', value: currentUnit?.SpecialBrokers?.map((broker: any) => broker.id) ?? [] }))
    dispatch(rerenderModal())
}

/**
 * @function addOwnerToForm
 * @param {OwnerValues} values
 *
 * @description This function adds a new owner to the owners list and calls rerenderModal to display new owner.
 * If there is at least one owner in the list, a hidden field owners_valid gets value 'Valid' so it is possible to submit the form. Otherwise, validation will be invoked
 */
export const addOwnerToForm = (values: OwnerValues = {}) => (dispatch: AppDispatch, getState: () => RootState) => {
    const { owners } = getState().setUnit
    const index = (owners[owners.length - 1]?.index ?? 0) + 1
    dispatch(addOwner({
        index,
        id: `Owners[${index}]`,
        values
    }))
    dispatch(rerenderModal())
    if (getState().setUnit.owners.length === 0) {
        dispatch(setValue({ id: 'owners_valid', value: '' }))
    } else {
        dispatch(setValue({ id: 'owners_valid', value: 'Valid' }))
    }
}

/**
 * @function ownerFieldId
 * @param {...string[]}args
 *
 * @description gets owner field id from sources like the ownerFieldParts array
 */
export const ownerFieldId = (...args: string[]): string => {
    return args.join('.')
}

const ownerFieldParts = [
    ['ContactInfo', 'Name'],
    ['ContactInfo', 'Phone'],
    ['ContactInfo', 'Email'],
    // ['DocumentNumber'],
    ['emirates_id'],
    ['passport'],
    ['IsDecisionMaker'],
    ['separator']
]

/**
 * @function mapOwnerToTemplate
 * @param {Owner} owner
 *
 * @description prepares owner object to be added to form as a set of fields
 */
export const mapOwnerToTemplate = (owner: Owner): Array<{ row: ModalRow<string>, value: any }> => {
    return [
        {
            row: {
                id: ownerFieldId(owner.id, 'ContactInfo', 'Name'),
                content: ['Owner name'],
                element: 'input',
                required: true
            },
            value: owner.values.Name
        },
        {
            row: {
                id: ownerFieldId(owner.id, 'ContactInfo', 'Phone'),
                content: ['Owner phone'],
                element: 'input',
                regexp: regExps.isPhone
                // required: true
            },
            value: owner.values.Phone
        },
        {
            row: {
                id: ownerFieldId(owner.id, 'ContactInfo', 'Email'),
                content: ['Owner email'],
                element: 'input',
                regexp: regExps.isEmail
                // required: true
            },
            value: owner.values.Email
        },
        // {
        //     row: {
        //         id: ownerFieldId(owner.id, 'DocumentNumber'),
        //         content: ['Owner document number'],
        //         element: 'input',
        //         regexp: regExps.isNumber,
        //         required: true
        //     },
        //     value: owner.values.DocumentNumber
        // },
        {
            row: {
                id: ownerFieldId(owner.id, 'emirates_id'),
                content: ['Landlord emirates id'],
                element: 'input-file',
                required: true
            },
            value: owner.values.emirates_id
        },
        {
            row: {
                id: ownerFieldId(owner.id, 'passport'),
                content: ['Landlord passport'],
                element: 'input-file',
                required: true
            },
            value: owner.values.passport
        },
        {
            row: {
                id: ownerFieldId(owner.id, 'IsDecisionMaker'),
                content: ['Is decision maker', 'Select option'],
                selectors: ['Yes', 'No'],
                element: 'select',
                required: false
            },
            value: owner.values.IsDecisionMaker === true ? 'Yes' : 'No'
        },
        {
            row: {
                id: ownerFieldId(owner.id, 'separator'),
                element: 'separator'
            },
            value: undefined
        }
    ]
}

/**
 * @function applyTitleDeed
 * @param {any[]} scannedDocuments
 *
 * @description the callback that is called after TitleDeed is scanned.
 * Adds owners to the form, fills the TitleDeed related fields in modal.
 * Adds or picks property.
 */
export const applyTitleDeed = (scannedDocuments: any[]) => (dispatch: AppDispatch, getState: () => RootState) => {
    const values = dispatch(renderSetUnitInitialState())
    // customLog(values, 'VALUES')
    // const keys = _.keys(values)
    const keys = _.map(getState().setUnit.staticForm, (formItem) => formItem.id)
    for (const document of scannedDocuments) {
        const entries = _.toPairs(document?.response?.document)
        for (const [key, value] of entries) {
            const formKey = _.find(keys, (keyItem) => keyItem?.toLowerCase() === key.toLowerCase())
            if (formKey != null) {
                switch (formKey) {
                    case 'Number':
                        break
                    case 'Property':
                        // eslint-disable-next-line no-case-declarations
                        const searchedAreas = searchFuse(
                            getState()?.app?.config?.all?.Area ?? [],
                            (value as any)?.area,
                            0.4,
                            []
                        )
                        console.log(searchedAreas, _.cloneDeep(getState()?.app?.config?.all?.Area)?.sort())
                        // eslint-disable-next-line
                        const storedProperty = getState().property.properties.find((property) => property.attributes.Area === searchedAreas?.[0]?.item && property.attributes.Name === (value as any)?.name)
                        if (storedProperty == null) {
                            void dispatch(addProperty({
                                Name: (value as any)?.name ?? searchedAreas[0]?.item ?? (value as any)?.area,
                                Area: searchedAreas[0]?.item,
                                Type: 'Community'
                            }, true))
                                .then((response) => {
                                    dispatch(setValue({ id: 'Property', value: response.response.data.id }))
                                    //  console.log({ response })
                                    dispatch(setDynamicDropDown(mapPropertiesForSelect(getState().property.properties)))
                                    dispatch(rerenderModal())
                                }).catch((err) => {
                                    console.log(err)
                                })
                        } else {
                            dispatch(setValue({ id: 'Property', value: storedProperty.id }))
                        }
                        dispatch(setValue({ id: 'Number', value: (value as any)?.number }))
                        break
                    case 'Type':
                        values.property_type = value
                        dispatch(setValue({ id: 'property_type', value: value as string }))
                        break
                    default:
                        values[formKey] = value
                        dispatch(setValue({ id: formKey, value: value as string }))
                        break
                }
            }
        }

        switch (getUserType({ checkForAdmin: true })) {
            case 'Admin':
            case 'Landlord':
                _.forEach(/* _.filter( */ document?.response?.document?.owners/* , owner => owner.valid === true */, (owner: any) => {
                    dispatch(addOwnerToForm({ Name: owner.name }))
                })
                break
            default:
                break
        }
    }
    dispatch(rerenderModal())
}
