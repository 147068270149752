import React, { type ReactElement, useMemo, useState } from 'react'
import { type StepProps, Steps } from 'antd'
import Icon from '../../../../icon/Icon'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'
import {
    checkIfUnitHasPassedValidationProcess,
    checkIfUnitIsInValidationProcess
} from '../../../../../store/actionsCreators/unitActions/landlordUnitsActions/utils/checkIfUnitIsISInValidationProcess'

interface StepData extends StepProps {
    index: string
}

interface LandlordUnitTimelineProps {
    unitID: number
}

export default function LandlordUnitTimeline ({ unitID }: LandlordUnitTimelineProps): ReactElement {
    const [line, setLine] = useState<'prev' | 'next' | 'middle'>('middle')
    const units = useAppSelector((state: RootState) => state.units.units)
    const dispatch = useAppDispatch()

    const [currentStepNumber, allSteps] = useMemo(() => {
        let allSteps: StepData[] = [
            {
                title: (
                    <div>
                        <div className='lh-sm mb-1'>Fill the main unit info</div>
                    </div>
                ),
                status: 'finish',
                disabled: false,
                description: 'Including the information about unit\'s property, owner, features e.t.c.',
                index: 'create_step'
            },
            {
                title: (
                    <div>
                        <div className='lh-sm mb-1'>{'Unit\'s documents get validated by BSO'} </div>
                    </div>
                ),
                status: 'finish',
                disabled: false,
                description: 'The Title Deed and owner\'s documents are going to be validated as soon as possible',
                index: 'docs_validation_step'
            },
            {
                title: (
                    <div>
                        <div className='lh-sm mb-1'>Add marketing information</div>
                    </div>
                ),
                status: 'finish',
                disabled: false,
                description: 'Add marketing information for unit by ordering Find a Tenant service or by Finding Tenants on your own',
                index: 'add_marketing_step'
            }
        ]
        const unit = units.find((unitItem) => unitItem.id === unitID)
        const unitIsInValidationProcess = dispatch(checkIfUnitIsInValidationProcess(Number(unit?.id))) === true
        const unitHasPassedValidationProcess = dispatch(checkIfUnitHasPassedValidationProcess(Number(unit?.id))) === true
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const marketingStep = allSteps.find(step => step.index === 'add_marketing_step')!
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const docsValidationStep = allSteps.find(step => step.index === 'docs_validation_step')!
        let currentStepNumber = 0
        if (unit?.attributes?.Marketing === null) {
            marketingStep.status = 'wait'
            if (unitIsInValidationProcess) {
                docsValidationStep.status = 'process'
                currentStepNumber = allSteps.findIndex(step => step.index === 'docs_validation_step')
            } else if (unitHasPassedValidationProcess) {
                docsValidationStep.status = 'finish'
                currentStepNumber = allSteps.findIndex(step => step.index === 'add_marketing_step')
                marketingStep.status = 'process'
            } else {
                marketingStep.status = 'process'
                allSteps = allSteps.filter(step => step.index !== 'docs_validation_step')
                currentStepNumber = allSteps.findIndex(step => step.index === 'add_marketing_step')
            }
        } else {
            marketingStep.status = 'finish'
            if (!unitIsInValidationProcess && !unitHasPassedValidationProcess) {
                allSteps = allSteps.filter(step => step.index !== 'docs_validation_step')
            }
            currentStepNumber = allSteps.findIndex(step => step.index === 'add_marketing_step')
        }
        return [
            currentStepNumber,
            allSteps
        ]
    }, [unitID, units, dispatch])

    const renderedSteps = useMemo(() => {
        console.log(allSteps, currentStepNumber, line)
        switch (line) {
            case 'middle':
                return [
                    allSteps[currentStepNumber]
                ]
            case 'prev':
                return allSteps.slice(0, currentStepNumber + 1)
            case 'next':
                return allSteps.slice(currentStepNumber)
            default:
                return allSteps
        }
    }, [allSteps, currentStepNumber, line])

    return (
        <>
            <Steps
                current={0}
                percent={60}
                items={renderedSteps}
            />
            <div className={'d-flex'} style={{ justifyContent: 'space-evenly' }}>
                <div className="d-flex justify-content-between align-items-center mx-4">
                    {<div className={'text-end'}>
                        <div
                            className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                            onClick={line === 'prev'
                                ? () => {
                                    // setSliceSteps([groupRent[process]]);
                                    setLine('middle')
                                }
                                : () => {
                                    // setSliceSteps([...groupRent].slice(0, process + 1))
                                    setLine('prev')
                                }
                            }
                        >
                            <div className="d-flex justify-content-around align-items-center">
                                <Icon name={line === 'prev' ? 'play_circle' : 'expand_circle_up'} className={'fs-5'} />
                                <span className="ms-1 fs-8">{line === 'prev' ? 'Current step' : 'Previous steps'}</span>
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-between align-items-center mx-2">
                        <div className={'text-end'}>
                            <div
                                className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                                onClick={
                                    line === 'next'
                                        ? () => {
                                            // setSliceSteps([groupRent[process]]);
                                            setLine('middle')
                                        }
                                        : () => {
                                            // setSliceSteps([...groupRent].slice(process));
                                            setLine('next')
                                        }}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <Icon name={line === 'next' ? 'play_circle' : 'expand_circle_down'} className={'fs-5'} />
                                    <span className="ms-1 fs-8">   {line === 'next' ? 'Current step' : 'Next steps'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
