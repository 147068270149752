import React, {type ReactElement} from "react";
import Icon from "../../../../icon/Icon";

interface InputPasswordSecurityProps {
    value: string
}

const calculatePasswordStrength = (password: string): number => {
    let strength = 0
    if (/[A-Z]/.test(password)) {
        strength++
    }
    // if (/[a-z]/.test(password)) {
    //     strength++
    // }
    if (/\d/.test(password)) {
        strength++
    }
    if (/[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/.test(password)) {
        strength++
    }
    if (password?.length > 8) {
        strength++
    }

    return strength
}

export default function InputPasswordSecurity ({value}: InputPasswordSecurityProps): ReactElement {
    const passwordStrength = calculatePasswordStrength(value)
    let indicatorActiveClassName = 'flex-grow-1 rounded h-5px'
    const indicatorInactiveClassName = 'flex-grow-1 bg-secondary rounded h-5px'
    switch (passwordStrength) {
        case 1:
            indicatorActiveClassName += ' bg-danger'
            break
        case 2:
        case 3:
            indicatorActiveClassName += ' bg-bso'
            break
        case 4:
            indicatorActiveClassName += ' bg-success'
            break
        default:
            break
    }
    return (
        <div>
            <div className="d-flex align-items-center mb-2 pt-2 gap-2">
                <div className={passwordStrength >= 1 ? indicatorActiveClassName : indicatorInactiveClassName}></div>
                <div className={passwordStrength >= 2 ? indicatorActiveClassName : indicatorInactiveClassName}></div>
                <div className={passwordStrength >= 3 ? indicatorActiveClassName : indicatorInactiveClassName}></div>
                <div className={passwordStrength >= 4 ? indicatorActiveClassName : indicatorInactiveClassName}></div>
            </div>
            <div className={'d-flex gap-3'}>
                <div className={'fs-1 text-gray-400'}>
                    <Icon name={'info'}/>
                </div>
                <div className={'fs-5 fw-medium text-gray-400'}>
                    Must be 8 characters or more and contain at least one special number or symbol.
                </div>
            </div>
        </div>
    )
}