import React from 'react'
import {type Unit} from "../../../../../../../store/slices/unitsSlice";
import {type TableProps} from "../../../../../UIv2/Table/Table";
import ViewUnitMarketingPriceDealPrice from "./ViewUnitMarketingPriceDealPrice";
import {nanoid} from "nanoid";

type IUseViewUnitMarketingPrice = (unit: Unit) => {
    tableProps: TableProps
}

const useViewUnitMarketingPrice: IUseViewUnitMarketingPrice = () => {
    return {
        tableProps: {
            columns: [
                {
                    title: 'Deal Price'
                },
                {
                    title: 'Rental Index'
                },
                {
                    title: 'Duration'
                }
            ],
            rows: [
                [
                    <ViewUnitMarketingPriceDealPrice price={150000} priceChange={{ direction: 'up', amount: 20 }} key={nanoid()}/>,
                    <div className={'fs-7 fw-medium'} key={nanoid()}>110,000 - 130,000</div>,
                    <div className={'fs-7'} key={nanoid()}>09/23 - 09/24</div>
                ],
                [
                    <ViewUnitMarketingPriceDealPrice price={120000} priceChange={{ direction: 'down', amount: 10 }} key={nanoid()}/>,
                    <div className={'fs-7 fw-medium'} key={nanoid()}>110,000 - 130,000</div>,
                    <div className={'fs-7'} key={nanoid()}>09/23 - 09/24</div>
                ]
            ]
        }
    }
}

export default useViewUnitMarketingPrice
