import React, { useEffect, useState } from 'react'
import { type IOffer } from '../../../../store/slices/offersSlice'
import { type Unit } from '../../../../store/slices/unitsSlice'
import { type TimelineItem } from '../ViewingTimeline'

import Icon from '../../../icon/Icon'
import { Steps } from 'antd'
import { SchedulerStatus } from '../../../../functions/agent/date/SchedulerStatus'
import Approved from '../../../../functions/agent/statuses/Approved'

interface IProps {
    offer: IOffer
    unit: Unit
    isMyUnit: boolean
}
export default function TimelineOfferAgentToLandlord ({ offer, unit, isMyUnit }: IProps): JSX.Element {
    let process = 0
    const offerStatuses = offer?.attributes?.Statuses

    const textsOfferTimeline = {
        stepInProcess: isMyUnit ? 'Confirm offer from agent' : 'Wait for offer approval', //
        descriptionInProcess: ' ',
        stepApprovedOrRejected: isMyUnit ? 'You approve the agent\'s offer ' : 'Your offer has been aproved ',
        descriptionApprovedOrRejected: isMyUnit ? '' : '',
        stepConfirmed: isMyUnit ? 'You confirm the agent\'s offer' : 'Your have a confirmed offer',
        descriptionConfirmed: isMyUnit ? ' ' : ' ',
        stepCompleted: 'Deal closed successfully',
        descriptionCompleted: 'Congratulations on another completed offer! Let’s make a new one with the BSO Club!',
        addTitle: ''
    }

    if (offerStatuses?.includes('In-process')) {
        process = 0
    } else if (offerStatuses?.includes('Canceled')) { // || offerStatuses?.includes('Canceled by other agent')) {
        process = 0
        textsOfferTimeline.stepInProcess = 'The offer was canceled'
        textsOfferTimeline.descriptionInProcess = 'Description for cancel agent\'s offer'
    } else if (offerStatuses?.includes('Confirmed')) {
        process = 1
        textsOfferTimeline.stepApprovedOrRejected = isMyUnit ? 'You have confirmed this offer.' : 'Landlord has confirmed your offer.'
        textsOfferTimeline.descriptionApprovedOrRejected = isMyUnit ? 'Check everything, if it good, Close deal ' : 'Wait complete deal'
    } else if (offerStatuses?.includes('Negotiated by agent')) {
        process = 1
        textsOfferTimeline.stepApprovedOrRejected = isMyUnit ? 'You have new deal from agent' : 'You negotiated new offer negotiation to landlord'
        textsOfferTimeline.descriptionApprovedOrRejected = ''
    } else if (offerStatuses?.includes('Negotiated by landlord')) {
        process = 1
        textsOfferTimeline.stepApprovedOrRejected = isMyUnit ? 'You negotiated new offer negotiation to agent' : 'You have new deal from landlord'
        textsOfferTimeline.descriptionApprovedOrRejected = ''
    } else if (offerStatuses?.includes('Completed')) {
        process = 2
    }

    const [line, setLine] = useState('middle')

    const iconProcess = (step: number, process: number) => {
        if (process + 1 > step || process === 2) {
            return undefined
        }
        return (<div className="ant-steps-item-icon">
            <span className="ant-steps-icon" style={{ color: 'black' }}>
                {step}
            </span>
        </div>
        )
    }

    const groupRent: TimelineItem[] = [
        {
            title: (
                <div>
                    <div className='lh-sm mb-1'>{textsOfferTimeline.stepInProcess}</div>
                    <div className={'d-flex align-items-center'}>
                        {process === 0 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="subTitle">{process === 0 ? SchedulerStatus(offerStatuses ?? [], 'fs-6') : undefined}</div>
                    </div></div>
            ),
            description: textsOfferTimeline.descriptionInProcess,
            status: process === 0 && (offerStatuses?.includes('Canceled') || offerStatuses?.includes('Rejected') || offerStatuses?.includes('Canceled by other agent')) ? 'finish' : undefined
        } as TimelineItem,

        ...((offerStatuses?.includes('Canceled') || offerStatuses?.includes('Canceled by other agent'))

            ? []
            : [
                {
                    title: (
                        <div>
                            <div className='lh-sm mb-1'> {textsOfferTimeline.stepApprovedOrRejected} </div>
                            <div className={'d-flex align-items-center'}>
                                {process === 1 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2 ">Status</div>}
                                <div className="subTitle">{
                                    process === 1
                                        ? offerStatuses.includes('Approved')
                                            ? <Approved fontsize={'fs-6'} />
                                            : SchedulerStatus(offerStatuses ?? [], 'fs-6 mb-2 pb-2')
                                        : undefined
                                }
                                </div>
                            </div>
                            {(process === 1 || process === 0) && (offerStatuses?.includes('Canceled by other agent') || offerStatuses?.includes('Rejected')) && offer?.attributes?.RejectReason &&
                                <div className={'d-flex align-items-center'}>
                                    <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Reject Reason :</div>
                                    <div className="d-block mt-2"> {
                                        SchedulerStatus([`${offer?.attributes?.RejectReason}`], 'badge badge-light-danger  border border-danger text-truncate mw-100 d-inline-block fs-6')
                                    } </div>
                                </div>
                            }
                        </div>
                    ),
                    description: textsOfferTimeline.descriptionApprovedOrRejected
                },
                ...(!(offerStatuses?.includes('Rejected') || offerStatuses?.includes('Canceled'))
                    ? [

                        {
                            title: (
                                <div >
                                    <div className='lh-sm mb-1'> {textsOfferTimeline.stepCompleted} </div>
                                    <div className={'d-flex align-items-center'}>
                                        {process === 2 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                        <div className="subTitle d-block">{process === 2 ? SchedulerStatus(offerStatuses ?? [], 'fs-6 my-0') : undefined}</div>
                                    </div></div>
                            ),
                            description: textsOfferTimeline.descriptionCompleted,
                            status: process === 2 ? 'finish' : undefined
                        } as TimelineItem]
                    : [])

            ])

    ]
    const [sliceSteps, setSliceSteps] = useState([groupRent[process]])

    useEffect(() => {
        groupRent.map((el, i) => el.icon = iconProcess(i + 1, process))
    }, [sliceSteps, process])

    return (
        <div>

            <Steps
                current={line === 'next' ? 0 : line === 'middle' ? 0 : process}
                percent={60}
                items={sliceSteps}
            />

            <div className={'d-flex'} style={{ justifyContent: process === 0 || process === 2 ? 'flex-end' : 'space-evenly' }}>
                <div className="d-flex justify-content-between align-items-center mx-4">
                    {process !== 0 && <div className={'text-end'}>
                        <div
                            className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                            onClick={line === 'prew'
                                ? () => { setSliceSteps([groupRent[process]]); setLine('middle') }
                                : () => {
                                    setSliceSteps([...groupRent].slice(0, process + 1))
                                    setLine('prew')
                                }
                            }
                        >
                            <div className="d-flex justify-content-around align-items-center">
                                <Icon name={line === 'prew' ? 'play_circle' : 'expand_circle_up'} className={'fs-5'} />
                                <span className="ms-1 fs-8">{line === 'prew' ? 'Current step' : 'Previous steps'}</span>
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-between align-items-center mx-2">
                        {process !== 2 && !offerStatuses.includes('Canceled') && <div className={'text-end'}>
                            <div
                                className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                                onClick={
                                    line === 'next'
                                        ? () => { setSliceSteps([groupRent[process]]); setLine('middle') }
                                        : () => { setSliceSteps([...groupRent].slice(process)); setLine('next') }}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <Icon name={line === 'next' ? 'play_circle' : 'expand_circle_down'} className={'fs-5'} />
                                    <span className="ms-1 fs-8">   {line === 'next' ? 'Current step' : 'Next steps'}</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </div>
    )
}
