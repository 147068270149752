import React, {type ReactElement, useRef, useState} from 'react'
import PageLayout from "../../UIv2/PageLayout/PageLayout";
import IconButton from "../../UIv2/Button/IconButton";
import PageSearchbar from "../../UIv2/PageSearchbar/PageSearchbar";
import PageHeader from "../../UIv2/PageHeader/PageHeader";
import {useAppSelector} from "../../../../hooks/redux";
import LandlordUnitCard from "./UnitCards/LandlordUnitCard/LandlordUnitCard";
import {nanoid} from "nanoid";
import InfoBlock from "../../UIv2/InfoBlock/InfoBlock";
import useMyUnits from "./MyUnits.hook";
import {useNavigate} from "react-router-dom";
import getUserType from "../../../../functions/shared/user/getUserType";
import useUserType from "../../../../hooks/UseUserType";
import MyUnitsScrollSaver from "../MyUnitsScrollSaver";
export default function MyUnitsV2 (): ReactElement {
    const [showInfoBlock, setShowInfoBlock] = useState(true)
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const navigate = useNavigate()
    const scrollRef = useRef<HTMLDivElement>(null)
    const {
        myUnits,
        searchString,
        setSearchString
    } = useMyUnits()
    const {userTypeLowercase} = useUserType()
    return <PageLayout>
        <MyUnitsScrollSaver scrollRef={scrollRef}/>
        <PageHeader
            title={'My Units'}
            controls={
                <div className={'d-flex align-items-center gap-2'}>
                    <IconButton icon={'add'} style={'bso'} onClick={() => {
                        switch (getUserType()) {
                            case 'Landlord':
                                navigate(`/${userTypeLowercase}/modal/v2/landlord_create_unit_choose_role/-/-`)
                                break
                            case 'Admin':
                                navigate(`/${userTypeLowercase}/set_unit_modal/set_unit_modal/-/-`)
                                break
                            case 'Agent':
                                navigate(`/${userTypeLowercase}/modal/agent_create_unit_property/-/-`)
                                break
                        }

                    }}/>
                    <IconButton icon={'more_vert'} style={'gray'}/>
                </div>
            }
            horizontalControls={
                <div className={'mb-8'}>
                    <PageSearchbar
                        searchString={searchString}
                        setSearchString={setSearchString}
                    />
                </div>
            }
        />
        <div className={'overflow-auto'}
            style={{
                height: `calc(100vh - ${userDevice === 'IPhone' ? '21.5rem' : '20.3rem'})`
            }}
            ref={scrollRef}
        >
            <div className={'d-flex flex-column gap-8'} style={{
                height: 'fit-content'
            }}>
                <InfoBlock
                    title={'Do you want fast results?'}
                    text={'Get BSO’s Property Management Contract (PMC) for better results. Add a new service below to get started!'}
                    isShown={showInfoBlock}
                    setIsShown={setShowInfoBlock}
                />
                {
                    myUnits.map((unit) => (
                        <LandlordUnitCard key={nanoid()} unit={unit}/>
                    ))
                }
            </div>
        </div>

    </PageLayout>
}