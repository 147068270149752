import {type ViewUnitSectionProps} from "../ViewUnitSection";
import React, {type ReactElement} from "react";
import ClosableBlock from "../../../../../UIv2/ClosableBlock/ClosableBlock";
import Table from "../../../../../UIv2/Table/Table";
import useViewUnitMarketingPrice from "./ViewUnitMarketingPrice.hook";
import LinkButton from "../../../../../UIv2/Button/LinkButton";

export default function ViewUnitSectionMarketPrice ({unit}: ViewUnitSectionProps): ReactElement {
    const {
        tableProps
    } = useViewUnitMarketingPrice(unit)
    return <ClosableBlock
        title={'Market Price'}
        closable={false}
    >
        <div className={'d-flex flex-column gap-4'}>
            <Table {...tableProps}/>
            <LinkButton text={'VIEW ALL'} size={'38'}/>
        </div>
    </ClosableBlock>
}