import { combineReducers, configureStore } from '@reduxjs/toolkit'
import counterReducer from './slices/counterSlice'
import authReducer from './slices/authSlice'
import userSlice from './slices/userSlice'
import unitsSlice from './slices/unitsSlice'
import viewingsSlice from './slices/viewingsSlice'
import offersSlice from './slices/offersSlice'
import documentSlice from './slices/documentSlice'
import modalSlice from './slices/modalSlice'
import serviceSlice from './slices/serviceSlice'
import scheduleSlice from './slices/scheduleSlice'
import voiceSlice from './slices/voiceSlice'
import chatListSlice from './slices/chatListSlice'
import chatSlice from './slices/chatSlice'
import propertySlice from './slices/propertySlice'
import paginationSlice from './slices/paginationSlice'
import agentSlice from './slices/agentSlice'
import setUnitSlice from './slices/setUnitSlice'
import appSlice from './slices/appSlice'
import advertisesSlice from './slices/advertisesSlice'
import areasSlice from './slices/areasSlice'
import swalsSlice from './slices/swalsSlice'
import tasksSlice from './slices/tasksSlice'
import hashSlice from './slices/hashSlice'

const rootReducer = combineReducers({
    counter: counterReducer,
    auth: authReducer,
    user: userSlice,
    units: unitsSlice,
    viewings: viewingsSlice,
    offers: offersSlice,
    documents: documentSlice,
    modal: modalSlice,
    service: serviceSlice,
    chat: chatSlice,
    schedule: scheduleSlice,
    property: propertySlice,
    voice: voiceSlice,
    chatList: chatListSlice,
    pagination: paginationSlice,
    agentStates: agentSlice,
    setUnit: setUnitSlice,
    app: appSlice,
    advertises: advertisesSlice,
    areas: areasSlice,
    swalsStates: swalsSlice,
    tasks: tasksSlice,
    hash: hashSlice
    // unitKeys: unitKeysSlice
})

const store = configureStore({
    reducer: rootReducer
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export { store }
