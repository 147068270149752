import { type AppDispatch, type RootState } from '../../store'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import qs from 'qs'
import { setTasks } from '../../slices/tasksSlice'

export const getAllTasks = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    try {
        const bodyObject = {
            method: 'GET',
            collection: 'tasks',
            query: qs.stringify({
                populate: {
                    Documents: true,
                    Unit: true,
                    AssignedTo: true
                }
            }, { encodeValuesOnly: true })
        }
        //  console.log(bodyObject, 'bodyObject') "?filters[Type][Number][$eq]=784199821044296&populate=deep,2"
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'BSO-Hash': '02ns30nsBSO!',
                Authorization: `Bearer ${String(jwt)}`
            },
            body: JSON.stringify(bodyObject)
        })
        //    &[Type][Number][$eq]=784199447445752
        const responseJSON = await response.json()
        //   console.log(dataJSON, 'dataJSON UUUUUUUUSSSSSSSSEEEEEEEERRRRRRRRR')
        if (responseJSON.success === true) {
            if (responseJSON.response.error == null) {
                dispatch(setTasks(responseJSON.response.data))
                return {
                    isSuccessful: true
                }
            }
        }
    } catch (error) {
        console.error(error)
    }
    return { textNavigate: '', isSuccessful: false, textNotification: '' }
}
