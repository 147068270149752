import React, { type ReactElement, useState } from 'react'
import ChatInDrawer from '../../../components/shared/newChat/chat/ChatInDrawer'
import { openChatByUserID } from '../../../store/actionsCreators/chatActions'
import { useAppDispatch } from '../../../hooks/redux'

export default function SupportInModal (): ReactElement {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [chatID, setChatID] = useState(0)
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()
    function openSupportChat (): void {
        dispatch(openChatByUserID(Number(process.env.REACT_APP_BSO_USER_ID)))
            .then(({ id }: { id: any }) => {
                if (id != null) {
                    setChatID(id)
                    console.log(id)
                    setDrawerOpen(true)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <>
            <div className="d-flex gap-2 align-items-center fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                <div className={`btn btn-secondary ${loading ? 'disabled' : ''}`}
                    onClick={() => {
                        if (chatID !== 0 && chatID != null) {
                            setDrawerOpen(true)
                        } else {
                            openSupportChat()
                        }
                    }}
                >
                    {
                        loading
                            ? <span className="indicator-progress d-block">
                                Please wait...
                                <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                            </span>
                            : <>
                                Chat with support
                            </>
                    }
                </div>
            </div>
            <ChatInDrawer
                chatID={chatID}
                open={drawerOpen}
                onClose={() => {
                    setDrawerOpen(false)
                }}
            />
        </>
    )
}
