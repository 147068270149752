import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'
import { ACCESS_KEY, USER_ID_KEY, queryUSER, urlAPI } from '../../../urls'
import { modalSlice } from '../../slices/modalSlice'
import { userSlice, type ISettings, type User } from '../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../store'

export const userUpdateNotifies = (formValues: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const currentUser = getState().user.currentUser
    const userSettings = (currentUser.Settings as ISettings[] | null)?.at(0)
    const Notifies = userSettings?.Notifies
    const actualUser = {
        ...currentUser,
        Settings:
            [{
                ...userSettings,
                Notifies: { ...Notifies, ...formValues }
            }]
    }
    try {
        const sendObject = {
            method: 'PUT',
            collection: 'users',
            id: Number(userID),
            // query: queryUSER,
            query: getQueryCurrentUser(),
            body: {
                Settings:
                 [{
                     ...userSettings,
                     Notifies: { ...Notifies, ...formValues }
                 }]
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()

        if (dataJSON.success) {
            if ((!dataJSON.response.error != null)) {
                dispatch(userSlice.actions.userUpdate(actualUser as User))
            }
        }
    } catch (error) {
        console.log(error)
    }
}
