export const ServicesConfig = {
    getService (id: string) {
        return this.config.find(service => service.id === id)
    },
    config: [
        {
            id: 'find_tenant',
            title: 'Finding a Tenant',
            topText: 'PROPERTY MANAGEMENT',
            // description: 'Duration: 06/24/25 - 07/04/25',
            priceText: '5,000 AED',
            price: 5000,
            iconSrc: '/assets/media/landlord/services/icons/finding-tenant.svg',
        },
        {
            id: 'unit_photography',
            title: 'Unit Photography',
            priceText: '1,000 AED',
            price: 1000,
            iconSrc: '/assets/media/landlord/services/icons/camera.svg'
        },
        {
            id: 'request_inspection',
            title: 'Request Inspection',
            topText: 'PROPERTY MANAGEMENT',
            priceText: '0 AED',
            price: 0,
            iconSrc: '/assets/media/landlord/services/icons/inspection.svg'
        },
        {
            id: 'present_value_ratio',
            title: 'Present Value Ratio',
            priceText: '1,000 AED',
            price: 1000,
            iconSrc: '/assets/media/landlord/services/icons/business-graph.svg'
        }
    ]
}