import React, {type ReactElement, type ReactNode} from "react";

interface PageLayoutProps {
    children: ReactNode
}

export default function PageLayout ({children}: PageLayoutProps): ReactElement {
    return <div className={'px-6 pt-8 bg-white h-100'}>
        {
            children
        }
    </div>
}