import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { type IOrder, type IPayment, type IUnit, IUser } from '../../types'
import { type IGetServices } from '../../functions/landlord/data/servicesSubm'

export interface IServicePlan {
    id: number
    oneTime: boolean
    name: string
    info: string
    price?: string
    dayString?: string
    show?: boolean
}

/** Service state for work app Landlords. */
export interface ServiceState {
    unit: IUnit
    service: IGetServices | undefined
    order: IOrder
    payment: IPayment
    plan?: IServicePlan | any
    dop: any[]
    type: string
    switchUnit: boolean
    tasks: any[]
    task: any
    vendors: any[]
    vendor: any
}

const initialState: ServiceState = {
    unit: {},
    service: undefined,
    order: {},
    payment: {},
    plan: {},
    dop: [],
    type: '',
    switchUnit: true,
    tasks: [{ id: 1, name: 'Kitchen set', area: '700', price: '700', status: 'Done' }, { id: 2, name: 'Wiring', area: 700, price: '500', status: 'In progress' }],
    task: {},
    vendors: [{ id: '1', name: 'Mehmed Abdul', company: 'Right Vendor', phone: '+97168655500', email: 'rv@rv.ae', web: 'rv.ae', price: '2500', img: '/assets/sharedMedia/test/testvendor.jpg' },
        { id: '2', name: 'Masulimi Al Gubeib', company: '24x7', phone: '+97168999547', email: 'mae@vendor24.ae', web: 'vendor24.ae', price: '1200', img: '/assets/sharedMedia/test/testvendo2.png' }],
    vendor: { id: '2', name: 'Masulimi Al Gubeib', company: '24x7', phone: '+97168999547', email: 'mae@vendor24.ae', web: 'vendor24.ae', price: '1200', img: '/assets/sharedMedia/test/testvendo2.png' }
}

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        setUnitService: (state: ServiceState, action: PayloadAction<IUnit>) => {
            state.unit = action.payload
        },
        setService: (state: ServiceState, action: PayloadAction<IGetServices | undefined>) => {
            state.service = action.payload
        },
        setOrder: (state: ServiceState, action: PayloadAction<IOrder>) => {
            state.order = action.payload
        },
        setPayment: (state: ServiceState, action: PayloadAction<IPayment>) => {
            state.payment = action.payload
        },
        setPlan: (state: ServiceState, action: PayloadAction<any>) => {
            state.plan = action.payload
        },
        setDop: (state: ServiceState, action: PayloadAction<any[]>) => {
            state.dop = action.payload
        },
        setType: (state: ServiceState, action: PayloadAction<string>) => {
            state.type = action.payload
        },
        setSwitch: (state: ServiceState, action: PayloadAction<boolean>) => {
            state.switchUnit = action.payload
        },
        setTasks: (state: ServiceState, action: PayloadAction<any[]>) => {
            state.tasks = action.payload
        },
        setTask: (state: ServiceState, action: PayloadAction<any>) => {
            state.task = action.payload
        },
        setVendors: (state: ServiceState, action: PayloadAction<any[]>) => {
            state.vendors = action.payload
        },
        setVendor: (state: ServiceState, action: PayloadAction<any>) => {
            state.vendor = action.payload
        }
    }
})

export const {
    setUnitService, setService, setOrder, setPayment, setPlan,
    setDop, setType, setSwitch, setTasks, setTask, setVendors, setVendor
} = serviceSlice.actions

export default serviceSlice.reducer
