import {type AppDispatch, type RootState} from "../../../../store";
import {
    landlordAddUnitClearStorageV2,
    landlordAddUnitReadFromStorageV2,
    landlordAddUnitWriteToStorageV2
} from "../utils/landlordAddUnitHandleStorageV2";
import dayjs from "dayjs";
import {type User} from "../../../../slices/userSlice";
import indexedDBHandler from "../../../../../functions/utils/indexedDB/indexedDBHandler";

export const landlordCreateUnitChooseRole  = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const storedData = landlordAddUnitReadFromStorageV2()
    if (storedData.landlord_create_unit_upload_title_deed?.indexedDBKey != null) {
        await indexedDBHandler.deleteFile(storedData.landlord_create_unit_upload_title_deed.indexedDBKey)
    }
    landlordAddUnitClearStorageV2()
    landlordAddUnitWriteToStorageV2('landlord_create_unit_choose_role', { role: data.role })
    const currentUser = getState().user.currentUser as User
    const userHasValidPassport = currentUser?.Documents?.some(doc => (
        doc?.Type?.[0]?.__component === 'documents.passport' &&
        dayjs(doc?.ExpiryDate).isAfter(dayjs())
    )) === true
    if (data.role === 'representative') {
        return {
            isSuccessful: true,
            textNavigate: '/landlord/modal/v2/landlord_create_unit_representative_auth_method/-/-',
            textNotification: ''
        }
    } else {
        if (!userHasValidPassport) {
            return {
                isSuccessful: true,
                textNavigate: '/landlord/modal/v2/landlord_create_unit_users_passport/-/-',
                textNotification: ''
            }
        } else {
            return {
                isSuccessful: true,
                textNavigate: '/landlord/modal/v2/landlord_create_unit_upload_title_deed/-/-',
                textNotification: ''
            }
        }
    }
}