import React, {type ReactElement, type ReactNode} from "react"

type HexColor = `#${string}`

interface CardProps {
    rounded: '4' | '8'
    style: 'white' | 'filled'
    children: ReactNode
    customBackground?: HexColor
    customBackgroundClass?: string
    height?: 'fit-content' | 'fill'
    key?: string
}

export default function Card ({rounded, style, customBackground, customBackgroundClass, children, key, height}: CardProps): ReactElement {
    let styles: React.CSSProperties = {}
    let className = ''
    switch (style) {
        case 'white':
            className = 'border border-secondary bg-white overflow-hidden'
            break
        case 'filled':
            className = 'overflow-hidden'
            if (customBackground != null) {
                styles = {
                    backgroundColor: customBackground
                }
            } else if (customBackgroundClass != null) {
                className = customBackgroundClass
            } else {
                className = 'bg-secondary'
            }
            break
        default:
            break
    }
    switch(rounded) {
        case '4':
            styles.borderRadius = '4px'
            break
        case '8':
            styles.borderRadius = '8px'
            break
        default:
            break
    }
    switch (height) {
        case 'fill':
            className += ' h-100'
            break
        default:
            break
    }
    return <div className={className} style={styles} key={key}>
        {children}
    </div>
}