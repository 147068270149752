import React from 'react'
import { ava_blank } from '../../../urls'

interface AvatarProps {
    image?: string
    name: string
    size: string
}

const Avatar = ({ image, name, size }: AvatarProps): JSX.Element => {
    return (
        /* image != null
            ? */ <div className={'rounded-circle overflow-hidden bg-image'}
            style={{
                background: `url(${image ?? ava_blank}) center center / cover no-repeat`,
                // backgroundSize: 'cover',
                width: size,
                height: size
            }}
        />
        /*: <div className={'rounded-circle overflow-hidden d-flex align-items-center justify-content-center'}
                    style={{
                        border: 'solid 1px #fff200',
                        background: '#fff200',
                        width: size,
                        height: size
                    }}
                >
                    <div className={'fs-2 text-gray-600'}>
                        {
                            name[0]
                        }
                    </div>
                </div> */
    )
}

export default Avatar
