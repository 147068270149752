import {type ViewUnitSectionProps} from "../ViewUnitSection";
import React, {type ReactElement} from "react";
import LinkCard from "../../../../../UIv2/Card/LinkCard";
import {GOOGLE_MAPS_IMAGE_URL} from "../../../../../../../constants";

export default function ViewUnitSectionMainInfo ({unit}: ViewUnitSectionProps): ReactElement {
    const property= unit?.attributes?.Property?.data?.attributes
    return <>
        <div className={'fs-2x fw-medium'}>
            <div>
                {property?.Area}, {property?.Name}, {unit?.attributes?.Number}
            </div>
            { unit?.attributes?.Marketing?.Headline != null
                ? <div>
                    {unit?.attributes?.Marketing?.Headline}
                </div>
                : <></>
            }
        </div>
        <div className={'d-flex flex-column gap-4'}>
            {
                property?.GoogleMapsLink != null && property?.GoogleMapsLink?.length > 0
                    ? <LinkCard
                        image={GOOGLE_MAPS_IMAGE_URL}
                        title={'Get unit location'}
                        description={{
                            text: 'Open link in Google Maps',
                            position: 'top'
                        }}
                        onClick={() => {
                            window.open(property.GoogleMapsLink ?? '', '_blank')
                        }}
                    />
                    : <></>
            }
            {
                unit?.attributes?.Video != null
                    ? <LinkCard
                        icon={{
                            name: 'play_arrow',
                            filled: true
                        }}
                        title={'Unit Video'}
                        description={{
                            text: 'Watch the unit video',
                            position: 'bottom'
                        }}
                        onClick={() => {
                            window.open(unit?.attributes?.Video, '_blank')
                        }}
                    />
                    : <></>
            }
        </div>
    </>
}