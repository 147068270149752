import React, {type ReactElement} from "react";
import {Controller} from "react-hook-form";
import Select from "../../../../../components/shared/UIv2/Select/Select";
import Icon from "../../../../icon/Icon";
import DateInput from "../../../../../components/shared/UIv2/Input/DateInput";

interface InputDateProps {
    row: Record<string, any>
    control: any
}

export default function InputDate ({row, control}: InputDateProps): ReactElement {
    return (
        <div className={'mb-8'}>
            <Controller
                name={row.id}
                control={control}
                defaultValue={row.defaultValue}
                rules={{
                    required: row.required === true ? `Field "${row.content[0]}" is required` : undefined
                }}
                render={({field, fieldState: { error }}) => {
                    return (
                        <div className={'d-flex flex-column gap-3 w-100'}>
                            <div className={'fs-5 fw-medium'}>
                                {row.content[0]}
                            </div>
                            <DateInput
                                placeholder={row.content[1]}
                                value={field.value}
                                onChange={field.onChange}
                            />
                            { error != null
                                ? <div className={'d-flex align-items-center justify-content-between text-danger fs-5 fw-medium'}>
                                    <div>
                                        {error.message}
                                    </div>
                                    <Icon name={'error'} className={'fs-1'} imageSize={48}/>
                                </div>
                                : <>
                                </>
                            }
                        </div>
                    )
                }}
            />
        </div>
    )
}