import React, { useState } from 'react'
import { titleDeedInstructionImage } from '../../../urls'
import { Space, Switch } from 'antd'
import { ReturnStsScan } from '../../../components/agentModule/componentsOffers/ReturnStsScan'
import Icon from '../../icon/Icon'
import { type RootState } from '../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import CustomFormLable from '../customModalUtils/CustomFormLable'
import { setCreatePropertyFields } from '../../../store/slices/propertySlice'
import { setTemporaryTitleDeed } from '../../../store/slices/documentSlice'
import { dataFetchingFinish, dataFetchingStart } from '../../../store/slices/modalSlice'
import { scanDocument } from '../../../store/actionsCreators/documentsActions/scaning/documentScan'

export default function VerificationUnitByTitleDeed({
    setValue,
    errors,
    register,
    control,
    row
}: any): JSX.Element {
    const documents = useAppSelector((state: RootState) => state.documents.documents)
    const currentTitleDeed = documents.filter((doc) => doc.id === row?.idTitleDeed)
    const dispatch = useAppDispatch()

    // const [selectedFile, setSelectedFile] = useState<any>(null)
    const [loadingLocal, setLoadingLocal] = useState(false)
    const loading = useAppSelector(state => state.auth.loading)
    const temporaryTitleDeed = useAppSelector(state => state.documents.temporaryTitleDeed)

    const [switchChecked, setChecked] = useState(false)
    const rowTitleDeed = { id: 'agent_create_unit_title_deed', content: ['Scan Title Deed'], element: 'input-file', multiple: false, required: true }
    const isValid =
        temporaryTitleDeed != null
            ? temporaryTitleDeed?.document?.valid
            : currentTitleDeed[0]?.attributes?.Valid

    const scanHandler = async (selectedFile: any): Promise<any> => {
        if (selectedFile == null) return
        setLoadingLocal(true)
        dispatch(dataFetchingStart())

        try {
            const scanDoc = await dispatch(scanDocument({ agent_edit_unit_title_deed: selectedFile }))
            //   console.log(scanDoc, 'scanDoc')
            setLoadingLocal(true)
            if (scanDoc && scanDoc !== null && scanDoc.document) {
                dispatch(setTemporaryTitleDeed(scanDoc))
                console.log(scanDoc, 'scanDoc')
                // if (scanDoc?.document?.property) {
                //     dispatch(setCreatePropertyFields(scanDoc?.document?.property))
                // }
                setLoadingLocal(false)
            }
        } catch (error) {
            console.error('Error while scanning title deed:', error)
        } finally {
            dispatch(dataFetchingFinish())
            setLoadingLocal(false)
        }
    }

    return (
        <div>
            <div className="fv-row  mt-8 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase"> Do you want add  units's Title Deed for verification ?</div>
                    </div>
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch unCheckedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>NO</span>} defaultChecked={switchChecked}
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                onChange={(ch) => { setChecked(ch) }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-4"></div>

                {
                    switchChecked
                        ? <>
                            <>
                                <p className={'text-gray-800 fs-7 text-uppercase lh-sm '}>
                                    Boost your listing’s exposure and enhance your chances of closing a deal by verifying
                                    your property. Simply scan your title deed by taking a picture or uploading a picture of
                                    the document.

                                </p>
                                <div className={'w-100 d-flex align-items-center justify-content-between'}>

                                    <label className={'form-label fw-bold mb-2 text-dark text-uppercase required'}>Title Deed</label>
                                    <div className={'form-label fw-bold text-dark text-uppercase d-flex align-items-center'
                                    }>
                                        {

                                            isValid
                                                ? <>
                                                    <Icon
                                                        name={'check_circle'}
                                                        className={'text-success fs-1'}
                                                    />
                                                    <span className='pt-1 ms-1'>Verified</span></>

                                                : <>
                                                    <Icon
                                                        name={'cancel'}
                                                        className={'text-danger fs-1'}
                                                    />
                                                    <span className='pt-1 ms-1'>Not Verified</span></>
                                        }

                                    </div>
                                </div>
                                <input
                                    {...register(rowTitleDeed.id)}
                                    name={rowTitleDeed.id}
                                    onChange={(event) => {
                                        const files = event.target.files
                                        if ((files != null) && files.length > 0) {
                                            //      setSelectedFile(files)
                                            scanHandler(files)
                                        }
                                    }}

                                    className="form-control form-control-solid form-control-lg"
                                    type="file"
                                    placeholder="Choose File"
                                />

                                {/* <input
                                    {...register(rowTitleDeed.id, { required: `Field "${rowTitleDeed.content?.[0]}" is required` })}
                                    name={rowTitleDeed.id}
                                    className="form-control form-control-solid form-control-lg"
                                    accept={undefined}
                                    type="file"
                                    placeholder="Choose File"
                                    multiple={false}
                                //    required={rowTitleDeed.required}
                                //   onChange={  handleFileChange }
                                //   onChange={(event) => {
                                // const files = event.target.files
                                // if ((files != null) && files.length > 0) {
                                //     setSelectedFile(files)
                                // }
                                //     setSelectedFile(event)
                                // }}
                                /> */}
                                {errors?.[rowTitleDeed.id] && (
                                    <div className="text-danger text-start">{errors?.[rowTitleDeed.id].message}</div>
                                )}
                                <div className="separator separator-dashed my-4"></div>
                            </>

                            {/* <div className={'text-start'}>
                                <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                    <CustomFormLable row={rowAmenities} />
                                    <CustomSelect control={control} register={register} data={rowAmenities} setValue={setValue} />
                                    {
                                        _.get(errors, rowAmenities.id) != null && (
                                            <div className="text-danger my-2">{_.get(errors, rowAmenities.id).message}</div>
                                        )
                                    }   </div>

                            </div>   */}

                        </>
                        : null
                }

            </div > </div>
    )
}
