import React, { useRef } from 'react'
import { nanoid } from 'nanoid'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { store, type RootState } from '../../../../store/store'
import _ from 'lodash'
import AreaCard from './area-card'
import { type User } from '../../../../store/slices/userSlice'
import useSaveScroll from '../../../../hooks/useSaveScroll'
import { setUnitsSearchCatalogScrollPosition } from '../../../../store/slices/unitsSlice'
import getAgentStatuses from '../../../../functions/agent/documents/checkDocumentsForAproved'
import { hideMails } from '../../../../constants'
import { USER_ID_KEY } from '../../../../urls'

const SearchUnits = (): JSX.Element => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const searchCatalogString = useAppSelector((state: RootState) => state.units.searchSearchString)
    const searchCatalogScrollPosition = useAppSelector((state: RootState) => state.units.searchCatalogScrollPosition)
    const allUsers = useAppSelector((state: RootState) => state.user.allUsers)
    const scrollRef = useRef<HTMLDivElement>(null)
    const dispatch = useAppDispatch()
    const areas = store.getState().app.config?.all.Area
        .filter((area: string) => area !== 'All areas')
        .sort((a: string, b: string) => a.localeCompare(b));

    useSaveScroll({
        scrollRef,
        position: searchCatalogScrollPosition,
        setPosition: (position: number) => dispatch(setUnitsSearchCatalogScrollPosition(position))
    })

    const filteredAreas = _.map(
        _.filter(
            areas,
            (area) =>
                _.includes(
                    area.toLowerCase(),
                    searchCatalogString.toLowerCase()
                )
        ),
        (area) => ({
            name: area,
            users: _.filter(
                allUsers,
                (user) => {
                    const hasArea = _.includes(
                        user?.Settings?.[0]?.Areas,
                        area
                    )
                    const isSystemUser = _.includes([process.env.REACT_APP_BSO_SUPPORT_USER_ID, process.env.REACT_APP_BSO_USER_ID], String(user.id))
                    const statuses = getAgentStatuses(user?.Documents ?? [])
                    const isUserVerified = (statuses?.passportApproved === true || statuses?.emiratesApproved === true) && user.emailVerified
                    const isAgent = user.Category === 'Agent'
                    const userEmail = user.email
                    return !isSystemUser && isUserVerified && hasArea && isAgent && !hideMails.includes(userEmail) && user.id !== Number(userID)
                }
            ).sort((a, b) => {
                const dateA = new Date(a?.createdAt).getTime()
                const dateB = new Date(b?.createdAt).getTime()
                return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
            })
        })
    )
    const sortedAreas = _.sortBy(
        filteredAreas,
        (area) => -area?.users?.length
    )
    return (
        <div className="content d-flex flex-column flex-fill p-4 mb-0 overflow-auto"
            ref={scrollRef}
            style={{
                height: '100%'
            }}
        // ref={scrollRef}
        >
            {
                _.map(sortedAreas, (area: { name: string, users: User[] }) => {
                    return (
                        <AreaCard area={area.name} users={area.users} key={nanoid()} />
                    )
                })
            }
        </div>
    )

    /* const { searchPage, searchPageCount, searchCatalog, searchCatalogLoading, searchCatalogScrollPosition } = useAppSelector((state: RootState) => state.units)
    const dispatch = useAppDispatch()
    const scrollRef = useRef<HTMLDivElement>(null)
    useInfinteScroll({
        scrollRef,
        page: searchPage,
        setPage: unitsSetSearchPage,
        pageCount: searchPageCount,
        direction: 'bottom',
        action: () => (dispatch: AppDispatch, getState: () => RootState) => {
            void dispatch(fetchSearchCatalogUnits(searchPage + 1))
        },
        block: searchCatalogLoading
    })
    useSaveScroll({
        scrollRef,
        position: searchCatalogScrollPosition,
        setPosition: (position: number) => dispatch(setUnitsSearchCatalogScrollPosition(position))
    })

    return (
        <>
            <div className="content d-flex flex-column flex-fill p-0 m-4 mb-0 overflow-auto"
                style={{
                    height: 'calc(100vh - 17rem)'
                }}
                ref={scrollRef}
            >

                { searchCatalog?.length > 0
                    ? (
                        <>
                            {
                                searchCatalog.map((unit: any) => {
                                    //        console.log(unit, 'UUUUUUUUUUUUUUNNNNNNNIIIIIIITTTTTTt')
                                    return (
                                        <UnitSh
                                            key={`${String(unit.id)}-${nanoid()}`}
                                            hide={true}
                                            unit={unit}
                                            fromEntry={'search'}
                                        />
                                    )
                                })
                            }
                        </>
                    )
                    : (!searchCatalogLoading
                        ? <div className="card shadow-sm my-1">
                            <div className="card-body p-0 m-5 text-center">
                                <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                                        No units for you
                                </span>
                            </div>
                        </div>
                        : <></>
                    )
                }
                {
                    searchCatalogLoading
                        ? <div className={'w-100 d-flex justify-content-center p-4'}>
                            <Spin size={'large'}/>
                        </div>
                        : <></>
                }
                <div className="d-none"></div>
            </div>

            <div className="m-0" style={{ height: '75px' }}></div>
        </>
    )
     */
}

export default SearchUnits
