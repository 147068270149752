import React, {type ReactElement, type ReactNode} from "react";
import {nanoid} from "nanoid";
import Icon from "../../../../app/icon/Icon";

interface BulletPointListProps {
    data: ReactNode[]
}

export default function BulletPointList ({data}: BulletPointListProps): ReactElement {
    return <div className={'d-flex flex-column gap-3 fs-5'}>
        {
            data.map((item) => (
                <div className={'d-flex align-items-center gap-3 px-5'}
                    key={nanoid()}
                >
                    <Icon name={'radio_button_unchecked'}
                        filled={true}
                        className={'fs-10 text-gray-400'}
                    />
                    <div>
                        {item}
                    </div>
                </div>
            ))
        }
    </div>
}