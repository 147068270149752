import React, {type ReactElement, type ReactNode} from "react";
import {nanoid} from "nanoid";

export interface TableProps {
    columns?: Array<{
        title: string
    }>
    rows: ReactNode[][]
}

export default function Table ({columns, rows}: TableProps): ReactElement {
    return <div className={'d-flex flex-column gap-4'}>
        { columns != null
            ? <div className={'row px-4'}>
                {
                    columns.map((column, index) => {
                        let alignText = ''
                        if (index === columns.length - 1) {
                            alignText = ' text-end'
                        } else if (index > 0) {
                            alignText = ' text-center'
                        }
                        return (
                            <div key={nanoid()} className={`col text-gray-600 fs-6 lh-1${alignText}`}>
                                {
                                    column.title
                                }
                            </div>
                        )
                    })
                }
            </div>
            : <></>
        }
        {
            rows.map((row) => (
                <div className={'border border-secondary py-3 px-4'}
                    key={nanoid()}
                    style={{borderRadius: '6px'}}
                >
                    <div className={'row'}>
                        {
                            row.map((ceil, index) => {
                                let justifyContent = ''
                                let paddingStart = ''
                                let paddingEnd = ''
                                if (index === row.length - 1) {
                                    justifyContent = ' justify-content-end'
                                    paddingEnd = ' pe-2'
                                } else if (index > 0) {
                                    justifyContent = ' justify-content-center'
                                } else {
                                    paddingStart = ' ps-2'
                                }
                                return <div className={`col px-0 d-flex align-items-center${justifyContent}${paddingEnd}${paddingStart}`} key={nanoid()}>
                                    {
                                        ceil
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
            ))
        }
    </div>
}