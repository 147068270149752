import React, {type ReactElement, useEffect, useRef, useState} from "react";
import {Controller} from "react-hook-form";
import Icon from "../../../../icon/Icon";
import formatFileSize from "../../../../../functions/shared/format/formatFileSize";
import IconButton from "../../../../../components/shared/UIv2/Button/IconButton";
import CardInputFileMultiplePreview from "./CardInputFileMultiplePreview";
import CardInputPreviewVideo from "./CardInputPrevideVideo";

interface CardInputFileProps {
    row: Record<string, any>
    control: any
    setError: (...args: any) => void
    getValues: any
}

export default function CardInputFile ({row, control, setError, getValues}: CardInputFileProps): ReactElement {
    const inputRef = useRef<HTMLInputElement>(null)
    const [imageUrl, setImageUrl] = useState<string | undefined>()
    useEffect(() => {
        if (row.required === true) {
            setError(row.id, 'Field is required')
        }
    }, [])
    return (
        <div className={'pb-8'}>
            <Controller
                name={row.id}
                control={control}
                rules={{
                    required: row.required === true ? 'Field is required' : undefined,
                    validate: (value) => {
                        return row.required === true ? value?.[0] != null : true
                    }
                }}
                render={({field}) => {
                    return <>
                        {row.content?.[0] != null
                            ? <div className={'fs-5 fw-medium mb-3'} id={`${String(row.id)}-label`}>
                                {row.content[0]}
                            </div>
                            : <></>
                        }
                        { row.multiple === true && field.value?.length > 0
                            ? <CardInputFileMultiplePreview
                                inputRef={inputRef}
                                field={field}
                            />
                            : row.type === 'video' && field.value?.length > 0 && ["video/mp4", "video/quicktime"].includes(field.value[0]?.type)
                                ? <CardInputPreviewVideo
                                    row={row}
                                    field={field}
                                />
                                : row.type === 'image' && field.value?.length > 0
                                    ? <div className={'w-100 d-flex align-items-center justify-content-center overflow-hidden bg-dark position-relative'}
                                        style={{
                                            borderRadius: '12px',
                                            height: row.size === 'small' ? '130px' : '186px'
                                        }}
                                    >
                                        <img
                                            src={field.value?.[0]?.url}
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%'
                                            }}
                                            alt={""}
                                        />
                                        <div className={'position-absolute'} style={{
                                            top: '9px',
                                            right: '9px'
                                        }}>
                                            <IconButton
                                                icon={'close'}
                                                style={'no-background'}
                                                customBackground={'#8080804D'}
                                                customColor={'#FFFFFF'}
                                                onClick={() => {
                                                    field.onChange(
                                                        undefined
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                    : <div className={'bg-gray-100 p-1 overflow-hidden p-8'}
                                        style={{
                                            height: row.size === 'small' ? '130px' : '186px',
                                            borderRadius: '16px'
                                        }}
                                        onClick={(e) => {
                                            if ((e as any).target.closest('.click-ignore') == null) {
                                                inputRef.current?.click()
                                            }
                                        }}
                                    >
                                        {
                                            field.value?.[0] == null
                                                ? <div className={'h-100 d-flex justify-content-center align-items-center'}>
                                                    <div className={'d-flex flex-column align-items-center gap-3 text-gray-400'}>
                                                        <Icon
                                                            name={'attach_file'}
                                                            className={ row.size === 'small' ? 'fs-4x' : 'fs-6x'}
                                                            styles={{
                                                                transform: 'rotate(-14deg)'
                                                            }}
                                                        />
                                                        <div className={'fs-4 fw-medium'}>
                                                            {
                                                                row.multiple === true
                                                                    ? 'Upload files'
                                                                    : 'Upload a file'
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className={'d-flex align-items-center justify-content-center w-100 h-100 position-relative'}
                                                    style={{
                                                        backgroundImage: imageUrl != null ? `url(${imageUrl})` : '',
                                                        backgroundPosition: 'top',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat'
                                                    }}
                                                >
                                                    <div className={'d-flex align-items-center justify-content-center position-absolute text-white click-ignore'}
                                                        style={{
                                                            top: '15px',
                                                            right: '15px',
                                                            backgroundColor: '#8080804D',
                                                            backdropFilter: 'blur(12px)',
                                                            borderRadius: '8px',
                                                            zIndex: '2'
                                                        }}
                                                        onClick={() => {
                                                            field.onChange([])
                                                            setImageUrl(undefined)
                                                        }}
                                                    >
                                                        <IconButton
                                                            icon={'close'}
                                                            style={'no-background'}
                                                        />
                                                    </div>
                                                    <div className={'d-flex flex-column align-items-center gap-3 p-8'}
                                                        style={{
                                                            backgroundColor: '#8080804D',
                                                            backdropFilter: 'blur(12px)',
                                                            borderRadius: '16px'
                                                        }}
                                                    >
                                                        <div className={'fs-4 fw-medium text-center'}>
                                                            {
                                                                field.value?.[0]?.name
                                                            }
                                                        </div>
                                                        <div className={'fs-6 fw-medium'}>
                                                            {
                                                                field.value?.[0]?.size != null
                                                                    ? formatFileSize(field.value?.[0]?.size * 8)
                                                                    : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                        }
                        <input
                            type="file"
                            onChange={(e) => {
                                if (row.multiple === true) {
                                    Array.from(e.currentTarget.files ?? []).forEach((file) => {
                                        const reader = new FileReader()
                                        reader.readAsDataURL(file)
                                        reader.onload = () => {
                                            (file as any).url = reader.result as string
                                            const prev = getValues(row.id) ?? []
                                            field.onChange([
                                                file,
                                                ...(prev as any[])
                                            ])
                                        }
                                    })
                                } else {
                                    field.onChange(e.currentTarget.files)
                                    const file = e.currentTarget.files?.[0]
                                    const reader = new FileReader()
                                    if (['image/', 'video/'].some((type) => file?.type?.startsWith(type) === true)) {
                                        reader.readAsDataURL(file as File)
                                        reader.onload = () => {
                                            (file as any).url = reader.result as string
                                            console.log(reader.result)
                                            field.onChange([file])
                                            if (file?.type?.startsWith('image/') === true) {
                                                setImageUrl(reader.result as string)
                                            } else {
                                                setImageUrl(undefined)
                                            }
                                        }
                                        reader.onerror = (err) => {
                                            console.log(err)
                                        }
                                    }
                                }
                            }
                            }
                            multiple={row.multiple === true}
                            className={'d-none'}
                            accept={
                                row.type === 'video'
                                    ? 'video/*'
                                    : row.type === 'image'
                                        ? 'image/*'
                                        : undefined
                            }
                            ref={inputRef}
                        />
                    </>
                }}
            />
        </div>
    )
}