import React, { useState } from 'react'
import CustomFormLable from '../../../app/custom/customModalUtils/CustomFormLable'
import CustomSelect from '../../../app/custom/selects/CustomSelect'
import _ from 'lodash'
import DefaultInput from '../../../app/custom/inputs/DefaultInput'
import { type RootState, store } from '../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { setTypeUnitToCreate } from '../../../store/slices/unitsSlice'

export default function StateTypeUnit({
    setValue,
    errors,
    register,
    control,
    unitID
}: any): JSX.Element {

    const type = useAppSelector(
        (state: RootState) => state.units.typeUnitToCreate
    )
    const dispatch = useAppDispatch()
    const areas = store.getState().app.config?.all.Area
        .filter((area: string) => area !== 'All areas')
        .sort((a: string, b: string) => a.localeCompare(b));

    const rowArea = { id: 'Area', content: ['Location', 'Select location'], selectors: areas, element: 'select', required: true }
    const rowName = { id: `${type}Name`, content: [`${type === 'Villa' ? 'Community' : 'Building'} name`, 'Input property name'], element: 'input', required: true }

    return (
        <>
            <div className="d-flex justify-content-evenly gap-4 w-100 scrollbar-width overflow-x-auto px-4 mb-4">

                <div className={'w-80px h-80px overflow-x-visible position-relative'} onClick={() => { dispatch(setTypeUnitToCreate('Apartment')) }} >
                    <div className={`w-80px h-80px d-flex flex-column align-items-center position-relative overflow-hidden rounded-4 ${type === 'Apartment' ? 'bg-bso-semi-back' : 'bg-light-secondary'}`}>
                        <div className="position-relative w-100 h-100 ">
                            {/* <img className="z-index-0" src={navigations[0].imgPath} alt="" style={{ height: 'calc(100% - 10px)', width: '100%' }} /> */}
                        </div>
                        <div className={'d-flex align-items-center justify-content-center w-100 px-auto py-1 position-absolute bottom-0 z-index-2 overlay '} >
                            <span className={`fs-8 text-dark text-uppercase mb-2 ${type === 'Apartment' ? 'fw-bold ' : 'fw-semibold'}  lh-1 text-center`}>

                                Apartment</span>
                        </div>
                    </div>
                </div>
                <div className={'w-80px h-80px overflow-x-visible position-relative'} onClick={() => { dispatch(setTypeUnitToCreate('Villa')) }} >
                    <div className={`w-80px h-80px d-flex flex-column align-items-center position-relative overflow-hidden rounded-4 ${type === 'Villa' ? 'bg-bso-semi-back' : 'bg-light-secondary'}`}>
                        <div className="position-relative w-100 h-100 ">
                            {/* <img className="z-index-0" src={navigations[0].imgPath} alt="" style={{ height: 'calc(100% - 10px)', width: '100%' }} /> */}
                        </div>
                        <div className={'d-flex align-items-center justify-content-center w-100 px-auto py-1 position-absolute bottom-0 z-index-2 overlay '} >
                            <span className={`fs-8 text-dark  text-uppercase ${type === 'Villa' ? 'fw-bold ' : 'fw-semibold'}  lh-1 text-center mb-2`}>

                                Villa
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'w-80px h-80px overflow-x-visible position-relative'} onClick={() => { dispatch(setTypeUnitToCreate('Commercial')) }} >
                    <div className={`w-80px h-80px d-flex flex-column align-items-center position-relative overflow-hidden rounded-4 ${type === 'Commercial' ? 'bg-bso-semi-back' : 'bg-light-secondary'}`}>
                        <div className="position-relative w-100 h-100 ">
                            {/* <img className="z-index-0" src={navigations[0].imgPath} alt="" style={{ height: 'calc(100% - 10px)', width: '100%' }} /> */}
                        </div>
                        <div className={'d-flex align-items-center justify-content-center w-100 px-auto py-1 position-absolute bottom-0 z-index-2 overlay '} >
                            <span className={`fs-8 text-dark text-uppercase mb-2 ${type === 'Commercial' ? 'fw-bold ' : 'fw-semibold'}  lh-1 text-center`}>

                                Commercial</span>
                        </div>
                    </div>
                </div>
            </div>

            <>

                <DefaultInput row={rowName} register={register} errors={errors} />

                <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                    <CustomFormLable row={rowArea} />
                    <CustomSelect control={control} register={register} data={rowArea} setValue={setValue} />
                    {
                        _.get(errors, rowArea.id) != null && (
                            <div className="text-danger my-2">{_.get(errors, rowArea.id).message}</div>
                        )
                    }
                </div>
            </>
        </>

    )
}
