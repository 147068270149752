import React, {type ReactElement} from "react";
import SimpleSelectCard from "../../../../../components/shared/UIv2/Card/SimpleSelectCard";
import {nanoid} from "nanoid";
import {Controller} from "react-hook-form";

interface ServiceAddonsProps {
    row: Record<string, any>
    control: any
    getValues: any
}

export default function ServiceAddons ({row, control, getValues}: ServiceAddonsProps): ReactElement {
    return (
        <Controller
            name={row.id}
            control={control}
            defaultValue={row.preselected ?? []}
            render={({field}) => {
                return (
                    <div className={'d-flex flex-column gap-5'}>
                        {
                            row.preselected?.length > 0
                                ? <>
                                    <div className={'fs-5 fw-medium text-gray-400'}>
                                        Preselected Services
                                    </div>
                                    {
                                        row.addons
                                            ?.filter((addon: any) => (row.preselected ?? []).includes(addon.id) === true)
                                            ?.map((addon: any) => (
                                                <SimpleSelectCard
                                                    key={nanoid()}
                                                    title={addon.title}
                                                    description={addon.description}
                                                    priceText={addon.priceText}
                                                    iconSrc={addon.iconSrc}
                                                    isActive={field.value?.includes(addon.id)}
                                                    setIsActive={() => {
                                                        const prev = getValues(row.id) ?? []
                                                        field.onChange(([...(prev as any[]), addon.id]))
                                                    }}
                                                    // setIsInactive={() => {
                                                    //     field.onChange((prev: string[]) => (prev ?? []).filter((value) => value !== addon.id))
                                                    // }}
                                                />
                                            ))
                                    }
                                    <div className={'fs-5 fw-medium text-gray-400'}>
                                        Other Services
                                    </div>
                                </>
                                : <></>
                        }
                        {
                            row.addons
                                ?.filter((addon: any) => (row.preselected ?? []).includes(addon.id) !== true)
                                ?.map((addon: any) => (
                                    <SimpleSelectCard
                                        key={nanoid()}
                                        title={addon.title}
                                        description={addon.description}
                                        priceText={addon.priceText}
                                        iconSrc={addon.iconSrc}
                                        isActive={field.value?.includes(addon.id)}
                                        setIsActive={() => {
                                            const prev = getValues(row.id) ?? []
                                            console.log(prev)
                                            field.onChange([...(prev as any[]), addon.id])
                                        }}
                                        setIsInactive={() => {
                                            const prev = getValues(row.id) ?? []
                                            field.onChange((prev as any[]).filter((value: any) => value !== addon.id))
                                        }}
                                    />
                                ))
                        }
                    </div>
                )
            }}
        />

    )
}