import {type Unit} from "../../../../../../../store/slices/unitsSlice";
import {useAppSelector} from "../../../../../../../hooks/redux";
import {type User} from "../../../../../../../store/slices/userSlice";
import dayjs from "dayjs";

type IUseServiceFindTenants = (unit: Unit) => {
    activeOrdersCount: number
}
const useLandlordUnitCardActions: IUseServiceFindTenants = (unit) => {
    const currentUser = useAppSelector((state) => state.user.currentUser) as User
    const orders = currentUser.Orders
    const activeOrders = orders?.filter((order: any) => [
        order.Unit?.id === unit.id,
        dayjs().isBefore(dayjs(order.InvoiceDueDate))
    ].every(Boolean))
    const activeOrdersCount = activeOrders?.length ?? 0
    return {
        activeOrdersCount
    }
}

export default useLandlordUnitCardActions
