import React, {type ReactElement} from "react";
import {type ViewUnitSectionProps} from "../ViewUnitSection";
import ClosableBlock from "../../../../../UIv2/ClosableBlock/ClosableBlock";

export default function ViewUnitSectionAnnualFees ({unit}: ViewUnitSectionProps): ReactElement {
    const annualServiceCharge = unit?.attributes?.AnnualServiceCharge ?? 0
    const monthlyServiceCharge = annualServiceCharge / 12
    return <ClosableBlock
        title={'Annual Fees'}
        closable={false}
    >
        <div className={'d-flex flex-column align-items-center gap-1'}>
            <div className={'d-flex gap-4 justify-content-center align-items-center'}>
                <div className={'d-flex gap-2'}>
                    <div className={'fs-4x'}>
                        {
                            annualServiceCharge?.toLocaleString('en')
                        }
                    </div>
                    <div className={'d-flex flex-column justify-content-center fs-8 lh-1'}>
                        <div>
                            AED
                        </div>
                        <div>
                            per year
                        </div>
                    </div>
                </div>
                <div className={'fs-3x fw-light text-gray-500'}>
                    /
                </div>
                <div className={'d-flex gap-2'}>
                    <div className={'fs-4x'}>
                        {
                            monthlyServiceCharge?.toLocaleString('en')
                        }
                    </div>
                    <div className={'d-flex flex-column justify-content-center fs-8 lh-1'}>
                        <div>
                            AED
                        </div>
                        <div>
                            per mo
                        </div>
                    </div>
                </div>
            </div>
            <div className={'fs-6 text-gray-600 fst-italic'}>
                Included taxes and fees
            </div>
        </div>
    </ClosableBlock>
}