import { type AppDispatch, type RootState } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { landlordAddUnitWriteToStorage } from '../utils/landlordAddUnitHandleStorage'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'
import { type NavigateFunction } from 'react-router-dom'
import { uploadStrapiFile } from '../../../fileActions'
import { nanoid } from 'nanoid'
import { type IFile } from '../../../../../types'
import Swal from 'sweetalert2'
import { swalOptions } from '../../../../../functions/swalOptions'
import { addUnit } from './addUnit'

export const addAdditionalUnitInfoFiles = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    try {
        const images: Array<IFile | null> = getState().modal.images
        const storedImages = images.filter((image) => image?.data?.includes('data:') === false)
        const notUploadedImages = images.filter((image) => image?.data?.includes('data:') === true)

        const uploadedImages = await Promise.all(notUploadedImages.map(async (image) => {
            const res: Response = await fetch(image?.data ?? '')
            const blob: Blob = await res.blob()
            const file = new File([blob], `unit-image-${nanoid()}`, { type: blob.type })
            return await uploadStrapiFile(file, 'units', null, `unit-image-${nanoid()}`)
        }))

        if (storedImages.includes(images[0])) {
            data.Images = [...storedImages, ...uploadedImages].map((image) => image?.data)
        } else {
            data.Images = [...uploadedImages, ...storedImages].map((image) => image?.data)
        }

        if (!(data.Images?.length > 0)) {
            const result = await Swal.fire({
                ...swalOptions('warning'),
                title: 'You haven\'t uploaded any images yet',
                html: 'The unit will be displayed without the images and will look less interesting for agents.<br/> <b>Do you want to upload some images?<b/>',
                showConfirmButton: 'Upload images',
                showCancelButton: 'Upload later',
                confirmButtonText: 'Upload now',
                cancelButtonText: 'Upload later'
            })
            if (result.isConfirmed) {
                return {
                    textNotification: '',
                    textNavigate: `/landlord/modal/landlord_create_unit_additional_info_files/${nanToNull(unitID) ?? '-'}/-`,
                    isSuccessful: true
                }
            }
        }

        if (data.Video?.[0] != null && typeof data.Video !== 'string') {
            const video = await uploadStrapiFile(data.Video[0], 'units', String(unitID), `unit-video-${nanoid()}`)
            data.Video = video?.data
        } else {
            data.Video = undefined
        }

        if (data.FloorPlan?.[0] != null && typeof data.FloorPlan !== 'string') {
            const floorPlan = await uploadStrapiFile(data.FloorPlan[0], 'units', String(unitID), `unit-floor-plan-${nanoid()}`)
            data.FloorPlan = floorPlan?.data
        } else {
            data.FloorPlan = undefined
        }

        landlordAddUnitWriteToStorage('additional_unit_info_files', data)
        return await dispatch(addUnit(nanToNull(unitID)))
    } catch (err) {
        console.log('Error while adding additional unit files info', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export const skipAddAdditionalUnitInfoFiles = (navigate: NavigateFunction, unitID?: number) => async () => {
    navigate(`/landlord/modal/landlord_create_unit_special_requests/${nanToNull(unitID) ?? '-'}/-`)
}

export const goBackAddAdditionalUnitInfoFiles = (navigate: NavigateFunction, unitID?: number) => () => {
    navigate(`/landlord/modal/landlord_create_unit_additional_info_outdoor/${nanToNull(unitID) ?? '-'}/-`)
}
