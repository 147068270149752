import React, {type ReactElement} from "react";
import {nanoid} from "nanoid";
import Icon from "../../../../app/icon/Icon";

interface NumericKeyboardProps {
    handleKeyPress: (key: number) => void
    handleBackspace: () => void
}

export default function NumericKeypad ({handleKeyPress, handleBackspace}: NumericKeyboardProps): ReactElement {
    const keypadConfig = [1, 2, 3, 4, 5, 6, 7, 8, 9, undefined, 0, 'del']
    return <div className={'row gy-4'}>
        {
            keypadConfig.map((key) => (
                <div className={'col-4 d-flex align-items-center justify-content-center'} key={nanoid()}>
                    { key !== 'del'
                        ? <div className={'text-gray-500 fs-4x text-center'}
                            onClick={() => {
                                if (key !== undefined) {
                                    handleKeyPress(key as number)
                                }
                            }}
                        >
                            {
                                key
                            }
                        </div>
                        : <Icon
                            name={'backspace'}
                            className={'fs-3x text-gray-400'}
                            onClick={handleBackspace}
                        />
                    }
                </div>
            ))
        }
    </div>
}