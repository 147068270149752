import React, { useEffect, useState } from 'react'
import CustomValidatorHook from '../CustomValidatorHook'
import CustomFieldsHook from '../CustomFieldsHook'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import {
    fillValuesForEdit,
    renderSetUnitInitialState,
    renderSetUnitModal
} from '../../../store/actionsCreators/setUnitActions'
import { useNavigate, useParams } from 'react-router'
import { type AppDispatch, type RootState, store } from '../../../store/store'
import { addUnit, archiveUnit, updateUnit } from '../../../store/actionsCreators/unitsActions'
import useUserType from '../../../hooks/UseUserType'
import {
    dropModalSlice,
    setDocuments,
    setDynamicDropDown,
    setImages
} from '../../../store/slices/modalSlice'

import { dropSetUnitSlice, rerenderModal } from '../../../store/slices/setUnitSlice'
import CustomModalHeader from '../CustomModalHeader'
import { swalAttentionLeave } from '../../../functions/swalOptions'
import swalConfig from '../../texts/swalTexts'
import getUserType from '../../../functions/shared/user/getUserType'
import mapPropertiesForSelect from '../../../functions/shared/properties/mapPropertiesForSelect'
import { updateValidateDocumentsTask } from '../../../store/actionsCreators/taskActions/updateValidateDocumentsTask'

const SetUnitModal = (): JSX.Element => {
    const { userType } = useUserType()
    const { modalID, unitID, objectID } = useParams()
    const formKey = useAppSelector((state) => state.setUnit.formKey)

    const { properties } = useAppSelector((state: RootState) => state.property)
    const tasks = useAppSelector((state) => state.tasks.tasks)
    const dispatch = useAppDispatch()
    const [modal, setModal] = useState([dispatch(renderSetUnitModal(modalID ?? 'set_unit_modal', objectID))])
    const initialState = dispatch(renderSetUnitInitialState())
    const navigate = useNavigate()

    useEffect(() => {
        switch (modalID) {
            case 'edit_unit_modal':
                dispatch(fillValuesForEdit(Number(unitID)))
                break
            case 'validate_unit':
                {
                    const task = tasks.find((taskItem: any) => taskItem.id === Number(objectID))
                    const documents = task?.attributes?.Documents?.data
                    const titleDeed = documents?.find((doc: any) => doc?.attributes?.Category === 'Title Deed')
                    if (titleDeed != null) {
                        dispatch(setDocuments([titleDeed.id]))
                    }
                }
                break
            default:
                break
        }
        dispatch(setDynamicDropDown(mapPropertiesForSelect(properties)))
        return () => {
            dispatch(dropModalSlice())
            dispatch(dropSetUnitSlice())
            dispatch(setImages([]))
            dispatch(setDocuments([]))
        }
    }, [])

    useEffect(() => {
        setModal([dispatch(renderSetUnitModal(modalID ?? 'set_unit_modal'))])
    }, [formKey])

    let callback: any
    let handleReset: any = () => { }
    switch (modalID) {
        case 'edit_unit_modal':
            {
                const currentUnit = store.getState().units.units.find((unit) => unit?.id === Number(unitID))
                if (currentUnit?.attributes?.Statuses?.includes('Archived') === true) {
                    modal[0].footer[0].content[0] = 'Unarchive'
                }

                handleReset = () => {
                    dispatch(archiveUnit(+(unitID ?? -1), navigate))
                    navigate(-1)
                }
                callback = (data: any) => async (dispatch: AppDispatch, getState: (state: RootState) => RootState) => {
                    const response = await dispatch(updateUnit(data, +(unitID ?? unitID ?? -1)))
                    /* response.textNavigate = -1
                    response.textNotification = '' */
                    return response
                }
            }
            break
        case 'set_unit_modal':
            callback = (data: any) => async (dispatch: AppDispatch, getState: (state: RootState) => RootState): Promise<any> => await new Promise((resolve) => {
                void dispatch(addUnit(data))
                    .then((response: any) => {
                        console.log(response)
                        if (getUserType() === 'Admin') {
                            if (response.isSuccessful === true) {
                                resolve({
                                    isSuccessful: response.isSuccessful,
                                    textNotification: 'Set up marketing for unit',
                                    textNavigate: `/${userType.toLowerCase()}/modal/set_unit_marketing_modal/${String(response.response.data?.id)}/-`
                                })
                            } else {
                                resolve({
                                    isSuccessful: false
                                })
                            }
                        } else {
                            resolve({
                                isSuccessful: true,
                                textNotification: 'Unit has been created',
                                textNavigate: `/${userType.toLowerCase()}/modalView/unit_modal/${String(response.response.data?.id)}/not/catalog`
                            })
                        }
                    })
                    .catch((err: any) => {
                        console.error(err)
                        resolve({
                            isSuccessful: false,
                            ignoreSubmit: true
                        })
                    })
            })
            break
        case 'validate_unit':
            callback = updateValidateDocumentsTask(Number(objectID), Number(unitID))
            modal[0].header = 'Validate documents and fill unit info'
            break
        default:
            break
    }
    const onLeave = (str?: string): void => {
        return void swalAttentionLeave(swalConfig.attentionLeave.messageModalFooterClose).then(async (result: any) => {
            if (result.isConfirmed) {
                str && str !== '' ? navigate(str) : navigate(-1)
            }
        })
    }

    const onCloseForAdvertiseModal = () => {

    }
    const userDevice = useAppSelector((state) => state.app.userDevice)
    return (
        <>
            <div className="page d-flex fs-5">
                <div className="content w-100 mw-450px mx-auto">
                    <div className="card d-flex mh-100 ">
                        <div className="card-header" style={{ paddingTop: userDevice === 'IPhone' ? '45px' : '14px', paddingBottom: '14px' }}>
                            <CustomModalHeader modal={modal} onLeave={onLeave} onCloseForAdvertiseModal={onCloseForAdvertiseModal} />
                        </div>

                        <div className="card-body card-scroll h-50">
                            <CustomValidatorHook id={modalID ?? ''} fromPage={unitID} unitID={unitID} objectID={objectID} callback={callback} handleReset={handleReset} initialState={initialState}>
                                <CustomFieldsHook modal={modal} isAuth={false} unitID={unitID} objectID={objectID} />
                            </CustomValidatorHook>
                            <div style={{ height: '70px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SetUnitModal
