import { type AppDispatch, type RootState, store } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { scanDocuments } from '../../../documentActions'
import { addUnit } from './addUnit'
import { landlordAddUnitWriteToStorage } from '../utils/landlordAddUnitHandleStorage'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'
import { type NavigateFunction } from 'react-router-dom'

export const scanUnitUserPassport = (unitID: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    try {
        const fileList = data.passport
        if (fileList != null && fileList?.length > 0) {
            const documentsResponse = await dispatch(scanDocuments(fileList, 'Passport', false, undefined, 'user', true))
            const passportID = documentsResponse[0].id
            const documents = getState().documents.documents
            const passport = documents.find((doc) => doc.id === passportID)
            if (passport != null) {
                landlordAddUnitWriteToStorage('user_passport', {
                    id: passportID
                })
                // return await dispatch(addUnit('user_passport_step', nanToNull(unitID)))
            }
        }
    } catch (err) {
        console.log('Error while scanning user\'s passport', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export const skipScanUnitUserPassport = (navigate: NavigateFunction, unitID?: number) => async () => {
    // const response = await store.dispatch(addUnit('user_passport_step', nanToNull(unitID)))
    // navigate(response.textNavigate)
}
