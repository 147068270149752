import { type AppDispatch, type RootState } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { landlordAddUnitWriteToStorage } from '../utils/landlordAddUnitHandleStorage'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'
import { type NavigateFunction } from 'react-router-dom'

export const addAdditionalUnitInfo = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    // const storedData = landlordAddUnitReadFromStorage()
    // const currentUser = getState().user.currentUser as User
    try {
        // const images: Array<IFile | null> = getState().modal.images
        // const storedImages = _.filter(images, (image) => !_.includes(image?.data, 'data:'))
        // const notUploadedImages = _.filter(images, (image) => _.includes(image?.data, 'data:'))
        //
        // const uploadedImages = await Promise.all(_.map(notUploadedImages, async (image) => {
        //     const res: Response = await fetch(image?.data ?? '')
        //     const blob: Blob = await res.blob()
        //     const file = new File([blob], `unit-image-${nanoid()}`, { type: blob.type })
        //     return await uploadStrapiFile(file, 'properties', null, `unit-image-${nanoid()}`)
        // }))
        //
        // if (storedImages.includes(images[0])) {
        //     data.Images = _.map([...storedImages, ...uploadedImages], (image) => image?.data)
        // } else {
        //     data.Images = _.map([...uploadedImages, ...storedImages], (image) => image?.data)
        // }
        //
        // if (data.Video?.[0] != null && typeof data.Video !== 'string') {
        //     const video = await uploadStrapiFile(data.Video[0], 'properties', String(unitID), `unit-video-${nanoid()}`)
        //     data.Video = video?.data
        // } else {
        //     data.Video = undefined
        // }
        //
        // data.MaidsRoom = data.MaidsRoom === 'Yes'
        // data.GuestBathroom = data.GuestBathroom === 'Yes'
        // data.Bathrooms = nanToNull(data.Bathrooms)
        // data.Bedrooms = nanToNull(data.Bedrooms)
        // data.SizeArea = nanToNull(data.SizeArea)
        if (data.Utilities != null) {
            data.Utilities.ACCount = nanToNull(data.Utilities.ACCount)
        }
        landlordAddUnitWriteToStorage('additional_unit_info', data)
        return {
            textNotification: '',
            textNavigate: `/landlord/modal/landlord_create_unit_additional_info_outdoor/${nanToNull(unitID) ?? '-'}/-`,
            isSuccessful: true
        }
        // if (storedData?.title_deed?.needsVerification === true) {
        //     return await dispatch(addUnit('add_info_step', nanToNull(unitID)))
        // }
        // const userIsLandlord = storedData?.title_deed?.response?.document?.owners?.some((owner: any) => compareNames(
        //     String(owner.name),
        //     {
        //         FirstName: String(currentUser?.ContactInfo?.FirstName),
        //         FamilyName: String(currentUser?.ContactInfo?.FamilyName)
        //     }
        // ))
        // if (userIsLandlord === true) {
        //     const userHasValidPassport = currentUser?.Documents?.some(doc => (
        //         doc?.Type?.[0]?.__component === 'documents.passport' &&
        //         dayjs(doc?.ExpiryDate).isAfter(dayjs())
        //     ))
        //     if (userHasValidPassport === true) {
        //         return await dispatch(addUnit('add_info_step', nanToNull(unitID)))
        //     } else {
        //         return {
        //             textNotification: '',
        //             textNavigate: `/landlord/modal/landlord_create_unit_users_passport/${nanToNull(unitID) ?? '-'}/-`,
        //             isSuccessful: true
        //         }
        //     }
        // } else {
        //     return {
        //         textNotification: '',
        //         textNavigate: `/landlord/modal/landlord_create_unit_owners_passport/${nanToNull(unitID) ?? '-'}/-`,
        //         isSuccessful: true
        //     }
        // }
    } catch (err) {
        console.log('Error while adding additional unit info', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export const skipAddAdditionalUnitInfo = (navigate: NavigateFunction, unitID?: number) => async () => {
    // const currentUser = store.getState().user.currentUser as User
    // const currentUnit = store.getState().units.units.find((unit) => unit.id === unitID)
    //
    // const userIsLandlord = currentUnit?.attributes?.Landlords?.some((owner: any) => compareNames(
    //     {
    //         FirstName: String(owner?.Landlord?.data?.attributes?.ContactInfo?.FirstName),
    //         FamilyName: String(owner?.Landlord?.data?.attributes?.ContactInfo?.FamilyName)
    //     },
    //     {
    //         FirstName: String(currentUser?.ContactInfo?.FirstName),
    //         FamilyName: String(currentUser?.ContactInfo?.FamilyName)
    //     }
    // ))
    //
    // if (userIsLandlord === true) {
    //     const userHasValidPassport = currentUser?.Documents?.some(doc => (
    //         doc?.Type?.[0]?.__component === 'documents.passport' &&
    //         dayjs(doc?.ExpiryDate).isAfter(dayjs())
    //     ))
    //     if (userHasValidPassport === true) {
    //         const response = await store.dispatch(addUnit('add_info_step', nanToNull(unitID)))
    //         navigate(response.textNavigate)
    //     } else {
    //         navigate(`/landlord/modal/landlord_create_unit_users_passport/${String(unitID)}/-`)
    //     }
    // } else {
    //     navigate(`/landlord/modal/landlord_create_unit_owners_passport/${String(unitID)}/-`)
    // }
    navigate(`/landlord/modal/landlord_create_unit_additional_info_outdoor/${nanToNull(unitID) ?? '-'}/-`)
}

export const goBackAddAdditionalUnitInfo = (navigate: NavigateFunction, unitID?: number) => () => {
    navigate(`/landlord/modal/landlord_create_main_info_second/${nanToNull(unitID) ?? '-'}/-`)
}
