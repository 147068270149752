import QueryString from 'qs'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../../slices/modalSlice'

import { type AppDispatch, type RootState } from '../../../store'
import { type IOffer, offersSlice } from '../../../slices/offersSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { setFilterNavigate } from '../../../slices/scheduleSlice'
import { getCurrentDubaiDate } from '../../../../functions/agent/date/getCurrentDateStrFormat'
import { getNameUser } from '../../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../../functions/agent/agentModuleComponent/getUnitAddres'
import { type User } from '../../../slices/userSlice'
import { sendNotification } from '../../../../functions/shared/notifications/from-agent/sendNotification'
import { toUTCWithOffset } from '../../../../functions/agent/date/toUTCWithOffset'
import { getBelongsUnitForOffer } from '../../../../functions/shared/schedule/getBelongsUnit'

export const negotiateOfferAgentToAgent = (bodyClient: any) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const oferID = bodyClient.objectID
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            dispatch(dataFetchingStart())
            const units: any = getState().units.units
            const currentUnit = units?.filter((el: any) => el.id === Number(bodyClient.unitID))?.[0]
            const currentUser = getState().user.currentUser

            const offer = getState().offers?.offers?.find((offer) => offer.id === Number(oferID))
            const isSale = currentUnit?.attributes.Marketing?.Use === 'For sale'

            const ownerUnit = getBelongsUnitForOffer(offer as IOffer)

            const offerType = {
                ...offer?.attributes?.Type?.[0],
                Offer: bodyClient.Type.Offer,
                ContractStartDate: bodyClient.Type.ContractStartDate,
                Payments: bodyClient.Type.Payments,
                ...(isSale ? {} : { Duration: bodyClient.Type.Duration }),
                AdditionalNotes: bodyClient.Type.AdditionalNotes
            }

            const bodyObject = {
                method: 'PUT',
                collection: 'offers',
                id: Number(oferID),
                query: QueryString.stringify({
                    populate: ['Type', 'Documents', 'User', 'Unit']
                }, {
                    encodeValuesOnly: true
                }),

                body: {
                    Type: [offerType],
                    Statuses: ownerUnit.type === 'MY UNIT' ? ['Negotiated by other agent'] : ['Negotiated by agent'],
                    Notify: ownerUnit.type === 'MY UNIT' ? 'Agent' : 'Other Agent'
                }
            }

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify(bodyObject)
            })
            const dataJSON = await response.json()

            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                } else {
                    const nameUser = getNameUser(currentUser as User)
                    const unitAdress = getUnitAddress(currentUnit)
                    if (Number(offer?.attributes?.AgentToAgentID) === currentUser.id) {
                        await sendNotification(toUTCWithOffset(getCurrentDubaiDate(), 1), Number(offer?.attributes?.User.data?.id), dataJSON?.response?.data?.id, 'user-offer-negotiate', `Attention kindy, ${nameUser} did offer on ${unitAdress} , please respond.`)
                    } else {
                        await sendNotification(toUTCWithOffset(getCurrentDubaiDate(), 1), Number(offer?.attributes?.AgentToAgentID), dataJSON?.response?.data?.id, 'admin-offer-negotiate', `Attention kindy, ${nameUser} did offer on ${unitAdress} , please respond.`)
                    }

                    dispatch(setFilterNavigate('offers'))
                    dispatch(dataFetchingFinish())
                    return {
                        textNavigate: '/agent/schedule',
                        isSuccessful: true,
                        textNotification: 'Wait change text',
                        title: 'Offer has been created'
                    }
                }
            } else {
                dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
                dispatch(dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.log(e)
        }
    }
}
