import React, {type ReactElement} from "react";
import {useParams} from "react-router";
import {useAppSelector} from "../../../../../hooks/redux";
import Card from "../../../../../components/shared/UIv2/Card/Card";
import SimpleSelectCard from "../../../../../components/shared/UIv2/Card/SimpleSelectCard";
import Separator from "../../../../../components/shared/UIv2/Separator/Separator";
import {nanoid} from "nanoid";
import {Controller} from "react-hook-form";

interface ServiceCheckoutProps {
    row: Record<string, any>
    control: any
}

export default function ServiceCheckout ({row, control}: ServiceCheckoutProps): ReactElement {
    const { unitID } = useParams()
    const units = useAppSelector((state) => state.units.units)
    const unit = units.find(unitItem => unitItem.id === Number(unitID))
    const property = unit?.attributes?.Property?.data?.attributes
    return (
        <Controller
            name={row.id}
            control={control}
            defaultValue={row.preselected ?? []}
            render={({field}) => {
                const activeServices = row.services?.filter((service: any) => field.value.includes(service.id))
                const subTotal: number = activeServices?.reduce((acc: number, service: any) => acc + Number(service.price), 0)
                const vat = subTotal * 0.05
                const total = subTotal + vat
                return (
                    <div className={'d-flex flex-column gap-8 pt-10'}>
                        <Card rounded={'8'} style={'white'}>
                            <div className={'p-5 d-flex flex-column'}>
                                <div className={'fs-6 text-gray-500'}>
                                    FOR RENT / {unit?.attributes?.Type?.toUpperCase()}
                                </div>
                                <div className={'fs-2 fw-medium'}>
                                    {property?.Area}, {property?.Name}, {unit?.attributes?.Number}
                                </div>
                            </div>
                        </Card>
                        <div className={'d-flex align-items-center justify-content-between'}>
                            <div className={'fs-2 fw-medium'}>
                                Ordered Services
                            </div>
                            <div className={'fs-2'}>
                                {field.value?.length ?? 0} {field.value?.length === 1 ? 'item' : 'items'}
                            </div>
                        </div>
                        <div className={'d-flex flex-column gap-5'}>
                            {
                                activeServices
                                    ?.map((service: any) => (
                                        <SimpleSelectCard
                                            key={nanoid()}
                                            title={service.title}
                                            topText={service.topText}
                                            description={service.description}
                                            priceText={service.priceText}
                                            pricePosition={'end'}
                                            isActive={false}
                                            iconSrc={service.iconSrc}
                                            setIsActive={() => {}}
                                            onDelete={
                                                service.mainService !== true
                                                    ? () => {
                                                        field.onChange((field.value ?? []).filter((serviceItem: any) => serviceItem !== service.id))
                                                    }
                                                    : undefined
                                            }
                                        />
                                    ))
                            }
                        </div>
                        <Separator/>
                        <div className={'fs-2 fw-medium'}>
                            Summary
                        </div>
                        <div className={'d-flex flex-column gap-2'}>
                            <div className={'d-flex align-items-center justify-content-between'}>
                                <div className={'fs-4'}>
                                    Subtotal
                                </div>
                                <div className={'fs-4 fw-medium'}>
                                    {subTotal.toLocaleString()} AED
                                </div>
                            </div>
                            <div className={'d-flex align-items-center justify-content-between'}>
                                <div className={'fs-4'}>
                                    VAT
                                </div>
                                <div className={'fs-4 fw-medium'}>
                                    {vat.toLocaleString()} AED
                                </div>
                            </div>
                            <div className={'d-flex align-items-center justify-content-between'}>
                                <div className={'fs-4'}>
                                    Total
                                </div>
                                <div className={'fs-3x fw-bold lh-1'}>
                                    {total.toLocaleString()} AED
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}
        />
    )
}