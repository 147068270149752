import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { nanoid } from 'nanoid'
import { Drawer } from 'antd'
import { type RootState } from '../../../store/store'
import { setIsAreasOpen, setShowPopupAddOnsAreas } from '../../../store/slices/modalSlice'
import { setUnitIDnotEnoughAreaForAdvertise } from '../../../store/slices/advertisesSlice'

interface IProps {
    children: any
    title: string

}
export default function HideButtonListAddOns ({ children, title }: IProps) {
    const showPopupAddOnsAreas = useAppSelector((state: RootState) => state.modal.showPopupAddOnsAreas)
    const dispatch = useAppDispatch()

    // const showDrawer = (): void => {
    //     dispatch(setShowPopupAddOnsAreas(true))
    // }

    const onClose = (): void => {
        dispatch(setShowPopupAddOnsAreas(false))
        dispatch(setIsAreasOpen(false))
        dispatch(setUnitIDnotEnoughAreaForAdvertise(undefined))
    }
    return (
        <>

            <div className="w-100 h-100 opacity-0"
                key={nanoid()}
                // onClick={showDrawer}
            >

            </div>

            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '80vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={onClose}

                open={showPopupAddOnsAreas}
                // key={'bottom'}
                rootStyle={{ zIndex: '1100' }}
                key={title}
                maskClosable={true}
            >
                <div key={nanoid()}
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div className="w-100 mw-450px  mx-auto">
                        {children}
                    </div>
                </div>
            </Drawer>
        </>
    )
}
