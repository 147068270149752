
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, USER_ADVERTISE_DAYS, USER_AREAS_EXTEND_COUNT, USER_ID_KEY, urlAPI } from '../../../urls'
import { modalSlice } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'
import { addAdvertise, setDebitMarketingCredits, setIsExtend, setNotEnoughMarketingCredits, setUnitIDnotEnoughAreaForAdvertise } from '../../slices/advertisesSlice'
import { makePositiveOrUnchanged } from '../../../functions/agent/date/makePositiveOrUnchanged'

import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { type User } from '../../slices/userSlice'
import { setFilterNavigate } from '../../slices/scheduleSlice'

import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'

export const createAdvertise = (formValues: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userEMAIL = getState().user.currentUser.email
    const debitMarketingCredits = getState().advertises.debitMarketingCredits
    try {
        const units: any = getState().units.units
        const unit = units?.filter((el: any) => el.id === Number(formValues.unitID))
        const name = unit[0]?.attributes?.Property?.data?.attributes?.Name ?? ''
        const location = unit[0]?.attributes?.Property?.data?.attributes?.Area ?? ''
        const unitNumber = unit[0].attributes?.Number ?? ''
        dispatch(modalSlice.actions.dataFetchingStart())
        const bodyObject = {
            method: 'POST',
            collection: 'unit-advertises',
            query: getQueryAdvertises(),  
            body: {
                Name: `${unitNumber} <-> ${name} <-> ${location}`,
                Duration: formValues.advertise_create_duration,
                User: Number(userID),
                Unit: Number(formValues.unitID),
                CreditsAmount: makePositiveOrUnchanged(debitMarketingCredits),
                Notify: 'Both',
                Statuses: ['In-process']
            }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                const currentUnit = getState().units?.units?.filter((el: any) => el.id === Number(formValues.unitID))[0]
                const currentUser = getState().user.currentUser
                const nameUser = getNameUser(currentUser as User)
                const unitAdress = getUnitAddress(currentUnit)
                dispatch(setIsExtend(false))
                dispatch(setUnitIDnotEnoughAreaForAdvertise(undefined))
                dispatch(setNotEnoughMarketingCredits(0))
                dispatch(addAdvertise(dataJSON.response.data))
                const currentDubaiDate = getCurrentDubaiDate()
                const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                //     await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON.response.data.id, 'admin-advertise-in-process', `Attention admin, ${nameUser} bought an ad for ${unitAdress}, for from ${bodyObject.body.StartDate.toLocaleDateString()} to ${bodyObject.body.EndDate.toLocaleDateString()} period.`)
                await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON.response.data.id, 'admin-advertise-in-process', `Attention admin, ${nameUser} bought an ad for ${unitAdress}, for  ${formValues?.advertise_create_duration} days period.`)
                const expirationDate = new Date(currentDubaiDate)
                expirationDate.setDate(expirationDate.getDate() + formValues?.advertise_create_duration)
                expirationDate.setDate(expirationDate.getDate() + 7)

                const responseGenerateLink = await fetch('https://bso.ae/api/app/generateDocumentsLink', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify({
                        id: Number(dataJSON.response.data.id), // Number(formValues.unitID),
                        expiryDate: expirationDate.getTime()
                    })
                })

                const responseGenerateLinkJSON = await responseGenerateLink.json()
                const responseSendMailAdvertise = await fetch('https://bso.ae/api/site/sendMail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify({
                        email: userEMAIL,
                        templateId: 'd-e749cc0a5dfd499cad0d8d15932e8129',
                        dynamicTemplateData: {
                            unit_title: currentUnit?.attributes?.Marketing?.Headline,
                            documents: responseGenerateLinkJSON?.response?.link // `${URL_MAIN_API_APP}/action?type=GetAdvertiseDocuments&id=${dataJSON?.response?.data?.id}`
                        }
                    })
                })

                const responseSendMailAdvertiseJSON = await responseSendMailAdvertise.json()
                if (responseSendMailAdvertiseJSON.success && responseSendMailAdvertiseJSON.response.status === 202) {
                    dispatch(setFilterNavigate('adverts'))
                    dispatch(setDebitMarketingCredits(0))
                    localStorage.removeItem('firstDateAdvertise')
                    localStorage.removeItem('secondDateAdvertise')
                    localStorage.removeItem(USER_ADVERTISE_DAYS)
                    window.localStorage.removeItem('choose-area-extend')
                    window.localStorage.removeItem(USER_AREAS_EXTEND_COUNT)

                    // const advertiseDateEndNotify = toUTCWithOffset(new Date(formValues.advertise_create_duration[1]), 0)
                    // if (!isToday(formValues.advertise_create_duration[0])) {
                    //     await sendNotification(advertiseDateEndNotify, Number(userID), dataJSON.response.data.id, 'user-advertise-end', `Hi admin, agent ${nameUser} bought an advertise for ${currentUnit.attributes?.Marketing?.Headline ? currentUnit.attributes?.Marketing?.Headline : 'Unit'} ${currentUnit.attributes?.Marketing?.Headline?.includes('Unit') ? '' : currentUnit?.attributes?.Number ?? ''}, for from ${bodyObject.body.StartDate.toLocaleDateString()} to ${bodyObject.body.EndDate.toLocaleDateString()} period.`)
                    // } else {
                    //     await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON.response.data.id, 'user-advertise-start', `Hi admin, agent ${nameUser} bought an advertise for ${currentUnit.attributes?.Marketing?.Headline ? currentUnit.attributes?.Marketing?.Headline : 'Unit'} ${currentUnit.attributes?.Marketing?.Headline?.includes('Unit') ? '' : currentUnit?.attributes?.Number ?? ''}, for from ${bodyObject.body.StartDate.toLocaleDateString()} to ${bodyObject.body.EndDate.toLocaleDateString()} period.`)
                    //     await sendNotification(advertiseDateEndNotify, Number(userID), dataJSON.response.data.id, 'user-advertise-end', `Hi admin, agent ${nameUser} bought an advertise for ${currentUnit.attributes?.Marketing?.Headline ? currentUnit.attributes?.Marketing?.Headline : 'Unit'} ${currentUnit.attributes?.Marketing?.Headline?.includes('Unit') ? '' : currentUnit?.attributes?.Number ?? ''}, for from ${bodyObject.body.StartDate.toLocaleDateString()} to ${bodyObject.body.EndDate.toLocaleDateString()} period.`)
                    // }

                    const areThereMoreThanFiveAdvertises = getState().advertises.areThereMoreThanFiveAvertises
                    if (!areThereMoreThanFiveAdvertises) {
                        dispatch(setFilterNavigate('adverts'))
                    }

                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return {
                        ...dataJSON,
                        textNavigate:
                         areThereMoreThanFiveAdvertises
                             ? `/agent/modalView/advertise_modal/${currentUnit.id}/${dataJSON?.response?.data?.id}/create`
                             : '/agent/schedule',

                        isSuccessful: true,
                        textNotification: `Congratulations, you have successfully purchased the marketing right to advertising the unit.
                        The unit documents with the Marketing Authorization Form have been sent to your registered email.`

                    }
                } else {
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return { ...dataJSON, textNavigate: responseSendMailAdvertiseJSON.response.status === 202 ? -1 : '', isSuccessful: false, textNotification: 'Something\'s gone wrong, try again.' }
                }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
        dispatch(modalSlice.actions.dataFetchingFinish())
    }
}

export const generateAdvertiseLink = (advID: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY)
        const responseGenerateLink = await fetch('https://bso.ae/api/app/generateDocumentsLink', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                id: advID, // Number(formValues.unitID),
                expiryDate: new Date()
            })
        })
    
        dispatch(modalSlice.actions.dataFetchingFinish())  
        const resultJSON = await responseGenerateLink.json()
        return resultJSON
    } catch (error) {
        console.log(error)
        dispatch(modalSlice.actions.dataFetchingFinish())
    }
}
