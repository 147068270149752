import React, {type ReactElement} from "react";
import {Controller} from "react-hook-form";
import Select from "../../../../../components/shared/UIv2/Select/Select";
import Icon from "../../../../icon/Icon";

interface InputSelectProps {
    row: Record<string, any>
    control: any
}

export default function InputSelect ({row, control}: InputSelectProps): ReactElement {
    let selectors = row.selectors
    if (selectors?.[0]?.value == null) {
        selectors = selectors.map((selector: string) => (
            {
                label: selector,
                value: selector
            }
        ))
    }
    return (
        <div className={row.paddingBottom === 'small' ? 'pb-4' : 'pb-8'}>

            <Controller
                name={row.id}
                control={control}
                rules={{
                    required: row.required === true ? `Field "${row.content[0]}" is required` : undefined
                }}
                defaultValue={row.defaultValue}
                render={({field, fieldState: {error}}) => {
                    return (
                        <div className={'d-flex flex-column gap-3 w-100'}>
                            <div className={'fs-5 fw-medium'}>
                                {row.content[0]}
                            </div>
                            <Select
                                placeholder={row.content[1] ?? 'Select an option'}
                                multiple={row.multiple}
                                selectors={selectors}
                                value={field.value}
                                onChange={(value) => {
                                    if (row.disabled !== true) {
                                        if (row.onChange != null) {
                                            row.onChange(value)
                                        }
                                        field.onChange(value)
                                    }
                                }}
                                disabled={row.disabled === true}
                            />
                            { error != null
                                ? <div className={'d-flex align-items-center justify-content-between text-danger fs-5 fw-medium'}>
                                    <div>
                                        {error.message}
                                    </div>
                                    <Icon name={'error'} className={'fs-1'} imageSize={48}/>
                                </div>
                                : <>
                                </>
                            }
                        </div>
                    )
                }}
            />
        </div>
    )
}