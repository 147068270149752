import React, {type ReactElement} from "react";

interface RadioButtonProps {
    isActive: boolean
}

export default function RadioButton ({isActive}: RadioButtonProps): ReactElement {
    return (
        <div className={'rounded-circle border border-gray-400 d-flex align-items-center justify-content-center'}
            style={{
                height: '20px',
                width: '20px'
            }}
        >
            <div className={`rounded-circle`}
                style={{
                    height: '8px',
                    width: '8px',
                    backgroundColor: isActive? '#2168F2' : undefined,
                    boxShadow: isActive ? '0px 0px 4px 0px #C9E4FF' : undefined
                }}
            />
        </div>
    )
}