import React, {type ReactElement} from "react";
import {Drawer} from "antd";

interface DrawerPageProps {
    children: ReactElement
    isOpen: boolean
}

export default function DrawerPage ({children, isOpen}: DrawerPageProps): ReactElement {
    return <Drawer
        placement={'bottom'}
        closable={false}
        size={'large'}
        height={'95vh'}
        open={isOpen}
        contentWrapperStyle={{
            borderTopRightRadius: '16px',
            borderTopLeftRadius: '16px',
            overflow: 'hidden'
        }}
        bodyStyle={{
            padding: 0
        }}
    >
        {
            children
        }
    </Drawer>
}