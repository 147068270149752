
import { List } from 'antd'

import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { getMonthImage } from '../../../../functions/agent/schedule/getMonthImage'
import { getPeriod } from '../../../../functions/agent/schedule/getPeriod'
import ViewingsListItem from './ViewingsListItem'
import { setViewingsListOffsetPosition, type IViewing } from '../../../../store/slices/viewingsSlice'
import Icon from '../../../../app/icon/Icon'
import { placeholders } from '../../../../urls'

interface IProps {
    agentViewings: IViewing[]
}

export default function ViewingsList({ agentViewings }: IProps): JSX.Element {
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)

    const filterNavigate = useAppSelector((state: RootState) => state.schedule.filterNavigate)
    const scrollRefToday = useRef<HTMLDivElement>(null)
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const currentDate = new Date().getDate()
    const [currentMonthYear, setCurrentMonthYear] = useState(`${currentDate}-${currentMonth}-${currentYear}`)
    const dispatch = useAppDispatch()
    const containerList = document.getElementById('root')

    const offsetPosition = useAppSelector(
        (state: RootState) => state.viewings.viewingsListOffsetPosition
    )

    useEffect(() => {
        document.getElementById(currentMonthYear)?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        })
    }, [])

    useEffect(() => {
        if (currentDate === 1) {
            setCurrentMonthYear(`${currentMonth}-${currentYear}`)
        }

        const element = document.getElementById(currentMonthYear)
        if (element != null && containerList !== null) {
            const offset = -95
            const bodyRect = containerList.getBoundingClientRect()
            const elementRect = element.getBoundingClientRect()
            const newOffsetPosition = elementRect.top - bodyRect.top + offset
            dispatch(setViewingsListOffsetPosition(newOffsetPosition))
        }

        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || containerList?.scrollTop || 0
            if (scrollTop > offsetPosition) {
                setCurrentIcon('up')
            } else {
                setCurrentIcon('down')
            }
        }

        containerList?.addEventListener('scroll', handleScroll, { passive: true })
        return () => {
            containerList?.removeEventListener('scroll', handleScroll)
        }
    }, [currentMonthYear, filterNavigate, offsetPosition])

    let periods = getPeriod(currentUser.createdAt, agentViewings)
    const [currentIcon, setCurrentIcon] = useState('down')

    const handleClick = () => {
        document.getElementById(currentMonthYear)?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        })
    }

    useEffect(() => {
        periods = getPeriod(currentUser.createdAt, agentViewings)
    }, [agentViewings])

    return (
        agentViewings?.length > 0
            ? <div >
                {agentViewings?.length > 5 && <div onClick={() => { handleClick() }} className="symbol symbol-40px bg-bso p-4 position-fixed opacity-50" style={{ bottom: '282px', right: '-3px', zIndex: 2 }}>
                    <Icon name={'keyboard_double_arrow_down'} className={'fs-4x text-dark'} styles={{ transform: currentIcon === 'up' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </div>}

                <List
                    dataSource={periods}
                    renderItem={(period: any, index: number) => {
                        const { year, month, viewings, viewingsLength } = period
                        const date = new Date(year, month)
                        const isMonthChanged = index === 0 || new Date(periods[index - 1].year, periods[index - 1].month).getMonth() !== month
                        return (
                            <>
                                {isMonthChanged && (
                                    <div id={viewingsLength < 1 && periods.length - 1 === index ? `${currentDate}-${currentMonth}-${currentYear}` : `${month}-${year}`} className='position-relative'
                                    >
                                        <img
                                            className='w-100 h-100px object-fit-cover mb-2 rounded opacity-100'
                                            src={getMonthImage(date).mounth}
                                            alt={`Month ${date.getMonth() + 1}`}
                                        />
                                        <span className="ps-4 fs-5x fw-semi-bold text-white position-absolute" style={{ top: '6px', left: '0px', textShadow: '1px 1px 10px #332d2dd9' }}>
                                            {getMonthImage(date).str}
                                        </span>
                                        <span className="d-block fs-2 min-w-30px max-w-30px text-center position-absolute rounded-0 rounded-bottom rounded-end-0 translate-middle badge badge-secondary opacity-75" style={{ top: '12px', right: '-15px' }}>
                                            {viewingsLength}
                                        </span>
                                        <span className="pe-4 fs-1x fw-semi-bold  position-absolute text-white" style={{ bottom: '6px', right: '0px' }}>
                                            {year}
                                        </span>
                                    </div>
                                )}
                                <ViewingsListItem key={period.id} {...period} myRef={scrollRefToday} />
                            </>
                        )
                    }}
                />

            </div>
            : <div className={'d-flex position-relative align-items-end'} style={{ height: '55vhs' }}>
                <img
                    className="w-75 object-fit-cover m-auto rounded-0 rounded-bottom opacity-100"
                    src={placeholders.Viewings}
                    alt={'liable_picture'}
                />
                <span className="fs-2x fw-bold text-white text-uppercase position-absolute text-center" style={{ bottom: '16%', left: '50%', transform: 'translateX(-50%)', textShadow: '1px 1px 10px #332d2dd9' }}>
                    You don’t have any active viewings yet
                </span>
            </div>
    )
}
