
import React, { useState } from 'react'

import Icon from '../../icon/Icon'
import { type Unit } from '../../../store/slices/unitsSlice'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { timeStringInScheduler } from '../../../functions/agent/date/timeStringInScheduler'
import { getDaysUntilHideDate } from '../../../functions/shared/date/getDaysUntilHideDate'
interface IProps {
    currentUnit: Unit
    broadcastID: string
}
export default function ChildBroadcast({ currentUnit, broadcastID }: IProps): JSX.Element {
    const [expanded, setExpanded] = useState(false)
    const userBroadcastMessages = useAppSelector((state: RootState) => state.chat.userBroadcastMessages)
    const currentBroadcast = userBroadcastMessages.filter((_broadcast) => _broadcast.id === Number(broadcastID))?.[0]
    const marketing = currentUnit?.attributes?.Marketing ?? null

    const handleClick = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <div className="fv-row mb-2 m-0 p-0 w-100">
                <div className="d-flex align-items-center">
                    <div className="fs-3 fw-bolder text-dark mb-4 lh-1">   Broadcast Information</div>
                    {currentBroadcast?.createdAt
                        ? <div className="fv-row mb-2 m-0 p-0 w-100 text-end">
                            <label className="form-label fs-5 fw-bold text-dark m-0">
                                Created date
                            </label>
                            <div className="fw-normal text-dark mb-2">
                                <div className="badge badge-light-primary border border-primary fs-6 mb-1">{timeStringInScheduler(currentBroadcast?.createdAt) ?? ''}</div>
                            </div>
                        </div>
                        : null
                    }
                </div>

                {currentBroadcast?.HideDate && getDaysUntilHideDate(currentBroadcast?.HideDate) &&
                    <div className="d-flex align-items-center">
                        <div className="fs-4 fw-bold text-dark mb-4 lh-1">  Last days</div>
                        <div className="fv-row mb-2 m-0 p-0 w-100 text-end">
                            <div className="fw-normal text-dark mb-2">
                                <div className="badge badge-light-primary border border-primary fs-6 mb-1">{getDaysUntilHideDate(currentBroadcast?.HideDate) ?? ''}</div>
                            </div>
                        </div>

                    </div>
                }

                <div className="d-flex align-items-center">
                    <div className="fs-4 fw-bold text-dark mb-4 lh-1">Views</div>
                    <div className="fv-row mb-2 m-0 p-0 w-100 text-end">
                        <div className="fw-normal text-dark mb-2">
                            <div className="badge badge-light-primary border border-primary fs-6 mb-1">{0}</div>
                        </div>
                    </div>

                </div>

                <div className="fv-row my-2  p-2 w-100">
                    <div className="separator border border-bso border-3 px-2 my-2"></div>
                </div>

            </div >

            <div className="d-flex align-items-start mb-0 px-3">
                <span className="text-gray-900 fs-3x me-0 mt-1 lh-sm">
                    {marketing?.Headline}
                </span>
            </div>
            <div className="d-flex align-items-end justify-content-end mb-2 px-3">
                <span className="text-gray-900 fs-2 me-2 mb-1">AED</span>
                <span className="text-gray-900 fs-3x me-0">{marketing?.Price}</span>
            </div>

        </>
    )
}
