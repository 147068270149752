export default function formatFileSize (size: number): string {
    const units = ['bits', 'Bytes', 'Kb', 'Mb', 'Gb', 'Tb']
    let bytes
    let index = 0
    if (size < 8) {
        bytes = size
    } else {
        index++
        bytes = size / 8
        while (bytes >= 1024 && index < units.length - 1) {
            bytes /= 1024
            index++
        }
    }
    return `${Math.ceil(bytes)} ${units[index]}`
}
