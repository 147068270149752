export default {
    async openDatabase(storageName: string): Promise<any> {
        return await new Promise((resolve, reject) => {
            const request = indexedDB.open('fileDatabase', 1);

            request.onupgradeneeded = (event: any) => {
                const db = event.target.result;
                if (db.objectStoreNames.contains(storageName) === false) {
                    db.createObjectStore(storageName, { keyPath: 'id' });
                }
            };

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    },
    async saveFile(file: File, id: string) {
        const db = await this.openDatabase('files');

        return await new Promise((resolve, reject) => {
            const transaction = db.transaction('files', 'readwrite');
            const store = transaction.objectStore('files');

            const fileData = {
                id,
                fileBlob: file,
                name: file.name,
                type: file.type,
                lastModified: file.lastModified
            };

            const request = store.put(fileData);

            request.onsuccess = () => {
                console.log('File saved to IndexedDB');
                resolve(true);
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    },
    async getFile(id: string): Promise<File | null> {
        const db = await this.openDatabase('files');

        return await new Promise((resolve, reject) => {
            const transaction = db.transaction('files', 'readonly');
            const store = transaction.objectStore('files');
            const request = store.get(id);

            request.onsuccess = (event: any) => {
                const fileData = event.target.result;
                if (fileData != null) {
                    const file = new File([fileData.fileBlob], fileData.name, {
                        type: fileData.type,
                        lastModified: fileData.lastModified,
                    });
                    resolve(file);
                } else {
                    resolve(null);
                }
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    },
    async deleteFile(id: string) {
        const db = await this.openDatabase('files');

        return await new Promise((resolve, reject) => {
            const transaction = db.transaction('files', 'readwrite');
            const store = transaction.objectStore('files');
            const request = store.delete(id);

            request.onsuccess = () => {
                console.log(`File with id ${id} deleted from IndexedDB`);
                resolve(true);
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    }
}