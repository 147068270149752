import Swal from 'sweetalert2'
import { swalOptions } from '../../swalOptions'
import { type NavigateFunction } from 'react-router-dom'
import { store } from '../../../store/store'

const animationOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        className: 'w-150px h-150px mb-1'
    }
}
export const showAdvertisesSwals = (advert: any, navigate: NavigateFunction, iconText: string) => {
    const advertiseID = advert.split(',')[0]
    const advertises = store.getState().advertises.advertises
    const currentAdvertise = advertises.filter((ad) => ad.id === Number(advertiseID))?.[0]

    void Swal.fire(
        {
            ...swalOptions('warning'),
            ...{
                title: 'Attencion',
                html: 'After receiving the unit documents, please send links to the advertisements you have posted',
                showConfirmButton: true,
                showDenyButton: true,
                denyButtonText: 'Add links now',
                confirmButtonText: 'Ok, may do it later',

                iconHtml: `<div id="lottie-icon-${iconText}"></div>`,
                didOpen: () => {
                    const lottieContainer = document.getElementById(`lottie-icon-${iconText}`)
                    if (lottieContainer != null) {
                        lottie.loadAnimation({
                            container: lottieContainer,
                            renderer: 'svg',
                            ...{ ...animationOptions, animationData: require(`../../../animations/${iconText}.json`) }
                        })
                    }
                }

            }
        }

    ).then(async (result: any) => {
        if (result.isDenied) {
            navigate(`/agent/modalView/advertise_modal/${currentAdvertise?.attributes?.Unit?.data?.id}/${currentAdvertise?.id}/not`)
        }
    })
}
