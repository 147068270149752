import React from 'react'
import LandlordHeader from '../../LandlordHeader'
import ActionCard from '../actionCard'
import { useAppSelector } from '../../../../hooks/redux'
import { useParams } from 'react-router'
import { type RootState } from '../../../../store/store'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

const AdvertiseActions = (): JSX.Element => {
    const { unitID, advertiseID } = useParams()
    const advertises = useAppSelector((state: RootState) => state.advertises.advertises)
    const advertise = _.find(advertises, (advertise) => advertise.id === Number(advertiseID))
    const status = advertise?.attributes?.Statuses[advertise?.attributes?.Statuses?.length - 1]
    const navigate = useNavigate()
    return <>
        <LandlordHeader active={'Advertise actions'}/>
        <div className={'d-flex flex-column gap-4 p-4'}>
            { (status === 'Links added' || status === 'Advertisement started') &&
                <ActionCard
                    title={'Check links'}
                    description={'Check that advertisement links are correct'}
                    onClick={() => {
                        navigate(`/landlord/modal/check_advertisement_links_modal/${unitID ?? '-'}/${advertiseID ?? '-'}`)
                    }}
                    icon={'check_circle'}
                />
            }
            { status === 'Ads removed' &&
                <ActionCard
                    title={'Check links'}
                    description={'Check that advertisement links are removed'}
                    onClick={() => {
                        navigate(`/landlord/modal/check_advertisement_links_removed_modal/${unitID ?? '-'}/${advertiseID ?? '-'}`)
                    }}
                    icon={'check_circle'}
                />
            }
            { status === 'A Form added' &&
                <ActionCard
                    title={'Check A Form'}
                    description={'Check A Form for the advertise'}
                    onClick={() => {
                        navigate(`/landlord/modal/check_a_form_modal/${unitID ?? '-'}/${advertiseID ?? '-'}`)
                    }}
                    icon={'check_circle'}
                />
            }
        </div>
    </>
}

export default AdvertiseActions
