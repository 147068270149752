import React from 'react'

import { Link } from 'react-router-dom'
import { Collapse, Drawer, type CollapseProps } from 'antd'

import { useDispatch } from 'react-redux'
import { setButtonDriverAddOnsParent, setButtonDriverExtendLocation } from '../../../store/slices/modalSlice'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'

import AgentSmalBtn from '../../shared/elements/buttons/AgentSmalBtn'
import ActionLoadEmiratesID from '../../shared/elements/buttons/ActionLoadEmiratesID'
import ActionLoadPassport from '../../shared/elements/buttons/ActionLoadPassport'
import ActionLoadRera from '../../shared/elements/buttons/ActionLoadRera'
import { setNotEnoughMarketingCredits } from '../../../store/slices/advertisesSlice'
import AddOnsServiceBricks from '../addOnsServices/AddOnsServicesCards/AddOnsServiceBricks'
import AddOnsServiceArea from '../addOnsServices/AddOnsServicesCards/AddOnServicesArea'

interface IProps {
    userID: number
    loadSts: boolean
}

export default function AgentProfileSettings({ userID, loadSts }: IProps): JSX.Element {
    const dispatch = useDispatch()

    const buttonDriverAddOnsParent = useAppSelector((state: RootState) => state.modal.buttonDriverAddOnsParent)
    const buttonDriverExtendLocation = useAppSelector((state: RootState) => state.modal.buttonDriverExtendLocation)

    const isDev = process.env.REACT_APP_IS_DEV === 'true' || window.localStorage.getItem('BSO_DEVELOP_MODE') === 'true'

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="card-title fs-3 fw-bold fw-bold p-0 m-0 ">Profile</span>,
            children: <div className="card-body p-0 m-0">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Avatar</div>
                    </div>
                    <div className="ms-auto">
                        <Link

                            to={`/agent/modal/agent_avatar/${userID}/${userID}`}
                            className="text-decoration-none "
                        >
                            <div
                                className="btn btn-sm fs-6 btn-light-secondary  border border-dark text-dark me-2">Update
                            </div>
                        </Link>

                    </div>
                </div>
                <div className="separator separator-dashed my-6"></div>
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">My Documents</div>
                    </div>

                    <div className="ms-auto">
                        <AgentSmalBtn title={'Update'}>
                            <ActionLoadEmiratesID />
                            <ActionLoadPassport />
                            <ActionLoadRera />
                        </AgentSmalBtn>

                    </div>

                </div>
                {loadSts && <>  <div className="separator separator-dashed my-6"></div>

                    <div className="d-flex flex-wrap align-items-center">
                        <div >
                            <div className="fs-6 fw-bold mb-1 text-uppercase">Active areas</div>
                        </div>
                        <div className="ms-auto">


                            <div onClick={() => { dispatch(setButtonDriverExtendLocation(true)) }}
                                className="btn btn-sm fs-6 btn-light-secondary  border border-dark text-dark me-2">Update
                            </div>

                        </div>
                    </div>

                    {isDev
                        ? <>
                            <div className="separator separator-dashed my-6"></div>
                            <div className="d-flex flex-wrap align-items-center mb-2">
                                <div className="fs-6 fw-bold mb-1 text-uppercase">Black list</div>
                                <div className="ms-auto">
                                    <div onClick={() => {
                                        console.log('BLACK LIST')
                                    }
                                    } className="btn btn-sm fs-6 btn-light-secondary border border-dark text-dark me-2">Update</div>
                                </div>
                            </div>
                        </>
                        : null}

                </>}

            </div>

        }
    ]

    return (
        <div className="card shadow-sm mb-2">
            <Collapse defaultActiveKey={['1']} items={items} />
            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '88vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={() => { dispatch(setButtonDriverAddOnsParent(false)); dispatch(setNotEnoughMarketingCredits(0)) }}
                open={buttonDriverAddOnsParent}
                rootStyle={{ zIndex: '1100' }}
                key={'parent-driver-bricks'}
                maskClosable={true}
            >
                <div key={'child-driver-bricks'}
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div className="w-100 mw-450px  mx-auto">
                        <AddOnsServiceBricks />
                    </div>
                </div>
            </Drawer>
            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '88vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={() => { dispatch(setButtonDriverExtendLocation(false)) }}
                open={buttonDriverExtendLocation}
                rootStyle={{ zIndex: '1100' }}
                key={'parent-driver-areas'}
                maskClosable={true}
            >
                <div key={'child-driver-areas'}
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div className="w-100 mw-450px  mx-auto">
                        <AddOnsServiceArea />
                    </div>
                </div>
            </Drawer>


        </div>
    )
}
