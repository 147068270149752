import React, {type ReactElement} from "react";
import IconButton from "../Button/IconButton";

interface SimpleSelectCardProps {
    title: string
    description: string
    topText?: string
    priceText?: string
    iconSrc: string
    isActive: boolean
    setIsActive: () => void
    setIsInactive?: () => void
    onDelete?: () => void
    pricePosition?: 'end' | 'bottom'
}

export default function SimpleSelectCard ({title, description, topText, priceText, iconSrc, isActive, setIsActive, setIsInactive, pricePosition = 'bottom', onDelete}: SimpleSelectCardProps): ReactElement {
    return (
        <div className={`d-flex align-items-center gap-5 p-5 border border-radius-8px position-relative ${isActive ? 'border-dark' : 'border-gray-300'}`}
            onClick={!isActive ? setIsActive : setIsInactive}
        >
            {
                onDelete != null
                    ? <div className={'position-absolute'}
                        style={{
                            top: 6,
                            right: 6
                        }}
                    >
                        <IconButton
                            style={'gray'}
                            icon={'close'}
                            onClick={onDelete}
                        />
                    </div>
                    : <></>
            }
            <div className={'align-self-stretch'}>
                <div className={'bg-gray-200 d-flex align-items-center justify-content-center'}
                    style={{
                        height: '32px',
                        width: '32px',
                        borderRadius: '50%'
                    }}>
                    <img src={iconSrc} width="20" alt=""/>
                </div>
            </div>
            <div className={'d-flex flex-column gap-2'}>
                {topText != null
                    ?  <div className={'fs-6 text-gray-500'}>
                        {
                            topText
                        }
                    </div>
                    : <></>
                }
                <div className={'fs-4'}>
                    {
                        title
                    }
                </div>
                {description != null
                    ? <div className={'fs-6 text-gray-500'}>
                        {
                            description
                        }
                    </div>
                    : <></>
                }
                {priceText != null && pricePosition === 'bottom'
                    ? <div className={'fs-4 fw-medium'}>
                        {
                            priceText
                        }
                    </div>
                    : <></>
                }
            </div>
            {priceText != null && pricePosition === 'end'
                ? <div className={'fs-4 fw-medium text-end flex-grow-1'}>
                    {
                        priceText
                    }
                </div>
                : <></>
            }
        </div>
    )
}