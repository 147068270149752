import React, {type ReactElement} from "react";
import Card from "../Card/Card";
import Icon from "../../../../app/icon/Icon";

interface PageSearchbarProps {
    searchString: string
    setSearchString: (value: string) => void
}

export default function PageSearchbar ({searchString, setSearchString}: PageSearchbarProps): ReactElement {
    return <Card style={'filled'} rounded={'8'} customBackgroundClass={'bg-gray-200'}>
        <div className={'px-4 py-2 d-flex align-items-center gap-2 text-gray-600'}>
            <Icon name={'search'} className={'fs-1'}/>
            <input
                type="text"
                className={'bg-none border-0 fw-medium w-100'}
                style={{
                    outline: 'none',
                    boxShadow: 'none'
                }}
                placeholder={'Search'}
                value={searchString}
                onInput={(e) => {
                    setSearchString(e.currentTarget.value)
                }}
            />
        </div>
    </Card>
}