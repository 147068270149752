import React from 'react'
import GetDescriptions from './GetDescriptions'
import subscriptionTexts from '../../../../app/texts/subscriptionTexts'
import { useAppSelector } from '../../../../hooks/redux'

interface IProps {
    selectedSubscription: string
    selectedPeriod: string
    activeSubPeriod: string
    activeSub: string
    setSelectedSubscription: React.Dispatch<React.SetStateAction<string>>
    handleSubscriptionChange: (renew?: string) => void
    isDev?: boolean
}

export default function StartSub({ activeSubPeriod, handleSubscriptionChange, activeSub, selectedPeriod, selectedSubscription, setSelectedSubscription, isDev }: IProps): JSX.Element {
    const loading = useAppSelector(state => state.modal.loading)
    if (selectedPeriod === 'annual') {
        return (<></>)
    } else {
        return (
            <div className={`card card-dashed p-2  mb-4 bg-light-primary  ${selectedSubscription === 'Free' ? 'border border-primary' : ''} `} onClick={() => { setSelectedSubscription('Free') }}>
                {/* ${selectedSubscription === 'Free' ? 'bg-primary' : ''} */}
                <div className="d-flex justify-content-between align-items-center mb-1 rounded p-2 mb-2">
                    <div className="d-flex align-items-center me-2 ">
                        <span className={`badge badge-lg border border-5  badge-circle badge-light-success me-6 ${activeSub === 'Free' && activeSubPeriod === selectedPeriod ? 'border-success' : 'border-secondary'}`}></span>
                        <div className="flex-grow-1">
                            <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                                {subscriptionTexts.free_plane.title}
                            </div>
                            <div className="fw-semi-bold opacity-75 lh-sm ">

                                {subscriptionTexts.free_plane.description}
                                {/* { selectedPeriod === 'weekly' ? '1-week trial' : '1-month trial'} */}
                                {/* features  */}
                            </div>
                        </div>
                    </div>

                    <div className="ms-5">
                        <span className="mb-2 me-1">AED</span>
                        <span
                            className="fs-3x fw-bold"
                        >
                            {selectedPeriod === 'annual' ? '0' : selectedPeriod === 'weekly' ? '0' : '0'}
                        </span>

                        <span className="fs-7 opacity-50">/<span>
                            {selectedPeriod === 'annual' ? 'Year' : selectedPeriod === 'weekly' ? 'week' : 'Mon'}
                        </span>
                        </span>
                        {activeSub === 'Free' && activeSubPeriod === selectedPeriod
                            ? <div className="text-end">
                                <div className="badge badge-primary text-end fs-7" >Active</div>
                            </div>
                            : null

                        }
                    </div>
                </div>

                {selectedSubscription === 'Free' && (
                    <GetDescriptions
                        activeSub={activeSub}
                        activeSubPeriod={activeSubPeriod}
                        selectedPeriod={selectedPeriod}
                        selectedSubscription={selectedSubscription}
                        setSelectedSubscription={setSelectedSubscription}
                        isDev={isDev}
                    />
                )}

                {selectedSubscription === 'Free' && (
                    activeSub !== 'Free'
                        ? <div className="w-100 mx-auto py-1" >
                            <button className="btn btn-bso py-3 w-100 fs-5" onClick={() => { handleSubscriptionChange() }}>
                                {loading
                                    ? <span className="indicator-progress  d-block">
                                        Please wait...
                                        <span className={'spinner-border spinner-border-sm align-middle ms-2'}></span>
                                    </span>
                                    : <span className="indicator-label">
                                        Subscribe
                                    </span>
                                }

                            </button>
                        </div>
                        : null
                )}
            </div>

        )
    }
}
