import { type UserType } from '../../../hooks/UseUserType'
import { store } from '../../../store/store'
import { USER_CATEGORY_KEY, USER_ID_KEY } from '../../../urls'

type UserTypeExpanded = UserType | 'Admin'
interface GetUserTypeOptions {
    checkForAdmin: boolean
}
const BSO_USER_ID = Number(process.env.REACT_APP_BSO_USER_ID ?? '57')
export default function getUserType (options?: GetUserTypeOptions): UserTypeExpanded {
    const checkForAdmin = options?.checkForAdmin ?? true
    const userID = store.getState().user.currentUser.id ?? Number(window.localStorage.getItem(USER_ID_KEY))
    const userCategory = store.getState()?.user?.currentUser?.Category ?? window.localStorage.getItem(USER_CATEGORY_KEY)
    if (userID === BSO_USER_ID && checkForAdmin) {
        return 'Admin'
    } else {
        return (userCategory ?? 'Unknown') as UserType
    }
}
