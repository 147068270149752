
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { generateBgAndBorderColFromOfferMessage } from '../../../../functions/agent/schedule/generateBgAndBorderCol'
import { nanoid } from 'nanoid'
import { type RootState } from '../../../../store/store'
import UseUserType, { type UserType } from '../../../../hooks/UseUserType'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
import Icon from '../../../../app/icon/Icon'
import { type User } from '../../../../store/slices/userSlice'
import { type Unit } from '../../../../store/slices/unitsSlice'
import { getStatusBroadcast } from '../../../../functions/shared/date/getStatusBroadcast'
import { getDaysUntilHideDate } from '../../../../functions/shared/date/getDaysUntilHideDate'
import ProfileCard from '../../search/search-results/profile-card'
import Avatar from '../../elements/Avatar'
import { openChatByUserID } from '../../../../store/actionsCreators/chatActions'

interface IProps {
    currentBroadcast: any
}
export default function BroadcastClientCard({ currentBroadcast }: IProps): JSX.Element {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    console.log(currentBroadcast, 'currentBroadcast')
    const { userType, userTypeLowercase } = UseUserType()
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const allUsers = useAppSelector((state: RootState) => state.user.allUsers)
    const storeUnits = useAppSelector((state: RootState) => state.units.units)
    const isUnitsBSOIds = storeUnits
        .filter((__unit) => __unit.attributes.ManagedBy === 'BSO')
        .map((_unit) => _unit.id)

    const agentUnitsIds = (((currentUser as User)?.Units) != null) && Array.isArray(currentUser?.Units) && currentUser?.Units?.length > 0
        ? currentUser?.Units.map((_unit: Unit) => _unit.id)
        : []

    const task = {
        type: 'TYPE UNIT',
        color: 'secondary',
        redirect: 'catalog'
    }

    if (currentBroadcast?.Unit?.attributes?.User?.data?.id === Number(userID)) {
        task.type = 'MY UNIT'
        task.color = 'primary'
        task.redirect = 'my_unit'
    }
    if (currentBroadcast?.Sender === Number(userID) && isUnitsBSOIds.includes(currentBroadcast?.Unit?.attributes?.id as number)) {
        task.type = 'BSO UNIT'
        task.color = 'warning'
        task.redirect = 'catalog'
    } if (currentBroadcast?.Sender === Number(userID) && !agentUnitsIds.includes(currentBroadcast?.Unit?.attributes?.id as number) && !isUnitsBSOIds.includes(currentBroadcast?.Unit?.attributes?.id as number)) { // && currentOffer?.attributes?.AgentToAgentID && currentOffer?.attributes?.AgentToAgentID !== Number(userID)
        task.type = 'OTHER AGENT UNIT'
        task.color = 'info'
        task.redirect = 'other_agent_s_unit'
    }

    const currentUnit = useAppSelector(
        (state: RootState) => state.units.units
    )?.filter(
        (unit) => unit.id === Number(currentBroadcast?.Unit?.attributes?.id)
    )[0]

    const [unitNumber, buildName, location] = [
        currentUnit?.attributes?.Number ?? 'Not number',
        currentUnit?.attributes?.Property?.data?.attributes?.Name ?? 'Not title',
        currentUnit?.attributes?.Property?.data?.attributes?.Area ?? 'Not location'
    ]

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // View: 'Beach',
    // Features: [
    //     'Close to metro'
    // ],
    // Amenities: [
    //     'Gym',
    //     'Pool'
    // ],
    // Appliances: [
    //     'Build-in kitchen',
    //     'Oven',
    //     'Fridge'
    // ],

    return (
        <>

            <div
                className={'card shadow-sm  position-relative'}
            >

                <div className="h-100 my-2">

                    {/* <div className="text-gray-800 fs-3 fw-bold text-end p-3 me-4">Client preferences</div> */}
                    <div className="fs-7 fw-semibold text-gray-700 text-uppercase text-end me-4 ">Unit details</div>
                    <div className="d-flex gap-6 px-4 ">
                        <div className="col d-flex align-items-center">
                            <div className="symbol symbol-30px symbol-circle me-3">
                                <span className="symbol-label border border-dark">
                                    <Icon name={'person'} className={'text-dark fs-2x'} />
                                </span>
                            </div>
                            <div className="m-0 lh-2">
                                <span className="fw-semibold text-gray-500 d-block fs-8">Fit</span>
                                <div className="fw-bold text-gray-800 text-hover-primary fs-7 lh-1">Semi Furnished</div>
                            </div>
                        </div>
                        <div className="col d-flex align-items-center">
                            <div className="symbol symbol-30px symbol-circle me-3">
                                <span className="symbol-label border border-dark bg-success">
                                    <Icon name={'monetization_on'} className={'text-dark fs-2x'} />
                                </span>
                            </div>
                            <div className="m-0 lh-1">
                                <span className="fw-semibold text-gray-500 d-block fs-8">Budget</span>
                                <span className="fw-bold text-gray-800 fs-7 lh-1">$64.800</span>
                            </div>
                        </div>
                    </div>

                    <div className="separator separator-dashed border-muted my-2 mx-4"></div>
                    <div className="d-flex gap-6 px-4 ">
                        <div className="col d-flex align-items-center">
                            <div className="symbol symbol-30px symbol-circle me-3">
                                <span className="symbol-label border border-dark">
                                    <Icon name={'bed'} className={'text-dark fs-2x'} />
                                </span>
                            </div>
                            <div className="m-0 lh-2">
                                <span className="fw-semibold text-gray-500 d-block fs-8">Bedrooms</span>
                                <div className="fw-bold text-gray-800 text-hover-primary fs-7 lh-1">2</div>
                            </div>
                        </div>
                        <div className="col d-flex align-items-center">
                            <div className="symbol symbol-30px symbol-circle me-3">
                                <span className="symbol-label border border-dark">
                                    <Icon name={'info'} className={'text-dark fs-2x'} />
                                </span>
                            </div>
                            <div className="m-0 lh-1">
                                <span className="fw-semibold text-gray-500 d-block fs-8">Type</span>
                                <span className="fw-bold text-gray-800 fs-7 lh-1">Residential</span>
                            </div>
                        </div>
                    </div>

                    <div className="p-3">
                        <div className="fs-7 fw-semibold text-gray-700 text-uppercase text-end me-4 ">AREAS</div>
                        <div className="d-flex text-gray-700 fw-semibold fs-7 lh-sm">
                            <span className="border border-2 border-gray-400 rounded me-1 p-1 px-2 ">Dubai Marina</span>
                            <span className="border border-2 border-gray-400 rounded me-1 p-1 px-2">JLT</span>
                            <span className="border border-2 border-gray-400 rounded me-1 p-1 px-2">Business Bay </span>
                        </div>
                    </div>
                </div>
                <div className="separator separator-dashed border-muted my-2 mx-4"></div>
                <div className="mb-6 px-2">
                    <div className="fs-7 fw-semibold text-gray-700 text-uppercase text-end me-4 ">Preferences</div>
                    {/* <div className="fw-semibold text-gray-800 fs-6 mb-4 lh-sm">
                                    If you have any listings that meet these criteria, please get in touch. Thank you!
                                </div> */}
                    <blockquote className="blockquote lh-sm fs-7 px-1">
                        <p>
                            Hello!
                            I'm searching for a 2-bedroom apartment in a safe and convenient neighborhood. Here are my requirements:
                            Budget: Up to $1,500 per month
                            Bedrooms: 2
                            Living Room: Spacious living room required
                            Amenities: Preferably with a gym and a swimming pool
                            If you have any listings that meet these criteria, please get in touch. Thank you!</p>
                    </blockquote>
                    <div className="separator separator-dashed border-muted my-2 mx-4"></div>
                    <div className="fs-7 fw-semibold text-gray-700 text-uppercase text-end me-4">Autor</div>
                    <div className="d-flex mb-2 px-2">
                        <Avatar image={allUsers[7]?.Type?.[0]?.Photo ?? undefined} name={allUsers[7]?.ContactInfo?.FirstName ?? ''} size={'5rem'} />
                        <div className={'d-flex flex-column ms-2 ps-1'} style={{ width: 'calc(100% - 6rem)' }}>
                            <div className={'fw-bold fs-3 text-gray-900 text-truncate'}>
                                {allUsers[7]?.ContactInfo?.FirstName} {allUsers[7]?.ContactInfo?.FamilyName}
                            </div>
                            <span className="fs-6 text-gray-800 fw-semibold">Russia</span>
                            <div className='d-flex justify-content-between lh-1'>
                                <div>
                                    <span className="fs-7 text-gray-700 fw-bold mb-1">Feb 6, 2021</span>
                                    <div className="fs-8 fw-semibold text-gray-500 text-uppercase">Request date</div>
                                </div>
                                <Link
                                    to={`/${userTypeLowercase}/chats/${currentBroadcast.ChatRoom}`}
                                    className="text-decoration-none"
                                >
                                    <div
                                        className={`fs-7 pt-1 badge border border-${generateBgAndBorderColFromOfferMessage(currentBroadcast).borderColor}`} >
                                        Message
                                        <Icon
                                            name={'mail'}
                                            className={'text-dark fs-2 ms-1'}
                                            useWrapper
                                        />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}
