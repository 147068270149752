import React, {type ReactElement} from "react";
import {nanoid} from "nanoid";
import Icon from "../../../../icon/Icon";
import IconButton from "../../../../../components/shared/UIv2/Button/IconButton";

interface CardInputFileMultiplePreviewProps {
    inputRef: any
    field: any
}

export default function CardInputFileMultiplePreview ({inputRef, field}: CardInputFileMultiplePreviewProps): ReactElement {
    return (
        <div className={'overflow-auto'}>
            <div className={'d-flex gap-3 w-auto'}>
                <div key={nanoid()}
                    className={'position-relative overflow-hidden d-flex flex-column justify-content-center align-items-center gap-3 bg-gray-100 text-gray-400'}
                    style={{
                        height: '129px',
                        width: '188px',
                        borderRadius: '12px',
                        flexShrink: 0
                    }}
                    onClick={() => {
                        inputRef.current?.click()
                    }}
                >
                    <Icon
                        name={'attach_file'}
                        className={ 'fs-4x' }
                        styles={{
                            transform: 'rotate(-14deg)'
                        }}
                    />
                    <div className={'fs-4 fw-medium'}>
                        Upload files
                    </div>
                </div>
                {
                    Array.from(field.value as FileList ?? []).map((file: File) => (
                        <div key={nanoid()}
                            className={'position-relative overflow-hidden d-flex align-items-center justify-content-center bg-dark overflow-hidden'}
                            style={{
                                height: '129px',
                                width: '188px',
                                borderRadius: '12px',
                                flexShrink: 0
                            }}
                        >
                            <img src={(file as any).url}
                                alt=""
                                style={{
                                    minHeight: '100%',
                                    minWidth: '100%'
                                }}
                            />
                            <div className={'position-absolute'} style={{
                                top: '9px',
                                right: '9px'
                            }}>
                                <IconButton
                                    icon={'close'}
                                    style={'no-background'}
                                    customBackground={'#8080804D'}
                                    customColor={'#FFFFFF'}
                                    onClick={() => {
                                        field.onChange(
                                            (field.value as File[] ?? []).filter((valueFile: any) => valueFile.name !== file.name)
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}