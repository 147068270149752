import React, {type ReactElement} from "react";
import Badge from "../../../../../UIv2/Badge/Badge";

interface ViewUnitMarketingPriceDealPriceProps {
    price: number
    priceChange: {
        direction: 'up' | 'down'
        amount: number
    }
}

export default function ViewUnitMarketingPriceDealPrice ({price, priceChange}: ViewUnitMarketingPriceDealPriceProps): ReactElement {
    return <div className={'d-flex gap-1 align-items-center text-nowrap'}>
        <div className={'fs-7 fw-medium'}>{price.toLocaleString('en')} AED</div>
        <Badge
            size={'22'}
            style={ priceChange.direction === 'down' ? 'danger-light' : 'success-light'}
            icon={{
                position: 'start',
                name: 'change_history',
                filled: true,
                styles: priceChange.direction === 'down' ? { transform: 'rotate(180deg)' } : undefined
            }}
            text={`${priceChange.amount}%`}
        />
    </div>
}