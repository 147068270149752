import React, {type ReactElement} from "react";
import ClosableBlock from "../../../../../UIv2/ClosableBlock/ClosableBlock";
import {type ViewUnitSectionProps} from "../ViewUnitSection";
import Markdown from "react-markdown";

export default function ViewUnitSectionDescription ({unit}: ViewUnitSectionProps): ReactElement {
    const marketing = unit?.attributes?.Marketing
    const descriptionIsEmpty = marketing?.Description == null || marketing?.Description?.trim() === ''
    const description = marketing?.Description?.replaceAll('\n', '  \n')
    return !descriptionIsEmpty
        ? <ClosableBlock
            title={'Description'}
            closable={false}
        >
            <div className={'fs-5'} style={{
                letterSpacing: '-0.02em'
            }}>
                <Markdown>
                    {descriptionIsEmpty
                        ? 'Not set'
                        : description
                    }
                </Markdown>
            </div>
        </ClosableBlock>
        : <></>
}