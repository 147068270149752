import { getErrorss } from '../../../functions/errors/GetErrors'
import { type IVerifyPhoneFormValues, type IVerifyPhoneJSON, type ResponseObject } from '../../../types/userTypes/TAuth'
import { urlAUTH } from '../../../urls'
import { authSlice } from '../../slices/authSlice'
import { modalSlice } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'
import { type User } from '../../slices/userSlice'

export const resetPasswordSendMobileOTP = (formValues?: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    //  console.log(formValues, 'formValues________-???????')

    const phone = formValues?.phone ?? getState().auth.userNUMBER
    // const renew = (formValues != null && formValues !== undefined) && formValues?.renew !== false
    //  console.log(phone, 'verifyResetPasswordPhone')

    const renew = formValues?.hasOwnProperty('renew')
    // console.log(renew, 'renew')
    try {
        dispatch(modalSlice.actions.dataFetchingStart())

        const sendObject = {
            phone, changePass: true // renew: !renew
        }
        // console.log(sendObject)
        const response = await fetch(`${urlAUTH}/sendMobileOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const dataJSON: IVerifyPhoneJSON = await response.json()
        //    console.log(dataJSON, 'dataJSON')
        if (dataJSON.response?.sent) {
            //   console.log(dataJSON.response?.sent, 'dataJSON.response?.sent')
            if (formValues?.phone) {
                dispatch(authSlice.actions.numberSuccess(formValues?.phone))
            } else {
                dispatch(authSlice.actions.numberSuccess(phone))
            }
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/modal/my_profile_password_verify_phone/not/not`, isSuccessful: true, textNotification: '' }
        }
        // getErrors
        // console.log(dataJSON,"dataJSON")

        dispatch(modalSlice.actions.dataFetchingFinish())
        return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/settings`, isSuccessful: false, textNotification: getErrorss(dataJSON) }
    } catch (error) {
        console.log(error)
    }
}

export const resetPasswordSendMailOTP = (formValues?: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const email = formValues?.email ?? getState().auth.userEMAIL
    // ?? window.localStorage.getItem(USER_EMAIL_KEY)
    // console.log(email, 'verifyResetPasswordEmail')
    try {
        //  dispatch(authSlice.actions.authFetching(true))
        dispatch(modalSlice.actions.dataFetchingStart())
        //  console.log(formValues, 'formValues')
        const sendObject = {
            email, changePass: true // renew: true
        }
        // console.log(sendObject, 'sendObject')
        // checkMailOTP
        const response = await fetch(`${urlAUTH}/sendMailOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const dataJSON: IVerifyPhoneJSON = await response.json()
        // console.log(dataJSON, 'dataJSON verifyResetPasswordEmail')
        if (dataJSON.response?.sent) {
            if (formValues?.email) {
                dispatch(authSlice.actions.emailSuccess(formValues?.email))
            } else {
                dispatch(authSlice.actions.emailSuccess(email))
            }

            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            // /${(getState().user.currentUser as User).Category.toLowerCase()}/modal/my_profile_password_verify_email/not/not
        }
        // dispatch(authSlice.actions.authFetching(false))
        dispatch(modalSlice.actions.dataFetchingFinish())
        return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/settings`, isSuccessful: false, textNotification: getErrorss(dataJSON) }
    } catch (error) {
        console.log(error)
    }
}

export const resetPasswordWithPhone = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
    //    dispatch(authSlice.actions.authFetching(true))
        dispatch(modalSlice.actions.dataFetchingStart())
        const number = getState().auth.userNUMBER
        const AES_CODE = getState().auth.aes_code
        const sendObject = {
            password: formValues.password, code: AES_CODE, phone: number
        }

        const response = await fetch(`${urlAUTH}/renewPasswordWithMobile`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON = await response.json() as ResponseObject
        if (dataJSON.success && dataJSON.response?.approved) {
            //  dispatch(authSlice.actions.authFetching(false))
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/settings`, isSuccessful: true, textNotification: 'Your password has been changed' }
        } else { // getErrors
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
    }
}

export const resetPasswordWithEmail = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    // console.log(formValues, 'formValues resetPasswordEmail')
    try {
        //   dispatch(authSlice.actions.authFetching(true))
        dispatch(modalSlice.actions.dataFetchingStart())
        const email = getState().auth.userEMAIL
        const AES_CODE = getState().auth.aes_code
        const sendObject = {
            password: formValues.password, code: AES_CODE, email
        }
        const response = await fetch(`${urlAUTH}/renewPasswordWithMail`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const dataJSON = await response.json() as ResponseObject
        //  console.log(dataJSON, 'dataJSON')
        if (dataJSON.success && dataJSON.response?.approved) {
            // dispatch(authSlice.actions.authFetching(false))
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/settings`, isSuccessful: true, textNotification: 'Your password has been changed' }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
    }
}

export const resetPasswordCheckMobileOTP = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        // dispatch(authSlice.actions.authFetching(true))
        dispatch(modalSlice.actions.dataFetchingStart())
        const number = getState().auth.userNUMBER

        const sendObject = {
            ...formValues, changePass: true, phone: number //  renew: true
        }

        const response = await fetch(`${urlAUTH}/checkMobileOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON = await response.json() as ResponseObject
        if (dataJSON.success && dataJSON.response?.approved) {
            dispatch(authSlice.actions.aes_codeSuccess(dataJSON.response?.code))
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/modal/my_profile_reset_password_final_phone/not/not`, isSuccessful: true, textNotification: '' }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: -1, isSuccessful: false, textNotification: 'Uncorrect code' }
        }
    } catch (error) {
        console.log(error)
    }
}

export const resetPasswordCheckMailOTP = (formValues?: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        // dispatch(authSlice.actions.authFetching(true))

        dispatch(modalSlice.actions.dataFetchingStart())
        const email = getState().auth.userEMAIL

        const sendObject = {
            ...formValues, changePass: true, email //  renew: true,
        }
        //  console.log(sendObject, 'sendObject')

        // sendMailOTP
        const response = await fetch(`${urlAUTH}/checkMailOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON = await response.json() as ResponseObject
        //  console.log(dataJSON, ' dataJSON      confirmResetPasswordEmail')
        if (dataJSON.success && dataJSON.response?.approved) {
            dispatch(authSlice.actions.aes_codeSuccess(dataJSON.response?.code))
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/modal/my_profile_reset_password_final_email/not/not`, isSuccessful: true, textNotification: '' }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: -1, isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
    }
}
