import { type AppDispatch, type RootState } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { landlordAddUnitWriteToStorage } from '../utils/landlordAddUnitHandleStorage'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'
import { type NavigateFunction } from 'react-router-dom'

export const addAdditionalUnitInfoOutdoor = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    try {
        landlordAddUnitWriteToStorage('additional_unit_info_outdoor', data)
        return {
            textNotification: '',
            textNavigate: `/landlord/modal/landlord_create_unit_additional_info_files/${nanToNull(unitID) ?? '-'}/-`,
            isSuccessful: true
        }
    } catch (err) {
        console.log('Error while adding additional unit outdoor info', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export const skipAddAdditionalUnitInfoOutdoor = (navigate: NavigateFunction, unitID?: number) => async () => {
    navigate(`/landlord/modal/landlord_create_unit_additional_info_files/${nanToNull(unitID) ?? '-'}/-`)
}

export const goBackAddAdditionalUnitInfoOutdoor = (navigate: NavigateFunction, unitID?: number) => () => {
    navigate(`/landlord/modal/landlord_create_unit_additional_info/${nanToNull(unitID) ?? '-'}/-`)
}
