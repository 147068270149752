import React, { useEffect, useState } from 'react'
import CloseIconBasic from '../../elements/CloseIconBasic'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { setScheduleFilters, setShowPopupScheduleFilters } from '../../../../store/slices/modalSlice'
import { type RootState } from '../../../../store/store'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
interface IProps {
    scheduleFiltersApply: boolean
    setScheduleFiltersApply: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ScheduleFiltersList({ scheduleFiltersApply, setScheduleFiltersApply }: IProps): JSX.Element {
    const dispatch = useAppDispatch()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const scheduleFilters = useAppSelector((state: RootState) => state.modal.scheduleFilters)
    const loading = useAppSelector(state => state.modal.loading)

    const [localFilters, setLocalFilters] = useState(scheduleFilters)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    // Number(userID) === Number(REACT_APP_BSO_USER_ID)

    useEffect(() => {
        setLocalFilters(scheduleFilters)
    }, [scheduleFilters])

    const handleFilterChange = (sort: string) => {
        setLocalFilters({ ...localFilters, sort })
    }

    const applyFilters = () => {
        dispatch(setScheduleFilters(localFilters))
        dispatch(setShowPopupScheduleFilters(false))
        if (!scheduleFiltersApply) {
            setScheduleFiltersApply(true)
        }
    }

    const resetFilters = () => {
        setLocalFilters({ sort: 'all' })
        dispatch(setShowPopupScheduleFilters(false))
        if (!scheduleFiltersApply) {
            setScheduleFiltersApply(true)
        }
    }

    return (
        <div className="page d-flex fs-5">
            <div className="content w-100 mw-450px mx-auto ">
                <div className="card d-flex mh-100 ">
                    <div
                        className="card-header shadow-sm "
                        style={{
                            paddingTop:
                                userDevice === 'IPhone' ? '45px' : '14px',
                            paddingBottom: '14px'
                        }}
                    >
                        <div className="card-title">
                            <h3 className="text-uppercase ">
                                Schedule Filters
                            </h3>

                            {
                                <CloseIconBasic
                                    onCancel={() => {
                                        dispatch(
                                            setShowPopupScheduleFilters(false)
                                        )
                                    }}
                                    cls={'15px'}
                                />
                            }
                        </div>
                    </div>
                    <div className="card-body card-scroll h-50">
                        <h5 className="text-uppercase mb-4">
                            Sort tasks by :
                        </h5>
                        <div className="fs-6 text-uppercase ">
                            <div className="d-flex flex-row align-items-center"
                                onClick={() => { handleFilterChange('all') }}

                            >
                                <div className={`form-check form-check-custom 
                                 ${localFilters.sort === 'all' ? 'form-check-warning' : 'form-check-sucsess '} form-check-solid`}

                                >
                                    <input
                                        className="form-check-input h-25px w-25px border border-5 border-gray-300"
                                        type="radio"
                                        value="all"
                                        checked={localFilters.sort === 'all'}
                                        onChange={() => { handleFilterChange('all') }}

                                    />
                                    <div
                                        className=" ms-4 py-2 fw-semibold"

                                    >
                                        All
                                    </div>
                                </div>

                            </div>
                            <div className="separator separator-dotted border-gray-300 my-2"></div>
                            <div className="d-flex flex-row align-items-center"

                                onClick={() => {
                                    handleFilterChange('bso_units')
                                }}
                            >
                                <div className={`form-check form-check-custom 
                                
                                   
                                       ${localFilters.sort === 'bso_units' ? 'form-check-warning' : 'form-check-sucsess '}
                                    form-check-solid`}

                                >

                                    <input
                                        className="form-check-input h-25px w-25px border border-5 border-gray-300"
                                        type="radio"
                                        value="bso_units"
                                        checked={localFilters.sort === 'bso_units'}
                                        onChange={() => { handleFilterChange('bso_units') }}

                                    />
                                    <div
                                        className=" ms-4 py-2 fw-semibold"

                                    >
                                        BSO Units
                                    </div>
                                </div>
                            </div>
                            <div className="separator separator-dotted border-gray-300 my-2"></div>
                            <div className="d-flex flex-row align-items-center " onClick={() => {
                                handleFilterChange('other_units')
                            }}>
                                <div className={`form-check form-check-custom 
                                 ${localFilters.sort === 'other_units' ? 'form-check-warning' : 'form-check-sucsess '} form-check-solid`}

                                >
                                    <input
                                        className="form-check-input h-25px w-25px border border-5 border-gray-300"
                                        type="radio"
                                        value="other_units"
                                        checked={localFilters.sort === 'other_units'}
                                        onChange={() => { handleFilterChange('other_units') }}

                                    />
                                    <div
                                        className=" ms-4 py-2 fw-semibold"

                                    >
                                        Other Agents Units
                                    </div>
                                </div>
                            </div> <div className="separator separator-dotted border-gray-300 my-2"></div>
                            <div className="d-flex flex-row align-items-center" onClick={() => {
                                handleFilterChange('my_units')
                            }}>
                                <div className={`form-check form-check-custom 
       
                                      ${localFilters.sort === 'my_units' ? 'form-check-warning' : 'form-check-sucsess '}
                                    form-check-solid`}

                                >
                                    <input
                                        className="form-check-input h-25px w-25px border border-5 border-gray-300"
                                        type="radio"
                                        value="my_units"
                                        checked={localFilters.sort === 'my_units'}
                                        onChange={() => { handleFilterChange('my_units') }}

                                    />
                                    <div
                                        className=" ms-4 py-2 fw-semibold"

                                    >
                                        My Units
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '70px' }}></div>
                    <div className="footer fixed-bottom bg-white shadow-sm mw-450px mx-auto ">
                        <div className="d-flex flex-row w-100 h-100 p-4">
                            {!loading && (
                                <button
                                    onClick={resetFilters}

                                    className={`btn btn-white mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} ${loading ? ' disabled' : ''} border-bso2px p-4 fs-4 col-4 me-2 ms-n1 ls-1`}>
                                    <span className="indicator-label">RESET</span>
                                </button>
                            )}
                            {!loading && (
                                <button

                                    className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} ${loading ? ' disabled' : ''} border-bso2px p-4 fs-4 col-8  ls-1`}
                                    onClick={applyFilters}

                                >
                                    {loading
                                        ? (
                                            <span className="indicator-progress d-block">
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-4"></span>
                                            </span>
                                        )
                                        : (
                                            <span className="indicator-label">APPLY</span>
                                        )}
                                </button>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
