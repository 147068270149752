import {type AppDispatch, type RootState} from "../../../../store";
import {landlordAddUnitWriteToStorageV2} from "../utils/landlordAddUnitHandleStorageV2";

export const landlordCreateUnitPropertyInfo  = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    landlordAddUnitWriteToStorageV2('landlord_create_unit_property_info', data)
    return {
        isSuccessful: true,
        textNavigate: '/landlord/modal/v2/landlord_create_unit_unit_info/-/-',
        textNotification: ''
    }
}