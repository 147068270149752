import { type ApiResponseDocumentScan, type IFields } from '../../../../types/userTypes/documentTypes/TScan'
import { ACCESS_KEY, documentScanTypes, urlSCANING } from '../../../../urls'
import { authSlice } from '../../../slices/authSlice'
import { type AppDispatch, type RootState } from '../../../store'

export const scanDocument = (formValues: IFields) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

    try {
        const formData = new FormData()
        dispatch(authSlice.actions.authFetching(true))
        const sendedFile = Object.values(formValues)[0]?.[0]
        formData.append('file', sendedFile)
        formData.append('type', documentScanTypes[`${Object.keys(formValues)[0]}`])

        const response = await fetch(`${urlSCANING}`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as ApiResponseDocumentScan
        if (responseJSON.success) {
            if (responseJSON.response.document) {
                return { ...responseJSON.response }
            } else {
                return null
            }
        } else {
            return null
        }
    } catch (error) {
        dispatch(authSlice.actions.authFetching(false))

        console.log(error)
        return null
    }
}
