import React, {type ReactElement} from "react";

interface ModalTitleProps {
    row: Record<string, any>
}

export default function ModalTitle ({row}: ModalTitleProps): ReactElement {
    return (
        <div className={`fs-4x fw-medium text-center pt-8 ${row.paddingX === 'small' ? '' : 'px-8'}`}>
            {
                row.content
            }
        </div>
    )
}