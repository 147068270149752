import React, {type ReactElement} from "react";
import {nanoid} from "nanoid";
import Icon from "../../../../icon/Icon";

interface InformationCheckListProps {
    row: Record<string, any>
}

export default function InformationCheckList ({row}: InformationCheckListProps): ReactElement {
    return (
        <div className={'d-flex flex-column gap-4 fs-5 fw-medium text-gray-500 pb-8'}>
            <div>
                {row.title}
            </div>
            {
                row.checklist.map((item: any) => (
                    <div key={nanoid()}
                        className={'d-flex gap-2 align-items-start'}
                    >
                        <div className={'d-flex align-items-center justify-content-center bg-gray-200'}
                            style={{
                                height: '20px',
                                width: '20px',
                                minWidth: '20px',
                                borderRadius: '50%'
                            }}
                        >
                            <Icon name={'check'} className={'fs-2'}/>
                        </div>
                        <div>
                            {
                                item
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}