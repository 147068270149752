import QueryString from 'qs'
import {
    REACT_APP_BSO_USER_ID,
    USER_CATEGORY_KEY,
    USER_ID_KEY
} from '../../../../urls'

export const getQueryCurrentUser = () => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userCATEGORY = window.localStorage.getItem(USER_CATEGORY_KEY)
    let userQueryObject = {}
    if (userCATEGORY === 'Agent') {
        userQueryObject = {
            populate: {
                Type: '*',
                FavoriteList: '*',
                BlackList: '*',
                ContactInfo: '*',
                BankDetails: '*',
                Stats : "*", 
                Referrals: {
                    populate: {
                        Type: '*',
                        ContactInfo: '*',
                        Orders: '*',
                        Documents: '*'
                    }
                },
                Settings: {
                    populate: {
                        Notifies: '*'
                    }
                },
                Documents: {
                    populate: {
                        Type: '*'
                    }
                },

                Viewings: {
                    populate: {
                        Unit: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        }
                    }
                },
                Offers: {
                    populate: {
                        Type: '*',
                        Unit: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        }
                    }
                },
                Units: {
                    populate: {
                        Offers: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Viewings: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Documents: {
                            fields: ['id', 'Type'],
                            populate: {
                                Type: '*'
                            }
                        },
                        Property: '*'
                    }
                },
                Orders: {
                    populate: {
                        Service: '*',
                        Unit: {
                            fields: ['id']
                        }
                    }
                },
                // Chats: {
                //     populate: {
                //         Users: {
                //             populate: {
                //                 Type: '*',
                //                 ContactInfo: '*',
                //                 Settings: '*'
                //             }
                //         },
                //         Messages: {
                //             populate: {
                //                 Sender: {
                //                     fields: ['id']
                //                 },
                //                 Unit: {
                //                     populate: {
                //                         Marketing: '*',
                //                         Property: '*'
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // },
                Chats: {
                    populate: {
                        Users:
                    {
                        fields: ['id'],
                        populate: {
                            User: {
                                fields: ['id']
                            }
                        }
                    },
                        Messages: {
                            populate: {
                                Sender: {
                                    fields: ['id']
                                },
                                Unit:
                             {
                                 fields: ['id']

                             }
                            }
                        }
                    }
                }
            }
        }
    } else if (userCATEGORY === 'Landlord' && Number(userID) !== Number(REACT_APP_BSO_USER_ID)) {
        userQueryObject = {
            populate: {
                Type: '*',
                FavoriteList: '*',
                BlackList: '*',
                ContactInfo: '*',
                BankDetails: '*',
                Settings: {
                    populate: {
                        Notifies: '*'
                    }
                },
                Documents: {
                    populate: {
                        Type: '*'
                    }
                },

                Viewings: {
                    populate: {
                        Unit: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        }
                    }
                },
                Offers: {
                    populate: {
                        Type: '*',
                        Unit: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        }
                    }
                },
                Units: {
                    populate: {
                        Offers: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Viewings: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Documents: {
                            fields: ['id', 'Type'],
                            populate: {
                                Type: '*'
                            }
                        },
                        Property: '*'
                    }
                },
                Orders: {
                    populate: {
                        Service: '*',
                        Unit: {
                            fields: ['id']
                        }
                    }
                },
                Chats: {
                    populate: {
                        Users:
                            {
                                fields: ['id'],
                                populate: {
                                    User: {
                                        fields: ['id']
                                    }
                                }
                            },
                        Messages: {
                            populate: {
                                Sender: {
                                    fields: ['id']
                                },
                                Unit:
                                    {
                                        fields: ['id']

                                    }
                            }
                        }
                    }
                }
            }
        }
    } else if (Number(userID) === Number(REACT_APP_BSO_USER_ID)) {
        userQueryObject = {
            populate: {
                Type: '*',
                FavoriteList: '*',
                BlackList: '*',
                ContactInfo: '*',
                BankDetails: '*',
                Settings: {
                    populate: {
                        Notifies: '*'
                    }
                },
                Documents: {
                    populate: {
                        Type: '*'
                    }
                },

                Viewings: {
                    populate: {
                        Unit: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        }
                    }
                },
                Offers: {
                    populate: {
                        Type: '*',
                        Unit: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        }
                    }
                },
                Units: {
                    populate: {
                        Offers: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Viewings: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Documents: {
                            fields: ['id', 'Type'],
                            populate: {
                                Type: '*'
                            }
                        },
                        Property: '*',
                        Marketing: '*',
                        Landlords: {
                            populate: {
                                Landlord: {
                                    populate: {
                                        ContactInfo: '*',
                                        Documents: '*'
                                    }
                                },
                                Representatives: {
                                    populate: {
                                        ContactInfo: '*',
                                        Documents: '*'
                                    }
                                }
                            }
                        }
                    }
                },
                Orders: {
                    populate: {
                        Service: '*',
                        Unit: {
                            fields: ['id']
                        }
                    }
                },

                Chats: {
                    populate: {
                        Users:
                    {
                        fields: ['id'],
                        populate: {
                            User: {
                                fields: ['id']
                            }
                        }
                    },
                        Messages: {
                            populate: {
                                Sender: {
                                    fields: ['id']
                                },
                                Unit:
                             {
                                 fields: ['id']

                             }
                            }
                        }
                    }
                }
            }
        }
    }

    const userQuery = QueryString.stringify(userQueryObject, { encode: false })
    return userQuery
}

// userQueryObject = {
//     populate: {
//         Type: '*',
//         FavoriteList: '*',
//         BlackList: '*',
//         ContactInfo: '*',
//         BankDetails: '*',
//         Settings:
//         {
//             populate: {
//                 Notifies: '*'
//             }
//         },
//         Documents: {
//             populate: {
//                 Type: '*'
//             }
//         },

//         Viewings:
//         {
//             populate: {
//                 Unit: {
//                     fields: ['id'],
//                     populate: {
//                         User: {
//                             fields: ['id']
//                         }
//                     }
//                 }
//             }
//         },
//         Offers: {
//             populate: {
//                 Type: '*',
//                 Unit: {
//                     fields: ['id'],
//                     populate: {
//                         User: {
//                             fields: ['id']
//                         }
//                     }
//                 }
//             }
//         },
//         Units: {
//             populate: {
//                 Offers: {
//                     fields: ['id'],
//                     populate: {
//                         User: {
//                             fields: ['id']
//                         }
//                     }
//                 },
//                 Viewings: {
//                     fields: ['id'],
//                     populate: {
//                         User: {
//                             fields: ['id']
//                         }
//                     }
//                 },
//                 Documents: {
//                     fields: ['id', 'Type'],
//                     populate: {
//                         Type: '*'
//                     }
//                 },
//                 Property: '*'
//             }
//         },

//         Orders: {
//             populate: {
//                 Service: '*',
//                 Unit: {
//                     fields: ['id']
//                 }
//             }
//         },

//         Chats: {
//             populate: {
//                 Users:
//                 {
//                     fields: ['id'],
//                     populate: {
//                         User: {
//                             fields: ['id']
//                         }
//                     }
//                     // было в строке
//                     // populate: {
//                     //     Type: '*',
//                     //     ContactInfo: '*',
//                     //     Settings: '*'
//                     // }
//                 },
//                 Messages: {
//                     populate: {
//                         Sender: {
//                             fields: ['id']
//                         },
//                         Unit:
//                          {
//                              fields: ['id']
//                              // было в строке
//                              // populate: {
//                              //     Marketing: '*',
//                              //     Property: '*'
//                              // }
//                          }
//                     }
//                 }
//             }
//         }
//     }
// }
// [
//     {
//         "id": 194,
//         "createdAt": "2024-05-28T05:52:10.881Z",
//         "updatedAt": "2024-06-27T07:57:51.684Z",
//         "Name": "296_amemetoff@yandex.ru_Free_1716875530497",
//         "Amount": 0,
//         "InvoiceRefNumber": null,
//         "InvoiceDate": null,
//         "InvoiceDueDate": null,
//         "Statuses": [
//             "Paid"
//         ],
//         "InvoiceURL": null,
//         "ReceiptURL": null,
//         "SessionID": null,
//         "CardBrand": null,
//         "CardCountry": null,
//         "CardExpiryMonth": null,
//         "CardExpiryYear": null,
//         "CardLast4": null
//     }
// ]
