import React, {type ReactElement, useState} from "react";
import PageLayout from "../../../UIv2/PageLayout/PageLayout";
import useViewUnit from "./ViewUnit.hook";
import ViewUnitSectionAnnualFees from "./ViewUnitSections/ViewUnitSectionAnnualFees/ViewUnitSectionAnnualFees";
import ViewUnitSectionMarketPrice from "./ViewUnitSections/ViewUnitSectionMarketPrice/ViewUnitSectionMarketPrice";
import ViewUnitSectionMainInfo from "./ViewUnitSections/ViewUnitSectionMainInfo/ViewUnitSectionMainInfo";
import ViewUnitSectionUnitInformation
    from "./ViewUnitSections/ViewUnitSectionUnitInformation/ViewUnitSectionUnitInformation";
import ViewUnitSectionAdditionalDetails
    from "./ViewUnitSections/ViewUnitSectionAdditionalDetais/ViewUnitSectionAdditionalDetails";
import ViewUnitSectionFloorPlan from "./ViewUnitSections/ViewUnitSectionFloorPlan/ViewUnitSectionFloorPlan";
import ViewUnitSectionOwnerDetails from "./ViewUnitSections/ViewUnitSectionOwnerDetails/ViewUnitSectionOwnerDetails";
import ViewUnitSectionDescription from "./ViewUnitSections/ViewUnitSectionDescription/ViewUnitSectionDescription";
import ViewUnitSectionHeading from "./ViewUnitSections/ViewUnitSectionHeading/ViewUnitSectionHeading";
import ViewUnitActions from "./ViewUnitActions/ViewUnitActions";
import TextButton from "../../../UIv2/Button/TextButton";
import { nanoid } from "nanoid";
import UnitActiveOrders from "../UnitActiveOrders/UnitActiveOrders";
import {useNavigate} from "react-router-dom";

export default function LandlordViewUnit (): ReactElement {
    const { unit } = useViewUnit()
    const navigate = useNavigate()
    const [activeOrdersOpen, setActiveOrdersOpen] = useState(false)
    return unit != null
        ? <>
            <ViewUnitSectionHeading unit={unit}/>
            <PageLayout>
                <div className={'d-flex flex-column gap-8'}>
                    <ViewUnitSectionMainInfo unit={unit}/>
                    <ViewUnitSectionAnnualFees unit={unit}/>
                    <ViewUnitSectionMarketPrice unit={unit}/>
                    <ViewUnitSectionUnitInformation unit={unit}/>
                    <ViewUnitSectionAdditionalDetails unit={unit}/>
                    <ViewUnitSectionFloorPlan unit={unit}/>
                    <ViewUnitSectionOwnerDetails unit={unit}/>
                    <ViewUnitSectionDescription unit={unit}/>
                </div>
            </PageLayout>
            <ViewUnitActions buttons={[
                <TextButton
                    text={'ACTIVE ORDERS'}
                    badge={{
                        text: String(0),
                        style: 'dark',
                        position: 'end'
                    }}
                    style={'white'}
                    textSize={'large'}
                    height={'42'}
                    width={'full'}
                    key={nanoid()}
                    onClick={() => {
                        setActiveOrdersOpen(true)
                    }}
                />,
                <TextButton
                    text={'NEW SERVICE'}
                    icon={{
                        name: 'add',
                        position: 'end'
                    }}
                    style={'dark'}
                    textSize={'large'}
                    height={'42'}
                    width={'full'}
                    key={nanoid()}
                    onClick={() => {
                        navigate(`/landlord/services/${unit.id}`)
                    }}
                />
            ]}/>
            <UnitActiveOrders unit={unit} isOpen={activeOrdersOpen} setOpen={setActiveOrdersOpen}/>
        </>
        : <></> // TODO Render 404 Error
}