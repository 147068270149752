
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, URL_MAIN_API_APP, USER_ID_KEY } from '../../../urls'
import { modalSlice } from '../../slices/modalSlice'
import { type IOrder } from '../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../store'

interface IOptions {
    isLiveMode: boolean
    selectedPeriod: string
    orderID: number
}

export const upgrateSubscriptionAgent = (chooseSubscription: string, options?: IOptions) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    const currentUser = getState().user.currentUser

    const testing = !options?.isLiveMode
    const orderID = options?.orderID
    let action = ''

    switch (chooseSubscription) {
        case 'Standard':
            action = options?.selectedPeriod === 'weekly' ? 'WeeklyStandard' : options?.selectedPeriod === 'annual' ? 'AnnualStandard' : 'MonthlyStandard'
            break
        case 'Premium':
            action = options?.selectedPeriod === 'weekly' ? 'WeeklyPremium' : options?.selectedPeriod === 'annual' ? 'AnnualPremium' : 'MonthlyPremium'
            break
        default:
            break
    }

    try {
        dispatch(modalSlice.actions.dataFetchingStart())

        const sendObject = { id: String(orderID), action, testing }
        const response = await fetch(`${URL_MAIN_API_APP}/payment/updateSubscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(sendObject)
        })
        const dataJSON = await response.json()
        //  console.log(dataJSON, 'dataJSON')
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                // dispatch(viewingsSlice.actions.viewingsFetchingError(getErrors(dataJSON)))
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(modalSlice.actions.dataFetchingFinish())
                //       console.log({ id: dataJSON.response.data.id, ...dataJSON.response.data.attributes }, '{ ...dataJSON.response.data.id, ...dataJSON.response.data.attributes }')
                return { ...dataJSON.response, textNavigate: '', isSuccessful: true, textNotification: '' } // 'Please make payment'
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(modalSlice.actions.dataFetchingFinish())
        //  dispatch(userSlice.actions.userFetchingError(error))
    }
}
