import { type AppDispatch, type RootState } from '../../store'
import { updateUnit } from '../unitsActions'
import { updateTask } from './updateTask'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'

export const updateValidateDocumentsTask = (taskID: number, unitID: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(dataFetchingStart())
    try {
        const unitResponse = await dispatch(updateUnit(data, unitID, false, true))
        if (unitResponse.isSuccessful === true) {
            const taskResponse = await dispatch(updateTask(taskID, {
                Statuses: ['Done']
            }))
            if (taskResponse.success === true && taskResponse.response.error == null) {
                dispatch(dataFetchingFinish())
                return {
                    textNotification: 'Unit has been successfully validated',
                    textNavigate: '/landlord/schedule',
                    isSuccessful: true
                }
            }
        }
    } catch (err: any) {
        console.error(`Error in updateValidateDocumentsTask: ${String(err.message)}`)
    }
    dispatch(dataFetchingFinish())
    return {
        textNotification: 'An error occurred while processing task',
        textNavigate: '',
        isSuccessful: false
    }
}
