import { type AppDispatch, type RootState } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { landlordAddUnitWriteToStorage } from '../utils/landlordAddUnitHandleStorage'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'
import { type NavigateFunction } from 'react-router-dom'

export const addMainInfoSecond = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    try {
        data.Bathrooms = nanToNull(data.Bathrooms)
        data.Bedrooms = nanToNull(data.Bedrooms)
        landlordAddUnitWriteToStorage('main_unit_info_second', data)
        return {
            textNotification: '',
            textNavigate: `/landlord/modal/landlord_create_unit_additional_info/${nanToNull(unitID) ?? '-'}/-`,
            isSuccessful: true
        }
    } catch (err) {
        console.log('Error while adding main unit info first modal', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export const skipAddMainInfoSecond = (navigate: NavigateFunction, unitID?: number) => async () => {
    navigate(`/landlord/modal/landlord_create_unit_additional_info/${nanToNull(unitID) ?? '-'}/-`)
}

export const goBackAddMainInfoSecond = (navigate: NavigateFunction, unitID?: number) => () => {
    navigate(`/landlord/modal/landlord_create_main_info_first/${nanToNull(unitID) ?? '-'}/-`)
}
