export const timeStringInScheduler = (dateString: string): string => {
    const date = new Date(dateString)

    let dateTimeDubai = date.toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Asia/Dubai' })
    const reg = / at /g

    if (reg.test(String(dateTimeDubai))) {
        dateTimeDubai = dateTimeDubai.replace(reg, ', ')
    }

    const [dateDubai, timeDubai] = dateTimeDubai.split(',')
    return dateTimeDubai
}
