import {type UnitActiveOrdersSectionProps} from "../UnitActiveOrdersSection";
import React, {type ReactElement} from "react";
import ClosableBlock from "../../../../../UIv2/ClosableBlock/ClosableBlock";
import Card from "../../../../../UIv2/Card/Card";
import LinkButton from "../../../../../UIv2/Button/LinkButton";
import StatusMark from "../../../../../UIv2/StatusMark/StatusMark";
import Separator from "../../../../../UIv2/Separator/Separator";
import Table from "../../../../../UIv2/Table/Table";
import unitActiveOrdersSectionFindingTenantsPreviousOfferHelper
    from "./UnitActiveOrdersSectionFindingTenantsPreviousOffer.helper";
import useUnitActiveOrderSectionFindingTenant from "./UnitActiveOrderSectionFindingTenant.hook";
import dayjs from "dayjs";

export default function UnitActiveOrdersSectionFindingTenant ({unit}: UnitActiveOrdersSectionProps): ReactElement {
    const {
        closestViewingDateTime,
        upcomingViewingsCount,
        closestAdvertiseDates,
        activeAdvertisesCount,
        highestOfferData,
        previousOffers
    } = useUnitActiveOrderSectionFindingTenant(unit)
    return <ClosableBlock
        title={'Finding a Tenant'}
    >
        <div className={'d-flex flex-column gap-4'}>
            <div className={'row'}>
                <div className={'col'}>
                    <Card style={'white'} rounded={'8'}>
                        <div className={'d-flex flex-column gap-4 p-5'}>
                            <div className={'fs-6 lh-1 text-gray-600'}>
                                CURRENT VIEWING
                            </div>
                            <Card style={'filled'} rounded={'8'}>
                                <div className={'d-flex flex-column fs-6 py-2 px-3'}>
                                    {
                                        closestViewingDateTime != null
                                            ? <>
                                                <div>
                                                    {
                                                        closestViewingDateTime?.isSame(dayjs(), 'day')
                                                            ? 'Today'
                                                            : closestViewingDateTime?.format('MMM D')
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        closestViewingDateTime?.format('hh:mmA')
                                                    } - {
                                                        closestViewingDateTime?.add(1, 'h')?.format('hh:mmA')
                                                    }
                                                </div>
                                            </>
                                            : <div>
                                                No upcoming viewings
                                            </div>
                                    }
                                </div>
                            </Card>
                            <LinkButton
                                text={'OPEN VIEWINGS'}
                                size={'26'}
                                badge={{
                                    position: 'end',
                                    text: String(upcomingViewingsCount)
                                }}
                            />
                        </div>
                    </Card>
                </div>
                <div className={'col'}>
                    <Card style={'white'} rounded={'8'}>
                        <div className={'d-flex flex-column gap-4 p-5'}>
                            <div className={'fs-6 lh-1 text-gray-600'}>
                                CURRENT ADVERTISE
                            </div>
                            <Card style={'filled'} rounded={'8'}>
                                <div className={'d-flex flex-column fs-6 py-2 px-3'}>
                                    { closestAdvertiseDates != null
                                        ? <>
                                            <div className={'d-flex justify-content-between'}>
                                                <div>
                                                    FROM
                                                </div>
                                                <div>
                                                    TO
                                                </div>
                                            </div>
                                            <div className={'d-flex justify-content-between'}>
                                                <div>
                                                    {
                                                        closestAdvertiseDates.startDate.format('DD/MM/YY')
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        closestAdvertiseDates.endDate.format('DD/MM/YY')
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        : <div>
                                            No upcoming advertises
                                        </div>
                                    }
                                </div>
                            </Card>
                            <LinkButton
                                text={'OPEN ADVERTISES'}
                                size={'26'}
                                badge={{
                                    position: 'end',
                                    text: String(activeAdvertisesCount)
                                }}
                            />
                        </div>
                    </Card>
                </div>
            </div>
            <Card style={'white'} rounded={'8'}>
                <div className={'d-flex flex-column gap-4 p-5'}>
                    { highestOfferData != null
                        ? <>
                            <div className={'fs-6 lh-1 text-gray-600'}>
                                HIGHEST OFFER
                            </div>
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <div className={'fs-5'}>
                                    {highestOfferData.createdAt.format('MMM D, YYYY')}
                                </div>
                                <div className={'d-flex flex-column align-items-center gap-1'}>
                                    <div className={'d-flex align-items-center gap-1'}>
                                        <div className={'fs-2x lh-1 fw-medium'}>
                                            {
                                                highestOfferData.offer.toLocaleString('en')
                                            }
                                        </div>
                                        <div className={'fs-8 lh-1 fw-medium'}>
                                            AED/yr
                                        </div>
                                    </div>
                                    <div className={'fs-6 lh-1'}>
                                        {
                                            highestOfferData.chequesNumber
                                        } {
                                            highestOfferData.chequesNumber === 1
                                                ? 'CHEQUE'
                                                : 'CHEQUES'
                                        }
                                    </div>
                                </div>
                                <div className={'d-flex align-items-center gap-2'}>
                                    <StatusMark style={highestOfferData.statusType}/>
                                    <div className={'fs-6'}>
                                        {
                                            highestOfferData.status
                                        }
                                    </div>
                                </div>
                            </div>
                            <Card style={'filled'}
                                rounded={'8'}
                            >
                                <div className={'d-flex align-items-center justify-content-center gap-3 py-2'}>
                                    <div className={'fs-6 text-gray-600 lh-1'}>
                                        PREDICTION
                                    </div>
                                    <div className={'fs-8 text-danger fst-italic lh-1'}>
                                        {
                                            highestOfferData.roiDate.diff(dayjs(), 'year')
                                        } yrs before ROI (EST: {
                                            highestOfferData.roiDate.format('DD/MM/YYYY')
                                        })
                                    </div>
                                </div>
                            </Card>
                            <Separator/>
                        </>
                        : <></>
                    }
                    <div className={'d-flex flex-column gap-3'}>
                        <div className={'fs-8 text-gray-600 lh-1'}>
                            PREVIOUS OFFERS
                        </div>
                        <Table
                            rows={previousOffers.map(offer => unitActiveOrdersSectionFindingTenantsPreviousOfferHelper(offer))}
                        />
                    </div>
                </div>
            </Card>
            <LinkButton text={'OPEN OFFERS'} size={'38'}/>
        </div>
    </ClosableBlock>
}