import React, {type ReactElement} from "react";
import IconButton from "../../../../components/shared/UIv2/Button/IconButton";
import {AgentModals} from "../../../../functions/agent/AgentModals";
import {useNavigate} from "react-router-dom";
import {setNavigationDirection} from "../../../../store/slices/modalSlice";
import {useAppDispatch} from "../../../../hooks/redux";

interface ModalHeaderProps {
    modalID: string
}

export default function ModalHeader({modalID}: ModalHeaderProps): ReactElement {
    const modal = AgentModals().find((modal: any) => modal.id === modalID)
    const backButtonRow = modal?.footer?.find((row: any) => row.id === 'back')
    const closeButtonRow = modal?.footer?.find((row: any) => row.id === 'close')
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    return <div className={'d-flex flex-column gap-8 w-100'}>
        <div className={'d-flex justify-content-between align-items-center'}>
            <div
                className={'d-flex justify-content-start'}
                style={{
                    minWidth: '20px'
                }}
                onClick={() => {
                    if (backButtonRow != null) {
                        dispatch(setNavigationDirection('back'))
                        navigate(backButtonRow.redirect ?? -1)
                        setTimeout(() => {
                            dispatch(setNavigationDirection(undefined))
                        }, 1000)
                    }
                }}
            >
                { backButtonRow != null
                    ? <IconButton icon={'chevron_left'} style={'no-background'} iconToFontRatio={1.5}/>
                    : <></>
                }
            </div>
            <div className={'fs-2x fw-medium'}>
                {
                    modal?.header
                }
            </div>
            <div className={'d-flex justify-content-end'}
                style={{
                    minWidth: '20px'
                }}
                onClick={() => {
                    if (closeButtonRow != null) {
                        navigate(closeButtonRow.redirect ?? -1)
                    }
                }}
            >
                { closeButtonRow != null
                    ? <IconButton icon={'close'} style={'no-background'} iconToFontRatio={1.5}/>
                    : <></>
                }
            </div>
        </div>
        { modal.progress != null
            ?  <div className={'w-100 bg-bso'}
                style={{
                    height: '6px',
                    borderRadius: '24px'
                }}
            >
                <div className={'bg-black'}
                    style={{
                        height: '6px',
                        borderRadius: '24px',
                        width: `${modal.progress as number}%`
                    }}
                />
            </div>
            : <></>
        }
    </div>
}