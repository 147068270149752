import { List } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
// import { getStatusesOffers } from '../../../../functions/agent/schedule/getStatusesOffers'
// import OfferListItem from './OfferListItem'
import { type IOffer, offersSlice } from '../../../../store/slices/offersSlice'
import { nanoid } from 'nanoid'
import useSaveScroll from '../../../../hooks/useSaveScroll'

import {
    prImage,
    othrImage,
    rejImage,
    aprImage,
    completedImage,
    resubmitRequiredImage,
    cancelledImage, closedImage, negotiatedImage, confirmedImage,
    placeholders
} from '../../../../urls'
import { getStatusesTasks } from '../../../../functions/agent/schedule/getStatusesTasks'
import TasksListItem from './TasksListItem'

interface IProps {
    tasks: any[]
}

export default function TasksList ({ tasks }: IProps): JSX.Element {
    const statuses = getStatusesTasks(tasks) as string[] | []
    const filterNavigate = useAppSelector((state: RootState) => state.schedule.filterNavigate)
    const offersScrollPosition = useAppSelector((state: RootState) => state.offers.offersScrollPosition)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (filterNavigate === 'tasks') {
            setTimeout(() => {
                window.scrollTo({ top: Number(offersScrollPosition), behavior: 'smooth' })
            }, 100)
        }
    }, [filterNavigate])

    useEffect(() => {
        //  console.log('useEffect scroll OFFERS')
        const scrollWrapper = document.querySelector('#root')
        const handleScroll = (event: Event): void => {
            const target = event?.target as HTMLElement
            if (target.scrollTop > 0) {
                dispatch(offersSlice.actions.setOffersScrollPosition(target.scrollTop))
            }
        }
        scrollWrapper?.addEventListener('scroll', handleScroll, { passive: true })
        return () => {
            ///    dispatch(offersSlice.actions.setOffersScrollPosition(offersScrollPosition + 2))
            scrollWrapper?.removeEventListener('scroll', handleScroll)
        }
    }, [filterNavigate])
    console.log(statuses)
    return (
        <>
            {tasks.length > 0
                ? <List
                    // style={{ marginTop: '-30px' }}
                    dataSource={statuses}
                    renderItem={(item: any, index: number) => {
                        const { status } = item
                        // const date = new Date(year, month)
                        //          console.log(`${currentMonth}-${currentYear}`, '`${currentMonth}-${currentYear}`', `${month}-${year}`, '`${month}-${year}`')
                        // const isMonthChanged = index === 0 || new Date(periods[index - 1].year, periods[index - 1].month).getMonth() !== month
                        const srcImage = othrImage
                        // switch (status) {
                        //     case 'To do':
                        //         srcImage = completedImage
                        //         break
                        //     case 'In-process':
                        //         srcImage = aprImage
                        //         break
                        //     case 'Done':
                        //         srcImage = rejImage
                        //         break
                        //     case 'Archived':
                        //         srcImage = prImage
                        //         break
                        //     default:
                        //         break
                        // }
                        return (
                            <>
                                {item.tasksLength > 0
                                    ? <div key={nanoid()} className={'position-relative'}>
                                        <img
                                            className=" w-100 h-100px object-fit-cover mb-2 rounded-0 rounded-bottom opacity-100"
                                            src={srcImage}
                                            alt={status}
                                        />
                                        <span className="d-block fs-2 min-w-30px max-w-30px text-center position-absolute rounded-0 rounded-bottom rounded-end-0 translate-middle badge badge-secondary opacity-75" style={{ top: '12px', right: '-15px' }}>
                                            {item.tasksLength}
                                        </span>
                                        <span className="ps-4 fs-5x fw-semi-bold text-white position-absolute " style={{ top: '6px', left: '0px', textShadow: '1px 1px 10px #332d2dd9' }}>
                                            {status}
                                        </span>
                                    </div>
                                    : null}
                                {/* <OfferListItem key={item.id} {...item} /> */}
                                <TasksListItem key={item.id} {...item}/>
                            </>
                        )
                    }}
                />
                : <div className={'d-flex position-relative align-items-end'} style={{ height: '50vh' }}>
                    <img
                        className="w-75 object-fit-cover m-auto rounded-0 rounded-bottom opacity-100"
                        src={placeholders.Offers}
                        alt={'liable_picture'}
                    />
                    <span className="fs-2x fw-bold text-white text-uppercase position-absolute text-center" style={{ bottom: '17%', left: '50%', transform: 'translateX(-50%)', textShadow: '1px 1px 10px #332d2dd9' }}>
                        You don’t have any active offers yet
                    </span>
                </div>
            }

        </>
    )
}
