import React, {type ReactElement, useRef} from "react";
import Icon from "../../../../app/icon/Icon";

interface InputFileProps {
    placeholder: string
    multiple: boolean
    onChange: (files: FileList | null) => void
    value: FileList | null | undefined
    loading?: boolean
}

export default function FileInput ({placeholder, multiple, onChange, value, loading = false}: InputFileProps): ReactElement {
    const inputRef = useRef<HTMLInputElement>(null)
    return <>
        <div className={'d-flex border border-gray-300 w-100 ps-8 pe-5'}
            onClick={() => {
                inputRef?.current?.click()
            }}
            style={{
                height: '48px',
                borderRadius: '48px'
            }}
        >
            <div className={'flex-grow-1 d-flex align-items-center'}>
                <div className={`fs-4 fw-medium text-truncate w-100 ${value?.[0] != null ? 'text-dark' : 'text-gray-400'}`}
                    style={{
                        maxWidth: '250px'
                    }}
                >
                    {
                        value?.[0]?.name ?? placeholder
                    }
                </div>
            </div>
            <div className={'d-flex align-items-center border border-gray-300 border-top-0 border-right-0 border-bottom-0 ps-5'}>
                {
                    loading
                        ? <div className={'spinner-border spinner-border-sm'}/>
                        : <Icon name={'attach_file'} className={'fs-2x'} imageSize={40}/>
                }
            </div>
        </div>
        <input
            type="file"
            className={'d-none'}
            multiple={multiple}
            onChange={(e) => {
                onChange(e.target.files)
            }}
            ref={inputRef}
        />
    </>
}