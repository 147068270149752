import React, {type ReactElement} from "react";
import {type Unit} from "../../../../../../../store/slices/unitsSlice";
import ServiceFindTenant from "./ServiceFindTenant/ServiceFindTenant";
import useServiceFindTenants from "./ServiceFindTenant/ServiceFindTenant.hook";

export interface IUnitCardServiceData {
    serviceIsActive: boolean
}

interface LandlordUnitCardServicesProps {
    unit: Unit
}

export default function LandlordUnitCardServicesList ({unit}: LandlordUnitCardServicesProps): ReactElement {
    const serviceFindTenantData = useServiceFindTenants(unit)
    const unitHasActiveServices = [
        serviceFindTenantData
    ].some(
        (service: IUnitCardServiceData) => service.serviceIsActive
    )
    return unitHasActiveServices
        ? <div className={'d-flex flex-column gap-6'}>
            <ServiceFindTenant {...{...serviceFindTenantData, unit}}/>
        </div>
        : <></>
}