import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { type RootState } from '../../../store/store'
import { useAppSelector } from '../../../hooks/redux'
import { swalError, swalInProgress, swalNeedLoadDocs, swalRejectedDoc, swalWithBootstrapButtons } from '../../../functions/swalOptions'
import swalConfig from '../../../app/texts/swalTexts'
export default function AlertsVerified(): JSX.Element {
    //  console.log('ALERTS VERIFU')
    const { type } = useParams()
    const navigate = useNavigate()
    const location = useLocation().pathname
    const { currentUser } = useAppSelector(
        (state: RootState) => state.user
    )
    const { statusesApproved, documentsExpires, documentsLoaded, documentsRejected, documentsInProgress } = useAppSelector(
        (state: RootState) => state.agentStates
    )
    const rejSts = documentsRejected.passportReject || documentsRejected.emiratesReject
    const verifiedStatus = statusesApproved.emiratesApproved || statusesApproved.passportApproved
    const verifiedInProgress = documentsInProgress.emiratesInProgress || documentsInProgress.passportInProgress
    const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.passportLoaded
    const expSts = documentsExpires.emiratesExpire || documentsExpires.passportExpire

    if (!loadSts) {
        void swalNeedLoadDocs().then(async (result: any) => {
            if (result?.isConfirmed) {
                navigate('/agent/modal/update_emirates_front_together/INagentMODULE/to_choose_subscription')
            } if (result.isDismissed) {
                navigate('/agent/units/nav')
            }
        })
    }
    if (loadSts && verifiedInProgress && !verifiedStatus) {
        void swalInProgress(swalConfig.inProgress.message).then(async (result: any) => {
            if (result.isConfirmed) {
                navigate(-1)
            }
        })
    } if (loadSts && rejSts && !verifiedStatus) {
        void swalRejectedDoc(swalConfig.rejectedDoc.documentMessageRej(`${documentsRejected.emiratesReject ? 'EmiratesID' : 'Passport'}`)).then(async (result: any) => {
            if (result?.isConfirmed) {
                if (documentsRejected.emiratesReject) {
                    navigate('/agent/modal/update_emirates_front_together/not/not')
                } else {
                    navigate('/agent/modal/update_passport_together/not/not')
                }
            }
        })
    } if (loadSts && expSts && !verifiedStatus) {
        void swalRejectedDoc(swalConfig.rejectedDoc.documentMessageExp(`${documentsExpires.emiratesExpire ? 'EmiratesID' : 'Passport'}`)).then(async (result: any) => {
            if (result?.isConfirmed) {
                if (documentsExpires.emiratesExpire) {
                    navigate('/agent/modal/update_emirates_front_together/not/not')
                } else {
                    navigate('/agent/modal/update_passport_together/not/not')
                }
            }
        })
    }
    return (
        <></>
    )
}
