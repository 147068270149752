import React, {type ReactElement} from "react";
import Icon from "../../../../../app/icon/Icon";
import {useNavigate} from "react-router-dom";
import Badge from "../../../../shared/UIv2/Badge/Badge";
import {type Unit} from "../../../../../store/slices/unitsSlice";

interface ServicesHeaderProps {
    unit?: Unit
}

export default function ServicesHeader ({unit}: ServicesHeaderProps): ReactElement {
    const navigate = useNavigate()
    const property = unit?.attributes?.Property?.data?.attributes
    const marketing = unit?.attributes?.Marketing
    return <div className={'d-flex flex-column gap-5 pb-5'}>
        <div className={'d-flex justify-content-between align-items-center'}>
            <Icon
                name={'chevron_left'}
                className={'fs-2x'}
                iconToFontRatio={1.5}
                onClick={() => {
                    navigate(-1)
                }}
                styles={{
                    marginLeft: '-10px'
                }}
            />
            <div className={'fs-2x fw-medium'}>
                Order a Service
            </div>
            <div style={{ width: '20px' }}/>
        </div>
        <div className={'d-flex gap-3 align-items-center justify-content-between'}>
            <div className={'d-flex align-items-center gap-3'}>
                <div className={'fs-3 flex-grow-1'}>
                    {property?.Area}, {property?.Name}, {unit?.attributes?.Number}
                </div>
                <div className={'fs-6 fw-light text-gray-500'}>
                    {
                        marketing?.Use != null
                            ? marketing.Use.toUpperCase()
                            : <></>
                    }
                </div>
            </div>
            <div>
                {/* <Badge */}
                {/*    icon={{ */}
                {/*        name: 'check_circle', */}
                {/*        position: 'end', */}
                {/*        filled: true */}
                {/*    }} */}
                {/*    text={'bso'} */}
                {/*    style={'bso'} */}
                {/*    size={'18'} */}
                {/* /> */}
            </div>
        </div>
    </div>
}