import {type AppDispatch, type RootState} from "../../../../store";
import {dataFetchingFinish, dataFetchingStart} from "../../../../slices/modalSlice";
import {type User} from "../../../../slices/userSlice";
import {
    landlordAddUnitWriteToStorage
} from "../../landlordUnitsActions/utils/landlordAddUnitHandleStorage";
import {createDocument, scanDocuments, scanTitleDeed} from "../../../documentActions";
import compareNames from "../../../../../functions/shared/user/compareNames/compareNames";
import dayjs from "dayjs";
import nanToNull from "../../../../../functions/adapters/universal/nanToNull";
import {
    landlordAddUnitReadFromStorageV2,
    landlordAddUnitWriteToStorageV2
} from "../utils/landlordAddUnitHandleStorageV2";
import indexedDBHandler from "../../../../../functions/utils/indexedDB/indexedDBHandler";
import {nanoid} from "nanoid";

export const landlordCreateUnitUploadTitleDeed = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    const currentUser = getState().user.currentUser as User
    try {
        const response = await dispatch(scanTitleDeed(data.title_deed?.[0]))// await dispatch(scanDocuments(data.title_deed, 'Title Deed', true))
        // const documentId: number | null = response?.[0]?.id
        if (response?.response?.document?.valid === true && response?.response?.document?.owners?.length > 0) {
            // if (documentId != null) {
            const fileKey = `TitleDeed-${nanoid()}`
            await indexedDBHandler.saveFile(data.title_deed[0], fileKey)
            response.indexedDBKey = fileKey
            landlordAddUnitWriteToStorageV2('landlord_create_unit_upload_title_deed', response)
            const storedData = landlordAddUnitReadFromStorageV2()

            const userIsLandlord = storedData?.landlord_create_unit_upload_title_deed?.response?.document?.owners?.some((owner: any) => compareNames(
                String(owner.name),
                {
                    FirstName: String(currentUser?.ContactInfo?.FirstName),
                    FamilyName: String(currentUser?.ContactInfo?.FamilyName)
                }
            )) === true
            const representedLandlordIsOwner = storedData?.landlord_create_unit_upload_title_deed?.response?.document?.owners?.some((owner: any) => compareNames(
                String(owner.name),
                {
                    FirstName: String(storedData?.landlord_create_unit_id_verification?.owner_first_name),
                    FamilyName: String(storedData?.landlord_create_unit_id_verification?.owner_last_name)
                }
            )) === true
            if (userIsLandlord && storedData.landlord_create_unit_choose_role?.role === 'owner') {
                const userHasValidPassport = currentUser?.Documents?.some(doc => (
                    doc?.Type?.[0]?.__component === 'documents.passport' &&
                        dayjs(doc?.ExpiryDate).isAfter(dayjs())
                ))
                if (userHasValidPassport === true) {
                    return {
                        textNotification: '',
                        textNavigate: `/landlord/modal/v2/landlord_create_unit_property_info/${nanToNull(unitID) ?? '-'}/-`,
                        isSuccessful: true
                    }
                    // return await dispatch(addUnit('add_info_step', nanToNull(unitID)))
                } else {
                    // return {
                    //     textNotification: '',
                    //     textNavigate: `/landlord/modal/landlord_create_unit_users_passport/${nanToNull(unitID) ?? '-'}/-`,
                    //     isSuccessful: true
                    // }
                }
            } else if (representedLandlordIsOwner && storedData.landlord_create_unit_choose_role?.role === 'representative') {
                return {
                    textNotification: '',
                    textNavigate: `/landlord/modal/v2/landlord_create_unit_property_info/${nanToNull(unitID) ?? '-'}/-`,
                    isSuccessful: true
                }
            }
            // }
        } else {
            // const documentResponse = await dispatch(createDocument(
            //     null,
            //     {
            //         Valid: false,
            //         Type: [{
            //             __component: 'documents.title-deed'
            //         }]
            //     },
            //     undefined,
            //     undefined,
            //     response[0].url
            // ))
            // response[0].id = documentResponse?.response?.data?.id
            // landlordAddUnitWriteToStorage('title_deed', { ...response[0], needsVerification: true })
            // /* const taskResponse = await dispatch(createNewTask({
            //     AssignedTo: Number(process.env.REACT_APP_BSO_USER_ID),
            //     Documents: [
            //         documentId
            //     ],
            //     Type: 'Document Verification',
            //     Statuses: [
            //         'To do'
            //     ],
            //     Unit: unitResponse?.response?.data?.id,
            //     StartDate: dayjs()
            // })) */
            // dispatch(dataFetchingFinish())
            // return {
            //     // ...taskResponse,
            //     isSuccessful: true,
            //     textNotification: '',
            //     textNavigate: '/landlord/modal/landlord_create_unit_request_validation/-/-'
            // }
        }
    } catch (err) {
        console.log('Error while title deed scanning', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}