import React, {type ReactElement, useState} from "react";
import Icon from "../../../../app/icon/Icon";
import {nanoid} from "nanoid";
import {Drawer} from "antd";
import Badge from "../Badge/Badge";
import IconButton from "../Button/IconButton";

interface SelectProps {
    placeholder: string
    multiple?: boolean
    selectors: Array<{
        label: string,
        value: string
    }>,
    value: string | string[],
    onChange: (value?: string | string[]) => void
    disabled?: boolean
}

export default function Select ({placeholder, selectors, value, onChange, multiple = false, disabled = false}: SelectProps): ReactElement {
    const [selectOpen, setSelectOpen] = useState(false)
    const selectedLabel = selectors.find((selector) => selector.value === value)?.label
    const selectedLabels = selectors
        .filter((selector) => value?.includes(selector.value))
        .map((selector) => selector.label) ?? []
    return (
        <>
            <div className={`d-flex border border-gray-300 w-100 ${multiple && value?.length > 0 ? 'ps-3': 'ps-5'} ${disabled ? 'bg-gray-100' : ''} pe-4`}
                style={{
                    borderRadius: '8px',
                    minHeight: '42px'
                }}
                onClick={() => {
                    if (!disabled) {
                        setSelectOpen(true)
                    }
                }}
            >
                <div className={`flex-grow-1 d-flex flex-wrap align-items-center fw-medium fs-5 py-1 ${
                    selectedLabel != null
                        ? disabled 
                            ? 'text-gray-500'
                            : 'text-dark' 
                        : 'text-gray-400'
                }`}
                style={{
                    gap: '3px 9px'
                }}
                >
                    { multiple && Array.isArray(value)
                        ? selectedLabels?.length > 0
                            ? selectedLabels.map((label) => (
                                <Badge
                                    text={label}
                                    size={'24'}
                                    style={'gray'}
                                    key={nanoid()}
                                />
                            ))
                            : placeholder
                        : selectedLabel ?? placeholder
                    }
                </div>
                <div className={'d-flex align-items-center border border-gray-300 border-top-0 border-right-0 border-bottom-0 ps-4'}>
                    <Icon name={multiple ? 'add' : 'keyboard_arrow_down'} className={'fs-2x'} imageSize={40}/>
                </div>
            </div>
            <Drawer
                placement={'bottom'}
                size={'large'}
                height={'50vh'}
                open={selectOpen}
                destroyOnClose={true}
                closable={true}
                onClose={() => {
                    setSelectOpen(false)
                }}
                headerStyle={{ display: 'none' }}
                contentWrapperStyle={{
                    borderTopRightRadius: '16px',
                    borderTopLeftRadius: '16px',
                    overflow: 'hidden'
                }}
                bodyStyle={{
                    padding: 0
                }}
                zIndex={10000}
            >
                <div
                    className={'overflow-auto'}
                    style={{
                        maxHeight: '50vh'
                    }}
                >
                    <div className={'d-flex flex-column p-5 gap-2'}>
                        {
                            selectors.map((selector) => (
                                <div key={nanoid()}
                                    className={`d-flex p-4 align-items-center justify-content-between fw-medium fs-4 ${
                                        multiple
                                            ? value?.includes(selector.value) ? 'bg-secondary' : ''
                                            : selector.value === value ? 'bg-secondary' : ''
                                    }`}
                                    style={{
                                        borderRadius: '8px'
                                    }}
                                    onClick={() => {
                                        if (multiple) {
                                            if (value?.includes(selector.value) && Array.isArray(value)) {
                                                onChange(value.filter((option) => option !== selector.value))
                                            } else {
                                                onChange([...((value ?? []) as string[]), selector.value])
                                            }
                                        } else {
                                            if (value !== selector.value) {
                                                onChange(selector.value)
                                            } else {
                                                onChange(undefined)
                                            }
                                            setSelectOpen(false)
                                        }
                                    }}
                                >
                                    <span>
                                        {
                                            selector.label
                                        }
                                    </span>
                                    {
                                        multiple && value?.includes(selector.value)
                                            ? <IconButton icon={'close'} style={'no-background'}/>
                                            : <></>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Drawer>
        </>
    )
}