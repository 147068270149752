import React, {type ReactElement} from "react";
import Icon from "../../../../app/icon/Icon";

interface CheckboxProps {
    checked: boolean
    style?: 'dark' | 'light'
}

export default function Checkbox ({checked, style = 'dark'}: CheckboxProps): ReactElement {
    return <div
        className={`d-flex align-items-center justify-content-center border border-gray-400 ${ checked && style === 'dark' ? 'bg-gray-400' : ''}`}
        style={{
            borderRadius: '6px',
            width: '24px',
            height: '24px'
        }}
    >
        {
            checked
                ? <Icon
                    name={'check'}
                    className={`${style === 'dark' ? 'text-white' : ''} fs-1`}
                    styles={{
                        color: style === 'light' ? '#2168F2' : undefined
                    }}
                />
                : <></>
        }
    </div>
}