import { type AppDispatch, type RootState, store } from '../../store'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { userSlice } from '../../slices/userSlice'
import { authSlice } from '../../slices/authSlice'
import sendMail from '../../../functions/shared/email/sendMail'
import { removeNotifyByDeviceToken } from '../../../functions/shared/notify-helpers/removeNotify'

export const deleteUserAction = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''

    try {
        const currentUser = store.getState().user.currentUser
        dispatch(userSlice.actions.setDeletingAccount(true))
        const dataCollections = await Promise.all([
            fetchData('documents', 'id', userID, jwt),
            fetchData('viewings', 'id', userID, jwt),
            fetchData('offers', 'id', userID, jwt),
            fetchData('unit-advertises', 'id', userID, jwt),
            fetchData('orders', 'id', userID, jwt),
            fetchData('properties', 'id', userID, jwt, true),
            fetchData('units', 'id', userID, jwt)
        ])

        for (let i = 0; i < dataCollections.length; i++) {
            const dataCollectionResponse = dataCollections[i]
            if (dataCollectionResponse.ok) {
                const dataCollection = await dataCollectionResponse.json()
                const collectionName = getCollectionName(i)

                for (const item of dataCollection.response.data) {
                    await deleteData(collectionName, item.id, jwt)
                }
            }
        }

        const response = await deleteData('users', Number(userID), jwt)

        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(userSlice.actions.setDeletingAccount(false))

                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                const sentMail = await sendMail({
                    email: currentUser?.email as string,
                    templateId: 'd-c64063419bca43a8884261403b2f14cb'

                })

                dispatch(authSlice.actions.logout())
                void removeNotifyByDeviceToken({
                    userID: store.getState().user.currentUser.id as number,
                    deviceToken: store.getState().user.currentUser.DeviceToken as string
                })
                dispatch(userSlice.actions.setDeletingAccount(false))
                return { ...dataJSON, textNavigate: '/auth/login', isSuccessful: true, textNotification: 'You have successfully deleted your profile' }
            }
        } else {
            dispatch(userSlice.actions.setDeletingAccount(false))
            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (e) {
        dispatch(userSlice.actions.setDeletingAccount(false))
        console.error(e)
    }
}
function getCollectionName (index: number): string {
    switch (index) {
        case 0:
            return 'documents'
        case 1:
            return 'viewings'
        case 2:
            return 'offers'
        case 3:
            return 'unit-advertises'
        case 4:
            return 'orders'
        case 5:
            return 'properties'
        case 6:
            return 'units'
        default:
            return 'unknown'
    }
}
const fetchData = async (collection: string, filterField: string, filterValue: string, jwt: string, isProperties = false) => {
    return await fetch(`${urlAPI}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        },
        body: JSON.stringify({
            method: 'GET',
            collection,
            query: `filters[${isProperties ? 'ManagedBy' : 'User'}][${filterField}][$eq]=${filterValue}&populate=id`
        })
    })
}

const deleteData = async (collection: string, id: number, jwt: string) => {
    return await fetch(`${urlAPI}`, {
        method: 'POST',
        body: JSON.stringify({
            method: 'DELETE',
            collection,
            id
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        }
    })
}
