import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'

import { Collapse, Switch, Space, type CollapseProps } from 'antd'
import { useNavigate } from 'react-router-dom'
import { changeEmailVerifyPhone } from '../../../store/actionsCreators/userActions/changeEmailActions'
import { changeNumberVerifyEmail } from '../../../store/actionsCreators/userActions/changePhoneActions'
import { swalError, swalSuccess } from '../../../functions/swalOptions'
import { type IResponseObjectJSON } from '../../../types/userTypes/TAuth'
import { type User } from '../../../store/slices/userSlice'
import { type RootState } from '../../../store/store'

export default function AgentProfileSecurity (): JSX.Element {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { currentUser } = useAppSelector(
        (state: RootState) => state.user
    )
    const { loading } = useAppSelector(
        (state: RootState) => state.modal
    )

    const [loadType, setLoadType] = useState({ emailLoad: false, phoneLoad: false, passLoad: false })

    const phone = (currentUser as User)?.phone
    const email = (currentUser as User)?.email
    const userID = (currentUser as User).id
    //  console.log(phone, 'phone')
    const phoneHide = phone ? phone.slice(0, 9) : ''
    //    console.log({ phoneHide })

    function hideEmailCharacters (email: string) {
        const atIndex = email.indexOf('@')
        const username = email.slice(2, atIndex)
        const hiddenUsername = username.replace(/./g, '*')
        return email.slice(0, 2) + hiddenUsername + email.slice(atIndex)
    }

    const onClickChange = async (callback: any) => {
        const json: IResponseObjectJSON = await dispatch(callback)
        //     console.log(json, 'json')
        if (json.isSuccessful) {
        //    console.log(json.textNotification, 'json.textNotification', json.textNavigate, 'json.textNavigate')
            void swalSuccess(json.textNotification, () => {
                navigate(json.textNavigate)
                // dispatch(authSlice.actions.authFetching(false))
            })
        } else {
            void swalError(json.textNotification, () => {
                // dispatch(authSlice.actions.authFetching(false))
                navigate(json.textNavigate)
            })
        }
        setLoadType({ emailLoad: false, phoneLoad: false, passLoad: false })
    }
    const isDev = window.localStorage.getItem('BSO_DEVELOP_MODE')

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="card-title fs-3 fw-bold fw-bold p-0 m-0 ">Security</span>,
            children: <div className="card-body p-0 m-0 ">
                {/* <div className="card-body border-top p-9"> */}
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Email Address</div>
                        {/* hideEmailCharacters(email) */}
                        <div className="fw-semibold text-gray-600">{ email}</div>
                    </div>

                    <div className="ms-auto  d-flex">
                        {/* <Link to={`/agent/modal/phone_verify_contacts_my/${userID}/${userID}`} className="text-decoration-none"> */}
                        <div onClick={async () => {
                            setLoadType((prevState) => ({ ...prevState, emailLoad: true }))
                            onClickChange(changeEmailVerifyPhone({ phone }))
                        }
                        }

                        className="btn btn-sm fs-6 btn-light-secondary border border-dark text-dark me-2 d-flex">Change
                            {loading && loadType.emailLoad
                                ? (
                                    <span className="indicator-progress d-block">
                                        <span className={'spinner-border spinner-border-sm align-middle ms-2'}></span>
                                    </span>
                                )
                                : null}

                        </div>

                    </div>

                </div>

                <div className="separator separator-dashed my-6"></div>
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Phone number</div>
                        {/* {phoneHide}*****/}
                        <div className="fw-semibold text-gray-600"> {phone}</div>
                    </div>
                    <div className="ms-auto">
                        {/* <Link to={`/agent/modal/email_verify_contacts_my/${userID}/${userID}`} className="text-decoration-none"> */}
                        <div onClick={async () => {
                            setLoadType((prevState) => ({ ...prevState, phoneLoad: true }))
                            onClickChange(changeNumberVerifyEmail({ email }))
                        }} className="btn btn-sm fs-6 btn-light-secondary  border border-dark text-dark me-2 d-flex">Change
                            {loading && loadType.phoneLoad
                                ? (
                                    <span className="indicator-progress d-block">
                                        <span className={'spinner-border spinner-border-sm align-middle ms-2'}></span>
                                    </span>
                                )
                                : null}
                        </div>
                        {/* </Link> */}

                    </div>

                </div>
                <div className="separator separator-dashed my-6"></div>
                <div className="d-flex flex-wrap align-items-center mb-2">

                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Password</div>
                        <div className="fw-semibold text-gray-600">************</div>
                    </div>

                    <div className="ms-auto">
                        <div onClick={() => {
                            setLoadType((prevState) => ({ ...prevState, passLoad: true }))
                            navigate('/agent/modal/my_profile_reset_password_way_email/not/not')
                        }} className="btn btn-sm fs-6 btn-light-secondary border border-dark text-dark me-2 d-flex">Change
                            {loadType.passLoad
                                ? (
                                    <span className="indicator-progress">
                                        <span className={'spinner-border spinner-border-sm align-middle ms-2'}></span>
                                    </span>
                                )
                                : null}
                        </div>
                    </div>

                </div>

                {isDev === 'true'
                    ? <>  <div className="separator separator-dashed my-6"></div>
                        <div className="d-flex flex-wrap align-items-center mb-2">

                            <div >
                                <div className="fs-6 fw-bold mb-1 text-uppercase">Fingerprint / Face ID</div>

                            </div>

                            <div className="ms-auto">
                                <Space direction="vertical">
                                    <Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked />
                                </Space>
                            </div>

                        </div> </>
                    : null }

            </div>
        }
    ]

    return (
        <>
            <div className="card shadow-sm mb-2">
                <Collapse defaultActiveKey={['1']} items={items}/>
            </div>
        </>
    )
}
