import {type IFields} from "../../../../types/userTypes/documentTypes/TScan";
import {type AppDispatch, type RootState} from "../../../store";

export const chooseLandlordType = (formValues: IFields) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const errorRedirect = '/auth/register_choose_landlord_type'
    let successRedirect = '/landlord'
    try {
        const landlordType = formValues.landlord_type
        if (landlordType !== 'Landlord') {
            successRedirect = '/auth/register_upload_poa'
        }
        return { textNavigate: successRedirect, isSuccessful: true, textNotification: '' }
    } catch (err) {
        return { textNavigate: errorRedirect, isSuccessful: false, textNotification: 'Some error occurred,please try again' }
    }
}