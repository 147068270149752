export const getStatusesTasks = (tasks: any[]): any => {
    const statuses = [] as any

    const todoTasks = tasks.filter(task => task?.attributes?.Statuses?.includes('To do'))
    const inProcessTasks = tasks.filter(task => task?.attributes?.Statuses?.includes('In-process'))
    const doneTasks = tasks.filter(task => task?.attributes?.Statuses?.includes('Done'))
    const archivedTasks = tasks.filter(task => task?.attributes?.Statuses?.includes('Archived'))

    statuses.push({
        status: 'To do',
        tasks: todoTasks,
        tasksLength: todoTasks.length
    })

    statuses.push({
        status: 'In-process',
        tasks: inProcessTasks,
        tasksLength: inProcessTasks.length
    })
    statuses.push({
        status: 'Done',
        tasks: doneTasks,
        tasksLength: doneTasks.length
    })

    statuses.push({
        status: 'Archived',
        tasks: archivedTasks,
        tasksLength: archivedTasks.length
    })

    return statuses
}
