
import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { Collapse } from 'antd'
import { type User } from '../../../store/slices/userSlice'
import PaymentMy from '../profile/PaymentMy'
import RulesModals from '../../shared/auth/RulesModals'

export default function AgentBilling (): JSX.Element {
    const { currentUser, loading } = useAppSelector(
        (state: RootState) => state.user
    )
    const userID = currentUser.id
    //  console.log(currentUser, '________currentUser AgentGeneralInfo')
    const { Panel } = Collapse
    const userCONTACTS = (currentUser as User)?.Type?.[0]
    const userContactsINFO = (currentUser as User)?.ContactInfo
    return (
        <div className="card shadow-sm mb-2">
            <Collapse defaultActiveKey={'1'} >
                <Panel
                    style={{ fontFamily: 'Poppins' }}
                    showArrow={false}
                    header={<span className="card-title fs-3 fw-bold fw-bold p-0 m-0">Billing</span>}
                    key="1"
                >    <div className="card-body p-0 m-0">
                        <div className="row gx-9 gy-6">
                            {/* <div className="col-xl-6" data-kt-billing-element="card">

                                <div className="card card-dashed h-xl-100 p-6 bg-light-primary">

                                    <div className="d-flex flex-column py-2 ">

                                        <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                                            <div className="text-uppercase">
                                                {(currentUser as User)?.ContactInfo?.FirstName} {(currentUser as User)?.ContactInfo?.FamilyName}
                                            </div>
                                            <span className="badge badge-light-success fs-7 ms-2">Primary</span>
                                        </div>

                                        <div className="d-flex align-items-center ">

                                            <img src="/assets/media/placeholders/visa.svg" alt="" className="me-4"/>

                                            <div>
                                                <div className="fs-4 fw-bold">Visa **** 1679</div>
                                                <div className="fs-6 fw-semibold text-gray-400">Card expires at 09/24</div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="d-flex align-items-center py-2">
                                        <button className="btn btn-sm  border border-dark text-dark  me-3" data-kt-billing-action="card-delete">
                                            <span className="indicator-label">Delete</span>
                                            <span className="indicator-progress">
Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>

                                    </div>

                                </div>

                            </div>

                            <div className="col-xl-6 " data-kt-billing-element="card">

                                <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6 bg-light-danger">

                                    <div className="d-flex flex-column py-2 ">

                                        <div className="d-flex align-items-center text-uppercase fs-4 fw-bold mb-5">
                                            {(currentUser as User)?.ContactInfo?.FirstName} {(currentUser as User)?.ContactInfo?.FamilyName}
                                        </div>

                                        <div className="d-flex align-items-center ">

                                            <img src="/assets/media/placeholders/mastercard.svg" alt="" className="me-4"/>

                                            <div>
                                                <div className="fs-4 fw-bold">Mastercard **** 1290</div>
                                                <div className="fs-6 fw-semibold text-gray-400">Card expires at 03/23</div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="d-flex align-items-center justify-content-end py-2">
                                        <button className="btn btn-sm  border border-dark text-dark btn-active-light-primary me-3">
                                            <span className="indicator-label">
Delete</span>

                                            <span className="indicator-progress">
Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>

                                        </button>

                                    </div>

                                </div>

                            </div> */}

                            <div className="col-xl-6">

                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">

                                    <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">

                                        <div className="mb-3 mb-md-0 fw-semibold">
                                            <h4 className="text-gray-900 fw-bold">Important Note!</h4>

                                            <div className="fs-6 text-gray-700 pe-7">

                                                Please carefully read  <span className="fw-bold me-1"> <RulesModals row={'Terms & Conditions'}/></span> before adding your debit/credit card </div>
                                        </div>

                                        <Link to={`/agent/modal/add_card_modal/${userID}/${userID}`} className="btn btn-bso px-6 align-self-center text-nowrap">
Add Card            </Link>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </Panel>
            </Collapse>

        </div>
    )
}
