import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { AgentModals } from '../../../functions/agent/AgentModals'
import { LandlordModals } from '../../../functions/landlord/data/LandlordModals'

import CustomFieldsHook from './Fields/CustomFieldsHook'
import CustomValidatorHook from './CustomValidatorHook'

import { setDop, setPlan, setService } from '../../../store/slices/serviceSlice'
import { getServices } from '../../../functions/landlord/data/servicesSubm'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import TutorialLoadDocuments from '../../../components/agentModule/tutorials/TutorialLoadDocuments'
import { type RootState, store } from '../../../store/store'
import useUserType from '../../../hooks/UseUserType'

import {
    dropModalSlice,
    setImages,
    setShowButtonBuyCoinsOnAdvertise
} from '../../../store/slices/modalSlice'
import { swalAttentionLeave } from '../../../functions/swalOptions'
import swalConfig from '../../texts/swalTexts'
import { setDebitMarketingCredits, setNotEnoughMarketingCredits } from '../../../store/slices/advertisesSlice'
import { USER_ADVERTISE_DAYS } from '../../../urls'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
    landlordCreateUnitUploadTitleDeed
} from "../../../store/actionsCreators/unitActions/landlordUnitActionsV2/callbacks/landlordCreateUnitUploadTitleDeed";
import {
    landlordCreateUnitChooseRole
} from "../../../store/actionsCreators/unitActions/landlordUnitActionsV2/callbacks/landlordCreateUnitChooseRole";
import {
    landlordCreateUnitOwnerPassport
} from "../../../store/actionsCreators/unitActions/landlordUnitActionsV2/callbacks/landlordCreateUnitOwnerPassport";
import {
    fillValuesForAddUnitModalV2
} from "../../../store/actionsCreators/unitActions/landlordUnitActionsV2/modalFillers/fillValuesForAddUnitModalV2";
import {
    landlordCreateUnitIdVerification
} from "../../../store/actionsCreators/unitActions/landlordUnitActionsV2/callbacks/landlordCreateUnitIdVerification";
import {
    landlordCreateUnitPropertyInfo
} from "../../../store/actionsCreators/unitActions/landlordUnitActionsV2/callbacks/landlordCreateUnitPropertyInfo";
import {
    landlordCreateUnitUnitInfo
} from "../../../store/actionsCreators/unitActions/landlordUnitActionsV2/callbacks/landlordCreateUnitUnitInfo";
import {
    landlordCreateUnitUnitFeatures
} from "../../../store/actionsCreators/unitActions/landlordUnitActionsV2/callbacks/landlordCreateUnitUnitFeatures";
import {
    landlordCreateUnitUsersPassport
} from "../../../store/actionsCreators/unitActions/landlordUnitActionsV2/callbacks/landlordCreateUnitUsersPassport";
import {
    serviceFindTenantPickDate
} from "../../../store/actionsCreators/serviceActions/servicesV2/findingTenant/callbacks/serviceFindTenantPickDate";
import {
    serviceFindTenantUnitInfo
} from "../../../store/actionsCreators/serviceActions/servicesV2/findingTenant/callbacks/serviceFindTenantUnitInfo";
import {
    serviceFindTenantMarketingInfo
} from "../../../store/actionsCreators/serviceActions/servicesV2/findingTenant/callbacks/serviceFindTenantMarketingInfo";
import {
    serviceFindTenantPhotos
} from "../../../store/actionsCreators/serviceActions/servicesV2/findingTenant/callbacks/serviceFindingTenantPhotos";
import {
    serviceFindTenantAddons
} from "../../../store/actionsCreators/serviceActions/servicesV2/findingTenant/callbacks/serviceFindTenantAddons";
import {
    serviceFindTenantCheckout
} from "../../../store/actionsCreators/serviceActions/servicesV2/findingTenant/callbacks/serviceFindTenantCheckout";
import {
    fillValuesForServiceFindingTenant
} from "../../../store/actionsCreators/serviceActions/servicesV2/findingTenant/modalFillers/fillValuesForServiceFindingTenant";
dayjs.extend(utc)

interface CustomModalProps {
    modalID?: string
    unitID?: string
    objectID?: string
    close?: () => void
}

export default function CustomModalV2 (props: CustomModalProps): JSX.Element {
    let { modalID, unitID, objectID } = useParams()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const dispatch = useAppDispatch()
    if (_.some([props.modalID, props.unitID, props.objectID], prop => prop !== undefined)) {
        modalID = props.modalID
        unitID = props.unitID
        objectID = props.objectID
    }

    const units = useAppSelector((state) => state.units.units)
    const newProperty = useAppSelector((state) => state.property.newPropertyFields)
    const temporaryTitleDeed = store.getState().documents.temporaryTitleDeed

    // const [defaultFields, setDefaultFields] = useState(initializeFields())
    // const [modals, setModals] = useState<any[]>([...LandlordModals, ...AgentModals(defaultFields)])

    const unitFields = units.filter((unit) => unit.id === Number(unitID)) ?? [] as any
    const propertyFields = {
        ...unitFields?.[0]?.attributes?.Property?.data?.attributes
    }

    const [modals, setModals] = useState<any[]>([...LandlordModals, ...AgentModals({
        unitFields,
        propertyFields,
        newProperty
    })])

    useEffect(() => {
        const unitsFields = store.getState().units.units.filter((unit) => unit.id === Number(unitID)) ?? []
        const newProperty = store.getState().property.newPropertyFields
        const propertyFields = {
            ...unitsFields?.[0]?.attributes?.Property?.data?.attributes,
            Name: newProperty?.name,
            // Type: newProperty?.type,
            Area: newProperty?.area,
            MAKANI: newProperty?.makani
        }

        const updatedUnitFields = unitsFields.length > 0
            ? [{
                ...unitsFields[0],
                attributes: {
                    ...unitsFields[0].attributes,
                    Number: (newProperty?.number != null) ? Number(newProperty?.number) : unitsFields[0].attributes.Number ?? null
                }
            }]
            : []

        const newModals = [...LandlordModals, ...AgentModals({
            unitFields: updatedUnitFields,
            newProperty,
            propertyFields
        })]
        setModals(newModals)
    }, [temporaryTitleDeed, newProperty, modalID]) // ?.name, newProperty?.number, newProperty?.area
    let modal = modals.filter((modal: any) => modal.id === modalID)

    if (props.close !== undefined) {
        const closeButton = modal[0]?.footer?.find((button: any) => button.id === 'close') ?? {}
        closeButton.close = props.close
    }

    const key = modalID
    let callback
    let handleReset

    const navigate = useNavigate()
    const { userType } = useUserType()

    const state: RootState = store.getState()// useAppSelector((state: RootState) => state)
    const modalInitialData = useAppSelector((state: RootState) => state.modal.initialData)

    let initialData = JSON.parse(JSON.stringify(modalInitialData))
    useEffect(() => {
        const pageServices = getServices(modalID ?? '')
        dispatch(setService(pageServices))
        const initialPlan = ((pageServices?.plans?.[0]) != null) || {}
        dispatch(setPlan(initialPlan))
        return () => {
            dispatch(setService(undefined))
            dispatch(setPlan(undefined))
            dispatch(setDop([]))
            dispatch(setImages([]))
            if (key === 'agent_set_offer_modal_residential') {
                dispatch(dropModalSlice())
            }
        }
    }, [])

    switch (key) {
        case 'landlord_create_unit_choose_role':
            callback = landlordCreateUnitChooseRole(Number(unitID))
            break
        case 'landlord_create_unit_users_passport':
            callback = landlordCreateUnitUsersPassport(Number(unitID))
            break
        case 'landlord_create_unit_find_owner':
            callback = (formValues: any) => () => {
                return {
                    isSuccessful: true,
                    textNavigate: `/landlord/modal/v2/landlord_create_unit_wait_for_owner/-/${String(formValues.landlord_id)}`,
                    textNotification: ''
                }
            }
            break
        case 'landlord_create_unit_owner_passport':
            callback = landlordCreateUnitOwnerPassport(Number(unitID))
            break
        case 'landlord_create_unit_id_verification':
            initialData = dispatch(fillValuesForAddUnitModalV2(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = landlordCreateUnitIdVerification(Number(unitID))
            break
        case 'landlord_create_unit_upload_title_deed':
            callback = landlordCreateUnitUploadTitleDeed(Number(unitID))
            break
        case 'landlord_create_unit_property_info':
            initialData = dispatch(fillValuesForAddUnitModalV2(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = landlordCreateUnitPropertyInfo(Number(unitID))
            break
        case 'landlord_create_unit_unit_info':
            initialData = dispatch(fillValuesForAddUnitModalV2(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = landlordCreateUnitUnitInfo(Number(unitID))
            break
        case 'landlord_create_unit_unit_features':
            initialData = dispatch(fillValuesForAddUnitModalV2(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = landlordCreateUnitUnitFeatures(Number(unitID))
            break
        case 'service_finding_tenant':
            initialData = dispatch(fillValuesForServiceFindingTenant(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = serviceFindTenantPickDate(Number(unitID))
            break
        case 'service_finding_tenant_unit_info':
            initialData = dispatch(fillValuesForServiceFindingTenant(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = serviceFindTenantUnitInfo(Number(unitID))
            break
        case 'service_finding_tenant_marketing_info':
            initialData = dispatch(fillValuesForServiceFindingTenant(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = serviceFindTenantMarketingInfo(Number(unitID))
            break
        case 'service_finding_tenant_photos':
            initialData = dispatch(fillValuesForServiceFindingTenant(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = serviceFindTenantPhotos(Number(unitID))
            break
        case 'service_finding_tenant_addons':
            initialData = dispatch(fillValuesForServiceFindingTenant(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = serviceFindTenantAddons(Number(unitID))
            break
        case 'service_finding_tenant_checkout':
            initialData = dispatch(fillValuesForServiceFindingTenant(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = serviceFindTenantCheckout(Number(unitID))
            break
        case 'service_finding_tenant_end':
            callback = () => () => {
                return {
                    isSuccessful: true,
                    textNotification: '',
                    textNavigate: '/landlord/my_units'
                }
            }
            break
        default:
            break
    }

    const onLeave = (str?: string): void => {
        return void swalAttentionLeave(swalConfig.attentionLeave.messageModalFooterClose).then(async (result: any) => {
            if (result.isConfirmed) {
                str && str !== '' ? navigate(str) : navigate(-1)
            }
        })
    }

    const onCloseForAdvertiseModal = () => {
        localStorage.removeItem(USER_ADVERTISE_DAYS)
        dispatch(setDebitMarketingCredits(0))
        dispatch(setNotEnoughMarketingCredits(0))
        dispatch(setShowButtonBuyCoinsOnAdvertise(false))
        navigate('/agent/units/nav')
    }

    if (key === 'update_emirates_front_together' && objectID === 'first') return <TutorialLoadDocuments />
    return (
        <>
            <div className="page d-flex fs-5 vh-100">
                <div className="content w-100 mw-450px mx-auto">

                    <div className="card d-flex mh-100 h-100 p-8">
                        <div className="h-100 p-0" style={
                            modal[0].overflowScroll === true
                                ? { maxHeight: `calc(100vh - ${userDevice === 'IPhone' ? '101' : '70'}px)` }
                                : {}
                        }>
                            <CustomValidatorHook id={modalID ?? ''} fromPage={unitID} unitID={unitID} objectID={objectID} callback={callback} handleReset={handleReset} initialState={initialData.initialState} modals={modals}>
                                <CustomFieldsHook modal={modal} isAuth={false} unitID={unitID} objectID={objectID} />
                            </CustomValidatorHook>
                            {/* <div style={{ height: '70px' }}></div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
