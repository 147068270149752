import React, {type ReactElement, useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import Icon from "../../../../icon/Icon";
import {nanoid} from "nanoid";
import dayjs from "dayjs";
interface InputDateRangeProps {
    row: Record<string, any>
    control: any,
    setError: any
}
export default function InputDateRange ({row, control, setError}: InputDateRangeProps): ReactElement {
    const availableDateOffsetH = 72
    const minRange = 10
    const maxRange = 30
    const availableDate = dayjs().add(availableDateOffsetH, 'hour')
    const [pickerTarget, setPickerTarget] = useState<'From' | 'To'>('From')

    return (
        <div className={'mb-8 flex-grow-1'}>
            <Controller
                name={row.id}
                control={control}
                defaultValue={row.defaultValue}
                rules={{
                    required: row.required === true ? '' : undefined,
                    validate: (dates) => {
                        const from = dates?.From != null ? dayjs(dates?.From) : undefined
                        const to = dates?.To != null ? dayjs(dates?.To) : undefined

                        if (from == null || to == null) {
                            return false
                        }

                        console.log(to.diff(from, 'day'))

                        if (to.diff(from, 'day') < minRange) {
                            return `Minimum range is ${minRange} days`
                        }

                        if (to.diff(from, 'day') > maxRange) {
                            return `Maximum range is ${maxRange} days`
                        }

                        return true
                    }
                }}
                render={({field, fieldState: { error }}) => {
                    const dateValues = {
                        From: field.value?.From != null ? dayjs(field.value?.From) : undefined,
                        To: field.value?.To != null ? dayjs(field.value?.To) : undefined
                    }
                    return <div className={'d-flex flex-column gap-5 h-100'}>
                        <div className={'d-flex gap-5'}>
                            <div className={`flex-grow-1 d-flex gap-4 align-items-center border border-radius-8px px-5 py-3 ${pickerTarget === 'From' ? 'border-dark' : 'border-gray-300'}`}
                                onClick={() => {
                                    setPickerTarget('From')
                                }}
                            >
                                <Icon name={'calendar_month'} className={'fs-4x text-gray-500'}/>
                                <div>
                                    <div className={'fs-7 text-gray-500'}>
                                        From
                                    </div>
                                    <div className={`fs-5 fw-medium ${field.value?.From != null ? 'text-dark' : 'text-gray-400'}`}>
                                        {
                                            dayjs(field.value?.From).format('MMM D, YYYY')
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={`flex-grow-1 d-flex gap-4 align-items-center border border-radius-8px px-5 py-3 ${pickerTarget === 'To' ? 'border-dark' : 'border-gray-300'}`}
                                onClick={() => {
                                    if (field.value?.From != null) {
                                        setPickerTarget('To')
                                    }
                                }}
                            >
                                <Icon name={'calendar_month'} className={'fs-4x text-gray-500'}/>
                                <div>
                                    <div className={'fs-7 text-gray-500'}>
                                        To
                                    </div>
                                    <div className={`fs-5 fw-medium ${field.value?.To != null ? 'text-dark' : 'text-gray-400'}`}>
                                        {
                                            dayjs(field.value?.To).format('MMM D, YYYY')
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        { error != null && error.message !== ''
                            ? <div className={'d-flex align-items-center justify-content-between text-danger fs-5 fw-medium'}>
                                <div>
                                    {error.message}
                                </div>
                                <Icon name={'error'} className={'fs-1'} imageSize={48}/>
                            </div>
                            : <>
                            </>
                        }
                        <div className={'h-100'}>
                            <div className={'d-flex fs-5 fw-medium pb-4 text-gray-400 border border-gray-400 border-top-0 border-end-0 border-start-0'}>
                                <div className={'flex-grow-1 text-center'}>SUN</div>
                                <div className={'flex-grow-1 text-center'}>MON</div>
                                <div className={'flex-grow-1 text-center'}>TUE</div>
                                <div className={'flex-grow-1 text-center'}>WED</div>
                                <div className={'flex-grow-1 text-center'}>THU</div>
                                <div className={'flex-grow-1 text-center'}>FRI</div>
                                <div className={'flex-grow-1 text-center'}>SAT</div>
                            </div>
                            <div className={'h-100 overflow-auto'}>
                                {
                                    [...Array(12)].map((_, i) => {
                                        const month = dayjs().startOf('month').add(i, 'month')
                                        const firstDayOffset = month.day()
                                        const daysInMonth = month.daysInMonth()
                                        return (
                                            <>
                                                <div className={'py-5 fs-2 fw-medium text-center'}>
                                                    {
                                                        month.format('MMMM YYYY')
                                                    }
                                                </div>
                                                <div className={'fs-3 py-2'}
                                                    style={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(7, 1fr)'
                                                    }}
                                                >
                                                    {[...Array(firstDayOffset + daysInMonth)].map((_, i) => {
                                                        const isDate = i >= firstDayOffset
                                                        if (!isDate) {
                                                            return (
                                                                <div
                                                                    key={nanoid()}
                                                                    className={
                                                                        `col py-2`
                                                                    }
                                                                />
                                                            )
                                                        }
                                                        const date = month.add(i - firstDayOffset, 'day')
                                                        const isVisiblyDisabled = availableDate.isAfter(date)
                                                        const isDisabled = [
                                                            availableDate.isAfter(date),
                                                            pickerTarget === 'To' && date.isBefore(dateValues.From)
                                                            // pickerTarget === 'To' && date.isBefore(dateValues.From?.add(minRange, 'day')),
                                                            // pickerTarget === 'To' && date.isAfter(dateValues.From?.add(maxRange, 'day'))
                                                        ].some(Boolean)

                                                        let dateClassName: string = ''
                                                        const dateStyle: React.CSSProperties = {}

                                                        if (dateValues.From != null && ( dateValues.To == null || dateValues.From.isSame(dateValues.To)) && dateValues.From.isSame(date)) {
                                                            dateClassName += ' bg-bso'
                                                            dateStyle.borderRadius = '36px'
                                                        } else if (dateValues.From != null && dateValues.To != null && dateValues.From.isSame(date)) {
                                                            dateClassName += ' bg-bso'
                                                            dateStyle.borderTopLeftRadius = '36px'
                                                            dateStyle.borderBottomLeftRadius = '36px'
                                                        } else if (dateValues.From != null && dateValues.To != null && dateValues.To.isSame(date)) {
                                                            dateClassName += ' bg-bso'
                                                            dateStyle.borderTopRightRadius = '36px'
                                                            dateStyle.borderBottomRightRadius = '36px'
                                                        } else if (dateValues.From != null && dateValues.To != null && date.isBefore(dateValues.To) && date.isAfter(dateValues.From)) {
                                                            dateClassName += ' bg-bso'
                                                        }
                                                        return <div
                                                            key={nanoid()}
                                                            className={
                                                                `col text-center py-2 ${isVisiblyDisabled ? 'text-gray-300' : 'text-gray-900'}${dateClassName}`
                                                            }
                                                            style={dateStyle}
                                                            onClick={() => {
                                                                if (!isDisabled) {
                                                                    if (dateValues[pickerTarget]?.isSame(date) === true) {
                                                                        field.onChange(
                                                                            {
                                                                                ...(field.value ?? {}),
                                                                                [pickerTarget]: undefined
                                                                            }
                                                                        )
                                                                    } else {
                                                                        field.onChange(
                                                                            {
                                                                                ...(field.value ?? {}),
                                                                                [pickerTarget]: date.toISOString()
                                                                            }
                                                                        )
                                                                        if (pickerTarget === 'From') {
                                                                            setPickerTarget('To')
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                (i - firstDayOffset + 1)
                                                            }
                                                        </div>
                                                    })}
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }}
            />
        </div>
    )
}