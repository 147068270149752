import { type AppDispatch, type RootState, store } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { createDocument, scanDocuments } from '../../../documentActions'
import {
    landlordAddUnitClearStorage,
    landlordAddUnitReadFromStorage,
    landlordAddUnitWriteToStorage
} from '../utils/landlordAddUnitHandleStorage'
import { type NavigateFunction } from 'react-router-dom'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'
import compareNames from '../../../../../functions/shared/user/compareNames/compareNames'
import dayjs from 'dayjs'
import { type User } from '../../../../slices/userSlice'

export const scanUnitTitleDeed = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    const currentUser = getState().user.currentUser as User
    try {
        landlordAddUnitClearStorage()
        const response = await dispatch(scanDocuments(data.title_deed, 'Title Deed', true))
        const documentId: number | null = response?.[0]?.id
        if (response[0]?.response?.document?.valid === true && response[0]?.response?.document?.owners?.length > 0) {
            if (documentId != null) {
                landlordAddUnitWriteToStorage('title_deed', response[0])
                const storedData = landlordAddUnitReadFromStorage()

                const userIsLandlord = storedData?.title_deed?.response?.document?.owners?.some((owner: any) => compareNames(
                    String(owner.name),
                    {
                        FirstName: String(currentUser?.ContactInfo?.FirstName),
                        FamilyName: String(currentUser?.ContactInfo?.FamilyName)
                    }
                ))
                if (userIsLandlord === true) {
                    const userHasValidPassport = currentUser?.Documents?.some(doc => (
                        doc?.Type?.[0]?.__component === 'documents.passport' &&
                        dayjs(doc?.ExpiryDate).isAfter(dayjs())
                    ))
                    if (userHasValidPassport === true) {
                        return {
                            textNotification: '',
                            textNavigate: `/landlord/modal/landlord_create_unit_info/${nanToNull(unitID) ?? '-'}/-`,
                            isSuccessful: true
                        }
                        // return await dispatch(addUnit('add_info_step', nanToNull(unitID)))
                    } else {
                        return {
                            textNotification: '',
                            textNavigate: `/landlord/modal/landlord_create_unit_users_passport/${nanToNull(unitID) ?? '-'}/-`,
                            isSuccessful: true
                        }
                    }
                } else {
                    return {
                        textNotification: '',
                        textNavigate: `/landlord/modal/landlord_create_unit_owners_passport/${nanToNull(unitID) ?? '-'}/-`,
                        isSuccessful: true
                    }
                }
            }
        } else {
            const documentResponse = await dispatch(createDocument(
                null,
                {
                    Valid: false,
                    Type: [{
                        __component: 'documents.title-deed'
                    }]
                },
                undefined,
                undefined,
                response[0].url
            ))
            response[0].id = documentResponse?.response?.data?.id
            landlordAddUnitWriteToStorage('title_deed', { ...response[0], needsVerification: true })
            /* const taskResponse = await dispatch(createNewTask({
                AssignedTo: Number(process.env.REACT_APP_BSO_USER_ID),
                Documents: [
                    documentId
                ],
                Type: 'Document Verification',
                Statuses: [
                    'To do'
                ],
                Unit: unitResponse?.response?.data?.id,
                StartDate: dayjs()
            })) */
            dispatch(dataFetchingFinish())
            return {
                // ...taskResponse,
                isSuccessful: true,
                textNotification: '',
                textNavigate: '/landlord/modal/landlord_create_unit_request_validation/-/-'
            }
        }
    } catch (err) {
        console.log('Error while title deed scanning', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export const skipScanUnitTitleDeed = (navigate: NavigateFunction, unitID?: number) => () => {
    const currentUnit = store.getState().units.units.find(unit => unit.id === unitID)
    const currentDocuments = store.getState().documents.documents.filter(doc => (
        currentUnit?.attributes?.Documents?.data?.some((unitDoc) => (
            unitDoc.id === doc.id
        ))
    ))
    const titleDeedDocument = currentDocuments.findLast(doc => doc?.attributes?.Type?.[0]?.__component === 'documents.title-deed')
    const titleDeedData = {
        success: true,
        response: {
            document: {
                number: titleDeedDocument?.attributes?.Type?.[0]?.Number,
                type: titleDeedDocument?.attributes?.Type?.[0]?.Type,
                owners: currentUnit?.attributes?.Landlords?.map((owner: any) => ({
                    id: owner?.Landlord?.data?.id,
                    name: `${owner?.Landlord?.data?.attributes?.ContactInfo?.FirstName as string ?? '-'} ${owner?.Landlord?.data?.attributes?.ContactInfo?.FamilyName as string ?? '-'}`,
                    valid: true
                })),
                property: {
                    // number: '1905',
                    number: currentUnit?.attributes?.Number,
                    name: currentUnit?.attributes?.Property?.data?.attributes?.Name,
                    area: currentUnit?.attributes?.Property?.data?.attributes?.Area,
                    type: currentUnit?.attributes?.Property?.data?.attributes?.Type,
                    id: currentUnit?.attributes?.Property?.data?.id
                },
                valid: true
            },
            type: 'Title Deed'
        },
        url: titleDeedDocument?.attributes?.Link,
        id: titleDeedDocument?.id
    }
    landlordAddUnitWriteToStorage('title_deed', titleDeedData)
    navigate(`/landlord/modal/landlord_create_unit_info/${String(unitID)}/-`)
}
