import QueryString from 'qs'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../../urls'
import { advertisesSlice } from '../../slices/advertisesSlice'

import { type AppDispatch, type RootState } from '../../store'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'
import getUserType from '../../../functions/shared/user/getUserType'
import qs from 'qs'

export const fetchAdvertises = (noLading?: boolean) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) as string
    const userID = window.localStorage.getItem(USER_ID_KEY) as string

    return async (dispatch: AppDispatch, getState: () => RootState) => {
        if (noLading !== true) {
            dispatch(advertisesSlice.actions.advertisesFetching())
        }

        try {
            const bodyObject = {
                method: 'GET',
                collection: 'unit-advertises',
                // query: qs.stringify(fetchAdvertisesQueryObject, { encodeValuesOnly: true })
                query: getQueryAdvertises()
                //  `${isAgent ? `filters[User][id][$eq]=${userID}&` : ''}populate[User][fields][0]=id&populate[Unit][fields][0]=id` // [id]
            }

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify(bodyObject)
            })

            const responseJSON = await response.json()
            if (responseJSON.success) {
                if (responseJSON.response.error != null) {
                    dispatch(advertisesSlice.actions.advertisesFetchingError(getErrorss(responseJSON)))
                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
                } else {
                    dispatch(advertisesSlice.actions.advertisesFetchingSuccess(responseJSON?.response?.data))
                    return { ...responseJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
                }
            } else {
                dispatch(advertisesSlice.actions.advertisesFetchingError(getErrorss(responseJSON)))
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (error) {
            dispatch(advertisesSlice.actions.advertisesFetchingError(JSON.stringify(error)))
            console.log(error)
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    }
}
