import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type IAdvertise } from '../../../store/slices/advertisesSlice'
import { type RootState } from '../../../store/store'
import { USER_ID_KEY } from '../../../urls'
import { showAdvertisesSwals } from '../../../functions/agent/advertises/showAdvertisesSwals'
import { setAlreadyShown } from '../../../store/slices/swalsSlice'

interface IProps {
    dataLoaded: boolean

}

export default function AlertNeedCreateLinks (dataLoaded: IProps) {
    const dispatch = useAppDispatch()

    const oneCicleShowPopupsViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.oneCicleShowPopupsViewingReschedule)
    const showPopupViewingFeedback = useAppSelector((state: RootState) => state.swalsStates.showPopupViewingFeedback)
    const showPopupViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.showPopupViewingReschedule)
    const firstLengthSolidStackViewingsReschedule = useAppSelector((state: RootState) => state.swalsStates.firstLengthSolidStackViewingsReschedule)
    const alreadyShown = useAppSelector((state: RootState) => state.swalsStates.alreadyShown)
    const [show, setShow] = useState(false)

    const userID = window.localStorage.getItem(USER_ID_KEY)

    const advertises = useAppSelector(
        (state: RootState) => state.advertises.advertises
    )

    const filteredAdvertises = Array.isArray(advertises)
        ? advertises.filter((adv: IAdvertise) => adv.attributes.User.data?.id === Number(userID))
        : []

    const alertsAdvertises = Array.isArray(filteredAdvertises)
        ? filteredAdvertises.filter(
            (adv: IAdvertise) =>
                adv?.attributes?.Statuses?.includes('Docs received') &&
                (adv?.attributes?.Links === null || adv?.attributes?.Links === undefined)
        )
        : []

    const navigate = useNavigate()

    useEffect(() => {
        if ((oneCicleShowPopupsViewingReschedule && !showPopupViewingFeedback && !showPopupViewingReschedule) || (!oneCicleShowPopupsViewingReschedule && firstLengthSolidStackViewingsReschedule === 0)) {
            if (!alreadyShown) {
                setShow(true)
                dispatch(setAlreadyShown(true))
            }
        }
    }, [oneCicleShowPopupsViewingReschedule, showPopupViewingFeedback, alreadyShown])

    useEffect(() => {
        if (show) {
            if (Array.isArray(alertsAdvertises) && alertsAdvertises.length > 0) {
                const allAdvertises = alertsAdvertises.reduce((accumulator, current) => {
                    accumulator += current.id + ','
                    return accumulator
                }, '')

                showAdvertisesSwals(allAdvertises, navigate, 'Warning')
                setShow(false)
            }
        }
    }, [show])

    return <></>
}
