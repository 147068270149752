import React, { useEffect, useState } from 'react'
import StartSub from './StartSub'

import MidSub from './MidSub'
import EndSub from './EndSub'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { mainSwalDeal, swalJustAlertAndRedirect } from '../../../../functions/swalOptions'

import { Navigate, useNavigate } from 'react-router-dom'
import { type IOrder, type OrderService } from '../../../../store/slices/userSlice'
import { createSubscriptionAgent, createSubscriptionAgentWithPaymentMethod } from '../../../../store/actionsCreators/subscriptionsActions/createSubscriptionAgent'
import FormPaymentComponent from './FormPaymentComponent'
import { Switch } from 'antd'
import swalsConfig from '../../../../app/texts/swalTexts'
import { upgrateSubscriptionAgent } from '../../../../store/actionsCreators/subscriptionsActions/upgrateSubscriptionAgent'
export default function SubscritionsList(): JSX.Element {
    const isDev = window.localStorage.getItem('BSO_DEVELOP_MODE')

    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    // console.log(currentUser, 'currentUser')
    const userID = currentUser.id

    // const ServiceAgentSubscription = currentUser.Orders && currentUser.Orders.filter((servise: OrderService) => servise.__component === 'services.broker-subscription')

    const ServiceAgentSubscription = (currentUser.Orders as IOrder[])?.filter(
        (order: IOrder) => order.Service.some((service) => service.__component === 'services.broker-subscription')
    )
    ServiceAgentSubscription?.sort((a, b) => new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime())

    const latestServiceAgentSubscription = ServiceAgentSubscription?.[0]
    //  console.log(latestServiceAgentSubscription, 'latestServiceAgentSubscription')
    const packageTitle = latestServiceAgentSubscription?.Service?.[0]?.Package
    //  console.log(packageTitle, 'packageTitle')
    const activeSub = packageTitle?.includes('Premium') ? 'Premium' : packageTitle?.includes('Standard') ? 'Standard' : packageTitle?.includes('Free') ? 'Free' : '' // 'free'
    const activeSubPeriod = latestServiceAgentSubscription?.Name?.includes('(Yearly)') ? 'annual' : latestServiceAgentSubscription?.Name?.includes('(Weekly)') ? 'weekly' : 'month'

    const [isLiveMode, setIsLiveMode] = useState(process.env.REACT_APP_IS_DEV === 'true')
    const [selectedSubscription, setSelectedSubscription] = useState<string>(activeSub)
    const [selectedPeriod, setSelectedPeriod] = useState<string>(activeSubPeriod)

    const { buttonSubscritionsOpen } = useAppSelector((state: RootState) => state.modal)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!activeSub) {
            setSelectedSubscription('Free')
        } else {
            setSelectedSubscription(activeSub)
        }
    }, [buttonSubscritionsOpen, activeSub])

    //  console.log({ activeSub, activeSubPeriod, selectedSubscription, selectedPeriod })

    const onChange = (checked: boolean) => {
        //  console.log(`switch to ${checked}`)
        setIsLiveMode(checked)
    }

    const handleSubscriptionChange = async (renew?: string): Promise<void> => {
        let isUpgradeSub = false
        let orderID: number | undefined
        if (Boolean(activeSub) && activeSub !== 'Free') {
            if (activeSub !== selectedSubscription) {
                isUpgradeSub = true
                orderID = latestServiceAgentSubscription.id
            }
        }
        // selectedSubscription === 'Premium' && (activeSub === 'Free' || activeSub === '') ? undefined :

        if (isDev === 'true') {
            void mainSwalDeal(
                swalsConfig.mainDeal.textSubscriptionListRenew(renew),
                swalsConfig.mainDeal.titleSubscriptionList,
                swalsConfig.mainDeal.confirmButtonSubscriptionList(selectedSubscription === 'Free'),
                swalsConfig.mainDeal.denyButtonSubscriptionList
            ).then(async (result: any) => {
                if (result.isConfirmed) {
                    //     navigate('/agent/settings/subscriptions/choose')
                    // const createOrder = await dispatch(createSubscriptionAgent(selectedSubscription))
                    let createOrder: any

                    if (selectedSubscription === 'Free') {
                        createOrder = await dispatch(createSubscriptionAgent(selectedSubscription))

                        // console.log(createOrder?.url, 'createOrder?.url')
                    } else {
                        //   console.log({ isUpgradeSub, orderID })
                        if (isUpgradeSub) {
                            if (orderID === undefined) {
                                console.error('Variable orderID is not assigned')
                                return
                            }
                            createOrder = await dispatch(upgrateSubscriptionAgent(selectedSubscription, { orderID, selectedPeriod, isLiveMode }))
                        } else {
                            createOrder = await dispatch(createSubscriptionAgentWithPaymentMethod(selectedSubscription, undefined, { selectedPeriod, isLiveMode }))
                        }
                    }

                    if (createOrder?.isSuccessful) {
                        if (isUpgradeSub) {
                            if (selectedSubscription === 'Premium') {
                                void swalJustAlertAndRedirect(swalsConfig.justAlertAndRedirect.messageAfterUpgradeSubscription(selectedSubscription)).then(async (result: any) => {
                                    if (result.isConfirmed) {
                                        navigate('/agent/profile/me')
                                    }
                                })
                            } if (selectedSubscription === 'Standard') {
                                void swalJustAlertAndRedirect(swalsConfig.justAlertAndRedirect.messageAfterUpgradeSubscription(selectedSubscription)).then(async (result: any) => {
                                    if (result.isConfirmed) {
                                        navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/Standard`)
                                    }
                                })
                            }
                        } else {
                            if (selectedSubscription === 'Free') {
                                navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/${selectedSubscription}`)
                            } else {
                                window.open(createOrder?.url, '_self')
                            }
                        }
                    }
                }
            })
        } else {
            let createOrder: any

            if (selectedSubscription === 'Free') {
                createOrder = await dispatch(createSubscriptionAgent(selectedSubscription))

                // console.log(createOrder?.url, 'createOrder?.url')
            } else {
                //   console.log({ isUpgradeSub, orderID })
                if (isUpgradeSub) {
                    if (orderID === undefined) {
                        console.error('Variable orderID is not assigned')
                        return
                    }
                    createOrder = await dispatch(upgrateSubscriptionAgent(selectedSubscription, { orderID, selectedPeriod, isLiveMode }))
                } else {
                    createOrder = await dispatch(createSubscriptionAgentWithPaymentMethod(selectedSubscription, undefined, { selectedPeriod, isLiveMode }))
                }
            }

            if (createOrder?.isSuccessful) {
                if (isUpgradeSub) {
                    if (selectedSubscription === 'Premium') {
                        void swalJustAlertAndRedirect(swalsConfig.justAlertAndRedirect.messageAfterUpgradeSubscription(selectedSubscription)).then(async (result: any) => {
                            if (result.isConfirmed) {
                                navigate('/agent/profile/me')
                            }
                        })
                    } if (selectedSubscription === 'Standard') {
                        void swalJustAlertAndRedirect(swalsConfig.justAlertAndRedirect.messageAfterUpgradeSubscription(selectedSubscription)).then(async (result: any) => {
                            if (result.isConfirmed) {
                                navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/Standard`)
                            }
                        })
                    }
                } else {
                    if (selectedSubscription === 'Free') {
                        navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/${selectedSubscription}`)
                    } else {
                        window.open(createOrder?.url, '_self')
                    }
                }
            }
        }
    }

    return (

        <div>

            <div className="d-flex flex-row flex-fill justify-content-center m-4 ">
                <img
                    alt="Logo"
                    src="/assets/media/logo.png"
                    className="h-60px mx-auto"
                />
            </div>

            {/* <div className='text-end my-2'> */}
            {/*    <Switch checkedChildren="Live mode" size="default" unCheckedChildren="Test mode" defaultChecked onChange={onChange}/> */}
            {/* </div> */}
            {isDev === 'true'
                ? <div className="nav-group nav-group-outline mx-auto mb-4 text-center" >

                    <button className={`btn btn-color-gray-400 btn-active btn-active-secondary px-8 py-3 me-2 ${selectedPeriod === 'weekly' ? 'active' : ''} `} onClick={() => { setSelectedPeriod('weekly') }}>
                        Weekly
                    </button>
                    <button className={`btn btn-color-gray-400 btn-active btn-active-secondary px-8 py-3  ${selectedPeriod === 'month' ? 'active' : ''} `} onClick={() => { setSelectedPeriod('month') }} >
                        Monthly
                    </button>
                    <button className={`btn btn-color-gray-400 btn-active btn-active-secondary px-8 py-3 ${selectedPeriod === 'annual' ? 'active' : ''} `} onClick={() => { setSelectedPeriod('annual') }}>
                        Annual
                    </button>

                </div>
                : null
            }
            <div className="col-lg-6 mb-10 mb-lg-0">
                <div className="nav flex-column" role="tablist">
                    <StartSub handleSubscriptionChange={handleSubscriptionChange} activeSub={activeSub} activeSubPeriod={activeSubPeriod} selectedPeriod={selectedPeriod} selectedSubscription={selectedSubscription} setSelectedSubscription={setSelectedSubscription} isDev={isDev === 'true'} />
                    {/* {activeSub === 'Free' || activeSub === '' ? StartSub : null} */}
                    {isDev === 'true'
                        ? <>
                            <MidSub handleSubscriptionChange={handleSubscriptionChange} activeSub={activeSub} activeSubPeriod={activeSubPeriod} selectedPeriod={selectedPeriod} selectedSubscription={selectedSubscription} setSelectedSubscription={setSelectedSubscription} />
                            <EndSub handleSubscriptionChange={handleSubscriptionChange} activeSub={activeSub} activeSubPeriod={activeSubPeriod} selectedPeriod={selectedPeriod} selectedSubscription={selectedSubscription} setSelectedSubscription={setSelectedSubscription} />
                        </>
                        : null}

                </div>

            </div>

        </div>
    )
}
