import React, { useState } from 'react'
import { createSubscriptionAgentWithPaymentMethod } from '../../../../store/actionsCreators/subscriptionsActions/createSubscriptionAgent'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { store, type RootState } from '../../../../store/store'
import { setIsAreasForBuyBricks, setIsAreasOpen, setIsBricksOpen, setShowButtonBuyCoinsOnAdvertise, setShowPopupAddOnsAreas, setShowPopupAddOnsBricks } from '../../../../store/slices/modalSlice'
import { setNotEnoughMarketingCredits, setUnitIDnotEnoughAreaForAdvertise } from '../../../../store/slices/advertisesSlice'
import { areaExpansionImage, marketingCreditsImage, USER_AREAS_EXTEND_COUNT } from '../../../../urls'
import { nanoid } from 'nanoid'
import RulesModals from '../../../shared/auth/RulesModals'
import { type ISettings } from '../../../../store/slices/userSlice'
import { mainSwalDeal } from '../../../../functions/swalOptions'
import { makePositiveOrUnchanged } from '../../../../functions/agent/date/makePositiveOrUnchanged'
import { useNavigate } from 'react-router-dom'

interface IProps {
    alone?: true | undefined
    // setButtonDriverAddOnsParent?: React.Dispatch<React.SetStateAction<boolean>> | undefined
}
// setButtonDriverAddOnsParent
export default function ChooseAreasList({ alone }: IProps) {
    const limitCoins = alone ? [1] : [1, 3, 5, 10]
    // const limitCoins = [1]
    const [activePack, setActivePack] = useState(alone ? 1 : 0)
    const dispatch = useAppDispatch()
    const loading = useAppSelector(state => state.modal.loading)
    const unitID = useAppSelector((state: RootState) => state.advertises.unitIDnotEnoughAreaForAdvertise)
    const units = useAppSelector((state: RootState) => state.units.units).filter((u) => u.id === Number(unitID))
    const unitArea = units?.[0]?.attributes?.Property?.data?.attributes?.Area ?? ''

    const currentUser = useAppSelector((state) => state.user.currentUser)
    const currentUserMarketingCredits = Number((currentUser?.Settings as ISettings[])?.[0]?.MarketingCredits) ?? 0

    const navigate = useNavigate()

    const onBuyPack = async (countPack: number) => {
        const createOrder = await dispatch(createSubscriptionAgentWithPaymentMethod(`pack_areas_${countPack}`, unitID, { isLiveMode: process.env.REACT_APP_IS_DEV === 'true', selectedPeriod: '' }))
        if (createOrder?.isSuccessful) {
            if (createOrder?.url === 'error') {
                void mainSwalDeal('You don\'t have enough "BRICKS" , do you want to buy more for area expansion', undefined, 'Yes, I do', 'Not now').then(async (result: any) => {
                    if (result.isConfirmed) {
                        dispatch(setShowPopupAddOnsAreas(false))
                        dispatch(setIsAreasOpen(false))
                        dispatch(setNotEnoughMarketingCredits(makePositiveOrUnchanged((countPack === 10 ? 70 : countPack === 5 ? 35 : countPack === 3 ? 25 : countPack * 10) - currentUserMarketingCredits)))
                        dispatch(setIsAreasForBuyBricks(true))
                        dispatch(setIsBricksOpen(true))
                        dispatch(setShowPopupAddOnsBricks(true))
                    }
                })
            } else {
                const actualUser = store.getState().user?.currentUser
                const lastPayDestination = actualUser?.LastPayDestination
                const storageCountAreasExtend = window.localStorage.getItem(USER_AREAS_EXTEND_COUNT)

                const [chooseExtAreaIdUnit, countAreasExtend] = storageCountAreasExtend
                    ? (typeof storageCountAreasExtend === 'string' ? storageCountAreasExtend.split(' - ') : [undefined, '3'])
                    : [undefined, '3']

                navigate(`/agent/modal/agent_settings_for_areas_extension/${chooseExtAreaIdUnit === 'undefined' || chooseExtAreaIdUnit === undefined ? 'addons' : Number(chooseExtAreaIdUnit) ? chooseExtAreaIdUnit : 'addons'}/${actualUser.id}`)
            }
        }
    }

    return (
        <>
            <img
                className=" w-100 h-275px object-fit-contain mb-2 rounded-0 rounded-bottom rounded-top opacity-100 bg-success"
                src={areaExpansionImage}
                alt={'mark_liable'}
            />
            <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center pt-5 pb-7 mb-8 card-rounded my-4">
                <span className="fs-2 fw-bold counted text-center px-1"> Unlock New Markets with Area Expansion Add-On</span>

                <div className="separator separator-dotted border-success my-2 mx-4"></div>

                <span className="fw-semi-bold text-gray-600 fs-6 pb-1 text-justify px-4"> Utilize our in-app currency, Bricks, to unlock the Area Expansion Add-On and maximize your
                    real estate potential.</span>

            </div>

            <div className="col-lg-6 mb-10 mb-lg-0">
                <div className="nav flex-column" >
                    {/* onClick={() => { setSelectedSubscription('Free') }} */}
                    {/* ${selectedSubscription === 'Free' ? 'bg-primary' : ''} */}

                    {limitCoins.map((count) =>
                        <div className={`card card-dashed p-2  mb-4 bg-light-primary  ${activePack === count ? 'border border-primary' : ''}`}
                            onClick={() => { setActivePack(count) }}
                            key={nanoid()}
                        >
                            <div className="d-flex justify-content-between align-items-center mb-1 rounded p-2 mb-2">
                                <div className="d-flex align-items-center me-2 ">
                                    <span className={`badge badge-lg border border-5  badge-circle badge-light-success me-6 ${activePack === count ? 'border-success' : 'border-secondary'}`}></span>
                                    <div className="flex-grow-1">
                                        <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                                            Pack   {count} <span className="ms-2 fs-6 fw-normal">Area</span>
                                        </div>

                                    </div>
                                </div>

                                <div className="ms-5">
                                    <span className="mb-2 me-1 fs-5">Bricks</span>
                                    {/* AED */}
                                    <span
                                        className="fs-3x fw-bold"
                                    >
                                        {count === 10 ? 70 : count === 5 ? 35 : count === 3 ? 25 : count * 10}

                                    </span>

                                    <span className="fs-7 opacity-50">
                                        /<span>
                                            At once

                                        </span>
                                    </span>
                                </div>
                            </div>
                            <>
                                {
                                    alone && unitID && unitArea
                                        ? <div className="ms-2 text-start p-2 mb-2">
                                            Extend area -     <span
                                                className="fs-3 fw-bold"
                                            >{unitArea}</span>
                                        </div>
                                        : null
                                }
                                {activePack === count
                                    ? <div className="text-end" >
                                        {/* onClick={() => { handleSubscriptionChange() }} */}
                                        <button className="btn btn-sm btn-bso"
                                            onClick={() => { onBuyPack(count) }}
                                        >
                                            {loading
                                                ? <span className="indicator-progress d-block">
                                                    Please wait...
                                                    <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                </span>
                                                : <span className="indicator-label">
                                                    To buy
                                                </span>
                                            }

                                        </button>
                                    </div>

                                    : null}   </>
                        </div>)}

                </div>

            </div>
            <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center pt-5 pb-7 mb-8 card-rounded my-4">
                <div className="fs-2 fw-bold counted text-gray-800 text-start px-4 mb-2 text-center">
                    Benefits of Area Expansion Add-On:
                </div>
                <span className="fs-2 fw-bold counted text-gray-800"> Unlock Untapped Markets:

                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Dive into new districts, unveiling hidden opportunities and
                    expanding your reach to a wider client base.

                </span>

                <span className="fs-2 fw-bold counted text-gray-800"> Priority Property Access:

                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Gain exclusive rights to market properties in your chosen areas.
                    This gives you a head start in showcasing listings and attracting potential clients effectively.

                </span>
                <span className="fs-2 fw-bold counted text-gray-800">        Personalised Recommendations:

                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Get tailored suggestions based on expanded areas,
                    ensuring you find properties that perfectly match your preferences and meet your clients' needs.
                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Ready to unlock new markets and elevate your real estate game? Use Bricks to access the
                    Area Expansion Add-On
                </span>
            </div>

        </>
    )
}
// area expansion
