import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { Outlet, useNavigate } from 'react-router'
import Loader from '../../app/custom/loaders/Loader'
import { type AppDispatch, type RootState } from '../../store/store'

import {
    fetchUser,
    fetchUsers,
    updateUserTokenDevice
} from '../../store/actionsCreators/userActions'
import { fetchViewings } from '../../store/actionsCreators/viewingsActions'
import { fetchUnits } from '../../store/actionsCreators/unitsActions'
import { fetchProperties } from '../../store/actionsCreators/propertyActions'
import ViewingRescheduled from './componentsViewings/ViewingRescheduled'

import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import {
    fetchOffers
} from '../../store/actionsCreators/offerActions'

import { ACCESS_KEY, USER_ID_KEY } from '../../urls'
import { fetchDocuments } from '../../store/actionsCreators/documentActions'
import AlertsComponentVerify from '../../AlertsComponentVerify'
import LandscapePlaceholder from '../shared/landscape-placeholder'
import useOrientation from '../../hooks/UseOrientation'
import { getChatList } from '../../store/actionsCreators/chatListActions'
import SearchProvider from '../../app/SearchProvider'

import { fetchAdvertises } from '../../store/actionsCreators/advertiseActions/fetchAdvertiseAction'
import { fetchAppConfig } from '../../store/actionsCreators/appActions'
import AlertNeedCreateLinks from './advertise/AlertNeedCreateLinks'
import ViewingFeedback from './componentsViewings/ViewingFeedback'

import { AgentLoadProcessData } from './AgentLoadProcessData'
import { socketReplayConnection } from '../../store/actionsCreators/userActions/socketReplayConnection'
import { getBroadcasts } from '../../store/actionsCreators/broadcastActions/getBroadcasts'
import { type NavigateFunction } from 'react-router-dom'
import { userUpdateCountLeaving } from '../../store/actionsCreators/userActions/userUpdateCountLeaving'


export default function AgentModule(): JSX.Element {
    const [dataLoaded, setDataLoaded] = useState(false)
    const isUserVerified = useAppSelector((state: RootState) => state.modal.isUserVerified)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const { unitsLoading, units } = useAppSelector((state: RootState) => state.units)
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)

    const loading = useAppSelector((state: RootState) => state.user.loading)
    const { viewingsLoading, viewings } = useAppSelector((state: RootState) => state.viewings)
    const documentsLoading = useAppSelector((state: RootState) => state.documents.documentsLoading)
    const offersLoading = useAppSelector((state: RootState) => state.offers.offersLoading)
    let userID = window.localStorage.getItem(USER_ID_KEY)

    const oneCicleShowPopupsViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.oneCicleShowPopupsViewingReschedule)
    const showPopupViewingFeedback = useAppSelector((state: RootState) => state.swalsStates.showPopupViewingFeedback)
    const showPopupViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.showPopupViewingReschedule)
    const firstLengthSolidStackViewingsReschedule = useAppSelector((state: RootState) => state.swalsStates.firstLengthSolidStackViewingsReschedule)
    const { orientation } = useOrientation()
    const [wb, setWb] = useState<any>({})
    let webSocket = {} as any

    useEffect(() => {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        }

        const initializeWebSocket = (dispatch: AppDispatch, navigate: NavigateFunction): void => {
            setDataLoaded(true)
            webSocket = new WebSocket('wss://bso.ae/ws')
            setWb(webSocket)
            void socketReplayConnection(webSocket, dispatch, navigate)
        }

        const checkToken = async (dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
            const maxAttempts = 16
            let attemptCount = 0
            let currentToken: string | null = null

            while (attemptCount < maxAttempts && !currentToken) {
                try {

                    currentToken = (window as unknown as Window & { devicetoken: string }).devicetoken || window.localStorage.getItem('devicetoken')
                    //   currentToken = window.localStorage.getItem('devicetoken')
                    if (currentToken) {
                        void dispatch(updateUserTokenDevice(currentToken))
                        initializeWebSocket(dispatch, navigate)
                        break
                    } else {
                        attemptCount++
                        await new Promise(resolve => setTimeout(resolve, 500))
                    }
                } catch (error) {
                    console.error('Error updating token', error)
                    setDataLoaded(true)
                    break
                }
            }

            if (!currentToken) {
                void dispatch(updateUserTokenDevice(`BrowserTokenUnfound${userID}`))
                initializeWebSocket(dispatch, navigate)
            }
        }

        const loadUserData = async (): Promise<void> => {
            const jwt = window.localStorage.getItem(ACCESS_KEY)
            userID = window.localStorage.getItem(USER_ID_KEY)

            if (jwt && userID) {
                const loadAll = await Promise.all([
                    dispatch(fetchUser()),
                    dispatch(fetchViewings()),
                    dispatch(fetchOffers()),
                    dispatch(fetchUnits()),
                    dispatch(fetchDocuments()),
                    dispatch(fetchUsers()),
                    dispatch(fetchProperties()),
                    dispatch(getChatList()),
                    dispatch(getBroadcasts()),
                    dispatch(fetchAdvertises()),
                    dispatch(fetchAppConfig())
                ])

                const isLoad = loadAll.every((el: any) => el?.isSuccessful === true || el?.success === true)

                if (isLoad) {
                    await dispatch(userUpdateCountLeaving())
                    const userAgent = navigator.userAgent
                    const isIPhone = _.includes(userAgent.toLowerCase(), 'iphone')
                    const isAndroid = _.includes(userAgent.toLowerCase(), 'android')

                    if (isAndroid) {
                        try {
                            const { initializeApp } = await import('firebase/app')
                            const firebaseApp = initializeApp(firebaseConfig)
                            const { getMessaging, getToken, onMessage } = await import('firebase/messaging')
                            const messaging = getMessaging(firebaseApp)

                            if (Notification.permission === 'default') {
                                const permission = await Notification.requestPermission()
                                if (permission === 'granted') {
                                    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                                    if (currentToken != null) {
                                        console.log('FB Token:', currentToken.slice(0, 5) + '...')
                                        await dispatch(updateUserTokenDevice(currentToken))
                                        initializeWebSocket(dispatch, navigate)
                                    } else {
                                        await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                        initializeWebSocket(dispatch, navigate)
                                    }
                                } else if (permission === 'denied') {
                                    await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                    initializeWebSocket(dispatch, navigate)
                                    alert('Notifications have been blocked by the user.')
                                }
                            } else if (Notification.permission === 'granted') {
                                const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                                if (currentToken != null) {
                                    console.log('FB Token:', currentToken.slice(0, 5) + '...')
                                    await dispatch(updateUserTokenDevice(currentToken))
                                    initializeWebSocket(dispatch, navigate)
                                } else {
                                    await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                    initializeWebSocket(dispatch, navigate)
                                }
                            } else if (Notification.permission === 'denied') {
                                await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                initializeWebSocket(dispatch, navigate)
                                alert('Notifications have been blocked by the user.')
                            }

                            onMessage(messaging, (payload) => {
                                navigator.serviceWorker.ready.then((registration) => {
                                    registration.showNotification(payload.notification?.title as string, {
                                        body: payload.notification?.body
                                    })
                                }).catch((e) => { console.log(e) })
                            })
                        } catch (err) {
                            console.log(err)
                        }
                    } else if (isIPhone) {
                        await checkToken(dispatch, navigate)
                    } else {
                        setDataLoaded(true)
                        await dispatch(updateUserTokenDevice(`BrowserTokenUnknownToken${userID}`))
                        initializeWebSocket(dispatch, navigate)
                    }

                    setDataLoaded(true)
                } else {
                    setDataLoaded(false)
                    navigate('/auth/login')
                }
            }
        }
        window.myController = new AbortController()
        void loadUserData()
    }, [])

    useEffect(() => {
        if (dataLoaded) {
            void AgentLoadProcessData(navigate, dispatch, location)
        }
    }, [dataLoaded])

    useEffect(() => {
        const func = () => {
            if (wb.readyState === 3) {
                webSocket = new WebSocket('wss://bso.ae/ws')
                setWb(webSocket)
                void socketReplayConnection(webSocket, dispatch, navigate)
            }
        }
        window.myController.abort()
        window.myController = new AbortController()
        window.addEventListener('open-app', func, { signal: window.myController.signal })
    }, [wb])

    if (!dataLoaded || loading || unitsLoading || viewingsLoading || documentsLoading || offersLoading) {
        return <Loader />
    }

    return (
        <>
            <SearchProvider>
                {
                    orientation === 'landscape'
                        ? <LandscapePlaceholder />
                        : <>
                            {isUserVerified || currentUser?.emailVerified ? null : location.pathname.includes('agent/modal/update_passport_together') || location.pathname.includes('/agent/modal/update_emirates_back_together') || location.pathname.includes('/agent/modal/update_emirates_front_together') || location.pathname.includes('/auth/register_scan_emirates') ? null : <AlertsComponentVerify />}
                            {dataLoaded &&
                                isUserVerified
                                ? <>
                                    {location.pathname.includes('agent_update_viewing_modal') || showPopupViewingFeedback ? null : <ViewingRescheduled currentUser={currentUser} viewings={viewings} dispatch={dispatch} dataLoaded={dataLoaded} />}
                                    {oneCicleShowPopupsViewingReschedule || (firstLengthSolidStackViewingsReschedule === 0 && !oneCicleShowPopupsViewingReschedule)
                                        ? <ViewingFeedback currentUser={currentUser} viewings={viewings} dispatch={dispatch}
                                            dataLoaded={dataLoaded} units={units} />
                                        : null}
                                    {
                                        (oneCicleShowPopupsViewingReschedule && !showPopupViewingFeedback && !showPopupViewingReschedule || (firstLengthSolidStackViewingsReschedule === 0 && !oneCicleShowPopupsViewingReschedule && !showPopupViewingFeedback && !showPopupViewingReschedule))
                                            ? !location.pathname.includes('agent_advertise_create_start_date') ? <AlertNeedCreateLinks dataLoaded={dataLoaded} /> : null
                                            : null}
                                </>
                                : null}
                            <Outlet />
                        </>
                }
            </SearchProvider>
        </>
    )
}
