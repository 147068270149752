import React from 'react'
import AgentFooter from '../AgentFooter'
import SubscritionsList from '../settings/ordersSubscriptions/SubscritionsList'
import AgentHeader from '../AgentHeader'

export default function AgentSubscriptions () {
    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
            >
                <AgentHeader active="My Subscription" />
                <div
                    className="content d-flex flex-column flex-fill p-0 m-4"
                >
                    <div className="w-100 mw-450px mx-auto">
                        <SubscritionsList/>
                    </div>
                </div>
                <div className="m-0" style={{ height: '110px' }} />
            </div>
            <AgentFooter />
        </>

    )
}
