import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import ActionCard from '../../landlordModule/adminActions/actionCard'
import { animationOptions, swalAttentionLeave, swalOptions } from '../../../functions/swalOptions'
import Swal from 'sweetalert2'

import { updateStatusOfferAgent } from '../../../store/actionsCreators/offerActions/agentToAgentOfferCommunication/updateStatusOfferAgent'
import { createOfferAgentToAgent } from '../../../store/actionsCreators/broadcastActions/createOfferAgentToAgent'
import UseUserType from '../../../hooks/UseUserType'
import { openChatByUserID } from '../../../store/actionsCreators/chatActions'
import MainLoader from '../../../app/custom/loaders/MainLoader'
import { setOfferStatus } from '../../../store/actionsCreators/offerActions'

export default function OfferActionsForLandlords (): JSX.Element {
    const { unitID, objectID: offerID } = useParams()

    const { userType } = UseUserType()
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const offer = offers.filter((off: any) => off.id === Number(offerID))[0]
    const typeOffer = offer?.attributes?.Type?.[0]?.__component?.split('.')[1] ?? ''
    // console.log(offer?.attributes?.Type?.[0]?.__component, 'offer?.attributes?.Type?.[0]?.__component')
    // console.log(typeOffer, 'typeOffer')
    // const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    // const units = useAppSelector((state: RootState) => state.units.units)
    // const unit = _.find(units, (unit) => unit.id === Number(unitID))
    const [localLoading, setLocalLoading] = useState(true)
    // const userToConnect = offer?.attributes?.Unit?.data?.attributes?.User?.data?.id
    const userToConnect = offer?.attributes?.User?.data?.id
    // console.log(userToConnect, 'userToConnect')
    //  const offerStatuses = (offer?.attributes)?.Statuses
    // const offerStatuses = []
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const iconText = 'Success'

    const optionsSuccess = {
        ...swalOptions('success'),
        ...{

            showConfirmButton: true,
            iconHtml: '<div  id="lottie-icon-success></div>',
            confirmButtonText: 'Got it',
            didOpen: async () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                const pathLottie = await import(`../../../../src/animations/${iconText}.json`)
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: pathLottie.default }
                    })
                }
            }

        }
    }

    return (
        <>
            {!localLoading ? <MainLoader title={'In process'} />

                : <div className={'d-flex flex-column w-100'}>
                    { offer?.attributes?.Statuses?.[0] !== 'Confirmed'
                        //  (offerStatuses?.includes('In-process') || offerStatuses?.includes('Rescheduled by agent')) &&
                        ? <ActionCard
                            title={'Approve'}
                            description={'Approve offer'}
                            onClick={
                                () => {
                                    swalAttentionLeave('Are you sure?')
                                        .then(async (result: any) => {
                                            if (result.isConfirmed) {
                                                setLocalLoading(true)
                                                const res = await dispatch(
                                                    setOfferStatus(Number(offerID), 'Confirmed', undefined, { rule: 'user-offer-approved', receiver: 'Agent' })
                                                )
                                                if (res?.success === true && res?.response?.data != null) {
                                                    setLocalLoading(false)

                                                    await Swal.fire({
                                                        ...optionsSuccess,
                                                        title: 'Confirmed!',
                                                        html: 'You have successfully confirmed offer'
                                                    }).then(async (value: any) => {
                                                        if (value.isConfirmed) {
                                                            navigate(`/landlord/modalView/offer_modal/${String(unitID)}/${String(offerID)}/my_unit?type=${typeOffer ?? 'not'}`)
                                                        }
                                                    })
                                                } else {
                                                    setLocalLoading(false)

                                                    await Swal.fire({
                                                        ...swalOptions('error'),
                                                        ...{
                                                            title: 'Something wrong',
                                                            html: 'Try again'
                                                        }
                                                    })
                                                }
                                            }
                                        })
                                        .catch(console.log)
                                }
                            }
                            icon={'check_circle'}
                            styles={'mb-3 mx-n3'}
                        />
                        : <></>
                    }
                    {
                        offer?.attributes?.Statuses[0] !== 'Canceled'
                        //   (offerStatuses?.includes('Approved') || offerStatuses?.includes('In-process') || offerStatuses?.includes('Rescheduled by other agent') || offerStatuses?.includes('Rescheduled by agent')) &&
                            ? <ActionCard
                                title={'Reject'}
                                description={'Reject offer'}
                                onClick={() => {
                                    void (async () => {
                                        setLocalLoading(true)
                                        // if (result.isConfirmed) {
                                        const res = await dispatch(
                                            setOfferStatus(Number(offerID), 'Canceled', undefined, { rule: 'user-offer-canceled', receiver: 'Agent' })
                                        )
                                        if (res?.success === true && res?.response?.data != null) {
                                            setLocalLoading(false)

                                            await Swal.fire({
                                                ...optionsSuccess,
                                                title: 'Rejected!',
                                                html: 'You have successfully rejected offer'
                                            }).then(async (value: any) => {
                                                if (value.isConfirmed) {
                                                    navigate(`/landlord/modalView/offer_modal/${String(unitID)}/${String(offerID)}/my_unit?type=${typeOffer ?? 'not'}`)
                                                }
                                            })
                                        }
                                    })()
                                }}
                                icon={'cancel'}
                                styles={'mb-3 mx-n3'}
                            />
                            : <></>
                    }
                    {
                        //  (offerStatuses?.includes('Approved') || offerStatuses?.includes('In-process') || offerStatuses?.includes('Rescheduled by agent')) &&
                        offer?.attributes?.Statuses?.[0] !== 'Negotiated by landlord'
                            ? <ActionCard
                                title={'Negotiate'}
                                description={'Negotiate offer'}
                                onClick={
                                    () => {
                                        navigate(`/landlord/modal/negotiate_offer_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                                    }
                                }
                                icon={'event_available'}
                                styles={'mb-3 mx-n3'}
                            />
                            : <></>
                    }
                    {
                        offer?.attributes?.Statuses?.[0] === 'Confirmed'
                            ? <ActionCard
                                title={'Set completed'}
                                description={'Close the deal'}
                                onClick={
                                    () => {
                                        swalAttentionLeave('Are you sure?')
                                            .then(async (result: any) => {
                                                if (result.isConfirmed) {
                                                    setLocalLoading(true)
                                                    const res = await dispatch(
                                                        setOfferStatus(Number(offerID), 'Completed', undefined, { rule: 'user-offer-completed', receiver: 'Agent' })
                                                    )
                                                    if (res?.success === true && res?.response?.data != null) {
                                                        setLocalLoading(false)

                                                        await Swal.fire({
                                                            ...optionsSuccess,
                                                            title: 'Completed!',
                                                            html: 'You have successfully completed the offer process'
                                                        }).then(async (value: any) => {
                                                            if (value.isConfirmed) {
                                                                navigate(`/landlord/modalView/offer_modal/${String(unitID)}/${String(offerID)}/my_unit?type=${typeOffer ?? 'not'}`)
                                                            }
                                                        })
                                                    } else {
                                                        setLocalLoading(false)

                                                        await Swal.fire({
                                                            ...swalOptions('error'),
                                                            ...{
                                                                title: 'Something wrong',
                                                                html: 'Try again'
                                                            }
                                                        })
                                                    }
                                                }
                                            })
                                            .catch(console.log)
                                    }
                                }
                                icon={'check_circle'}
                                styles={'mb-3 mx-n3'}
                            />
                            : <></>
                    }
                    <ActionCard
                        title={'Chat'}
                        description={'Chat any time about some details '}
                        onClick={
                            () => {
                                //   console.log(unit?.attributes?.User?.data?.id, 'unit?.attributes?.User?.data?.id', currentUser.id, 'currentUser.id')
                                //      if (user.id !== store.getState().user.currentUser.id) {
                                dispatch(openChatByUserID(userToConnect as number))
                                    .then(({ id }: { id: any }) => {
                                        if (id != null) {
                                            navigate(`/${userType.toLowerCase()}/chats/${String(id)}`)
                                        }
                                    })
                                //   }
                            }
                        }
                        icon={'chat_bubble'}
                        styles={'mb-3 mx-n3'}

                    />
                </div>}
        </>
    )
}
