import React, { useState, type Dispatch, type SetStateAction } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'

import { Modal } from 'antd'
import { type ISettings, type User } from '../../../../../store/slices/userSlice'
import Icon from '../../../../../app/icon/Icon'
import CloseIconBasic from '../../../../shared/elements/CloseIconBasic'
import { mainSwalDeal } from '../../../../../functions/swalOptions'
import { ava_blank } from '../../../../../urls'

interface IProps {
    previewOpen?: boolean
    setPreviewOpen: Dispatch<SetStateAction<boolean>>
    currentUser: User
}

export default function MyProfileHead({
    previewOpen,
    setPreviewOpen,
    currentUser
}: IProps): JSX.Element {
    const userCONTACTS = (currentUser)?.Type?.[0]
    const userContactsINFO = (currentUser)?.ContactInfo
    const userSettings = currentUser?.Settings as ISettings[]
    const userID = currentUser.id
    const navigate = useNavigate()
    const [imageUrl, setImageUrl] = useState<string>(
        userCONTACTS?.Photo ?? ava_blank
    )
    const licenseApproved = useAppSelector(
        (state: RootState) => state.agentStates.statusesApproved.licenseApproved
    )

    const upgradeAvatar = () => {
        void mainSwalDeal('Want to change your avatar?', undefined, 'Yes, I do', 'Not now').then(async (result: any) => {
            if (result.isConfirmed) {
                navigate(`/agent/modal/agent_avatar/${userID}/${userID}`)
            } if (result.isDenied) {

            }
        })
    }

    return (
        <>

            <div className="d-flex flex-sm-nowrap">
                <div className="me-7 mb-4 position-relative">
                    <div
                        onClick={() => {
                            setPreviewOpen(true)
                        }}
                        className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
                    >
                        <img
                            className="object-fit-cover border rounded"
                            src={imageUrl}
                            alt="image"
                        />

                        <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>

                    </div>
                    <Modal
                        open={previewOpen}
                        footer={null}
                        onCancel={() => {
                            setPreviewOpen(false)
                        }}

                        closeIcon={
                            <CloseIconBasic onCancel={() => {
                                setPreviewOpen(false)
                            }} cls={''} style={{ top: '-5px', right: '-5px' }} />
                        }
                        centered
                        styles={{
                            body: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '50vh'
                            }
                        }}
                    >
                        <img
                            alt="example"
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                            src={imageUrl}
                            className='position-relative object-fit-cover w-100'
                        />
                        <div style={{ top: '11px', left: '12px' }} className='position-absolute' onClick={upgradeAvatar}>
                            <div className={'p-2'} style={{ borderRadius: '1rem', background: '#00000066' }}>
                                <i className="ki-duotone ki-pencil fs-2x p-1">
                                    <span className="path1 text-white"></span><span className="path2 text-white"></span>
                                </i>
                                {/* <input type="file" name="avatar" accept=".png, .jpg, .jpeg" className='opacity-0 w-2px' />
                                <input type="hidden" name="avatar_remove" /> */}
                            </div>

                        </div>
                    </Modal>
                </div>
                <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                                <span className="text-gray-900 fs-2 fw-bold me-1 ">
                                    {`${userContactsINFO?.FirstName
                                        ? userContactsINFO?.FirstName
                                        : userCONTACTS?.Name
                                        } ${userContactsINFO?.FamilyName
                                            ? userContactsINFO?.FamilyName
                                            : ''
                                        }`}
                                </span>
                                <span className={'d-flex align-items-center'}>
                                    {licenseApproved
                                        ? <Icon name={'verified'} className={'fs-1 text-warning'} />
                                        : <Icon name={'verified'} className={'fs-1 text-primary'} />
                                    }

                                </span>
                            </div>

                            {/* <div className="d-flex flex-wrap fw-semi-bold fs-6 pe-2">
                                <span
                                    className="d-flex align-items-center text-gray-400  me-5 mb-2"
                                >
                                    <Icon name={'leaderboard'} className={'fs-1 fs-4 me-1 text-muted'}/>
                  Rating {userSettings?.[0]?.Performance ?? 0}
                                </span>
                            </div> */}

                            <div className="d-flex flex-wrap fw-semi-bold fs-6 pe-2">
                                <span className="d-flex align-items-center text-gray-400  mb-2">
                                    <Icon name={'mail'} className={'fs-1 fs-4 me-1 text-muted'} />
                                    {(currentUser)?.email}
                                </span>
                                <br />
                            </div>
                            <div className={'fw-semi-bold'}>
                                <span

                                    className="d-flex align-items-center text-gray-400 mb-1"
                                >
                                    <Icon name={'call'} className={'fs-1 fs-4 me-1 text-muted'} />
                                    {(currentUser)?.phone
                                        ? (currentUser)?.phone
                                        : ' No phone'}
                                </span>
                            </div>

                            <div className={'mb-4 fw-semi-bold'}>
                                <span

                                    className="d-flex align-items-center text-gray-400 mb-2"
                                >
                                    <Icon name={'location_on'} className={'fs-1 fs-4 me-1 text-muted'} />
                                    {userContactsINFO?.Country
                                        ? userContactsINFO?.Country
                                        : ''}
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
