import React from 'react'
import AgentHeader from '../AgentHeader'
import SubscritionsList from '../settings/ordersSubscriptions/SubscritionsList'

export default function AgentSubscriptionsChoose (): JSX.Element {
    return (
        <div
            className="page d-flex flex-column flex-row-fluid fs-5"
        >
            <div className="text-center py-4 mt-4" >
                <h1 className="text-dark m-0 fs-3" style={{ paddingTop: '28px' }}>
                Please, choose a subscription
                </h1>
            </div>
            {/* <AgentHeader active="Subscriptions" /> */}
            <div
                className="content d-flex flex-column flex-fill p-0 m-4"
            >
                <div className="w-100 mw-450px mx-auto">
                    <SubscritionsList/>
                </div>
            </div>
            <div className="m-0" style={{ height: '100px' }} />
        </div>
    )
}
