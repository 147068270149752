import React, {type ReactElement} from "react";
import {type Unit} from "../../../../../../store/slices/unitsSlice";
import Card from "../../../../UIv2/Card/Card";
import LandlordUnitCardUnitInfo from "./LandlordUnitCardUnitInfo";
import LandlordUnitCardServicesList from "./LandlordUnitCardServices/LandlordUnitCardServicesList";
import LandlordUnitCardActions from "./LandlordUnitCardActions/LandlordUnitCardActions";
import useUserType from "../../../../../../hooks/UseUserType";
import {useNavigate} from "react-router-dom";

interface LandlordUnitCardProps {
    unit: Unit
    key: string
}
export default function LandlordUnitCard ({unit, key}: LandlordUnitCardProps): ReactElement {
    const { userTypeLowercase } = useUserType()
    const navigate = useNavigate()
    return <Card key={key} rounded={'8'} style={'white'}>
        <div className={'d-flex flex-column gap-6 px-6 pt-8 pb-6'}
            onClick={(e) => {
                if ((e.target as Element).closest('.click-ignore') == null) {
                    navigate(`/${userTypeLowercase}/unit/${unit.id}`)
                }
            }}
        >
            <LandlordUnitCardUnitInfo unit={unit}/>
            <LandlordUnitCardServicesList unit={unit}/>
            <LandlordUnitCardActions unit={unit}/>
        </div>
    </Card>
}