import React, {type ReactElement, useState} from "react";
import ChatInDrawer from "../../../../../components/shared/newChat/chat/ChatInDrawer";
import {useAppDispatch} from "../../../../../hooks/redux";
import {openChatByUserID} from "../../../../../store/actionsCreators/chatActions";

interface ContactSupportProps {
    row: Record<string, any>
}

export default function ContactSupport ({row}: ContactSupportProps): ReactElement {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [chatID, setChatID] = useState(0)
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()
    function openSupportChat (): void {
        dispatch(openChatByUserID(Number(process.env.REACT_APP_BSO_USER_ID)))
            .then(({ id }: { id: any }) => {
                if (id != null) {
                    setChatID(id)
                    console.log(id)
                    setDrawerOpen(true)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <>
            <div className={`pt-2 ${row.stickToBottom === true ? 'h-100' : 'h-fit'} d-flex flex-column justify-content-end`}>
                <div className={`text-center fs-4 fw-medium text-gray-500 ${row.stickToBottom === true ? 'mb-5' : 'mb-8'}`}
                    onClick={() => {
                        if (chatID !== 0 && chatID != null) {
                            setDrawerOpen(true)
                        } else {
                            openSupportChat()
                        }
                    }}
                >
                    {
                        loading
                            ? <span>Please wait</span>
                            : <>
                                <span className={'me-1'}>
                                    Having difficulty?
                                </span>
                                <span className={'text-decoration-underline'}
                                    style={{
                                        textUnderlineOffset: '3px'
                                    }}
                                >
                                    Chat support now
                                </span>
                            </>
                    }
                </div>
            </div>
            <ChatInDrawer
                chatID={chatID}
                open={drawerOpen}
                onClose={() => {
                    setDrawerOpen(false)
                }}
            />
        </>
    )
}