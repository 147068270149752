import {type AppDispatch, type RootState} from "../../../../../store";
import {serviceFindingTenantWriteToStorage} from "../utils/findingTenantHandleStorage";
import {uploadStrapiFile} from "../../../../fileActions";
import {dataFetchingFinish, dataFetchingStart} from "../../../../../slices/modalSlice";

export const serviceFindTenantPhotos  = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    try {
        const promises: Array<Promise<boolean>> = []
        if (data.Images?.length > 0) {
            const imagesPromises = data.Images
                .filter((image: File) => image.size != null) // filter saved images
                .map(async (image: File) => {
                    const response = await uploadStrapiFile(image, 'units', String(unitID))
                    return response?.data
                })
            const imagesPromise = (async (): Promise<boolean> => {
                const images = await Promise.all(imagesPromises)
                data.Images = [
                    ...images,
                    ...data.Images.filter((image: File) => image.size == null).map((image: any) => image.url) // saved images
                ]
                return true
            })()
            promises.push(imagesPromise)
        }
        if (data.Video?.[0] != null) {
            if (data.Video?.[0]?.size != null) {
                const videoPromise = (async (): Promise<boolean> => {
                    const response = await uploadStrapiFile(data.Video?.[0], 'units', String(unitID))
                    data.Video = response?.data
                    return true
                })()
                promises.push(videoPromise)
            } else {
                data.Video = data.Video?.[0]?.url
            }
        }
        if (data?.FloorPlan?.[0] != null) {
            if (data.FloorPlan?.[0]?.size != null) {
                const floorPlanPromise = (async (): Promise<boolean> => {
                    const response = await uploadStrapiFile(data.FloorPlan[0], 'units', String(unitID))
                    data.FloorPlan = response?.data
                    return true
                })()
                promises.push(floorPlanPromise)
            } else {
                data.FloorPlan = data.FloorPlan?.[0]?.url
            }
        }
        console.log(promises)
        if (promises.length > 0) {
            await Promise.all(promises)
        }
        serviceFindingTenantWriteToStorage('service_finding_tenant_photos', data)
        dispatch(dataFetchingFinish())
        return {
            isSuccessful: true,
            textNavigate: `/landlord/modal/v2/service_finding_tenant_addons/${unitID ?? '-'}/-`,
            textNotification: ''
        }
    } catch (err) {
        console.error('Error in serviceFindTenantPhotos', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false,
        textNotification: 'An error occurred while uploading media. Please try again'
    }
}