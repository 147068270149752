import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { Collapse } from 'antd'
import { type ISettings, type User } from '../../../../store/slices/userSlice'

const { Panel } = Collapse

interface IProps {
    currentUser: User
}

export default function CreditsBSO ({ currentUser }: IProps): JSX.Element {
    const credits = currentUser.Settings?.[0]?.BSOCredits ?? null
    // console.log(credits, 'credits')
    //  const dispatch = useAppDispatch()
    return (
        <div className="card shadow-sm mb-2">
            <Collapse defaultActiveKey={'credits_bso'} >
                <Panel
                    style={{ fontFamily: 'Poppins' }}
                    showArrow={false}

                    header={<span className="card-title fs-3 fw-bold fw-bold p-0 m-0">BSO Credits</span>}
                    key="credits_bso"
                >    <div className="card-body p-0 m-0">
                        {credits ? <div className="col-xl-6 " data-kt-billing-element="card">

                            <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6" style={{ backgroundColor: '#fcf7de' }}>

                                <div className="d-flex  flex-column py-2 ">

                                    <div className="d-flex align-items-center text-uppercase fs-4 fw-bold mb-5">
                                        {(currentUser)?.ContactInfo?.FirstName} {(currentUser)?.ContactInfo?.FamilyName}
                                    </div>

                                    <div className="d-flex align-items-center ">

                                        <div className="d-flex flex-column min-w-80px min-h-40px me-4 py-2 bg-warning rounded text-center lh-1">
                                            <span className="d-block  text-white fw-bold fs-3 m-0">
BSO
                                            </span>
                                            <span className="d-block text-white fw-semi-bold fs-7 m-0">CREDITS</span>
                                        </div>

                                        <div>
                                            <div className="fs-4 fw-bold">1234 2213 **** 1290</div>
                                            <div className="fw-semi-bold fs-4 text-dark">IBAN</div>
                                        </div>

                                    </div>

                                </div>

                                <div className="d-flex align-items-center py-2">
                                    {/* <button className="btn btn-sm  border border-dark text-dark btn-active-light-primary me-3" >
            <span className="indicator-label">Delete</span>
            <span className="indicator-progress">
Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
        </button> */}

                                    <div className="ms-auto">
                                        <div onClick={() => {
                                            //  navigate('/agent/modal/my_profile_reset_password_way_email/not/not')
                                        }
                                        } className="btn btn-sm fs-6 border border-dark text-dark">Withdrawal</div>
                                    </div>
                                    {/* <button className="btn btn-sm  border border-dark text-dark btn-active-light-primary" >Edit</button> */}
                                    {/* style={{ backgroundColor: '#fff200', borderColor: '#fff200' }} */}
                                    {/* <button className="btn btn-sm  border border-dark text-dark text-dark ms-3" >Withdrawal of funds</button> */}

                                </div>
                            </div>

                        </div> : <div> Not credits</div>}

                    </div></Panel>
            </Collapse>

        </div>
    )
}
