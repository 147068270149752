import React, { useEffect, useState } from 'react'
import { type Unit } from '../../../store/slices/unitsSlice'
import { useAppDispatch } from '../../../hooks/redux'
import { type IAdvertise } from '../../../store/slices/advertisesSlice'
import { timeStringInScheduler } from '../../../functions/agent/date/timeStringInScheduler'

import { viewAdvertise } from '../../../store/actionsCreators/advertiseActions/viewAdvertise'
import SecondTryTable from './SecondTryTable'
import AdveretiseTimeline from './advertiseTimelines/AdvertiseTimeline'
import useUserType from '../../../hooks/UseUserType'
import AgentInfoForChildren from './contactInfo/AgentInfoForChildren'

interface IProps {
    currentUnit: Unit
    currentAdvertise: IAdvertise // advertiseID: string | undefined
}

interface StateLink {
    Title: string
    Link: string
    Trakheesi_id: string
    Status: string
}
// advertiseID
export default function ChildAdvertise({ currentAdvertise, currentUnit }: IProps): JSX.Element {

    const dispatch = useAppDispatch()

    const { userType } = useUserType()
    let stateLinks: StateLink[] = []


    useEffect(() => {
        if (currentAdvertise.attributes.hasOwnProperty('Links') && currentAdvertise.attributes.Links !== null) {
            if (Array.isArray(currentAdvertise.attributes.Links)) {
                stateLinks = currentAdvertise.attributes.Links as StateLink[]
            } else if (typeof currentAdvertise.attributes.Links === 'object') {
                stateLinks = [currentAdvertise.attributes.Links] as StateLink[]
            }
        }
        const landlordViewStatuses = ['Links added', 'Advertisement started', 'Ads removed', 'A Form added']
        if (userType === 'Agent' && currentAdvertise?.attributes?.Notify === 'Agent') {
            dispatch(
                viewAdvertise({
                    advID: currentAdvertise.id,
                    notify: null, // 'BSO',
                    view: currentUnit
                })
            )
        } else if (userType === 'Agent' && currentAdvertise?.attributes?.Notify === 'Both') {
            dispatch(
                viewAdvertise({
                    advID: currentAdvertise.id,
                    notify: 'BSO',
                    view: currentUnit
                })
            )
        } else if (userType === 'Landlord' && currentAdvertise?.attributes?.Notify === 'Both') {
            dispatch(
                viewAdvertise({
                    advID: currentAdvertise.id,
                    notify: 'Agent',
                    view: currentUnit
                })
            )
        } else if (userType === 'Landlord' && currentAdvertise?.attributes?.Notify === 'BSO' && !landlordViewStatuses.includes(currentAdvertise?.attributes?.Statuses?.[0])) {
            dispatch(
                viewAdvertise({
                    advID: currentAdvertise.id,
                    notify: null,
                    view: currentUnit
                })
            )
        }
    }, [])
    const [advertiseKey, setAdvertiseKey] = useState(0)

    useEffect(() => {
        setAdvertiseKey((prevKey) => prevKey + 1)
    }, [currentAdvertise])

    return (
        <>
            <div className="fv-row mb-2 m-0 p-0 w-100">
                <div className="d-flex align-items-center">
                    <div className="fs-3 fw-bolder text-dark mb-4 lh-1">   Advertisement Information</div>
                    {currentAdvertise !== null && currentAdvertise?.attributes?.StartDate
                        ? <div className="fv-row mb-2 m-0 p-0 w-100 text-end">
                            <label className="form-label fw-bold text-dark m-0 ">
                                Advertisement duration
                            </label>
                            <div className="fw-normal text-dark " >
                                Your advertisement for this unit is active since <strong style={{ whiteSpace: 'nowrap' }}>{timeStringInScheduler((currentAdvertise?.attributes?.StartDate)).split(',')[0]}</strong>
                            </div>
                            <div className="fw-normal text-dark">
                                and ends on <strong>{timeStringInScheduler((currentAdvertise?.attributes?.EndDate as string)).split(',')[0]}</strong>
                            </div>
                        </div>
                        : null}
                </div>
                <div className="fv-row mb-2 m-0 p-2 w-100">
                    <div className="separator border border-bso border-3 px-2 my-2"></div>
                </div>
                <AdveretiseTimeline key={advertiseKey} advertise={currentAdvertise} unit={currentUnit} />

                <div className="fv-row my-2  p-2 w-100">
                    <div className="separator border border-bso border-3 px-2 my-2"></div>
                </div>
                {stateLinks && stateLinks.length > 0
                    ? <>
                        <div className={'d-flex align-items-center mb-2 fs-3 '}>
                            <span className="fw-bolder">Advertisements links</span>
                        </div>
                        <SecondTryTable stateLinks={stateLinks} />
                        <div className="fv-row my-2  p-2 w-100">
                            <div className="separator border border-bso border-3 px-2 my-2"></div>
                        </div>
                    </>
                    : null}
            </div>

            {userType === 'Landlord'
                ? <AgentInfoForChildren userID={currentAdvertise?.attributes?.User?.data?.id ?? -1} />
                : <></>
            }
        </>
    )
}
