import React, {type ReactElement, useEffect, useState} from "react";
import Checkbox from "../../../../components/shared/UIv2/Checkbox/Checkbox";
import {Controller} from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import IconButton from "../../../../components/shared/UIv2/Button/IconButton";
import {
    serviceFindingTenantReadFromStorage, serviceFindingTenantWriteToStorage
} from "../../../../store/actionsCreators/serviceActions/servicesV2/findingTenant/utils/findingTenantHandleStorage";
import {nanoid} from "nanoid";

interface FooterUpSaleProps {
    row: Record<string, any>
    control: any
    setError: (...args: any) => void
    loading: boolean
}

export default function FooterUpSale ({row, control, setError, loading}: FooterUpSaleProps): ReactElement {
    const [overlayShown, setOverlayShown] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    useEffect(() => {
        // const storedData = serviceFindingTenantReadFromStorage()
        // if (storedData.service_finding_tenant_photos?.upsale_shown !== true) {
        // toggleError(false)
        if (!loading) {
            const timeout = setTimeout(() => {
                setOverlayShown(true)
                setIsDisabled(false)
            }, 1000)
            return () => {
                clearTimeout(timeout)
            }
        }
        // }
    }, [])
    const toggleError = (toActive: boolean): void => {
        // setError(row.id, !toActive ? 'Field must be checked': undefined)
    }
    return (
        <>
            <Controller
                name={row.id}
                control={control}
                render={({field, fieldState: { error }}) => {
                    return (
                        <div className={'d-flex align-items-center justify-content-between pt-5'}>
                            <div className={'d-flex align-items-center gap-3'}
                                onClick={() => {
                                    if (!isDisabled) {
                                        if (overlayShown) {
                                            toggleError(field.value !== true)
                                        }
                                        field.onChange(field.value !== true)
                                    }
                                }}
                            >
                                <Checkbox
                                    checked={field.value === true}
                                    style={'light'}
                                />
                                <div>
                                    {/* Add BSO Photography */}
                                    {
                                        row.actionText
                                    }
                                </div>
                            </div>
                            <div>
                                {
                                    row.price
                                }
                                {/* 1,000 AED */}
                            </div>
                        </div>
                    )
                }}
            />
            <AnimatePresence>
                { overlayShown
                    ? <motion.div className={'d-flex flex-column justify-content-end position-absolute top-0 end-0 start-0 overflow-hidden'}
                        initial={{
                            background: 'rgba(128, 128, 128, 0)',
                            backdropFilter: 'blur(0px)'
                        }}
                        animate={{
                            background: 'rgba(128, 128, 128, 0.3)',
                            backdropFilter: "blur(12px)"
                        }}
                        transition={{ duration: 0.5 }}
                        exit={{
                            background: 'rgba(128, 128, 128, 0)',
                            backdropFilter: 'blur(0px)'
                        }}
                        style={{
                            // backdropFilter: 'blur(12px)',
                            // background: '#8080804D',
                            bottom: '129px'
                        }}
                    >
                        <motion.div className={'py-16 px-10 bg-gray-100 position-relative d-flex flex-column gap-5 overflow-hidden'}
                            initial={{
                                marginBottom: -400
                            }}
                            animate={{
                                marginBottom: 0
                            }}
                            transition={{ duration: 0.5 }}
                            exit={{
                                marginBottom: -400
                            }}
                            style={{
                                borderTopLeftRadius: '24px',
                                borderTopRightRadius: '24px'
                            }}
                        >
                            <div className={'rounded-circle d-flex align-items-center justify-content-center bg-white'}
                                style={{
                                    height: '48px',
                                    width: '48px'
                                }}
                            >
                                {/* <img width={30} src="/assets/media/landlord/services/icons/finding-tenant.svg" alt=""/> */}
                                <img width={30} src={row.iconSrc} alt=""/>
                            </div>
                            <div className={'fs-2x fw-bold position-relative'} style={{ zIndex: 2 }}>
                                {/* Skip this step<br/> */}
                                {/* and hire BSO for<br/> */}
                                {/* professional photos! */}
                                {
                                    // row.title.replaceAll('\n', <br/>)
                                    row.title?.split('\n')?.map((line: string) => (
                                        <React.Fragment key={nanoid()}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))
                                }
                            </div>
                            <div className={'fs-3 text-gray-500 position-relative z-2'} style={{ zIndex: 2 }}>
                                {
                                    row.description?.split('\n')?.map((line: string) => (
                                        <React.Fragment key={nanoid()}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))
                                }
                            </div>
                            <img src={row.backgroundImageSrc}// src="/assets/media/landlord/services/images/gallery.png"
                                alt=""
                                className={'position-absolute z-1'}
                                style={{
                                    top: '-170px',
                                    right: '-70px',
                                    transform: 'rotate(-26deg)'
                                }}
                            />
                            <div className={'position-absolute'}
                                style={{
                                    top: '15px',
                                    right: '12px'
                                }}
                            >
                                <IconButton
                                    icon={'close'}
                                    style={'light-gray'}
                                    onClick={() => {
                                        // const storedData = serviceFindingTenantReadFromStorage()
                                        // serviceFindingTenantWriteToStorage('service_finding_tenant_photos', {
                                        //     ...storedData,
                                        //     upsale_shown: true
                                        // })
                                        setOverlayShown(false)
                                        toggleError(true)
                                    }}
                                />
                            </div>
                        </motion.div>
                    </motion.div>
                    : <></>
                }
            </AnimatePresence>
        </>
    )
}