import React, {type ReactElement} from "react";
import ClosableBlock from "../../../../../UIv2/ClosableBlock/ClosableBlock";
import {type ViewUnitSectionProps} from "../ViewUnitSection";

export default function ViewUnitSectionFloorPlan ({unit}: ViewUnitSectionProps): ReactElement {
    const floorPlanSrc = unit?.attributes?.FloorPlan
    return floorPlanSrc != null
        ? <ClosableBlock
            title={'Floor Plan'}
            closable={false}
        >
            <div
                className={'w-100 overflow-hidden bg-secondary'}
                style={{
                    borderRadius: '8px',
                    minHeight: '200px'
                }}
            >
                <img src={floorPlanSrc} className={'w-100'} alt="floor-plan"/>
            </div>
        </ClosableBlock>
        : <></>
}