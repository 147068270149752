import _ from 'lodash'

interface ComparedNameObject { FirstName: string, FamilyName: string }
type ComparedName = string | ComparedNameObject
// TODO make comparing function more flexible
export default function compareNames (name1: ComparedName, name2: ComparedName): boolean {
    const names = [name1, name2]
    const objNames = names.map((name) => {
        if (_.isString(name)) {
            return {
                FirstName: name.split(' ')[0].toLowerCase(),
                FamilyName: name.split(' ').slice(1).join(' ').toLowerCase()
            }
        }
        return {
            FirstName: name.FirstName.toLowerCase(),
            FamilyName: name.FamilyName.toLowerCase()
        }
    })

    return (
        (objNames[0].FirstName === objNames[1].FirstName && objNames[0].FamilyName === objNames[1].FamilyName) ||
        (objNames[0].FirstName === objNames[1].FamilyName && objNames[0].FamilyName === objNames[1].FirstName)
    )
}
