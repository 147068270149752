import {type AppDispatch, type RootState} from "../../../../store";
import {landlordAddUnitWriteToStorageV2} from "../utils/landlordAddUnitHandleStorageV2";
import {landlordSaveUnit} from "./landlordSaveUnit";

export const landlordCreateUnitUnitInfo  = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    if (data.Type === 'Commercial') {
        data.GuestBathroom = undefined
        data.MaidsRoom = undefined
    }
    landlordAddUnitWriteToStorageV2('landlord_create_unit_unit_info', data)
    return await dispatch(landlordSaveUnit(unitID))
}