import React, {type ReactElement} from "react";
import {Controller} from "react-hook-form";
import Icon from "../../../../icon/Icon";
import Badge from "../../../../../components/shared/UIv2/Badge/Badge";
import useFindUserByEmail from "./FindUserByEmail.hook";

interface FindUserByEmailProps {
    row: Record<string, any>
    control: any
    setError: (...args: any) => void
}

export default function FindUserByEmail ({row, control, setError}: FindUserByEmailProps): ReactElement {
    const {
        searchString,
        setSearchString,
        findUser,
        userData
    } = useFindUserByEmail(row, setError)
    return (
        <div className={'pb-8'}>
            <Controller
                name={row.id}
                control={control}
                rules={{
                    required: true,
                    validate: (value) => value != null
                }}
                render={({field, fieldState}) => {
                    return (
                        <div className={'d-flex flex-column gap-8'}>
                            <div className={'d-flex justify-content-between align-items-center py-2 ps-8 pe-2 border border-gray-300'}
                                style={{
                                    borderRadius: '48px'
                                }}
                            >
                                <input
                                    placeholder={'whiterabbit@follow.me'}
                                    type="text"
                                    className={'bg-none border-0 fs-4 fw-medium text-dark lh-1'}
                                    value={searchString}
                                    onInput={(e) => {
                                        setSearchString(e.currentTarget.value)
                                    }}
                                    style={{
                                        outline: 'none',
                                        boxShadow: 'none',
                                        width: 'calc(100% - 36px)'
                                    }}
                                />
                                <div className={'d-flex align-items-center justify-content-center bg-dark'}
                                    style={{
                                        width: '36px',
                                        height: '36px',
                                        borderRadius: '36px'
                                    }}
                                    onClick={() => {
                                        findUser(field.onChange)
                                    }}
                                >
                                    <Icon name={'arrow_forward'} className={'fs-3x text-white'}/>
                                </div>
                            </div>

                            { userData.exists
                                ? <div className={'d-flex flex-column gap-10 bg-gray-100 border border-gray-300 p-8'}
                                    style={{
                                        borderRadius: '16px'
                                    }}
                                >
                                    <div className={'fs-4 fw-medium'}>
                                        This email belong to
                                    </div>
                                    <div className={'d-flex flex-column gap-1'}>
                                        <div className={'d-flex gap-3 fs-2x fw-bold'}>
                                            <div>
                                                {
                                                    userData.firstNamePlaceholder
                                                }
                                            </div>
                                            <div>
                                                {
                                                    userData.lastNamePlaceholder
                                                }
                                            </div>
                                        </div>
                                        <div className={'d-flex gap-3'}>
                                            <Badge
                                                size={'25'}
                                                style={'gray-400'}
                                                text={userData.age ?? ''}
                                            />
                                            <Badge
                                                size={'25'}
                                                style={'gray-400'}
                                                text={userData.gender ?? ''}
                                            />
                                        </div>
                                    </div>
                                    <div className={'fs-5 text-gray-500'}>
                                        <div className={'fw-medium'}>
                                            Not the person you’re representing?
                                        </div>
                                        <div>
                                            Retype the email address or confirm with your landlord if they have a registered account.
                                        </div>
                                    </div>
                                </div>
                                : <></>
                            }
                        </div>
                    )
                }}
            />
        </div>
    )
}