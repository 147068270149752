import React, {type ReactElement, useState} from "react";
import Icon from "../../../../app/icon/Icon";

type HTMLInputTypeAttribute = "button" | "checkbox" | "color" | "date" | "datetime-local" | "email" | "file" | "hidden" | "image" | "month" | "number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week";


interface TextInputProps {
    style: 'white' | 'gray'
    value: string
    onChange: (value: string) => void
    name?: string
    type?: HTMLInputTypeAttribute
    placeholder?: string
    error?: string
    hideError?: boolean
    disabled?: boolean
    autoComplete?: string
    unit?: string
    max?: number
    min?: number
}

export default function TextInput ({value, onChange, name, type, placeholder, error, disabled = false, autoComplete, hideError = false, style, unit, max, min }: TextInputProps): ReactElement {
    const [passwordShown, setPasswordShown] = useState(false)
    return (
        <div className={'w-100 d-flex flex-column gap-3'}>
            <div className={`${style === 'white' ? 'bg-white text-input' : 'bg-gray-100 text-gray-500'} d-flex align-items-cetner justify-content-between gap-3 ps-5 border ${error == null ? 'border-secondary' : 'border-danger'} w-100`}
                style={{
                    borderRadius: '8px'
                }}
            >
                <input
                    type={
                        type === 'password' && passwordShown ? 'text' : type
                    }
                    value={value}
                    onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        onChange(e.currentTarget.value)
                    }}
                    name={name}
                    placeholder={placeholder}
                    className={'bg-none border-0 fs-5 fw-medium w-100 lh-1 py-3'}
                    style={{
                        outline: 'none',
                        boxShadow: 'none'
                    }}
                    disabled={disabled}
                    autoComplete={autoComplete as any}
                />
                { type === 'password'
                    ? <div
                        onClick={() => {
                            setPasswordShown(!passwordShown)
                        }}
                        className={'d-flex align-items-center fs-2x text-gray-400 py-3 pe-5'}
                    >
                        <Icon name={passwordShown ? 'visibility' : 'visibility_off'}/>
                    </div>
                    : type === 'number'
                        ? <div className={'d-flex flex-column border border-gray-300 border-top-0 border-right-0 border-bottom-0'}
                            style={{
                                width: '51px'
                            }}
                        >
                            <div className={'flex-grow-1 d-flex align-items-center justify-content-center border border-gray-300 border-top-0 border-left-0 border-right-0'}
                                onClick={() => {
                                    if (Number.isFinite(Number(value)) && (max == null || Number(value) < max)) {
                                        onChange((Number(value) + 1).toString(10))
                                    }
                                }}
                            >
                                <Icon name={'keyboard_arrow_up'} className={'fs-2x'}/>
                            </div>
                            <div className={'flex-grow-1 d-flex align-items-center justify-content-center'}
                                onClick={() => {
                                    if (Number.isFinite(Number(value)) && (min == null || Number(value) > min)) {
                                        onChange((Number(value) - 1).toString(10))
                                    }
                                }}
                            >
                                <Icon name={'keyboard_arrow_down'} className={'fs-2x'}/>
                            </div>
                        </div>
                        : unit != null
                            ? <div className={'d-flex align-items-center justify-content-center border border-gray-300 border-top-0 border-right-0 border-bottom-0 px-1 fs-6 fw-medium'}
                                style={{
                                    minWidth: '45px'
                                }}
                            >
                                {
                                    unit
                                }
                            </div>
                            : <></>
                }
            </div>
            { error != null && !hideError
                ? <div className={'d-flex align-items-center justify-content-between text-danger fs-5 fw-medium'}>
                    <div>
                        {error}
                    </div>
                    <Icon name={'error'} className={'fs-1'} imageSize={48}/>
                </div>
                : <>
                </>
            }
        </div>
    )
}