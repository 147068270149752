import {type IOffer} from "../../../../../../../store/slices/offersSlice";
import React, {type ReactNode} from "react";
import {nanoid} from "nanoid";
import StatusMark from "../../../../../UIv2/StatusMark/StatusMark";
import dayjs from "dayjs";

type IUnitActiveOrdersSectionFindingTenantsPreviousOfferHelper = (offer: IOffer) => [
    ReactNode, ReactNode, ReactNode
]

const unitActiveOrdersSectionFindingTenantsPreviousOfferHelper: IUnitActiveOrdersSectionFindingTenantsPreviousOfferHelper = (offer) => {
    const createdAt = dayjs(offer?.attributes?.createdAt)
    const offerPrice = offer?.attributes?.Type?.[0]?.Offer
    const chequesNumber = offer?.attributes?.Type?.[0]?.Payments
    const status = offer?.attributes?.Statuses?.[0]
    let statusType = 'success' as 'success' | 'warning' | 'danger'
    if (['Rejected', 'Canceled'].includes(status)) {
        statusType = 'danger'
    } else if (status === 'In-process') {
        statusType = 'warning'
    }
    return [
        <div key={nanoid()} className={'fs-6'}>
            {
                createdAt.format('MMM D, YYYY')
            }
        </div>,
        <div key={nanoid()} className={'d-flex flex-column align-items-center'}>
            <div className={'fs-4 fw-bold'}>
                {
                    offerPrice?.toLocaleString('en')
                } AED
            </div>
            <div className={'fs-6'}>
                {
                    chequesNumber
                } {
                    chequesNumber === 1
                        ? 'CHEQUE'
                        : 'CHEQUES'
                }
            </div>
        </div>,
        <div key={nanoid()}>
            <div className={'d-flex gap-2 align-items-center fs-6'}>
                <StatusMark style={statusType}/>
                <div>{status}</div>
            </div>
        </div>
    ]
}

export default unitActiveOrdersSectionFindingTenantsPreviousOfferHelper
