
import Swal from 'sweetalert2'
import swalConfig from '../../../app/texts/swalTexts'
import { animationOptions, swalOptions } from '../../../functions/swalOptions'
import { customCreateNotify } from '../../../store/actionsCreators/userActions/customCreateNotify'
import { createFeedbackViewingAction } from '../../../store/actionsCreators/viewingActions/createFeedbackViewingAction'
import { type IViewing } from '../../../store/slices/viewingsSlice'
import { type User } from '../../../store/slices/userSlice'
import { scanDocumentsForOffer } from '../../../store/actionsCreators/offerActions'
import { type NavigateFunction } from 'react-router-dom'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import { getCurrentDubaiDate } from '../date/getCurrentDateStrFormat'
import { viewingsQuery } from '../../../store/actionsCreators/viewingsActions'
import { setShowPopupViewingFeedback } from '../../../store/slices/swalsSlice'
import { is24HoursPassed } from '../date/is24HoursPassed'
import { getNameUser } from '../agentModuleComponent/getNameUser'
import { getUnitAddress } from '../agentModuleComponent/getUnitAddres'
import { store } from '../../../store/store'
import { toUTCWithOffset } from '../date/toUTCWithOffset'

export const showFeedbackPopup = (viewingDone: IViewing, dispatch: any, navigate: NavigateFunction) => {
    const units = store.getState().units?.units
    const currentUser = store.getState().user.currentUser as User
    dispatch(setShowPopupViewingFeedback(true))

    const userID = currentUser.id
    const unit = units.filter((el: any) => el.id === viewingDone?.attributes?.Unit?.data?.id)[0]

    const userName = getNameUser(currentUser)
    const unitAddress = getUnitAddress(unit)
    const viewingDatetime = viewingDone.attributes.Datetime
    let offerModalType = ''

    if (unit?.attributes?.Marketing?.Use === 'For rent') {
        offerModalType = unit?.attributes?.Type === 'Residential' ? 'residential' : 'commercial'
    } else {
        offerModalType = 'sale'
    }
    const paramsAnimationInfo = {
        iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
        didOpen: async () => {
            const pathLottie = await import('../../../animations/Info.json')
            const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
            if (lottieContainer != null) {
                lottie.loadAnimation({
                    container: lottieContainer,
                    renderer: 'svg',
                    ...{ ...animationOptions, animationData: pathLottie.default }
                })
            }
        }
    }

    void Swal.fire({
        ...swalOptions('info'),
        ...{
            title: swalConfig.feedbackPopup.title(unitAddress, viewingDatetime),
            html: swalConfig.feedbackPopup.htmlMessage,
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: swalConfig.feedbackPopup.confirmButtonText,
            denyButtonText: swalConfig.feedbackPopup.denyButtonText,
            allowOutsideClick: viewingDone.attributes.NotifyViewedDate == null ? true : !is24HoursPassed(viewingDone?.attributes?.NotifyViewedDate),
            backdrop: true,
            ...paramsAnimationInfo,
            preConfirm: async () => {
                const viewingTakePlace = swalConfig.feedbackPopup.questions.viewingTakePlace
                Swal.fire({
                    ...swalOptions('info'),
                    title: viewingTakePlace.title(unitAddress, viewingDatetime),
                    html: viewingTakePlace.html,
                    showConfirmButton: true,
                    showDenyButton: true,
                    confirmButtonText: viewingTakePlace.confirmButtonText,
                    denyButtonText: viewingTakePlace.denyButtonText,
                    ...paramsAnimationInfo,
                    preConfirm: async () => {
                        const viewingTakePlaceWasOkay = swalConfig.feedbackPopup.questions.viewingTakePlace.viewingTakePlaceWasOkay
                        Swal.fire({
                            ...swalOptions('info'),
                            ...{
                                title: viewingTakePlaceWasOkay.title(unitAddress, viewingDatetime),
                                html: viewingTakePlaceWasOkay.html,
                                confirmButtonText: viewingTakePlaceWasOkay.confirmButtonText,
                                denyButtonText: viewingTakePlaceWasOkay.denyButtonText,
                                showDenyButton: true,
                                showConfirmButton: true,
                                ...paramsAnimationInfo,
                                preConfirm: async (value) => {
                                    const feedbackAgent = viewingTakePlaceWasOkay.feedbackViewingTakePlaceWasOkayMakeOffer
                                    const currentDubaiDate = getCurrentDubaiDate()
                                    const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                                    const notificationParams = {
                                        notificationTime: inMomentViewingForAdmin,
                                        RuleID: viewingDone.id,
                                        Rule: 'admin-viewing-feedback',
                                        User: Number(userID),
                                        Text: viewingTakePlaceWasOkay.textNotifyViewingTakePlaceWasOkayMakeOffer(userName, unitAddress),
                                        publishedAt: 'Published'
                                    }
                                    dispatch(customCreateNotify(notificationParams))
                                    dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                                    dispatch(scanDocumentsForOffer(unit.id, offerModalType, navigate, false))
                                    dispatch(setShowPopupViewingFeedback(false))
                                },
                                preDeny: async () => {
                                    const viewingTakePlaceWasOkaySubmitTheAnswer = swalConfig.feedbackPopup.questions.viewingTakePlace.viewingTakePlaceWasOkay.viewingTakePlaceWasOkaySubmitTheAnswer

                                    Swal.fire({
                                        ...swalOptions('info'),
                                        ...{
                                            title: viewingTakePlaceWasOkaySubmitTheAnswer.title(unitAddress, viewingDatetime),
                                            input: 'select',
                                            inputLabel: viewingTakePlaceWasOkaySubmitTheAnswer.inputLabel,
                                            inputOptions: viewingTakePlaceWasOkaySubmitTheAnswer.inputOptions,
                                            ...paramsAnimationInfo,
                                            inputPlaceholder: 'Select a reason',
                                            inputValidator: async (value) => {
                                                if (value === 'Other reason') {
                                                    const viewingTakePlaceWasOkaySubmitTheAnswerOtherReason = swalConfig.feedbackPopup.questions.viewingTakePlace.viewingTakePlaceWasOkay.viewingTakePlaceWasOkaySubmitTheAnswer.viewingTakePlaceWasOkaySubmitTheAnswerOtherReason
                                                    return await new Promise((resolve) => {
                                                        Swal.fire({
                                                            ...swalOptions('info'),
                                                            ...{
                                                                title: viewingTakePlaceWasOkaySubmitTheAnswerOtherReason.title(unitAddress, viewingDatetime),
                                                                inputLabel: viewingTakePlaceWasOkaySubmitTheAnswerOtherReason.inputLabel,
                                                                confirmButtonText: viewingTakePlaceWasOkaySubmitTheAnswerOtherReason.confirmButtonText,
                                                                input: 'textarea',
                                                                showConfirmButton: true,
                                                                showLoaderOnConfirm: true,
                                                                showLoaderOnDeny: true,
                                                                ...paramsAnimationInfo
                                                            }
                                                        }).then((result: any) => {
                                                            // console.log(result)
                                                            if (result.isConfirmed) {
                                                                const feedbackAgent = viewingTakePlaceWasOkaySubmitTheAnswer.feedbackViewingTakePlaceWasOkaySubmitTheAnswerReason(String(result.value))
                                                                //  const feedbackAgent = `Viewing take place - Viewing was okay - Potential client is considering other options because of: ${String(result.value)}`
                                                                const currentDubaiDate = getCurrentDubaiDate()
                                                                const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                                                                const notificationParams = {
                                                                    notificationTime: inMomentViewingForAdmin,
                                                                    RuleID: viewingDone.id,
                                                                    Rule: 'admin-viewing-feedback',
                                                                    User: Number(userID),
                                                                    Text: `User:  ${userName} ('${currentUser.email}') complete his viewing: Unit:  ${unitAddress}  Check his review in app!`,
                                                                    publishedAt: 'Published'
                                                                }
                                                                dispatch(customCreateNotify(notificationParams))
                                                                dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                                                                dispatch(setShowPopupViewingFeedback(false))
                                                            }
                                                        }) // .catch(err => { console.log(err) })
                                                    })
                                                }
                                            },
                                            showConfirmButton: true,
                                            confirmButtonText: 'Submit the answer',
                                            allowOutsideClick: viewingDone.attributes.NotifyViewedDate == null ? true : !is24HoursPassed(viewingDone?.attributes?.NotifyViewedDate),
                                            preConfirm: async (value) => {
                                                const feedbackAgent = viewingTakePlaceWasOkaySubmitTheAnswer.feedbackViewingTakePlaceWasOkaySubmitTheAnswerReason(String(value))
                                                //   const feedbackAgent = `Viewing take place - Viewing was okay - Potential client is considering other options because of: ${String(value)}`
                                                const currentDubaiDate = getCurrentDubaiDate()
                                                const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                                                const notificationParams = {
                                                    notificationTime: inMomentViewingForAdmin,
                                                    RuleID: viewingDone.id,
                                                    Rule: 'admin-viewing-feedback',
                                                    User: Number(userID),
                                                    Text: `User:  ${userName} ('${currentUser.email}') complete his viewing: Unit:  ${unitAddress}  Check his review in app!`,
                                                    publishedAt: 'Published'
                                                }
                                                dispatch(customCreateNotify(notificationParams))
                                                dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                                                dispatch(setShowPopupViewingFeedback(false))
                                            }
                                        }
                                    }) // .catch(err => { console.log(err) })
                                }
                            }
                        }) // .catch(err => { console.log(err) })
                    },
                    preDeny: async () => {
                        const viewingTakePlaceWasNotOkay = swalConfig.feedbackPopup.questions.viewingTakePlace.viewingTakePlaceWasNotOkay

                        Swal.fire({
                            ...swalOptions('info'),
                            ...{
                                title: viewingTakePlaceWasNotOkay.title(unitAddress, viewingDatetime),
                                inputLabel: viewingTakePlaceWasNotOkay.inputLabel,
                                input: 'select',
                                inputOptions: viewingTakePlaceWasNotOkay.inputOptions,
                                inputPlaceholder: 'Select a reason',
                                ...paramsAnimationInfo,
                                inputValidator: async (value) => {
                                    if (value === 'Other reason') {
                                        const viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason = viewingTakePlaceWasNotOkay.viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason

                                        return await new Promise((resolve) => {
                                            Swal.fire({
                                                ...swalOptions('info'),
                                                ...{
                                                    title: viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason.title(unitAddress, viewingDatetime),
                                                    inputLabel: viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason.inputLabel,
                                                    confirmButtonText: viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason.confirmButtonText,
                                                    input: 'textarea',
                                                    showConfirmButton: true,
                                                    showLoaderOnConfirm: true,
                                                    showLoaderOnDeny: true
                                                }
                                            }).then((result: any) => {
                                            // console.log(result)
                                                if (result.isConfirmed) {
                                                    const feedbackAgent = viewingTakePlaceWasNotOkay.feedbackViewingTakePlaceWasNotOkaySubmitTheAnswerReason(String(result.value))
                                                    //  const feedbackAgent = `Viewing take place - Viewing was not okay because of: ${String(result.value)}`
                                                    const currentDubaiDate = getCurrentDubaiDate()
                                                    const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                                                    const notificationParams = {
                                                        notificationTime: inMomentViewingForAdmin,
                                                        RuleID: viewingDone.id,
                                                        Rule: 'admin-viewing-feedback',
                                                        User: Number(userID),
                                                        Text: `User:  ${userName} ('${currentUser.email}') complete his viewing: Unit:  ${unitAddress}  Check his review in app!`,
                                                        publishedAt: 'Published'
                                                    }
                                                    dispatch(customCreateNotify(notificationParams))
                                                    dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                                                    dispatch(setShowPopupViewingFeedback(false))
                                                }
                                            }) // .catch(err => { console.log(err) })
                                        })
                                    }
                                },
                                showConfirmButton: true,
                                confirmButtonText: 'Submit the answer',
                                showLoaderOnConfirm: true,
                                showLoaderOnDeny: true,
                                allowOutsideClick: viewingDone.attributes.NotifyViewedDate == null ? true : !is24HoursPassed(viewingDone?.attributes?.NotifyViewedDate),

                                preConfirm: async (value) => {
                                    //   const feedbackAgent = `Viewing take place - Viewing was okay - Potential client is considering other options because of: ${String(value)}`
                                    const feedbackAgent = viewingTakePlaceWasNotOkay.feedbackViewingTakePlaceWasNotOkaySubmitTheAnswerReason(String(value))
                                    const currentDubaiDate = getCurrentDubaiDate()
                                    const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                                    const notificationParams = {
                                        notificationTime: inMomentViewingForAdmin,
                                        RuleID: viewingDone.id,
                                        Rule: 'admin-viewing-feedback',
                                        User: Number(userID),
                                        Text: `User:  ${userName} ('${currentUser.email}') complete his viewing: Unit:  ${unitAddress}  Check his review in app!`,
                                        publishedAt: 'Published'
                                    }
                                    dispatch(customCreateNotify(notificationParams))
                                    dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                                    dispatch(setShowPopupViewingFeedback(false))
                                }
                            }
                        }) // .catch(err => { console.log(err) })
                        return false
                    }
                })
                return false
            },
            preDeny: async () => {
                const viewingDidNotTakePlace = swalConfig.feedbackPopup.questions.viewingDidNotTakePlace
                Swal.fire({
                    ...swalOptions('info'),
                    ...{
                        title: viewingDidNotTakePlace.title(unitAddress, viewingDatetime),
                        inputLabel: viewingDidNotTakePlace.inputLabel,
                        inputOptions: viewingDidNotTakePlace.inputOptions,
                        confirmButtonText: viewingDidNotTakePlace.confirmButtonText,
                        inputPlaceholder: viewingDidNotTakePlace.inputPlaceholder,
                        input: 'select',
                        showDenyButton: false,
                        showConfirmButton: true,
                        showLoaderOnConfirm: true,
                        showLoaderOnDeny: true,
                        ...paramsAnimationInfo,
                        allowOutsideClick: viewingDone.attributes.NotifyViewedDate == null ? true : !is24HoursPassed(viewingDone?.attributes?.NotifyViewedDate),
                        preConfirm: async (value) => {
                            const feedbackAgent = viewingDidNotTakePlace.feedbackViewingViewingDidNotTakePlace(value)
                            const currentDubaiDate = getCurrentDubaiDate()
                            const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                            const notificationParams = {
                                notificationTime: inMomentViewingForAdmin,
                                RuleID: viewingDone.id,
                                Rule: 'admin-viewing-feedback',
                                User: Number(userID),
                                Text: `User:  ${userName} ('${currentUser.email}') complete his viewing: Unit:  ${unitAddress}  Check his review in app!`,
                                publishedAt: 'Published'
                            }
                            //   console.log(timestamp, 'timestamp')
                            dispatch(customCreateNotify(notificationParams))
                            dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                            dispatch(setShowPopupViewingFeedback(false))
                        }
                    }
                }) // .catch(err => { console.log(err) })
                return false
            }
        }
    }).then(
        async (result: any) => {
            if (result.isDismissed) {
                const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

                if (viewingDone.attributes.NotifyViewedDate == null) {
                    const response = await fetch(urlAPI,
                        {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json',
                                Authorization: `Bearer ${jwt}`
                            },
                            body: JSON.stringify({
                                method: 'PUT',
                                collection: 'viewings',
                                query: viewingsQuery, // 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[KeyBooking]=*',
                                id: Number(viewingDone.id),
                                body: {
                                    NotifyViewedDate: getCurrentDubaiDate() // + '04:00'
                                }
                            })
                        })
                    //  const dataJSON = await response.json()
                }
                dispatch(setShowPopupViewingFeedback(false))
            }
        }
    ) // .catch(err => { console.log(err) })
}
