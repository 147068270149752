import React, { useEffect, useState } from 'react'

import { useAppDispatch } from '../../../hooks/redux'

import { Collapse, Switch, Space, type CollapseProps } from 'antd'
import { userUpdateNotifies } from '../../../store/actionsCreators/userActions/userUpdateNotifies'

interface IProps {
    notifies?: Record<string, boolean | number>
}

export default function AgentNotifies ({ notifies }: IProps): JSX.Element {
    const isEmptyNotifies = (notifies == null) || Object.keys(notifies).length === 0
    // console.log(isEmptyNotifies, 'isEmptyNotifies')
    const defaultValues = {
        Broadcast: false,
        Chat: false,
        Messages: false,
        Offers: false,
        Requests: false,
        Scheduler: false,
        ViewingBefore1Hour: false,
        ViewingBefore2Hours: false,
        ViewingBefore30Minutes: false,
        Viewings: false
    }

    const updatedNotifies = isEmptyNotifies ? defaultValues : { ...defaultValues, ...notifies }
    // console.log(updatedNotifies, 'updatedNotifies')
    const [appNotifies, setAppNotifies] = useState(updatedNotifies)
    const dispatch = useAppDispatch()
    useEffect(() => {
        setAppNotifies({ ...defaultValues, ...notifies })
    }, [notifies])
    // console.log(appNotifies, 'appNotifies')

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="card-title fs-3 fw-bold fw-bold p-0 m-0">Notifications</span>,
            children: <div className="card-body p-0 m-0">
                <div className="d-flex flex-wrap align-items-center">
                    <div >

                        {/* <div className="fw-semibold text-gray-600">22</div> */}
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Broadcast events</div>
                    </div>

                    <div className="ms-auto">
                        {/* <Link to={`/agent/modal/phone_verify_contacts_my/${userID}/${userID}`} className="text-decoration-none"> */}
                        {/* <div className="form-check form-switch form-check-custom form-check-solid me-2">
                                    <input className="form-check-input h-30px w-50px" type="checkbox" value="" id="flexSwitch30x50"/>

                                </div> */}

                        <Space direction="vertical">
                            <Switch checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>ON</span>} unCheckedChildren="OFF" defaultChecked={appNotifies.Broadcast}
                                className={'switch_broadcast'}
                                onChange={(checked) => {
                                    const updatedNotifies = { ...appNotifies, Broadcast: checked }
                                    dispatch(userUpdateNotifies(updatedNotifies))
                                }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-4"></div>
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Chat messages </div>
                    </div>

                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch unCheckedChildren="OFF" defaultChecked={appNotifies.Chat}
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>ON</span>}
                                onChange={(checked) => {
                                    const updatedNotifies = { ...appNotifies, Chat: checked }
                                    dispatch(userUpdateNotifies(updatedNotifies))
                                }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-4"></div>

                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Scheduler updates </div>
                    </div>
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>ON</span>}
                                unCheckedChildren="OFF" defaultChecked={appNotifies.Scheduler}
                                onChange={(checked) => {
                                    const updatedNotifies = { ...appNotifies, Scheduler: checked }
                                    dispatch(userUpdateNotifies(updatedNotifies))
                                }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-4 "></div>

                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Viewings updates</div>
                    </div>
                    <div className="ms-auto">
                        {/* <Link
                                    to={`/agent/modal/agent_settings/${userID}/${userID}`}
                                    className="text-decoration-none "
                                > */}
                        <Space direction="vertical">
                            <Switch checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>ON</span>} unCheckedChildren="OFF" defaultChecked={appNotifies.Viewings}
                                onChange={(checked) => {
                                    const updatedNotifies = { ...appNotifies, Viewings: checked }
                                    dispatch(userUpdateNotifies(updatedNotifies))
                                }}
                            />
                        </Space>
                        {/* </Link> */}
                    </div>
                </div>
                <div className="separator separator-dashed my-4 mb-6"></div>

                <h3 className="my-2 mb-4">Notify me about </h3>
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">New messages</div>
                        {/* <div className="fw-semibold text-gray-600">desc</div> */}
                    </div>

                    <div className="ms-auto my-2">
                        <Space direction="vertical">
                            <Switch checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>ON</span>} unCheckedChildren="OFF" defaultChecked={appNotifies.Messages}
                                onChange={(checked) => {
                                    const updatedNotifies = { ...appNotifies, Messages: checked }
                                    dispatch(userUpdateNotifies(updatedNotifies))
                                }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">new Chat requests</div>
                        {/* <div className="fw-semibold text-gray-600">desc</div> */}
                    </div>

                    <div className="ms-auto my-2">
                        <Space direction="vertical">
                            <Switch checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>ON</span>} unCheckedChildren="OFF" defaultChecked={appNotifies.Requests}
                                onChange={(checked) => {
                                    const updatedNotifies = { ...appNotifies, Requests: checked }
                                    dispatch(userUpdateNotifies(updatedNotifies))
                                }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Offers updates</div>
                        {/* <div className="fw-semibold text-gray-600">desc</div> */}
                    </div>

                    <div className="ms-auto my-2">
                        <Space direction="vertical">
                            <Switch checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>ON</span>} unCheckedChildren="OFF" defaultChecked={appNotifies.Offers}
                                onChange={(checked) => {
                                    const updatedNotifies = { ...appNotifies, Offers: checked }
                                    dispatch(userUpdateNotifies(updatedNotifies))
                                }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-2 mb-6"></div>

                <h3 className="my-2 mb-4">Notify me when viewing is coming</h3>
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Before 2 hours</div>
                        {/* <div className="fw-semibold text-gray-600">desc</div> */}
                    </div>

                    <div className="ms-auto my-2">
                        <Space direction="vertical">
                            <Switch checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>ON</span>} unCheckedChildren="OFF" defaultChecked={appNotifies.ViewingBefore2Hours}
                                onChange={(checked) => {
                                    const updatedNotifies = { ...appNotifies, ViewingBefore2Hours: checked }
                                    dispatch(userUpdateNotifies(updatedNotifies))
                                }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Before an hour</div>
                        {/* <div className="fw-semibold text-gray-600">desc</div> */}
                    </div>

                    <div className="ms-auto my-2">
                        <Space direction="vertical">
                            <Switch checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>ON</span>} unCheckedChildren="OFF" defaultChecked={appNotifies.ViewingBefore1Hour}
                                onChange={(checked) => {
                                    const updatedNotifies = { ...appNotifies, ViewingBefore1Hour: checked }
                                    dispatch(userUpdateNotifies(updatedNotifies))
                                }}

                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Before 30 minutes</div>
                        {/* <div className="fw-semibold text-gray-600">desc</div> */}
                    </div>

                    <div className="ms-auto my-2">
                        <Space direction="vertical">
                            <Switch checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>ON</span>} unCheckedChildren="OFF" defaultChecked={appNotifies.ViewingBefore30Minutes}
                                onChange={(checked) => {
                                    const updatedNotifies = { ...appNotifies, ViewingBefore30Minutes: checked }
                                    dispatch(userUpdateNotifies(updatedNotifies))
                                }}
                            />
                        </Space>
                    </div>
                </div>
                {/* <div className="separator separator-dashed my-2"></div> */}
            </div>
        }]

    return (
        <div className="card shadow-sm mb-2">
            <Collapse defaultActiveKey={'profile_notifies'} items={items}/>

        </div>
    )
}
