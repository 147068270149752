import Icon from '../../../../app/icon/Icon'
import { isCheckFeedbackTakeNotPlace, isCheckFeedbackTakePlace, isCheckFeedbackWasNotOkay, isCheckFeedbackWasOkay } from '../../../../functions/agent/date/isCheckFeedback'
import { isInLastMonth } from '../../../../functions/agent/date/isInLastMonth'
import { isInLastWeek } from '../../../../functions/agent/date/isInLastWeek'
import { isToday } from '../../../../functions/shared/date/isToday'

import { type IOffer } from '../../../../store/slices/offersSlice'
import { type IOrder, type ISettings, type User } from '../../../../store/slices/userSlice'
import { type IViewing } from '../../../../store/slices/viewingsSlice'

import { type IProfileMetric } from '../../../shared/profile/metrics/index'
import React from 'react' // Correct import path

export const getMetrics = (user: User): IProfileMetric[] => {
    //   console.log(user, 'user')
    const settings = user?.Settings as ISettings[]
    const orders = user?.Orders as IOrder[]
    const ServiceAgentSubscription = orders
        ? orders.filter((order: IOrder) =>
            order.Service?.some(
                (service) => service.__component === 'services.broker-subscription'
            )
        )
        : []
    ServiceAgentSubscription?.sort((a, b) => {
        const aCreatedAt = a.createdAt != null ? new Date(a.createdAt) : new Date(0)
        const bCreatedAt = b.createdAt != null ? new Date(b.createdAt) : new Date(0)
        return bCreatedAt.getTime() - aCreatedAt.getTime()
    })
    const latestServiceAgentSubscription = ServiceAgentSubscription?.[0]
    const activeSub = latestServiceAgentSubscription?.Service?.[0]?.Package ?? ''
    const isDev = window.localStorage.getItem('BSO_DEVELOP_MODE')

    const profileMetrics: IProfileMetric[] = [
        // {
        //     label: 'Subscription',
        //     value: activeSub,
        //     // dynamic: 'up',
        //     icon: <i className="ki-duotone ki-archive-tick fs-3x text-primary">
        //         <i className="path1"></i>
        //         <i className="path2"></i>
        //     </i>,
        //     color: 'purple',
        //     textPromt: 'Your current subscription plan'

        // },

        {
            label: 'Offers',
            value: user?.Offers?.length,
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-briefcase fs-3x text-warning">
                    <i className="path1"></i>
                    <i className="path2"></i>
                </i>,
            color: 'orange',
            textPromt: 'Your total completed offers count'
        },

        {
            label: 'Business',
            value: settings?.[0]?.Business ?? '0',
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-dollar fs-3x text-dark">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                </i>,
            color: 'cyan',
            textPromt: 'Your total amount of business of successful deals in the app'
        }
    ]
    if (isDev === 'true') {
        profileMetrics.concat([{
            label: 'Membership',
            value: settings?.[0]?.Membership ?? 'Bronze',
            dynamic: 'down',
            icon:
                <i className={`ki-duotone ki-crown fs-3x ${settings?.[0]?.Membership === 'Silver' ? 'text-secondary' : 'text-warning'} `}>
                    <i className="path1"></i>
                    <i className="path2"></i>
                </i>,
            color: 'geekblue',
            textPromt: 'Your current membership calculated depends on the last 30 days of your activity in the app'

        },

        {
            label: 'Performance',
            value: settings?.[0]?.Performance ?? '0',
            dynamic: 'up',
            icon: <i className="ki-duotone ki-award fs-3x text-info">
                <i className="path1"></i>
                <i className="path2"></i>
                <i className="path3"></i>
            </i>,
            color: 'blue',
            textPromt: 'Your current performance rating depends on the last 30 days of your activity in the app'
        },

        {
            label: 'Referrals',
            value: '0',
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-gift fs-3x text-danger">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                    <i className="path4"></i>
                </i>,
            color: 'red',
            textPromt: 'Your total completed referrals count'
        }])
    }

    return profileMetrics
}

export const getMetricsForPerfomanceViewings = (user: User, viewings: IViewing[]): IProfileMetric[] => {
    // console.log(user, 'user')
    const userID = user.id
    const agentViewings = viewings.filter((el) => el?.attributes?.User?.data?.id === userID)

    const filterViewingsByDate = (viewings: any[], filterFunction: (date: string) => boolean) => {
        return viewings.filter((el: any) => filterFunction(el.attributes?.Datetime))
    }

    const todayViewings = filterViewingsByDate(agentViewings, isToday)
    const lastWeekViewings = filterViewingsByDate(agentViewings, isInLastWeek)
    const lastMonthViewings = filterViewingsByDate(agentViewings, isInLastMonth)

    const settings = user?.Settings as ISettings[]
    const orders = user?.Orders as IOrder[]

    const calcStat = () => {
        const statResult = { 'Take place': 0, 'Did\'t take place': 0, 'Was okay': 0, 'Was not okay': 0 }

        const updatedStatResult = agentViewings.reduce((acc: any, el: IViewing) => {
            if (isCheckFeedbackWasOkay(el)) {
                acc = { ...acc, 'Was okay': acc['Was okay'] + 1 }
            } if (isCheckFeedbackWasNotOkay(el)) {
                acc = { ...acc, 'Was not okay': acc['Was not okay'] + 1 }
            } if (isCheckFeedbackTakePlace(el)) {
                acc = { ...acc, 'Take place': acc['Take place'] + 1 }
            } if (isCheckFeedbackTakeNotPlace(el)) {
                acc = { ...acc, 'Did\'t take place': acc['Did\'t take place'] + 1 }
            }

            return acc
        }, statResult)

        return updatedStatResult
    }

    const resultStat = calcStat()

    const profileMetrics: IProfileMetric[] = [

        {
            label: 'Today',
            value: String(todayViewings.length),
            // dynamic: 'up',
            icon: <i className="ki-duotone ki-calendar-tick fs-3x text-info">
                <i className="path1"></i>
                <i className="path2"></i>
                <i className="path3"></i>
                <i className="path4"></i>
                <i className="path5"></i>
                <i className="path6"></i>
            </i>

        },

        {
            label: 'Last week',
            value: String(lastWeekViewings.length),
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-calendar-tick fs-3x text-info">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                    <i className="path4"></i>
                    <i className="path5"></i>
                    <i className="path6"></i>
                </i>
        },

        {
            label: 'Last month',
            value: String(lastMonthViewings.length),
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-calendar-tick fs-3x text-info">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                    <i className="path4"></i>
                    <i className="path5"></i>
                    <i className="path6"></i>
                </i>
        }, {
            label: 'Total',
            value: String(agentViewings.length),
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-abstract-20 fs-3x text-info">
                    <i className="path1"></i>
                    <i className="path2"></i>
                </i>

        },
        {
            label: 'Clients viewed',
            value: String(resultStat['Take place']),
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-eye fs-3x text-info">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                </i>
        },
        {
            label: 'Incomplete viewings',
            value: String(resultStat['Did\'t take place']),
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-eye-slash fs-3x text-info">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                    <i className="path4"></i>
                </i>
        },

        {
            label: 'Interested',
            value: String(resultStat['Was okay']),
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-like fs-3x text-info">
                    <i className="path1"></i>
                    <i className="path2"></i>
                </i>
        },
        {
            label: 'Not interested',
            value: String(resultStat['Was not okay']),
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-dislike fs-3x text-info">
                    <i className="path1"></i>
                    <i className="path2"></i>
                </i>
        }

    ]

    return profileMetrics
}
export const getMetricsForPerfomanceOffers = (user: User, offers: IOffer[]): IProfileMetric[] => {
    // console.log(user, 'user')
    const userID = user.id
    const agentOffers = offers.filter((el) => el?.attributes?.User?.data?.id === userID)
    // const othersOffers = offers.filter((el) => el?.attributes?.User?.data?.id !== userID)
    const filterOffersByCreateDate = (offers: any[], filterFunction: (date: string) => boolean) => {
        return offers.filter((el: any) => filterFunction(el?.attributes?.createdAt))
    }

    const todayOffers = filterOffersByCreateDate(agentOffers, isToday)
    const lastWeekOffers = filterOffersByCreateDate(agentOffers, isInLastWeek)
    const lastMonthOffers = filterOffersByCreateDate(agentOffers, isInLastMonth)

    const calcStat = () => {
        const statResult = { Completed: 0, Canceled: 0 }

        const updatedStatResult = agentOffers.reduce((acc: any, el: IOffer) => {
            if (el.attributes.Statuses.includes('Completed')) {
                return { ...acc, Completed: acc.Completed + 1 }
            } else if (el.attributes.Statuses.includes('Canceled')) {
                return { ...acc, Canceled: acc.Canceled + 1 }
            }
            return acc
        }, statResult)

        return updatedStatResult
    }

    const resultStat = calcStat()

    const profileMetrics: IProfileMetric[] = [
        {
            label: 'Today',
            value: String(todayOffers.length),
            dynamic: 'down',
            icon:
                <i className="ki-duotone ki-brifecase-timer fs-3x text-warning">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                </i>

        },
        {
            label: ' Last week',
            value: String(lastWeekOffers.length),
            dynamic: 'down',
            icon:
                <i className="ki-duotone ki-brifecase-timer fs-3x text-warning">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                </i>

        },
        {
            label: 'Last month',
            value: String(lastMonthOffers.length),
            dynamic: 'down',
            icon:
                <i className="ki-duotone ki-brifecase-timer fs-3x text-warning">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                </i>

        },
        {
            label: 'Total',
            value: String(agentOffers.length),
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-abstract-20 fs-3x text-warning">
                    <i className="path1"></i>
                    <i className="path2"></i>
                </i>
        },

        {
            label: 'Canceled',
            value: String(resultStat.Canceled),
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-brifecase-cros fs-3x text-warning">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                </i>
        },
        {

            label: 'Completed',
            value: String(resultStat.Completed),
            dynamic: 'up',
            icon:
                <i className="ki-duotone ki-brifecase-tick fs-3x text-warning">
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                </i>
        }

    ]

    return profileMetrics
}
