import React, {type ReactElement} from "react";
import Icon from "../../../../app/icon/Icon";
import {type IconName} from "../../../../app/icon/iconsConfig";

interface IconButtonProps {
    icon: IconName
    imageSize?: 24 | 40 | 48
    iconToFontRatio?: number
    style: 'bso' | 'gray' | 'light-gray' | 'no-background'
    customBackground?: `#${string}`
    customColor?: `#${string}`
    onClick?: () => void
    key?: string
    isLoading?: boolean
}

export default function IconButton ({icon, style, onClick, key, isLoading, imageSize, iconToFontRatio, customBackground, customColor}: IconButtonProps): ReactElement {
    let className = ''
    let styles = {}
    switch (style) {
        case 'bso':
            className = 'd-flex justify-content-center align-items-center rounded-circle bg-bso fs-1'
            styles = {
                width: '24px',
                height: '24px'
            }
            break
        case 'gray':
            className = 'd-flex justify-content-center align-items-center rounded-circle bg-secondary fs-1'
            styles = {
                width: '24px',
                height: '24px'
            }
            break
        case 'light-gray':
            className = 'd-flex justify-content-center align-items-center rounded-circle bg-gray-100 fs-1 color-gray-500'
            styles = {
                width: '24px',
                height: '24px'
            }
            break
        case 'no-background':
            className = 'd-flex justify-content-center align-items-center rounded-circle fs-2x'
            styles = {
                width: '24px',
                height: '24px',
                backgroundColor: customBackground != null ? customBackground : undefined,
                color: customColor != null ? customColor : undefined
            }
            break
        default:
            break
    }
    return <div className={className} style={styles} onClick={onClick} key={key}>
        {
            isLoading === true
                ? <div className={'spinner-border spinner-border-sm'}></div>
                : <Icon name={icon} imageSize={imageSize} iconToFontRatio={iconToFontRatio}/>
        }
    </div>
}