import React, { useEffect, useState } from 'react'
import { type IOffer } from '../../../../store/slices/offersSlice'
import { type Unit } from '../../../../store/slices/unitsSlice'
import { Button, Steps } from 'antd'
import { type TimelineItem } from '../ViewingTimeline'
import { SchedulerStatus } from '../../../../functions/agent/date/SchedulerStatus'
import Icon from '../../../icon/Icon'
import SentToLandlord from '../../../../functions/agent/statuses/SentToLandlord'
import Approved from '../../../../functions/agent/statuses/Approved'
interface IProps {
    offer: IOffer
    unit: Unit

}

function TimelineSale ({ offer, unit }: IProps): JSX.Element {
    let process = 0
    const offerStatuses = offer?.attributes?.Statuses

    const textsOfferTimelineSale = {
        stepInProcess: 'Wait for offer approval',
        descriptionInProcess: 'Our specialist will approve your offer soon',
        stepApprovedOrRejected: 'Your offer was sent to landlord',//'Your offer was approved',
        descriptionApprovedOrRejected: 'Please wait for the unit’s landlord\'s confirmation of your offer',
        stepConfirmed: 'Your offer was confirmed by the landlord',
        descriptionConfirmed: 'Please send us your client\'s contact info: email address and phone number',
        stepCompleted: 'Deal closed successfully',
        descriptionCompleted: 'Congratulations on another completed offer! Let’s make another one with the BSO Club!',
        addTitle: ''
    }

    if (offerStatuses?.includes('In-process')) {
        process = 0
    } else if (offerStatuses?.includes('Resubmit required')) {
        process = 0
        textsOfferTimelineSale.stepInProcess = 'Resubmit and wait approve offer'
        textsOfferTimelineSale.descriptionInProcess = 'Our specialist will approve your offer soon after you resubmit'
    } else if (offerStatuses?.includes('Canceled')) {
        process = 0
        textsOfferTimelineSale.stepInProcess = 'You canceled Offer!'
        textsOfferTimelineSale.descriptionInProcess = ''
    } else if (offerStatuses?.includes('Rejected')) {
        process = 1
        textsOfferTimelineSale.stepApprovedOrRejected = 'Your offer was rejected by BSO'
        textsOfferTimelineSale.descriptionApprovedOrRejected = 'Unfortunately, our specialist has decided to reject your offer because of the reason stated above'
    } else if (offerStatuses?.includes('Approved')) {
        process = 1
    } else if (offerStatuses?.includes('Confirmed')) {
        process = 2
        textsOfferTimelineSale.stepConfirmed = 'Landlord confirmed offer.'
        textsOfferTimelineSale.addTitle = 'Wait call from BSO.'
        textsOfferTimelineSale.descriptionConfirmed = ''
    } else if (offerStatuses?.includes('Negotiated by agent')) {
        process = 2
        textsOfferTimelineSale.stepConfirmed = 'You requested the offer negotiation'
        textsOfferTimelineSale.descriptionConfirmed = 'Please wait until our specialist or the landlord takes action about your negotiation of this offer'
    } else if (offerStatuses?.includes('Negotiated by BSO')) {
        process = 2
        textsOfferTimelineSale.stepConfirmed = 'The BSO offered another option from landlord'
        textsOfferTimelineSale.descriptionConfirmed = 'If you are satisfied, wait for a call from BSO, or provide updated offer details.'
    } else if (offerStatuses?.includes('Negotiated by landlord')) {
        process = 2
        textsOfferTimelineSale.stepConfirmed = 'The landlord offered another option'
        textsOfferTimelineSale.descriptionConfirmed = 'If you are satisfied, wait call from BSO, or provide updated offer details.'
    } else if (
        offerStatuses?.includes('Waiting for updates') ||
        offerStatuses?.includes('Under MOU') ||
        offerStatuses?.includes('Mortgage process') ||
        offerStatuses?.includes('Obtaining NOC') ||
        offerStatuses?.includes('Transfer process')
    ) {
        process = 3
        textsOfferTimelineSale.stepCompleted = 'Wait for the next updates'
        textsOfferTimelineSale.descriptionCompleted = 'Our specialist will update the offer status soon'
    } else if (offerStatuses?.includes('Completed')) {
        process = 3
    }

    const [line, setLine] = useState('middle')

    const iconProcess = (step: number, process: number) => {
        if (process + 1 > step || process === 3) {
            return undefined
        }
        return (<div className="ant-steps-item-icon">
            <span className="ant-steps-icon" style={{ color: 'black' }}>
                {step}
            </span>
        </div>
        )
    }

    const groupSale: TimelineItem[] = [
        {
            title: (
                <div>
                    <div className='lh-sm mb-1'>{textsOfferTimelineSale.stepInProcess}</div>
                    <div className={'d-flex align-items-center'}>
                        {process === 0 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="subTitle">{process === 0 ? SchedulerStatus(offerStatuses ?? [], 'fs-6') : undefined}</div>
                    </div>  </div>
            ),
            description: textsOfferTimelineSale.descriptionInProcess,
            status: process === 0 && (offerStatuses?.includes('Canceled') || offerStatuses?.includes('Rejected')) ? 'finish' : undefined
        } as TimelineItem,

        ...(offerStatuses?.includes('Canceled')

            ? []
            : [
                {
                    title: (
                        <div>
                            <div className='lh-sm mb-1'> {textsOfferTimelineSale.stepApprovedOrRejected} </div>
                            <div className={'d-flex align-items-center'}>
                                {process === 1 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                <div className="subTitle">{process === 1
                                    ? offerStatuses.includes('Approved')
                                        ? <Approved fontsize={'fs-6'} statusText={'Sent to landlord'}/>
                                        : SchedulerStatus(offerStatuses ?? [], 'fs-6')
                                    : undefined
                                }</div>
                            </div>
                            {process === 1 && offerStatuses?.includes('Rejected') && offer?.attributes?.RejectReason &&
                                <div className={'d-flex align-items-center'}>
                                    <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Reject Reason :</div>
                                    <div className="d-block mt-2"> {SchedulerStatus([`${offer?.attributes?.RejectReason}`], 'badge badge-light-danger  border border-danger text-truncate mw-100 d-inline-block fs-6')} </div>
                                </div>
                            }

                        </div>
                    ),
                    description: textsOfferTimelineSale.descriptionApprovedOrRejected
                },
                ...(!offerStatuses?.includes('Rejected')
                    ? [{
                        title: (
                            <div>
                                <div className='lh-sm mb-1'> {textsOfferTimelineSale.stepConfirmed} </div>
                                {textsOfferTimelineSale.addTitle ? <div className='lh-sm mb-1'> {textsOfferTimelineSale.addTitle} </div> : null}
                                <div className={'d-flex align-items-center'}>
                                    {process === 2 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle">{process === 2 ? SchedulerStatus(offerStatuses?.includes('Confirmed') ? ['Wait call BSO'] : offerStatuses ?? [], 'fs-6') : undefined}</div>
                                </div>  </div>
                        ),
                        description: textsOfferTimelineSale.descriptionConfirmed
                    },
                    {
                        title: (
                            <div >
                                <div className='lh-sm mb-1'> {textsOfferTimelineSale.stepCompleted} </div>
                                <div className={'d-flex align-items-center'}>
                                    {process === 3 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle d-block">{process === 3 ? SchedulerStatus(offerStatuses ?? [], 'fs-6 my-0') : undefined}</div>
                                </div>  </div>
                        ),
                        description: textsOfferTimelineSale.descriptionCompleted,
                        status: process === 3 ? 'finish' : undefined
                    } as TimelineItem]
                    : [])

            ])

    ]

    const [sliceSteps, setSliceSteps] = useState([groupSale[process]])

    useEffect(() => {
        groupSale.map((el, i) => el.icon = iconProcess(i + 1, process))
    }, [sliceSteps, process])

    return (
        <div>

            <Steps
                current={line === 'next' ? 0 : line === 'middle' ? 0 : process}
                percent={60}
                items={sliceSteps}
            />
            <div className={'d-flex'} style={{ justifyContent: process === 0 || process === 3 ? 'flex-end' : 'space-evenly' }}>
                <div className="d-flex justify-content-between align-items-center mx-4">
                    {process !== 0 && <div className={'text-end'}>
                        <div
                            className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                            onClick={line === 'prew'
                                ? () => { setSliceSteps([groupSale[process]]); setLine('middle') }
                                : () => {
                                    setSliceSteps([...groupSale].slice(0, process + 1))
                                    setLine('prew')
                                }
                            }
                        >
                            <div className="d-flex justify-content-around align-items-center">
                                <Icon name={line === 'prew' ? 'play_circle' : 'expand_circle_up'} className={'fs-5'} />
                                <span className="ms-1 fs-8">{line === 'prew' ? 'Current step' : 'Previous steps'}</span>
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-between align-items-center mx-2">
                        {process !== 3 && !offerStatuses.includes('Canceled') && <div className={'text-end'}>
                            <div
                                className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                                onClick={
                                    line === 'next'
                                        ? () => { setSliceSteps([groupSale[process]]); setLine('middle') }
                                        : () => { setSliceSteps([...groupSale].slice(process)); setLine('next') }}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <Icon name={line === 'next' ? 'play_circle' : 'expand_circle_down'} className={'fs-5'} />
                                    <span className="ms-1 fs-8">   {line === 'next' ? 'Current step' : 'Next steps'}</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimelineSale
