import React, { type ReactElement } from 'react'
import { nanoid } from 'nanoid'
import { docStatus } from '../../../../components/agentModule/settings/AgentIdAndLicence'
import NotAdded from '../../../../functions/agent/statuses/NotAdded'
import Approved from '../../../../functions/agent/statuses/Approved'
import Icon from '../../../icon/Icon'

export interface ViewModalContactInfoProps {
    title: string
    info: Array<string | ReactElement>
    controls?: Array<string | ReactElement>
    documents: Array<{
        docType: string
        document: any
        ignoreStatus?: boolean
    }>
}

export default function ViewModalContactInfo({ title, info, documents, controls = [] }: ViewModalContactInfoProps): ReactElement {
    return <div className="fv-row mb-2 m-0 p-0 w-100">
        <label className="form-label fs-5 fw-bold text-dark m-0">
            {title}
        </label>
        <div className="d-flex flex-wrap fw-normal text-dark mb-2">
            {
                info.map((element) => <div
                    className="badge badge-light-primary border border-primary fs-6 my-1 me-2"
                    key={nanoid()}
                >
                    {element}
                </div>)
            }
            {
                controls.map((element) => element)
            }
            <div className={'mb-2 w-100'} />
            {
                documents.map((element) => <div className={'w-100 d-flex gap-2 align-items-center'} key={nanoid()}>
                    <div
                        className="border border-gray-300 border-dashed rounded min-w-125px py-2 px-3 w-100 mb-1 g-0 d-flex flex-row justify-content-between"
                    >
                        <div className="fs-5 g-0 d-flex flex-column justify-content-center">
                            <div className="">
                                <span className='mb-2 fs-6 fw-bold counted'>{element.docType} :</span>
                            </div>
                        </div>
                        <div className={''}>
                            {
                                element.document != null
                                    ? element.ignoreStatus === true
                                        ? <Approved />
                                        : docStatus(element.document?.attributes?.Statuses)
                                    : <NotAdded />
                            }
                        </div>
                    </div>
                </div>)
            }
        </div>
        <div className="fv-row my-2  p-2 w-100">
            {/* <div className="separator border border-bso border-3 px-2 my-2"/> */}
        </div>
    </div>
}
