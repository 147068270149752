import React, { type ReactElement } from 'react'
import Icon from '../../../../app/icon/Icon'
import useUserType from '../../../../hooks/UseUserType'
import { useAppSelector } from '../../../../hooks/redux'

interface ScheduleLandlordPMCAttentionCardProps {
    pendingTasksCount: number
}

export default function ScheduleLandlordPMCAttentionCard ({ pendingTasksCount }: ScheduleLandlordPMCAttentionCardProps): ReactElement {
    const { userType } = useUserType()
    const currentUserId = useAppSelector((state) => state.user.currentUser.id)
    return userType === 'Landlord' && currentUserId !== Number(process.env.REACT_APP_BSO_USER_ID) && pendingTasksCount > 0
        ? <div className={'card shadow-sm mb-4 bg-white'}>
            <div className={'card-body p-4'}>
                <div className={'d-flex'}>
                    <div className={'d-flex align-items-center pe-4'}>
                        <Icon name={'lightbulb'} className={'fs-5x'}/>
                    </div>
                    <div className={'w-100'}>
                        <div className={'fs-6 mb-2'}>
                        You have <b>12 pending tasks</b>, you can <b>economy</b> your time if you order <b>PMC</b>
                        </div>
                        <div className={'d-flex justify-content-end'}>
                            <div className={'btn btn-bso btn-sm'}>
                            Learn more
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : <></>
}
