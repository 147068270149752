import React, {type ReactElement} from "react";
import DrawerPage from "../../../UIv2/DrawerPage/DrawerPage";
import {type Unit} from "../../../../../store/slices/unitsSlice";
import PageHeader from "../../../UIv2/PageHeader/PageHeader";
import IconButton from "../../../UIv2/Button/IconButton";
import PageLayout from "../../../UIv2/PageLayout/PageLayout";
import UnitActiveOrdersSectionFindingTenant
    from "./UnitActiveOrdersSections/UnitActiveOrdersSectionFindingTenant/UnitActiveOrdersSectionFindingTenant";

interface UnitActiveOrdersProps {
    isOpen: boolean
    setOpen: (state: boolean) => void
    unit: Unit
}

export default function UnitActiveOrders ({isOpen, setOpen, unit}: UnitActiveOrdersProps): ReactElement {
    return <DrawerPage isOpen={isOpen}>
        <PageLayout>
            <PageHeader title={'Active Orders'}
                controls={
                    <IconButton style={'no-background'}
                        icon={'close'}
                        onClick={() => {
                            setOpen(false)
                        }}
                    />
                }
                startControls={
                    <IconButton icon={'more_vert'}
                        style={'gray'}
                    />
                }
            />
            <div className={'d-flex flex-column gap-8'}>
                <UnitActiveOrdersSectionFindingTenant unit={unit}/>
            </div>
        </PageLayout>
    </DrawerPage>
}