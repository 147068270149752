import React, {type ReactElement} from "react";
import ClosableBlock from "../../../../../UIv2/ClosableBlock/ClosableBlock";
import {type ViewUnitSectionProps} from "../ViewUnitSection";
import BulletPointList from "../../../../../UIv2/BulletPointList/BulletpointList";
import {nanoid} from "nanoid";
import Badge from "../../../../../UIv2/Badge/Badge";

export default function ViewUnitSectionOwnerDetails ({unit}: ViewUnitSectionProps): ReactElement {
    const landlords = unit?.attributes?.Landlords
    return <ClosableBlock
        title={'Owner Details'}
        closable={false}
    >
        <BulletPointList data={
            landlords.map((landlord: any) => {
                const firstName = landlord?.Landlord?.data?.attributes?.ContactInfo?.FirstName
                const familyName = landlord?.Landlord?.data?.attributes?.ContactInfo?.FamilyName
                const sharing: string | number = landlord?.Sharing ?? 100
                return <div key={nanoid()} className={'d-flex align-items-center gap-3'}>
                    <div>{firstName} {familyName}</div>
                    <Badge text={`${sharing}% Ownership`}
                        size={'24'}
                        style={'danger-outlined'}
                    />
                </div>
            })
        }/>
    </ClosableBlock>
}