import React from 'react'

import { Link, useLocation } from 'react-router-dom'
import UnitBodyOptionsSh from './UnitBodyOptionsSh'
import ButtonsActionsSh from './ButtonsActionsSh'
import { type Unit } from '../../../store/slices/unitsSlice'
import { type ISettings } from '../../../store/slices/userSlice'
import UnitCarouselCard from './UnitCarouselCard'
import useUserType from '../../../hooks/UseUserType'

interface IProps {
    unit: Unit
    fromEntry: string | undefined
    settings?: ISettings[]
}
export default function UnitBodySh({ unit, fromEntry, settings }: IProps): JSX.Element {
    const { userTypeLowercase, userType } = useUserType()
    const currentLocation = useLocation().pathname;

    let modalFromEntry = 'catalog';

    if (fromEntry === 'catalogAgent') {
        modalFromEntry = currentLocation.includes('catalog_agent')
            ? 'other_agent_s_unit'
            : 'my_unit';
    } else if (fromEntry === 'myUnitsAgent' && userType === 'Agent') {
        modalFromEntry = 'agent_s_unit';
    } else if (fromEntry === 'other_agent_s_unit' && userType === 'Agent') {
        modalFromEntry = 'other_agent_s_unit';
    } else if (fromEntry === 'myUnitsAgent' && userType === 'Landlord') {
        modalFromEntry = 'landlord_s_unit';
    }

    return (
        <div className="card-body pb-3 pt-1 px-1">
            <Link
                to={`/${userTypeLowercase}/modalView/unit_modal/${unit.id}/not/${modalFromEntry}`}
                className="text-decoration-none"
            >
                <UnitCarouselCard unit={unit} />
                <UnitBodyOptionsSh unit={unit} />
            </Link>
            <ButtonsActionsSh unit={unit} fromEntry={fromEntry} settings={settings} />
        </div>
    )
}
