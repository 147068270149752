import Swal from 'sweetalert2'
import { swalOptions } from '../../../../../functions/swalOptions'
import { type Unit } from '../../../../slices/unitsSlice'
import { type NavigateFunction } from 'react-router-dom'
import { landlordAddUnitClearStorage } from './landlordAddUnitHandleStorage'

export const openEditUnitSwitch = (unit: Unit, navigate: NavigateFunction) => (): void => {
    void Swal.fire({
        ...swalOptions('info'),
        title: 'What info do you want to edit?',
        showConfirmButton: true,
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'Marketing info',
        denyButtonText: 'Property info',
        cancelButtonText: 'Go back',
        preConfirm (): void {
            landlordAddUnitClearStorage()
            navigate(`/landlord/modal/landlord_create_unit_special_requests/${unit.id}/-`)
        },
        preDeny (): void {
            landlordAddUnitClearStorage()
            navigate(`/landlord/modal/landlord_create_unit/${unit.id}/-`)
        }
    })
}
