import React, { type ReactElement } from "react";

interface StatusMarkProps {
    style: 'success' | 'warning' | 'danger'
}

export default function StatusMark ({style}: StatusMarkProps): ReactElement {
    const outerStyles: React.CSSProperties = {
        height: '12px',
        width: '12px'
    }
    const innerStyles: React.CSSProperties = {
        height: '6px',
        width: '6px'
    }
    switch (style) {
        case 'success':
            outerStyles.backgroundColor = '#C0DED2'
            innerStyles.backgroundColor = '#3E8367'
            break
        case 'warning':
            outerStyles.backgroundColor = '#EAD2BA'
            innerStyles.backgroundColor = '#EB9948'
            break
        case 'danger':
            outerStyles.backgroundColor = '#FFC3C3'
            innerStyles.backgroundColor = '#DC5454'
            break
        default:
            break
    }
    return <div className={'d-flex align-items-center justify-content-center rounded-circle'} style={outerStyles}>
        <div className={'rounded-circle'} style={innerStyles}/>
    </div>
}