import CustomFormLable from '../customModalUtils/CustomFormLable'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import { store } from '../../../store/store'
import { Drawer } from 'antd'
import { getBoardTime } from '../../../functions/getDataForSelects/getBoardTime'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { rerenderModal, setValue } from '../../../store/slices/setUnitSlice'
import { useAppDispatch } from '../../../hooks/redux'
dayjs.extend(customParseFormat)

type TimeRanges = Array<{ From: string, To: string }>

function mapTimesToRanges(times: string[]): TimeRanges {
    const ranges: TimeRanges = []
    times.sort().forEach((time, index) => {
        const timeObject = dayjs(time, 'HH:mm')
        if (index === 0 || timeObject.format('HH:mm') !== ranges?.[ranges.length - 1]?.To) {
            ranges.push({
                From: time,
                To: timeObject.add(30, 'minutes').format('HH:mm')
            })
        } else {
            ranges[ranges.length - 1].To = timeObject.add(30, 'minutes').format('HH:mm')
        }
    })
    return ranges
}

function mapRangesToTimes(ranges: TimeRanges): string[] {
    return getBoardTime()
        .filter((time) => {
            return ranges.some((range) => {
                const timeObject = dayjs(time, 'HH:mm')
                const fromObject = dayjs(range.From, 'HH:mm')
                const toObject = dayjs(range.To, 'HH:mm')
                return timeObject.isSame(fromObject) || (timeObject.isAfter(fromObject) && timeObject.isBefore(toObject))
            })
        })
}

interface CustomTimeRangeTimeBoardProps {
    row: any
    value: TimeRanges
}

function CustomTimeRangeTimeBoard({ row, value }: CustomTimeRangeTimeBoardProps): React.ReactElement {
    const dispatch = useAppDispatch()
    const [disabledDates, setDisabledDates] = useState<string[]>(mapRangesToTimes(value))
    useEffect(() => {
        return () => {
            dispatch(rerenderModal())
        }
    }, [])
    return <div className="row g-0 d-flex flex-row p-2">
        {
            getBoardTime().map((value) => (
                <div key={value + nanoid()} className={'col-3'} style={{ padding: '0.12rem' }}>
                    <div
                        className={`btn w-100 ${disabledDates.includes(value)
                            ? 'btn-light-danger active'
                            : 'btn-light-success active'
                            }`}
                        onClick={
                            () => {
                                if (disabledDates.includes(value)) {
                                    const newDates = disabledDates.filter(val => val !== value)
                                    setDisabledDates(newDates)
                                    dispatch(setValue({ id: row.id, value: mapTimesToRanges(newDates) }))
                                } else {
                                    const newDates = [...disabledDates, value]
                                    setDisabledDates(newDates)
                                    dispatch(setValue({ id: row.id, value: mapTimesToRanges(newDates) }))
                                }
                            }
                        }
                    >
                        {value}
                    </div>
                </div>
            ))
        }
    </div>
}

export interface CustomTimeRangeInputProps {
    row: any
    errors: any
}

/** IMPORTANT! Works with set unit modal only */
export default function CustomTimeRangeInput({ row, errors }: CustomTimeRangeInputProps): React.ReactElement {
    const value = store.getState().setUnit.staticForm.find((rowItem) => rowItem.id === row.id)?.value ?? []
    const [open, setOpen] = useState(false)
    function setModalOpen(): void {
        setOpen(true)
    }
    return <div className="fv-row mb-2 m-0 p-0 w-100 fv-plugins-icon-container">
        <CustomFormLable row={row} />
        <div className={'border border-secondary w-100 p-1 m-0 rounded rounded-md d-flex flex-wrap gap-1 align-items-center'}>
            {value.length > 0
                ? value.map((range: any) => (
                    <div style={{
                        background: '#0000000F',
                        fontSize: '16px'
                    }}
                        className={'px-3 rounded rounded-md lh-xl'}
                        key={nanoid()}
                    >
                        {range.From} - {range.To}
                    </div>
                ))
                : <span style={{
                    color: '#0000003F',
                    fontSize: '16px'
                }}
                    className={'lh-xl px-3 w-100 d-flex justify-content-between align-items-center'}
                >
                    {row.content[1]}
                    <i className="ki-duotone ki-plus-square fs-3x" onClick={setModalOpen}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                    </i>
                </span>
            }
            {value.length > 0
                ? <i className="ki-duotone ki-pencil fs-3x px-2" onClick={setModalOpen}>
                    <i className="path1"></i>
                    <i className="path2"></i>
                    <i className="path3"></i>
                </i>
                : <></>
            }
        </div>
        <Drawer
            style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', padding: 0 }}
            height={'auto'}
            styles={{
                body: {
                    maxHeight: '88vh',
                    padding: 0
                }
            }}
            placement={'bottom'}
            closable={false}
            onClose={() => {
                setOpen(false)
            }}

            open={open}
            // key={'bottom'}
            rootStyle={{ zIndex: '1100' }}
            key={nanoid()}
            maskClosable={true}
        >
            <CustomTimeRangeTimeBoard row={row} value={value} />
        </Drawer>
        {
            _.get(errors, row.id) != null && (<div className="text-danger">{_.get(errors, row.id).message}</div>)
        }
    </div>
}
