import React, {type ReactElement} from "react";
import {nanoid} from "nanoid";
import {useNavigate} from "react-router-dom";
import Icon from "../../../../icon/Icon";

interface LinkListProps {
    row: Record<string, any>
}

export default function LinkList({row}: LinkListProps): ReactElement {
    const navigate = useNavigate()
    return (
        <div className={'d-flex flex-column'}>
            {
                row?.links?.map((link: any, index: number) => (
                    <div key={nanoid()}
                        className={`d-flex align-items-center justify-content-between py-8 ${index < row?.links?.length - 1 ? 'border border-gray-300 border-top-0 border-start-0 border-end-0'  : ''}`}
                        onClick={() => {
                            navigate(link.href)
                        }}
                    >
                        <div className={'d-flex flex-column gap-3'}

                        >
                            <div className={'fs-5 fw-medium text-dark'}>
                                {
                                    link.title
                                }
                            </div>
                            <div className={'fs-5 fw-light text-dark'}>
                                {
                                    link.description
                                }
                            </div>
                        </div>
                        <div>
                            <Icon
                                name={'chevron_right'}
                                className={'fs-4x'}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}