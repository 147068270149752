import React, { useEffect, useState } from 'react'
import Icon from '../../../../app/icon/Icon'

import _ from 'lodash'
import AddOnServicesInfoDrawer from './AddOnServicesInfoDrawer'
import RulesModals from '../../../shared/auth/RulesModals'
import { type AddOnsServiceSliderCardOptions } from '../../../../types'
import { useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { USER_AREAS_EXTEND_COUNT } from '../../../../urls'

interface AddOnServicesCardProps {
    config: AddOnsServiceSliderCardOptions
    isLoading: boolean
    img: string
}

export default function AddOnServicesCardWithoutCarousel({
    config,
    isLoading,
    img
}: AddOnServicesCardProps): React.ReactElement {
    const [currentIndex, setCurrentIndex] = useState(0)

    const [termsOpen, setTermsOpen] = useState(false)
    const [termsAgreed, setTermsAgreed] = useState(false)

    const option = config.options[currentIndex]

    const handlePrevClick = (): void => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? config.options.length - 1 : prevIndex - 1
        )
    }

    const handleNextClick = (): void => {
        setCurrentIndex((prevIndex) =>
            prevIndex === config.options.length - 1 ? 0 : prevIndex + 1
        )
    }

    const notEnoughMarketingCredits = useAppSelector(
        (state: RootState) => state.advertises.notEnoughMarketingCredits
    )
    const [calculatedAmount, setCalculatedAmount] = useState(
        notEnoughMarketingCredits
    )

    useEffect(() => {
        setCalculatedAmount(notEnoughMarketingCredits)
    }, [notEnoughMarketingCredits])

    const unitID = useAppSelector((state: RootState) => state.advertises.unitIDnotEnoughAreaForAdvertise)
    const units = useAppSelector((state: RootState) => state.units.units).filter((u) => u.id === Number(unitID))
    const unitArea = units?.[0]?.attributes?.Property?.data?.attributes?.Area ?? ''




    return (
        <>
            <div className="px-4 pb-4">
                <div className="card shadow-sm">
                    <div className="card-body px-5 py-4">
                        <div
                            className="position-absolute mt-6 me-5 top-0 end-0"
                            onClick={config.onInfo}
                        >
                            <Icon name="help" className="text-muted fs-4x" />
                        </div>
                        <h3 className="text-center my-4">{config.title}</h3>
                        <div className="row mb-6">
                            <div className="col-8 d-flex align-content-center">
                                <div className="text-muted">
                                    {config.description}
                                </div>
                            </div>
                            <div className="col-4 d-flex align-content-center">
                                <img src={img} width="100%" alt="" />
                            </div>
                            {
                                option.product.amount === 1 && unitID && unitArea
                                    ? <div className="ms-2 p-2 d-flex align-items-center">
                                        Extend area -
                                        <div className={'ms-2 badge p-2 fs-7 text-dark badge-light border border-dark'}>
                                            {unitArea}
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex align-items-center justify-content-between border-bottom border-secondary border-width-2 pb-2 mb-4">
                            {option?.calculator?.use === true
                                ? (
                                    <div className="ps-8 d-flex gap-2 align-items-center">
                                        <Icon
                                            name="do_not_disturb_on"
                                            className={`fs-3x text-muted ${calculatedAmount === 0
                                                ? 'opacity-50' : ''}`}
                                            onClick={() => {
                                                if (calculatedAmount > 0) {
                                                    setCalculatedAmount(
                                                        calculatedAmount - 1
                                                    )
                                                }
                                            }}
                                        />
                                        {option.product.prefix}
                                        <b>
                                            <input
                                                type="number"
                                                tabIndex={0}
                                                autoFocus={false}
                                                value={calculatedAmount}
                                                className="fw-bold p-0 m-0 border-0 bg-secondary text-center rounded"
                                                style={{
                                                    width: '22px',
                                                    outline: 'none'
                                                }}
                                                onInput={(e: any) => {
                                                    const value = e.target.value
                                                    if (
                                                        value !== '' &&
                                                        !_.isFinite(Number(value))
                                                    ) {
                                                        setCalculatedAmount(0)
                                                    } else if (
                                                        value >=
                                                        Number(
                                                            option?.calculator?.max
                                                        )
                                                    ) {
                                                        setCalculatedAmount(
                                                            Number(
                                                                option?.calculator
                                                                    ?.max
                                                            )
                                                        )
                                                    } else {
                                                        setCalculatedAmount(
                                                            Number(value)
                                                        )
                                                    }
                                                }}
                                            />
                                        </b>
                                        {option.product.unit}
                                        <Icon
                                            name="add_circle"
                                            className={`fs-3x text-muted ${calculatedAmount ===
                                                Number(option.calculator?.max)
                                                ? 'opacity-50' : ''}`}
                                            onClick={() => {
                                                if (
                                                    calculatedAmount <
                                                    Number(option.calculator?.max)
                                                ) {
                                                    setCalculatedAmount(
                                                        calculatedAmount + 1
                                                    )
                                                }
                                            }}
                                        />
                                    </div>
                                )
                                : (
                                    <div className="px-2">
                                        {option.product.prefix}{' '}
                                        <b>{option.product.amount}</b>{' '}
                                        {option.product.unit}
                                    </div>
                                )}
                            {option?.calculator?.use === true

                                ? (
                                    <div className="pe-8">
                                        {option.price.unit}{' '}
                                        <b>
                                            {calculatedAmount *
                                                option?.calculator?.oneUnitPrice}
                                        </b>{' '}
                                        {option.price.postfix}
                                    </div>
                                )
                                : (
                                    <div className="px-2">
                                        {option.price.unit}{' '}
                                        <b>{option.price.amount}</b>{' '}
                                        {option.price.postfix}
                                    </div>
                                )}
                        </div>

                        <div className="row mb-4">
                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <Icon
                                    name="arrow_circle_left"
                                    className={`fs-4x text-muted ${currentIndex === 0 ? 'opacity-50' : ''}`}
                                    onClick={handlePrevClick}
                                />
                            </div>
                            {!isLoading
                                ? (
                                    <div
                                        className={`btn btn-bso col-6 ${option?.calculator?.use === true &&
                                            calculatedAmount <= 0
                                            ? 'opacity-50' : ''}`}
                                        onClick={() => {
                                            if (
                                                option?.calculator?.use !== true ||
                                                calculatedAmount > 0
                                            ) {
                                                if (config.showTermsAndConditions) {
                                                    setTermsOpen(true)
                                                } else {
                                                    if (unitID && unitArea) {
                                                        window.localStorage.setItem(USER_AREAS_EXTEND_COUNT, `${option.product.amount === 1 ? unitID : undefined} - ${option.product.amount}`)
                                                    }
                                                    config.onPurchase(
                                                        option.product.amount
                                                    )
                                                }
                                            }
                                        }}
                                    >
                                        {config.purchaseText}
                                    </div>
                                )
                                : (
                                    <div className="btn btn-bso col-6">
                                        Loading{' '}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </div>
                                )}
                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <Icon
                                    name="arrow_circle_right"
                                    className={`fs-4x text-muted ${currentIndex ===
                                        config.options.length - 1
                                        ? 'opacity-50'
                                        : ''
                                        }`}
                                    onClick={handleNextClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {config.showTermsAndConditions
                ? (
                    <AddOnServicesInfoDrawer
                        isOpen={termsOpen}
                        onClose={() => {
                            setTermsOpen(false)
                            setTermsAgreed(false)
                        }}
                        key={'Terms and conditions'}
                    >
                        <div className="fv-row my-2 py-2  w-100 px-2 ">
                            <div
                                className={
                                    'd-flex align-items-center justify-content-between border-bottom border-secondary border-width-2 pb-2 mb-4'
                                }
                            >
                                <div className={'px-2'}>
                                    {option.product.prefix}{' '}
                                    <b>
                                        {option?.calculator?.use === true
                                            ? calculatedAmount
                                            : option.product.amount}
                                    </b>{' '}
                                    {option.product.unit}
                                </div>
                                <div className={'px-2'}>
                                    {option.price.unit}{' '}
                                    <b>
                                        {option?.calculator?.use === true
                                            ? calculatedAmount *
                                            Number(
                                                option?.calculator?.oneUnitPrice
                                            )
                                            : option.price.amount}
                                    </b>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-4 px-4">
                                <label className="form-check form-check-solid form-check-inline">
                                    <input
                                        className="form-check-input h-40px w-40px pt-2 border border-dark"
                                        type="checkbox"
                                        name={'toc'}
                                        checked={termsAgreed}
                                        onChange={() => {
                                            setTermsAgreed(!termsAgreed)
                                        }}
                                    />
                                </label>
                                <span className="fw-semi-bold fs-5 py-2 my-2">
                                    I Accept the
                                    <RulesModals row={'Terms & Conditions'} />
                                    {'  '}and{'  '}
                                    <RulesModals row={'Privacy Policy'} />
                                </span>
                            </div>
                            <div className={'d-flex justify-content-center'}>
                                {!isLoading
                                    ? (
                                        <>
                                            {termsAgreed
                                                ? (
                                                    <div
                                                        className={'btn btn-bso col-4'}
                                                        onClick={() => {
                                                            config.onPurchase(
                                                                option?.calculator?.use ===
                                                                    true
                                                                    ? calculatedAmount
                                                                    : option.product.amount
                                                            )
                                                            setTermsOpen(false)
                                                            setTermsAgreed(false)
                                                        }}
                                                    >
                                                        {config.purchaseText}
                                                    </div>
                                                )
                                                : (
                                                    <div
                                                        className={
                                                            'btn btn-bso col-4 opacity-50'
                                                        }
                                                    >
                                                        {config.purchaseText}
                                                    </div>
                                                )}
                                        </>
                                    )
                                    : (
                                        <div className={'btn btn-bso col-4'}>
                                            Loading{' '}
                                            <span
                                                className={
                                                    'spinner-border spinner-border-sm align-middle ms-4'
                                                }
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                    </AddOnServicesInfoDrawer>
                )
                : (
                    <></>
                )}
        </>
    )
}
