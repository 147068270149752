import React from 'react'
import CalendarListItems from '../CalendarListItems'
// import { days } from '../../../../functions/agent/date/timeStringInScheduler'

export default function ServicesListItem (props: any): JSX.Element {
    console.log(props, '__________props____________')
    return (
        <div className="flex-grow-1">
            {props.orders && props.orders.length > 0 ? (
                Array.from(new Set(props.orders.map((order: any) => (new Date(order?.createdAt) as any).toDateString())))
                    .sort((a: any, b: any) => (new Date(a) as any) - (new Date(b) as any))
                    .map((dateString: any, index: number) => {
                        //      console.log(dateString, 'dateString')
                        const date = new Date(dateString)
                        const day = date.getDate().toString().padStart(2, '0')
                        const month = date.toLocaleString('default', { month: 'short' })
                        const dayWeek = date.getDay()
                        return (
                            <div
                                key={`${index}-${props.status}`}
                                className="card shadow-sm mb-2 position-relative me-n0 "
                            >

                                <div className="d-flex justify-content-between">
                                    <div className="w-50px d-flex flex-column align-items-center justify-content-start py-4 ps-2 col-2">
                                        <span className="fw-normal fs-5x lh-1 my-1">{`${day}`}</span>
                                        {/* <span className="text-gray-700 fs-4">{days[dayWeek]}</span> */}

                                    </div>
                                    <div className='col-10'>
                                        {props.orders
                                            .filter((order: any) => (new Date(order?.createdAt) as any).toDateString() === dateString)
                                            .map((order: any, index: number) => (
                                                <CalendarListItems key={`${String(order.id)}-${index}`} viewOrOffer={order} isWhat="service"/>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        )
                    })
            ) : null
                // <div className="card shadow-sm mb-2 position-relative" /> // Add an empty div when there are no viewings
            }
        </div>

    )
}
