import React, {type ReactElement, type ReactNode, useState} from "react";
import Separator from "../Separator/Separator";
import Icon from "../../../../app/icon/Icon";

interface ClosableBlockProps {
    title: string
    children: ReactNode
    closable?: boolean
}

export default function ClosableBlock ({title, children, closable = true}: ClosableBlockProps): ReactElement {
    const [blockOpen, setBlockOpen] = useState(true)
    return <div className={'d-flex flex-column'}>
        <div className={'d-flex justify-content-between align-items-center mb-2'}
            onClick={() => {
                setBlockOpen(!blockOpen)
            }}
        >
            <div className={'fs-2 fw-medium'}>
                {
                    title
                }
            </div>
            { closable
                ? <div className={'d-flex align-items-center justify-content-center fs-4x'}>
                    <Icon name={'chevron_right'} className={blockOpen ? ' d-none' : ''} imageSize={48}/>
                    <Icon name={'keyboard_arrow_down'} className={!blockOpen ? ' d-none' : ''} imageSize={48}/>
                </div>
                : <></>
            }
        </div>
        <div className={'mb-4'}>
            <Separator/>
        </div>
        { blockOpen
            ? children
            : <></>
        }
    </div>
}