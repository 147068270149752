import React, {type ReactElement} from "react";
import Card from "../Card/Card";
import IconButton from "../Button/IconButton";

interface InfoBlockProps {
    title: string
    text: string
    isShown: boolean
    setIsShown: (value: boolean) => void
}

export default function InfoBlock ({title, text, isShown, setIsShown}: InfoBlockProps): ReactElement {
    return isShown
        ? <Card rounded={'8'} style={'filled'} customBackground={'#FFC3C3'}>
            <div className={'d-flex flex-column gap-4 p-8'}>
                <div className={'d-flex align-items-center justify-content-between'}>
                    <div className={'fs-5 fw-medium'}>
                        {title}
                    </div>
                    <div>
                        <IconButton icon={'close'} style={'no-background'} onClick={() => {
                            setIsShown(false)
                        }}/>
                    </div>
                </div>
                <div className={'fs-7'}>
                    {text}
                </div>
            </div>
        </Card>
        : <></>
}